import React, { Component } from 'react'
import { Button, Col, Row, Table, CardHeader, Badge, Modal, ModalBody, ModalHeader, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input, Form, FormGroup, Label, InputGroup } from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import { withRouter } from 'react-router-dom'
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import { LoadingSpinner, CategoryFilter } from './../../components'
import TasksService from './../../services/tasksServices/TasksService'


export default class AddTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedCategory: 'Choose',
      selectedCategoryId: '',
      taskName: '',
      taskValue: '',
      types: [{ id: 1, type: 'prePricedTask', label: 'Pre Priced' }, { id: 2, type: 'timeBasedTask', label: 'Time Based' }],
      selectedTypeLabel: 'Type',
      selectedType: '',
      selectedTypeId: '',
      taskDays: ''
    }

  }

  componentDidMount() {
    console.log(this.props.selectedType)
  }


  addTask() {
    if (this.state.taskName == '') {
      alert('Please,add task name')
    } else if (this.state.taskValue == '') {
      alert('Please,add task value')
    }
  }

  handleChange(e, type) {
    let regex = /^[0-9]*$/
    if (type == 'taskName') {
      this.setState({ taskName: e.target.value })
    } else if (type == 'taskValue') {
      if (regex.test(e.target.value)) {
        this.setState({ taskValue: e.target.value })
      }
    } else if (type == 'taskDays') {
      if (regex.test(e.target.value)) {
        this.setState({ taskDays: e.target.value })
      }
    }
  }



  handler = (item) => {
    console.log(item)
    this.setState({ selectedCategory: item.enName, selectedCategoryId: item.id })
  }

  handleTypes(item) {
    this.setState({ selectedType: item.type, selectedTypeId: item.id, selectedTypeLabel: item.label })
  }


  addTask() {
    if (this.state.selectedType == '') {
      alert('Please , select type')
    } else if (this.state.selectedCategoryId == '') {
      alert('Please , select category')
    } else if (this.state.taskName == '' || this.state.taskName.trim() == '') {
      alert('Please , add task name')
    } else if (this.state.taskValue == '' && this.state.taskDays == '') {
      alert('Please , add task value or task days')
    } else {
      let data = {
        type: this.state.selectedType,
        categoryId: this.state.selectedCategoryId,
        description: this.state.taskName
      }
      if (this.state.selectedType == "prePricedTask") {
        data.price = this.state.taskValue
      } else {
        data.timeDuration = this.state.taskDays
      }
      console.log(data)
      this.setState({ loading: true })
      TasksService.add(data, (res) => {
        this.setState({ loading: false }, () => this.props.update())
      })
    }
  }

  render() {
    return (
      <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
        <Row>
          <Col md='2'>
            <label style={{ marginTop: 10 }}><strong>Task Type</strong></label>
          </Col>
          <Col md="6">
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle caret className='actionsBtnWithoutHover' style={{ width: '100%', backgroundColor: 'white', borderWidth: 1, borderColor: '#dddddd' }}><i className='fas fa-layer-group' style={{ marginRight: 10 }} />{this.state.selectedTypeLabel}</DropdownToggle>
              <DropdownMenu style={{ width: '100%' }}>
                {this.state.types.map((item, index) => (
                  <DropdownItem key={index} onClick={() => this.handleTypes(item)}>{item.label}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row>
          <Col md='2'>
            <label style={{ marginTop: 10 }}><strong>Category</strong></label>
          </Col>
          <Col md="6">
            <CategoryFilter handler={this.handler} className='actionsBtnWithoutHover' style={{ backgroundColor: 'white', width: '100%', borderColor: '#dddddd', borderWidth: 1, }} />
          </Col>
        </Row>
        <Row>
          <Col md='2'>
            <label style={{ marginTop: 10 }}><strong>Task Name</strong></label>
          </Col>
          <Col md='6' >
            <InputGroup style={{ marginBottom: '20px' }}>
              <Input style={{ padding: '10px' }} maxLength='150' placeholder='Task Name' value={this.state.taskName} onChange={(e) => this.handleChange(e, 'taskName')} />
            </InputGroup>
          </Col>
        </Row>
        {this.state.selectedType == "prePricedTask" ? (
          <Row>
            <Col md='2'>
              <label style={{ marginTop: 10 }}><strong>Task Value</strong></label>
            </Col>
            <Col md='6'>
              <InputGroup style={{ marginBottom: '20px' }}>
                <Input style={{ padding: '10px' }} maxLength='10' placeholder='Task Value' value={this.state.taskValue} onChange={(e) => this.handleChange(e, 'taskValue')} />
              </InputGroup>
            </Col>
            <Col md='1'>
              <label style={{ marginTop: 10 }}>LE</label>
            </Col>
          </Row>
        ) : null}
        {this.state.selectedType == "timeBasedTask" ? (
          <Row>
            <Col md='2'>
              <label style={{ marginTop: 10 }}><strong>Task Days</strong></label>
            </Col>
            <Col md='6'>
              <InputGroup style={{ marginBottom: '20px' }}>
                <Input style={{ padding: '10px' }} maxLength='10' placeholder='Task Days' value={this.state.taskDays} onChange={(e) => this.handleChange(e, 'taskDays')} />
              </InputGroup>
            </Col>
            <Col md='1'>
              <label style={{ marginTop: 10 }}>Days</label>
            </Col>
          </Row>
        ) : null}

        <LoadingSpinner fade loading={this.state.loading} />
      </div>
    )
  }

}