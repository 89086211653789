import React,{Fragment} from 'react'
import {LoadingSpinner, ChangePosition,CategoryFilter} from './../../components'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Tooltip, UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import LoadingOverlay from 'react-loading-overlay'
import {FModal,ContentMessage} from './../../components'
import SingleCustomer from './../customers/SingleCustomer'
import SingleFixer from './../fixers/SingleFixer'
import ConfirmDeleteMessage from './../../components/ConfirmDeleteMessage'
import ChatService from './../../services/chatServices/ChatService'
import Paginator from './../../components/Pagination/V2/Paginator'
import CategoriesService from './../../services/categoriesServices/CategoriesService'
export default class Categories extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      noResult:false,
      categories:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      dailyPriceModal:false,
      selectedCategory:'Category',
      selectedCategoryId:'',
      amount:''
    }
  }

  componentDidMount(){
    this.getCategories()
  }






  getCategories(){
    this.setState({loading:true})
    CategoriesService.getCategories((res)=>{
      this.setState({ loading:false, categories:res.data, noResult:!res.data.length})
    })
  }

  handleAddDailyPrice(item){
    this.setState({dailyPriceModal:!this.state.dailyPriceModal,category:item.id})
  }

  setSelectedCategory(item){
    this.setState({selectedCategory:item.enName,selectedCategoryId:item.id})
  }

  handleChange(e){
    let regex = /^[0-9]*$/
    if(regex.test(e.target.value)){
      this.setState({amount:e.target.value})
    }
  }

  editPrice(){
    if(this.state.amount == ''){
      alert('Please , add Price')
    }else{
      let data = {dailyPrice:this.state.amount}
      this.setState({loading:true})
      CategoriesService.editDailyPrice(data,this.state.category,(res)=>{
        this.setState({loading:false,dailyPriceModal:false,amount:''},()=>this.getCategories())
      })
    }
  }
  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderCategories()}
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>
          <FModal
            title='Edit Daily Price'
            content={
              <div style={{marginLeft:20}}>
                <Row>
                  <Col md="2"><Label style={{marginTop:7}}>Price : </Label></Col>
                  <Col md="6">
                    <InputGroup style={{marginBottom:'20px'}}>
                      <Input style={{padding:'10px'}} maxLength='10' placeholder='Price' value={this.state.amount} onChange={(e)=>this.handleChange(e)}/>
                    </InputGroup>
                  </Col>
                </Row>
              </div>
            }
            state={this.state.dailyPriceModal}
            onClose={()=>this.setState({dailyPriceModal:false,amount:''})}
            onSubmit={()=>this.editPrice()}
          />
          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Categories</span>
          </Col>

          <Col md="4">

          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderCategories(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Category</th>
            <th style={{textAlign:'center'}}>Daily Price</th>
            <th style={{textAlign:'center'}}>Edit Price</th>
          </tr>
        </thead>
        <tbody>
          {this.state.categories.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{item.enName}</td>
              <td style={{textAlign:'center'}}>{item.dailyPrice} LE</td>
              <td style={{textAlign:'center'}}>
                <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',marginLeft:'auto',marginRight:'auto'}} onClick={()=>this.handleAddDailyPrice(item)}><i className='fa fa-edit' style={{marginRight:8}}/>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }



}
