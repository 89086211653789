import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap'
import { validatePhoneNumber, validateUrl } from './../../../core/helper'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class AddNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note:'',
      noteLinkTitle:'',
      noteLink:'',
      customerBrief:0,
      selectedAddtional:'Choose',
      show:0,
      itemTitle:'',
      itemLink:'',
      itemImg:'',
      addedItems:[],
      isEdit:false,
      singleItem:''
    }
  }

  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'note':
      this.setState({note: e.target.value})
      break
      case 'noteLink':
      this.setState({noteLink: e.target.value})
      break
      case 'customerBrief':
      this.setState({customerBrief: e.target.checked})
      break
      case 'itemTitle':
      this.setState({itemTitle: e.target.value})
      break
      case 'itemLink':
      this.setState({itemLink: e.target.value})
      break
      case 'itemImg':
      this.setState({itemImg: e.target.value})
      break
      case 'noteLinkTitle':
      this.setState({noteLinkTitle: e.target.value},()=>{
        if (this.state.noteLinkTitle.length == 0) {
          this.setState({noteLink: ''})
        }
      })
      break
      default:
    }
  }

  handleMenu(option){
    switch (option) {
      case 0:
      this.setState({selectedAddtional:'Choose',show:0},()=>this.clearAddtional())
      break;
      case 1:
      this.setState({selectedAddtional:'Link',show:1},()=>this.clearAddtional())
      break;
      case 2:
      this.setState({selectedAddtional:'Product Item',show:2},()=>this.clearAddtional())
      break;
      default:
    }
  }

  addNoteModal(){
    if (this.state.note == "" || this.state.note.trim() == "") {
      alert('Please, enter note')
    }else{
      let data = {
        note : this.state.note,
        customerBrief : this.state.customerBrief
      }
      if (this.state.noteLinkTitle!='') {
        if (this.state.noteLink=='') {
          alert('Please, enter link')
          return
        }else if (!this.state.noteLink.includes('http') || !validateUrl(this.state.noteLink)) {
          alert('Please, note link must contain http://... like https://www.Example.com')
          return
        }else if (validateUrl(this.state.noteLink)) {
          data.link = this.state.noteLink
          data.linkTitle = this.state.noteLinkTitle
        }
      }
      this.setState({loading:true})
      Services.addNote(this.props.orderId, data,(res)=>{
        if (res.message == 'success') {
          this.props.close()
          this.setState({loading:false,note : '',customerBrief:0,})
        }else {
          this.setState({loading:false})
          alert('some thing is wrong')
        }
      })
    }
  }

  clearAddtional(){
    this.setState({
      noteLinkTitle:'',
      noteLink:'',
      itemTitle:'',
      itemLink:'',
      itemImg:'',
    })
  }

  addSingleItem(){
    if (this.state.itemTitle == "" || this.state.itemTitle.trim() == "") {
      alert('Please, enter title')
    }else if (validateUrl(this.state.itemLink) && validateUrl(this.state.itemImg)) {
      let data = {
        title: this.state.itemTitle,
        link: this.state.itemLink,
        image: this.state.itemImg
      }
      console.log(data);
      let arr = this.state.addedItems.slice()
      arr.push(data)
      this.clearAddtional()
      this.setState({addedItems:arr})
    }
  }

  editSingleItem(item){
    if (item != '') {
      this.setState({
        itemTitle: item.title,
        itemLink: item.link,
        itemImg: item.image,
        isEdit:true
      })
    }else {
      let arr = this.state.addedItems.slice()
      let indexOfItem = arr.findIndex(i=> i == this.state.singleItem)
      console.log('indexOfItem',indexOfItem);
      arr[indexOfItem].title = this.state.itemTitle
      arr[indexOfItem].link = this.state.itemLink
      arr[indexOfItem].image = this.state.itemImg
      this.clearAddtional()
      this.setState({addedItems:arr, isEdit:false})
    }
  }

  removeSingleItem(item){
    let arr = this.state.addedItems.slice()
    let newArr = arr.filter(i=> i != item)
    console.log(newArr);
    this.setState({addedItems:newArr})
  }


  render(){
    return(
      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <Row>
          <Col md="8">
            <Input
              type="textarea"
              value={this.state.note}
              onChange={(e)=>this.handleChangeInputs(e, 'note')}
              placeholder="Enter note details"
              className="addNote"
            />
          </Col>
          <Col md="4" style={{display:'flex',flexDirection:'row', alignSelf:'flex-start'}}>
            <Label check style={{cursor:'pointer'}}>
              <Input type="checkbox"
                checked={this.state.customerBrief}
                onChange={(e)=>this.handleChangeInputs(e, 'customerBrief')}
              />
              {' '}Display in customer logs
            </Label>
          </Col>
        </Row>

        {this.renderAddLink()}

        {/* <Row style={{marginTop:20, marginBottom:20}}>
          <Col md="2" style={{alignSelf:'center'}}>
            <h5 style={{marginBottom:0}}>Addtional</h5>
          </Col>
          <Col md="6">
            <UncontrolledDropdown>
          <DropdownToggle caret style={{width:'100%',backgroundColor:'#fff',borderColor:'#edededd'}}>{this.state.selectedAddtional}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          <DropdownItem onClick={()=>this.handleMenu(0)}>Choose</DropdownItem>
          <DropdownItem onClick={()=>this.handleMenu(1)}>Link</DropdownItem>
          <DropdownItem onClick={()=>this.handleMenu(2)}>Product Item</DropdownItem>
          </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          </Row>

          {this.state.show == 1 ? (
          this.renderAddLink()
          ): this.state.show == 2 ? (
          this.renderAddProductItem()
        ) : null} */}

        <LoadingSpinner fade loading={this.state.loading}/>
      </div>
    )
  }

  renderAddLink(){
    return(
      <div>
        <h5 style={{marginTop:16}}>Add Link</h5>
        <Row style={{marginTop:6}}>
          <Col md="4" style={{textAlign:'center'}}>
            <Input
              value={this.state.noteLinkTitle}
              onChange={(e)=>this.handleChangeInputs(e, 'noteLinkTitle')}
              placeholder="title"
            />
          </Col>
          <Col md="6">
            <Input
              value={this.state.noteLink}
              disabled={this.state.noteLinkTitle==''}
              onChange={(e)=>this.handleChangeInputs(e, 'noteLink')}
              placeholder="http://...."
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderAddProductItem(){
    return(
      <div>
        <h5 style={{marginTop:16}}>Add Product Items</h5>
        <Row style={{marginTop:6}}>
          <Col md="3" style={{textAlign:'center'}}>
            <Input
              value={this.state.itemTitle}
              onChange={(e)=>this.handleChangeInputs(e, 'itemTitle')}
              placeholder="title"
            />
          </Col>
          <Col md="3">
            <Input
              value={this.state.itemLink}
              onChange={(e)=>this.handleChangeInputs(e, 'itemLink')}
              placeholder="link as http://...."
            />
          </Col>
          <Col md="3">
            <Input
              value={this.state.itemImg}
              onChange={(e)=>this.handleChangeInputs(e, 'itemImg')}
              placeholder="Image link as http://...."
            />
          </Col>
          <Col>
            {this.state.isEdit?(
              <Button
                className='actionsBtn'
                onClick={()=>this.editSingleItem('')}>
                <i className="fas fa-edit" />&nbsp;&nbsp;Edit
              </Button>
            ):(
              <Button
                className='actionsBtn'
                onClick={()=>this.addSingleItem()}>
                <i className="fas fa-plus" />&nbsp;&nbsp;Add Item
              </Button>
            )}

          </Col>
        </Row>

        <Row style={{marginTop:20}}>
          {this.state.addedItems.map((item, i)=>(
            <Col md={3} key={i} className='productItem'>
              <img src={item.image} style={{height:150, width:'100%'}}/>
              <h6 style={{textAlign:'center'}}>{item.title}</h6>
              <p style={{textAlign:'center'}}><a href={item.link} target='_blank'>Link</a></p>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                <Button
                  className='actionsBtn'
                  onClick={()=>this.setState({singleItem:item},()=>this.editSingleItem(item))}>
                  <i className="fas fa-edit" />&nbsp;&nbsp;Edit
                </Button>

                <Button
                  className='actionsBtn'
                  onClick={()=>this.removeSingleItem(item)}>
                  <i className="fas fa-times" />&nbsp;&nbsp;Delete
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

}
