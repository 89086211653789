import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Table, Button, Badge} from 'reactstrap'
import CustomersServices from './../../services/customerServices/CustomersServices'
import PromocodeServices from './../../services/promo/PromocodeServices'
import Services from './../../services/Services'
import { LoadingSpinner, SingleCustomer, SingleOrder } from './../../components'

export default class ReferralCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code:'',
      loading:true
    }
  }

  componentDidMount(){
    console.log(this.props.code)
    this.getPromo()
  }
  getPromo(){
    this.setState({loading:true})
    PromocodeServices.get(this.props.code.id, (res) => {
      this.setState({code:res.data.promoCode, loading:false})
    })
  }
  showSingleCustomer(item){
    this.setState({showSingleCustomer:true, singleCustomer:item})
  }
  reload = () => {
    this.setState({showSingleOrder:false, showSingleCustomer:false})
  }

  render(){
    return(
      <div>
        {!this.state.loading?(
          <div>
            <h6 style={{paddingLeft:20}}>Users and Orders those use the <span style={{fontWeight:'bold', fontSize:16}}>{this.state.code.code}</span> code</h6>
            <h6 style={{paddingLeft:20, fontSize:16, margin:'30px -5px -10px'}}>Users</h6>
            <Table hover bordered striped responsive size="md">
              <thead>
                <tr>
                  <th style={{textAlign:'center',width:'7%'}}>ID</th>
                  <th style={{textAlign:'center'}}>Name</th>
                  <th style={{textAlign:'center'}}>Phone</th>
                  <th style={{textAlign:'center'}}>Mail</th>
                  <th style={{textAlign:'center'}}>Wallet Money</th>
                  <th style={{textAlign:'center'}}>Temperary Wallet</th>
                  <th style={{textAlign:'center'}}>Registration Date</th>
                  <th style={{textAlign:'center',width:'12%'}}>Status</th>
                </tr>
              </thead>
              <tbody>
                {this.state.code.users.map((item, index)=>(
                  <tr key={index}>
                    <td style={{textAlign:'center'}}>{item.id}</td>
                    <td style={{textAlign:'center'}}><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.showSingleCustomer(item)}>{item.name}</Button></td>
                    <td style={{textAlign:'center'}}>{item.phone}</td>
                    <td style={{textAlign:'center'}}>
                      {item.isEmailVerified?(<div><i className='fa fa-check-circle' style={{color:'#4dbd74', fontSize:18}}/> <br/></div>):''}
                      {item.email?item.email:'N/A'}
                    </td>
                    <td style={{textAlign:'center'}}>{item.walletAmount }EGP</td>
                    {item.user?(
                      <td style={{textAlign:'center'}}>{item.user.temporaryWallet.credit} LE</td>
                    ):(
                      <td style={{textAlign:'center'}}>N/A</td>
                    )}
                    {/* <td style={{textAlign:'center'}}>N/A</td> */}
                    <td style={{textAlign:'center'}}>{item.createdAt?item.createdAt.date.split(' ')[0]:''}</td>
                    <td style={{textAlign:'center'}}><Badge className='shadow' color={item.isBlocked==='1'?'danger':'success'}>{item.isBlocked==='1'?'Blocked':'Active'}</Badge></td>

                  </tr>
                ))}

              </tbody>

            </Table>
            {this.state.code.users.length == 0 ?(
              <p style={{color:'#777', textAlign:'center'}}>There is no users</p>
            ):null}

            <h6 style={{paddingLeft:20, fontSize:16, margin:'30px -5px -10px'}}>Orders</h6>
            <Table hover bordered striped responsive size="md">
              {this.state.code.orders.map((item, index)=>(
                <Button className="click" style={{backgroundColor:'#ededed',borderColor:'transparent', borderRadius:10, padding:10,fontWeight:'bold', marginRight:15}} onClick={()=>this.setState({showSingleOrder:true, singleOrder:item})}>{item.serial}</Button>
              ))}
              {this.state.code.orders.length == 0 ?(
                <p style={{color:'#777', textAlign:'center'}}>There is no orders</p>
              ):null}
            </Table>
          </div>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>

        {this.state.showSingleCustomer?<SingleCustomer data={this.state.singleCustomer} reload={this.reload}/>:null}
        {this.state.showSingleOrder?<SingleOrder data={this.state.singleOrder} reload={this.reload} />:null}
      </div>
    )
  }

}
