import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Services from '../../services/Services';

class UserPoints extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            admin:props.admin,
            orders:[],
            doneOrders:0,
            donePoints:0,
            wasteOrders:0,
            expiredOrders:0,
            cancledOrders:0,
            failedOrders:0,
            wastePoints:0,
            finalTotalPoints:0,
        };
    }

    componentDidMount(){
        if (!this.props.admin) {
            this.getUserPointsDetails();
        }else{
            this.setState({orders:this.state.admin.orders},()=>this.extractDetailsFromOrders())
        }
    }

    getUserPointsDetails(){
      Services.getUserPointsDetails((res)=>{
        this.setState({orders:res.data.orders},()=>this.extractDetailsFromOrders())
      })
    }

    extractDetailsFromOrders(){
        let cancledOrders=0,doneOrders=0,donePoints=0,expiredOrders=0,failedOrders=0,wasteOrders=0,wastePoints=0,finalTotalPoints=0;
        this.state.orders.map(order => {
            if (order.status.enName == 'Cancelled') {
                cancledOrders++;
                wastePoints+=order.points;
            }else if(order.status.enName == 'Done' || order.status.enName == 'Inspected'){
                doneOrders++;
                donePoints+=order.points;
            }else if (order.status.enName == 'Expired') {
                expiredOrders++;
                wastePoints+=order.points;
            }else if (order.status.enName == 'Failed') {
                failedOrders++;
                wastePoints+=order.points;
            }

            wastePoints = Number((wastePoints).toFixed(1));
            donePoints = Number((donePoints).toFixed(1));
            finalTotalPoints = Number((donePoints + wastePoints).toFixed(1));
        })

        this.setState({
            doneOrders:doneOrders,
            donePoints:donePoints,
            wasteOrders:wasteOrders,
            expiredOrders:expiredOrders,
            cancledOrders:cancledOrders,
            failedOrders:failedOrders,
            wastePoints:wastePoints,
            finalTotalPoints:finalTotalPoints,
        })
    }

    render(){
        return(
          <Modal fade={false} isOpen={true} toggle={()=>this.props.onClose()} >
            <ModalHeader toggle={()=>this.props.onClose()}>Points Details</ModalHeader>
            <ModalBody>
            <div className='orderBadges' style={{width:600, textAlign:'center', display:'block', margin:'auto'}}>
                <Table  hover bordered striped responsive size="md">
                    <thead>
                        <tr>
                            <th style={{textAlign:'center' }}>Key</th>
                            <th style={{textAlign:'center' }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Done Orders</th>
                            <th style={{textAlign:'center',width:'40%'}}>{this.state.doneOrders}</th>
                        </tr>
                        <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Done Points</th>
                            <th style={{textAlign:'center',width:'40%'}}>{this.state.donePoints}</th>
                        </tr>
                        <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Waste Orders</th>
                            <th style={{textAlign:'center',width:'40%'}}>
                                <Table  hover bordered striped responsive size="md">
                                    <tbody>
                                        <tr>
                                            <th style={{textAlign:'center',width:'40%'}}>Expired Orders</th>
                                            <th style={{textAlign:'center',width:'40%'}}>{this.state.expiredOrders}</th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:'center',width:'40%'}}>Canceled Orders</th>
                                            <th style={{textAlign:'center',width:'40%'}}>{this.state.cancledOrders}</th>
                                        </tr>
                                        <tr>
                                            <th style={{textAlign:'center',width:'40%'}}>Failed Orders</th>
                                            <th style={{textAlign:'center',width:'40%'}}>{this.state.failedOrders}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                            </th>
                        </tr>
                        <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Waste Points</th>
                            <th style={{textAlign:'center',width:'40%'}}>{this.state.wastePoints}</th>
                        </tr>
                        <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Final Total Points</th>
                            <th style={{textAlign:'center',width:'40%'}}>{this.state.finalTotalPoints}</th>
                        </tr>
                    </tbody>
                </Table>
            </div>
            </ModalBody>
            <ModalFooter>
              <Button className='actionsBtn' onClick={()=>this.props.onClose()}>Close</Button>
            </ModalFooter>
          </Modal>
        );
    }
}

export default UserPoints;
