import React, { Component } from 'react'
import { Button, Col, Row, Table, Modal, ModalBody, ModalHeader, ModalFooter, Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input,Pagination,PaginationLink,PaginationItem, FormGroup, Label } from 'reactstrap'
import Avatar from './../../assets/img/brand/download.png'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"

import firebase from 'firebase/app'
import 'firebase/database'

import FeedBack from './../feedback/FeedBack'
import FeedBackHistory from './../feedback/FeedBackHistory'
//import {handleFeedBack , renderFixerFeedBack} from './../customers/SingleCustomer'
import {FModal,FixerImage,FixerHistory, EditFixerData, DueFeesDetails} from './../../components'
import ViewValidFixerWorkContracts from '../../components/fixer/singleFixer/ViewValidFixerWorkContract'
class SingleFixer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      singleFixer:'',
      smsTitle:'',
      pushTitle:'',
      pushBody:'',
      pushURL:'',
      password:'',
      reasons:[],
      blockReason:'Choose',
      blockReasonId:'',
      multiSelectUnAssignedArea:[],
      unAssignedArea:[],
      selectedAreas:[],
      loading: false,
      mainModal: false,
      modalTwo: false,
      modalPush: false,
      modalSMS: false,
      modalunblock: false,
      modalBlock: false,
      modalAssginToArea:false,
      modalActiveFixer:false,
      serviceGroupsForFixerCategory:[],
      newAreasIds:[],
      authPassword:0,
      arName:'',
      enName:'',
      selectedServiceGroup:[],
      selectedServiceGroupId:[],
      multiSelectAssignedArea:[],
      feedBackModal: false,
      feedBackHistoryModal:false,
      rangeFeedBack:4.1,
      backgroundColorFeedBack:'',
      level:'',
      badgeColor:'',
      entryModal:false,
      entries:[],
      nextPages:[],
      previousPages:[],
      currentPage:1,
      noResult:false,
      editFixerImageModal:false,
      fixerHistoryModal:false,
      showEditFixerDataModal:false,
      showDueFeesModal:false,
      showValidWorkContractModal:false,
    }
  }

  componentDidMount(){
    this.getFirebaseGeneralData()
    this.getSingleFixer(this.props.data)
    window.addEventListener('scroll', this.listenScrollEvent)
  }


  // listenScrollEvent = e => {
  //   window.scrollY > 175 ? this.setState({position: true,
  //     widthDesc:240,widthId:105,widthDate:140,widthParties:382,widthDebit:97}) :
  //     // this.setState({position: false,widthDesc:'0%',widthId:'0%',widthDate:'0%',widthParties:'0%',widthDebit:'0%'})
  //     this.setState({position: false,
  //       widthDesc:240,widthId:105,widthDate:140,widthParties:382,widthDebit:97})
  //
  //
  //   this.compareFeedBackFixer()
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props.data != nextProps.data) {
      this.getSingleFixer(nextProps.data)
    }
  }

  getFirebaseGeneralData(){
    firebase.database().ref('general/').on('value', (snapshot) => {
      if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
        Object.keys(snapshot.val()).forEach( (key) => {
          let val = snapshot.val()[key]
          if(key == "authPassword"){
            this.setState({authPassword:val})
          }
        })
      }
    })
  }


  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'sms':
      this.setState({smsTitle: e.target.value})
      break
      case 'pushTitle':
      this.setState({pushTitle: e.target.value})
      break
      case 'pushBody':
      this.setState({pushBody: e.target.value})
      break
      case 'pushURL':
      this.setState({pushURL: e.target.value})
      break
      default:
    }
  }



  getSingleFixer(item){
    this.setState({loading:true})
    Services.getSingleFixer(this.props.from == "rate" ? item.fixerId : this.props.from == "request" ? item.actionMakerId : item.id , (res)=>{
      this.setState({loading:false, singleFixer:res.data.fixer, arName:res.data.fixer.arName, mainModal:true,})
      let arr = res.data.fixer.areas
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({loading:false, singleFixer:res.data.fixer,multiSelectAssignedArea:titles,mainModal:true,},()=>this.compareFeedBackFixer())
    })
  }

  getReasons(){
    this.setState({loading:true})
    Services.getReasons('fixer',(res)=>{
      this.setState({loading:false, reasons:res.data.reasons, mainModal:true, modalBlock:true})
    })
  }

  sendSMS(){
    if (this.state.smsTitle == '' || !this.state.smsTitle.replace(/\s/g, '').length) {
      alert('Please, enter SMS message content')
    }else {
      this.setState({loading:true})
      Services.sendSMS(this.state.smsTitle, this.state.singleFixer.id, (res)=>{
        this.setState({loading:false, mainModal:true, modalSMS:false})
      })
    }
  }
  sendPush(){
    if (this.state.pushTitle == '' || !this.state.pushTitle.replace(/\s/g, '').length) {
      alert('Please, enter push title')
    }else if (this.state.pushBody == '' || !this.state.pushBody.replace(/\s/g, '').length) {
      alert('Please, enter push content')
    }else {
      let reqData = {
        title: this.state.pushTitle,
        body: this.state.pushBody
      }
      if (this.state.pushURL != '') {
        reqData.url = this.state.pushURL
      }
      this.setState({loading:true})
      Services.sendPush(reqData, this.state.singleFixer.id, (res)=>{
        this.setState({loading:false, mainModal:true, modalPush:false})
      })
    }
  }


  blockCustomer(){
    if (this.state.blockReasonId == '') {
      alert('Please, select block reason')
    }else {
      this.setState({loading:true})
      Services.block(this.state.singleFixer.id, this.state.blockReasonId, (res)=>{
        if(res.message == 'forbidden'){
          alert('You Are Not Authorized To Take That Action')
          this.setState({loading:false, mainModal:false, modalBlock:false, blockReason:'Choose'},()=>this.props.reload())
        }else{
          this.setState({loading:false, mainModal:false, modalBlock:false, blockReason:'Choose'},()=>this.props.reload())
        }
      })
    }
  }

  unBlockCustomer(){
    if (this.state.singleFixer.blockReason.enName == 'Collection') {
      alert('You can’t un block fixer because collection reason please, contact with financial team.')
      return true
    }
    this.setState({loading:true})
    Services.unBlock(this.state.singleFixer.id, (res)=>{
      if(res.message == 'forbidden'){
        alert('You Are Not Authorized To Take That Action')
        this.setState({loading:false, mainModal:false, modalunblock:false},()=>this.props.reload())
      }else{
        this.setState({loading:false, mainModal:false, modalunblock:false},()=>this.props.reload())
      }
    })
  }
  getUnAssignedAreas(){
    this.setState({loading:true, modalAssginToArea:true})
    Services.getUnAssignedAreas(this.state.singleFixer.id, (res)=>{
      let arr = res.data.areas
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      console.log(titles);
      this.setState({unAssignedArea:res.data.fixers,multiSelectUnAssignedArea:titles,loading:false})
    })
  }


  handleArray(){
      let arr =[]
      let selectedItems = this.state.multiSelectAssignedArea

      for (var i = 0; i < selectedItems.length; i++) {
        arr.push(selectedItems[i].id)
      }
      this.setState({selectedAreas:selectedItems,newAreasIds:[]})
  }


  assignToNewArea(){
    if (this.state.newAreasIds.length == 0) {
      alert('Please, select an area to assign')
    }else {
      this.setState({loading:true})
      let data = {
        areasIds: this.state.newAreasIds
      }
      Services.assignToNewArea(this.state.singleFixer.id, data, (res)=>{
        this.setState({loading:false, modalAssginToArea:false,newAreasIds:[]},()=>this.getSingleFixer(this.props.data))
      })
    }
  }

  getServiceGroupRelatedToSpecificCategory(){
    this.setState({loading:true})
    Services.getServiceGroupRelatedToSpecificCategory(this.state.singleFixer.category.id, (res)=>{
      let arr = res.data.serviceGroups
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({loading:false,modalActiveFixer:true, serviceGroupsForFixerCategory:titles})
    })
  }

  activeFixer(){
    let isEnglishText = /[\u0600-\u06FF]/;
    if (!this.state.arName.replace(/\s/g, '').length || !isEnglishText.test(this.state.arName)) {
      alert('Please, Enter Arabic Name')
    }else if (!this.state.enName.replace(/\s/g, '').length || isEnglishText.test(this.state.enName)) {
      alert('Please, Enter English Name')
    }else if (this.state.selectedServiceGroupId.length == 0) {
      alert('Please, Select at least one serviceGroups')
    }else{
      let data = {
        arName:this.state.arName,
        enName:this.state.enName,
        serviceGroupsIds:this.state.selectedServiceGroupId
      }
      this.setState({loading:true})
      Services.activeFixer(data, this.state.singleFixer.id, (res)=>{
        this.setState({loading:false,modalActiveFixer:false},()=>this.props.reload())
      })
    }
  }


  handleSingleFixer(item) {
    if (this.state.mainModal !== false) {
      this.setState({mainModal:!this.state.mainModal},()=>this.props.reload())
    }else {
      this.getSingleFixer(item)

    }
  }
  handlePushModal(){
    this.setState({modalPush : !this.state.modalPush,pushTitle:'',pushBody:'',pushURL:''})
  }
  handelSMSModal(){
    this.setState({modalSMS : !this.state.modalSMS,smsTitle:''})
  }
  handelAssignToArea(){
    if (this.state.modalAssginToArea !== false) {
      this.setState({modalAssginToArea : !this.state.modalAssginToArea})
    }else {
      this.getUnAssignedAreas()
      this.handleArray()
    }
  }
  handelActiveFixer(){
    if (this.state.modalActiveFixer !== false) {
      this.setState({modalActiveFixer : !this.state.modalActiveFixer})
    }else {
      this.getServiceGroupRelatedToSpecificCategory()
    }
  }
  handleUnBlockModal(){
    this.setState({modalunblock : !this.state.modalunblock})
  }
  handleBlockModal(){
    if (this.state.modalBlock !== false) {
      this.setState({modalBlock : !this.state.modalBlock,blockReason:'Choose',blockReasonId:'',})
    }else {
      this.getReasons()
    }
  }
  handleBlockReason(item){
    this.setState({blockReason: item.enName, blockReasonId:item.id})
  }

  onSelectedChanged(selectedItems) {
    console.log(selectedItems);

    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    console.log('ahmed')
      this.setState({selectedAreas:selectedItems,newAreasIds:arr},()=>console.log(arr))
  }
  onChangeActiveFixer(e,type,item){
    if (type == "enName") {
      this.setState({enName : e.target.value})
    }else if (type == "arName") {
      this.setState({arName : e.target.value})
    }
  }

  startChat(){
    this.setState({loading:true})
    let data = {
      userId:this.state.singleFixer.id,
    }
    Services.startChat(data,(res)=>{
      this.setState({loading:false},()=>this.props.history.push(`/chat/fixer?userId=${data.userId}`))
    })
  }

  onChangeMultiSelect(itemSelected){
    let arr = []
    for (var i = 0; i < itemSelected.length; i++) {
      arr.push(itemSelected[i].id)
    }
    this.setState({selectedServiceGroup:itemSelected,selectedServiceGroupId:arr})
  }




   addFixerFeedBack(){
     console.log('fixer')
   }





    closeFeedBackModal = () => {
      this.setState({feedBackModal:false});
    }

    insertFeedBack = (fixer) => {
      this.getSingleFixer(fixer);
      this.closeFeedBackModal();
    }


    closeFeedBackHistoryModal = () => {
      this.setState({feedBackHistoryModal:false});
    }

    insertFeedBackHistory = (fixer) => {
      this.getSingleFixer(fixer);
      this.closeFeedBackHistoryModal();
    }



    compareFeedBackFixer(){
      if (this.state.singleFixer.finalFeedback >= 1 &&this.state.singleFixer.finalFeedback <=2.9) {
         this.setState({badgeColor:'danger' , level:'Low'})
      }else if (this.state.singleFixer.finalFeedback >=3 && this.state.singleFixer.finalFeedback <=3.9 ) {
        this.setState({badgeColor:'warning',level:'Medium'})
      }else {
        this.setState({badgeColor:'success',level:'High'})
      }



    }



    handleEntryFixer(){
      this.setState({entryModal: !this.state.entryModal},()=>this.getEntriesSingleFixer(1))
    }

    viewDueFeesDetails(){
      this.setState({showDueFeesModal:true})
    }

    closeDueFeesModal(){
      this.setState({showDueFeesModal:false})
    }


    getEntriesSingleFixer(page){
      this.setState({loading:true,noResult : false})
      Services.getEntriesSingleFixer('desc',page,this.state.singleFixer.financialAccountId,(res)=>{
        this.setState({loading:false,
          entries:res.data.entries,
          previousPages:res.data.paginationLinks.previousPages.reverse(),
          nextPages:res.data.paginationLinks.nextPages,
          currentPage:res.data.paginationInfo.currentPage,
          noResult: res.data.entries.length == 0? true : false
        })
      })
    }



    nextPage(){
      if(this.state.nextPages.length == 0) return;
      this.getEntriesSingleFixer  (this.state.currentPage +1);
    }
    previousPage(){
      if(this.state.previousPages.length == 0) return;
      this.getEntriesSingleFixer  (this.state.currentPage -1);
    }



    noResult(){
        return (
          <div style={{marginTop:20,marginBottom:20,width:'20%',marginRight:'auto',marginLeft:'auto'}}>
            <h1 style={{color:'#eeeeee'}}>No Results</h1>
            <i className="fa fa-frown-o" style={{fontSize:200,color:'#eeeeee'}}/>
          </div>
        )
    }

  renderPushInputs(){
    return(
      <ModalBody>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Title: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter notification title"
              value={this.state.pushTitle}
              onChange={(e)=>this.handleChangeInputs(e, 'pushTitle')}
              maxLength={500}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Body: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={2}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter notification content"
              value={this.state.pushBody}
              onChange={(e)=>this.handleChangeInputs(e, 'pushBody')}
              maxLength={1000}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>URL: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="http://......"
              value={this.state.pushURL}
              onChange={(e)=>this.handleChangeInputs(e, 'pushURL')}
            />
          </Col>
        </Row>
      </ModalBody>
    )
  }


  handleEditFixerImage(){
     this.setState({editFixerImageModal:!this.state.editFixerImageModal})

  }

  reload(){
    this.getSingleFixer(this.state.singleFixer)
  }


  handleFixerHistory(){
    this.setState({fixerHistoryModal:!this.state.fixerHistoryModal})
  }

  editFixerData(){
    this.setState({showEditFixerDataModal:true})
  }

  viewValidWorkContracts(){
    this.setState({showValidWorkContractModal:true})
  }

  closeEditFixerData(){
    this.setState({showEditFixerDataModal:false},()=>this.reload())
  }

  closeViewFixerWorkContractModal(){
    this.setState({showValidWorkContractModal:false})
  }

  submitEditFixerDataRef(){
    this.setState({loading:true},()=>this.editFixerDataRef.submit())
  }


  render() {
    return (
      <div>
        {this.state.showDueFeesModal?(<DueFeesDetails fixer={this.state.singleFixer} onClose={()=>this.closeDueFeesModal()} />):null}
        {/* main modal */}
        <Modal fade={false} isOpen={this.state.mainModal} toggle={()=>this.handleSingleFixer()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleSingleFixer()}>Fixer Details</ModalHeader>
          {this.state.singleFixer!=''?this.renderSingleFixer():null}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleSingleFixer()}>Close</Button>
          </ModalFooter>
        </Modal>


        {/* Push notification */}
        <Modal fade={false} isOpen={this.state.modalPush} toggle={()=>this.handlePushModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handlePushModal()}>Push Notification</ModalHeader>
          {this.renderPushInputs()}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handlePushModal()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.sendPush()}>Send</Button>
          </ModalFooter>
        </Modal>


        {/* SMS Message */}
        <Modal fade={false} isOpen={this.state.modalSMS} toggle={()=>this.handelSMSModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handelSMSModal()}>SMS Message</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="3">
                <p className='titles'>SMS message : </p>
              </Col>
              <Col md="6">
                <textarea
                  wrap="hard" cols={5} rows={2}
                  style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
                  placeholder="Enter content of SMS message.."
                  value={this.state.smsTitle}
                  onChange={(e)=>this.handleChangeInputs(e, 'sms')}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handelSMSModal()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.sendSMS()}>Send</Button>
          </ModalFooter>
        </Modal>


        {/* SMS Message */}
        <Modal fade={false} isOpen={this.state.modalAssginToArea} toggle={()=>this.handelAssignToArea()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handelAssignToArea()}>Assign {this.state.singleFixer.name} to Area(s)</ModalHeader>
          {this.state.singleFixer!=''?this.renderAssignToArea():null}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handelAssignToArea()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.assignToNewArea()}>Submit</Button>
          </ModalFooter>
        </Modal>




        <Modal fade={false} isOpen={this.state.entryModal} toggle={()=>this.handleEntryFixer()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleEntryFixer()}>Entires of ({this.state.singleFixer.name})</ModalHeader>
          <ModalBody>
            {this.state.entries.length != 0 ? (<div> {this.renderEntriesSingleFixer()}
              {this.renderPagination()}</div>) : (this.state.noResult ? this.noResult() : null)}

          </ModalBody>
          {/* {this.renderEntriesSingleFixer()}
          {this.renderPagination()} */}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleEntryFixer()}>Cancel</Button>
          </ModalFooter>
        </Modal>

        {/* unBlock */}
        <Modal fade={false} isOpen={this.state.modalunblock} toggle={()=>this.handleUnBlockModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleUnBlockModal()}>un-Block</ModalHeader>
          <ModalBody>
            <p style={{fontWeight:'bold',fontSize:16}}>Are you sure un-Block this fixer? </p>
          </ModalBody>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleUnBlockModal()}>Cancel</Button>{' '}
            <Button className='actionsBtn' onClick={()=>this.unBlockCustomer()}>Submit</Button>
          </ModalFooter>
        </Modal>


        {/* Block */}
        <Modal fade={false} isOpen={this.state.modalBlock} toggle={()=>this.handleBlockModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleBlockModal()}>Block</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="3">
                <p className='titles'>Reason: </p>
              </Col>
              <Col md="6">
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret  style={{width:'100%', backgroundColor:'#fff'}}>{this.state.blockReason}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.reasons.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleBlockReason(item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleBlockModal()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.blockCustomer()}>Submit</Button>
          </ModalFooter>
        </Modal>


        {/* SMS Message */}
        <Modal fade={false} isOpen={this.state.modalActiveFixer} toggle={()=>this.handelActiveFixer()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handelActiveFixer()}>Active {this.state.singleFixer.arName}</ModalHeader>
          {this.state.singleFixer!=''?this.renderActiveFixer():null}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handelActiveFixer()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.activeFixer()}>Submit</Button>
          </ModalFooter>
        </Modal>


        <FModal
          title='Fixer Image'
          content={
            <FixerImage
              ref={ref => this.fixerImageRef = ref}
              singleFixer={this.state.singleFixer}
              update={()=>this.setState({editFixerImageModal:!this.state.editFixerImageModal},()=>this.reload())}
            />
          }
          state={this.state.editFixerImageModal}
          onClose={()=>this.handleEditFixerImage()}
          onSubmit={()=>this.fixerImageRef.changeFixerImage()}
        />


        <FModal
          title='Fixer History'
          content={
            <FixerHistory
              ref={ref => this.fixerHistoryRef = ref}
              singleFixer={this.state.singleFixer}
            />
          }
          state={this.state.fixerHistoryModal}
          onClose={()=>this.handleFixerHistory()}
          show="none"
        />

        <FModal
          title='Edit Data'
          content={
            <EditFixerData
              singleFixer={this.state.singleFixer}
              ref={ref => this.editFixerDataRef = ref}
                onClose={()=>this.setState({loading:false},()=>this.closeEditFixerData())}
                onError={()=>this.setState({loading:false})}
              />
            }
            state={this.state.showEditFixerDataModal}
            onClose={()=>this.closeEditFixerData()}
            onSubmit={()=>this.submitEditFixerDataRef()}
          />

        <FModal
          title='Valid Fixer Work Contracts'
          content={
              <ViewValidFixerWorkContracts
                singleFixer={this.state.singleFixer}
              />
            }
            state={this.state.showValidWorkContractModal}
            noSubmitBtn={true}
            onClose={()=>this.closeViewFixerWorkContractModal()}
          />

        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>

         {/* FeedBack Createria */}
        {this.state.feedBackModal? <FeedBack  user={this.props.data} insertFeedBack={this.insertFeedBack} closeFeedBackModal={this.closeFeedBackModal}/> : null }
        {/* FeedBack History */}
        {this.state.feedBackHistoryModal? <FeedBackHistory  user={this.props.data} insertFeedBackHistory={this.insertFeedBackHistory} closeFeedBackHistoryModal={this.closeFeedBackHistoryModal}/> : null }


      </div>
    )
  }


  renderEntriesSingleFixer(){
      return (

        <Table hover bordered striped responsive size="md"
        className=" entriesTable allEntries ">
          <thead
            // style={{position:this.state.position?'fixed':'relative',top:55,}}
          >
            <tr className="theadTable">
              <th className="theadId"
                // style={{width:this.state.widthId}}
              >ID</th>
              <th className="theadDate"
                // style={{width:this.state.widthDate}}
              >Date</th>
              <th className="theadDescription"
                // style={{width:this.state.widthDesc}}
              >Description</th>
              <th className="theadParent"
                // style={{width:this.state.widthParties}}
              >
                <Table>
                  <thead>
                    <tr>
                      <th className="childAccount ParentAccount" colSpan={2}>Parents</th>
                    </tr>
                    <tr>
                      <th className="childAccount">Account Id</th>
                      <th className="childAccountName theadCredit">Account Name</th>
                    </tr>
                  </thead>
                </Table>
              </th>
              <th className="theadType"
                // style={{width:this.state.widthDebit}}
              >
                <Table>
                  <thead>
                    <tr className="theadTypeChild">
                      <th className="childAccount" colSpan={2}>Type</th>
                    </tr>
                    <tr>
                      <th className="childAccount">Debit</th>
                      <th className="childAccountName theadCredit">Credit</th>
                    </tr>
                  </thead>
                </Table>
              </th>
              <th className="theadBalance"
                // style={{width:this.state.widthDebit}}
              >
                <Table>
                  <thead>
                    <tr className="theadTypeChild">
                      <th className="childAccount" colSpan={2}>Balance</th>
                    </tr>
                    <tr>
                      <th className="childAccount">Debit</th>
                      <th className="childAccountName theadCredit">Credit</th>
                    </tr>
                  </thead>
                </Table>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.entries.filter( e => e.status.enName == 'Approved' ).map((item, index)=>(
              <tr key={index}>
                {console.log('approved entries',item)}
                <td style={{width:this.state.widthId}} className='borderTopClass'><p className='actionsBtnEntries'>{item.id}</p> </td>
                <td style={{width:this.state.widthDate}} className='borderTopClass'>{item.createdAt}</td>
                <td style={{width:this.state.widthDesc}} className="descriptionRow borderTopClass">{item.description}</td>
                <td>
                  <Table>
                    <tbody>
                      {item.parties.map((item, index)=>(
                        <tr className="tbodyParent" key={index}>
                          <td className="childAccount">{item.accountId}</td>
                          <td className="childAccountName">{item.account.enName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </td>
                <td
                  className="childDebit"
                >
                  <Table>
                    <tbody>
                      {item.parties.map((item, index)=>(
                        <tr key={index}>
                          <td className="debitBody">{item.partyStatusInEntry == 'debit' ? (item.amount).toFixed(2) : ''}</td>
                          <td className="creditBody">{item.partyStatusInEntry == 'credit' ? (item.amount).toFixed(2) : ''}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </td>
                <td className="balanceDebitAndCredit">
                  <Table>
                    <tbody>
                      {item.parties.map((item, index)=>{
                        let totalDebit = item.previousDebit;
                        let totalCredit = item.previousCredit;
                        let balance = 0;
                        if(item.partyStatusInEntry == "debit")
                        {
                          totalDebit = item.previousDebit + item.amount;
                        }
                        else{
                          totalCredit = item.previousCredit + item.amount;
                        }
                        balance = (totalDebit - totalCredit).toFixed(2)
                        let debitBalance = balance > 0 ? balance : 0 ;
                        let creditBalance = balance <= 0 ? Math.abs(balance) : 0;
                        return(
                          <tr key={index}>
                            <td style={{width:'50%'}}>{debitBalance}</td>
                            <td className="creditColumnBalance">{creditBalance}</td>
                          </tr>
          )
        }
        )}
     </tbody>
      </Table>
       </td>
       </tr>
 ))}
</tbody>
 </Table>
)
}


renderPagination(){
    return (
      <div style={{justifyContent:'flex-end' ,marginTop:20}}>
        <Pagination style={{justifyContent:'flex-end'}}>
          <PaginationLink previous tag="button" style={{color:'#4dbd74',}} onClick={()=>this.previousPage()}/>
          {this.state.previousPages.map((page ,index) => (
            <PaginationLink style={{color:'black',}} onClick={()=>this.getEntriesSingleFixer(page)} tag="button" key={index}>
              {page}
            </PaginationLink>
          ))}
          <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
            {this.state.currentPage}
          </PaginationLink>
          {this.state.nextPages.map((page ,index) => (
            <PaginationLink style={{color:'black',}} onClick={()=>this.getEntriesSingleFixer(page)} tag="button" key={index}>
              {page}
            </PaginationLink>
          ))}
          <PaginationLink style={{color:'#4dbd74',}} onClick={()=>this.nextPage()} next tag="button" />
        </Pagination>
      </div>
    )
}


  renderSingleFixer(){
    return(
      <ModalBody>
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.handlePushModal()}>Push <i style={{marginLeft:10}} className="fas fa-bell"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.handelSMSModal()}>SMS<i style={{marginLeft:10}} className="fas fa-sms"></i></Button>
          {this.state.singleFixer.isBlocked=='1'?(
            <Button className='actionsBtn' onClick={()=>this.handleUnBlockModal()}>un-Block<i style={{marginLeft:10}} className="fas fa-check"></i></Button>
          ):(
            <Button className='actionsBtn' onClick={()=>this.handleBlockModal()}>Block<i style={{marginLeft:10}} className="fas fa-stop-circle"></i></Button>
          )}
          <Button className='actionsBtn' onClick={()=>this.handelAssignToArea()}>Assign to Area(s)<i style={{marginLeft:10}} className="fas fa-user-plus"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.startChat()}>Start Chat<i style={{marginLeft:10}} className="fas fa-comment-dots"></i></Button>
          {this.state.singleFixer.status.id != 11?(
            <Button className='actionsBtn' onClick={()=>this.handelActiveFixer()}>Active Fixer<i style={{marginLeft:10}} className="fas fa-user-check"></i></Button>
          ):null}
          <Button className='actionsBtn' onClick={()=>this.setState({feedBackModal:true})}>FeedBack<i style={{marginLeft:10}} className="fas fa-comment-dots"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({feedBackHistoryModal:true})}>FeedBack History<i style={{marginLeft:10}} className="fas fa-user-tie"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.handleEntryFixer()}>Entries<i style={{marginLeft:10}} className="fas fa-user-tie"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.handleEditFixerImage()}>Edit Fixer Image<i style={{marginLeft:10}} className="fas fa-user-tie"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.handleFixerHistory()}>View History<i style={{marginLeft:10}} className="fas fa-eye"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.editFixerData()}>Edit Data<i style={{marginLeft:10}} className="fa fa-pencil"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.viewValidWorkContracts()}>View Valid WorkContracts<i style={{marginLeft:10}} className="fas fa-eye"></i></Button>

        </Row>
        <Row>
          <Col md="6" style={{marginTop:'5%'}}>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixer ID: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.id}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Arabic Name : <span style={{fontWeight:'bold'}}>{this.state.singleFixer.arName}</span></p>
            {this.state.singleFixer.name?(
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>English Name : <span style={{fontWeight:'bold'}}>{this.state.singleFixer.name}</span></p>
            ):null}
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Noteational ID: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.socialSecurityNumber}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixer Age: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.age} </span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixer address: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.address!=null?this.state.singleFixer.address:'Not Available'}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixer status: <Badge className='shadow' color={this.state.singleFixer.status.id != 11?'warning':'success'}>{this.state.singleFixer.status.id!=11?'New':'Active'}</Badge></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixer Rate: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.averageTotalRate} </span></p>
            {this.state.singleFixer.isBlocked=='1'?(
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Block Reason: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.blockReason!=null?this.state.singleFixer.blockReason.enName:''}</span></p>
            ):null}
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Phone Number: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.phone}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Type of Presenter: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.type.enName}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Category: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.category.enName}</span></p>


            {this.state.singleFixer.areas.length!=0?(
              <div>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Area:</p>
                <span style={{display:'block',margin:'-15px 10px 10px 24px'}}>
                  {this.state.singleFixer.areas.map((i, index)=>(
                    <span key={index} style={{fontWeight:'bold'}}>  -  {i.enName}</span>
                  ))}
                </span>
              </div>
            ):null}



            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Join Date: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.createdAt}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Orders Total Price: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.ordersTotalPrice}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Orders Count: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.ordersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Due Fees:
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.viewDueFeesDetails()}>View Details</span>
              {/* <span style={{fontWeight:'bold'}}>{this.state.singleFixer.dueFees}</span> */}
            </p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Paid Fees: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.paidFees==null?'0.00':this.state.singleFixer.paidFees}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Company Percentage: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.companyPercentage}%</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixawy Commission On Orders Total Price: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.fixawyCommissionOnOrdersTotalPrice==null?'0.00':this.state.singleFixer.fixawyCommissionOnOrdersTotalPrice}</span></p>



          </Col>
          <Col md="6">
            <img src={this.state.singleFixer.image!=null?this.state.singleFixer.image:Avatar} className='shadow' style={{borderRadius:'50%',marginBottom: '5%', width:200,height:180,marginTop:'2%'}}/>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixer FeedBack: <Badge className='shadow' style={{padding:7,fontSize:14}} color={this.state.badgeColor}>{this.state.level}</Badge></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of 4 Last Weeks Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.lastFourWeeksOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Done Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.doneOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Inprogress Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.inProgressOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Waiting Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.waitingConfirmationOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Confirmed Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.confirmedOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Failed Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.failedOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Cancel Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.cancelledOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Complains Orders: <span style={{fontWeight:'bold'}}>{this.state.singleFixer.complaintsCount}</span></p>

          </Col>
        </Row>
      </ModalBody>
    )
  }


renderAssignToArea(){
  let assignedAreas = this.state.multiSelectAssignedArea
  let unAssignedArea = this.state.multiSelectUnAssignedArea
  let arrAssignAreaToFixer = assignedAreas.concat(unAssignedArea)
  return(
    <ModalBody>
      <Row>
        <Col md={4}>

        </Col>
        <Col md={5}>
          {arrAssignAreaToFixer.length!=0?(
              <MultiSelect
                items={arrAssignAreaToFixer}
                selectedItems={this.state.selectedAreas}
                onChange={(item)=>this.onSelectedChanged(item)}
                withGrouping={true}
                showSelectedItems={false}
              />
            ):null}
          </Col>
        </Row>
      </ModalBody>
    )
  }


  renderActiveFixer(){
    return (
      <Row style={{padding:'0px 20px'}}>
        <Col md={5}>
          <FormGroup style={{width:'70%',marginLeft:'auto',marginRight:'auto'}}>
            <Label>Arabic Name:</Label>
            <Input
              value={this.state.arName}
              onChange={(e)=>this.onChangeActiveFixer(e,'arName')}
              type="text"
              placeholder="Enter Arabic Name .."
              maxLength={150}
            />
          </FormGroup>
          <FormGroup style={{width:'70%',marginLeft:'auto',marginRight:'auto'}}>
            <Label>English Name:</Label>
            <Input
              value={this.state.description}
              onChange={(e)=>this.onChangeActiveFixer(e,'enName')}
              type="text"
              placeholder="Enter English Name .."
              maxLength={150}
            />
          </FormGroup>
        </Col>
        <Col md={6} className='mulitSelectMaxHeight' style={{marginBottom:50}}>
          <Label>ServiceGroups Related for Fixer Category:</Label>
          <MultiSelect
            items={this.state.serviceGroupsForFixerCategory}
            selectedItems={this.state.service}
            onChange={(item)=>this.onChangeMultiSelect(item)}
            withGrouping={true}
            showSelectedItems={false}
            responsiveHeight={200}
          />
        </Col>
      </Row>
    )
  }


}

export default withRouter(SingleFixer);
