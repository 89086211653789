import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
import { SRLWrapper } from "simple-react-lightbox";

const options = {
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
  }
}
export default class KeywordDetails extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }
  }

  componentDidMount(){

  }

  

  render(){
    return(
      <div>
           <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>En Name: <span style={{fontWeight:'bold'}}>{this.props.details.enName}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Ar Name: <span style={{fontWeight:'bold'}}>{this.props.details.arName}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>En Description: <span style={{fontWeight:'bold'}}>{this.props.details.enDescription}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Ar Description: <span style={{fontWeight:'bold'}}>{this.props.details.arDescription}</span></p>
              {this.props.resources.map((item,index)=>(
                        <div key={index}>
                        <p key={index}><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Keywords: </p>
                          {item.entity.keywords.map((item,index)=>(
                              <ul key={index}>
                                  <li><Row>
                                    <Col md="1">
                                    {item.enName}
                                    </Col>
                                    <Col md="4">
                                    <SRLWrapper options={options}>
                                    <img src={item.image} width={70} height={70}/>
                                    </SRLWrapper>
                                    </Col>
                                    </Row></li>
                              </ul>
                          ))}
                        </div>
              ))}  
      </div>
    )
  }
}
