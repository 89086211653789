import React,{Fragment} from 'react';
import {Col,Row,Label,Input,UncontrolledDropdown,DropdownMenu,DropdownItem,DropdownToggle,Table,Button} from 'reactstrap'
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel} from 'react-accessible-accordion';
import Services from './../../services/Services';
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import { LoadingSpinner } from './../../components'
import {isNumber} from './../../core/helper'

export default class Offer extends React.Component{
  constructor(props){
    super(props)
    this.state={
      cities:[],
      selectedCity:'',
      currentPrice:'',
      priceForCity:[],
      forAllCities:false,
      categories:[],
      selectedCategory:'Category',
      selectedCategoryId:'',
      selectedItem:[],
      itemId:[],
      multiSelectedItems:[],
      suggestItems:[],
      loading:false,
      pictures: [],
      enName:'',
      arName:'',
      imagePreview:'',
      cardItems:[],
      cardItemsIds:[]
    }
  }

  componentDidMount(){
    this.getAllCategories()
    this.getAllCities()
  }

  getAllCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr})
    })
  }
  getAllCities(){
    Services.getCities((res)=>{
      this.setState({cities:res.data.cities})
    })
  }


  getFixawyPricingItems(){
    console.log('getFixawyPricingItems', this.state.allItemsPage);
    this.setState({loading:true})

    Services.getFixawyPricingItems('', this.state.selectedCategoryId, this.state.allItemsPage, (res)=>{
      if (res.message=='success') {
        this.setState({loading:false})
        if (res.data.paginationInfo.lastPage > 1 && this.state.allItemsPage != res.data.paginationInfo.lastPage) {

          let pricingItems = this.state.suggestItems.slice()
          let arr = res.data.pricingItems
          for (var i = 0; i < arr.length; i++) {
            pricingItems.push(arr[i])
          }
          this.setState({allItemsPage:res.data.paginationLinks.nextPages[0], suggestItems:pricingItems},()=>this.getFixawyPricingItems())
        }else if (this.state.allItemsPage == res.data.paginationInfo.lastPage) {
          let pricingItems = this.state.suggestItems.slice()
          let arr = res.data.pricingItems
          for (var i = 0; i < arr.length; i++) {
            pricingItems.push(arr[i])
          }
          this.setState({suggestItems:pricingItems})
        }else {
          this.setState({suggestItems:res.data.pricingItems})
        }
      }
    })
  }

  handleCategory(item){
    if(item.id == 'choose' || item.id == 'all'){
      this.setState({selectedCategory:'Category',selectedCategoryId:''})
    }else{
      this.setState({selectedCategory:item.enName,selectedCategoryId:item.id, cardItems:[],cardItemsIds:[], allItemsPage:1, suggestItems:[]},()=>this.getFixawyPricingItems())
    }
  }
  handleCity(item){
    this.setState({selectedCity:item})
  }

  onSelectedChanged(selectedItems) {
    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({selectedItem:selectedItems, itemId:arr},()=>console.log(arr))
  }

  handleChangeInputs(e,type){
    if(type == 'price'){
      if (isNumber(e.target.value) || e.target.value == 0) {
        this.setState({currentPrice : e.target.value})
      }else {
        alert('Please, enter valid numbers')
      }
    }else if(type == 'enName'){
      this.setState({enName : e.target.value})
    }else if(type == 'arName'){
      this.setState({arName : e.target.value})
    }
  }


  addPriceForCity(){
    if (this.validatePriceForCity()) {
      let priceItem = {};
      priceItem.city = this.state.selectedCity;
      priceItem.price = this.state.currentPrice;
      this.state.priceForCity.push(priceItem)
      this.state.cities = this.state.cities.filter(city => city.id!=priceItem.city.id)
      this.state.selectedCity = ''
      this.state.currentPrice=''
      this.forceUpdate()
    }
  }

  validatePriceForCity(){
    if (this.state.selectedCity == '') {
      alert('please, choose city first')
      return false
    }
    if (this.state.currentPrice == '') {
      alert('please, add price first')
      return false
    }
    return true
  }


  addItemToCard(item, type){
    let cardItems = this.state.cardItems
    let cardItemsIds = this.state.cardItemsIds

    if (cardItems.includes(item) ) {
      alert('Already selected')
    }else {
      cardItems.push(item)
      cardItemsIds.push(item.id)
      this.setState({cardItems,cardItemsIds},()=>this.forceUpdate())
    }

  }

  removeItemFromCard(item){
    let cardItems = this.state.cardItems
    let cardItemsIds = this.state.cardItemsIds

    let items = cardItems.filter(i=> i != item)
    let itemsIds = cardItemsIds.filter(i=> i != item.id)

    this.setState({cardItems:items,cardItemsIds:itemsIds},()=>this.forceUpdate())
  }

  onSelectImageFromPC = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreview: reader.result
      })
    }
    reader.readAsDataURL(file)
  }

  creatOfferPost(type){
    if (this.state.selectedCategoryId == '') {
      alert('Please, choose category')
    }else if (this.state.cardItemsIds.length == 0) {
      alert('Please, choose price items')
    }else if (this.state.forAllCities && this.state.currentPrice.trim() == '') {
      alert('Please, add price for all cities')
    }else if (!this.state.forAllCities && this.state.priceForCity.length == 0) {
      alert('please, at least add price for one city')
    }else if (this.state.enName.trim()=='') {
      alert('Please, enter English title')
    }else if (this.state.arName.trim()=='') {
      alert('Please, enter Arabic title')
    }else if (!this.state.imageFile) {
      alert('Please, select image')
    }else {
      let data = {
        categoryId:this.state.selectedCategoryId,
        pricingItems:this.state.cardItemsIds,
        cities:[],
      }
      if (this.state.forAllCities) {
        let currentPrice = this.state.currentPrice
        this.state.cities.map( city => data.cities.push({id:city.id,price:currentPrice}))
        this.state.priceForCity.map( item => data.cities.push({id:item.city.id,price:currentPrice}) )
      }else{
        this.state.priceForCity.map( item => data.cities.push({id:item.city.id,price:item.price}) )
      }
      console.log(data)
      this.setState({loading:true})
      Services.creatOfferPost(data,(res)=>{
        this.setState({loading:false})
        if(res.message == "The given data was invalid."){
          alert("error")
        }else {
          let formData = new FormData();
          formData.append('type',type);
          formData.append('id',res.data.offer.id);
          formData.append('additionalData[image]',this.state.imageFile)
          formData.append('additionalData[arName]',this.state.arName)
          formData.append('additionalData[enName]',this.state.enName)
          this.props.createPost(formData)
        }
      })
    }
  }


  addPost(type){
    this.creatOfferPost(type)
  }




  render(){
    return(
      <div>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Categories :</Label>
          </Col>
          <Col md="4">
            <UncontrolledDropdown  className="mb-3">
              <DropdownToggle caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}><i className='fas fa-layer-group' style={{marginRight:10}} />{this.state.selectedCategory}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                {this.state.categories.map((item, index)=>(
                  <DropdownItem key={index} onClick={()=>this.handleCategory(item)}>{item.enName}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        {this.state.selectedCategoryId==''?null:(
          <Row>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Items : </Label>
            </Col>
            <Col md="4">

              <Accordion allowZeroExpanded={true}>
                {this.state.suggestItems.map((item, index)=>(
                  item.hasChildren !== 0 ?(
                    <AccordionItem key={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{item.enName}</AccordionItemButton>
                      </AccordionItemHeading>
                      {item.children.map((item, index)=>(
                        <AccordionItemPanel key={index} onClick={()=>this.addItemToCard(item, 'default')}>{item.enName}</AccordionItemPanel>
                      ))}
                    </AccordionItem>
                  ):(
                    <p className='moreItem' key={index} onClick={()=>this.addItemToCard(item, 'default')}>{item.enName}</p>
                  )
                ))}
              </Accordion>
            </Col>
            {this.state.cardItems.length != 0?(
              <Col md="1">
                <Label style={{fontWeight:'bold', textAlign:'center'}}>Selected Items </Label>
              </Col>
            ):null}
            <Col md="4">
              {this.state.cardItems.length !== 0?(
                <Table hover bordered striped responsive size="md" style={{marginTop:-10}}>
                  <thead>
                    <tr>
                      <th style={{textAlign:'center',width:'75%'}}>Item</th>
                      <th style={{textAlign:'center'}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cardItems.map((item, index)=>(
                      <tr key={index}>
                        <td style={{textAlign:'center'}}>{item.enName}</td>
                        <td style={{textAlign:'center'}}>
                          <i className='fa fa-trash' onClick={()=>this.removeItemFromCard(item)} style={{color:'red', cursor:'pointer'}} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ):null}
            </Col>
          </Row>
        )}
        {this.state.multiSelectedItems.length!=0?(
          <Row>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Items :</Label>
            </Col>
            <Col md="4">
              <MultiSelect
                items={this.state.multiSelectedItems}
                selectedItems={this.state.selectedItem}
                onChange={(item)=>this.onSelectedChanged(item)}
                withGrouping={true}
                showSelectedItems={false}
                showSelectAll={true}
              />
            </Col>
          </Row>
        ):null}
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>City : </Label>
          </Col>
          <Col md='3'>
            <UncontrolledDropdown  className="mb-3">
              <DropdownToggle disabled={!this.state.cities.length||this.state.forAllCities} caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}><i className='fas fa-layer-group' style={{marginRight:10}} />{this.state.selectedCity?this.state.selectedCity.enName:'choose'}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                {this.state.cities.map((item, index)=>(
                  <DropdownItem key={index} onClick={()=>this.handleCity(item)}>{item.enName}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col md="3">
            <Input
              value={this.state.currentPrice}
              onChange={(e)=>this.handleChangeInputs(e, 'price')}
              type="text"
              id="price"
              name="price"
              placeholder="Price ..."
              maxLength={10}
            />
          </Col>
          <Col md='2'>
            <Input id='forAll' disabled={!this.state.cities.length} type='checkbox' onClick={() => this.setState((prevState)=>({forAllCities:!prevState.forAllCities,selectedCity:''}))}/>
            <label htmlFor='forAll' >Apply For All Cities</label>
          </Col>
          <Col md='2'>
            <Button disabled={!this.state.cities.length||this.state.forAllCities}  className='actionsBtn' onClick={()=>this.addPriceForCity()} >Add</Button>
          </Col>
        </Row>
        {this.state.priceForCity.length&&(!this.state.forAllCities)?(
        <Row>
          <div style={{width:'50%',marginLeft:'17%'}} >
            <Table hover bordered striped responsive size="md">
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>City</th>
                  <th style={{textAlign:'center'}}>Price</th>
                </tr>
              </thead>
              <tbody>
                {this.state.priceForCity.map( (item, i)=>(
                  <tr key={i}>
                    <td style={{textAlign:'center'}}>{item.city.enName}</td>
                    <td style={{textAlign:'center'}}>{item.price} L.E</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Row>
        ):null}
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Offer En :</Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.enName}
              onChange={(e)=>this.handleChangeInputs(e, 'enName')}
              type="text"
              placeholder="English title.."
              maxLength={150}
            />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Offer Ar :</Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.arName}
              onChange={(e)=>this.handleChangeInputs(e, 'arName')}
              type="text"
              placeholder="Arabic title.."
              maxLength={150}
            />
          </Col>
        </Row>
        <Row style={{marginTop:30}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Images :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={(e)=>this.onSelectImageFromPC(e)}
            />
          </Col>
          <Col md='6'>
            <p><strong>Note: </strong>when insert image must the width double the length or upload this dimensions 362*138</p>
          </Col>
        </Row>
        {this.state.imagePreview != '' ?(
          <Row style={{marginTop:10}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview: </Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
            </Col>
          </Row>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
)
}
}
