import React from 'react';
// import { Row,ModalBody,Table,Pagination,PaginationItem,PaginationLink,Modal , Button} from 'reactstrap'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput} from 'reactstrap';
import QuotationService from './../../../services/quotationServices/QuotationService'
import Avatar from './../../../assets/img/brand/download.png'
import Services from './../../../services/Services'
import LoadingOverlay from 'react-loading-overlay';
import Paginator from './../../../components/Pagination/V2/Paginator'
import QuotationChat from './QuotationChat';
import {FModal, QuotationsHistory} from './../../../components'

export default class Quotation extends React.Component{
  constructor(props){
    super(props);
    this.state={
      noResultQutations:true,
      quotations:[],
      currentPage:1,
      quotationPaginationInfo:[],
      quotationPaginationLinks:[],
      quotationNextPages:[],
      quotationPreviousPages:[],
      nextPages:[],
      previousPages:[],
      loading:false,
      openChat:false,
      selectedQuotation:null,
      statuses:[],
      selectedStatus:'Status',
      selectedStatusId:''
    }
  }
  componentDidMount(){
    this.getQuotations(this.props.inquiryId)
  }

  changePage(page){
    this.setState({currentPage:page})
  }

  openQuotationChat(quotation){
    this.setState({openChat:true,selectedQuotation:quotation})
  }


  getQuotations(inquiryId){
    this.setState({loading:true})
    Services.getQuotations(this.state.quotationPage,inquiryId,(res)=>{
      this.setState({
        quotations:res.data.quotations,
        loading:false,
        quotationPaginationInfo:res.data.paginationInfo,
        quotationPaginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        noResultQutations:res.data.quotations.length==0?false:true,
      },()=>this.getStatuses())
    })
  }

  getStatuses(){
    this.setState({ loading: true });
    QuotationService.getStatuses("quotation",res => {
      this.setState({statuses:res.data.statuses,loading:false})
    });
  }

  handleMenus(type,item){
   if(type == 'status'){
     this.setState({selectedStatus:item.enName,selectedStatusId:item.id})
   }
  }
  changeStatus(){

    this.setState({ loading: true });
    let data = {
      statusId:this.state.selectedStatusId
    }
    QuotationService.changeStatus(data,this.state.selectedQuotation.id,res => {
      this.setState({loading:false,changeStatusModal:false},()=>this.getQuotations(this.props.inquiryId))
    });
  }


  render(){
    return(
      <div>
        {this.state.noResultQutations ?
          <div>
            {this.renderQuotationsModel()}
            <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
          </div>
          :
          <div>
            <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%', textAlign:'center', width:'100%'}}></i>
            <h2 style={{ textAlign:'center'}}>There's no result</h2>
          </div>
        }
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>

        <FModal
          title='Change Status'
          content={
           <div style={{marginLeft:20}}>
             <Row>
               <Col md="2">
                 <Label style={{fontWeight:'bold'}}>Statuses:</Label>
               </Col>
               <Col md="6">
                  <UncontrolledDropdown  className="" style={{marginRight:20}}>
                    <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-question-circle' style={{marginRight:10}} />{this.state.selectedStatus}</DropdownToggle>
                    <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                      {this.state.statuses.map((item, index)=>(
                        <DropdownItem key={index} onClick={()=>this.handleMenus('status',item)}>{item.enName}</DropdownItem>
                      ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
               </Col>
             </Row>
           </div>
          }
          state={this.state.changeStatusModal}
          // onClose={()=>this.orderHistoryModal()}
          onClose={()=>this.setState({changeStatusModal:!this.state.changeStatusModal})}
          onSubmit={()=>this.changeStatus()}
        />

        <FModal
         title='View History'
         content={
           <QuotationsHistory
             // ref={ref => this.qouteChatRef = ref}
             quotation={this.state.selectedQuotation}
             reload={()=>this.setState({historyModal:false,selectedQuotation:null})}
           />
         }
         state={this.state.historyModal}
         onClose={()=>this.setState({historyModal:false})}
         show={"none"}
       />

      </div>
    )
  }

  renderQuotationsModel(){
    return (<>
      {this.state.openChat?(
        <FModal
          title='View Chat'
          content={
            <QuotationChat
              ref={ref => this.qouteChatRef = ref}
              quotation={this.state.selectedQuotation}
              reload={()=>this.setState({openChat:false,selectedQuotation:null})}
            />
          }
          state={this.state.openChat}
          onClose={()=>this.setState({openChat:false,selectedQuotation:null})}
          show={"none"}
        />
      ):null}
      <ModalBody>
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center',}}>Fixer ID</th>
              <th style={{textAlign:'center'}}>Fixer</th>
              <th style={{textAlign:'center'}}>Fixer Category</th>
              <th style={{textAlign:'center'}}>Replay</th>
              <th style={{textAlign:'center'}}>Price</th>
              <th style={{textAlign:'center'}}>Price Type</th>
              <th style={{textAlign:'center'}}>Time</th>
              <th style={{textAlign:'center'}}>Quotation Date</th>
              <th style={{textAlign:'center'}}>Chat</th>
              {/* <th style={{textAlign:'center'}}>Accepted</th> */}
              <th style={{textAlign:'center'}}>Status</th>
              {/* <th style={{textAlign:'center'}}>Change Status</th> */}
              <th style={{textAlign:'center', minWidth:120}}>Change Status<br/><span style={{fontSize:8, fontWeight:500, textAlign:'center'}}>Accepted can't change</span></th>
              <th style={{textAlign:'center'}}>History</th>
            </tr>
          </thead>

          <tbody>
            {this.state.quotations.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>{item.fixerId}</td>
                <td style={{textAlign:'center'}}>{item.fixer.arName}</td>
                <td style={{textAlign:'center'}}>{item.fixer.category.enName}</td>
                <td style={{textAlign:'center'}}>{item.quote}</td>
                <td style={{textAlign:'center'}}>{item.price} LE</td>
                <td style={{textAlign:'center'}}>{item.priceType.enName}</td>
                <td style={{textAlign:'center'}}>{item.time>=24?`${item.time/24} days`: `${item.time} hours`}</td>
                <td style={{textAlign:'center'}}>{item.createdAt}</td>
                <td style={{textAlign:'center'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} disabled={!item.chat} onClick={()=>this.openQuotationChat(item)} >View Chat</Button>
                </td>
                {/* <td style={{textAlign:'center'}}>{item.statusId == 23 ? <i style={{fontSize:24,color:'#4dbd74'}} className="fas fa-check-circle"></i> : ''}</td> */}
                <td style={{textAlign:'center'}}>{item.status.enName}</td>
                <td style={{textAlign:'center'}}>
                  {item.status.enName == 'Accepted'?(
                    // <span> - </span>
                  <i className='fa fa-check' style={{alignSelf:'center', color:'green', fontSize:25}}/>
                  ):(
                    <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.setState({selectedQuotation:item, changeStatusModal:true})}>Change</Button>
                  )}
                </td>
                <td style={{textAlign:'center'}}>
                  <span onClick={()=>this.setState({selectedQuotation:item, historyModal:true})} style={{textDecoration:'underline', cursor:'pointer',color:'rgb(0, 137, 255)'}}>View</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    </>)
  }



}
