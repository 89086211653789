import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import SingleCustomer from './../customers/SingleCustomer'
import {SingleInquiry , FModal, Quotation} from './../../components'
class Inquiries extends Component {
  constructor(props) {
    super(props);
    this.state = {

      currentTab:'17,19',
      inquiries:[],
      inquiryPaginationInfo:[],
      inquiryPaginationLinks:[],
      inquiryNextPages:[],
      inquiryPreviousPages:[],
      singleInquiry:'',
      loading:false,
      currentPage:1,
      noResult:true,
      categories:[],
      statuses:[],
      cities:[],
      selectedCity:'City',
      selectedCityId:'',
      selectedCategory:'Category',
      selectedCategoryId:'',
      selectedStatus:'Status',
      selectedStatusId:'',
      selectedServiceGroup:'Service Groups',
      selectedServiceGroupId:'',
      serviceGroups:[],
      inquiryModal:false,
      singleCustomer:'',
      customerModal:false,
      image:'',
      qutationsModal:false,
      quotations:[],
      inquiryPreviousPages:[],
      inquiryPage:1,
      currentTab:'17,19',
      tabStatus:17,
      inquirySearchEngine:true,
      categoryModal:false,
      category:[],
      imageKeyword:'',
      filterChat:'Choose',
      searchTerm:'',
      searchText:'',
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount(){
    this.getCities()
    window.scrollTo(0, 0)
  }




  reload = () => {
    this.setState({inquiryModal:false,customerModal:false})
    console.log(this.state.currentPage);
    this.getInquiries()
  }


  getSingle(type,item){
    if (type == 'inquiry') {
      this.setState({inquiryModal:!this.state.inquiryModal ,singleInquiry:item,selectedStatusId:'',selectedStatus:'Status'}
      // ,
      // ()=>this.getStatuses()
    )
  }else if (type == 'customer') {
    let obj = {
      id :item.customerId
    }
    this.setState({customerModal:!this.state.customerModal,singleCustomer:obj})
  }
}

handleChangeInputs = (e, handle) => {
  this.setState({searchTerm:e.target.value})
}
handleKeyPress(target) {
  if(target.charCode==13)
  this.setState({currentPage:1},()=>this.getInquiries())
}


getCities(){
  this.setState({loading:true})
  Services.getCities((res)=>{
    let obj = {enName:'All',id:'all'}
    let choose = {enName:'Choose',id:'choose'}
    let arr = res.data.cities
    arr.push(obj)
    arr.unshift(choose)
    this.setState({cities:arr},()=>this.getCategories())
  })
}

getCategories(type){
  Services.getCategories((res)=>{
    let obj = {enName:'All',id:'all',children:[]}
    let choose = {enName:'Choose',id:'choose',children:[]}
    let arr = res.data.categories
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].children.length != 0) {
        for (var j = 0; j < arr[i].children.length; j++) {
          arr.push(arr[i].children[j])
        }
        arr.splice(i,1)
      }
    }
    arr.push(obj)
    arr.unshift(choose)
    this.setState({categories:arr},()=>this.getStatuses())
  })
}


getStatuses(typeStatus){
  Services.getStatuses('inquiry',(res)=>{
    if (typeStatus == 'ForChangeStatus') {
      let arr = res.data.statuses
      this.setState({changeStatusesArr:arr})
    }else {
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.statuses
      arr.push(obj)
      arr.unshift(choose)
      this.setState({statuses:arr},()=>this.getAllServiceGroups())
    }
  })
}

getInquiries(type){
  let api = ''
  if (type == 'filter') api = this.filter('filter')
  else api = this.filter()

  if (type == 'filter' && api == `${Global.baseURL}admin-panel/inquiries?page=${this.state.inquiryPage}`) {
    alert('Please, choose filter')
    return true
  }

  if (this.state.searchTerm != '') {
    this.setState({currentPage:1})
    api+=`&searchTerm=${this.state.searchTerm}`
  }

  this.setState({loading:true,})

  Services.getInquiries(api,(res)=>{
    this.setState({
      inquiries:res.data.inquiries,
      inquiryPaginationInfo:res.data.paginationInfo,
      inquiryPaginationLinks:res.data.paginationLinks,
      inquiryNextPages:res.data.paginationLinks.nextPages,
      inquiryPreviousPages:res.data.paginationLinks.previousPages.reverse(),
      inquiryPage:1,
      loading:false,
      searchText:this.state.searchTerm!=''?this.state.searchTerm:'',
    },()=>this.convertToOrderCountInInquiry())
  })
}


getAllServiceGroups(){
  this.setState({loading : true})
  Services.getAllServiceGroups((res)=>{
    let arr = res.data.serviceGroups
    let titles = []
    for (var i = 0; i < arr.length; i++) {
      let obj ={label:arr[i].enName, id: arr[i].id}
      titles.push(obj)
    }
    this.setState({serviceGroups : titles , loading:false},()=>this.getInquiries())
  })
}




inquiryPrevPage(item){
  let page = this.state.inquiryPage
  if (item == '') {
    this.setState({inquiryPage: page-1},()=>this.getInquiries())
  }else {
    this.setState({inquiryPage: item},()=>this.getInquiries())
  }
}
inquiryNextPage(item){
  let page = this.state.inquiryPage
  if (item == '') {
    this.setState({inquiryPage: page+1},()=>this.getInquiries())
  }else {
    this.setState({inquiryPage: item},()=>this.getInquiries())
  }
}

handleMenus(menu, item,currentStatus,typeStatus){
  switch (menu) {
    case 'city':
    if (item.id == 'choose' || item.id == 'all') {
      this.setState({selectedCity: 'City', selectedCityId: ''})
    }else {
      this.setState({selectedCity: item.enName, selectedCityId: item.id})
    }
    break
    case 'category':
    if (item.id == 'choose' || item.id == 'all') {
      this.setState({selectedCategory: 'Category', selectedCategoryId: ''})
    }else {
      this.setState({selectedCategory: item.enName, selectedCategoryId: item.id})
    }
    break
    case 'statuses':
    if (typeStatus == 'ForChangeStatus') {
      if (currentStatus) {
        this.setState({selectedStatus: currentStatus, selectedStatusId: ''})
      }else {
        this.setState({selectedStatus: item.enName, selectedStatusId: item.id})
      }
    }else{
      if (item.id == 'choose' || item.id == 'all') {
        this.setState({selectedStatus: 'Status', selectedStatusId: ''})
      }else {
        this.setState({selectedStatus: item.enName, selectedStatusId: item.id})
      }
    }
    break
    case 'serviceGroup':
    if (item.id == 'choose' || item.id == 'all') {
      this.setState({selectedServiceGroup: 'Service Groups', selectedServiceGroupId: ''})
    }else {
      this.setState({selectedServiceGroup: item.label, selectedServiceGroupId: item.id})
    }
    break
    default:
  }
}

resetFilter(){
  this.setState({
    selectedCity: 'City',
    selectedCityId: '',
    selectedStatus: 'Status',
    selectedStatusId: '',
    selectedCategory: 'Category',
    selectedCategoryId: '',
    selectedServiceGroup: 'Service Groups',
    selectedServiceGroupId: '',
    currentPage:1,
    quotationPage:1,
    convertPage:1,
    inquiryPage:1,
    inquiries:[],
    openAndCloseArr:[],
    convertArr:[],
    filterChat:'Choose'
  },()=>this.getInquiries())
}

filter(type){
  let api = ''
  if (type =='filter') {
    console.log(1);
    api = `${Global.baseURL}admin-panel/inquiries?status=${this.state.currentTab}&page=${this.state.inquiryPage}`
  }else {
    api = `${Global.baseURL}admin-panel/inquiries?status=${this.state.currentTab}&page=${this.state.inquiryPage}`

  }
  console.log(api);
  // return


  if (this.state.selectedCityId != '') {
    api+=`&cityId=${this.state.selectedCityId}`
  }
  if (this.state.selectedCategoryId != '') {
    api+=`&categoryId=${this.state.selectedCategoryId}`
  }
  if (this.state.selectedStatusId != '') {
    api+=`&status=${this.state.selectedStatusId}`
  }
  if (this.state.selectedStatusId != '') {
    api+=`&status=${this.state.selectedStatusId}`
  }
  if (this.state.selectedServiceGroupId != '' ) {
    api+=`&serviceGroupId=${this.state.selectedServiceGroupId}`
  }
  if (this.state.filterChat == 'Has Chat' ) {
    api+=`&chat=1`
  }



  return api
}

handleQuotationsModal(inquiryId){
  if (this.state.qutationsModal == false) {
    this.setState({qutationsModal : !this.state.qutationsModal,inquiryId:inquiryId}
      // ,
      // ()=>{
      // this.getQuotations(inquiryId)}
      )
  }else {
    this.setState({qutationsModal : !this.state.qutationsModal,inquiryId:inquiryId})
  }
}

handleInquiryTab(index){
  if (index == 18) {
    this.setState({currentTab : '18', tabStatus:index},()=>this.resetFilter())
  }else if (index == 17) {
    this.setState({currentTab : '17,19', tabStatus:index},()=>this.resetFilter())
  }

}


convertToOrderCountInInquiry(){
  Services.convertToOrderCountInInquiry(18,(res)=>{
    this.setState({countConvertedToOrder : res.data.count})
  })
}


handleCategory(category){
  console.log(category)
  this.setState({categoryModal : !this.state.categoryModal , category : category})
}

render() {
  return (
    <div className="app align-items-center">
      <Container>
        {this.renderFilter()}
        <Card>
          {/* search input */}
          {this.renderTabInquiry()}
          {/* render all orders */}
          {this.state.noResult?
            <CardBody className='shadow'>
              {this.renderInquiries()}
              {this.renderInquiryPagination()}
              <FModal
              title={"Inquiry Details Of : " + this.state.singleInquiry.id}
              content={
                <SingleInquiry
                  ref={ref=>this.singleInquiryRef=ref}
                  inquiry={this.state.singleInquiry}
                  inquiries={this.state.inquiries}
                  handleQuotationsModal={()=>this.singleInquiryRef.handleQuotationsModal(this.state.inquiryId)}
                  reload={this.reload}
                />
              }
              state={this.state.inquiryModal}
              onClose={()=>this.getSingle('inquiry',this.state.singleInquiry)}
              show={"none"}
            />

              <Modal fade={false} isOpen={this.state.categoryModal} toggle={()=>this.handleCategory(this.state.category)} className={this.props.className}>
                <ModalHeader toggle={()=>this.handleCategory(this.state.category)}>Categories</ModalHeader>
                {this.renderCategoryModal()}
                <ModalFooter>
                  <Button className='actionsBtn' onClick={()=>this.handleCategory(this.state.category)}>Close</Button>
                </ModalFooter>
              </Modal>
              <FModal
              title="Quotations"
              content={
                <Quotation
                  ref={ref=>this.quotationRef=ref}
                  inquiryId={this.state.inquiryId}
                  reload={this.reload}
                />
              }
              state={this.state.qutationsModal}
              onClose={()=>this.handleQuotationsModal(this.state.inquiryId)}
              show={"none"}
            />

              {this.state.customerModal?<SingleCustomer data={this.state.singleCustomer} reload={this.reload}/>:null}
            </CardBody>
            :(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
            </Modal>
          </Card>
        </Container>
      </div>
    )
  }

  renderTabInquiry(){
    return (
      <CardHeader className='shadow'>
        <Row className="rowTabReservation">
          <Col md="4" className="tabButton">
            <Button onClick={()=>this.handleInquiryTab(17)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tabStatus==17?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Open/Close</Button>
          </Col>
          <Col md="4" className="tabButton">
            <Button onClick={()=>this.handleInquiryTab(18)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tabStatus==18?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Converted To Order   <span style={{backgroundColor:this.state.tabStatus==17?'#3a4248':'#feb500',color:'#fff',borderRadius:10,padding:6}}>{this.state.countConvertedToOrder}</span></Button>
          </Col>
          {this.renderSearch()}
        </Row>
      </CardHeader>
    )
  }

  renderSearch(){
    return(
      <Col  style={{margin:'auto'}}>
        <InputGroup>
          <Input
            value={this.state.searchTerm}
            onChange={(e)=>this.handleChangeInputs(e, 'search')}
            type="text"
            id="searchTerm"
            name="searchTerm"
            placeholder="By inquiry id, customer name and phone...."
            maxLength={50}
            onKeyPress={this.handleKeyPress}
          />
          <InputGroupAddon addonType="prepend">
            <Button onClick={()=>this.setState({currentPage:1},()=>this.getInquiries())} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
          </InputGroupAddon>
        </InputGroup>
      </Col>
    )
  }


  renderInquiries(){
    return(
      <div>
        {this.state.searchText!=''?(
          <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
            <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
            <h6 style={{fontSize:18}}>Search Results of
              <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

            </h6>
            <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getInquiries())}>
                Clear Search
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </span>
            </div>
          ):null}
        <Table hover bordered striped responsive size="md">

          {this.renderInquiryHeader()}
          <tbody>
            {this.state.inquiries.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>
                <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', maxWidth:100}} onClick={()=>this.getSingle('inquiry',item)}>{item.serial}</Button>
                {item.keyword != null ? <p style={{color:'white',fontSize:10,fontWeight:'bold',backgroundColor:'#20a8d8',borderRadius:10,width:'50%',margin:'auto',padding:0,marginTop:7}}>S.E</p> : ''}
                </td>
                <td style={{textAlign:'center'}}><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', maxWidth:100}} onClick={()=>this.getSingle('customer',item)}>{item.customer.enName}</Button></td>
                <td style={{textAlign:'center'}}>{item.area.city.enName}</td>
                <td style={{textAlign:'center'}}>{item.area.enName}</td>
                <td style={{textAlign:'center'}}><Button className="someSpace" style={{cursor:'pointer',backgroundColor:'transparent',borderColor:'#262626',fontSize:12,fontWeight:'bold',borderRadius:0,borderWidth:1,color:'#262626'}} onClick={()=>this.handleCategory(item.categories)}>View Categories</Button></td>
                <td style={{textAlign:'center'}}>
                  <Badge className='shadow truncate' color='success' id={'statusTooltip'+item.id} style={{direction:item.description.match(/[\u0600-\u06FF]/)?'rtl':'ltr'}}>{item.description}</Badge>
                  <UncontrolledTooltip target={'statusTooltip'+item.id}>{item.description}</UncontrolledTooltip>
                </td>
                <td style={{textAlign:'center'}}>{item.serviceGroup != null ? item.serviceGroup.enName : 'N/A' }</td>
                <td style={{textAlign:'center'}} ><Badge className='shadow' color={item.status.id==17?'warning':item.status.id==19?'danger':'success'}>{item.status.enName}</Badge></td>
                <td style={{textAlign:'center'}}>{item.createdAt}</td>
                <td style={{textAlign:'center'}}><Button style={{padding:0,backgroundColor:'transparent',borderColor:'transparent'}} onClick={()=>this.handleQuotationsModal(item.id)}><span className="badge badge-dark" style={{fontSize:14}}>{item.quotationsCount}</span></Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    )
  }

  renderInquiryHeader(){
    return (
      <thead>
        <tr>
          <th style={{textAlign:'center',width:'5%'}}>Inquiry Serial</th>
          <th style={{textAlign:'center'}}>Customer</th>
          <th style={{textAlign:'center'}}>City</th>
          <th style={{textAlign:'center'}}>Area</th>
          <th style={{textAlign:'center'}}>Category</th>
          <th style={{textAlign:'center'}}>Description</th>
          <th style={{textAlign:'center'}}>Order Types</th>
          <th style={{textAlign:'center'}}>Status</th>
          <th style={{textAlign:'center'}}>Creation Date</th>
          <th style={{textAlign:'center'}}>Quotations Number</th>
        </tr>
      </thead>
    )
  }

  renderInquiryPagination(){
    return(
      <nav>
        <Row className='pull-right'>
          <Pagination style={{justifyContent:'flex-end'}}>
            {/* previous link*/}
            <PaginationItem>
              <PaginationLink previous tag="button" onClick={()=>this.state.inquiryPaginationInfo.prevPageUrl!=null?this.inquiryPrevPage(''):null}/>
            </PaginationItem>
            {/* previousPages */}
            {this.state.inquiryPreviousPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.inquiryPrevPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}
            {/* currentPage */}
            <PaginationItem active>
              <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                {this.state.inquiryPaginationInfo.currentPage}
              </PaginationLink>
            </PaginationItem>
            {/* nextPages */}
            {this.state.inquiryNextPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.inquiryNextPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}
            {/* nextPageUrl */}
            <PaginationItem>
              <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.inquiryPaginationInfo.nextPageUrl!=null?this.inquiryNextPage(''):null} />
            </PaginationItem>
          </Pagination>
        </Row>
      </nav>
    )
  }




  renderFilter(){
    return(
      <Row>
        <UncontrolledDropdown  className="mb-3">
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />{this.state.selectedCity}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.cities.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('city',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}} >
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />{this.state.selectedCategory}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.categories.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('category',item)}>{item.enName}</DropdownItem>
            )
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      {this.state.tabStatus == 17 ? (
        <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />{this.state.selectedStatus}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.statuses.map((item, index)=>(
              <DropdownItem key={index} disabled={item.id == 18 ? true : false} onClick={()=>this.handleMenus('statuses',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ):null}
      <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}}>
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-user-shield' style={{marginRight:10}} />{this.state.selectedServiceGroup}</DropdownToggle>
        <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          {this.state.serviceGroups.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>this.handleMenus('serviceGroup',item)}>{item.label}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fa fa-commenting-o' style={{marginRight:10}} />{this.state.filterChat!='Choose'?this.state.filterChat:'Chat'}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            <DropdownItem onClick={()=>this.setState({filterChat:'Choose'})}>Choose</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterChat:'Has Chat'})}>Orders has chat</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      <Col md={1}>
        <Button onClick={()=>this.getInquiries('filter')} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
      </Col>
      <Col md={1}>
        <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'white'}}>Reset</Button>
      </Col>
    </Row>
  )
}



renderCategoryModal(){
  return (
    <ModalBody>
    <Table hover bordered striped responsive size="md">
      <thead>
        <tr>
          <th style={{textAlign:'center',}}>ID</th>
          <th style={{textAlign:'center'}}>Category Name</th>
        </tr>
      </thead>
      <tbody>
      {this.state.category.map((item,index)=>(
        <tr key={index}>
          <td style={{textAlign:'center'}}>{item.id}</td>
          <td style={{textAlign:'center'}}>{item.enName}</td>
        </tr>
      ))}
      </tbody>
    </Table>
    </ModalBody>
  )
}


}

export default withRouter(Inquiries);
