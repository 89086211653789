import React from 'react'
import { DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown } from 'reactstrap'
import Services from './../../services/Services';
import QuotationService from './../../services/quotationServices/QuotationService'
class QuotationStatusFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedStatus: '',
      statuses: [],
    }
  }
  componentDidMount() {
    this.getStatuses()
  }
  selectStatus(status) {
    if (status.id == 'choose') {
      this.clear()
    } else {
      this.setState({ selectedStatus: status }, () => this.props.onStatusChange(status))
    }
  }
  clear() {
    this.setState({ selectedStatus: '' })
  }
  getStatuses() {
    QuotationService.getStatuses("quotation", res => {
      let obj = { enName: 'All', id: 'all' }
      let choose = { enName: 'Choose', id: 'choose' }
      let arr = res.data.statuses
      arr.push(obj)
      arr.unshift(choose)
      this.setState({ statuses: arr })
    });
  }

  render() {
    return (
      <UncontrolledDropdown className="" style={{ marginRight: 20 }}>
        <DropdownToggle caret className='actionsBtn' style={{ width: '100%' }}><i className='fas fa-thermometer-quarter' style={{ marginRight: 10 }} />{this.state.selectedStatus ? this.state.selectedStatus.enName : 'Status'}</DropdownToggle>
        <DropdownMenu style={{ width: '100%' }}>
          {this.state.statuses.map((item, index) => (
            <DropdownItem key={index} onClick={() => this.selectStatus(item)}>{item.enName}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}
export default QuotationStatusFilter;
