import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Dropdown ,DropdownItem ,DropdownMenu ,DropdownToggle ,Modal ,ModalBody ,ModalFooter ,ModalHeader, UncontrolledDropdown  } from 'reactstrap';

import { withRouter } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import OrderRequestsService from './../../../services/orderServices/OrderRequestsService'
import Services from './../../../services/Services'
import {FModal,ViewHistoryOrderRequest} from './../../../components'
import ConfirmDeleteMessage from './../../ConfirmDeleteMessage'
export default class OrderRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      request:'',
      customer:'',
      fixer:'',
      area:'',
      category:'',
      city:'',
      status:'',
      period:'',
      historyModal:false,
      orderRequestActions:[],
      acceptRequestModal:false,
      rejectRequestModal:false
    }
  }
  componentDidMount(){
    console.log(this.props.request)
    window.scrollTo(0, 0)
    this.getOrderRequest()
  }


  getOrderRequest(){
    this.setState({loading:true})
    OrderRequestsService.getOrderRequest(this.props.request,(res)=>{
      this.setState({
        customer:res.data.orderRequest.customer,
        fixer:res.data.orderRequest.fixer,
        status:res.data.orderRequest.status,
        category:res.data.orderRequest.category,
        city:res.data.orderRequest.city,
        area:res.data.orderRequest.area,
        period:res.data.orderRequest.period,
        request:res.data.orderRequest,
        orderRequestActions:res.data.orderRequest.orderRequestActions,
        loading:false
      })
    })
  }

  handleModals(button){
    if(button == 1){
      this.setState({historyModal:!this.state.history})
    }else if (button == 2){
      this.setState({acceptRequestModal:!this.state.acceptRequestModal})
    }else if (button == 3){
      this.setState({rejectRequestModal:!this.state.rejectRequestModal})
    }
  }


  acceptRequest(){
    this.setState({loading:true})
    OrderRequestsService.acceptRequest(this.props.request,(res)=>{
      this.setState({loading:false,acceptRequestModal:false},()=>this.props.update())
    })
  }

  rejectRequest(){
    this.setState({loading:true})
    OrderRequestsService.rejectRequest(this.props.request,(res)=>{
      this.setState({loading:false,rejectRequestModal:false},()=>this.props.update())
    })
  }

  



  render() {
    return (
      <div style={{padding:'0px 20px'}}>
        <Container>
          <Card style={{borderWidth:0}}>
            <Row>
              {this.state.status.enName == "Open" ? this.renderHeaderButtons() : this.renderHeaderButtonsWithoutStatus()}
            </Row>

            <Row>
              <Col>
                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Request Id: <span style={{fontWeight:'bold'}}>{this.state.request.id}</span> </span></p>


                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Customer Name: <span style={{fontWeight:'bold'}}>{this.state.customer.enName}</span> </span></p>


                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Fixer Name: <span style={{fontWeight:'bold'}}>{this.state.fixer.enName}</span> </span></p>

                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Date: <span style={{fontWeight:'bold'}}>{this.state.request.requestedDate}</span> </span></p>

                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Category: <span style={{fontWeight:'bold'}}>{this.state.category.enName}</span> </span></p>

                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>City: <span style={{fontWeight:'bold'}}>{this.state.city.enName}</span> </span></p>

                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Area: <span style={{fontWeight:'bold'}}>{this.state.area.enName}</span> </span></p>

                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Period: <span style={{fontWeight:'bold'}}>{this.state.period.enName}</span> </span></p>

                <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Created Date: <span style={{fontWeight:'bold'}}>{this.state.request.createdAt}</span> </span></p>

              </Col>
            </Row>

            <FModal
              title='History'
              content={
                <ViewHistoryOrderRequest
                  ref={ref=> this.viewHistoryRef = ref}
                  orderRequestActions={this.state.orderRequestActions}
                />
              }
              state={this.state.historyModal}
              onClose={()=>this.setState({historyModal:false})}
              show={"none"}
            />

            <ConfirmDeleteMessage message={"Are you accepted for request order ?"} confirm={()=>this.acceptRequest()} show={this.state.acceptRequestModal} close={()=>this.setState({acceptRequestModal:false})}/>
            <ConfirmDeleteMessage message={"Are you rejected for request order ?"} confirm={()=>this.rejectRequest()} show={this.state.rejectRequestModal} close={()=>this.setState({rejectRequestModal:false})}/>

            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
            </Modal>
          </Card>
        </Container>
      </div>
    )

  }

  renderHeaderButtons(){
    const buttons = [
      {id : 1 , title : 'View History',icon:<i className="fas fa-eye"></i>},
      {id : 2 , title : 'Accept',icon:''},
      {id : 3 , title : 'Reject',icon:''},
    ]
    return(
      <div style={{marginBottom:20,marginLeft:10}}>
        {buttons.map((button,index)=>(
          <Button key={index} className='actionsBtn' onClick={()=>this.handleModals(button.id)}>{button.title}{"         "}{button.icon}</Button>
        ))}
      </div>
    )
  }

  renderHeaderButtonsWithoutStatus(){
    const buttons = [
      {id : 1 , title : 'View History',icon:<i className="fas fa-eye"></i>},
    ]
    return(
      <div style={{marginBottom:20,marginLeft:10}}>
        {buttons.map((button,index)=>(
          <Button key={index} className='actionsBtn' onClick={()=>this.handleModals(button.id)}>{button.title}{"         "}{button.icon}</Button>
        ))}
      </div>
    )
  }


}
