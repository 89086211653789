import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class PriceOffersService {

  static get(page, callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/price-offer?page=${page}`).then(callBack)
  }

  static getPerOrder(id, callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/orders/${id}/price-offers`).then(callBack)
  }

}
