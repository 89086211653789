import React from 'react'
import {Input,InputGroup,Button,Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import TimePicker from 'react-time-picker'
import EmergencyService from './../../services/emergencyServices/EmergencyService'
import Services from './../../services/Services'
import LoadingSpinner from './../../components/LoadingSpinner';
class EmergencyModal extends React.Component{
  constructor(props){
    super(props)
    this.state={
      loading:false,
      startTime:'',
      endTime:'',
    }
  }
  clear(){
    this.setState({startTime:'',endTime:'',secret:''})
  }
  isValidRequset(statusId){
    if(statusId){
      return true
    }
    if(this.state.startTime==''){
      alert('Please, enter a valid start time ')
    }else if(this.state.endTime==''){
      alert('Please, enter a valid end time ')
    }else if(this.state.startTime >= this.state.endTime){
      alert('Invalid times , start time must be less than end time')
    }else{
      return true
    }
    return false
  }
  confirmEmergency(statusId,id){
    if(this.isValidRequset(statusId)){
      this.setState({loading:true})
      EmergencyService.togglePhoneNumber(id, statusId, this.state.startTime, this.state.endTime,(res)=>{
        if(res.message == 'forbidden'){
          alert('You Are Not Authorized To Take That Action')
        }
        this.setState({loading:false})
        this.clear()
        this.props.closeHandler()
      })
    }
  }

  render(){
    return(
      <>
      <Modal fade={false} isOpen={this.props.show} toggle={()=>{this.clear();this.props.toggleHandler()}} className={this.props.className}>
        <ModalHeader toggle={()=>{this.clear();this.props.toggleHandler()}}>Toggle Phone Number</ModalHeader>
        <ModalBody>
          <div style={{paddingBottom:'2px'}}>
            <div style={{padding:'15px',paddingBottom:'0px',paddingTop:'0px'}}><strong>Phone Number</strong></div>
          </div>
          <div style={{paddingBottom:'10px'}}>
            <div style={{padding:'15px'}}>{this.props.number.phone}</div>
          </div>
          <div style={{padding:'10px'}}>
            {!this.props.number.statusId?
              <InputGroup style={{marginBottom:'20px'}}>
                <div style={{marginBottom:'10px'}}>
                  <strong>Start Time</strong>
                </div>
                <InputGroup>
                  <TimePicker style={{padding:'10px'}} onChange={(time)=>this.setState({startTime:time})}
                    clearIcon={null}
                    value={this.state.startTime}/>
                  </InputGroup>
                  <div style={{marginBottom:'10px',marginTop:'10px'}}>
                    <strong>End Time</strong>
                  </div>
                  <InputGroup>
                    <TimePicker style={{padding:'10px'}} onChange={(time)=>this.setState({endTime:time})}
                      clearIcon={null}
                      value={this.state.endTime}/>
                    </InputGroup>

                  </InputGroup>
                  :null}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className='actionsBtn' onClick={()=>{this.clear();this.props.closeHandler()}}>Close</Button>
                <Button className='actionsBtn' onClick={()=>this.confirmEmergency(this.props.number.statusId,this.props.number.id)}>{!this.props.number.statusId?'Turn On':'Turn Off'}</Button>
              </ModalFooter>
            </Modal>
            <LoadingSpinner fade loading={this.state.loading}/>
          </>
        )
      }
    }
    export default EmergencyModal;
