import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Input} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Repeate extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount(){

  }


  repeate(){
    this.setState({loading:true})
    Services.changeStatusToRepeated(this.props.order.orderId,(res)=>{
      this.setState({loading: false, },()=>this.props.reload())
    })
  }


  render(){
    return(
      <div>
        <h6 style={{padding:'0px 15px'}}>Are you sure change status this order to repeated ?</h6>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
