import React from "react"
import firebase from 'firebase/app'
import 'firebase/database'
import { Container, CardHeader, Card, Row, Col, Button, CardBody} from "reactstrap"
import AddPromotedKeyword from "./AddPromotedKeyword"
import { Table, TableHeader, TH, TableBody, TR, TD, ListCategories, DisplayImageModal, FModal, LoadingSpinner, NoResult } from "../../components"
import Paginator from './../../components/Pagination/V2/Paginator'
import KeywordsServices from "../../services/searchEngineServices/KeywordsServices"
import Services from './../../services/Services'
import PasswordModal from "../../components/Modals/PasswordModal"

class SearchEngine extends React.Component{
  constructor(props){
    super(props)
    this.state={
      noResult:false,
      firebasePass:'',
      showPassModal:false,
      data:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      addPromotedKey:false,
      displayCategoriesModal:false,
      displayImagePreview:false,
      img:null,
      categories:[],
      modalCategories:false,
    }
  }
  componentDidMount(){
    this.getAllKeyWords()
    this.getFirebaseKey()
  }
  addPromotedKey(){
    this.setState({showPassModal:false,addPromotedKey:true})
  }
  closeAddPromotedKey(){
    this.setState({addPromotedKey:false},()=>this.reload())
  }
  displayImagePreview(img){
    this.setState({previewImage:true,img:img})
  }
  closeImagePreview(){
    this.setState({previewImage:false,img:null})
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.reload())
  }
  reload(){
    this.getAllKeyWords()
  }
  closePasswordModal(){
    this.setState({showPassModal:false})
  }
  authorizeAction(){
    this.setState({showPassModal:false,addPromotedKey:true})
  }
  getAllKeyWords(){
    this.setState({loading:true})
    KeywordsServices.get(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        data:res.data.keywords,
        noResult:!res.data.keywords.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
      })
    })
  }
  getFirebaseKey(){
    firebase.database().ref('general/').on('value', (snapshot) => {
      if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
            Object.keys(snapshot.val()).forEach( (key) => {
              let val = snapshot.val()[key]
              if(key == "keywords"){
                console.log(val)
                this.setState({firebasePass:val})
              }
            })
          }
        })
  }
  render(){
    return(
      <div className="app align-items-center">
        <AddPromotedKeyword show={this.state.addPromotedKey} closeHandler={()=>this.closeAddPromotedKey()} />
        <PasswordModal isOpen={this.state.showPassModal} firebasePass={this.state.firebasePass} closeHandler={()=>this.closePasswordModal()} submitAction={()=>this.authorizeAction()} />
        <Container>
          <Card>
            <CardHeader className='shadow'>
              <Row>
                <Col md="8" style={{display:'flex',alignItems:'center'}}>
                  <i className="fa fa-align-justify" ></i>
                  <span style={{marginLeft:6}}>Search Engine Promoted Keys</span>
                </Col>
                <Col md='4' >
                  <Button className="actionsBtn" style={{marginBottom: "2%",display: "block",marginLeft: "auto"}}
                    onClick={()=>this.addPromotedKey()}>
                    <i className="fa fa-plus-square" style={{ marginRight: 8 }} />
                    Add Promoted Key
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className='shadow'>
              {this.state.noResult?(<NoResult/>):(
                <Table>
                  <TableHeader>
                    <TH>ID</TH>
                    <TH>Name</TH>
                    <TH>Arabic Name</TH>
                    <TH>Categories</TH>
                    <TH>Image</TH>
                    <TH>Created Date</TH>
                    <TH>Hits</TH>
                  </TableHeader>
                  <TableBody>
                    {this.state.data.map((item,index)=>(
                      <TR key={index}>
                        <TD>{item.id}</TD>
                        <TD>{item.enName}</TD>
                        <TD>{item.arName}</TD>
                        <TD><span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({modalCategories:true, categories:item.categories })}>View Categories</span></TD>
                        <TD>
                          <div style={{boxShadow:'2px black',cursor:'pointer'}} onClick={()=>this.displayImagePreview(item.image)}>
                            <img src={item.image} className='keywordItem' style={{height:'80px',width:'160px'}}/>
                          </div>
                        </TD>
                        <TD>{item.createdAt}</TD>
                        <TD>{item.hits}</TD>
                      </TR>
                    ))}
                  </TableBody>
                </Table>
              )}
              <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
            </CardBody>
          </Card>
        </Container>

        <FModal
          title='Categories'
          content={this.renderCategories()}
          state={this.state.modalCategories}
          onClose={()=>this.setState({modalCategories:false, categories:[]})}
          noSubmitBtn={true}
        />
        <FModal
          state={this.state.previewImage}
          title='Image Preview'
          onClose={()=>this.closeImagePreview()}
          content={this.renderImagePreview()}
          noSubmitBtn={true} />
        <LoadingSpinner loading={this.state.loading} />
      </div>
    )
  }
  renderCategories(){
    return(
      <Table>
        <TableHeader>
          <TH>ID</TH>
          <TH>Category Name</TH>
        </TableHeader>
        <TableBody>
          {this.state.categories.map((item,index)=>(
            <TR key={index}>
              <TD>{item.id}</TD>
              <TD>{item.enName}</TD>
            </TR>
          ))}
        </TableBody>
      </Table>
    )
  }
  renderImagePreview(){
    return (
      <div className='keywordItem'>
        <img src={this.state.img} style={{width:'100%',height:'80%'}} />
      </div>
    )
  }

}
export default SearchEngine;
