import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class ChargeWalletService {

  static charge(data,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/temporary-wallet-entry`,data).then(callBack)
  }

}
