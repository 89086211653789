import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'

export default class PromoCodeDetails extends React.Component{
  constructor(props){
    super(props)
    this.state={
    }
  }

  componentDidMount(){
  }

  

  render(){
    return(
      <div>
                    <div style={{marginTop:10}}> 
                         <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>En Name: <span style={{fontWeight:'bold'}}>{this.props.details.enName}</span></p>
                         <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Ar Name: <span style={{fontWeight:'bold'}}>{this.props.details.arName}</span></p>
                    </div>
              {this.props.resources.map((item,index)=>(
                        <div key={index}>
                        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Promo Code: <span style={{fontWeight:'bold'}}>{item.entity.code}</span></p>
                        <p key={index}><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Categories: </p>
                          {item.entity.categories.map((item,index)=>(
                              <ul key={index}>
                                  <li>{item.enName}</li>
                              </ul>
                          ))}
                        </div>
              ))}
                    <div style={{marginTop:10}}> 
                              <div style={{width:300}}>
                              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Image:</p>
                              <img src={this.props.details.image} width="80%" height={300} style={{marginLeft:10}}/> 
                              </div>
                    </div>
      </div>
    )
  }
}
