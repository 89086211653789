import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Dropdown ,DropdownItem ,DropdownMenu ,DropdownToggle ,Modal ,ModalBody ,ModalFooter ,ModalHeader, UncontrolledDropdown  } from 'reactstrap';

import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import {FModal,AddNoteComplaint,AssignAdminComplaint,UpdateTags,ViewHistory} from './../../components'

import SingleCustomer from './../customers/SingleCustomer'
import SingleOrder from './../orders/SingleOrder'
import { CreateOrderModel } from './../../components';

class Complain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complain:'',
      customer:'',
      orderId:'',
      statuses:[],
      reply:'',
      selectedStatus:'Choose',
      selectedStatusId:'',
      loading:false,
      complainModal:false,
      showSingleCustomer:false,
      showSingleOrder:false,
      showCreateOrder:false,
      assignAdminModal:false,
      addNoteModal:false,
      showSatisfaction:false,
      selectedIsSatisfed:'Choose',
      satisfied:-1,
      updateTagsModal:false,
      historyModal:false,
      updateTagsModal:false,
      historyModal:false,
      showSatisfaction:false,
      selectedIsSatisfed:'Choose',
      satisfied:-1
    }
    console.log(this.props.match.params.id)
  }
  componentDidMount(){
    this.getStatus()
    window.scrollTo(0, 0)
  }

  handleComplainModal() {
    this.setState({complainModal:!this.state.complainModal})
  }
  showSingleCustomer(){
    this.setState({showSingleCustomer:true,showSingleOrder:false})
  }
  showSingleOrder(){
    this.setState({showSingleOrder:true,showSingleCustomer:false})
  }

  getStatus(){
    this.setState({loading:true})
    Services.getComplainStatus((res)=>{
      let obj = {id:'',enName:'Choose'}
      let arr = res.data.statuses
      arr.unshift(obj)
      this.setState({ statuses:arr},()=>this.getComplain())
    })
  }

  getComplain(){
    Services.getComplain(this.props.match.params.id, (res)=>{
      let obj = {id:res.data.complaint.orderId}
      this.setState({
        complain:res.data.complaint,
        customer: res.data.complaint.customer,
        orderId:obj,
      loading:false,complainModal: true })
    })
  }

    sendReply(type){
      let reqData = {}
      if (type == 'reply') {
        if (this.state.reply=='' || !this.state.reply.replace(/\s/g, '').length) {
          alert('Please, enter reply')
            return true
        }else {
          reqData.reply = this.state.reply
        }
      }else {
        if (this.state.selectedStatusId=='') {
          alert('Please, select status')
            return true
        }else {
          if (this.state.showSatisfaction && this.state.satisfied == -1) {
            alert('Please, select satisfaction level ')
            return true
          }else if (this.state.satisfied != -1) {
            reqData.satisfied = this.state.satisfied
          }
          reqData.statusId = this.state.selectedStatusId
        }

      }

      console.log(reqData);
      this.setState({loading:true})
      Services.sendReply(reqData, this.state.complain.id,(res)=>{
        this.setState({
          satisfied:-1,
          showSatisfaction:false,
          selectedIsSatisfed:'Choose'
        })
        this.setState({reply:'', selectedStatusId:'', selectedStatus:'Choose'},()=>{this.getComplain();this.refresh()})
      })
    }

    refresh(){
      this.getStatus()
    }


    handleMenu(item, type){
      console.log(item);
      if (type == 'changeStatus') {
        this.setState({
          selectedStatus:item.enName, selectedStatusId:item.id,
          showSatisfaction:item.enName == 'Closed'?true:false,
          disabledChangeStatus:item.enName == 'Closed'?true:false
        })
        if (item.enName != 'Closed') {
            this.setState({
              satisfied:-1,
              showSatisfaction:false,
              selectedIsSatisfed:'Choose'
            })
        }
      }else {
        if (item == 'Choose') {
          this.setState({disabledChangeStatus:true, selectedIsSatisfed:item, satisfied:-1})
        }else {
          this.setState({
            disabledChangeStatus:false, selectedIsSatisfed:item,
            satisfied:item=='Satisfed'?1:0
          })
        }
      }
    }

    handleChangeInputs = (e) => {
      this.setState({reply:e.target.value})
    }


    handleModals(button){
     if(button == 1){
       console.log('addnote')
       this.setState({addNoteModal:!this.state.addNoteModal})
     }else if(button == 2){
      console.log('assingAdmin')
      this.setState({assignAdminModal:!this.state.assignAdminModal})
     }else if(button == 4){
      this.setState({updateTagsModal:!this.state.updateTagsModal})
     }else if(button == 3){
      this.setState({historyModal:!this.state.historyModal})
    }else if (button == 5) {
      this.setState({showCreateOrder:!this.state.showCreateOrder})
    }
  }


    refresh(){
      window.location.reload()
    }

    reload =()=>{
      this.setState({showSingleCustomer:false,showSingleOrder:false})
    }

    render() {
      return (
        <div className="app align-items-center">
          <Container>
            <Card>
              {this.state.showSingleCustomer?<SingleCustomer data={this.state.customer} reload={this.reload}/>:null}
              {this.state.showSingleOrder?<SingleOrder data={this.state.orderId} reload={this.reload}/>:null}
              {this.state.showCreateOrder?<CreateOrderModel data={this.state.complain} show={this.state.showCreateOrder} closeHandler={()=>this.setState({showCreateOrder:false})}  />:null}


              {this.state.complain != '' ? this.renderComplain() : null}
              <FModal
                title='Add Note'
                content={
                  <AddNoteComplaint
                    ref={ref=> this.addNoteComplaintRef = ref}
                    complaintId={this.state.complain.id}
                    close={()=>this.setState({addNoteModal:false},()=>this.refresh())}
                  />
                }
                state={this.state.addNoteModal}
                onClose={()=>this.setState({addNoteModal:false})}
                onSubmit={()=>this.addNoteComplaintRef.addNoteToComplaint()}
              />
              <FModal
                title='Assign Admin'
                content={
                  <AssignAdminComplaint
                    ref={ref=> this.assignAdminComplaintRef = ref}
                    complaintId={this.state.complain.id}
                    close={()=>this.setState({assignAdminModal:false},()=>this.refresh())}
                  />
                }
                state={this.state.assignAdminModal}
                onClose={()=>this.setState({assignAdminModal:false})}
                onSubmit={()=>this.assignAdminComplaintRef.assignComplaintToAdmin()}
              />


              <FModal
                title='Update Tags'
                content={
                  <UpdateTags
                    ref={ref=> this.updateTagsRef = ref}
                    complaint={this.state.complain}
                    close={()=>this.setState({updateTagsModal:false},()=>this.refresh())}
                  />
                }
                state={this.state.updateTagsModal}
                onClose={()=>this.setState({updateTagsModal:false})}
                onSubmit={()=>this.updateTagsRef.updateTagsComplaint()}
              />
              <FModal
                title='History'
                content={
                  <ViewHistory
                    ref={ref=> this.viewHistoryRef = ref}
                    complaint={this.state.complain}
                    close={()=>this.setState({historyModal:false},()=>this.refresh())}
                  />
                }
                state={this.state.historyModal}
                onClose={()=>this.setState({historyModal:false})}
                show={"none"}
              />
              {/* </CardBody> */}

              {/* loading spinner */}
              <Modal fade={false} isOpen={this.state.loading} className='loading'>
                <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
              </Modal>
            </Card>
          </Container>
        </div>
      )
    }

    renderHeaderButtons(){
      const buttons = [
        {id : 1 , title:'Add Note',icon:<i className="fas fa-edit"></i>},
        {id : 2 , title : 'Assign Complaint To Admin',icon:<i className="fas fa-plus-circle"></i>},
        {id : 4 , title : 'Update Tags',icon:<i className="fas fa-plus-circle"></i>},
        {id : 3 , title : 'View History',icon:<i className="fas fa-eye"></i>},
        {id : 5 , title : 'Create Order',icon:<i className="fas fa-check-square"></i>}
      ]
      return(
        <div style={{marginBottom:20,marginLeft:10}}>
          {buttons.map((button,index)=>(
            <Button key={index} className='actionsBtn' onClick={()=>this.handleModals(button.id)}>{button.title}{"         "}{button.icon}</Button>
          ))}
        </div>
      )
    }



    renderComplain(){
      return(
        <ModalBody>
          <Row>
            {/* <Col> */}
            {this.renderHeaderButtons()}
            {/* </Col> */}
          </Row>

          <Row>
            <Col>
              {/* id */}
              <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Complain Id: <span style={{fontWeight:'bold'}}>{this.state.complain.id}</span> </span></p>

              {/* admin name */}
              <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Admin Name: <span style={{fontWeight:'bold'}}>{this.state.complain.admin == null ? 'N/A' : this.state.complain.admin.enName}</span> </span></p>

              {/* customer */}
              <p style={{marginTop:12,width:'100%'}}>
                <i className="icon-arrow-right-circle" style={{marginRight:6}}></i>Customer Name:
                <Button onClick={()=>this.showSingleCustomer()} className="click-text" style={{padding:'0px 7px',backgroundColor:'transparent',borderColor:'transparent',fontWeight:'bold'}}>{this.state.complain.customer.enName}</Button>
              </p>

              {/* order */}
              <p style={{marginTop:12,width:'100%'}}>
                <i className="icon-arrow-right-circle" style={{marginRight:6}}></i>Order Serial :
                <Button onClick={()=>this.showSingleOrder()} className="click-text" style={{padding:'0px 7px',backgroundColor:'transparent',borderColor:'transparent',fontWeight:'bold'}}> {this.state.complain.order.serial} </Button>
              </p>

              {/* description */}
              <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Description: <span style={{fontWeight:'bold'}}>{this.state.complain.description}</span> </span></p>

              {/* createAt */}
              <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>creation Date: <span style={{fontWeight:'bold'}}>{this.state.complain.createAt}</span> </span></p>

              {/* state */}
              <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>status: <span style={{fontWeight:'bold'}}>{this.state.complain.status.enName}</span> </span></p>

              {this.state.complain.satisfied != null?(
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}>satisfied:
                    <span style={{fontWeight:'bold'}}>{
                      this.state.complain.satisfied== 0 ?
                        <i className="fas fa-angry" style={{fontSize:20, marginLeft:10,color:'#ff0000'}}></i> :
                        <i className="fas fa-laugh-beam" style={{fontSize:20,marginLeft:10,color:'#31cc36'}}></i>
                    }</span>
                  </span>
                </p>
              ):null}

              <p>
                <i className="icon-arrow-right-circle"></i>
                <span style={{marginLeft:6}}>Tags: </span>
                {this.state.complain.tags.map((e,k)=>(
                  <span key={k} style={{marginRight:10, color:'#0089ff', textDecoration:'underline'}}>#{e.enName}</span>
                ))}
              </p>




              {/* Trello */}
              <a target="_blank" href={this.state.complain.trelloCardLink}>
                <span className='trelloCardLink'><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6, fontWeight:'bold'}}> Trello ID</span></span>
              </a>


              {/* update */}
              <Row style={{padding:'5% 0 2% 4%'}}>

                <Col md='6' >
                  <Row>
                    <Col md="9" style={{paddingRight: 0,paddingLeft:0}}>
                    <textarea
                      style={{width:'100%',padding:7,borderColor:'#777777',borderWidth:1,borderRadius:5,height:150,resize:'none'}}
                      value={this.state.reply}
                      onChange={(e)=>this.handleChangeInputs(e)}
                      placeholder="Enter reply.."
                    />
                    </Col>
                    <Col md="3" style={{paddingRight: 0,paddingLeft:0}}>
                    <Button className='actionsBtn' disabled={this.state.complain.status.id == 16?true:false} onClick={()=>this.sendReply('reply')}>Reply</Button>
                    </Col>
                  </Row>
                </Col>
                <Col md='6' >
                  <Row>
                    <UncontrolledDropdown style={{marginBottom:0,width:'55%'}}>
                      <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777', padding:10}}>{this.state.selectedStatus}</DropdownToggle>
                      <DropdownMenu style={{width:'100%'}}>
                        {this.state.statuses.map((item, index)=>(
                          <DropdownItem key={index} onClick={()=>this.handleMenu(item, 'changeStatus')}>{item.enName}</DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Button
                      className='actionsBtn'
                      onClick={()=>this.sendReply('changeStatus')}
                      disabled={this.state.disabledChangeStatus}
                      style={{backgroundColor:this.state.disabledChangeStatus?'#ededed':'#4dbd74'}}
                    >
                      Change Status
                    </Button>

                  </Row>
                  {this.state.showSatisfaction?(
                    <Row style={{marginTop:10}}>
                      <UncontrolledDropdown style={{marginBottom:0,width:'55%'}}>
                        <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777', padding:10}}>{this.state.selectedIsSatisfed}</DropdownToggle>
                        <DropdownMenu style={{width:'100%'}}>
                          <DropdownItem onClick={()=>this.handleMenu('Choose', 'isSatisfed')}>Choose</DropdownItem>
                          <DropdownItem onClick={()=>this.handleMenu('Satisfed', 'isSatisfed')}>Satisfed</DropdownItem>
                          <DropdownItem onClick={()=>this.handleMenu('Not Satisfed', 'isSatisfed')}>Not Satisfed</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Row>
                  ):null}


                </Col>
              </Row>

              {/* history */}
              {this.state.complain.complaintActions.length != 0?(
                <div>
                  <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Complain History: </span></p>
                  <div style={{padding:'0px 5%'}}>
                    {this.state.complain.complaintActions.map((item, index)=>(
                      <Row key={index}>
                        <span className='shadow reply' style={{backgroundColor:item.userType.id==1?'#0e78e6':'#d2d6da',color:item.userType.id==1?'#fff':'#000'}}>
                          {item.user.name}
                          <br/><span style={{fontSize:16, fontWeight:'bold'}}>{item.reply}</span><br/>
                          <span style={{fontSize:11}}>{item.createdAt}</span>
                        </span>
                      </Row>
                    ))}
                  </div>
                </div>
              ):null}


              {this.state.complain.orderComplaintImages.length != 0?(
                <div >
                  <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Order Image:</span></p>
                  <Row>
                    {this.state.complain.orderComplaintImages.map((item, index)=>(
                      <Col key={item.id} md="4" style={{marginBottom:8}}>
                        <img className='shadow' src={item.image} style={{width:'100%', height:200,}} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ):null}
            </Col>
          </Row>

        </ModalBody>
      )
    }





  }

  export default withRouter(Complain);
