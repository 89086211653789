import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, Row ,Table ,CardHeader ,FormGroup ,Modal ,ModalBody ,ModalFooter ,ModalHeader,CustomInput ,Label,PaginationItem ,PaginationLink ,Pagination,DropdownToggle,DropdownItem,DropdownMenu,UncontrolledDropdown  } from 'reactstrap';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import {FixawyButton} from './../../components'


class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      modalAddPackage : false,
      serviceGroups:[],
      duration:'',
      arName:'',
      description:'',
      conditions:[],
      service:[],
      condition:[],
      typeId:1,
      selectedServiceIds:[],
      selectedConditionsIds:[],
      packages:[],
      conditionDetails:[],
      modalConditionDetails:false,
      paginationInfo:[],
      paginationLinks:[],
      types:[{id:1 , type:'Hours'} ,{id:2 , type:'Days'}],
      nextPages:[],
      previousPages:[],
      selectedDuration:'Choose',
      selectedDurationId:''
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    this.getAllPackages()

  }



  handleAddPackage(type,item){
    if(type == 'duplicate'){
      console.log(item)
      this.handleDuplicatePackages(item)
    }else{
      if (this.state.modalAddPackage) {
        this.setState({modalAddPackage:!this.state.modalAddPackage,service:[],condition:[],arName:'',description:'',selectedDuration:'Choose',selectedDurationId:'', duration:''})
      }else {
        this.setState({modalAddPackage : !this.state.modalAddPackage})
      }
    }
  }


  handleDuplicatePackages(item){
    let conditions = item.conditions
    let conditionsTitle = []
    for (var i = 0; i < conditions.length; i++) {
      let obj ={label:conditions[i].description, id: conditions[i].id}
      conditionsTitle.push(obj)
    }

    let serviceGroups = item.serviceGroups
    let servicGroupsTitle = []
    for (var i = 0; i < serviceGroups.length; i++) {
      let obj ={label:serviceGroups[i].description, id: serviceGroups[i].id}
      servicGroupsTitle.push(obj)
    }

    let durationType = this.state.types
    for (var i = 0; i < durationType.length; i++) {
      console.log(durationType[i])
    }
    this.setState({
      modalAddPackage:!this.state.modalAddPackage,
      duration : item.duration,
      arName : item.arName,
      description:item.description,
      service:servicGroupsTitle,
      condition:conditionsTitle,
      selectedConditionsIds:item.conditions.map(item => item.id),
      selectedServiceIds:item.serviceGroups.map(item => item.id),
      selectedDuration:item.duration > 24 ? durationType[0].type : durationType[1].type,
      selectedDurationId:item.duration > 24 ? durationType[0].id : durationType[1].id
    })
  }


  onChangeAddPackages(e,type,item){
      const re = /^[0-9\b]+$/;
       if (type == "description") {
         this.setState({description : e.target.value})
       }else if (type == "duration") {
        // this.setState({duration : e.target.value})
        if (e.target.value === '' ||re.test(e.target.value)) {
          this.setState({duration : e.target.value})
        }
       }else if (type == "arName") {
         this.setState({arName : e.target.value})
       }
     }


  addPackages(){
    console.log(this.state.arName.length > 100);

    if (this.state.arName == "" || this.state.arName.trim() == "") {
      alert('Please, enter package name')
    }else if (this.state.arName.length > 100) {
      alert('Package name is more than 100 letters')
    }else if (this.state.description == "" || this.state.description.trim() == "") {
      alert('Please, enter description for package')
    }else if (this.state.description > 5000) {
      alert('Description for package is more than 5000')
    }else if (this.state.selectedServiceIds.length == 0) {
      alert('Please, choose service groups')
    }else if (this.state.selectedConditionsIds.length == 0) {
      alert('Please, choose conditions')
    }else {
      let durationValue = ''
      durationValue = this.state.selectedDurationId == 1? this.state.duration : this.state.duration * 24
      if (durationValue == 0 || this.state.selectedDurationId == '') {
        alert('Please, enter duration period')
        return
      }

      let data = {
       duration : durationValue,
       arName : this.state.arName,
       description:this.state.description,
       serviceGroups:this.state.selectedServiceIds,
       conditions:this.state.selectedConditionsIds
     }
      this.setState({loading: true})
      Services.addPackages(data,(res)=>{
        if (res.message == 'success') {
          this.setState({description:'', arName:'', modalAddPackage:false, service:[],condition:[],currentPage:1,selectedDuration:'Choose',selectedDurationId:'', duration:''},()=>this.forceUpdate())
          this.getAllPackages()
        }else {
          alert('please add package')
        }
        this.setState({loading: false})
      })
    }
  }


  getAllServiceGroups(){
    this.setState({loading : true})
    Services.getAllServiceGroups((res)=>{
      let arr = res.data.serviceGroups
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({serviceGroups : titles , loading:false},()=>this.getConditions())
    })
  }



  getConditions(){
    this.setState({loading : true})
    Services.getConditions(this.state.typeId,(res)=>{
      let arr = res.data.conditionsAndTerms
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].description, id: arr[i].id}
        titles.push(obj)
      }
      console.log(titles)
      this.setState({conditions : titles , loading:false})
    })
  }



  onChangeAddPackagesServiceGroup(itemSelected){
    let arr = []
    for (var i = 0; i < itemSelected.length; i++) {
      arr.push(itemSelected[i].id)
    }
    this.setState({service : itemSelected,selectedServiceIds:arr})
  }



  onChangeAddPackagesCondition(itemSelected){
    let arr = []
    for (var i = 0; i < itemSelected.length; i++) {
      arr.push(itemSelected[i].id)
    }
    this.setState({condition : itemSelected,selectedConditionsIds:arr})
  }



  getAllPackages(){
    this.setState({loading : true})
    Services.getAllPackages(this.state.currentPage,(res)=>{
      this.setState({
        packages : res.data.packages ,
        paginationInfo:res.data.paginationInfo,
        paginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages.reverse(),
        nextPages:res.data.paginationLinks.nextPages,
        loading:false,
      },()=>this.getAllServiceGroups())
    })
  }



  prevPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page-1},()=>this.getAllPackages())
    }else {
      this.setState({currentPage: item},()=>this.getAllPackages())
    }
  }
  nextPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page+1},()=>this.getAllPackages())
    }else {
      this.setState({currentPage: item},()=>this.getAllPackages())
    }
  }






  viewConditionsDetails(item){
    console.log(item)
    this.setState({conditionDetails: item},()=>this.toggleConditionDetails())
  }

  toggleConditionDetails(){
    this.setState({modalConditionDetails: !this.state.modalConditionDetails})
  }


  handleTypesDuration(item){
      console.log(item.id)
      if (item.id == 1){
        this.setState({selectedDuration:item.type , selectedDurationId:item.id, duration:''})
      }else if(item.id == 2) {
        this.setState({selectedDuration:item.type , selectedDurationId:item.id, duration:''})
      }
  }



  render(){
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            <CardHeader className='shadow'>
              {this.renderHeaderAddPackage()}
            </CardHeader>
            <CardBody>
              <Modal fade={false} isOpen={this.state.modalAddPackage} toggle={()=>this.handleAddPackage()} className={this.props.className}>
                <ModalHeader toggle={()=>this.handleAddPackage()}>Add Packages</ModalHeader>
                <ModalBody>
                  {this.renderBodyModal()}
                </ModalBody>
                <ModalFooter>
                  <Button className='actionsBtn' onClick={()=>this.handleAddPackage()}>Close</Button>
                  <Button className='actionsBtn' onClick={()=>this.addPackages()}>Add</Button>
                </ModalFooter>
              </Modal>
              {this.viewModalDetails()}
              {this.renderAllPackages()}
              {this.renderPagination()}
            </CardBody>

            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
            </Modal>
          </Card>
        </Container>
      </div>
    )
  }


  renderHeaderAddPackage(){
    return (
      <Row>
        <Col md="4" style={{display:'flex',alignItems:'center'}}>
          <i className="fa fa-align-justify" ></i>
          <span style={{marginLeft:6}}>Packages</span>
        </Col>
        <Button className='actionsBtn' style={{marginTop:1,marginBottom:-1, marginLeft:'auto'}} onClick={()=>this.handleAddPackage()}><i className='fa fa-plus-square' style={{marginRight:8}}/>  Add Packages</Button>
      </Row>
    )
  }


  renderBodyModal(){
    return (
      <Form>
        <FormGroup style={{width:'70%',marginLeft:'auto',marginRight:'auto'}}>
          <Label>Package Name:</Label>
          <Input
            value={this.state.arName}
            onChange={(e)=>this.onChangeAddPackages(e,'arName')}
            type="text"
            placeholder="Enter name.."
            maxLength={100}
          />
        </FormGroup>
        <FormGroup style={{width:'70%',marginLeft:'auto',marginRight:'auto'}}>
          <Label>Package Description:</Label>
          <Input
            rows={5}
            value={this.state.description}
            onChange={(e)=>this.onChangeAddPackages(e,'description')}
            type="textarea"
            placeholder="Enter description.."
            maxLength={5000}
            className="textareaconditions"
          />
        </FormGroup>
        <FormGroup style={{width:'70%',marginLeft:'auto',marginRight:'auto'}}>
          <Label>Package Duration:</Label>
          <Row>
            <Col md="6">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret  style={{width:'100%', backgroundColor:'#fff'}}>{this.state.selectedDuration}</DropdownToggle>
            <DropdownMenu style={{width:'100%'}}>
              {this.state.types.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleTypesDuration(item)}>{item.type}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col md="6">
          <Input
            value={this.state.duration}
            disabled={this.state.selectedDurationId ? false : true}
            onChange={(e)=>this.onChangeAddPackages(e,'duration')}
            type="text"
            placeholder={this.state.selectedDuration}

          />

        </Col>
        </Row>
        </FormGroup>
        <FormGroup style={{width:'70%',marginLeft:'auto',marginRight:'auto'}}>
          <Row>
            <Col md="6">
              <Label>Service Groups:</Label>
              <MultiSelect
                items={this.state.serviceGroups}
                selectedItems={this.state.service}
                onChange={(item)=>this.onChangeAddPackagesServiceGroup(item)}
                withGrouping={true}
                showSelectedItems={false}
              />
            </Col>
            <Col md="6">
              <Label>Conditions:</Label>
              <MultiSelect
                items={this.state.conditions}
                selectedItems={this.state.condition}
                onChange={(item)=>this.onChangeAddPackagesCondition(item)}
                withGrouping={true}
                showSelectedItems={false}
              />
            </Col>
          </Row>
        </FormGroup>
      </Form>
    )
  }


  viewModalDetails(){
    return (
      <Modal fade={false} isOpen={this.state.modalConditionDetails} toggle={()=>this.toggleConditionDetails()} className={this.props.className}>
        <ModalHeader toggle={()=>this.toggleConditionDetails()}>Condition Details</ModalHeader>
        <ModalBody>
          <Table hover bordered striped responsive size="md" className="tableConditionsDetails">
            <thead>
              <tr>
                <th style={{textAlign:'center'}}>Id</th>
                <th style={{textAlign:'center'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              {this.state.conditionDetails.map((item,index)=>(
                <tr key={index}>
                  <td style={{textAlign:'center'}}>{item.id}</td>
                  <td style={{textAlign:'center'}}>{item.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn' onClick={()=>this.toggleConditionDetails()}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderAllPackages(){
    return (
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center',width:94.8}}>ID</th>
            <th style={{textAlign:'center',width:142.2}}>Name</th>
            <th style={{textAlign:'center',width:142.2}}>Created At</th>
            <th style={{textAlign:'center',maxWidth:237}}>Description</th>
            <th style={{textAlign:'center',width:142.2}}>Service Groups</th>
            <th style={{textAlign:'center',width:142.2}}>Conditions</th>
            <th style={{textAlign:'center',width:47.4}}>Duration</th>
            <th style={{textAlign:'center',width:47.4}}>Duplicate</th>

          </tr>
        </thead>
        <tbody>
          {this.state.packages.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center',width:94.8}}>{item.id}</td>
              <td style={{textAlign:'center',width:142.2}}>{item.arName}</td>
              <td style={{textAlign:'center',width:142.2}}>{item.createdAt}</td>
              <td style={{textAlign:'center',maxWidth:237}}>{item.description}</td>
              <td style={{textAlign:'left',width:142.2}}>
                <ul className="listServiceGroups" style={{marginBottom:0}}>
                  {item.serviceGroups.map((item,index)=>(
                    <li key={index}>{item.enName}</li>
                  ))}
                </ul>
              </td>
              <td style={{textAlign:'center',width:142.2}}>
                <FixawyButton disabled={false} onClick={()=>this.viewConditionsDetails(item.conditions)}>Details</FixawyButton>
              </td>
              <td style={{textAlign:'center',width:47.4}}>{item.duration>=24?`${item.duration/24} days`: `${item.duration} hours`}</td>
              <td style={{textAlign:'center',width:47.4}}>
                <FixawyButton disabled={false} onClick={()=>this.handleAddPackage('duplicate',item)}>Duplicate <i className='fa fa-clone' /></FixawyButton>
              </td>

            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  renderPagination(){
    return(
      <nav>
        <Row className='pull-right'>
          <Pagination style={{justifyContent:'flex-end'}}>
            {/* previous link*/}
            <PaginationItem>
              <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
            </PaginationItem>

            {/* previousPages */}
            {this.state.previousPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* currentPage */}
            <PaginationItem active>
              <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                {this.state.paginationInfo.currentPage}
              </PaginationLink>
            </PaginationItem>


            {/* nextPages */}
            {this.state.nextPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* nextPageUrl */}
            <PaginationItem>
              <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
            </PaginationItem>
          </Pagination>
        </Row>
      </nav>
    )
  }





}

export default withRouter(Packages);
