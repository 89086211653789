import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import Switch from "react-switch";
import firebase from 'firebase/app'
import 'firebase/database'
import QuotationService from './../../services/quotationServices/QuotationService'
import Paginator from './../../components/Pagination/V2/Paginator'
import { FModal, QuotationStatusFilter, SingleInquiry, QuotationsHistory } from './../../components'
import QuotationChat from '../../components/inquiry/singleInquiry/QuotationChat';
import SingleFixer from './../fixers/SingleFixer'
// import { TRUE } from 'node-sass';
class Quotations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:false,
      previousPages:[],
      nextPages:[],
      currentPage:1,
      noResult:false,
      quotations:[],
      changeStatusModal:false,
      searchTerm:'',
      statuses:[],
      selectedStatus:'Status',
      selectedStatusId:'',
      searchText:'',
      selectedQuotation:'',
      showQuotationChat:false,
      selectedStatusesArr:[],
      singleInquiry:'',
      inquiryModal:false

    }
    this.handleKeyPress = this.handleKeyPress.bind(this)

  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.getQuotations()
  }

  handleKeyPress(target) {
    if(target.charCode==13)
    this.setState({currentPage:1},()=>this.getQuotations())
  }

  handleChangeInputs(e){
   this.setState({searchTerm:e.target.value})
  }
  reload = () => {
    this.setState({fixerModal:false})
    this.getQuotations()
  }

  getQuotations(){
    this.setState({ loading: true });
    let data = {
      statuses:this.state.selectedStatusesArr,
    };
    QuotationService.getQuotations(this.state.currentPage,this.state.searchTerm,data,res => {
      this.setState({
        loading:false,
        quotations:res.data.quotations,
        noResult:!res.data.quotations.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage,
        searchText:this.state.searchTerm!=''?this.state.searchTerm:''

      },()=>this.getStatuses())
    });
  }


  getStatuses(){
    this.setState({ loading: true });
    QuotationService.getStatuses("quotation",res => {
      this.setState({statuses:res.data.statuses.filter(e=> e.enName!='Accepted'),loading:false})
    });
  }


  changeStatus(){
    this.setState({ loading: true });
    let data = {statusId:this.state.selectedStatusId}
    QuotationService.changeStatus(data,this.state.quotationId,res => {
      this.setState({loading:false,changeStatusModal:false},()=>this.getQuotations())
    });
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getQuotations())
  }

  handleChangeStatus(item){
      this.setState({changeStatusModal:!this.state.changeStatusModal,quotationId:item.id,selectedStatus:item.status.enName})
  }

  handleMenus(type,item){
   if(type == 'status'){
     this.setState({selectedStatus:item.enName,selectedStatusId:item.id})
   }
  }

  handleViewChat(quotation){
    this.setState({selectedQuotation:quotation,showQuotationChat:true})
  }

  resetFilter(){
    this.setState({selectedStatusesArr:[]},() =>{
      this.statusFilterRef.clear()
      this.getQuotations()
    })
  }

  removeStatus(status){
    this.setState((prevState) => ({
      selectedStatusesArr : prevState.selectedStatusesArr.filter( s => s.id != status.id )
    }), () => {
      if (!this.state.selectedStatusesArr.length) {
        this.statusFilterRef.clear()
      }
    })
  }

  changeStatusHandle(status){
    let statuses = this.state.selectedStatusesArr;
    if (status.id == 'all') {
        statuses = [];
        this.setState({selectedStatusesArr:status})
    }else{
      let isExist = this.state.selectedStatusesArr.filter( s => s.id == status.id ).length
      if (isExist) {
        alert('you have been select this item before')
      }else{
        statuses = [ ...this.state.selectedStatusesArr , status ] ;
      }
    }

    this.setState({selectedStatusesArr:statuses})
  }


  getSingleInquiry(item){
   console.log(item)
   this.setState({singleInquiry:item.inquiry,inquiryModal:true})
  }

  render() {
    return (
      <div className="app align-items-center">
        <Container>
            <Row>
              <Col md='3'>
                  <QuotationStatusFilter ref={(ref) => this.statusFilterRef = ref} onStatusChange={(status)=>this.changeStatusHandle(status)} />
              </Col>
              <Col md='9'>
                <Button onClick={()=>this.getQuotations()} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'#fff',padding:'7px 20px'}}>Filter</Button>
                <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
              </Col>
            </Row>
            <Row>
              {this.state.selectedStatusesArr.length?(
                <div style={{marginRight:'4px' ,marginBottom:'10px',borderRadius:'7px',padding:'10px 20px',boxShadow:' 0px 14px 60px -17px rgba(0, 0, 0, 0.75)'}} >
                  <h5 style={{paddingLeft:'10px',fontWeight:500}} >Statuses </h5>
                  <div>
                    {this.state.selectedStatusesArr.map( (status,i) => (
                      <Button style={{borderRadius:'50px',marginRight:'5px'}} color="secondary" key={i} onClick={()=>this.removeStatus(status)} >
                        {`${status.enName} `}
                        <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                      </Button>
                    ))}
                  </div>
                </div>
              ):null}
            </Row>
          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderQuotations()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}

            {this.state.fixerModal?<SingleFixer data={this.state.singleFixer} reload={this.reload}/>:null}
          </Card>
          <FModal
            title='Change Status'
            content={
             <div style={{marginLeft:20}}>
               <Row>
                 <Col md="2">
                   <Label style={{fontWeight:'bold'}}>Statuses:</Label>
                 </Col>
                 <Col md="6">
                    <UncontrolledDropdown  className="" style={{marginRight:20}}>
                      <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-question-circle' style={{marginRight:10}} />{this.state.selectedStatus}</DropdownToggle>
                      <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                        {this.state.statuses.map((item, index)=>(
                          <DropdownItem key={index} onClick={()=>this.handleMenus('status',item)}>{item.enName}</DropdownItem>
                        ))}
                      </DropdownMenu>
                  </UncontrolledDropdown>
                 </Col>
               </Row>
             </div>
            }
            state={this.state.changeStatusModal}
            // onClose={()=>this.orderHistoryModal()}
            onClose={()=>this.setState({changeStatusModal:!this.state.changeStatusModal})}
            onSubmit={()=>this.changeStatus()}
          />
          {this.state.showQuotationChat?(
            <FModal
            title='View Chat'
            content={
              <QuotationChat
                ref={ref => this.qouteChatRef = ref}
                quotation={this.state.selectedQuotation}
                reload={()=>this.setState({showQuotationChat:false,selectedQuotation:null})}
              />
            }
            state={this.state.showQuotationChat}
            onClose={()=>this.setState({showQuotationChat:false,selectedQuotation:null})}
            show={"none"}
            />
          ):null}

             <FModal
              title={"Inquiry Details Of : " + this.state.singleInquiry.id}
              content={
                <SingleInquiry
                  ref={ref=>this.singleInquiryRef=ref}
                  inquiry={this.state.singleInquiry}
                  handleQuotationsModal={()=>this.singleInquiryRef.handleQuotationsModal(this.state.inquiryId)}
                  reload={this.reload}
                />
              }
              state={this.state.inquiryModal}
              onClose={()=>this.setState({inquiryModal:false})}
              show={"none"}
            />

            <FModal
             title='View History'
             content={
               <QuotationsHistory
                 // ref={ref => this.qouteChatRef = ref}
                 quotation={this.state.selectedQuotation}
                 reload={()=>this.setState({historyModal:false,selectedQuotation:null})}
               />
             }
             state={this.state.historyModal}
             onClose={()=>this.setState({historyModal:false})}
             show={"none"}
           />


          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Quotations</span>
          </Col>

          <Col md="4">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}} onClick={()=>this.getQuotations()}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e)}
                type="text"
                id="searchTerm"
                name="searchTerm"
                maxLength={50}
                placeholder="By Inquiry serial"
                onKeyPress={this.handleKeyPress}
              />
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderQuotations(){
    return(
      <div>
      {this.state.searchText!=''?(
        <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
          <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
          <h6 style={{fontSize:18}}>Search Results of
            <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

          </h6>
          <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getQuotations())}>
            Clear Search
            <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
          </span>
        </div>

      ):null}
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Inquiry Serial</th>
            <th style={{textAlign:'center'}}>Inquiry Category</th>
            <th style={{textAlign:'center'}}>Fixer</th>
            <th style={{textAlign:'center'}}>Price</th>
            <th style={{textAlign:'center'}}>Price Type</th>
            <th style={{textAlign:'center'}}>Reply</th>
            <th style={{textAlign:'center'}}>Time</th>
            <th style={{textAlign:'center'}}>Status</th>
            <th style={{textAlign:'center'}}>Date And Time</th>
            <th style={{textAlign:'center'}}>Chat</th>
            <th style={{textAlign:'center'}}>Change Status<br/><span style={{fontSize:8, fontWeight:500, textAlign:'center'}}>Accepted can't change</span></th>
            <th style={{textAlign:'center'}}>History</th>
          </tr>
        </thead>
        <tbody>
          {this.state.quotations.map((item,index)=>(
            <tr key={index}>
            <td style={{textAlign:'center'}}>
            <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingleInquiry(item)}>{item.inquiry.serial}</Button>
            </td>
            <td style={{textAlign:'center'}}>
            {item.inquiry.categories.length == 0 ? 'N/A' : (
                  item.inquiry.categories.map((e,k)=>(
                    <span key={k} style={{marginRight:10, whiteSpace:'nowrap',color:'#0089ff', textDecoration:'underline'}}>#{e.enName}</span>
                  ))
                )}
            </td>
            <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({singleFixer:item.fixer, fixerModal:true})}>{item.fixer.enName == null ? item.fixer.arName:item.fixer.enName}</Button>

            </td>
            <td style={{textAlign:'center'}}>{item.price} LE</td>
            <td style={{textAlign:'center'}}>{item.priceType == null ? 'N/A' : item.priceType.enName}</td>
            <td style={{textAlign:'center'}}>{item.quote}</td>
            <td style={{textAlign:'center'}}>{item.time} hr</td>
            <td style={{textAlign:'center'}}>
                {item.status != null ? item.status.enName : 'N/A'}
                {/* <Switch onChange={()=>this.switchVisibleQuotation(item , index)} checked={!item.visible} /> */}
              </td>
              <td style={{textAlign:'center'}}>{item.createdAt}</td>
              <td style={{textAlign:'center'}}>
                <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',width:'100%'}} disabled={!item.chat} onClick={()=>this.handleViewChat(item)}>View Chat</Button>
              </td>
              <td style={{textAlign:'center'}}>
                {item.status.enName == 'Accepted'?(
                  <i className='fa fa-check' style={{alignSelf:'center', color:'green', fontSize:25}}/>
                  // <Button className='actionsBtn' disabled  style={{marginBottom:'1%', display:'block',width:'100%', backgroundColor:'#ededed'}}>Change Status</Button>
                ):(
                  <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',width:'100%'}} onClick={()=>this.handleChangeStatus(item)}>Change Status</Button>
                )}

              </td>
              <td style={{textAlign:'center'}}>
                <span onClick={()=>this.setState({selectedQuotation:item, historyModal:true})} style={{textDecoration:'underline', cursor:'pointer',color:'rgb(0, 137, 255)'}}>View</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
    )
  }






}

export default Quotations;
