import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch";
import SingleFixer from './../fixers/SingleFixer'
import SingleWorkContract from './SingleWorkContract';
import { FModal } from '../../components';
import FixerWorkContractHistory from '../../components/fixerWorkContract/FixerWorkContractHistory';

class ReservationRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      noResult:false,
      approveModal:false,
      declineModal:false,
      displayAllAreasModal:false,
      singleContract:'',
      tab:20,
      data:[],
      showSingleFixer:false,
      paginationInfo:[],
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      currentPage:1,
      approveMsg:'',
      showSingleWorkContract:false,
      selectedWorkContract:null,
      showFixerWorkContractHistoryModal:false,
      selectedFixerWorkContract:null,
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.getReservationRequests()
    // this.setState({page : this.renderNewReservation()})
  }
  reload = () => {
    this.setState({showSingleFixer:false})
    this.getReservationRequests()
  }
  showSingleFixer(item){
    console.log('clckk');
    this.setState({showSingleFixer:true, singleFixer:item})
  }

  getReservationRequests(){
    this.setState({loading:true})
    let withProfit = -1
    Services.getReservationRequests(withProfit,this.state.tab, false, this.state.currentPage, (res)=>{
      if (res.data.workContractReservations.length == 0) {
        this.setState({loading:false, noResult:true, data:[]})
      }else {
        if (this.state.tab == 21) {
          let arr = res.data.workContractReservations
          for (var i = 0; i < arr.length; i++) {
            arr[i].action = true
          }
          this.setState({loading:false, noResult:false, data:arr})
        }else if (this.state.tab == 22) {
          let arr = res.data.workContractReservations
          for (var i = 0; i < arr.length; i++) {
            arr[i].action = false
          }
          this.setState({loading:false, noResult:false, data:arr})
        }else {
          this.setState({loading:false, noResult:false, data:res.data.workContractReservations})
        }
      }
      this.setState({
        paginationInfo:res.data.paginationInfo,
        paginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
      })
    })
  }


  handleApproveModal(){
    this.setState({approveModal:!this.state.approveModal})
  }
  handleDeclineModal(){
    this.setState({declineModal:!this.state.declineModal})
  }
  handleDisplayAllAreasModal(){
    this.setState({displayAllAreasModal:!this.state.displayAllAreasModal})
  }
  handleReservationTab(index){
    this.setState({tab:index},()=>this.getReservationRequests())
  }

  showFixerWorkContractHistory(fixerWorkContract){
    this.setState({
      showFixerWorkContractHistoryModal:true,
      selectedFixerWorkContract:fixerWorkContract,
    })
  }

  closeFixerWorkContractHistory(){
    this.setState({
      showFixerWorkContractHistoryModal:false,
      selectedFixerWorkContract:null,
    })
  }


  approveRequest(){
    this.setState({loading:true})
    Services.changeReservationRequestsStatus(this.state.contractId, 21, (res)=>{
      this.setState({loading:false},()=>this.handleApproveModal(),this.getReservationRequests())
    })
  }
  declineRequest(){
    this.setState({loading:true})
    Services.changeReservationRequestsStatus(this.state.contractId, 22, (res)=>{
      this.setState({loading:false},()=>this.handleDeclineModal(),this.getReservationRequests())
    })
  }


  prevPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page-1},()=>this.getReservationRequests())
    }else {
      this.setState({currentPage: item},()=>this.getReservationRequests())
    }
  }
  nextPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page+1},()=>this.getReservationRequests())
    }else {
      this.setState({currentPage: item},()=>this.getReservationRequests())
    }
  }

  checkIsAvailable(){
    this.setState({loading:true})
    Services.checkReservationResquestAvailabilty(this.state.contractId,(res)=>{
      let approveMsg = ''
      if (res.data.available) approveMsg = 'Are you sure approve this request?'
      else approveMsg = ' Are you sure to approve this reservation for this fixer because he has valid work contract in these date?'
      this.setState({loading:false, approveMsg:approveMsg},()=>this.handleApproveModal())
    })
    return

  }


  render() {
    return (
      <div className="app align-items-center">
        <FModal
          title='Fixer Work Contract History'
          content={<FixerWorkContractHistory fixerWorkContract={this.state.selectedFixerWorkContract} />}
          state={this.state.showFixerWorkContractHistoryModal}
          noSubmitBtn={true}
          onClose={()=>this.closeFixerWorkContractHistory()}
        />
        <Container>
          <Card>
            <CardHeader>
              {this.renderTabReservation()}
            </CardHeader>
            {!this.state.noResult?
              <CardBody className='shadow'>
                {this.renderRequestReservation()}
                {this.renderPagination()}
              </CardBody>
            :(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}

            {this.state.showSingleWorkContract?(
              <SingleWorkContract
                workContract={this.state.selectedWorkContract}
                onClose={()=>this.setState({showSingleWorkContract:false,selectedWorkContract:null})}
              />
            ):null}

            {this.state.showSingleFixer?<SingleFixer data={this.state.singleFixer} reload={this.reload}/>:null}
            {/* approve modal */}
            <Modal fade={false} isOpen={this.state.approveModal} toggle={()=>this.handleApproveModal()} className={this.props.className}>
              <ModalHeader toggle={()=>this.handleApproveModal()}>Approving the request</ModalHeader>
              <ModalBody>
                <h6 style={{paddingLeft:'2%'}}>{this.state.approveMsg}</h6>
              </ModalBody>
              <ModalFooter>
                <Button className='actionsBtn' onClick={()=>this.handleApproveModal()}>Cancel</Button>
                <Button className='actionsBtn' onClick={()=>this.approveRequest()}>Approve</Button>
              </ModalFooter>
            </Modal>

            {/* decline modal */}
            <Modal fade={false} isOpen={this.state.declineModal} toggle={()=>this.handleDeclineModal()} className={this.props.className}>
              <ModalHeader toggle={()=>this.handleDeclineModal()}>Decline the request</ModalHeader>
                <ModalBody>
                  <h6 style={{paddingLeft:'2%'}}>Are you sure decline this request?</h6>
                </ModalBody>
                <ModalFooter>
                  <Button className='actionsBtn' onClick={()=>this.handleDeclineModal()}>Cancel</Button>
                  <Button className='actionsBtn' onClick={()=>this.declineRequest()}>Decline</Button>
                </ModalFooter>
              </Modal>

              {/* all areas modal */}
              <Modal fade={false} isOpen={this.state.displayAllAreasModal} toggle={()=>this.handleDisplayAllAreasModal()} className={this.props.className}>
                <ModalHeader toggle={()=>this.handleDisplayAllAreasModal()}>Areas</ModalHeader>
                <ModalBody>
                  {this.state.singleContract!=''?(
                    <div className='orderBadges' style={{width:350, textAlign:'center', display:'block', margin:'auto'}}>
                      {this.state.singleContract.areas.map((item, index)=>(
                        <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent', border:'1px solid #5a5a5a', color:'#5a5a5a',fontSize:12}}>{item.enName}</span>
                      ))}
                    </div>
                  ):null}
                </ModalBody>
                <ModalFooter>
                  <Button className='actionsBtn' onClick={()=>this.handleDisplayAllAreasModal()}>close</Button>
                </ModalFooter>
              </Modal>

              {/* loading spinner */}
              <Modal fade={false} isOpen={this.state.loading} className='loading'>
                <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
              </Modal>

            </Card>
          </Container>
        </div>
      )
    }




    renderRequestReservation(){
      return(
        <div>
          <Table hover bordered striped responsive size="md">
            <thead>
              <tr>
                <th style={{textAlign:'center',width:'5%'}}>ID</th>
                <th style={{textAlign:'center'}}>Fixer</th>
                <th style={{textAlign:'center'}}>Contract ID</th>
                <th style={{textAlign:'center'}}>Contract Date</th>
                <th style={{textAlign:'center'}}>City</th>
                <th style={{textAlign:'center'}}>Areas</th>
                <th style={{textAlign:'center'}}>Category</th>
                <th style={{textAlign:'center',width:'5%'}}>Package</th>
                <th style={{textAlign:'center'}}>Duration</th>
                <th style={{textAlign:'center'}}>Fixer Rate</th>
                <th style={{textAlign:'center'}}>Action</th>
                <th style={{textAlign:'center'}}>Reserved At</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((item, index)=>(
                <tr key={index}>
                  <td style={{textAlign:'center',width:'5%'}}>
                    <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', minWidth:100}} onClick={()=>this.showFixerWorkContractHistory(item)}>
                      {item.id}
                    </Button>
                  </td>
                  <td style={{textAlign:'center'}}>
                    <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', minWidth:100}} onClick={()=>this.showSingleFixer(item.fixer)}>{item.fixer.enName?item.fixer.enName:item.fixer.arName}</Button>
                  </td>
                  <td style={{textAlign:'center',width:'5%'}}>
                    <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({showSingleWorkContract:true,selectedWorkContract:item.workContract})}>{item.workContract.id}</Button>
                  </td>
                  <td style={{textAlign:'center'}}><div style={{textAlign:'center',width:125}}>{item.workContract.startDate}</div></td>
                  <td style={{textAlign:'center'}}>{item.workContract.city.enName}</td>

                  <td style={{textAlign:'center'}}>
                    <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({singleContract:item.workContract},()=>this.handleDisplayAllAreasModal())}>Show Areas</span>
                  </td>
                  <td style={{textAlign:'center'}}>{item.workContract.category.enName}</td>
                  <td style={{textAlign:'center',width:'5%'}}>{item.workContract.package.arName}</td>
                  <td style={{textAlign:'center'}}>{item.workContract.package.duration>=24?`${item.workContract.package.duration/24} days`: `${item.workContract.package.duration} hours`}</td>
                  <td style={{textAlign:'center'}}>{item.fixer.averageTotalRate}</td>
                  {this.renderActionDependOnTab(item)}
                  <td style={{textAlign:'center'}}><div style={{textAlign:'center',width:125}}>{item.createdAt}</div></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )
    }

    renderActionDependOnTab(item){
      switch (this.state.tab) {
        case 20:
        return(
          <td style={{textAlign:'center'}}>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <Button onClick={()=>this.setState({contractId:item.id},()=>this.checkIsAvailable())} id="Approve" style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                <i className="fas fa-check" style={{fontSize:20,color:'#4dbd74'}}></i>
              </Button>
              <UncontrolledTooltip  target="Approve">Approve</UncontrolledTooltip>
              <Button onClick={()=>this.setState({contractId:item.id},()=>this.handleDeclineModal())} id="decline" style={{backgroundColor:'transparent',borderColor:'transparent'}}>
                <i className="fas fa-times" style={{fontSize:20,color:'#f86c6b'}}></i>
              </Button>
              <UncontrolledTooltip  target="decline">Decline</UncontrolledTooltip>
            </div>
          </td>
        )
        break
        case 21:
        return(
          <td style={{textAlign:'center'}}>
            <Switch onChange={()=>this.setState({contractId:item.id},()=>this.handleDeclineModal())} checked={item.action}/>
          </td>
        )
        break
        case 22:
        return(
          <td style={{textAlign:'center'}}>
            <Switch onChange={()=>this.setState({contractId:item.id},()=>this.handleApproveModal())} checked={item.action}/>
          </td>
        )
        break
        default:

      }
    }

    renderTabReservation(){
      return (
        <div>
          <Row className="rowTabReservation">
            <Col md="2" className="tabButton">
              <Button onClick={()=>this.handleReservationTab(20)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==20?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>New</Button>
            </Col>
            <Col md="2" className="tabButton">
              <Button onClick={()=>this.handleReservationTab(21)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==21?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Accepted</Button>
            </Col>
            <Col md="2" className="tabButton">
              <Button onClick={()=>this.handleReservationTab(22)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==22?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Refused</Button>
            </Col>
          </Row>
        </div>
      )
    }

    renderPagination(){
      return(
        <nav>
          <Row className='pull-right'>
            <Pagination style={{justifyContent:'flex-end'}}>
              {/* previous link*/}
              <PaginationItem>
                <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
              </PaginationItem>

              {/* previousPages */}
              {this.state.previousPages.map((item, index)=>(
                <PaginationItem key={index}>
                  <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                    {item}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {/* currentPage */}
              <PaginationItem active>
                <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                  {this.state.paginationInfo.currentPage}
                </PaginationLink>
              </PaginationItem>


              {/* nextPages */}
              {this.state.nextPages.map((item, index)=>(
                <PaginationItem key={index}>
                  <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                    {item}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {/* nextPageUrl */}
              <PaginationItem>
                <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
              </PaginationItem>
            </Pagination>
          </Row>
        </nav>
      )
    }




  }

  export default withRouter(ReservationRequests);
