import React from 'react'
import { Col, Row,Button, Spinner} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import ReverseReactivePaginator from '../../Pagination/ReverseReactivePaginator';
import { SRLWrapper } from "simple-react-lightbox";
import LogoImg from './../../../assets/img/logo.png'
import Message from './../../../components/chat/Message'
import ConfirmMessage from './../../ConfirmDeleteMessage'
import ChatService from './../../../services/chatServices/ChatService'

const options = {
  caption: {
    captionAlignment: 'start',
    captionColor: '#FFFFFF',
    captionContainerPadding: '0',
    captionFontFamily: 'inherit',
    captionFontSize: 'inherit',
    captionFontStyle: 'inherit',
    captionFontWeight: 'inherit',
    captionTextTransform: 'inherit',
    showCaption: true,
    reOpenChatAction:false,
    suspendChatAction:false,
    currentPlayingRecored:null
  }
}

export default class QuotationChat extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      messages:[],
      chat:[],
      customer:'',
      fixer:'',
      user:'',
      messagePage:1,
      nextPages:[],
      prevPages:[],
      me:'',
      isBanned:false
    }
  }

  componentDidMount(){
    this.getChat()
  }

  suspendReOpenChat(action){
    let arr = []
    arr = this.state.chat[0].participants.map(e=>e.id)
    let data = { participants:arr }
    this.setState({loading:true})
    Services.suspendReOpenChat(this.state.chat[0].id, data, action, (res)=>{
      this.setState({loading:false,suspendChatAction:false,reOpenChatAction:false,isBanned:action=='ban'})
    })
  }
  getNextMessages(){
    if (this.state.nextPages.length != 0) {
      this.setState((prevState)=>({messagePage:prevState.messagePage+1}),()=>this.updateWithNextMessages())
    }
  }

  updateChat( chat , user ){
    this.setState({messages:[],chat:chat,user:user,messagePage:1},()=>this.getMessages())
  }

  getChat(){
    ChatService.getQuotationChat(this.props.quotation.id,(res)=>{
      let chat = res.data.chat[0]
      chat.participants.map(e=>{
        if (e.banned==1) {
          this.setState({isBanned:true  })
        }
      })
      this.setState({chat:res.data.chat},()=>this.getMessages())
    })
  }

  getMessages(){
    this.setState({loading:true,disabled:true})
    Services.getChatMessagesAdminPanel(this.state.messagePage,this.state.chat[0].id,(res)=>{
      this.setState({
        loading:false,
        disabled:false,
        me:res.data.me,
        messages:res.data.messages,
        nextPages:res.data.paginationLinks.nextPages,
        prevPages:res.data.paginationLinks.previousPages,
      },()=>this.paginatorRef.toButtom())
    })
  }

  updateWithNextMessages(){
    this.setState({loading:true,disabled:true})
    Services.getChatMessagesAdminPanel(this.state.messagePage,this.state.chat[0].id,(res)=>{
      this.setState((prevState)=>({
        loading:false,
        disabled:false,
        messages:[...prevState.messages,...res.data.messages],
        nextPages:res.data.paginationLinks.nextPages,
        prevPages:res.data.paginationLinks.previousPages,
      }),()=>this.paginatorRef.toLastPosition())
    })

  }

  handlePlayRecoredMessage(voiceRecored){
    if (this.state.currentPlayingRecored != voiceRecored) {
      if (!!this.state.currentPlayingRecored) {
        this.state.currentPlayingRecored.reset();
      }
      this.setState({currentPlayingRecored:voiceRecored})
    }
  }

  render(){
    return(
      <div>
        {this.state.suspendChatAction ? (
          <ConfirmMessage
            show={this.state.suspendChatAction}
            message="Are you sure to suspend chat ?"
            close={()=>this.setState({suspendChatAction:false})}
            confirm={()=>this.suspendReOpenChat('ban')}
          />
        ):null}
        {this.state.reOpenChatAction ? (
          <ConfirmMessage
            show={this.state.reOpenChatAction}
            message="Are you sure to reopen chat?"
            close={()=>this.setState({reOpenChatAction:false})}
            confirm={()=>this.suspendReOpenChat('unban')}
          />
        ):null}
        <div style={{display:'flex',justifyContent:'center',marginTop:-30}}>
          {this.state.isBanned?(
            <Button className='actionsBtn' onClick={()=>this.setState({reOpenChatAction:true})}>Re-Open <i className="fas fa-retweet"></i></Button>
          ):(
            <Button className='actionsBtn' onClick={()=>this.setState({suspendChatAction:true})}>Suspend <i className="fas fa-ban"></i></Button>
          )}
        </div>

        <div style={{height:'300px',width:'100%'}} className='chat-panel_box'>
          <ReverseReactivePaginator ref={ ref => this.paginatorRef = ref } id='2' disabled={this.state.disabled} updateContent={()=>this.getNextMessages()}>
                  {this.state.loading?(<div className="text-center m-5"><Spinner color="primary" /></div>):null}
                  {this.state.chat?(
                    <SRLWrapper options={options}>
                    {this.state.messages.pureReverse().map((msg,i)=>(
                      <Message
                          key={i}
                          message={msg}
                          notify={(voiceRecored)=>this.handlePlayRecoredMessage(voiceRecored)}
                      />
                  ))}</SRLWrapper>
                  ):(
                  <div>
                      <img src={LogoImg} alt='loading..' style={{width:'15%',height:'25%',display:'block',marginTop:'10%', marginLeft:'auto',marginRight:'auto' }} />
                      <h1 style={{textAlign:'center', fontSize:25, color:'#545453', marginTop:'3%', fontWeight:'bold'}}>Fixawy Customer Chat  <span className='mdi mdi-message-bulleted' style={{fontSize:25}}/></h1>
                  </div>
                  )}
          </ReverseReactivePaginator>
        </div>
      </div>
      )
    }
}
