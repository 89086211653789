import React from 'react'
import { Input, Row, Col } from 'reactstrap';

class PushNotification extends React.Component{
  constructor(props){
    super(props)
    this.state={
      title:'',
      body:'',
    }
  }

  getTitle(){
    return this.state.title;
  }
  getBody(){
    return this.state.body;
  }

  render(){
    return(
      <div style={{paddingLeft:'10px'}}>
        <Row>
          <Col md='2'>
            <label><strong>Title :</strong></label>
          </Col>
          <Col md='9'>
            <Input  type='text'
                    style={{marginBottom:'10px'}}
                    maxLength='150'
                    placeholder='Notification Title...'
                    value={this.state.title}
                    onChange={(e)=>this.setState({title:e.target.value})}
                  />
          </Col>
        </Row>
        <Row>
          <Col md='2'>
            <label><strong>Body :</strong></label>
          </Col>
          <Col md='9'>
            <Input  type='textarea'
                    maxLength='5000'
                    placeholder='Notification Body...'
                    value={this.state.body}
                    onChange={(e)=>this.setState({body:e.target.value})}
                    />
            </Col>
          </Row>
      </div>
    )
  }
}
export default PushNotification;
