import React, { Component } from "react";
import { Container, Card, Button, Col, Row, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledDropdown } from "reactstrap";
import Services from "./../../services/Services";
import LoadingOverlay from "react-loading-overlay";
import PromocodeServices from './../../services/promo/PromocodeServices'
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      categories: [],
      multiSelectCities: [],
      multiSelectCategories: [],
      selectedCategory: [],
      selectedCity: [],
      promoType: "Choose",
      promoCode: "",
      promoAmount: "",
      numberOfUses: "",
      fromDate: "",
      toDate: "",
      maxAmountOfDiscount: "",
      loading: false,
      addPromoModal: true,
      promoCodeType:'Choose'
    };
  }

  componentDidMount() {
    this.getCities()
    console.log(this.props.promo);
  }
  componentWillReceiveProps(nextProps) {
    console.log("singleCustomer componentWillReceiveProps");
    this.setState({ addPromoModal: true }, () => this.getCities());
  }

  handleChangeInputs = (e, type) => {
    switch (type) {
      case "promoCode":
      this.setState({ promoCode: e.target.value });
      break;
      case "promoAmount":
      this.setState({ promoAmount: e.target.value });
      break;
      case "numberOfUses":
      this.setState({ numberOfUses: e.target.value });
      break;
      case "maxAmountOfDiscount":
      this.setState({ maxAmountOfDiscount: e.target.value });
      break;

      default:
    }
  };
  handleAddPromo() {
    this.setState({
      addPromoModal: !this.state.addPromoModal,
      multiSelectCities: [],
      multiSelectCategories: [],
      selectedCategory: [],
      selectedCity: [],
      promoType: "Choose",
      promoCode: "",
      promoAmount: "",
      numberOfUses: "",
      fromDate: "",
      toDate: "",
      maxAmountOfDiscount: "",
      loading: false,
      renderFromDate: "",
      renderToDate: "",
      promoCodeType: "",
    });
  }

  getCities() {
    this.setState({ loading: true });
    Services.getCities(res => {
      let arr = res.data.cities;
      let titles = [];
      for (var i = 0; i < arr.length; i++) {
        let obj = { label: arr[i].enName, id: arr[i].id };
        titles.push(obj);
      }
      this.setState({ cities: arr, multiSelectCities: titles }, () => this.getCategories())
    })
  }

  getCategories() {
    Services.getCategories(res => {
      let arr = res.data.categories;
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length !== 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j]);
          }
          arr.splice(i, 1);
        }
        let obj = { label: arr[i].enName, id: arr[i].id };
        titles.push(obj);
      }
      this.setState({
        categories: arr,
        multiSelectCategories: titles,
        loading: false
      },()=>this.assginPromoToState())
    });
  }

  assginPromoToState(){
    let promo = this.props.promo
    this.setState({
      promoCode: promo.code,
      promoAmount: promo.amount.toString(),
      numberOfUses: promo.numberOfUsesPerUser.toString(),
      promoType: promo.type,
      maxAmountOfDiscount: promo.maxAmountOfDiscount,
      promoCodeType: promo.calculatedOn,
      fromDate: new Date(promo.validFrom + " Z").toISOString().split("T")[0],
      renderFromDate: new Date(promo.validFrom + " Z"),
      toDate: new Date(promo.validTo + " Z").toISOString().split("T")[0],
      renderToDate: new Date(promo.validTo + " Z"),
      selectedCategory:promo.categories.map(e=> ({label:e.enName, id:e.id})),
      selectedCity:promo.cities.map(e=> ({label:e.enName, id:e.id})),
    })
  }

  onSelectedChanged(selectedItems, type) {
    if (type === "category") {
      this.setState({ selectedCategory: selectedItems });
    } else {
      this.setState({ selectedCity: selectedItems });
    }
  }

  handleOnChangeDate(type, date) {
    console.log(date);
    let day = new Date(date + " Z");
    if (type === "from") {
      this.setState(
        {
          fromDate: day.toISOString().split("T")[0],
          renderFromDate: date,
          toDate: "",
          renderToDate: ""
        },
        () => console.log(this.state.fromDate)
      );
    } else {
      this.setState(
        { toDate: day.toISOString().split("T")[0], renderToDate: date },
        () => console.log(this.state.toDate)
      );
    }
  }

  editPromo() {

    if ( this.state.promoCode === "" || !this.state.promoCode.replace(/\s/g, "").length ) {
      alert("Please, enter promo code");
    } else if (/\s/.test(this.state.promoCode)) {
      alert("Please, enter promo code without spaces");
    } else if ( this.state.promoAmount === "" || !this.state.promoAmount.replace(/\s/g, "").length ) {
      alert("Please, enter promo amount");
    } else if (this.state.promoType === "Choose") {
      alert("Please, select promo type");
    } else if (this.state.promoCodeType === "Choose") {
      alert("Please, select promo code type");
    } else if ( this.state.promoType === "percentage" && this.state.maxAmountOfDiscount === "" ) {
      alert("Please, enter max amount of discount");
    } else if ( this.state.numberOfUses === "" || !this.state.numberOfUses.replace(/\s/g, "").length ) {
      alert("Please, enter number of uses");
    } else if (this.state.fromDate === "") {
      alert("Please, enter starting date");
    } else if (this.state.toDate === "") {
      alert("Please, enter ending date");
    } else if (this.state.selectedCity.length === 0) {
      alert("Please, select city");
    } else if (this.state.selectedCategory.length === 0) {
      alert("Please, select category");
    } else {
      let categoryIds = [];
      for (var i = 0; i < this.state.selectedCategory.length; i++) {
        categoryIds.push(this.state.selectedCategory[i].id);
      }
      let cityIds = [];
      for (var j = 0; j < this.state.selectedCity.length; j++) {
        cityIds.push(this.state.selectedCity[j].id);
      }

      let reqData = {
        // code: this.state.promoCode,
        type: this.state.promoType,
        amount: this.state.promoAmount,
        numberOfUsesPerUser: this.state.numberOfUses,
        validFrom: this.state.fromDate,
        validTo: this.state.toDate,
        cities: cityIds,
        categories: categoryIds,
        calculatedOn:this.state.promoCodeType
      };
      if (this.state.promoType === "percentage") {
        reqData.maxAmountOfDiscount = this.state.maxAmountOfDiscount;
      }
      console.log(reqData);
      this.setState({ loading: true });
      PromocodeServices.edit(this.props.promo.id, reqData, res => {
        this.setState({ loading: false })
          if(res.message == 'forbidden'){
            alert('You Are Not Authorized To Take That Action')
          }else if (res.message == 'success') {
            this.props.reload()
          }
      })
    }
  }

  render() {
    return (
      <div className="app align-items-center">
        <Container>
          {this.renderAddPromo()}
          <Modal fade={false} isOpen={this.state.loading} className="loading">
            <LoadingOverlay
              active={this.state.loading}
              spinner
              text="Loading..."
              style={{ backgroundColor: "#00000060" }}
            />
          </Modal>
        </Container>
      </div>
    )
  }

  renderAddPromo() {
    return (
      <div>
        <Row>
          <Col md="2">
            <p className="titles">Promo Code:</p>
          </Col>
          <Col md="6">
            <input
              placeholder="Enter promo code.."
              type="text"
              disabled
              value={this.state.promoCode}
              onChange={e => this.handleChangeInputs(e, "promoCode")}
              style={{
                width: "100%",
                borderRadius: 5,
                border:'1px solid #ededed',
                padding: 7,
                backgroundColor:'#ededed'
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="2">
            <p className="titles">Promo Amount: </p>
          </Col>
          <Col md="6">
            <input
              placeholder="Enter code amount.."
              type="number"
              value={this.state.promoAmount}
              onChange={e => this.handleChangeInputs(e, "promoAmount")}
              onInput={e => {
                e.target.value = e.target.value.match(/^[0-9.]+$/);
              }}
              style={{
                width: "100%",
                borderRadius: 5,
                borderColor: "#777777",
                borderWidth: 1,
                padding: 7
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="2">
            <p className="titles">Promo Type: </p>
          </Col>
          <Col md="6">
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle
                caret
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  borderColor: "#777777"
                }}
                >
                  {this.state.promoType}
                </DropdownToggle>
                <DropdownMenu style={{ width: "100%" }}>
                  <DropdownItem
                    key={1}
                    onClick={() => this.setState({ promoType: "percentage" })}
                    >
                      percentage
                    </DropdownItem>
                    <DropdownItem
                      key={2}
                      onClick={() => this.setState({ promoType: "fixed" })}
                      >
                        fixed
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }}>
                <Col md="2">
                  <p className="titles">Calculated At: </p>
                </Col>
                <Col md="6">
                  <UncontrolledDropdown className="mb-3">
                    <DropdownToggle
                      caret
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        borderColor: "#777777"
                      }}
                      >
                        {this.state.promoCodeType}
                      </DropdownToggle>
                      <DropdownMenu style={{ width: "100%" }}>
                        <DropdownItem
                          key={1}
                          onClick={() => this.setState({ promoCodeType: "totalPrice" })}
                          >
                            total
                          </DropdownItem>
                          <DropdownItem
                            key={2}
                            onClick={() => this.setState({ promoCodeType: "fixingPrice" })}
                            >
                              fix
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>

                    {this.state.promoType === "percentage" ? (
                      <Row style={{ marginTop: 12 }}>
                        <Col md="2">
                          <p className="titles">Max Amount of Discount: </p>
                        </Col>
                        <Col md="6">
                          <input
                            placeholder="Enter max amount of discount"
                            type="number"
                            value={this.state.maxAmountOfDiscount}
                            onInput={e => { e.target.value = e.target.value.match(/^[0-9.]+$/); }}
                            onChange={e => this.handleChangeInputs(e, "maxAmountOfDiscount") }
                            style={{ width: "100%", borderRadius: 5, borderColor: "#777777", borderWidth: 1, padding: 7}}
                          />
                        </Col>
                      </Row>
                    ) : null}

                    <Row style={{ marginTop: 12 }}>
                      <Col md="2">
                        <p className="titles">Number of Uses: </p>
                      </Col>
                      <Col md="6">
                        <input
                          placeholder="Enter code number of uses.."
                          type="number"
                          value={this.state.numberOfUses}
                          onInput={e => {
                            e.target.value = e.target.value.match(/^[0-9.]+$/);
                          }}
                          onChange={e => this.handleChangeInputs(e, "numberOfUses")}
                          style={{ width: "100%", borderRadius: 5, borderColor: "#777777", borderWidth: 1, padding: 7   }}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 12 }}>
                      <Col md="4">
                        <Row>
                          <Col md="2">
                            <p className="titles">From: </p>
                          </Col>
                          <Col md="8">
                            <DatePicker
                              minDate={new Date()}
                              selected={this.state.renderFromDate}
                              onChange={date => this.handleOnChangeDate("from", date)}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="From date"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="4">
                        <Row>
                          <Col md="2">
                            <p className="titles">To: </p>
                          </Col>
                          <Col md="8">
                            <DatePicker
                              disabled={this.state.fromDate === "" ? true : false}
                              minDate={this.state.renderFromDate === "" ? new Date() : this.state.renderFromDate }
                              selected={this.state.renderToDate}
                              onChange={date => this.handleOnChangeDate("to", date)}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="To date"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 12 }}>
                      <Col md="4">
                        <p className="titles">City:</p>
                        <MultiSelect
                          items={this.state.multiSelectCities}
                          selectedItems={this.state.selectedCity}
                          onChange={item => this.onSelectedChanged(item, "city")}
                          withGrouping={true}
                          showSelectedItems={false}
                        />
                      </Col>
                      <Col md="4">
                        <p className="titles">Category: </p>
                        <MultiSelect
                          items={this.state.multiSelectCategories}
                          selectedItems={this.state.selectedCategory}
                          onChange={item => this.onSelectedChanged(item, "category")}
                          withGrouping={true}
                          showSelectedItems={false}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              }
            }
