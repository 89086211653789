import { Button , DropdownToggle,  DropdownItem, DropdownMenu,UncontrolledDropdown , Row, Col , Input ,  Modal , ModalBody , ModalFooter , ModalHeader  } from 'reactstrap'
import React from 'react'
import Services from '../../services/Services'
import CustomersServices from '../../services/customerServices/CustomersServices'

export default class PriceOfferMessage extends React.Component
{
    constructor(props){
        super(props)
        this.state={
            noResult:false,
            customerOrders:[],
            selectedOrder:{id:'Choose'},
            price:'',
        }
    }

    componentDidMount(){
        this.getOfferablePriceOrders();
    }

    getOfferablePriceOrders(){
      CustomersServices.getOfferablePriceOrders(this.props.customer.id,(res)=>{
        this.setState({customerOrders:res.data.orders , noResult :!res.data.orders.length})
      })
    }

    setSelectedOrder(order){
        this.setState({selectedOrder:order})
    }

    setPrice(e){
        if (! isNaN(e.target.value)) {
            this.setState({price:e.target.value})
        }
    }

    isDisabled(){
        return this.state.selectedOrder.id==='Choose' || (!this.state.price) ;
    }

    submit(){
        let data = {
            price:this.state.price,
            order:this.state.selectedOrder,
        }
        this.props.send(data)
    }

    render(){
        return(
            <Modal fade={false} isOpen={true} toggle={()=>this.props.onClose()} >
                <ModalHeader toggle={()=>this.props.onClose()}>Create Price Offer Message</ModalHeader>
                <ModalBody>
                    <div style={{padding:15}} >
                        {this.state.noResult?(
                            <p>Sorry , This Customer Has No Active Orders Now</p>
                        ):(<>
                        <Row>
                            <Col md="3">
                                <p className='titles'>Choose Order :</p>
                            </Col>
                            <Col md="5">
                                <UncontrolledDropdown  className="mb-3">
                                <DropdownToggle disabled={!this.state.customerOrders.length} caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedOrder.id}</DropdownToggle>
                                <DropdownMenu style={{width:'100%'}}>
                                    {this.state.customerOrders.map((order, index)=>(
                                    <DropdownItem key={index} onClick={()=>this.setSelectedOrder(order)}>{order.id}</DropdownItem>
                                    ))}
                                </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="3"><p className='titles' >Price :</p></Col>
                            <Col md="6">
                            <Input
                                placeholder="Enter Offer Price.."
                                maxLength='6'
                                onChange={(e)=>this.setPrice(e)}
                                value={this.state.price}
                                />
                            </Col>
                        </Row>
                        </>)}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className='actionsBtn' onClick={()=>this.props.onClose()}>Close</Button>
                    <Button disabled={this.isDisabled()} className='actionsBtn' onClick={()=>this.submit()}>Send</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
