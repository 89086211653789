import React from 'react'
import { Card, CardBody, Container ,Button as Link} from 'reactstrap'
import { Button, NoResult, Table, TableBody, TableHeader, TD, TH, TR, LoadingSpinner, } from '../../components'
import SingleCustomer from './SingleCustomer'
import SingleOrder from './../orders/SingleOrder'
import CustomersServices from '../../services/customerServices/CustomersServices'
import Services from './../../services/Services'
import Paginator from './../../components/Pagination/V2/Paginator'

class CustomersEquipments extends React.Component{
  constructor(props){
    super(props)
    this.state={
      loading:false,
      noResult:false,
      data:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      singleCustomer:null,
      showSingleCustomer:false,
      singleOrder:null,
      showSingleOrder:false,
    }
  }
  componentDidMount(){
    this.getCustomersEquipments()
  }
  showCustomer(customer){
    this.setState({singleCustomer:customer,showSingleCustomer:true})
  }
  closeSingleCustomer(){
    this.setState({singleCustomer:null,showSingleCustomer:false},()=>this.reload())
  }
  showOrder(order){
    this.setState({singleOrder:order,showSingleOrder:true})
  }
  closeSingleOrder(){
    this.setState({singleOrder:null,showSingleOrder:false},()=>this.reload())
  }
  changePage(page){
    this.setState({currentPage:page},()=>this.reload())
  }
  getCustomersEquipments(){
    this.setState({loading:true})
    CustomersServices.listEqupmentsRequest(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        data:res.data.equipmentRequests,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        noResult:!res.data.equipmentRequests.length,
      })
    })
  }
  reload(){
    this.getCustomersEquipments()
  }
  render(){
    return(
    <>
    {this.state.showSingleCustomer?(<SingleCustomer data={this.state.singleCustomer} reload={()=>this.closeSingleCustomer()} />):null}
    {this.state.showSingleOrder?(<SingleOrder data={this.state.singleOrder} reload={()=>this.closeSingleOrder()}/>):null}
    <div className="app align-items-center">
      <Container>
        <Card>
          <CardBody className='shadow'>
            {this.state.noResult?(<NoResult/>):(
            <Table>
              <TableHeader>
                <TH>Order ID</TH>
                <TH>Customer Name</TH>
                <TH>Product Name</TH>
                <TH>Product Link</TH>
                <TH>Product Price</TH>
                <TH>Requsted Date</TH>
              </TableHeader>
              <TableBody>
                {this.state.data.map((item,index) => (
                  <TR key={index}>
                    <TD>{item.order?(<Button handler={()=>this.showOrder(item.order)} >{item.order.id}</Button>):'N/A'}</TD>
                    <TD><Button handler={()=>this.showCustomer(item.user)} >{item.user.enName?item.user.enName:item.user.arName}</Button></TD>
                    <TD>{item.equipment?item.equipment.enName?item.equipment.enName:item.equipment.arName:'N/A'}</TD>
                    <TD>{item.equipment.previewLink?(<Link href={item.equipment.previewLink} target='blank' color="link">{item.equipment.previewLink}</Link>):'N/A'}</TD>
                    <TD>{item.equipment.price?`${item.equipment.price} LE`:'N/A'}</TD>
                    <TD>{item.createdAt?item.createdAt:'N/A'}</TD>
                  </TR>
                ))}
              </TableBody>
            </Table>
            )}
            <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
          </CardBody>
        </Card>
      </Container>
    </div>
    <LoadingSpinner loading={this.state.loading} />
    </>
    );
  }
}
export default CustomersEquipments ;
