import React from 'react'
class SearchResult extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
        <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
            <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
            <h6 style={{fontSize:18}}>Search Results of
              <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.props.searchTerm}</span>

            </h6>
            <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.props.handler()}>
              Clear Search
              <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
            </span>
        </div>
        )
    }
}
export default SearchResult;