import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Label,FormGroup, Modal} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Bowser from "bowser";
import LoadingOverlay from 'react-loading-overlay';
import { Global } from './../../core/Global'
import Logo from './../../assets/img/logo.png'
import Services from './../../services/Services'
import firebase from 'firebase/app'
import 'firebase/messaging'
import { connectToSocket } from '../../core/helper';
import NetworkDetector from './../../core/NetworkDetector'

class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
      phone:'',
      password:[],
      loading:false,
      pushNotificationToken:'',
      model:''
    }
    this.handleChangeInputs = this.handleChangeInputs.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount(){
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.setState({model:browser.getBrowserName()})
    this.getToken()
  }
  getToken(){
    let messaging = null
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging()
      messaging.requestPermission().then(()=>{
        return messaging.getToken()
      }).then((token)=>{
        this.setState({pushNotificationToken:token})
      })
      .catch((e)=>{
        this.setState({pushNotificationToken:''})
      })
    }
    // .catch((e)=>{
    //   var r = window.confirm("You must ALLOW get Notifications");
    //   if (r == true) {
    //     window.location.href  = 'https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&hl=en'
    //   }
    // })
  }

  handleChangeInputs = (x, e) => {
    switch (x) {
      case 'phone':
      this.setState({phone:e.target.value})
      break
      case 'password':
      this.setState({password:e.target.value})
      break
      default:
    }
  }


  handleKeyPress(target) {
    if(target.charCode==13){
      this.login()
    }
  }


  login(){
    console.log(this.state.pushNotificationToken);
    if (this.state.phone == '' || !this.state.phone.replace(/\s/g, '').length) {
      alert('Enter your phone number')
    }else if (!this.state.phone.startsWith('01')) {
      alert('Phone number must be starts with 01')
    }else if (this.state.phone.length !== 11) {
      alert('Phone number must be 11 digits at least')
    }else if (this.state.phone.match(/^[0-9]+$/) == null) {
      alert('Enter vaild phone number')
    }else if (this.state.password == '' || !this.state.password.replace(/\s/g, '').length) {
      alert('Enter your password')
    }else if (this.state.password.length < 6) {
      alert('Enter vaild password')
    }
    // else if (!this.state.pushNotificationToken.trim()) {
    //   var r = window.confirm("Your token not generate please reload thr page");
    //   if (r == true) window.location.reload()
    // }
    else {
      this.setState({loading:true})
      let x = {
        phone : this.state.phone,
        password : this.state.password,
        platformTypeId: 3,
        userTypeId:3,
        osVersion: window.navigator.platform,
        model: this.state.model,
        pushNotificationToken:this.state.pushNotificationToken
      }
      Services.login(x, async (res)=>{
        console.log(res);
        this.setState({loading:false})
        if (res.message === 'success') {
          await localStorage.setItem('logged','home')
          await localStorage.setItem('userData',JSON.stringify(res.data))
          await connectToSocket();
          this.props.history.push('/')
        }else if (res.message == 'wrong_phone_or_password') {
          alert('Phone number or Password is wrong')
        }else if (res.message == 'blocked') {
          alert('Your account is blocked')
        }else {
          this.props.history.push('/')
        }
      })
    }
  }





  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <img src={Logo} style={{display:'block',margin:'auto',width:100,height:100, marginBottom:20}}/>
                      <h1 style={{textAlign:'center'}}>Login</h1>
                      <p className="text-muted" style={{textAlign:'center',marginTop:20}}>Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.phone}
                          onChange={(e)=>this.handleChangeInputs('phone',e)}
                          type="text"
                          placeholder="Phone Number"
                          autoComplete="username"
                          maxLength={11}
                          onKeyPress={this.handleKeyPress}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.password}
                          onChange={(e)=>this.handleChangeInputs('password',e)}
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          onKeyPress={this.handleKeyPress}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="3">

                        </Col>
                        <Col xs="6" className="text-center">
                          <FormGroup check className="checkbox">
                            <Input className="form-check-input" type="checkbox" id="checkbox1" name="checkbox1" value="option1" style={{fontSize:40}}/>
                            <Label check className="form-check-label" htmlFor="checkbox1" style={{fontSize:15}} className="text-muted">Remember Password</Label>
                          </FormGroup>
                        </Col>
                        <Col xs="3">

                        </Col>
                      </Row>
                      <Row style={{marginTop:12}}>
                        <Col xs="4">
                        </Col>
                        <Col xs="4" className="text-center">
                          <Button onClick={()=>this.login()} style={{backgroundColor:'#4dbd74',borderWidth:1,borderColor:'white',color:'black',marginTop:15}}  className="px-4">Login</Button>
                        </Col>

                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>

        </Container>
      </div>
    )
  }
}

export default NetworkDetector(withRouter(Login));
