import React from 'react'
import SchedualeMessage from './../SchedualeMessage'
import { UncontrolledTooltip } from 'reactstrap';
class Scheduale extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      showModal:false,
    }
  }
  render(){
    return(<>
      {this.state.showModal?(
        <SchedualeMessage
            onClose={()=>this.setState({showModal:false})}
            onMessageScheduled={()=>this.props.onMessageScheduled()}
            chat={this.props.chat}
            message={this.props.message}
        />
      ):null}
      <span id="message-scheduler" className="fa fa-2x fa-calendar" onClick={()=>this.setState({showModal:true})} />
      <UncontrolledTooltip placement="top" target="message-scheduler">Scheduale This Message</UncontrolledTooltip>
    </>);
  }
}

export default Scheduale;
