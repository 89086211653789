import React from 'react';
import {Col,Row,Label,Input,Table} from 'reactstrap'
import {NoResult} from './../../components'
export default class PostHistory extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }
  }

  componentDidMount(){

  }



  render(){
    return(
      <div>
        {this.props.data.homePageSectionActions.length == 0 ? (
          <NoResult/>
        ):(
        <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Action Name</th>
            <th style={{textAlign:'center'}}>Admin Name</th>
            <th style={{textAlign:'center'}}>Date And Time</th>
          </tr>
        </thead>
        <tbody>
          {this.props.data.homePageSectionActions.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{item.action.action}</td>
              <td style={{textAlign:'center'}}>{item.actionMaker.enName}</td>
              <td style={{textAlign:'center'}}>{item.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      )}
      </div>
    )
  }
}
