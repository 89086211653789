import React from 'react'
import {PaginationItem,PaginationLink} from 'reactstrap'
class PageLink extends React.Component{
    render(){
        return(
            <PaginationItem key={this.props.key} active={this.props.active}>
                <PaginationLink tag="button" 
                                onClick={this.props.action} 
                                style={ this.props.active ?
                                          {backgroundColor:'#4dbd74',
                                           color:'black',
                                           borderColor:'#4dbd74'} 
                                          : {}
                                    }
                >
                   {this.props.children} 
                </PaginationLink>
            </PaginationItem>
        )
    }
}
export default PageLink;