import React,{Fragment} from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
import Services from './../../services/Services';
import MultiSelect from "@kenshooui/react-multi-select"
import { LoadingSpinner } from './../../components'
import ServiceGroupFilter from './../Filters/ServiceGroupFilter'

export default class PromotedServiceGroup extends React.Component{
  constructor(props){
    super(props)
    this.state={
      selectedCategories:[],
      categoryId:[],
      multiSelectCategory:[],
      serviceGroupId:'',
      selectedServiceGroup:'ServiceGroup',
      loading:false,
      typeId:'',
      image:'',
      imagePreview:''

    }
  }

  componentDidMount(){
  }

  getCategoriesForServiceGroups(){
    this.setState({loading:!this.state.loading})
    Services.getCategoriesForServiceGroups(this.state.serviceGroupId,(res)=>{
      let arr = res.data.categories
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length) {
          for (let j = 0; j < arr[i].children.length; j++) {
            const element = arr[i].children[j];
            const obj = {label:element.enName, id: element.id}
            titles.push(obj)
          }
        }else{
          let obj ={label:arr[i].enName, id: arr[i].id}
          titles.push(obj)
        }
      }
      this.setState({multiSelectCategory:titles, loading:false})
    })
  }

  handlerServiceGroup=(item)=>{
    if (item.enName == 'Choose') {
      this.setState({selectedServiceGroup : "ServiceGroup" , serviceGroupId:'',selectedCategories:[]})
    }else if(item.enName == 'All'){
      alert('Please , you can not choose all')
    }else{
      this.setState({selectedServiceGroup : item.enName , serviceGroupId:item.id,selectedCategories:[]},()=>this.getCategoriesForServiceGroups())
    }
  }

  onSelectImageFromPC = (e, handle) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({image: file,imagePreview:reader.result})
    }
    reader.readAsDataURL(file)
  }


  onSelectedChanged(selectedItems) {
    console.log(selectedItems);
    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({selectedCategories:selectedItems, categoryId:arr},()=>console.log(arr))
  }


  getData(type){
    this.getPromotedServiceGroup(type)
    console.log(1);
  }
  getPromotedServiceGroup(type){
    console.log(2);
    if(this.state.serviceGroupId == ''){
      alert('Please, select service group')
    }else if(this.state.image == ''){
      alert('Please, choose image')
    }else if(type == '' || type.trim() == ''){
      alert('Please, enter type')
    }else if (this.state.categoryId.length == 0) {
      alert('Please, choose category')
    }else{
      let data = {
        serviceGroupId : this.state.serviceGroupId,
        categories : this.state.categoryId
      }
      this.setState({loading:true})
      Services.getPromotedServiceGroup(data,(res)=>{
        if(res.message == "The given data was invalid."){
          alert("Please , enter one or more categories")
        }else {
          this.setState({loading:false})
          let formData = new FormData();
          formData.append('type',type);
          formData.append('id',res.data.promotedServiceGroup.id);
          formData.append('additionalData[image]',this.state.image)
          this.props.createPost(formData)
        }
      })
    }
  }



  render(){
    return(
      <div>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Service Groups :</Label>
          </Col>
          <Col md="4">
            <ServiceGroupFilter bc="#dddddd" bw={1} bgColor="white" handler={this.handlerServiceGroup}/>
          </Col>
        </Row>
        {this.state.selectedServiceGroup == "ServiceGroup" ? null : (
          <Row>
            <Col md="2">
              {this.state.multiSelectCategory.length!=0?(
                <Label style={{fontWeight:'bold'}}>Categories :</Label>
              ):null}
            </Col>
            <Col md="4">
              {this.state.multiSelectCategory.length!=0?(
                <MultiSelect
                  items={this.state.multiSelectCategory}
                  selectedItems={this.state.selectedCategories}
                  onChange={(item)=>this.onSelectedChanged(item)}
                  withGrouping={true}
                  showSelectedItems={false}
                  showSelectAll={true}
                />
              ):null}
            </Col>
          </Row>
        )}
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Images :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              onChange={(e)=>this.onSelectImageFromPC(e, 'arName')}
              name="uploadfile"
              id="img"
              style={{display:'none'}}
              accept="image/x-png,image/jpeg"
            />
            <Label for="img" style={{cursor:'pointer',width:'100%',backgroundColor:'#4dbd74',borderRadius:5,textAlign:'center',padding:7}}>{this.state.image == '' ? 'Upload Image' : this.state.image.name}</Label>
          </Col>
          <Col md='6'>
            <p><strong>Note: </strong>when insert image must the width double the length or upload this dimensions 362*138</p>
          </Col>
        </Row>
        {this.state.imagePreview != '' ? (
          <Row style={{marginTop:15}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview :</Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
            </Col>
          </Row>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
