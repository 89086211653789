import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class CommunicationChannelService {

  static updateChannel(orderId,channel,callBack){
    let data={"communicationChannel": channel == 'push-notification' ? 'push' : 'sms' }
    Adaptor.patch(`${Global.baseURL}admin-panel/orders/${orderId}/communication-channel`,data).then(callBack)
  }


}
