import React from 'react';
import {CardHeader,Row,Col,InputGroup,Input,Button,InputGroupAddon ,ModalBody,Badge ,UncontrolledTooltip,Modal,ModalHeader,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,ModalFooter,Label} from 'reactstrap'


export default class StatusModal extends React.Component{
    constructor(props){
        super(props);
        this.state={
         
        }
    }
    componentDidMount(){
    }

    render(){
        return(
            <div>
            <ModalBody>
            <Row style={{marginLeft:'auto',marginRight:'auto',width:'90%'}}>
              <Col md="2">
                <Label style={{marginTop:5}}>Choose Status : </Label>
              </Col>
              <Col md="4">
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret className='actionsButton' style={{width:'100%',backgroundColor:'white'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />{this.props.selectedStatus}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.props.changeStatusesArr.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.props.handleStatus('statuses',item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
    
          </ModalBody>
            </div>
        )
    }

    
}
