import React, { Component } from 'react'
import { Col, Row ,Input} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import PaymentMethodsServices from '../../../services/paymentMethods/PaymentMethodsServices';
import CustomersServices from '../../../services/customerServices/CustomersServices';

export default class ChangePaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      paymentMethods:[],
      paymentMethod:this.props.order.paymentMethod?this.props.order.paymentMethod.enName:'',
      paymentMethodId:this.props.order.paymentMethod?this.props.order.paymentMethod.id:'',
    }
  }

  componentDidMount(){
    this.getAllPaymentMethods()
  }

  setPaymentMethod(paymentMethod){
    this.setState({paymentMethodId:paymentMethod.id,paymentMethod:paymentMethod.enName})
  }

  changePaymentMethod(){
    if(this.state.paymentMethodId == '' ){
      alert('Please, choose payment method')
    }else {
      let data = {
        paymentMethodId:this.state.paymentMethodId
      }
      console.log(data)
      Services.changePaymentMethod(data,this.props.order.orderId,(res)=>{
        if(res.message == 'payment method already exist'){
          alert('Sorry , payment method already exist')
        }else if(res.message == 'success') {
          console.log(res)
          this.setState({paymentMethod:'',paymentMethodId:''},()=>this.props.reload())
        }
      })
    }
  }

  getAllPaymentMethods(){
    this.setState({loading:true})
    let serviceGroupId = this.props.order.serviceGroup ? this.props.order.serviceGroup.id : null;
    PaymentMethodsServices.get(serviceGroupId, (res) => {
      this.getTemporaryWallet(res.data.paymentMethods)
    })
  }

  getTemporaryWallet(paymentMethods){
    CustomersServices.getTemporaryWallet(this.props.order.customer.id , (res) => {
      if (res.data.credit==0) {
        this.setState({paymentMethods:paymentMethods.filter(e=> e.id!=3), loading:false})
      }else {
        let arr = paymentMethods
        arr.map(e=>{
          if (e.id == 3) {
            e.enName = `${e.enName} (${res.data.credit} LE )`
          }
        })
        this.setState({paymentMethods:paymentMethods, loading:false})
      }
    })
  }


  render(){
    return(
      <>
      <div>
        <Row style={{marginLeft:20}}>
          {this.state.paymentMethods.map((paymentMethod,i) => (
            <Col key={i} md="4">
              <Input type="checkbox" name="cash" checked={this.state.paymentMethodId == paymentMethod.id}  onChange={()=>this.setPaymentMethod(paymentMethod)}/>{` ${paymentMethod.enName}`}
            </Col>
          ))}
        </Row>
      </div>
      <LoadingSpinner loading={this.state.loading}/>
    </>
  )
}
}
