import React from 'react'
import { ChatList } from 'react-chat-elements'
import { animateScroll } from "react-scroll"
import { Row, Col } from 'reactstrap'
import { Picker } from 'emoji-mart'
import { MessageList } from './MessageList/MessageList'
import SingleFixer from './../fixers/SingleFixer'
import LogoImg from './../../assets/img/logo.png'
import Services from './../../services/Services'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/database'
import 'react-chat-elements/dist/main.css'
import './../../assets/style/chat.css'
import 'emoji-mart/css/emoji-mart.css'

class Chats extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      userList:[],
      messages: [],
      wait: true,
      chatRoom:'',
      singleFixer:'',
      msg:'',
      showSingleFixer:false,
      operationId:'',
      showEmoji:false,
      search:'',
      allFixers:[],
      noFixer:'',
      loading:true
    }
  }

  componentDidMount(){
    // this.getArea()
    this.getAllChatRooms()
  }

  getArea(){
    this.setState({loading:true})
    Services.getArea(this.state.selectedCityId, (res)=>{
      if(res.message == 'user_is_blocked') this.props.history.push('/login')
    })
  }

  // getAllChatRooms(){
  //   firebase.database().ref('messages/').on('value', (snapshot) => {
  //     let time7daysAgo = moment(new Date()).subtract(7,'days').toDate()
  //     let allMessages = snapshot.val()
  //     let arr = []
  //     Object.keys(allMessages).filter(key => {
  //       if (Array.isArray(allMessages[key])) {
  //         if (moment(allMessages[key][0].createdAt).toDate() > time7daysAgo) {
  //           arr.push(this.generateMsg(allMessages[key][0], key))
  //         }
  //       }else {
  //         Object.keys(allMessages[key]).filter(megArr => {
  //           allMessages[key][megArr].forEach((item, i) => {
  //             if (moment(item.createdAt).toDate() > time7daysAgo) {
  //               arr.push(this.generateMsg(item, key))
  //             }
  //           });
  //         })
  //       }
  //     })
  //     let sortedArr = arr.sort((a,b)=> new Date(b.date) - new Date(a.date))
  //     this.setState({userList: sortedArr, allFixers:sortedArr, loading:false},()=>this.checkIfOpenFirstChat())
  //   })
  // }
  getAllChatRooms(){
    // database ref

    firebase.database().ref('messages/').on('value', (snapshot) => {
      let newMsgs = []
      let x = 0
      // check if there is no messages on this room
      if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
        // looping for all rooms
        Object.keys(snapshot.val()).forEach(function (key) {

          // get each room length in order to get last msg text and time
          let len = Object.keys(Object.values(snapshot.val())[x]).length -1
          var val = snapshot.val()[key]
          let chatRooms = Object.keys(snapshot.val())
          let obj = val[Object.keys(val)[len]][0]
          // pushing each fixer in array to render it on left-side of page

          if (obj != null) {
            newMsgs.push({
              chatName:chatRooms[x],
              subtitle:obj.text,
              unread: obj.counter,
              avatar:obj.user.image,
              title:val[Object.keys(val)[0]][0].user.enName ? val[Object.keys(val)[0]][0].user.enName : val[Object.keys(val)[0]][0].user.arName,
              date:new Date(obj.createdAt),
            })
          }

          // update index in order to avoid duplicated data using X variable
          x++;
        })
      }

      let time7daysAgo = moment(new Date()).subtract(7,'days').toDate()
      let last7Daysmsg =  newMsgs.filter(item=>moment(item.date).toDate() > time7daysAgo)
      let sotredMsg = last7Daysmsg.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0));
      this.setState({userList: sotredMsg, allFixers:newMsgs, loading:false},()=>this.checkIfOpenFirstChat())
    })
  }
  generateMsg(msg, key){
    let obj = {
      chatName: key,
      subtitle:msg.text,
      unread: msg.counter,
      date:new Date(msg.createdAt),
    }
    if (msg.user) {
      obj.title = msg.user.enName ? msg.user.enName : msg.user.arName
      obj.avatar = msg.user.image
    }else {
      obj.title = ''
      obj.avatar = 'https://api.fixawy.net/storage/images/users/fixers/profiles/ab.png'
    }
    return obj
  }
  checkIfOpenFirstChat(){
    const { state } = this.props.location;
    if (state && state.getChat) {
      this.getChatRoomForSingleFixer(this.state.userList[0].chatName)
    }
  }

  getSingleRoomMessages(x){
    let newMsgs = []
    // set room id using X .. and hide loading view
    this.setState({wait:false, chatRoom:x})
    firebase.database().ref(`messages/${x}`).on('value', (snapshot) => {

      if (snapshot.val().length !== 0) {
        Object.keys(snapshot.val()).forEach(function (key) {
          var val = snapshot.val()[key];
          // return true
          let msgTemplate = {}
          // check if the msg from operation set if on right else ...
          if (val[0].user._id!=undefined && val[0].user._id.toString().includes('operation')) {
            msgTemplate = {id:0, text: val[0].text, date: new Date(val[0].createdAt), position: 'right',type: 'text', admin: ''}
            if (val[0].admin != undefined) {
              msgTemplate.admin = val[0].admin.enName
            }
          }else {
            msgTemplate = {id:val[0].user._id, text: val[0].text, date: new Date(val[0].createdAt), position: 'left',type: 'text', admin: ''}
          }
          newMsgs.push(msgTemplate)
        })
      }
    })
    return newMsgs
  }

  // get room messages
  getChatRoomForSingleFixer(x){
    // remove unread counter
    let obj = {}
    for (var i = 0; i < this.state.userList.length; i++) {
      if (this.state.userList[i].chatName === x) {
        obj = this.state.userList[i];
        obj.unread = 0
        obj.id = this.state.userList[i].chatName
        this.setState({singleFixer:obj},()=>console.log(this.state.singleFixer))
        this.setState(obj)
      }
    }

    firebase.database().ref(`messages/${x}`).once('value', (snapshot) => {
      if (snapshot.val().length !== 0) {
        Object.keys(snapshot.val()).forEach((key)=> {
          // console.log(obj.id);
          // console.log(snapshot.val()[key]);
          if (snapshot.val()[key][0].user.id == x) {
            firebase.database().ref(`messages/${x}`).child(key).child(0).child('counter').set(0)
            // this.getChatRoomForSingleFixer(x)
          }
        })
      }
    })


    // this.props.history.replace('/chats', null);
    this.props.history.replace({
      pathname: '/chats',
      state: {}
    });

    // get messages form firebaseListener
    let m = this.getSingleRoomMessages(x)
    // update chat
    this.setState({ messages: m },()=>this.scrollToBottom())
  }

  sendMsg () {
    let y = this.state.operationId
    // check if input value is empty
    if (this.state.msg === '') {
      alert('Make sure that message is not empty')
    }else {
      this.setState({msg: ''})
      // create msg obj
      let msg = {
        _id: Math.round(Math.random() * 1000000),
        text:this.state.msg,
        createdAt: new Date().toISOString(),
        admin: JSON.parse(localStorage.getItem('adminAccount')),
        user: {
          _id: `operation-${y}`,
          avatar:'https://is5-ssl.mzstatic.com/image/thumb/Purple125/v4/27/bf/ca/27bfcaf8-5e77-e14b-0e75-85ba03816451/AppIcon-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-1.png/1200x630wa.jpg'
        }
      }
      let arr = []
      arr.push(msg)
      console.log(arr);
      firebase.database().ref(`messages/${this.state.chatRoom}`).push(arr)
      this.getChatRoomForSingleFixer(this.state.chatRoom)
    }
  }

  appendEmoji(em){
    let text = this.state.msg
    text += em.native
    this.setState({msg: text, showEmoji:false})
  }
  showProfile(item){
    this.setState({showSingleFixer:true, singleFixer:item})
  }
  reload = () => {
    this.setState({showSingleFixer:false},()=>this.getAllChatRooms())
  }


    scrollToBottom() {
      this.messagesEnd.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    handleChange (e, action) {
      if (action == 'search') {
        this.setState({search: e.target.value},()=>this.implementSearch());
      }else {
        this.setState({msg: e.target.value});
      }
    }

    implementSearch(){
      let arr = this.state.allFixers
      let res = []
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].title&&arr[i].title.toLowerCase().includes(this.state.search.toLowerCase())) {
          res.push(arr[i])
        }
      }
      this.setState({userList:res, noFixer:res.length==0?'No result':''},()=>this.forceUpdate())
    }

    handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        this.sendMsg()
      }
    }

  renderFixerList(){
    return(
      <ChatList
        className='chat-list'
        dataSource={this.state.userList}
        onClick={(fixer)=> this.getChatRoomForSingleFixer(fixer.chatName)}
      />
    )
  }


  renderChat(){
    return(
      <div>
        {this.state.wait?(
          <div>
            <img src={LogoImg} alt='loading..' style={{width:'15%',height:'25%',display:'block',marginTop:'20%', marginLeft:'auto',marginRight:'auto' }} />
            <h1 style={{textAlign:'center', fontSize:35, color:'#545453', marginTop:'3%', fontWeight:'bold'}}>Fixawy Chat  <span className='mdi mdi-message-bulleted' style={{fontSize:25}}/></h1>
          </div>
        ):(
          <div>
            <div className='chatHeader'>
              <img src={this.state.singleFixer.avatar}/>
              <h6 onClick={()=>this.showProfile(this.state.singleFixer)}>{this.state.singleFixer.title}</h6>
            </div>
            <div style={{height: window.innerHeight-350 + 'px',overflow:'scroll'}} ref={(el) => { this.messagesEnd = el; }}>
              <MessageList
                className='message-list'
                lockable={false}
                dataSource={this.state.messages}
              />
            </div>

            <div className="inputMsg">

              <span className="fa fa-smile-o" onClick={()=>this.setState({showEmoji:!this.state.showEmoji})}/>
              {this.state.showEmoji?(
                <Picker set='emojione'
                  style={{ position: 'absolute', bottom: '20px', left: '70px' }}
                  showPreview={false} showSkinTones={false}
                  onSelect={(em)=>this.appendEmoji(em)}
                />
              ):null}

              <textarea
                rows={1}
                type="text" name="value"
                placeholder="Type a message"
                value={this.state.msg}
                onKeyPress={this.handleKeyPress}
                onChange={(e) => {this.handleChange(e, 'message')}}
              />

              <span className="mdi mdi-send" onClick={()=>this.sendMsg()}/>
            </div>


          </div>
        )}
      </div>
    )
  }


  render() {
    return (
      <div style={{margin:0}}>
        {this.state.showSingleFixer?<SingleFixer data={this.state.singleFixer} reload={this.reload}/>:null}

        <Row style={{margin:0}}>
          <Col md="4" lg="4" className="shadow noPadding" style={{backgroundColor:'#efefef'}}>
            <div className="inputMsg" >
              <textarea
                rows={1}
                type="text" name="value"
                placeholder="Search by fixer name.."
                value={this.state.search}
                style={{width:'100%', marginBottom:10}}
                onKeyPress={this.handleKeyPress}
                onChange={(e) => {this.handleChange(e, 'search')}}
              />
              {this.state.search!=''?(
                <span className='fas fa-close' onClick={()=>this.setState({search:'', userList:this.state.allFixers, noFixer:''})} style={{position:'absolute', right:40, fontSize:16, color:'#bdbcbc',top:30}}/>
              ):(
                <span className='fas fa-search' style={{position:'absolute', right:40, fontSize:16, color:'#bdbcbc',top:30}}/>
              )}

            </div>
            {this.state.loading?(
              <i className="fas fa-circle-notch fa-spin loadChat" />
            ):(
              <div style={{overflow:'scroll', height: window.innerHeight-275 + 'px',}}>
                {this.renderFixerList()}
                {this.state.noFixer!=''?(
                  <h6 style={{color:'#999', textAlign:'center', marginTop:'10%'}}>{this.state.noFixer}</h6>
                ):null}
              </div>
            )}

          </Col>

          <Col md="8" lg="8" className='noPadding' style={{backgroundColor:'#efefef', height: window.innerHeight-200 + 'px'}}>
            {this.renderChat()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Chats;
