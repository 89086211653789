import React, { Component } from 'react'
import { Modal ,ModalBody ,ModalHeader ,ModalFooter, Button} from 'reactstrap'

export default class FModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return(
      <Modal fade={false} isOpen={this.props.state} toggle={()=>this.props.onClose()} className={this.props.className}>
        <ModalHeader toggle={()=>this.props.onClose()}>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.content}
        </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn btnClose' onClick={()=>this.props.onClose()}>Close</Button>
          {!this.props.noSubmitBtn?(
            <Button className='actionsBtn' style={{display:this.props.show}} onClick={()=>this.props.onSubmit()}>Confirm</Button>
          ):null}
        </ModalFooter>
      </Modal>
    )
  }
}
