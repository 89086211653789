import React,{Fragment} from 'react'
import {LoadingSpinner} from './../../components'
import {Container,CardHeader,Card,Row,Col,Button,Input, InputGroup, InputGroupAddon, InputGroupText,} from 'reactstrap'
import Services from './../../services/Services'
import {FModal , AddPost, AddBackground} from './../../components'
import ListPosts from './ListPosts'
import Logo from './../../assets/img/logo.png'
import firebase from 'firebase/app'
import 'firebase/database'


export default class Posts extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      postModal:false,
      backgroundModal:false
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
  }

  handleAddPost(){
    this.setState({postModal : !this.state.postModal})
  }
  handleAddBackground(){
    this.setState({backgroundModal : !this.state.backgroundModal})
  }


  render(){
    return(
      <div className="app align-items-center">
        <Container>
          <Card>
            {this.renderHeader()}
            <ListPosts ref={ref => this.listPostsRef = ref}/>
          </Card>
        </Container>
        <FModal
          title='Add Background'
          content={
            <AddBackground
              ref={ref => this.addBackgroundRef = ref}
              update={()=>this.setState({backgroundModal : false})}
            />
          }
          state={this.state.backgroundModal}
          onClose={()=>this.handleAddBackground()}
          onSubmit={()=>this.addBackgroundRef.addBackground()}
        />
        <FModal
          title='Add Post'
          content={
            <AddPost
              ref={ref => this.addPostRef = ref}
              update={()=>this.setState({postModal : false},()=>this.listPostsRef.update())}
            />
          }
          state={this.state.postModal}
          onClose={()=>this.handleAddPost()}
          onSubmit={()=>this.addPostRef.addPost()}
        />
      </div>
  )
}

renderHeader(){
  return (
    <CardHeader className='shadow'>
      <Row>
        <Col md="6" style={{display:'flex',alignItems:'center'}}>
          <i className="fa fa-align-justify" ></i>
          <span style={{marginLeft:6}}>All Posts</span>
        </Col>
        <Col md="3" style={{display:'flex',alignItems:'right'}}>
          <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',width:'100%'}} onClick={()=>this.handleAddBackground()}><i className='fa fa-plus-square' style={{marginRight:8}}/>Add Background</Button>
        </Col>
        <Col md="3" style={{display:'flex',alignItems:'right'}}>
          <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',width:'100%'}} onClick={()=>this.handleAddPost()}><i className='fa fa-plus-square' style={{marginRight:8}}/>Add New Post</Button>
        </Col>
      </Row>
    </CardHeader>
  )
}

}
