import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import Switch from "react-switch";
import firebase from 'firebase/app'
import 'firebase/database'
import {FModal , CreateRole ,RoleDetails,AgentsRole,ActionsRole} from './../../components'
import PermissionsService from './../../services/permissions/PermissionsService'
class Permissions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      noResult:false,
      createRoleModal:false,
      roleDetailsModal:false,
      agentRoleModal:false,
      actionsRoleModal:false,
      title:'',
      roles:[],
      permissions:[],
      admins:[]
    }

  }

  componentDidMount(){
    // this.getArea() //check if is login
    window.scrollTo(0, 0)
   this.getRoles()
  }

 handleCreateRole(type){
   if(type == 'create'){
       this.setState({title:'Add Role'})
   }else if(type == 'edit') {
       this.setState({title:'Edit Role'})
   }
   this.setState({createRoleModal:!this.state.createRoleModal})
 }

 handleDetailsModal(item){
    this.setState({roleDetailsModal:true,details:item.description})
  }

  handleActionsModal(item){
    this.setState({actionsRoleModal:true,roleId:item.id})
  }
  handleAgentsModal(item){
    this.setState({agentRoleModal:true,roleId:item.id},()=>this.getAllAdmins())
  }

  getRoles(){
    PermissionsService.getRoles((res)=>{
      if(res.message == 'forbidden'){
        this.props.history.push('/403');
      }else{
        console.log(res)
        this.setState({roles:res.data})
      }
    })
  }


   getAllAdmins(){
    this.setState({loading:true})
    Services.getAllAdmins((res)=>{
      this.setState({admins : res.data.admins , loading:false})
    })
  }


  render() {
    return (
      <div className="app align-items-center">
        <Container>

          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderRoles()}

              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>


          <FModal
            title={this.state.title}
            content={
              <CreateRole
                ref={ref=>this.createRoleRef=ref}
                update={()=>this.setState({createRoleModal:false},()=>this.getRoles())}
              />
            }
            state={this.state.createRoleModal}
            onClose={()=>this.handleCreateRole()}
            onSubmit={()=>this.createRoleRef.createRole()}
          />

         <FModal
          title={"Role Details"}
          content={
              <RoleDetails
              ref={ref=>this.roleDetailsRef=ref}
              details={this.state.details}
              />
          }
          state={this.state.roleDetailsModal}
          onClose={()=>this.setState({roleDetailsModal:false})}
          show="none"
        />

          <FModal
          title={"Actions Role"}
          content={
              <ActionsRole
              ref={ref=>this.actionsRoleRef=ref}
              roleId={this.state.roleId}
              update={()=>this.setState({actionsRoleModal:false})}

              />
          }
          state={this.state.actionsRoleModal}
          onClose={()=>this.setState({actionsRoleModal:false})}
          show="none"
        />



          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Permissions</span>
          </Col>

          <Col md="4">
            <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',marginLeft:'auto'}} onClick={()=>this.handleCreateRole('create')}><i className='fa fa-plus-square' style={{marginRight:8}}/>Add Role</Button>

          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderRoles(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>Role Name</th>
            <th style={{textAlign:'center'}}>Details</th>
            <th style={{textAlign:'center'}}>Actions</th>
            <th style={{textAlign:'center'}}>Date And Time</th>
          </tr>
        </thead>
        <tbody>
          {this.state.roles.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{item.id}</td>
              <td style={{textAlign:'center'}}>{item.displayName}</td>
              <td style={{textAlign:'center'}}>
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.handleDetailsModal(item)}>View Details</span>
              </td>
              <td style={{textAlign:'center'}}>
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.handleActionsModal(item)}>View Actions</span>
              </td>
              <td style={{textAlign:'center'}}>{item.createdAt != null ? item.createdAt.date : 'N/A'}</td>
            </tr>
         ))}
        </tbody>
      </Table>
    )
  }





}

export default Permissions;
