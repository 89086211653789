import React from 'react'
import {Button as RButton } from 'reactstrap'
class Button extends React.Component{
  render(){
    return(
      <RButton
        disabled={this.props.disabled}
        className={this.props.badge?"":"click"}
        style={{
          backgroundColor:'transparent',
          borderColor:'transparent',
          padding:(this.props.padding ? this.props.padding : 10 ),
          fontWeight:'bold',
          minWidth:(this.props.minWidth ? this.props.minWidth : 100 )
        }}
        onClick={this.props.handler}
      >
        {this.props.children}
      </RButton>
      )
    }
  }
  export default Button;
