import React from 'react';
import LogoImg from './../assets/img/logo.png'
class AuthorizationError extends React.Component {
    render() {
        return (<div style={{textAlign:'center'}}>
          <div>
              <img src={LogoImg} alt='loading..' style={{width:'15%',height:'25%',display:'block',marginTop:'10%', marginLeft:'auto',marginRight:'auto' }} />
              <h1 style={{textAlign:'center', fontSize:25, color:'#545453', marginTop:'3%', fontWeight:'bold'}}>Fixawy <span className='mdi mdi-message-bulleted' style={{fontSize:25}}/></h1>
          </div>
          <p style={{fontWeight:'bold'}}> Sorry You Are Not Authorized To View This Page Please Contact Your Manager To Allow You To Take That Permession </p>
        </div>);
    }
}

export default AuthorizationError;
