import React from 'react';

// pages
import Customers from './../views/customers/Customers'
import Fixers from './../views/fixers/Fixers'
import Admins from './../views/admins/Admins'
import Orders from './../views/orders/Orders'
import PromoCode from './../views/promoCode/PromoCode'
import Complain from './../views/complain/Complain'
import Report from './../views/reports/Report'
import Trello from './../views/trello/Trello'
import ContactUS from './../views/contactus/ContactUs'
import TrackingFixers from './../views/map/TrackingFixers'
import Chats from './../views/chats/Chats'
import Conditions from './../views/workContracts/Conditions'
import Packages from './../views/workContracts/Packages'
import WorkContracts from './../views/workContracts/WorkContracts'
import ReservationRequests from './../views/workContracts/ReservationRequests'
import ApprovedReservations from './../views/workContracts/ApprovedReservations'
import EmergencyNumber from './../views/emergencyNumbers/EmergencyNumbers'
import CanceledAndFailedReasons from './../views/reports/CanceledAndFailedReasons'
import Inquiries from './../views/inquiries/Inquiries'
import FixersSchedule from '../views/fixers/FixersSchedule';
import TrackFixersProfile from '../views/fixers/TrackFixersProfile';

import CustomersEquipments from '../views/customers/CustomersEquipments';
import ServiceGroups from '../views/featuredServiceGroups/ServiceGroups';
import Items from '../views/items/Items';
import Posts from '../views/posts/Posts';
import SearchEngine from '../views/searchEngine/SearchEngine';
import ChatReplay from '../views/chats/ChatReplay';
import Blogs from './../views/blogs/Blogs'
import PriceEstimation from '../views/estimation/PriceEstimation'


import ListComplaints from '../views/complaints/ListComplaints';
import ReceivedOrdersPerMonth from '../views/reports/ReceivedOrdersPerMonth';
import WorkContractReport from '../views/reports/WorkContractReport';
import AdminsPoints from '../views/admins/AdminsPoints';
import OrderStatusReport from '../views/reports/OrderStatusReport';

import ListScheduales from '../views/scheduale/ListScheduales';
import OrdersRate from '../views/orders/Rates';
import Permissions from '../views/permissions/Permissions'
import PriceOffers from '../views/offers/PriceOffers';
import OrdersRequests from '../views/orders/Requests';

import Tasks from '../views/tasks/Tasks';


import Categories from '../views/categories/Categories';

import AuthorizationError from '../views/403.js'
import Quotations from '../views/quotations/Quotations';


const routes = [
  { path: '/customers', name: 'Customers', component: Customers, exact: true },
  { path: '/equipmentRequests', name: 'Equipment Requests', component: CustomersEquipments, exact: true },
  { path: '/emergencyNumbers', name: 'Emergency Numbers', component: EmergencyNumber, exact: true },
  { path: '/serviceGroups', name: 'Service Groups', component: ServiceGroups, exact: true },
  { path: '/admins', name: 'Admins', component: Admins, exact: true },
  { path: '/adminsPoints', name: 'Admins Points', component: AdminsPoints , exact: true },
  { path: '/fixers', name: 'Fixers', component: Fixers, exact: true },
  { path: '/fixersSchedule', name: 'Fixers Schedule', component: FixersSchedule, exact: true },
  { path: '/orders', name: 'Orders', component: Orders, exact: true },
  { path: '/chats', name: 'Chats', component: Chats, exact: true },
  { path: '/trackFixersProfile', name: 'Track Fixers Profile', component: TrackFixersProfile, exact: true },
  { path: '/trackingFixers', name: 'Tracking Fixers', component: TrackingFixers, exact: true },
  { path: '/reports/registedCustomerPerDay',name: 'Registed Customer Per Day', component: Report, exact: true },
  { path: '/reports/recivedOrderPerDay', name: 'Recived Order Per Day', component: Report, exact: true },
  { path: '/reports/cancelOrderPerDay', name: 'Cancel Order Per Day', component: Report, exact: true },
  { path: '/reports/DoneOrderPerDay', name: 'Done Order Per Day', component: Report, exact: true },
  { path: '/reports/CanceledAndFailedReasons', name: 'Canceled And Failed Reasons', component: CanceledAndFailedReasons, exact: true },
  { path: '/reports/receivedOrderPerMonth', name: 'Recived Order Per Month', component: ReceivedOrdersPerMonth, exact: true },
  { path: '/promoCode', name: 'Promo Code', component: PromoCode, exact: true },
  { path: '/trelloSynchronization', name: 'Trello Synchronization', component: Trello, exact: true },
  { path: '/contactus', name: 'Contact us', component: ContactUS, exact: true },
  { path: '/complaints/:id', name: 'Complain', component: Complain, exact: true },
  { path: '/orders/:id', name: 'Orders', component: Orders, exact: true },
  { path: '/conditions', name: 'Conditions', component: Conditions, exact: true },
  { path: '/packages', name: 'Packages', component: Packages, exact: true },
  { path: '/workingContracts', name: 'Working Contract', component: WorkContracts, exact: true },
  { path: '/reservationRequests', name: 'Reservation Requests', component: ReservationRequests, exact: true },
  { path: '/approvedReservations', name: 'Approved Reservations', component: ApprovedReservations, exact: true },
  { path: '/inquiries', name: 'Inquiries', component: Inquiries, exact: true },
  { path: '/items', name: 'Items', component: Items, exact: true },
  { path: '/posts', name: 'Posts', component: Posts, exact: true },
  { path: '/searchEngine', name: 'Search Engine', component: SearchEngine, exact: true },
  { path: '/chat/replay', name: 'Chat Replay', component: ChatReplay , exact: true },

  { path: '/estimation', name: 'Price Estimation', component: PriceEstimation , exact: true },
  { path: '/articles', name: 'Articles', component: Blogs , exact: true },
  { path: '/complaints', name: 'Complaints', component: ListComplaints , exact: true },
  { path: '/reports/workContract', name: 'Complaints', component: WorkContractReport , exact: true },
  { path: '/reports/orderStatus', name: 'Complaints', component: OrderStatusReport , exact: true },
  { path: '/scheduales', name: 'Scheduales', component: ListScheduales , exact: true },
  { path: '/ordersRate', name: 'OrdersRate', component: OrdersRate , exact: true },
  { path: '/tasks', name: 'Tasks', component: Tasks , exact: true },
  { path: '/permissions', name: 'Permissions', component: Permissions , exact: true },
  { path: '/priceOffers', name: 'priceOffers', component: PriceOffers , exact: true },
  { path: '/ordersRequests', name: 'Orders Requests', component: OrdersRequests , exact: true },
  { path: '/categories', name: 'Categories', component: Categories , exact: true },
  { path: '/403', name:'Permession Denied' , component: AuthorizationError , exact:true },
  { path: '/quotations', name: 'Quotations', component: Quotations , exact: true },


];

export default routes;
