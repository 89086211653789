import React from 'react'
import Table from './../Table/Table'
import TR from './../Table/TR'
import TD from './../Table/TD'
import TH from './../Table/TH'
import TableBody from './../Table/TableBody'
import TableHeader from './../Table/TableHeader'
import {Modal,ModalBody,ModalFooter,ModalHeader,Button} from 'reactstrap'
import NoResult from './../NoResult'
import moment from 'moment'
class IntervalDetailsModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            intervals:[],
        }
    }
    static getDerivedStateFromProps(props, state){
        return state.intervals=props.Intervals
    }
    timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + " hour(s) and " + rminutes + " minute(s).";
    }

    render(){
        // console.log(this.props.Intervals)
        // console.log(this.state.intervals)
        return(
            <Modal fade={false}
                    isOpen={this.props.show}
                    toggle={this.props.closeHandler} className={this.props.className}>
              <ModalHeader toggle={this.props.closeHandler}>Intervals Details</ModalHeader>
              <ModalBody>
                  {this.state.intervals.length?
                  <Table>
                    <TableHeader>
                        <TH>Start Time</TH>
                        <TH>End Time</TH>
                        <TH>Accumlative Time</TH>
                    </TableHeader>
                    <TableBody>
                        {this.state.intervals.map((interval,index) =>
                            (<TR key={index}>
                                <TD>{interval.startPoint?moment(interval.startPoint).format('MMMM Do YYYY, h:mm a'):'N/A'}</TD>
                                <TD>{interval.endPoint?moment(interval.endPoint).format('MMMM Do YYYY, h:mm a'):'Still Working'}</TD>
                                <TD>{interval.accumulativeTime?this.timeConvert(interval.accumulativeTime):'N/A'}</TD>
                            </TR>))}
                    </TableBody>
                  </Table>
                  :<NoResult/>}
              </ModalBody>
              <ModalFooter>
                <Button className='actionsBtn' onClick={this.props.closeHandler}>Close</Button>
              </ModalFooter>
            </Modal>
        )
    }
}
export default IntervalDetailsModal;
