import { Global } from './../../core/Global'
import Adaptor from './../Adaptor'

export default class ComplaintsServices{

  static listComplaintsCustomer(customerId,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/customers/${customerId}/complaints`).then(callBack)
  }

}
