import React, { Component } from 'react'
import {Table} from 'reactstrap'
import "@kenshooui/react-multi-select/dist/style.css"
import {LoadingSpinner,NoResult} from './../../components'



export default class ViewHistory extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
      console.log(this.props.complaint)
  }

  

  render(){
    return(
      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        {this.props.complaint.complaintActions.length == 0 ? (
            <div>
                <NoResult/>
            </div>
        ):(
            <div>
               {this.renderHistory()}
            </div>
        )}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
)
}

renderHistory(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center',width:'10%'}}>ID</th>
            <th style={{textAlign:'center',width:'20%'}}>Action Name</th>
            <th style={{textAlign:'center'}}>Description</th>
            <th style={{textAlign:'center',width:'20%'}}>Date And Time</th>
          </tr>
        </thead>
        <tbody>
            {this.props.complaint.complaintActions.map((action,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center',width:'10%'}}>{action.actionId}</td>
              <td style={{textAlign:'center',width:'20%'}}>{action.action.action}</td>
              <td style={{textAlign:'center'}}>{action.description}</td>
              <td style={{textAlign:'center',width:'20%'}}>{action.createdAt}</td>
            </tr>
            ))}
        </tbody>
      </Table>
    )
  }

}
