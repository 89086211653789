import React from 'react'
import firebase from 'firebase/app'
import 'firebase/database'
import {Table,TableBody,TableHeader,TD,TH,TR,Toggler,NoResult,LoadingSpinner} from './../../components'
import {Container,Card,CardHeader,CardBody,InputGroup,Button,FormGroup,Label ,Row,Col} from 'reactstrap'
import EmergencyModal from './EmergencyModal'
import AddNumberModal from './AddNumberModal'
import EmergencyService from './../../services/emergencyServices/EmergencyService'
import Services from './../../services/Services'
import moment from 'moment'
class EmergencyNumbers extends React.Component{
  constructor(props){
    super(props)
    this.state={
      firebasePass:'',
      showEmergencyModal:false,
      showAddPhoneModal:false,
      singleNumber:'',
      loading:false,
      data:[],
    }
  }
  componentDidMount(){
    this.fetchNumbers()
    this.getPassWordFromFirebase()
  }
  getPassWordFromFirebase(){
    firebase.database().ref('general/').on('value', (snapshot) => {
      if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
        Object.keys(snapshot.val()).forEach( (key) => {
          let val = snapshot.val()[key]
          if(key == "emergencyPhonePassword"){
            this.setState({firebasePass:val})
          }
        })
      }
    })
  }
  togglerHandler(item){
    this.setState({singleNumber:item},()=>this.displayEmergencyModal())
  }
  displayEmergencyModal(){
    this.setState({showEmergencyModal:true})
  }
  displayAddNewPhone(){
    this.setState({showAddPhoneModal:true})
  }

  fetchNumbers(){
    this.setState({loading:true})
    EmergencyService.getEmergencyNumbers((res)=>{
      let arr = res.data.emergencyPhones
      arr.forEach(element => {
        element.isOpened ? element.statusId = 1 : element.statusId = 0
      })
      this.setState({data:arr,loading:false})
    })
  }

  reload(){
    this.setState({loading:true},()=>this.fetchNumbers())
  }

  render(){
    return(
      <div className="app align-items-center">
        <Container>
          <Card>
            <CardHeader className="shadow">
              <Row>
                <Col md="8" style={{display:'flex',alignItems:'center'}}>
                  <i className="fa fa-align-justify" ></i>
                  <span style={{marginLeft:6}}>Emergency Phone Numbers</span>
                </Col>
                <Col md="4">
                  <Button
                    className="actionsBtn"
                    style={{marginBottom: "2%",display: "block",marginLeft: "auto"}}
                    onClick={()=>this.displayAddNewPhone()}>
                    <i className="fa fa-plus-square" style={{ marginRight: 8 }} />
                    Add Phone Number
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.data.length?
                <Table>
                  <TableHeader>
                    <TH> Id</TH>
                    <TH> Phone Numbers</TH>
                    <TH> Status</TH>
                    <TH> Start Time</TH>
                    <TH> End Time</TH>
                  </TableHeader>
                  <TableBody>
                    {this.state.data.map( (item,index) => (
                      <TR key={index}>
                        <TD> {item.id} </TD>
                        <TD> {item.phone} </TD>
                        <TD> <Toggler handler={()=>this.togglerHandler(item)} status={item.statusId}/> </TD>
                        <TD> {item.statusId?item.validFrom:'N/A'} </TD>
                        <TD> {item.statusId?item.validTo:'N/A'} </TD>
                      </TR>
                    ))}
                  </TableBody>
                </Table>
                :this.state.loading?null:<NoResult/>}
              </CardBody>
            </Card>
          </Container>
          <LoadingSpinner fade loading={this.state.loading}/>
          <EmergencyModal  firebasePass={this.state.firebasePass}
            show={this.state.showEmergencyModal}
            number={this.state.singleNumber}
            closeHandler={()=>this.setState({showEmergencyModal:false,singleNumber:''},()=>this.reload())}
            toggleHandler={()=>this.setState({showEmergencyModal:!this.state.showEmergencyModal},()=>this.reload())}
          />
          <AddNumberModal firebasePass={this.state.firebasePass}
            show={this.state.showAddPhoneModal}
            closeHandler={()=>this.setState({showAddPhoneModal:false},()=>this.reload())}
            toggleHandler={()=>this.setState({showAddPhoneModal:!this.state.showAddPhoneModal},()=>this.reload())}/>
          </div>
        )
      }
    }
    export default EmergencyNumbers;
