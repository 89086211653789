import React, { Component } from 'react'
import { Button, Row, Col, Table, Modal, ModalBody, ModalHeader, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input} from 'reactstrap'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import firebase from 'firebase/app'
import 'firebase/database'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

let myTimeHoure = ''
let myTimeMinutes = ''

class PricingOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleOrder:this.props.data,
      cardItems:[],
      pricingItems:[],
      meterAndQuantityItems:[],
      selectedMeterAndQuantityItems:[],
      selectedHour:'Choose',
      selectedMinutes:'Choose',
      selectedPricingTypes:'Choose',
      fixingPrice:'',
      toolsPrice:'',
      fixingHours:'',
      fixingMinutes:'',
      currentPricing:'',
      pricingDesc:'',
      otherItem:'',
      loading:false,
      modalPrice:true,
      showMinutes:true,
      showOtherItem:false,
      allPeriods:Global.allPeriods,
      estimatePrice:'',
      modalChangeEstimatedOrderPricing:false,
      changePricingEstimatedOrderPassword:'',
      password:'',
      allItemsPage:1,
      showMeterAndQuantityOtherItem:false,
      meterAndQuantityOtherItem:{enName:'',fixingPrice:'',toolsPrice:'',otherItem:true},
      showCompensationModal:false,
      compensationAmount:0,
      amountToCompensate:'',
    }
    this.handleChangeInputs = this.handleChangeInputs.bind(this)
  }

  componentDidMount(){
    this.getFixawyPricingItems()
    this.getMeterAndQuantityItems()
    if(this.props.data.fromComplaint){
      this.getCompensationAmount();
    }

    if (this.state.singleOrder.sourceCompanyId) {
      let arr = this.state.singleOrder.items
      for (var i = 0; i < arr.length; i++) {
        arr[i].count = arr[i].quantity
      }
      this.setState({selectedPricingTypes:'Meter Or Quantity', currentPricing:4, selectedMeterAndQuantityItems:arr})
    }else if (this.state.singleOrder.estimation!=null) {
      let arr = this.state.singleOrder.items
      for (var i = 0; i < arr.length; i++) {
        arr[i].count = arr[i].quantity
      }
      this.setState({selectedPricingTypes:'Fixawy Pricing', currentPricing:2, cardItems:arr})
    }else if (this.props.data.offer!=null) {
      let itemsArr=this.props.data.offer.pricingItems;
      for (let i = 0; i < itemsArr.length; i++) {
        itemsArr[i].count = itemsArr[i].quantity?itemsArr[i].quantity:1;
      }
      // this.setState({selectedPricingTypes:'Fixawy Pricing', currentPricing:2, cardItems:itemsArr})
    }
  }
  componentWillReceiveProps(nextProps) {
    if (global.showPriceModle) {
      this.setState({cardItems:[], selectedMeterAndQuantityItems:[]})
      if (this.state.singleOrder.sourceCompanyId) {
        let arr = []
        for (var i = 0; i < nextProps.data.items.length; i++) {
          arr.push(nextProps.data.items[i])
        }

        for (var i = 0; i < arr.length; i++) {
          arr[i].count = arr[i].quantity
        }
        this.setState({singleOrder:nextProps.data,selectedPricingTypes:'Meter Or Quantity', currentPricing:4, selectedMeterAndQuantityItems:arr})
      }else if (nextProps.data.estimation!=null) {
        let arr = []
        for (var i = 0; i < nextProps.data.items.length; i++) {
          arr.push(nextProps.data.items[i])
        }

        for (var i = 0; i < arr.length; i++) {
          arr[i].count = arr[i].quantity
        }
        this.setState({singleOrder:nextProps.data,selectedPricingTypes:'Fixawy Pricing', currentPricing:2, cardItems:arr})
      }
      this.setState({singleOrder:nextProps.data},()=>this.getFixawyPricingItems())
    }
  }


  extractPricingInformationFromOrder(){
    return this.props.data.category.pricingModels.filter(item => item.id != 3).map((item)=>{
      if(item.name == 'Fixawy'){
        return  { id: 2, name: "Fixawy Pricing" }
      }else if( item.name == 'Fixer'){
        return { id: 1, name: "Marama" }
      }else {
        return item
      }
    });
  }


  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'fixingPrice':
      this.setState({fixingPrice:e.target.value})
      break
      case 'toolsPrice':
      this.setState({toolsPrice:e.target.value})
      break
      case 'pricingDesc':
      this.setState({pricingDesc:e.target.value})
      break
      case 'otherItem':
      this.setState({otherItem:e.target.value})
      break
      case 'password':
      this.setState({password: e.target.value})
      break
      default:
    }
  }

  getFixawyPricingItems( page = 1){
    console.log('getFixawyPricingItems', this.state.allItemsPage);
    this.setState({loading:true})

    Services.getFixawyPricingItems(this.state.singleOrder.city.id, this.state.singleOrder.category.id,page, (res)=>{
      if (res.message=='success') {
        this.setState((prevState) => ({pricingItems:[...prevState.pricingItems,...res.data.pricingItems],modalPrice:true,loading:false}),
          res.data.paginationLinks.nextPages.length?this.getFixawyPricingItems(page+1):null
        )
      }
    })
  }

  getMeterAndQuantityItems(page = 1){
    this.setState({loading:true})
    Services.getMeterAndQuantityItems(page,this.state.singleOrder.category.id,this.state.singleOrder.city.id,(res)=>{
      // looping to get all paginated items
      this.setState((prevState)=>({meterAndQuantityItems:[...prevState.meterAndQuantityItems,...res.data.pricingItems], modalPrice:true, loading:false}),
      res.data.paginationLinks.nextPages.length?this.getMeterAndQuantityItems(page+1):null)
    })

    setTimeout(()=>{
      let orderItems = this.state.selectedMeterAndQuantityItems
      let items = this.state.meterAndQuantityItems
      let arr = []
      items.map(e=>{
        let cities = e.cities.filter(e=> e.id == this.state.singleOrder.city.id)
        e.cities = cities
        orderItems.map(a=>{
          e.count = a.quantity
          if (e.id == a.id) arr.push(e)
        })
        if (e.children.length != 0) {
          e.children.map(child=>{
            let cities = child.cities.filter(ch=> ch.id == this.state.singleOrder.city.id)
            child.cities = cities
          })
        }
      })
      for (var i = 0; i < arr.length; i++) {
        arr[i].price = Number(arr[i].cities[0].fixingPrice) + Number(arr[i].cities[0].toolsPrice)
      }
      this.setState({selectedPricingTypes:'Meter Or Quantity', currentPricing:4, selectedMeterAndQuantityItems:arr},()=>this.forceUpdate())
    },1000)
  }

  handlePricingModal(){
    if (this.state.modalPrice !== false) {
      this.clearState()
      this.setState({modalPrice:!this.state.modalPrice,})
    }else {
      this.getFixawyPricingItems()
    }
  }

  clearState(){
    global.showPriceModle = false
    myTimeHoure = ''
    myTimeMinutes = ''
    this.setState({
      singleOrder:'',
      modalPrice:false,
      cardItems:[],
      selectedHour:'Choose',
      selectedMinutes:'Choose',
      selectedPricingTypes:'Choose',
      fixingPrice:'',
      toolsPrice:'',
      fixingHours:'',
      fixingMinutes:'',
      currentPricing:'',
      pricingDesc:'',
      otherItem:'',
      loading:false,
      showMinutes:true,
      showOtherItem:false,
      showMeterAndQuantityOtherItem:false,
      meterAndQuantityOtherItem:{enName:'',fixingPrice:'',toolsPrice:'',otherItem:true},
      meterAndQuantityItems:this.resetItems(),
      selectedMeterAndQuantityItems:[],
    })
  }

  handleChangeEstimatedOrderPricing(){
    if (this.state.changePricingEstimatedOrderPassword=='') {
      firebase.database().ref('general/').on('value', (snapshot) => {
        if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
          Object.keys(snapshot.val()).forEach( (key) => {
            let val = snapshot.val()[key]
            if (key == 'changePricingEstimatedOrder') {
              this.setState({changePricingEstimatedOrderPassword:val})
            }
          })
        }
      })
    }

    this.setState({modalChangeEstimatedOrderPricing: !this.state.modalChangeEstimatedOrderPricing,})
  }

  handleFixingTime(type, item){
    switch (type) {
      case 'hour':
      if (item.id === 4) {
        myTimeHoure = item.value
        this.setState({selectedHour:item.name, fixingHours: item.value, showMinutes:false, fixingMinutes:0, selectedMinutes:'Choose'},()=>console.log(this.state.fixingHours))
      }else {
        myTimeHoure = item.value
        this.setState({selectedHour:item.name, fixingHours: item.value, showMinutes:true},()=>console.log(this.state.fixingHours))
      }
      break
      case 'minute':
      myTimeMinutes = item.value
      this.setState({selectedMinutes:item.name, fixingMinutes: item.value,},()=>console.log(this.state.fixingMinutes))
      break
      case 'pricingTypes':
      if (item.id != this.state.currentPricing) {
        if (item.id == 1 && this.state.singleOrder.estimation!=null) {
          this.handleChangeEstimatedOrderPricing()
        }else {
          if (item.id == 2) {
            if (this.props.data.offer!=null) {
              let itemsArr=this.props.data.offer.pricingItems;
              for (let i = 0; i < itemsArr.length; i++) {
                itemsArr[i].count = itemsArr[i].quantity?itemsArr[i].quantity:1;
              }
              this.setState({selectedPricingTypes:'Fixawy Pricing', currentPricing:2, cardItems:itemsArr})
            }else {
              this.setState({selectedPricingTypes:'Fixawy Pricing', currentPricing:2, cardItems:[]})
            }
          }else {
            this.setState({selectedPricingTypes:item.name, currentPricing:item.id, cardItems:[]})
          }
        }
        this.setState({estimatePrice:'', password:'',selectedMeterAndQuantityItems:[],meterAndQuantityItems:this.resetItems(),meterAndQuantityOtherItem:{enName:'',fixingPrice:'',toolsPrice:'',otherItem:true},showMeterAndQuantityOtherItem:false})
      }
      break
      default:
    }
    this.estimatingPrice()
  }

  addToCard(item, type){
    let arr = this.state.cardItems
    let pos = arr.map(function(e) { return e.id; }).indexOf(item.id)
    if (pos === -1) {
      item.count = 1
      item.type = type
      arr.push(item)
      this.setState({cardItems:arr},()=>console.log('yas'))
    }else {
      alert('This item is already added')
    }
    this.estimatingPrice()
    this.forceUpdate()
  }

  removeFromCard(item){
    let arr = this.state.cardItems
    let pos = arr.map(function(e) { return e; }).indexOf(item)
    arr.splice(pos, 1)
    this.setState({cardItems:arr},()=>this.estimatingPrice(),this.forceUpdate())
  }

  increaseItemCount(item){
    let arr = this.state.cardItems
    let numberOfCount = item.count
    let pos = arr.map(function(e) { return e; }).indexOf(item)
    arr[pos].count = numberOfCount+1
    this.setState({cardItems:arr},()=>this.estimatingPrice(),this.forceUpdate())
  }
  decreaseItemCount(item){
    let numberOfCount = item.count
    if (numberOfCount !== 1) {
      let arr = this.state.cardItems
      let pos = arr.map(function(e) { return e; }).indexOf(item)
      arr[pos].count = numberOfCount-1
      this.setState({cardItems:arr},()=>this.estimatingPrice(),this.forceUpdate())
    }else {
      confirmAlert({
        title: 'Fixawy',
        message: `Are you sure to delete ${item.enName}`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.removeFromCard(item)
          },
          {
            label: 'No',
            onClick: () => console.log('cancel')
          }
        ]
      })
    }
  }

  addOtherItem(){
    if (this.state.otherItem === '') {
      alert('Please, enter other item ')
    }else {
      let obj ={
        enName: this.state.otherItem,
        id: this.state.otherItem
      }
      this.addToCard(obj, 'otherItems')
      this.setState({showOtherItem:false, otherItem:''})
    }
    // this.estimatingPrice()
  }



  pricingOrder(){
    let reqData = {}
    if (this.state.currentPricing === 1) {
      if (this.state.fixingPrice === '') {
        alert('Please, enter fixing price')
      }else if (this.state.toolsPrice === '') {
        alert('Please, enter tools price')
      }else if (this.state.pricingDesc === '') {
        alert('Please, enter description')
      }else {
        reqData.fixingPrice = this.state.fixingPrice
        reqData.toolsPrice = this.state.toolsPrice
        if (this.state.pricingDesc !== '')
        reqData.description = this.state.pricingDesc

        this.pricing(reqData)
      }
    }else if (this.state.currentPricing === 2) {
      console.log('fixawy pricing');
      console.log(this.state.cardItems);
      let time = myTimeHoure + myTimeMinutes
      if (time === 0 || time == '') {
        alert('Please, select time')
      }else if (this.state.cardItems.length === 0) {
        alert('Please, select one item at least')
      }else {
        let arr = this.state.cardItems
        let defaultItems = []
        let otherItems = []

        for (var i = 0; i < arr.length; i++) {
          if (arr[i].type === 'otherItems') {
            let obj = {
              name: arr[i].enName,
              numberOfItems: arr[i].count
            }
            otherItems.push(obj)
          }else {
            let obj = {
              itemId: arr[i].id,
              numberOfItems: arr[i].count
            }
            defaultItems.push(obj)
          }
        }
        reqData.time = time
        if (defaultItems.length !== 0) {
          reqData.items = defaultItems
        }
        if (otherItems.length) {
          reqData.otherItems = otherItems
        }
        this.pricing(reqData)
      }
    }else if(this.state.currentPricing === 4) {
      if (this.state.selectedMeterAndQuantityItems.length==0) {
        alert('please, add atleast one Item');
        return ;
      }
      let reqData =  {
        items: this.state.selectedMeterAndQuantityItems.filter(item => !item.otherItem ).map((item)=>{
          return {
            itemId:item.id,
            numberOfItems:item.count,
            fixingPrice:true,
            toolsPrice:true,
            // toolsPrice:item.selectedPrice == 'total',
          }
        }),
        otherItems:this.state.selectedMeterAndQuantityItems.filter(item => item.otherItem).map((item)=>{
          return {
            name:item.enName,
            numberOfItems:1,
            fixingPrice:item.fixingPrice,
            toolsPrice:item.toolsPrice,
          }
        })

      };
      this.pricing(reqData)
    }else{
      alert('Please, select pricing type')
    }

  }

  pricing(reqData){
    console.log(reqData);
    let type = ''
    if (this.state.singleOrder.quotation) {
      type = 'quotation'
      reqData.quotationId = this.state.singleOrder.quotation.id
    }else {
      this.state.currentPricing === 2 ? type = 'fixawy' : this.state.currentPricing == 4 ? type= 'meter_or_quantity' : type = 'marama'
    }

    this.setState({loading:true})
    Services.pricingOrder(reqData, this.state.singleOrder.orderId, type, (res)=>{
      if(res.message == 'minimum_price_not_reached' || res.message == 'The given data was invalid.'){
        // check if city is cairo the minimum price will be 120 LE and minimum price for any other city will be 100 LE
        if( this.state.singleOrder.city.id == 2 ){
          alert('The minimum pricing is 120 le Please, enter another price.')
        }else{
          alert('The minimum pricing is 100 le Please, enter another price.')
        }
        this.setState({ loading:false,fixingHours:''})
      }else{
        myTimeHoure = ''
        myTimeMinutes = ''
        this.setState({modalPrice:false, loading:false},()=>this.clearState(),this.props.reload())
      }
    })
  }


  estimatingPrice(){
    console.log('estimate pricing');
    let reqData = {}
    let time = myTimeHoure + myTimeMinutes
    if (time != 0 && this.state.cardItems.length != 0) {
      this.setState({loading:true})
      let arr = this.state.cardItems
      let defaultItems = []
      let otherItems = []

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].type === 'otherItems') {
          let obj = {
            name: arr[i].enName,
            numberOfItems: arr[i].count
          }
          otherItems.push(obj)
        }else {
          let obj = {
            itemId: arr[i].id,
            numberOfItems: arr[i].count
          }
          defaultItems.push(obj)
        }
      }
      reqData.time = time
      if (defaultItems.length !== 0) {
        reqData.items = defaultItems
      }
      if (otherItems.length) {
        reqData.otherItems = otherItems
      }
      console.log(reqData);
      Services.estimatingPrice(reqData, this.state.singleOrder.orderId, (res)=>{
        this.setState({estimatePrice:res.data.fixingPrice, loading:false})
      })
    }
  }

  changePriceStartegy(e,item,key,price){
    // e.stopPropagation()
    console.log('omar changePriceStartegy');
    item.selectedPrice = key;
    item.price = price;
    console.log(item)
    this.forceUpdate();
  }

  checkAuthorizeForChangePricing(){
    if (this.state.password == '') {
      alert('Please, enter password')
    }else if (this.state.password != this.state.changePricingEstimatedOrderPassword) {
      alert('Invalid password')
    }else {
      this.setState({selectedPricingTypes:'Marama', currentPricing:1, cardItems:[], modalChangeEstimatedOrderPricing:false})
    }
  }

  resetItems(){
    let items = this.state.meterAndQuantityItems
    items.map((item)=>{
      item.selectedPrice = ''
      item.price = ''
    })

    return items
  }

    handleSelectedMeterAndQuantity(item){
      let items = this.state.selectedMeterAndQuantityItems;
      if (item.otherItem) {
        item.count = 1
        item.price = Number(item.fixingPrice) + Number(item.toolsPrice)
        items.push(item)
        this.setState({selectedMeterAndQuantityItems:items})
      }else{
        if (!item.selectedPrice) {
          // alert('Please,choose price first')
          return
        }
        let pos = items.map( e => e.id ).indexOf(item.id)
        if (pos === -1) {
          item.count = 1
          items.push(item)
          this.setState({selectedMeterAndQuantityItems:items})
          console.log('selectedMeterAndQuantityItems 2', items)
        }
      }
    }

    removeFromSelectedMeterAndQuantity(item){
      let arr = this.state.selectedMeterAndQuantityItems
      let pos = arr.map(function(e) { return e; }).indexOf(item)
      item.count = 0
      item.selectedPrice = ''
      arr.splice(pos, 1)
      this.setState({selectedMeterAndQuantityItems:arr})
    }

    mutatePrice(method,item){
      if (method == 'inc') {
        item.count++;
      }else{
        item.count--;
      }
      this.forceUpdate()
    }

    handleChangeMeterAndQuantityItem(item,type){
      switch (type) {
        case 'name':
          this.state.meterAndQuantityOtherItem.enName = item
          this.forceUpdate();
          break;
        case 'fixing':
          if (item == '' || !isNaN(item)) {
            this.state.meterAndQuantityOtherItem.fixingPrice = item
          }
          this.forceUpdate();
          break;
        case 'tools':
          if(item == '' || !isNaN(item)){
            this.state.meterAndQuantityOtherItem.toolsPrice = item
          }
          this.forceUpdate();
          break;
        default:
          break;
      }
    }

    onChangeItemCount(value, item, where){
      if (where == 'mutatePrice') {
        item.count = value
        this.forceUpdate()
      }else{
        if (value == '') {
          let arr = this.state.cardItems
          let pos = arr.map(function(e) { return e; }).indexOf(item)
          arr[pos].count = parseFloat(1)
          this.setState({cardItems:arr},()=>this.estimatingPrice(),this.forceUpdate())
        }else{
          let arr = this.state.cardItems
          let numberOfCount = item.count
          let pos = arr.map(function(e) { return e; }).indexOf(item)
          arr[pos].count = parseFloat(value)
          this.setState({cardItems:arr},()=>this.estimatingPrice(),this.forceUpdate())
        }
      }

    }

    addMeterAndQuantityOtherItem(){
      if (this.state.meterAndQuantityOtherItem.enName == '' || this.state.meterAndQuantityOtherItem.fixingPrice == '' || this.state.meterAndQuantityOtherItem.toolsPrice == '') {
        alert('please add valid values to the item')
      }else{
        this.handleSelectedMeterAndQuantity(this.state.meterAndQuantityOtherItem)
        this.setState({meterAndQuantityOtherItem:{enName:'',fixingPrice:'',toolsPrice:'',otherItem:true}});
      }
    }

    compensateOrder(){
      if (this.state.amountToCompensate <= 0 ) {
        alert('Please Enter A valid Amount To Compensate With')
        return
      }
      let data = {
        amount:this.state.amountToCompensate,
      }
      Services.compensateOrder(this.state.singleOrder.fromComplaint.id,data, (res)=>{
        this.setState({loading:false},()=>this.closeCompensationModel())
      })
    }
    getCompensationAmount(){
      this.setState({loading:true})
      Services.getCompensationOnOrder(this.state.singleOrder.fromComplaint.id, (res)=>{
        this.setState({compensationAmount:res.data.amount,loading:false})
      })
    }

    handleAmountToCompensate(e){
      if (!isNaN(e.target.value)) {
        this.setState({amountToCompensate:e.target.value})
      }
    }

    closeCompensationModel(){
      this.setState({showCompensationModal:false,amountToCompensate:''},()=>this.getCompensationAmount())
    }




  render(){
    return(
      <div>
        {/* PriceModal */}
        <Modal fade={false} isOpen={this.state.modalPrice} toggle={()=>this.handlePricingModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handlePricingModal()}>Price</ModalHeader>
          {this.state.singleOrder !== '' ? this.renderPricing():null}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handlePricingModal()}>Close</Button>
            <Button className='actionsBtn' onClick={()=>this.pricingOrder()}>Submit</Button>
          </ModalFooter>
        </Modal>

        {/* AskForChangePricingModal */}
        <Modal fade={false} isOpen={this.state.modalChangeEstimatedOrderPricing} toggle={()=>this.handleChangeEstimatedOrderPricing()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleChangeEstimatedOrderPricing()}>Are you sure you want to price marama?</ModalHeader>
          {this.state.singleOrder !== '' ? this.renderChangeEstimatedOrderPricing():null}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleChangeEstimatedOrderPricing()}>Close</Button>
            <Button className='actionsBtn' onClick={()=>this.checkAuthorizeForChangePricing()}>Submit</Button>
          </ModalFooter>
        </Modal>

        {/* AskForCompensationAmount */}
        <Modal fade={false} isOpen={this.state.showCompensationModal} toggle={()=>this.closeCompensationModel()} className={this.props.className}>
          <ModalHeader toggle={()=>this.closeCompensationModel()}>Compensate This Order</ModalHeader>
          <div style={{padding:'0% 3%'}}>
            <h6>Enter Amount To Compensate With: </h6>
            <Col md="5" style={{paddingLeft:0}}>
              <Input
                value={this.state.amountToCompensate}
                onChange={(e)=>this.handleAmountToCompensate(e)}
                type="text"
                placeholder="Enter Amount"
              />
            </Col>
          </div>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.closeCompensationModel()}>Close</Button>
            <Button className='actionsBtn' onClick={()=>this.compensateOrder()}>Submit</Button>
          </ModalFooter>
        </Modal>


        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>
      </div>
    )
  }

  renderChangeEstimatedOrderPricing(){
    return(
      <div style={{padding:'0% 3%'}}>
        <h6>Note: customer chose items when estimated his order</h6>
        <Col md="5" style={{paddingLeft:0}}>
          <Input
            value={this.state.password}
            onChange={(e)=>this.handleChangeInputs(e, 'password')}
            type="password"
            placeholder="Enter password"
            autoComplete="current-password"
            onKeyPress={this.handleKeyPress}
          />
        </Col>
      </div>
    )
  }
    renderPricing(){
      return(
        <ModalBody>
          {this.state.compensationAmount?(
          <Row>
            <div style={{margin:'10px auto'}}>
              <strong>Note: This Order Has Compensation Amount {this.state.compensationAmount} L.E</strong>
            </div>
          </Row>
          ):null}
          {this.props.data.fromComplaint?(
          <Row>
            <div style={{margin:'10px auto'}}>
              <Button className='actionsBtn' onClick={()=>this.setState({showCompensationModal:true})} >Compensate This Order</Button>
            </div>
          </Row>
          ):null}
          <Row>
            <Col md="3"><p className='titles'>Price Type: </p></Col>
            <Col md="6">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedPricingTypes}</DropdownToggle>
                <DropdownMenu style={{width:'100%'}}>
                  {/* // ! note we neglect inspiction pricing model from the options before mapping over it */}
                  {this.extractPricingInformationFromOrder().map((item, index)=>(
                    <DropdownItem key={index} onClick={()=>this.handleFixingTime('pricingTypes', item)}>{item.name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>

          {this.state.currentPricing==1?(
            <div>
              <Row>
                <Col md="3"><p className='titles'>Fixing Price:</p></Col>
                <Col md="6">
                  <input
                    type="number" style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                    placeholder="Enter fixing price.."
                    value={this.state.fixingPrice}
                    onInput = {(e) =>{e.target.value = e.target.value.match(/^[0-9.]+$/)}}
                    onChange={(e)=>this.handleChangeInputs(e, 'fixingPrice')}
                  />
                </Col>
              </Row>

            <Row style={{marginTop:10}}>
              <Col md="3"><p className='titles'>Tools Price:</p></Col>
              <Col md="6">
                <input
                  type="number" style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                  placeholder="Enter tools price.."
                  value={this.state.toolsPrice}
                  onInput = {(e) =>{e.target.value = e.target.value.match(/^[0-9.]+$/)}}
                  onChange={(e)=>this.handleChangeInputs(e, 'toolsPrice')}
                />
              </Col>
            </Row>

            <Row style={{marginTop:10}}>
              <Col md="3"><p className='titles'>Description:</p></Col>
              <Col md="6">
                <textarea
                  wrap="hard" cols={5} rows={3}
                  style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
                  placeholder="Enter details..."
                  value={this.state.pricingDesc}
                  onChange={(e)=>this.handleChangeInputs(e, 'pricingDesc')}
                  maxLength={1000}
                />
              </Col>
            </Row>
          </div>
        ):this.state.currentPricing==2?(
          <div>
            <Row style={{marginTop:12}}>
              <Col md="3"><p className='titles'>Number of Hours:</p></Col>
              <Col md="6">
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedHour}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {Global.hours.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleFixingTime('hour', item)}>{item.name}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>

            {this.state.showMinutes?(
              <Row>
                <Col md="3"><p className='titles'>Minutes:</p></Col>
                <Col md="6">
                  <UncontrolledDropdown  className="mb-3">
                    <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedMinutes}</DropdownToggle>
                    <DropdownMenu style={{width:'100%'}}>
                      {Global.minutes.map((item, index)=>(
                        <DropdownItem key={index} onClick={()=>this.handleFixingTime('minute', item)}>{item.name}</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            ):null}

            {this.state.fixingHours == '' && this.state.fixingMinutes == ''?null:(
              <Row>
                <Col md="3">
                  <p className='titles'>Items : </p>
                </Col>
                <Col md="6">
                  <Accordion allowZeroExpanded={true}>
                    {this.state.pricingItems.map((item, index)=>(
                      item.hasChildren !== 0 ?(
                        <AccordionItem key={index}>
                          <AccordionItemHeading>
                            <AccordionItemButton>{item.enName}</AccordionItemButton>
                          </AccordionItemHeading>
                          {item.children.map((child, i)=>(
                            <AccordionItemPanel key={i} onClick={()=>this.addToCard(child, 'default')}>{child.enName}</AccordionItemPanel>
                          ))}
                        </AccordionItem>
                      ):(
                        <p className='moreItem' key={index} onClick={()=>this.addToCard(item, 'default')}>{item.enName}</p>
                      )

                    ))}
                  </Accordion>
                </Col>

                {this.state.estimatePrice!=''?(
                  <Col md="3" className='estimatePrice'>
                    <h5>Estimated Price</h5>
                    <span>{this.state.estimatePrice}</span>
                    <p>EGP</p>
                  </Col>
                ):null}

              </Row>
            )}




          {this.state.showOtherItem?(
                <Row style={{display:'block', marginTop:'3%'}} className='text-center otherItem'>
                  <input
                    type="text" className='col-md-5' style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                    placeholder="Enter other item.."
                    value={this.state.otherItem}
                    onChange={(e)=>this.handleChangeInputs(e, 'otherItem')}
                  />
                  <Button className='actionsBtn' onClick={()=>this.addOtherItem()}>Add<i style={{marginLeft:10}} className="fas fa-plus-square"></i></Button>

                </Row>
              ):(
                <Button className='actionsBtn' style={{display:'block',margin:'auto',marginTop:'3%'}} onClick={()=>this.setState({showOtherItem: true})}>Add other item <i style={{marginLeft:10}} className="fas fa-plus-square"></i></Button>
              )}

            </div>
          ):this.state.currentPricing == 4 ? (<>
          <Row>
            <Col md="3">
                    <p className='titles'>Items : </p>
                  </Col>
                  <Col md="6">
                    <Accordion allowZeroExpanded={true}>
                      {this.state.meterAndQuantityItems.map((item, index)=>(
                        item.hasChildren !== 0 ?(
                          <AccordionItem key={index}>
                            <AccordionItemHeading>
                              <AccordionItemButton>{item.enName}</AccordionItemButton>
                            </AccordionItemHeading>
                            {item.children.map((child, i)=>(
                              <AccordionItemPanel key={i} onClick={()=>this.handleSelectedMeterAndQuantity(child)} >
                              {child.cities.map((city,i)=>(<>
                              {/* ? here is a modification to make the default option for price item to be total pricing strategy
                               and the code which is commented is if The Business Owner Choose to extend the functinality to include Fixing Price only   */}
                                <Row key={i} onClick={(e)=>this.changePriceStartegy(e,child,'total',city.fixingPrice+city.toolsPrice)} >
                                  <Col md="3">
                                    {child.enName}
                                  </Col>
                                  <Col md="3">
                                    <div //onClick={(e)=>this.changePriceStartegy(e,child,'fixing',city.fixingPrice)}
                                        >
                                      {/* <i className={item.selectedPrice=='fixing'?'fa fa-check-circle':'fa fa-circle-thin'}/> */}
                                      <span> Fixing:{city.fixingPrice}</span>
                                    </div>
                                  </Col>
                                  <Col md="3">
                                      Tools:{city.toolsPrice}
                                  </Col>
                                  <Col md="3">
                                  <div //onClick={(e)=>this.changePriceStartegy(e,child,'total',city.fixingPrice+city.toolsPrice)}
                                      >
                                    {/* <i className={item.selectedPrice=='total'?'fa fa-check-circle':'fa fa-circle-thin'}/> */}
                                    <span> Total:{city.fixingPrice+city.toolsPrice}</span>
                                  </div>
                                  </Col>
                                </Row>
                              </>))}
                              </AccordionItemPanel>
                            ))}
                          </AccordionItem>
                        ):(
                          <div className='moreItem' key={index} onClick={()=>this.handleSelectedMeterAndQuantity(item)}>
                          {item.cities.map((city,i)=>(<>
                            <Row key={i} onClick={(e)=>this.changePriceStartegy(e,item,'total',city.fixingPrice+city.toolsPrice)}>
                              <Col md="3">
                                {item.enName}
                              </Col>
                                <Col md="3">
                              <div // onClick={(e)=>this.changePriceStartegy(e,item,'fixing',city.fixingPrice)}
                                  >
                                    {/* <i className={item.selectedPrice=='fixing'?'fa fa-check-circle':'fa fa-circle-thin'}/> */}
                                    <span> Fixing:{city.fixingPrice}</span>
                                  </div>
                                </Col>
                                <Col md="3">
                                    Tools:{city.toolsPrice}
                                </Col>
                                <Col md="3">
                                  <div //onClick={(e)=>this.changePriceStartegy(e,item,'total',city.fixingPrice+city.toolsPrice)}
                                      >
                                    {/* <i className={item.selectedPrice=='total'?'fa fa-check-circle':'fa fa-circle-thin'}/> */}
                                    <span> Total:{city.fixingPrice+city.toolsPrice}</span>
                                  </div>
                                </Col>
                            </Row>
                            </>))}
                          </div>
                        )

                      ))}
                    </Accordion>
                  </Col>
              </Row>
              {this.state.showMeterAndQuantityOtherItem?(
                <Row style={{display:'flex', marginTop:'3%',padding:'0 15%'}} className='text-center otherItem'>
                  <Col md='3'>
                    <input
                      type="text"  style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                      placeholder="Enter other item.."
                      value={this.state.meterAndQuantityOtherItem.enName}
                      onChange={(e)=>this.handleChangeMeterAndQuantityItem(e.target.value,'name')}
                      />
                  </Col>
                  <Col md='3'>
                    <input
                      type="text"  style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                      placeholder="Enter Fix Price.."
                      value={this.state.meterAndQuantityOtherItem.fixingPrice}
                      onChange={(e)=>this.handleChangeMeterAndQuantityItem(e.target.value,'fixing')}
                      />
                  </Col>
                  <Col md='3'>
                    <input
                      type="text"  style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                      placeholder="Enter Tools Price.."
                      value={this.state.meterAndQuantityOtherItem.toolsPrice}
                      onChange={(e)=>this.handleChangeMeterAndQuantityItem(e.target.value,'tools')}
                      />
                  </Col>
                  <Col md='3'>
                    <Button className='actionsBtn' onClick={()=>this.addMeterAndQuantityOtherItem()}>Add<i style={{marginLeft:10}} className="fas fa-plus-square"></i></Button>
                  </Col>
              </Row>):(
              <Row>
                <Button className='actionsBtn' style={{display:'block',margin:'auto',marginTop:'3%'}} onClick={()=>this.setState({showMeterAndQuantityOtherItem: true})}>Add other item <i style={{marginLeft:10}} className="fas fa-plus-square"></i></Button>
              </Row>
              )}
          </>):null}


        {this.state.cardItems.length !== 0?(
          <div>
            <p className='titles'>Selected Items</p>
            <Table hover bordered striped responsive size="md">
              <thead>
                <tr>
                  <th style={{textAlign:'center',width:'35%'}}>Item</th>
                  <th style={{textAlign:'center'}}>Count</th>
                  <th style={{textAlign:'center'}}>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.cardItems.map((item, index)=>(
                  <tr key={index}>
                    <td style={{textAlign:'center'}}>{item.enName}</td>
                    <td style={{textAlign:'center'}}>
                      <div className='cardItems'>
                        <i className='fas fa-minus-circle' style={{alignSelf:'center'}} onClick={()=>this.decreaseItemCount(item)}/>
                        {/* <span>{item.count}</span> */}
                        <input
                          type="text"  style={{width:'20%',padding:7,border:'1px solid #fff', borderRadius:10, borderColor:'#fff',textAlign:'center'}}
                          placeholder="count"
                          value={item.count}
                          maxLength='5'
                          onChange={(e)=>this.onChangeItemCount(e.target.value, item)}
                          />
                        <i className='fas fa-plus-circle' style={{alignSelf:'center'}} onClick={()=>this.increaseItemCount(item)}/>
                      </div>
                    </td>
                    <td style={{textAlign:'center'}}><Button color="danger" onClick={()=>this.removeFromCard(item)}>Delete</Button></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ):null}

        {this.state.selectedMeterAndQuantityItems.length!==0?(
            <div>
                <p className='titles'>Selected Items</p>
                <Table hover bordered striped responsive size="md">
                  <thead>
                    <tr>
                      <th style={{textAlign:'center',width:'35%'}}>Item</th>
                      <th style={{textAlign:'center'}}>Count</th>
                      <th style={{textAlign:'center'}}>Price</th>
                      <th style={{textAlign:'center'}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.selectedMeterAndQuantityItems.map((item, index)=> {console.log(item) ;return(
                    <tr key={index}>
                      <td style={{textAlign:'center'}}>{item.enName}</td>
                      {/* <td style={{textAlign:'center'}}>
                        <div className='cardItems'>
                          <i className='fas fa-minus-circle' onClick={item.count>1 && !item.otherItem?()=>this.mutatePrice('dec',item):null}  />
                          <span>{item.count}</span>
                          <i className='fas fa-plus-circle'  onClick={!item.otherItem?()=>this.mutatePrice('inc',item):null} />
                        </div>
                      </td> */}
                      <td style={{textAlign:'center'}}>
                        <div className='cardItems'>
                          <i className='fas fa-minus-circle' style={{alignSelf:'center'}} onClick={item.count>1 && !item.otherItem?()=>this.mutatePrice('dec',item):null}/>
                          {/* <span>{item.count}</span> */}
                          <input
                            type="text"  style={{width:'20%',padding:7,border:'1px solid #fff', borderRadius:10, borderColor:'#fff',textAlign:'center'}}
                            placeholder="count"
                            value={item.count}
                            maxLength='5'
                            onChange={(e)=>this.onChangeItemCount(e.target.value, item, 'mutatePrice')}
                            />
                          <i className='fas fa-plus-circle' style={{alignSelf:'center'}} onClick={!item.otherItem?()=>this.mutatePrice('inc',item):null}/>
                        </div>
                      </td>
                      <td style={{textAlign:'center'}}>{parseFloat(item.count) * parseFloat(item.price?item.price:1)} L.E</td>
                      <td style={{textAlign:'center'}}><Button color="danger" onClick={()=>this.removeFromSelectedMeterAndQuantity(item)}>Delete</Button></td>
                    </tr>
                  )}
                )}
                </tbody>
              </Table>
            </div>
        ):null}

      </ModalBody>
    )
  }


}
export default withRouter(PricingOrder)
