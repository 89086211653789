import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class OrderRequestsService {

  static getOrdersRequests(page,searchTerm,callBack){
    let api = ''
    if(searchTerm != '') api = `${Global.baseURL}admin-panel/order-requests?page=${page}&searchTerm=${searchTerm}`
    else api = `${Global.baseURL}admin-panel/order-requests?page=${page}`

    Adaptor.get(api).then(callBack)
  }

  static getOrderRequest(orderRequest,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/order-requests/${orderRequest}`).then(callBack)
  }

  static acceptRequest(orderRequest,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/order-requests/${orderRequest}/accept`).then(callBack)
  }

  static rejectRequest(orderRequest,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/order-requests/${orderRequest}/reject`).then(callBack)
  }

}
