export default {
  items: [
    {
      name: 'Work Contracts',
      url: '/workContracts',
      icon: 'icon-calendar',
      children: [
        {
          name: 'Conditions',
          url: '/conditions'
        },
        {
          name: 'Packages',
          url: '/packages'
        },
        {
          name: 'Work-Contracts',
          url: '/workingContracts'
        },
        {
          name: 'Reservation Requests',
          url: '/reservationRequests',
        },
        {
          name: 'Approved Reservations',
          url: '/approvedReservations',
        },
      ]
    },
    {
      name: 'Orders',
      url: '/orders',
      icon: 'icon-wrench',
    },
    {
      name: 'Orders Rate',
      url: '/ordersRate',
      icon: 'icon-star',
    },
    {
      name: 'Orders Requests',
      url: '/ordersRequests',
      icon: 'fa fa-bookmark-o',
    },{
      name: 'Quotations',
      url: '/quotations',
      icon: 'icon-tag',
    },
    {
      name:'Estimation',
      url:'/estimation',
      icon:'fas fa-money-check-alt'
    },
    {
      name: 'Complaints',
      url: '/complaints',
      icon: 'fa fa-exclamation-triangle',
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'icon-people',
      children:[
        {
          name:'Customers',
          url:'/customers',
        },
        {
          name:'Equipment Requests',
          url:'/equipmentRequests',
        }
      ],
    },
    {
      name: 'Fixers',
      url: '/fixers',
      icon: 'icon-user-follow',
      children : [
        {
          name: 'Fixers',
          url: '/fixers',
        },
        {
          name: 'Chats',
          url: '/chats',
        },
        {
          name: 'Tracking',
          url: '/trackingFixers',
        },
        {
          name:'Fixers Schedule',
          url:'/fixersSchedule',
        },
        {
          name:'Track Fixers Profile',
          url:'/trackFixersProfile',
        },
      ]
    },
    {
      name: 'Contact Us',
      url: '/contactus',
      icon: 'icon-speech',
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-chart',
      children : [
        {
          name: 'Recived Order Per Day',
          url: '/reports/recivedOrderPerDay',
        },
        {
          name: 'Recived Order Per Month',
          url: '/reports/receivedOrderPerMonth',
        },
        {
          name: 'Cancel Order Per Day',
          url: '/reports/cancelOrderPerDay',
        },
        {
          name: 'Done Order Per Day',
          url: '/reports/doneOrderPerDay',
        },
        {
          name: 'Registed Customer Per Day',
          url: '/reports/registedCustomerPerDay',
        },
        {
          name: 'Canceled And Failed Reasons',
          url: '/reports/canceledAndFailedReasons',
        },
        {
          name: 'Released Work Contract',
          url: '/reports/workContract',
        },
        {
          name: 'Order Status',
          url: '/reports/orderStatus',
        },
      ]
    },
    {
      name: 'Promo Code',
      url: '/promoCode',
      icon: 'icon-credit-card',
    },
    {
      name: 'Price Offers',
      url: '/priceOffers',
      icon: 'fas fa-money-check-alt',
    },
    {
      name: 'TrelloSynchronization',
      url: '/trelloSynchronization',
      icon: 'icon-globe',
    },
    {
      name: 'Admins',
      url: '/admins',
      icon: 'icon-briefcase',
    },
    {
      name: 'Admins Points',
      url: '/adminsPoints',
      icon: 'icon-briefcase',
    },
    {
      name: 'Inquiries',
      url: '/inquiries',
      icon: 'icon-cursor',
    },
    {
      name:'Emergency Numbers',
      url:'/emergencyNumbers',
      icon:'icon-phone'
    },
    {
      name:'Search Engine',
      url:'/searchEngine',
      icon:'icon-magnifier-add'
    },
    // {
    //   name:'Service Groups',
    //   url:'/serviceGroups',
    //   icon:'icon-list'
    // },
    {
      name:'Items',
      url:'/items',
      icon:'icon-check'
    },
    {
      name:'Tasks',
      url:'/tasks',
      icon:'icon-check'
    },
    {
      name:'Catregories',
      url:'/categories',
      icon:'icon-check'
    },
    {
      name:'Posts',
      url:'/posts',
      icon:'icon-share-alt'
    },
    {
      name:'Search Engine',
      url:'/searchEngine',
      icon:'icon-magnifier-add'
    },
    {
      name:'Customer Chat',
      url:'/chat/customer',
      icon:'far fa-comment-dots'
    },
    {
      name:'Fixer Chat',
      url:'/chat/fixer',
      icon:'far fa-comment-dots'
    },
    {
      name:'Chat Replay',
      url:'/chat/replay',
      icon:'far fa-comments'
    },
    {
      name:'Scheduale Messages',
      url:'/scheduales',
      icon:'fa fa-clipboard'
    },
    {
      name:'Articles',
      url:'/articles',
      icon:'far fa-edit'
    },
    {
      name:'Permissions',
      url:'/permissions',
      icon:'fa fa-lock'
    },
  ],
};
