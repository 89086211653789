import React,{Fragment} from 'react'
import {LoadingSpinner} from './../../components'
import {Modal,ModalHeader,ModalBody,InputGroup,Input,ModalFooter,Button} from 'reactstrap'
import EmergencyService from '../../services/emergencyServices/EmergencyService'
import Services from './../../services/Services'
class AddNumberModal extends React.Component{
    constructor(props) {
        super(props)
        this.state={
            loading:false,
            phone:'',
            secret:'',
        }
    }
    clear(){
        this.setState({phone:'',secret:''})
    }
    addPhone(){
        if(this.confirmPass()){
            if(this.notValideNumber()){
                alert('Please Insert A Valid Phone Number')
            }else{
              this.setState({loading:true})
              EmergencyService.addNewEmergencyNumber(this.state.phone, (res) => {
                this.setState({loading:false})
                this.clear()
                this.props.closeHandler()
              },(res)=>{
                console.log(res)
                this.setState({loading:false})
                this.clear()
              })
            }
        }
    }
    confirmPass(){
        if(this.props.firebasePass!=this.state.secret){
            alert('Please, enter valid credentials')
            this.setState({secret:''})
            return false
        }
        return true
    }
    notValideNumber(){
        return (!this.state.phone.startsWith("01")|| this.state.phone.match(/^[0-9]+$/) == null|| this.state.phone.length !== 11 || this.state.phone.match(/[\u0600-\u06FF]/))
    }
    appendPhone(e){
        const re = /^[0-9\b]+$/;
        if(re.test(e.target.value)||e.target.value==''){
            this.setState({phone:e.target.value})
        }
    }
    render(){
        return(
            <Fragment>
              <Modal fade={false} isOpen={this.props.show} toggle={()=>{this.clear();this.props.toggleHandler()}} className={this.props.className}>
                <ModalHeader toggle={()=>{this.clear();this.props.toggleHandler()}}>Add Phone Number</ModalHeader>
                <ModalBody>
                  <div style={{padding:'15px'}}>
                    <label style={{textAlign:'center'}}><strong>Emergency Phone Number</strong></label>
                    <InputGroup style={{marginBottom:'20px'}}>
                      <Input style={{padding:'20px'}} maxLength='11' placeholder='Enter Phone Number ...' value={this.state.phone} onChange={(e)=>this.appendPhone(e)}/>
                    </InputGroup>
                  </div>
                  <div style={{padding:'15px'}}>
                    <label style={{textAlign:'center'}}><strong>Password</strong></label>
                    <InputGroup style={{marginBottom:'20px'}}>
                      <Input style={{padding:'20px'}} type="password" placeholder='Enter Password ...' value={this.state.secret} onChange={(e)=>this.setState({secret:e.target.value})}/>
                    </InputGroup>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button className='actionsBtn' onClick={()=>{this.clear();this.props.closeHandler()}}>Close</Button>
                    <Button className='actionsBtn' onClick={()=>this.addPhone()}>Add</Button>
                </ModalFooter>
            </Modal>
            <LoadingSpinner fade loading={this.state.loading}/>
            </Fragment>
        )
    }
}
export default AddNumberModal
