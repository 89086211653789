import React from 'react'

class ReactivePaginator extends React.Component {
    constructor(props){
        super(props);
        this.state={}
    }
    paginatorUpdateContent(){
        let node = document.querySelector(`#reactive-paginator${this.props.id}`);
        if (( node.scrollHeight - node.scrollTop === node.clientHeight)) {
            this.props.updateContent()
        }
    }
    toTop(){
        let node = document.querySelector(`#reactive-paginator${this.props.id}`);
        node.scrollBy(0,0);
    }
    smothScrollToTop(){
        let node = document.querySelector(`#reactive-paginator${this.props.id}`);
        node.scrollTo({top: 0,left: 0,behavior: 'smooth'});
    }
    render(){
        return (
            <div style={{overflowY:'scroll',backgroundColor:'white',flex:'1 1 auto'}}
                 id={`reactive-paginator${this.props.id}`}
                 onScroll={()=>this.paginatorUpdateContent()}
            >
            {this.props.children}
            </div>
        )
    }
}
export default ReactivePaginator;
