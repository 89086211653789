import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Tooltip} from 'reactstrap';
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

// import SingleCustomer from './../customers/SingleCustomer'
// import SingleFixer from './../fixers/SingleFixer'
// import SingleOrder from './SingleOrder'

class CanceledAndFailedReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm:'',
      orders:[],
      paginationInfo:[],
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      singleOrder:'',
      showSingleOrder:false,
      showSingleCustomer:false,
      showSingleFixer:false,
      loading:false,
      currentPage:1,
      searchText:'',
      noResult:true,
      categories:[],
      statuses:[],
      cities:[],
      areas:[],
      agents:[],
      selectedCity:'City',
      selectedArea:'Area',
      selectedCityId:'',
      selectedAreaId:'',
      selectedCategory:'Category',
      selectedCategoryId:'',
      selectedStatus:'Status',
      selectedStatusId:'',
      singleFixer:'',
      searchTerm:'',
      selectedAgent:'Agent',
      selectedAgentId:'',
      toDate:'',
      requestedDate:'',
      text:'',
      tooltipOpen: false,
      reasons:[],
      from:'',
      to:'',
      renderFromDate : '',
      renderToDate: '',
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
    // this.toggle = this.toggle.bind(this)
  }

  componentDidMount(){
    // if (this.props.match.params.id==undefined) {
      this.getCities()
    // }else {
    //   let item = {id:this.props.match.params.id}
    //   this.getSingle('order',item)
    // }

  }

  componentDidMount(){
      this.getCities()
    window.scrollTo(0, 0)
  }


  toggle(item) {
    if (item.id == item.id) {
      this.setState({tooltipOpen: !this.state.tooltipOpen,text:item.name})
    }else {
      this.setState({tooltipOpen: this.state.tooltipOpen,text:''})
    }
  }



  reload = () => {
    this.setState({showSingleOrder:false,showSingleCustomer:false,showSingleFixer:false, })
    console.log(this.state.currentPage);
    this.getOrders()
  }
  handleChangeInputs = (e, handle) => {
    this.setState({searchTerm:e.target.value})
  }
  handleKeyPress(target) {
    if(target.charCode==13)

    this.setState({currentPage:1},()=>this.getReportReasons())
  }

  getCities(){
    console.log(JSON.parse(localStorage.getItem('userData')).accessToken);
    this.setState({loading:true})
    Services.getCities((res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.cities
      arr.push(obj)
      arr.unshift(choose)
      this.setState({cities:arr},()=>this.getCategories())
    })
  }

  getCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr ,loading:false},()=>this.getStatuses())
    })
  }


  getStatuses(){
    Services.getStatuses('order',(res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.statuses.slice(4,6)
      arr.push(obj)
      arr.unshift(choose)
      this.setState({statuses:arr},()=>this.getReportReasons())
    })
  }








  getReportReasons(type){
    let api = ''
    if (type == 'filter') api = this.filter('filter')
    else api = this.filter()
    if (type == 'filter' && api == `${Global.baseURL}admin-panel/reports/reasons?`) {
      alert('Please, choose filter')
      return true
    }
    console.log(api);
    this.setState({loading:true})
    Services.getReportReasons(api, (res)=>{
      this.setState({
        loading:false,
        reasons:res.reasons,
        noResult:res.reasons.length==0?false:true,
      })
    })
  }




  handleMenus(menu, item){
    switch (menu) {
      case 'city':
      if (item.id == 'choose' || item.id == 'all') {
        this.setState({selectedCity: 'City', selectedCityId: '',selectedArea: 'Areas', selectedAreaId: ''})
      }else {
        this.setState({selectedCity: item.enName, selectedCityId: item.id})
      }
      break
      case 'category':
      if (item.id == 'choose' || item.id == 'all') {
        this.setState({selectedCategory: 'Category', selectedCategoryId: ''})
      }else {
        this.setState({selectedCategory: item.enName, selectedCategoryId: item.id})
      }
      break
      case 'statuses':
      if (item.id == 'choose' || item.id == 'all') {
        this.setState({selectedStatus: 'Status', selectedStatusId: ''})
      }else {
        this.setState({selectedStatus: item.enName, selectedStatusId: item.id})
      }
      break
      default:
    }
  }

  resetFilter(){
    this.setState({
      selectedCity: 'City',
      selectedCityId: '',
      selectedStatus: 'Status',
      selectedStatusId: '',
      selectedCategory: 'Category',
      selectedCategoryId: '',
      requestedDate:'',
      from:'',
      currentPage:1,
      to:'',
      renderFromDate:'',
      renderToDate:''
    },()=>this.getReportReasons())
  }

  filter(type){

    let api = ''
    if (type =='filter') {
       api = `${Global.baseURL}admin-panel/reports/reasons?`
    }else {
       api = `${Global.baseURL}admin-panel/reports/reasons?`
    }


    if (this.state.selectedCityId != '') {
      api+=`&cityId=${this.state.selectedCityId}`
    }
    if (this.state.selectedCategoryId != '') {
      api+=`&categoryId=${this.state.selectedCategoryId}`
    }
    if (this.state.selectedStatusId != '') {
      api+=`&statusId=${this.state.selectedStatusId}`
    }
    if (this.state.renderFromDate) {
      api+=`&from=${this.state.renderFromDate}`
    }
    if (this.state.renderToDate) {
      api+=`&to=${this.state.renderToDate}`
    }
    return api
  }



  handleOnChangeDate(type, date){
    var day = new Date (date+' Z')

    if(!date){
      this.setState({from:'', renderFromDate: '',to:'', renderFromDate: ''})
    }else {
      var neededDate  = day.toISOString().split('T')[0]
      if (type === 'from') {
        this.setState({from:date, renderFromDate: neededDate},()=>console.log(this.state.renderFromDate))
      }else {
        this.setState({to:date, renderToDate: neededDate},()=>console.log(this.state.renderToDate))
      }
    }
  }







  render() {
    return (
      <div className="app align-items-center">

        <Container>
          {this.renderFilter()}
          <Card>
            <CardHeader className='shadow'>
                  <Row>
                   <Col md="8" style={{display:'flex',alignItems:'center'}}>
                      <i className="fa fa-align-justify" ></i>
                      <span style={{marginLeft:6}}>Canceled And Failed Reasons</span>
                   </Col>
                 </Row>

            </CardHeader>
            {/* search input */}
            {/* {this.renderSearch()} */}

            {/* render all orders */}
            {this.state.noResult?
              <CardBody className='shadow'>
                {this.renderCancelledAndFailedOrders()}
                {/* {this.renderPagination()} */}
              </CardBody>
            :(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}






            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
            </Modal>


          </Card>
        </Container>
      </div>
      )
    }




    renderCancelledAndFailedOrders(){
      return(
        <div>
          {this.state.searchText!=''?(
            <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
              <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
              <h6 style={{fontSize:18}}>Search Results of
                <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

              </h6>
              <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getOrders())}>
                Clear Search
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </span>
            </div>

          ):null}
          <Table hover bordered striped responsive size="md">
            <thead>
              <tr>
                <th style={{textAlign:'center',width:'5%'}}>Id</th>
                <th style={{textAlign:'center'}}>Reasons</th>
                <th style={{textAlign:'center'}}>types</th>
                <th style={{textAlign:'center'}}>Order Counts</th>

              </tr>
            </thead>
            <tbody>
              {/* <div style={{position:'absolute',top:150,right:0}}>
                <p>{this.state.text}</p>
              </div> */}
              {this.state.reasons.map((item, index)=>(
                <tr key={index}>
                  <td style={{textAlign:'center'}}>{item.id}</td>
                  <td style={{textAlign:'center'}}>{item.enName}</td>
                  <td style={{textAlign:'center'}}>{item.type}</td>
                  <td style={{textAlign:'center'}}>{item.ordersCount}</td>



                </tr>
              ))}
            </tbody>
          </Table>
        </div>

)
}



renderFilter(){
  return(
    <Row>
      <UncontrolledDropdown  className="mb-3">
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />{this.state.selectedCity}</DropdownToggle>
        <DropdownMenu style={{width:'100%'}}>
          {this.state.cities.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>this.handleMenus('city',item)}>{item.enName}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>



      <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}} >
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />{this.state.selectedCategory}</DropdownToggle>
        <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          {this.state.categories.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>this.handleMenus('category',item)}>{item.enName}</DropdownItem>
          )
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}}>
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />{this.state.selectedStatus}</DropdownToggle>
        <DropdownMenu style={{width:'100%'}}>
          {this.state.statuses.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>this.handleMenus('statuses',item)}>{item.enName}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>


      <div className="date">
        <DatePicker
          maxDate={this.state.to === '' ? new Date() :  this.state.to}
          selected={this.state.from}
          onChange={(date)=> this.handleOnChangeDate('from', date)}
          dateFormat="yyyy-MM-dd"
          placeholderText='From date'
        />
      </div>

      <div className="date">
        <DatePicker
          minDate={this.state.from}
          selected={this.state.to}
          onChange={(date)=> this.handleOnChangeDate('to', date)}
          dateFormat="yyyy-MM-dd"
          placeholderText='To date'
        />
      </div>

      {this.state.requestedDate!=''?(
        <div className='fa fa-close clearDatePicker' onClick={()=>this.setState({toDate:'',fromDate:''})}/>
      ):null}
      <Col md={1}>

        <Button onClick={()=>this.getReportReasons('filter')} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
      </Col>

      <Col md={1}>
        <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'white'}}>Reset</Button>
      </Col>


    </Row>
)
}

}

export default withRouter(CanceledAndFailedReasons);
