import { Global } from './../../core/Global'
import Adaptor from './../Adaptor';

export default class KeywordsServices{
  static add(data,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/keywords`, data).then(callBack)
  }

  static get(page,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/keywords?page=${page}`).then(callBack)
  }
  
}
