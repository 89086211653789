import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label,Input,InputGroup } from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'
import ChargeWalletService from './../../services/chargeWallet/ChargeWalletService'
export default class ChargeFixawyPay extends React.Component{
  constructor(props){
    super(props);
    this.state = {
       amount:'',
       expireDate:'',
       loading:false
    }

  }
  componentDidMount(){
  }

  handleAmount(e){
    var regex=/^[0-9\b]+$/;
    if(e.target.value === '' || regex.test(e.target.value)){
        this.setState({amount:e.target.value})
    }
  }


  chargeFixawyPay(){
    if(this.state.amount == "" || this.state.amount.trim() == ""){
      alert('Please,add amount')
    }else if(this.state.expireDate == ""){
      alert('Please,add expiry data')
    }else {
    let data = {
      userId:this.props.customer.id,
      amount:this.state.amount,
      expiryDate:this.state.expireDate
    }
    console.log(data)
    ChargeWalletService.charge(data,(res)=>{
      if(res.message == 'invalid_date'){
        alert('Please,expiry date should be today or after today')
      }else{
        console.log(res)
        this.setState({loading:false},()=>this.props.update())
      }
    })
    }
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
           <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>Amount</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input style={{padding:'20px'}} maxLength='150' placeholder='Amount' value={this.state.amount} onChange={(e)=>this.handleAmount(e)}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>Expire Date</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input type="date" style={{padding:'20px'}} maxLength='150' placeholder='Expire Date' value={this.state.expireDate} onChange={(e)=>this.setState({expireDate:e.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
      )
    }
  }
