import React, { Component } from 'react'
import { Button, Col, Row ,Table ,CardHeader ,Badge ,Modal ,ModalBody ,ModalHeader ,ModalFooter  ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Input ,Form,FormGroup,Label} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import { LoadingSpinner } from './../../components'



export default class UpdateTags extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      tags:[],
      selectedTags:[],
      selectedTagsIds:[]
    }

  }

  componentDidMount(){
    console.log(this.props.complaint)
    let arr =[]
    let selectedItems = this.props.complaint.tags
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({selectedTags:selectedItems,selectedTagsIds:[]})
    this.getComplainTags()
  }

  onSelectedChanged(selectedItems) {
    console.log(selectedItems);

    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    console.log('ahmed')
    this.setState({selectedTags:selectedItems,selectedTagsIds:arr},()=>console.log(arr))
  }

  getComplainTags(){
    Services.getComplainTags((res)=>{
      let arr = res.data.tags
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({tags:titles})
    })
  }


  updateTagsComplaint(){
    if(this.state.selectedTagsIds == ''){
      alert('Please , select at least one agent for order')
    }else {
      this.setState({loading:true})
      let data = {
        tags:this.state.selectedTagsIds
      }
      console.log(data)
      Services.updateTagsComplaint(this.props.complaint.id,data,(res)=>{
        this.setState({loading:false},()=>this.props.close())
      })
    }
  }

  render(){
    return(
      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <Row>
          <Col md={4}>

          </Col>
          <Col md={5}>
            {this.state.tags.length!=0?(
              <MultiSelect
                items={this.state.tags}
                selectedItems={this.state.selectedTags}
                onChange={(item)=>this.onSelectedChanged(item)}
                withGrouping={true}
                showSelectedItems={false}
              />
            ):null}
          </Col>
        </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
