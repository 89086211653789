import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Dropdown ,DropdownItem ,DropdownMenu ,DropdownToggle ,Modal ,ModalBody ,ModalFooter ,ModalHeader, UncontrolledDropdown  } from 'reactstrap';

import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'

import SingleCustomer from './../customers/SingleCustomer'
import SingleOrder from './../orders/SingleOrder'

class ContactUS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singlePromo:'',
      contactMsg:[],
      searchTerm:'',
      paginationInfo:[],
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      loading:false,
      addPromoModal : false,
      singlePromoModal : false,
      showAddPromo: false,
      currentPage:1,
      currentApi:'',
      noResult:true,
      searchText:'',
    }
  }
  componentDidMount(){
    this.getContactMsg()
    window.scrollTo(0, 0)
  }

  getContactMsg(){
    this.setState({loading:true})
    Services.getContactMsg(this.state.currentPage , (res)=>{
      if (res.data.contactUsMessages.length==0) {
        this.setState({noResult:false,loading:false,})
      }else {
        this.setState({
          contactMsg:res.data.contactUsMessages,
          paginationInfo:res.data.paginationInfo,
          paginationLinks:res.data.paginationLinks,
          previousPages:res.data.paginationLinks.previousPages.reverse(),
          nextPages:res.data.paginationLinks.nextPages,
          loading:false,
          searchText:this.state.searchTerm!=''?this.state.searchTerm:''
        })
      }
    })
  }

  nextPage(item){
    let page = this.state.currentPage
    if (item === '') {
      this.setState({currentPage: page+1},()=>this.getContactMsg())
    }else {
      this.setState({currentPage: item},()=>this.getContactMsg())
    }
  }

  prevPage(item){
    let page = this.state.currentPage
    if (item === '') {
      this.setState({currentPage: page-1},()=>this.getContactMsg())
    }else {
      this.setState({currentPage: item},()=>this.getContactMsg())
    }
  }

  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            <CardHeader>
              Contact Us
            </CardHeader>
            {this.state.loading?null:(
              <div>
                {this.state.noResult?
                  <CardBody className='shadow'>
                    {this.renderContactMsg()}
                    {this.renderPagination()}
                  </CardBody>
                :(
                  <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                    <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                    <h2>There's no result</h2>
                  </CardBody>
                )}
              </div>
            )}


            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
            </Modal>
          </Card>
        </Container>
      </div>
    )
  }


  renderContactMsg(){
    return(
      <div>

        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center',width:'25%'}}>Name</th>
              <th style={{textAlign:'center'}}>E-Mail</th>
              <th style={{textAlign:'center'}}>Message</th>
            </tr>
          </thead>
          <tbody>
            {this.state.contactMsg.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>{item.name}</td>
                <td style={{textAlign:'center'}}>{item.email}</td>
                <td style={{textAlign:'center'}}>{item.message}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  renderPagination(){
    return(
      <nav>
        <Row className='pull-right'>
          <Pagination style={{justifyContent:'flex-end'}}>
            {/* previous link*/}
            <PaginationItem>
              <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
            </PaginationItem>

            {/* previousPages */}
            {this.state.previousPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* currentPage */}
            <PaginationItem active>
              <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                {this.state.paginationInfo.currentPage}
              </PaginationLink>
            </PaginationItem>


            {/* nextPages */}
            {this.state.nextPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* nextPageUrl */}
            <PaginationItem>
              <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
            </PaginationItem>
          </Pagination>
        </Row>
      </nav>
    )
  }

}

export default withRouter(ContactUS);
