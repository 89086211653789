import React,{ Fragment }from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button , Input,InputGroup, Row, Col, Container, Tooltip, UncontrolledTooltip} from 'reactstrap'
import MultiSelect from "@kenshooui/react-multi-select"
import Services from './../../services/Services'
import { LoadingSpinner, ConfirmMessage, FModal } from '../../components'
import KeywordsServices from '../../services/searchEngineServices/KeywordsServices'
import { array } from 'prop-types'

class AddPromotedKeyword extends React.Component{
  constructor(props){
    super(props)
    this.state={
      loading:false,
      arName:'',
      name:'',
      file:null,
      imagePreview:null,
      categories:[],
      selectedCategories:[],
      selectedCategoriesIds:[],
      keywordsRows:[],
      displayConfirmMessage:false,
      deletedItem:null,
    }
  }
  componentDidMount(){
    this.getCategories()
  }

  fakeUpload(){
    document.getElementById('promotedKey__imageUploader').click();
  }
  uploadImage(e){
    // upload the image
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreview: reader.result
      });
    }
    reader.readAsDataURL(file)
  }
  onChangeMultiSelect(items){
    let arr=[],selectedIds=[];
    items.forEach(item => {
      arr.push({id:item.id,enName:item.label})
      selectedIds.push(item.id)
    });
    this.setState({selectedCategories:arr,selectedCategoriesIds:selectedIds})
  }
  addKeywordRow(){
    if (this.validKeyword()) {
      let keywordsRow = {
        arName:this.state.arName,
        name:this.state.name,
        imagePreview:this.state.imagePreview,
        file:this.state.file,
        selectedCategories:this.state.selectedCategories,
        selectedCategoriesIds:this.state.selectedCategoriesIds,
      }
      let newKeywordsRow=this.state.keywordsRows
      newKeywordsRow.push(keywordsRow)
      this.setState({keywordsRows:newKeywordsRow},()=>this.clearRow())
    }
  }
  validKeyword(){
    let arRegex=/[\u0600-\u06FF]/,enRegex=/^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/;
    if (this.state.name == '' || this.state.arName == '' ) {
      alert('please, enter a valid promoted keyword  ')
      return false
    }
    if (!arRegex.test(this.state.arName)) {
      alert('please, enter a valid arabic name ')
      return false
    }
    if (!enRegex.test(this.state.name)) {
      alert('please, enter a valid english name ')
      return false
    }
    if (this.state.selectedCategories.length == 0) {
      alert('please, select one category or more')
      return false
    }
    if(this.state.file == null ){
      alert('please, upload image first ')
      return false
    }
    return true
  }
  clear(){
    // clear state
    this.setState({arName:'',name:'',file:null,imagePreview:null,selectedCategories:[],selectedCategoriesIds:[],keywordsRows:[]})
  }
  clearRow(){
    this.setState({arName:'',name:'',imagePreview:null,file:null,selectedCategories:[],selectedCategoriesIds:[]})
  }
  clearImage(){
    this.setState({file:null,imagePreview:null})
  }
  removeKeyword(Keyword){
    this.setState({displayConfirmMessage:true,deletedItem:Keyword})
  }
  closeMessage(){
    this.setState({displayConfirmMessage:false,deletedItem:null})
  }
  confirmMessage(){
    let newKeywordsRow = this.state.keywordsRows,Keyword = this.state.deletedItem
    this.setState({keywordsRows:newKeywordsRow.filter((item)=>item != Keyword),displayConfirmMessage:false,deletedItem:null})
  }
  getCategories(){
    Services.getCategories((res)=>{
      let arr = []
      res.data.categories.forEach(e => {
        arr.push({id:e.id,label:e.enName})
      })
      this.setState({categories:arr})
    })
  }
  validRequest(){
    if (this.state.keywordsRows.length == 0) {
      alert('please, add one promoted keyword or more')
      return false
    }
    return true
  }
  submit(){
    if(this.validRequest()){
      let formObj = new FormData;
      for (let i = 0; i < this.state.keywordsRows.length; i++) {
        const element = this.state.keywordsRows[i];
        formObj.append(`keywords[${i}][enName]`,element.name)
        formObj.append(`keywords[${i}][arName]`,element.arName)
        formObj.append(`keywords[${i}][image]`,element.file)
        for (let j = 0; j < element.selectedCategoriesIds.length; j++) {
          const cat = element.selectedCategoriesIds[j];
          formObj.append(`keywords[${i}][categories][${j}]`,cat)
        }
      }
      this.submitKeywords(formObj)
    }
  }
  submitKeywords(data){
    this.setState({loading:true})
    KeywordsServices.add(data,(res)=>{
      this.setState({loading:false},()=>{this.clear();this.props.closeHandler()})
    })
  }
  render(){
    return(
      <>
      <FModal state={this.props.show}
              title='Add Promoted Keyword'
              onClose={()=>{this.clear();this.props.closeHandler()}}
              content={this.renderPromotedKeyBody()}
              onSubmit={()=>this.submit()}
              show={true}
              />
      </>
    )
  }
  renderPromotedKeyBody(){
    return(
        <>
        <Row>
          <div className='shadow' style={{width:'97%',margin:'auto',padding:'10px',borderRadius:'10px'}}>
            <Row>
              <Col md='3'>
                <label><strong>Promoted Keyword Name</strong></label>
              </Col>
              <Col md='3' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input style={{padding:'20px'}} maxLength='150' placeholder='Arabic Name' value={this.state.arName} onChange={(e)=>this.setState({arName:e.target.value})}/>
                </InputGroup>
              </Col>
              <Col md='3' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input style={{padding:'20px'}} maxLength='150' placeholder='English Name' value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label><strong>Choose Category </strong></label>
              </Col>
              <Col md='6' >
                <MultiSelect
                  items={this.state.categories}
                  selectedItems={this.state.selectedCategories}
                  onChange={(item)=>this.onChangeMultiSelect(item)}
                  withGrouping={true}
                  showSelectedItems={false}
                  responsiveHeight={400}
                />
              </Col>
            </Row>
            <Row>
              <Col md='3' >
                <label style={{marginTop:'25px'}}><strong>Choose Image</strong></label>
              </Col>
              <Col md='4'>
                {this.state.imagePreview?(
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center'}} className='keywordItem'>
                    <img src={this.state.imagePreview} style={{height:150, width:'100%',marginBottom:'10px'}} />
                      <span className='image__clear' id='clearBtn' onClick={()=>this.clearImage()}>
                        <i className="fas fa-times" />
                      </span>
                      <UncontrolledTooltip placement="right" target='clearBtn'>Clear Image</UncontrolledTooltip>
                  </div>
                ):null}
              </Col>
              <Col md='2' >
                <Input id='promotedKey__imageUploader' type='file' accept="image/x-png,image/gif,image/jpeg"  onChange={(e)=>this.uploadImage(e)}/>
                <Button disabled={!!this.state.imagePreview} className='actionsBtn' onClick={()=>this.fakeUpload()}>Upload Image</Button>
              </Col>
            </Row>
            <Row>
              <Container>
                <div className="clearfix" style={{ padding: '.5rem',display:'flex',flexDirection:'row',justifyContent:'center' }}>
                  <Button style={{marginTop:'20px'}} className='actionsBtn' onClick={()=>this.addKeywordRow()}> <i className="fas fa-plus" />&nbsp;&nbsp;Add Single Keyword</Button>
                </div>
              </Container>
            </Row>
          </div>
        </Row>
          {this.state.keywordsRows.length?(
          <Row>
            <div className='shadow' style={{width:'97%',margin:'auto',marginTop:'10px',padding:'10px',borderRadius:'10px'}}>
              <Row>
                {this.state.keywordsRows.map((item,index) =>(
                  <Fragment key={index}>
                    <Col md='3' className='keywordItem'>
                      {item.imagePreview!=null?(
                        <img src={item.imagePreview} style={{height:150, width:'100%'}} />
                      ):null}
                      <h6 style={{textAlign:'center'}}>{item.name}</h6>
                      <h6 style={{textAlign:'center'}}>{item.arName}</h6>
                      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                        <div>
                          <span style={{cursor:'pointer',color:'#73818f',textDecoration:'underline'}} href="#" id={`Tooltip${index}`}><em>view categories</em></span>
                          <UncontrolledTooltip placement="top" target={`Tooltip${index}`}>
                            {item.selectedCategories.map((category)=>(<>{category.enName}<br/></>))}
                          </UncontrolledTooltip>
                        </div>
                        <Button
                          className='actionsBtn'
                          onClick={()=>this.removeKeyword(item)}>
                          <i className="fas fa-times" />&nbsp;&nbsp;Delete
                        </Button>
                      </div>
                    </Col>
                  </Fragment>
                ))}
              </Row>
            </div>
          </Row>
          ):null}
          <LoadingSpinner loading={this.state.loading} />
          <ConfirmMessage show={this.state.displayConfirmMessage}
            close={()=>this.closeMessage()}
            confirm={()=>this.confirmMessage()}
          message='Are you sure you want to delete this keyword ?' />
        </>);
  }
}
export default AddPromotedKeyword
