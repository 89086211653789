import React from 'react';
import ReverseReactivePaginator from '../Pagination/ReverseReactivePaginator';
import Services from './../../services/Services'
import Message from './Message';
import { Button , Spinner} from 'reactstrap';
import LogoImg from './../../assets/img/logo.png'
import { SRLWrapper } from "simple-react-lightbox";
import './../../assets/style/chat/ChatBox.scss'
import Man from './../../assets/img/brand/download.png';

class ChatBox extends React.Component{
  constructor(props){
    super(props);
    this.state={
      loading:false,
      disabled:false,
      messagePage:1,
      user:'',
      chat:'',
      messages:[],
      nextPages:[],
      prevPages:[],
      showSingleUser:false,
      currentPlayingRecored:null,
    };

  }

  componentDidMount(){
    if (navigator.mediaSession) {
      navigator.mediaSession.setActionHandler('play', () => {
        if(this.state.currentPlayingRecored){
          this.state.currentPlayingRecored.playVoice()
        }
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        if(this.state.currentPlayingRecored){
          this.state.currentPlayingRecored.pauseVoice()
        }
      });
    }
  }

  getNextMessages(){
    if (this.state.nextPages.length != 0) {
      this.setState((prevState)=>({messagePage:prevState.messagePage+1}),()=>this.updateWithNextMessages())
    }
  }

  updateChat( chat , user ){
    this.setState({messages:[],chat:chat,user:user,messagePage:1},()=>this.getMessages())
  }

  handleSocketChatMessage(){
    this.seenMessage()
  }

  updateWithNextMessages(){
    this.setState({loading:true,disabled:true})
    Services.getChatMessages(this.state.messagePage,this.state.chat.id,(res)=>{
      console.log(res,'msgs')
      if (res.message == "Unauthenticated." || res.message == 'not_a_admin') {
        Services.refreshToken((res)=>{
          console.log(res)
          if (res.message == 'success') {
            localStorage.removeItem('userData')
            localStorage.setItem('userData',JSON.stringify(res.data))
            this.getMessages()
          }else {
            this.props.history.push('/login')
          }
        })
      }else {
        this.setState((prevState)=>({
          loading:false,
          disabled:false,
          messages:[...prevState.messages,...res.data.messages],
          nextPages:res.data.paginationLinks.nextPages,
          prevPages:res.data.paginationLinks.previousPages,
        }),()=>this.paginatorRef.toLastPosition())
      }
    })

  }

  getMessages(){
    this.setState({loading:true,disabled:true})
    Services.getChatMessages(this.state.messagePage,this.state.chat.id,(res)=>{
      this.setState({
        loading:false,
        disabled:false,
        me:res.data.me,
        messages:res.data.messages,
        nextPages:res.data.paginationLinks.nextPages,
        prevPages:res.data.paginationLinks.previousPages,
      },()=>this.paginatorRef.toButtom())
    })
  }

  sendMesage(message){
    this.setState({msgLoading:true});
    let data = new FormData;
    data.append('type',message.messageable.type)
    if (message.messageable.image) {
      data.append('image',message.messageable.image)
      data.append('body',message.messageable.body)
    }else if (!!message.messageable.priceOffer) {
      data.append('price',message.messageable.priceOffer.price)
      data.append('orderId',message.messageable.priceOffer.orderId)
      data.append('body',message.messageable.priceOffer.qoute)
    }else if (message.messageable.type == 'sound') {
      data.append('sound',message.messageable.soundFile)
    }else{
      data.append('body',message.messageable.body)
    }

    Services.sendChatMessage(data,this.state.chat.id,(res)=>{
      message.senderId = this.state.me;
      message.messageable.image = message.messageable.imagePreview;
      this.state.chat.lastMessage = message;
      if (res.message == 'success') {
        this.setState((prevState)=>({messages:[message,...prevState.messages],msgLoading:false}),()=>{
          this.paginatorRef.toButtom();
          this.props.onUpdate(this.state.chat);
        })
      }else{
        if (res.message == 'version_error') {
          alert('the user isn\'t install the latest version')
        }
        this.setState({msgLoading:false},()=>{
          alert('message can\'t send please try again later')
        })
      }
    })
  }

  seenMessage(){
    Services.seenChatMessage(this.state.chat.id,(res)=>{
      if (res.message == 'success') console.log('seened')
    })
  }

  handlePlayRecoredMessage(voiceRecored){
    if (this.state.currentPlayingRecored != voiceRecored) {
      if (!!this.state.currentPlayingRecored) {
        this.state.currentPlayingRecored.reset();
      }
      this.setState({currentPlayingRecored:voiceRecored})
    }
  }

  showProfile(){
    if(this.state.user) this.setState({showSingleUser:true});
  }


  reload = () => {
    this.setState({showSingleUser:false})
  }

  handleImageNotFound(){
    this.imageRef.src = Man;
  }

  onNewMessage(message){
    this.setState(
      (prevState) => ({
        messages:[message,...prevState.messages],
        disabled:false
      }),
      ()=>this.paginatorRef.toButtom()
    )
  }

  render(){
    const options = {
      caption: {
        captionAlignment: 'start',
        captionColor: '#FFFFFF',
        captionContainerPadding: '0',
        captionFontFamily: 'inherit',
        captionFontSize: 'inherit',
        captionFontStyle: 'inherit',
        captionFontWeight: 'inherit',
        captionTextTransform: 'inherit',
        showCaption: true
      }
    }
    return(
      <>
      {this.state.showSingleUser?<this.props.SingleUser data={this.state.user} reload={this.reload}/>:null}
      <div className='chat-box_header' onClick={()=>this.showProfile()}>
        <div className='chat-box_header_back-btn'>
          <span onClick={(e)=>this.props.onGoBack(e)} class="fa fa-arrow-left" />
        </div>
        <div className='chat-box_header_img-wrapper'>
          <img className='chat-box_header_img' onError={()=>this.handleImageNotFound()}  ref={(ref) => this.imageRef = ref} src={this.state.user?this.state.user.image:LogoImg}/>
        </div>
        <div className='chat-box_header_user-name'>
          <strong>{this.state.user?this.state.user.enName:'Fixawy Chat'}</strong>
        </div>
      </div>
      <ReverseReactivePaginator ref={ ref => this.paginatorRef = ref } id='2' disabled={this.state.disabled} updateContent={()=>this.getNextMessages()}>
        {this.state.loading?(<div className="text-center m-5"><Spinner color="primary" /></div>):null}
        {this.state.chat?(
          <SRLWrapper options={options}>
            {this.state.messages.pureReverse().map((msg,i)=>(
              <Message
                key={i}
                message={msg}
                notify={(voiceRecored)=>this.handlePlayRecoredMessage(voiceRecored)}
              />
            ))}</SRLWrapper>
          ):(
            <div>
              <img src={LogoImg} alt='loading..' style={{width:'15%',height:'25%',display:'block',marginTop:'10%', marginLeft:'auto',marginRight:'auto' }} />
              <h1 style={{textAlign:'center', fontSize:25, color:'#545453', marginTop:'3%', fontWeight:'bold'}}>Fixawy Customer Chat  <span className='mdi mdi-message-bulleted' style={{fontSize:25}}/></h1>
            </div>
          )}
        </ReverseReactivePaginator>
      </>
    )
  }
}

export default ChatBox;
