import React from 'react'
import {Modal,ModalBody,ModalFooter,ModalHeader,Button} from 'reactstrap'
import GoogleMapReact from 'google-map-react'

const MapMarker = () => <i className="fa fa-map-pin" style={{color:'red'}} aria-hidden="true"></i>;

class MapModal extends React.Component{
    constructor(props){
        super(props)
    }
    
    render(){
        return(
            <Modal fade={false}
              isOpen={this.props.show}
              toggle={this.props.closeHandler} className={this.props.className}>
              <ModalHeader toggle={this.props.closeHandler}>Map</ModalHeader>
              <ModalBody>
                <div style={{ height: '80vh', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyA9PJNedI5y2RLoIdjBq6ojyJGeWZ3izR0' }}
                      defaultCenter={{lat: parseFloat(this.props.lat),lng: parseFloat(this.props.lng)}}
                      yesIWantToUseGoogleMapApiInternals
                      defaultZoom={15}
                    >
                      <MapMarker
                        lat={this.props.lat}
                        lng={this.props.lng}
                      />
                    </GoogleMapReact>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className='actionsBtn' onClick={this.props.closeHandler}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default MapModal
