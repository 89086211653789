import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput,Tooltip} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import Switch from "react-switch";
import firebase from 'firebase/app'
import 'firebase/database'
// import {FModal} from './../../components'
import OrderRequestsService from './../../services/orderServices/OrderRequestsService'
import Paginator from './../../components/Pagination/V2/Paginator'
import SingleCustomer from './../customers/SingleCustomer'
import SingleFixer from './../fixers/SingleFixer'
import {FModal,ViewHistory,OrderRequest} from './../../components'

class Requests extends Component {
  constructor(props) {
    super(props)
    this.state = {
      noResult:false,
      requests:[],
      loading:false,
      previousPages:[],
      nextPages:[],
      currentPage:1,
      showSingleCustomer:false,
      customer:'',
      showSingleFixer:false,
      fixer:'',
      user:'',
      text:'',
      tooltipOpen: false,
      orderRequest:'',
      request:'',
      showSingleOrderRequest:false,
      searchTerm:'',
      searchText:'',

    }
    this.handleKeyPress = this.handleKeyPress.bind(this)


  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.getOrdersRequests()
  }


  changePage(page){
    this.setState({currentPage:page},()=>this.getOrdersRequests())
  }



  getSingle(type,item){
    console.log(item)
    if(type == 'customer'){
     this.setState({showSingleCustomer:true,customer:item})
    }else if(type == 'fixer'){
     this.setState({showSingleFixer:true,fixer:item})
    }else if(type == 'orderRequest'){
      this.setState({showSingleOrderRequest:true,orderRequest:item})
     }
   }

   reload =()=>{
    this.setState({showSingleCustomer:false,showSingleFixer:false})
  }


  getOrdersRequests(){
    this.setState({loading:!this.state.loading})
    OrderRequestsService.getOrdersRequests(this.state.currentPage,this.state.searchTerm,(res)=>{
      this.setState({
        loading:false,
        requests:res.data.orderRequests,
        noResult:!res.data.orderRequests.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage,
        searchText:this.state.searchTerm!=''?this.state.searchTerm:''
      })
    })
  }

  handleKeyPress(target) {
    if(target.charCode==13)
    this.setState({currentPage:1},()=>this.getOrdersRequests())
  }




  handleChangeInputs = (e, handle) => {
      this.setState({searchTerm:e.target.value})
  }





  render() {
    return (
      <div className="app align-items-center">
        <Container>
        {this.state.showSingleCustomer?<SingleCustomer data={this.state.customer} from={"request"} reload={this.reload}/>:null}
        {this.state.showSingleFixer?<SingleFixer data={this.state.fixer} reload={this.reload} from={"request"}/>:null}

          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderRequests()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>
               <FModal
                title='Order Request'
                content={
                  <OrderRequest
                    ref={ref=> this.orderRequestRef = ref}
                    request={this.state.orderRequest.id}
                    update={()=>this.setState({showSingleOrderRequest:false},()=>this.getOrdersRequests())}
                  />
                }
                state={this.state.showSingleOrderRequest}
                onClose={()=>this.setState({showSingleOrderRequest:false})}
                show={"none"}
              />
          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Orders Requests</span>
          </Col>
          <Col>
          <InputGroup>
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e, 'search')}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By customer ,fixer ,@id, ...."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.setState({currentPage:1},()=>this.getOrdersRequests())} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
            </InputGroup></Col>
        </Row>
      </CardHeader>
    )
  }

  renderRequests(){
    return(
      <div>
        {this.state.searchText!=''?(
          <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
            <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
            <h6 style={{fontSize:18}}>Search Results of
              <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

            </h6>
            <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getOrdersRequests())}>
              Clear Search
              <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
            </span>
          </div>

        ):null}
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>Customer</th>
            <th style={{textAlign:'center'}}>Fixer</th>
            <th style={{textAlign:'center'}}>Status</th>
            <th style={{textAlign:'center'}}>Date</th>
            <th style={{textAlign:'center'}}>Category</th>
            <th style={{textAlign:'center'}}>City</th>
            <th style={{textAlign:'center'}}>Area</th>
            <th style={{textAlign:'center'}}>Period</th>
            <th style={{textAlign:'center'}}>Creation Date</th>

          </tr>
        </thead>
        <tbody>
          {this.state.requests.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('orderRequest',item)}>{item.id}</Button>
              </td>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('customer',item)}>{item.customer.enName}</Button>
              </td>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('fixer',item)}>{item.fixer.enName}</Button>
                </td>
              <td style={{textAlign:'center'}}>{item.status.enName}</td>
              <td style={{textAlign:'center'}}>{item.requestedDate}</td>
              <td style={{textAlign:'center'}}>{item.category.enName}</td>
              <td style={{textAlign:'center'}}>{item.city.enName}</td>
              <td style={{textAlign:'center'}}>{item.area.enName}</td>
              <td style={{textAlign:'center'}}>{item.period.enName}</td>

              <td style={{textAlign:'center'}}>{item.createdAt}</td>
            </tr>
           ))}
        </tbody>
      </Table>
      </div>
    )
  }


}

export default Requests;
