import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput,Tooltip} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import Switch from "react-switch";
import firebase from 'firebase/app'
import 'firebase/database'
import {FModal} from './../../components'
import OrdersRateService from './../../services/orderServices/OrdersRateService'
import Paginator from './../../components/Pagination/V2/Paginator'
import SingleCustomer from './../customers/SingleCustomer'
import SingleFixer from './../fixers/SingleFixer'
import SingleOrder from './../orders/SingleOrder'

class Rates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      noResult:false,
      rates:[],
      loading:false,
      previousPages:[],
      nextPages:[],
      currentPage:1,
      showSingleCustomer:false,
      customer:'',
      showSingleFixer:false,
      fixer:'',
      user:'',
      text:'',
      tooltipOpen: false,
      order:'',
      showSingleOrder:false,
    }

  }

  componentDidMount(){
    // this.getArea() //check if is login
    window.scrollTo(0, 0)
    this.getOrdersRate()
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getOrdersRate())
  }

  // toggle() {
  //     this.setState({tooltipOpen: !this.state.tooltipOpen})
  // }

  getSingle(type,item){
    console.log(item)
    if(type == 'customer'){
     this.setState({showSingleCustomer:true,customer:item.customer})
    }else if(type == 'fixer'){
     this.setState({showSingleFixer:true,fixer:item})
    }else if(type == 'order'){
      this.setState({showSingleOrder:true,order:item.orderId})
     }
   }

   reload =()=>{
    this.setState({showSingleCustomer:false,showSingleFixer:false,showSingleOrder:false})
  }


  getOrdersRate(){
    this.setState({loading:!this.state.loading})
    OrdersRateService.get(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        rates:res.data.rates,
        noResult:!res.data.rates.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }


  toggle(item) {
    if (item.orderId == item.orderId) {
      this.setState({tooltipOpen: !this.state.tooltipOpen,text:item.comment})
    }else {
      this.setState({tooltipOpen: this.state.tooltipOpen,text:''})
    }
  }



  render() {
    return (
      <div className="app align-items-center">
        <Container>
        {this.state.showSingleCustomer?<SingleCustomer data={this.state.customer}  reload={this.reload}/>:null}
        {this.state.showSingleFixer?<SingleFixer data={this.state.fixer} reload={this.reload} from={"rate"}/>:null}
        {this.state.showSingleOrder?<SingleOrder data={this.state.order} reload={this.reload}/>:null}
          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderAdmins()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Orders Rate</span>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderAdmins(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Order Serial</th>
            <th style={{textAlign:'center'}}>Customer Name</th>
            <th style={{textAlign:'center'}}>Fixer Name</th>
            <th style={{textAlign:'center'}}>Fixer Rate</th>
            <th style={{textAlign:'center'}}>Order Price Rate</th>
            <th style={{textAlign:'center'}}>Comment</th>
            <th style={{textAlign:'center'}}>Review Date</th>

          </tr>
        </thead>
        <tbody>
          {this.state.rates.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('order',item)}>{item.order?item.order.serial:'n/a'}</Button>
              </td>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('customer',item)}>{item.customer.enName}</Button>
              </td>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('fixer',item)}>{item.fixer.arName == null ? item.fixer.enName : item.fixer.arName}</Button>
                </td>
              <td style={{textAlign:'center'}}>{item.totalRate}</td>
              <td style={{textAlign:'center'}}>{item.priceSatisfactionLevel.enName}</td>
              <td style={{textAlign:'center'}}>
                <span id={'Tooltip-' + item.orderId} href="#">{item.comment == null ? 'N/A' : item.comment.slice(0, 5) + "  " +"..."}</span>
                    <Tooltip placement="bottom" isOpen={this.state.text == item.comment ?  this.state.tooltipOpen : false}
                            target={'Tooltip-' + item.orderId} autohide={false}
                           toggle={()=>this.toggle(item)}>{this.state.text == item.comment ? item.comment : ''}
                   </Tooltip>
              </td>
              <td style={{textAlign:'center'}}>{item.createdAt}</td>
            </tr>
           ))}
        </tbody>
      </Table>
    )
  }



}

export default Rates;
