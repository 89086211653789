import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle ,Label} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class ChangeCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
        fixers:[],
        categories:[],
        selectedCategory:'Category',
        selectedCategoryId:'',
        selectedServiceGroup:'Service Group',
        selectedServiceGroupId:'',
        serviceGroups:[],
        selectedSuggestedFixer:'Fixer',
        selectedSuggestedFixerId:'',
        allFixers:1
    }
  }

  componentDidMount(){
  this.getCategories()
  console.log(this.props.order)
  }


  getCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr})
    })
  }

  handleMenus(menu, item,page){
    switch (menu) {
      case 'category':
      if (item.id == 'choose' || item.id == 'all') {
        this.setState({selectedCategory: 'Category', selectedCategoryId: '', selectedSuggestedFixer: 'Fixer', selectedSuggestedFixerId: ''},()=>this.getServiceGroupsDependOnCategories())
      }else {
        this.setState({selectedCategory: item.enName, selectedCategoryId: item.id, selectedSuggestedFixer: 'Fixer', selectedSuggestedFixerId: ''},()=>this.getServiceGroupsDependOnCategories('dependOnCategory'))
      }
      break
      case 'serviceGroup':
      if (item.id == '') {
        this.setState({selectedServiceGroup: 'Service Group', selectedServiceGroupId: ''})
      }else {
        this.setState({selectedServiceGroup: item.enName, selectedServiceGroupId: item.id})
      }
      break
      case 'suggestedFixers':
      if (item.id == 'choose' || item.id == 'all') {
        this.setState({selectedSuggestedFixer: 'Fixer', selectedSuggestedFixerId: ''})
      }else {
        this.setState({selectedSuggestedFixer: item.arName, selectedSuggestedFixerId: item.id})
      }
      break
      default:
    }
  }



  getServiceGroupsDependOnCategories(type){
    if (type == 'dependOnCategory') {
      this.getServiceGroupsDependOnCategories()
    }else {
      Services.getServiceGroupsDependOnCategories(this.state.selectedCategoryId,(res)=>{
        let obj = {enName:'None',id:''}
        let arr = res.data.serviceGroups
        arr.push(obj)
        this.setState({serviceGroups:arr, allFixers:1,fixers:[]},()=>this.getFixerWithPagination())
      })
    }

  }


  getFixerWithPagination(){
    Services.getFixerWithPagination(this.state.allFixers,this.state.selectedCategoryId, this.props.order.city.id, (res)=>{
      if (res.data.paginationInfo.lastPage > 1 && this.state.allFixers != res.data.paginationInfo.lastPage) {
        let pagePlusOne = this.state.allFixers
        pagePlusOne+=1
        let fixers = this.state.fixers
        let arr = res.data.fixers
        for (var i = 0; i < arr.length; i++) {
          fixers.push(arr[i])
        }
        this.setState({allFixers:pagePlusOne, fixers:fixers},()=>this.getFixerWithPagination(pagePlusOne))
      }else if (this.state.allFixers == res.data.paginationInfo.lastPage) {
        let fixers = this.state.fixers
        let arr = res.data.fixers
        for (var i = 0; i < arr.length; i++) {
          fixers.push(arr[i])
        }
        this.setState({fixers:fixers},()=>console.log(fixers))
      }else {
        this.setState({fixers:res.data.fixers})
      }
      this.setState({loading:false})
    })
  }


  changeCategory(){
    if (this.state.selectedCategoryId == '') {
      alert('Please, select category')
      return
    }
    let data ={}
    if(this.props.order.status.enName == "pending-dispatch"){
       data = {
        categoryId : this.state.selectedCategoryId,
      }
    }else{
      if (this.state.selectedSuggestedFixerId == '') {
        alert('Please, select fixer')
        return
      }
        data = {
        categoryId : this.state.selectedCategoryId,
        fixerId:this.state.selectedSuggestedFixerId
      }
    }
    if (this.state.selectedServiceGroupId != '') {
      data.serviceGroupId = this.state.selectedServiceGroupId
    }

    console.log(data)
    this.setState({loading: true})
    Services.changeCategory(data,this.props.order.orderId,(res)=>{
      if (res.message == 'success') {
        this.setState({loading:false,mainModal:false,modalChangeCategory:false},()=>this.props.reload())
      }else {
        alert('Please , enter your data')
      }
    })
  }




  render(){
    return(
      <div>
          <div style={{marginLeft:'10%'}}>
        <Row>
          <Col md="2">
            <Label style={{marginTop:5}}>Categories : </Label>
          </Col>
          <Col md="6">
            <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}} >
              <DropdownToggle caret className='actionsButton' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />{this.state.selectedCategory}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                {this.state.categories.map((item, index)=>(
                  <DropdownItem key={index} onClick={()=>this.handleMenus('category',item)}>{item.enName}</DropdownItem>
                )
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row>
        <Col md="2">
          <Label style={{marginTop:5}}>Service Groups : </Label>
        </Col>
        <Col md="6">
          <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}} >
            <DropdownToggle caret className='actionsButton' style={{width:'100%'}}><i className="fas fa-layer-group" style={{marginRight:10}}></i>{this.state.selectedServiceGroup}</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              {this.state.serviceGroups.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMenus('serviceGroup',item)}>{item.enName}</DropdownItem>
              )
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
    </Row>
    {this.props.order.status.enName != "pending-dispatch" ? (
      <Row>
      <Col md="2">
        <Label style={{marginTop:5}}>Fixers : </Label>
      </Col>
      <Col md="6">
        <UncontrolledDropdown  className="mb-3" style={{marginLeft:20}} >
          <DropdownToggle caret className='actionsButton' style={{width:'100%'}}><i style={{marginRight:10}} className="fas fa-user-tie"></i>{this.state.selectedSuggestedFixer}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.fixers.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('suggestedFixers',item)}>{item.arName}</DropdownItem>
            )
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Col>
  </Row>
    ) : null}
  </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }


}
