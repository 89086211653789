import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label,Input,InputGroup } from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import PermissionsService from './../../services/permissions/PermissionsService'
export default class CreateRole extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      roleName:'',
      description:'',
      loading:false
    }

  }
  componentDidMount(){
  }



  createRole(){
    if(this.state.description == "" && this.state.roleName == ""){
      alert("Please, add role name and role description")
    }else if(this.state.roleName == "" || this.state.roleName.trim() == ""){
      alert('Please, add role name')
    }else if(this.state.description == "" || this.state.description.trim() == ""){
      alert("Please, add role description")
    }else{
      this.setState({loading:true})
      let data = {
        name:this.state.roleName,
        displayName:this.state.roleName,
        description:this.state.description
      }
      PermissionsService.addRoles(data,(res)=>{
        this.setState({loading:false},()=>this.props.update())
      })
    }
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
        <Row>
          <Col md='3'>
            <label style={{marginTop:10}}><strong>Name</strong></label>
          </Col>
          <Col md='6' >
            <InputGroup style={{marginBottom:'20px'}}>
              <Input style={{padding:'20px'}} maxLength='150' placeholder='Name' value={this.state.roleName} onChange={(e)=>this.setState({roleName:e.target.value})}/>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col md='3'>
            <label style={{marginTop:10}}><strong>Description</strong></label>
          </Col>
          <Col md='6' >
            <InputGroup style={{marginBottom:'20px'}}>
              <Input type="textarea" style={{padding:'20px',resize:'none',height:150}} maxLength='500' placeholder='Description' value={this.state.description} onChange={(e)=>this.setState({description:e.target.value})}/>
            </InputGroup>
          </Col>
        </Row>

        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
