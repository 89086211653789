import React from 'react'
import { withRouter } from 'react-router-dom'
import { InputGroup , InputGroupAddon , Input, InputGroupText } from 'reactstrap'
import LogoImg from './../../assets/img/logo.png'
import { Dropdown , DropdownToggle , DropdownMenu , DropdownItem  , UncontrolledTooltip } from 'reactstrap'

class FixerChatHeader extends React.Component
{
  constructor(props){
    super(props);
    this.state = {
      isOpen:false
    }
  }
  render(){
    return(
    <div style={{flex:'0 0 10%'}}>
      <div className='chat-panel_header'>
        <div id='customer-chat-header' onClick={ () => this.props.history.push('/orders') } style={{flex:'0 0 12%',cursor:'pointer', alignSelf:'center'}}>
          <img style={{width:'100%'}} src={LogoImg} />
        </div>
        <div style={{flex:4,padding:'10px 0 0 10px'}}>
          <p style={{fontSize:'1.6rem',fontWeight:'bold'}}> Fixers Chat </p>
        </div>
        <div style={{flex:0, alignSelf:'center'}}>
          <Dropdown direction="up" isOpen={this.state.isOpen} toggle={()=>this.setState((pS)=>({isOpen:!pS.isOpen}))}>
            <DropdownToggle className='message-snippit-btn'>
                <span className="fa fa-2x fa-cog" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={()=>this.props.history.push('/chat/customer')}>
                  <span className="fa fa-recycle" />
                  <span>
                    Swith To Customer Chat
                  </span>
                </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <UncontrolledTooltip placement="top" target="customer-chat-header">Go To Home</UncontrolledTooltip>
      <div style={{padding:'0px 10px 10px'}}>
        <InputGroup size="md">
          <InputGroupAddon addonType="prepend">
            <InputGroupText >
              <i className="fa fa-search" aria-hidden="true" ></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder='Search By FixerName ... '
            value={this.props.searchTerm}
            onChange={this.props.onSearch}
            style={{backgroundColor:'#f0f3f5', borderLeftWidth:0}}
          />
        </InputGroup>
      </div>
    </div>)
  }
 }

export default withRouter(FixerChatHeader);
