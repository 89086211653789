import React from 'react'
import Services from '../../services/Services';
import WorkContractService from '../../services/workContractServices/WorkContractService';
import LoadingSpinner from '../LoadingSpinner';
import NoResult from '../NoResult';
import { Row , Col , Table } from 'reactstrap'

class FixerWorkContractHistory extends React.Component{

  constructor(props){
    super(props);
    this.state={
      noResult:false,
      loading:false,
      actions:[]
    }
  }

  componentDidMount(){
    this.getFixerHistory();
  }

  getFixerHistory(){
    this.setState({loading:true})
    WorkContractService.getFixerHistory(this.props.fixerWorkContract.id,(res) => {
      if (res.message == 'success') {
        this.setState({
          loading:false,
          noResult:!res.data.fixerWorkContractActions.length,
          actions:res.data.fixerWorkContractActions
        })
      }
    })
  }

  render(){
    return(
      <>
      {this.state.noResult?(<NoResult/>):(
        <div>
          <Row>
            <Col>
              <Table hover bordered striped responsive size="md">
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}} width='15%'>Name</th>
                    <th style={{textAlign:'center'}} width='25%'>Actions</th>
                    <th style={{textAlign:'center'}} width='25%'>Date and Time</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.actions.map((action, i)=>(
                    <tr key={i}>
                      <td style={{textAlign:'center'}}>{action.actionMaker.name}</td>
                      <td style={{textAlign:'center'}}>
                        <span>- Action : {action.briefDescription == null ? 'N/A' : action.briefDescription}</span><br/>
                      </td>
                      <td style={{textAlign:'center'}}>{action.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
        </Row>
      </div>
      )}
      <LoadingSpinner loading={this.state.loading} />
      </>
    );
  }
}

export default FixerWorkContractHistory;
