import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Cancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedReasonType:'Choose',
        cancelReasons:[],
        renderPricingOrder:false,
        renderEditOrder:false,
        reasonId:'',
        selectedCancelReason:'Choose',
    }
  }

  componentDidMount(){
    this.getCancelReasons()
  }

  handleCancelType(item){
    if (item == 'Cancelation') {
      this.setState({selectedReasonType:item,selectedCancelReason:'Choose'},()=>this.getCancelReasons(4))
    }else {
      this.setState({selectedReasonType:item,selectedCancelReason:'Choose'},()=>this.getCancelReasons(5))
    }
  }

  handleCancelReasons(item){
    this.setState({selectedCancelReason:item.enName, reasonId:item.id})
  }

  getCancelReasons(reasonTypeIds){
    this.setState({loading:true})
    Services.getCancelReasons(reasonTypeIds, (res)=>{
      this.setState({loading:false, cancelReasons:res.data.reasons,mainModal:true, modalCancel:true})
    })
  }

  cancel(){
    if (this.state.reasonId == '') {
      alert('Please, choose reason in order to cancel an order')
    }else {
      this.setState({loading:true})
      Services.submitCancelOrder(this.state.reasonId, this.props.order.orderId,  (res)=>{
        this.setState({loading:false, mainModal:false, modalCancel:false},()=>this.props.reload())
      })
    }
  }

  render(){
    return(
      <div>
        <div>
          <Row>
            <Col md="3"><p>Reason Type: </p></Col>
            <Col md="6">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedReasonType}</DropdownToggle>
                <DropdownMenu style={{width:'100%'}}>
                  <DropdownItem onClick={()=>this.handleCancelType('Failure')}>Failure</DropdownItem>
                  <DropdownItem onClick={()=>this.handleCancelType('Cancelation')}>Cancelation</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          {this.state.cancelReasons.length != 0 ?(
            <Row>
              <Col md="3">
                <p>Choose Reasons: </p>
              </Col>
              <Col md="6">
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedCancelReason}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.cancelReasons.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleCancelReasons(item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          ):null}
        </div>

        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
