import React, { Component } from 'react'
import { Button, Col, Row ,Table ,CardHeader ,Badge ,Modal ,ModalBody ,ModalHeader ,ModalFooter  ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Input ,Form,FormGroup,Label} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import workContractReportServices from './../../services/reports/workContractReportServices'

class WorkContractDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      workContracts:[],
      currentPage:1,
    }
  }
  componentDidMount(){
    this.getWorkContractReportDetails()
  }

  getWorkContractReportDetails(){
    this.setState({loading:true})
    let api = `${this.props.api}&page=${this.state.currentPage}&specificMonth=${this.props.workContract.month}&specificYear=${this.props.workContract.year}`
    workContractReportServices.getDatails(api, (res)=>{
      this.setState({loading:false,workContracts:res.data})
    })
  }

  getNextReports(){
    let page = this.state.currentPage
    this.setState({currentPage:page+1},()=>this.getWorkContractReportDetails())
  }
  getPreviousReports(){
    let page = this.state.currentPage
    this.setState({currentPage:page-1},()=>this.getWorkContractReportDetails())
  }

  render(){
    return (
      <div>

        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center'}}>WC Id</th>
              <th style={{textAlign:'center'}}>fx-reservation</th>
              <th style={{textAlign:'center'}}>fx-accepted</th>
              <th style={{textAlign:'center'}}>fx-new</th>
              <th style={{textAlign:'center'}}>fx-declined</th>
              <th style={{textAlign:'center'}}>fx-approved</th>
              <th style={{textAlign:'center'}}>fx-expired</th>
              <th style={{textAlign:'center'}}>fx-closed</th>
              <th style={{textAlign:'center'}}>fx-terminated</th>
            </tr>
          </thead>
          <tbody>
            {this.state.workContracts.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>{item.workContractId}</td>
                <td style={{textAlign:'center'}}>{item.fwcsCount}</td>
                <td style={{textAlign:'center'}}>{item.acceptedRequests}</td>
                <td style={{textAlign:'center'}}>{item.newCount}</td>
                <td style={{textAlign:'center'}}>{item.declinedCount}</td>
                <td style={{textAlign:'center'}}>{item.approvedCount}</td>
                <td style={{textAlign:'center'}}>{item.expiredCount}</td>
                <td style={{textAlign:'center'}}>{item.closedCount}</td>
                <td style={{textAlign:'center'}}>{item.terminatedCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {this.renderPagination()}

        <Modal fade={false} isOpen={this.state.getDatails} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>
      </div>
    )
  }

  renderPagination(){
    return(
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', padding:'5px 15px'}}>

        <Button
          onClick={()=>this.getPreviousReports()}
          className='actionsBtn'
          disabled={this.state.currentPage <= 1}
          style={{color:'#fff',padding:'7px 20px'}}
          >
            <span>
              <i className='fa fa-angle-left' style={{marginRight:5}}/>  Perviose
            </span>
          </Button>
          <Button
            disabled={this.state.workContracts.length == 0 }
            onClick={()=>this.getNextReports()}
            className='actionsBtn'
            style={{color:'#fff',padding:'7px 20px'}}
            >
              <span>Next    <i className='fa fa-angle-right' style={{marginLeft:5}}/></span>
            </Button>
      </div>
    )
  }
}

export default withRouter(WorkContractDetails)
