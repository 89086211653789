import React,{Fragment} from 'react';
import {DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'
import LoadingSpinner from './../LoadingSpinner';
import Services from './../../services/Services';
class CityFilter extends React.Component{
  constructor(props){
    super(props)
    this.state={
      cities:[],
      cityId:null,
      selectedCity:'City',
      loading:false,
    }
  }
  clear(){
    this.setState({selectedCity:'City',cityId:null})
  }
  componentDidMount(){
    this.getCities()
  }
  setSelectedCity(city){
    this.setState({
      cityId:city.id,
      selectedCity:city.enName,
    })
  }
  getCityId(){
    return this.state.cityId?this.state.cityId:false
  }
  getCities(){
    this.setState({loading:true})
    Services.getCities((res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.cities
      arr.push(obj)
      arr.unshift(choose)
      this.setState({cities:arr,loading:false})
    })
  }
  render(){
    return(
      <Fragment>
        <UncontrolledDropdown  className="mb-3">
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />{this.state.selectedCity}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.cities.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>{this.props.handler(item);this.setSelectedCity(item)}}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    )
  }
}
export default CityFilter;
