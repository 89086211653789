import React from 'react'
import Switch from "react-switch";
class Toggler extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <label style={{width:60}}>
                <Switch onChange={() => this.props.handler()} checked={!!this.props.status} />
            </label>
        )
    }
}
export default Toggler;