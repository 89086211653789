import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table, CardHeader, Badge, PaginationItem, PaginationLink, Pagination, FormGroup, Label, Modal, ModalBody, ModalHeader, ModalFooter, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Tooltip } from 'reactstrap';
import Services from './../../services/Services'
import OrderStatusReportServices from './../../services/reports/OrderStatusReportServices'
import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { buildQueryStringFromArray } from './../../core/helper'
import moment from 'moment'
import { PieChart } from 'react-minimal-pie-chart'

class OrderStatusReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:['loading'],
      cities:[],
      areas:[],
      categories:[],
      serviceGroups:[],
      selectedCities:[],
      selectedAreas:[],
      selectedCategories:[],
      selectedPackages:[],
      selectedServiceGroups:[],
      from:moment().startOf('month').toDate(),
      to:'',
      renderFromDate : moment().startOf('month').add(1, 'days').toDate().toISOString().split('T')[0] ,
      renderToDate: '',
      currentPage: 1
    }
  }

  async componentDidMount() {
    await this.getOrderStatusReport()
    await this.getCities()
    window.scrollTo(0, 0)
  }

  getCities() {
    console.log(JSON.parse(localStorage.getItem('userData')).accessToken);
    this.setState({ loading: true })
    Services.getCities((res) => {
      let obj = { enName: 'All', id: 'all' }
      let choose = { enName: 'Choose', id: 'choose' }
      let arr = res.data.cities
      arr.push(obj)
      arr.unshift(choose)
      this.setState({ cities: arr }, () => this.getArea())
    })
  }
  getArea() {
    this.setState({ loading: true })
    Services.getArea(this.state.selectedCities, (res) => {
      let obj = { enName: 'All', id: 'all' }
      let choose = { enName: 'Choose', id: 'choose' }
      let arr = res.data.areas
      arr.push(obj)
      arr.unshift(choose)
      this.setState({ areas: arr }, () => this.getCategories())
    })
  }

  getCategories() {
    Services.getCategories((res) => {
      let obj = { enName: 'All', id: 'all', children: [] }
      let choose = { enName: 'Choose', id: 'choose', children: [] }
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i, 1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({ categories: arr }, () => this.getServiceGroups())
    })
  }
  getServiceGroups() {
    Services.getAllServiceGroups((res) => {
      let arr = [{ id: 'choose', enName: 'Choose' }];
      for (let i = 0; i < res.data.serviceGroups.length; i++) {
        arr.push(res.data.serviceGroups[i]);
      }
      arr.push({ id: 'all', enName: 'All' })
      this.setState({ serviceGroups: arr })
    })
  }

  getOrderStatusReport(type) {
    let api = ''
    if (type == 'filter') {
      api = this.filter('filter')
    } else {
      api = this.filter()
    }

    if (type == 'filter' && api == `${Global.baseURL}admin-panel/reports/orders/status?`) {
      alert('Please, choose filter')
      return true
    }
    console.log(this.state.currentPage);

    console.log(api);

    this.setState({ loading: true })

    OrderStatusReportServices.getReport(api, (res) => {
      let arr = res.data[0].slice()
      arr.map(e => {
        e.value = e.ordersCount
        e.title = e.enName
      })
      this.setState({
        loading: false,
        data: this.assignColorDependOnStatus(arr),
        noResult: res.data.length == 0 ? false : true,
      }, () => this.forceUpdate())
    })
  }


  assignColorDependOnStatus(items) {
    let arr = items
    arr.map(e => {
      e.color = this.getColor(e.statusId)
      console.log(e);
    })
    return arr
  }
  getColor(id) {
    switch (id) {
      case 1: return '#ffc107'; break
      case 2: return '#28a745'; break
      case 3: return '#007bff'; break
      case 4: return '#343a40'; break
      case 30: return '#7D67B5'; break
      case 30: return '#7D67B5'; break
      case 31: return '#964A1B'; break
      case 32: return '#d1c5f9'; break
      case 33: return '#254184'; break
      case 34: return '#71758D'; break
      default: return '#dc3545'
    }
  }




  handleMultiSelectMenu(type, item) {
    if (item.id == 'choose') {
      this.state[type] = [];
    } else if (item.id == 'all') {
      this.state[type] = [];
      this.state[type].push(item);
    } else {
      if (this.state[type].includes(item)) {
        alert('you have been select this item before')
      } else {
        this.state[type] = this.state[type].filter(item => item.id != 'all')
        this.state[type].push(item)
      }
    }
    this.forceUpdate()

  }
  removeMultiFilter(type, index) {
    this.state[type].splice(index, 1)
    this.forceUpdate()
  }
  filter(type) {
    let api = '', wcDetailsApi = ''
    if (type == 'filter') {
      api = `${Global.baseURL}admin-panel/reports/orders/status?page=1`
    } else {
      api = `${Global.baseURL}admin-panel/reports/orders/status?page=${this.state.currentPage}`
    }


    if (this.state.selectedCities.length != 0 && !(this.state.selectedCities[0].id == 'all')) {
      api += buildQueryStringFromArray('cities', this.state.selectedCities)
      wcDetailsApi += buildQueryStringFromArray('cities', this.state.selectedCities)
    }
    if (this.state.selectedCategories.length != 0 && !(this.state.selectedCategories[0].id == 'all')) {
      api += buildQueryStringFromArray('categories', this.state.selectedCategories)
      wcDetailsApi += buildQueryStringFromArray('categories', this.state.selectedCategories)
    }
    if (this.state.selectedAreas.length != 0 && !(this.state.selectedAreas[0].id == 'all')) {
      api += buildQueryStringFromArray('areas', this.state.selectedAreas)
      wcDetailsApi += buildQueryStringFromArray('areas', this.state.selectedAreas)
    }
    if (this.state.selectedServiceGroups.length != 0 && !(this.state.selectedServiceGroups[0].id == 'all')) {
      api += buildQueryStringFromArray('serviceGroups', this.state.selectedServiceGroups)
    }

    if (this.state.renderFromDate) {
      api += `&from=${this.state.renderFromDate}`
      wcDetailsApi += `&startDate=${this.state.renderFromDate}`
    }
    if (this.state.renderToDate) {
      api += `&to=${this.state.renderToDate}`
      wcDetailsApi += `&endDate=${this.state.renderToDate}`
    }

    console.log(api);

    return api
  }

  resetFilter() {
    this.setState({
      selectedCities: [],
      selectedAreas: [],
      selectedCategories: [],
      selectedServiceGroups:[],
      from:moment().startOf('month').toDate(),
      currentPage:1,
      to:'',
      renderFromDate:moment().startOf('month').add(1, 'days').toDate().toISOString().split('T')[0],
      renderToDate:'',
    },()=>this.getOrderStatusReport(''))
  }


  handleOnChangeDate(type, date) {
    var day = new Date(date + ' Z')

    if (!date) {
      this.setState({ from: '', renderFromDate: '', to: '', renderFromDate: '' })
    } else {
      var neededDate = day.toISOString().split('T')[0]
      if (type === 'from') {
        this.setState({ from: date, renderFromDate: neededDate }, () => console.log(this.state.renderFromDate))
      } else {
        this.setState({ to: date, renderToDate: neededDate }, () => console.log(this.state.renderToDate))
      }
    }
  }



  render() {
    return (
      <div className="app align-items-center">

        <Container>
          {this.renderFilter()}
          <Card>
            <CardHeader className='shadow'>
              <Row>
                <Col md="8" style={{ display: 'flex', alignItems: 'center' }}>
                  <i className="fa fa-align-justify" ></i>
                  <span style={{ marginLeft: 6 }}>Order Status</span>
                </Col>
              </Row>

            </CardHeader>
            <Row style={{ justifyContent: 'center' }}>
              {this.state.data[0] == 'loading' ? null :
                this.state.data.length != 0 ? (
                  <Col md='8' >
                    <PieChart
                      data={this.state.data}
                      radius={PieChart.defaultProps.radius - 10}
                      lineWidth={60}
                      segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                      segmentsShift={(index) => (index == this.state.selected ? 6 : 1)}
                      animate
                      label={({ dataEntry }) => `${dataEntry.ordersCount} ${dataEntry.enName} with ${Math.round(dataEntry.percentage)}%`}
                      labelPosition={100 - 60 / 2}
                      labelStyle={{
                        fill: '#fff',
                        opacity: 0.75,
                        pointerEvents: 'none',
                        fontSize: '2px',
                        textShadow: '2px 2px #777'
                      }}
                      onClick={(e, segmentIndex) => this.setState({ selected: segmentIndex })}
                    />
                  </Col>
                ) : (
                  <CardBody className='shadow' style={{ textAlign: 'center', color: '#777', padding: '14%' }}>
                    <i className="fas fa-heart-broken" style={{ fontSize: 50, marginBottom: '2%' }}></i>
                    <h2>There's no result</h2>
                  </CardBody>
                )}

            </Row>



            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{ backgroundColor: '#00000060' }} />
            </Modal>

          </Card>
        </Container>


      </div>
    )
  }


  renderFilter() {
    return (
      <>
        <Row style={{ padding: '0px 10px' }}>
          <UncontrolledDropdown className="" style={{}}>
            <DropdownToggle caret className='actionsBtn' style={{ width: '100%' }}><i className='fas fa-city' style={{ marginRight: 10 }} />City</DropdownToggle>
            <DropdownMenu style={{ width: '100%' }}>
              {this.state.cities.map((item, index) => (
                <DropdownItem key={index} onClick={() => { this.handleMultiSelectMenu('selectedCities', item) }}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown className=" areaMenu" style={{ marginRight: 20 }}>
            <DropdownToggle caret className='actionsBtn' style={{ width: '100%' }}><i className='fas fa-thermometer-quarter' style={{ marginRight: 10 }} />Area</DropdownToggle>
            <DropdownMenu style={{ width: '100%' }}>
              {this.state.areas.map((item, index) => (
                <DropdownItem key={index} onClick={() => this.handleMultiSelectMenu('selectedAreas', item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>


          <UncontrolledDropdown className="" style={{ marginRight: 20 }} >
            <DropdownToggle caret className='actionsBtn' style={{ width: '100%' }}><i className='fas fa-sitemap' style={{ marginRight: 10 }} />Category</DropdownToggle>
            <DropdownMenu style={{ width: '100%' }} className='scrolledMenu'>
              {this.state.categories.map((item, index) => (
                <DropdownItem key={index} onClick={() => this.handleMultiSelectMenu('selectedCategories', item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown className="" style={{ marginRight: 20 }} >
            <DropdownToggle caret className='actionsBtn' style={{ width: '100%' }}><i className='fas fa-sitemap' style={{ marginRight: 10 }} />Service Group</DropdownToggle>
            <DropdownMenu style={{ width: '100%' }} className='scrolledMenu'>
              {this.state.serviceGroups.map((item, index) => (
                <DropdownItem key={index} onClick={() => this.handleMultiSelectMenu('selectedServiceGroups', item)}>{item.enName}</DropdownItem>
              )
              )}
            </DropdownMenu>
          </UncontrolledDropdown>


          <div className="date">
            <DatePicker
              maxDate={this.state.to === '' ? new Date() : this.state.to}
              selected={this.state.from}
              onChange={(date) => this.handleOnChangeDate('from', date)}
              dateFormat="yyyy-MM-dd"
              placeholderText='Start date'
            />
          </div>
          {this.state.from != '' ? (
            <div className='fa fa-close clearDatePicker' onClick={() => this.setState({ from: '', fromDate: '' })} />
          ) : null}

          <div className="date">
            <DatePicker
              minDate={this.state.from}
              disabled={this.state.from == ''}
              selected={this.state.to}
              onChange={(date) => this.handleOnChangeDate('to', date)}
              dateFormat="yyyy-MM-dd"
              placeholderText='End date'
            />
          </div>
          {this.state.to != '' ? (
            <div className='fa fa-close clearDatePicker' onClick={() => this.setState({ to: '', fromDate: '' })} />
          ) : null}

        </Row>

        <Row style={{ padding: '5px 10px' }}>
          <Button onClick={() => this.getOrderStatusReport('filter')} className='actionsBtn' style={{ backgroundColor: '#3a4248', borderColor: '#3a4248', color: '#fff', padding: '7px 20px' }}>Filter</Button>
          <Button onClick={() => this.resetFilter()} className='actionsBtn' style={{ backgroundColor: '#a91010', borderColor: '#a91010', color: '#fff', padding: '7px 20px' }}>Reset</Button>
        </Row>

        <Row style={{ padding: '0px 10px' }}>
          {this.renderFiltersInfo()}
        </Row>
      </>
    )
  }


  renderFiltersInfo() {
    return (<>
      <Row style={{ padding: '0px 0px 20px 0px' }}>
        {this.state.selectedCities.length ? (
          <div style={{ marginLeft: 20 }}>
            <span style={{ paddingLeft: '10px', fontSize: 14, fontWeight: 'bold' }} >Cities </span>
            <div>
              {this.state.selectedCities.map((city, i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={() => { this.removeMultiFilter('selectedCities', i) }} >
                  {`${city.enName} `}
                  <i className='fa fa-times-circle' style={{ color: 'red', marginLeft: 10 }} />
                </Button>
              ))}
            </div>
          </div>
        ) : null}
        {this.state.selectedAreas.length ? (
          <div style={{ marginLeft: 20 }}>
            <span style={{ paddingLeft: '10px', fontSize: 14, fontWeight: 'bold' }} >Areas </span>
            <div>
              {this.state.selectedAreas.map((area, i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={() => this.removeMultiFilter('selectedAreas', i)} >
                  {`${area.enName} `}
                  <i className='fa fa-times-circle' style={{ color: 'red', marginLeft: 10 }} />
                </Button>
              ))}
            </div>
          </div>
        ) : null}

        {this.state.selectedCategories.length ? (
          <div style={{ marginLeft: 20 }}>
            <span style={{ paddingLeft: '10px', fontSize: 14, fontWeight: 'bold' }} >Categories </span>
            <div>
              {this.state.selectedCategories.map((category, i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={() => this.removeMultiFilter('selectedCategories', i)} >
                  {`${category.enName} `}
                  <i className='fa fa-times-circle' style={{ color: 'red', marginLeft: 10 }} />
                </Button>
              ))}
            </div>
          </div>
        ) : null}

        {this.state.selectedServiceGroups.length ? (
          <div style={{ marginLeft: 20 }}>
            <span style={{ paddingLeft: '10px', fontSize: 14, fontWeight: 'bold' }} >Packages </span>
            <div>
              {this.state.selectedServiceGroups.map((item, i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={() => this.removeMultiFilter('selectedServiceGroups', i)} >
                  {`${item.enName} `}
                  <i className='fa fa-times-circle' style={{ color: 'red', marginLeft: 10 }} />
                </Button>
              ))}
            </div>
          </div>
        ) : null}

      </Row>
    </>)
  }



}

export default withRouter(OrderStatusReport);
