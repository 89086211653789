import React from 'react';
import {DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'
import Services from './../../services/Services';
class CategoryFilter extends React.Component{
  constructor(props){
    super(props)
    this.state={
      loading:false,
      selectedCategory:'Category',
      categoryId:null,
      categories:[],
    }
  }
  clear(){
    this.setState({selectedCategory:'Category',categoryId:null})
  }
  componentDidMount(){
    this.getAllCategories()
  }
  setSelectedCategory(Category){
    this.setState({
      categoryId:Category.id,
      selectedCategory:Category.enName
    })
  }
  getCategoryId(){
    return this.state.categoryId?this.state.categoryId:false
  }
  getCategories = (parent,forSpecificArea = false,areaId=null)=>{
    parent.enableLoading()
    if(forSpecificArea){
      console.log('called from area change')
      this.getCategoriesForSpecificArea(parent,areaId)
    }else{
      console.log('area change for choose and all')
      this.getAllCategories(parent)
    }
  }
  getCategoriesForSpecificArea=(parent,areaId)=>{
    this.setState({loading:true})
    Services.getCategoriesThatBelongToSpecificArea(areaId, (res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr, loading:false})
      parent.disableLoading()
    })
  }
  getAllCategories =(parent)=>{
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      if (this.props.estimatable) {
        let filterArr = []
        arr.map(e => {
          if (e.estimatable) filterArr.push(e)
        })
        arr = filterArr
      }

      arr.push(obj)
      arr.unshift(choose)

      this.setState({categories:arr})
      if(parent){
        parent.disableLoading()
      }
    })
  }
  render(){
    return(

      <UncontrolledDropdown  className="mb-3" style={{marginLeft:this.props.marginLeft != 20 ? this.props.marginLeft : 20}} >
        <DropdownToggle caret className={!this.props.className?'actionsBtn':this.props.className} style={this.props.style?this.props.style:{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />{this.state.selectedCategory}</DropdownToggle>
        <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          {this.state.categories.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>{this.props.handler(item);this.setSelectedCategory(item)}}>{item.enName}</DropdownItem>
          )
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
}
export default CategoryFilter;
