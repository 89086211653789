import React from 'react'
import RightHandSideMessage from './Messages/RightHandSideMessage'
import TextMessage from './Messages/TextMessage'
import LeftHandSideMessage from './Messages/LeftHandSideMessage'
import ImageMessage from './Messages/ImageMessage'
import PriceOfferMessage from './Messages/PriceOfferMessage'
import SoundMessage from './Messages/SoundMessage'
class Message extends React.Component{
  renderMessage(){
        switch (this.props.message.type) {
            case 'text':
                return <TextMessage message={this.props.message} />
            case 'image':
                return <ImageMessage message={this.props.message} />
            case 'priceOffer':
                return <PriceOfferMessage message={this.props.message} />
            case 'sound':
              return <SoundMessage message={this.props.message} notify={(c) => this.props.notify(c)} />

        }
    }
  render(){
      return(
          <div style={{display:'block',width:'100%',padding:'15px'}}>
              {this.props.message.sender.userTypeId == 3 ? (
                <RightHandSideMessage message={this.props.message} >{this.renderMessage()}</RightHandSideMessage>
              ):(
                <LeftHandSideMessage message={this.props.message} >{this.renderMessage()}</LeftHandSideMessage>
              )}
              <div className='clearfix'></div>
          </div>
      )
  }
}

export default Message;
