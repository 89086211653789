import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class FixersService {

  static getFixersOutofWorkContracts(workContractId,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/work-contracts/${workContractId}/fixers?in=0`).then(callBack)
  }

  static assignFixersToWorkContract(workContractId,fixers,callBack){
    let data={fixers:fixers};
    Adaptor.post(`${Global.baseURL}admin-panel/work-contracts/${workContractId}/fixers`,data).then(callBack)
  }

  static getValidFixerWorkContracts(fixerId, callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/fixers/${fixerId}/approved-work-contracts`).then(callBack)
  }

}
