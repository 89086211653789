import React from 'react'
import {LoadingSpinner , NoResult} from './../../components'
import { Button, Card, CardBody, Col, Container, Row ,Table ,CardHeader } from 'reactstrap';
import Services from './../../services/Services'
import {FModal,ContentMessage} from './../../components'
import SingleCustomer from './../customers/SingleCustomer'
import SingleFixer from './../fixers/SingleFixer'
import ConfirmDeleteMessage from './../../components/ConfirmDeleteMessage'
import ChatService from './../../services/chatServices/ChatService'
import Paginator from './../../components/Pagination/V2/Paginator'

export default class ListScheduales extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      loading:false,
      noResult:false,
      scheduales:[],
      showMessageContent:false,
      previousPages:[],
      nextPages:[],
      currentPage:1,
      showSingleCustomer:false,
      showSingleFixer:false,
      message:null,
      showConfirmation:false,
    }
  }

  componentDidMount(){
    this.getScheduleMessages()
  }

  showMessageContent(message){
    this.setState({showMessageContent:true,message:message})
  }

  showCustomer(customer){
    this.setState({showSingleCustomer:true,user:customer})
  }

  showFixer(fixer){
    this.setState({showSingleFixer:true,user:fixer})
  }

  removeScheduleMessage(){
    this.setState({loading:true})
    ChatService.removeScheduleMessage(this.state.message.id,(res)=>{
      this.setState({loading:false,showConfirmation:false},()=>this.getScheduleMessages())
    })
  }

  deleteMessage(message){
    this.setState({showConfirmation:true,message:message})
  }

  getScheduleMessages(){
    this.setState({loading:true})
    ChatService.getScheduleMessages(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        scheduales:res.data.messages,
        noResult:!res.data.messages.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getScheduleMessages())
  }
  render() {
    return (
      <div className="app align-items-center">
        <Container>
        {this.state.showSingleCustomer?<SingleCustomer data={this.state.user} reload={() => this.setState({showSingleCustomer:false,user:null})}/>:null}
        {this.state.showSingleFixer?<SingleFixer data={this.state.user} reload={()=>this.setState({showSingleFixer:false,user:null})}/>:null}
        {this.state.showMessageContent?(
          <FModal
            title='Content Message'
            content={ <ContentMessage message={this.state.message} /> }
            state={this.state.showMessageContent}
            onClose={()=>this.setState({showMessageContent:false,message:null})}
            show="none"
          />
        ):null}
          <ConfirmDeleteMessage
            show={this.state.showConfirmation}
            message={"Are you sure you delete scheduale ?"}
            confirm={()=>this.removeScheduleMessage()}
            close={()=>this.setState({showConfirmation:false,message:null})}
          />

          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderSchedualeMessages()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </CardBody>
            ):(<NoResult/>)}
          </Card>

          {/* loading spinner */}
          <LoadingSpinner loading={this.state.loading} />
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Scheduales</span>
          </Col>

          <Col md="4">

          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderUserDetails(message){
    if (message.chat.type == 'Customer Support') {

      return(<>
        <td style={{textAlign:'center'}}>
          <Button
            className="click"
            style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}}
            onClick={()=>this.showCustomer(message.chat.customer)}
          >
            {message.chat.customer.enName ? message.chat.customer.enName : message.chat.customer.arName }
          </Button>
        </td>
        <td style={{textAlign:'center'}}>Customer</td>
      </>);

    }else if (message.chat.type == 'Fixer Support') {
      return(<>
        <td style={{textAlign:'center'}}>
          <Button
            className="click"
            style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}}
            onClick={()=>this.showFixer(message.chat.fixer)}
          >
            {message.chat.fixer.enName ? message.chat.fixer.enName : message.chat.fixer.arName }
          </Button>
        </td>
        <td style={{textAlign:'center'}}>Fixer</td>
      </>);

    }
  }

  renderSchedualeMessages(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>User Name</th>
            <th style={{textAlign:'center'}}>User Type</th>
            <th style={{textAlign:'center'}}>Message Content</th>
            <th style={{textAlign:'center'}}>Scheduale Date</th>
            <th style={{textAlign:'center'}}>Creation Date</th>
            <th style={{textAlign:'center'}}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {this.state.scheduales.map((message,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{message.id}</td>
              {this.renderUserDetails(message)}
              <td style={{textAlign:'center'}}>
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.showMessageContent(message)}>View Details</span>
              </td>
              <td style={{textAlign:'center'}}>{message.scheduledAt}</td>
              <td style={{textAlign:'center'}}>{message.createdAt}</td>
              <td style={{textAlign:'center'}}>

              <span onClick={()=>this.deleteMessage(message)} style={{cursor:'pointer'}}><i className="fas fa-trash-alt" style={{fontSize:22,color:'red'}}></i></span>
              </td>
            </tr>
           ))}
        </tbody>
      </Table>
    )
  }



}
