import React from 'react'
import { ChatList } from '../../../components'
import Services from './../../../services/Services'
import ChatBox from '../../../components/chat/ChatBox';
import SingleCustomer from '../../customers/SingleCustomer';
import ChatService from '../../../services/chatServices/ChatService';
import './../../../assets/style/chat/Chat.scss'
import MessageInput from '../../../components/chat/MessageInput';
import CustomerChatHeader from '../../../components/chat/CustomerChatHeader';
import Spinner from 'reactstrap/lib/Spinner';
import CardBody from 'reactstrap/lib/CardBody';
import qs from 'qs';
class CustomerChat extends React.Component{
  constructor(props){
    super(props);
    this.state={
      loading:false,
      fetchMoreChats:false,
      noResult:false,
      chatPage:1,
      chats:[],
      searchTerm:'',
      nextPages:[],
      prevPages:[],
      activeChat:null,
      showChatBoxOnSmallScreen:false,
      showOfflineBanner:false,
      showOnlineBanner:false,
      messagePrefix:'',
      customerId:''
    };
    this.chatMessageEventHandler = this.chatMessageEventHandler.bind(this)
  }

  componentDidMount(){
    let { userId , msg_prefix } = qs.parse(this.props.location.search.replace('\?',''));
    if (userId) {
      this.setState({
        searchTerm:`@${userId}`,
        messagePrefix:msg_prefix?msg_prefix:'',
        customerId:userId
      },
      () => this.getCustomerChats(
        () => this.setState(
          pS => ( { activeChat: pS.chats[0] } ) ,
          () =>{
            this.chatBoxRef.updateChat(
              this.state.activeChat,
              this.state.activeChat.customer
            )
            this.messageInputRef.focus();
          }
        )
      )
    )
  }else{
    this.getCustomerChats();
  }

  window.socket.on('chatMessage',this.chatMessageEventHandler)
  window.addEventListener('online', () => this.onOnline());
  window.addEventListener('offline', () => this.onOffline());
}

componentWillUnmount(){
  window.socket.removeEventListener('chatMessage',this.chatMessageEventHandler)
}

chatMessageEventHandler(msg){
  if ( msg.chat.type == 'Customer Support' ) {
    this.handleSocketChatMessage(msg)
  }
}

onChatClick(chat){
  this.setState({activeChat:chat,showChatBoxOnSmallScreen:true},() => {
    this.chatBoxRef.updateChat(
      chat,
      chat.customer
    );
  })
}

onOnline(){
  this.setState(
    {showOnlineBanner:true,showOfflineBanner:false},
    () => setTimeout(
      () => this.setState({showOnlineBanner:false})
      ,3000)
    )
  }

  onOffline(){
    this.setState({showOfflineBanner:true,showOnlineBanner:false})
  }

  getNextChats(){
    if (this.state.nextPages.length != 0) {
      this.setState((prevState)=>({chatPage:prevState.chatPage+1}),()=>this.getNextCustomerChats())
    }
  }

  handleSocketChatMessage(msg){

    if (msg.chat.id == this.state.activeChat?.id) {
      this.onMessageSent(msg);
      this.chatBoxRef.handleSocketChatMessage();
    }else{
      let isChatExist = false;
      this.state.chats.map(chat =>{
        if (chat.id == msg.chat.id) {
          chat.lastMessage = msg;
          if(msg.sender.userTypeId != 3){
            chat.participants[0].unSeenMessagesCount++;
          }
          msg.chat = chat;
          this.sortChats(msg.chat)
          isChatExist = true;
        }
      })
      if (!isChatExist) {
        Promise.resolve().then(() => {
          this.getCustomerChats(
            () => this.chatListRef.smothScrollToTop()
          )
        })
      }
    }
  }

  updateChatList(searchTerm){
    this.setState({chats:[],chatPage:1,searchTerm:searchTerm},()=>this.getCustomerChats())
  }



  getNextCustomerChats(){
    this.setState({fetchMoreChats:true})
    ChatService.getCustomerChats(this.state.chatPage,this.state.searchTerm,(res)=>{
      this.setState((prevState)=>({
        fetchMoreChats:false,
        chats:[...prevState.chats,...res.data.chats],
        nextPages:res.data.paginationLinks.nextPages,
        prevPages:res.data.paginationLinks.previousPages,
      }))
    })
  }

  sortChats(updatedChat){
    let sortedChats = this.state.chats.filter(chat => chat.id!=updatedChat.id);
    this.setState({chats:[updatedChat,...sortedChats]},()=>this.chatListRef.smothScrollToTop())
  }

  getCustomerChats( callback = null ){
    this.setState({loading:true,disabled:true})
    ChatService.getCustomerChats(this.state.chatPage,this.state.searchTerm,(res)=>{
      if (res.data.chats.length == 0) {
        this.startChat(callback = null)
      }else {
        this.setState({
          loading:false,
          chats:res.data.chats,
          noResult:!res.data.chats.length,
          nextPages:res.data.paginationLinks.nextPages,
          prevPages:res.data.paginationLinks.previousPages,
        },
        callback ? () => callback() : null
      )
    }
  })
}

startChat(callback = null){
  this.setState({loading:true})
  let data = {
    userId:this.state.customerId,
  }
  Services.startChat(data,(res)=>{
    if (res.message == 'success') this.getCustomerChats()
  })
}


onMessageSent(message){
  this.chatBoxRef.onNewMessage(message);
  this.state.activeChat.lastMessage = message ;
  let sortedChats = this.state.chats.filter(chat => chat.id != this.state.activeChat.id);
  this.setState({messagePrefix:'',chats:[ this.state.activeChat ,...sortedChats]},() => this.chatListRef.smothScrollToTop() )
}

goBackFromChat(e){
  e.stopPropagation();
  this.setState({showChatBoxOnSmallScreen:false});
}

render(){
  return(<>
    <div className='chat-panel'>
      {this.state.showOnlineBanner?(<div className='chat-panel_online-status'>you are online</div>):null}
      {this.state.showOfflineBanner?(<div className='chat-panel_offline-status'> <span className="fa fa-exclamation-triangle" />you are offline</div>):null}
      <div className={`chat-panel_chats-list ${this.state.showChatBoxOnSmallScreen?'chat-panel_chats-list_hide':''}`}>
        <CustomerChatHeader
          searchTerm={this.state.searchTerm}
          onSearch={(e)=>this.setState({searchTerm:e.target.value,chatPage:1},()=>this.getCustomerChats())}
        />
        {this.state.loading?(
          <div className='chat-panel_loading'>
            <div className="text-center m-5"><Spinner color="primary" /></div>
          </div>
        ):(
          <>
          {this.state.noResult?(
            <CardBody style={{textAlign:'center',color:'#777',padding:'14%'}}>
              <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
              <h2>There's no Chats</h2>
            </CardBody>
          ):(
            <ChatList
              activeChat={this.state.activeChat}
              loading={this.state.fetchMoreChats}
              forwardedRef={(ref) => this.chatListRef = ref}
              chats={this.state.chats}
              onSelectChat = { chat => this.onChatClick(chat) }
              onReachBottom = { () => this.getNextChats() }
            />
          )}
        </>
      )}
    </div>
    <div className={`chat-panel_box ${this.state.showChatBoxOnSmallScreen?'chat-panel_box_show':''}`}>
      <ChatBox
        onGoBack={(e)=>this.goBackFromChat(e)}
        ref={ ref => this.chatBoxRef = ref}
        SingleUser = {(props)=> (<SingleCustomer {...props} />)}
      />
      {this.state.activeChat?(
        <MessageInput
          messagePrefix={this.state.messagePrefix}
          messageRef={ ref => this.messageInputRef = ref }
          chat={this.state.activeChat}
          user={this.state.activeChat.customer}
          onMessageSent = { message => this.onMessageSent(message)  }
        />
      ):null}
    </div>
  </div>
</>);
}
}
export default CustomerChat;
