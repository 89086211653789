import React from 'react'
import firebase from 'firebase/app'
import 'firebase/database'
import { ListGroup, ListGroupItem, Container, Row, Col , Button } from 'reactstrap'
import { Toggler, CardList, LoadingSpinner, NoResult, PasswordModal } from '../../components'
import ServiceGroupModal from './ServiceGroupModal'
import Services from './../../services/Services'
import NoImage from './../../assets/img/noimage.jpg'
import AttachImageModal from './AttachImageModal'
class ServiceGroups extends React.Component {
  constructor(props){
    super(props)
    this.state={
      firebasePass:'test',
      authorized:false,
      data : [],
      loading:false,
      noResult:false,
      displayServiceGroupModal:false,
      displayAttachImageModal:false,
      selectedServiceGroup:null,
    }
  }
  componentDidMount(){
    this.getServiceGroups()
    this.getPassWordFromFirebase()
  }
  getServiceGroups(){
    this.setState({loading:true})
    Services.getFeaturedServiceGroups(res => {
      this.setState({
        data: res.data.serviceGroups,
        noResult:!res.data.serviceGroups.length,
        loading:false,
      })
    })
  }

  getPassWordFromFirebase(){
      firebase.database().ref('general/').on('value', (snapshot) => {
          if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
            Object.keys(snapshot.val()).forEach( (key) => {
              let val = snapshot.val()[key]
              if(key == "serviceGroupAuth"){
                this.setState({firebasePass:val})
              }
            })
          }
      })
    }

  updateFeatureStatus(item){
    this.setState({showPasswordModal:true},()=>this.setState({selectedServiceGroup:item,displayServiceGroupModal:true}))
  }
  closeAddServiceGroupModal(){
    this.setState({selectedServiceGroup:null,displayServiceGroupModal:false,authorized:false},()=>this.reload())
  }

  attachImage(item){
    this.setState({showPasswordModal:true},()=>this.setState({selectedServiceGroup:item,displayAttachImageModal:true}))
  }
  closeAttachImageModal(){
    this.setState({selectedServiceGroup:null,displayAttachImageModal:false,authorized:false},()=>this.reload())
  }

  closePasswordModal(){
    this.setState({showPasswordModal:false,authorized:false})
  }

  submitAction(){
    this.setState({authorized:true,showPasswordModal:false})
  }
  reload(){
    this.getServiceGroups()
  }
  render(){
    return(
      <>
      {this.state.showPasswordModal?(
        <PasswordModal  isOpen={this.state.showPasswordModal}
                        closeHandler={()=>this.closePasswordModal()}
                        firebasePass={this.state.firebasePass}
                        submitAction={()=>this.submitAction()} />
      ):null}
      {this.state.selectedServiceGroup&&this.state.displayServiceGroupModal&&this.state.authorized?(
        <ServiceGroupModal  isOpen={this.state.displayServiceGroupModal}
                            serviceGroup={this.state.selectedServiceGroup}
                            closeHandler={()=>this.closeAddServiceGroupModal()}
                            toggleHandler={()=>this.closeAddServiceGroupModal()}
         />):null}
      {this.state.selectedServiceGroup&&this.state.displayAttachImageModal&&this.state.authorized?(
        <AttachImageModal   isOpen={this.state.displayAttachImageModal}
                            serviceGroup={this.state.selectedServiceGroup}
                            closeHandler={()=>this.closeAttachImageModal()}
                            toggleHandler={()=>this.closeAttachImageModal()}
                            />
      ):null}
        <Container>
          <Row>
            <Col md='12'>
              {this.state.noResult?(
                <NoResult/>
              ):(
              <ListGroup>
                  {this.state.data.map((item,index) => (
                    <ListGroupItem key={index} className="justify-content-between">
                      <CardList img={item.featuredImage?item.featuredImage:NoImage}
                                toggleStatus={item.featured}
                                toggleUploadImageHandler={()=>this.attachImage(item)}
                                toggleHandler={()=>this.updateFeatureStatus(item)}>{item.enName?item.enName:item.arName}</CardList>
                    </ListGroupItem>
                  ))}
              </ListGroup>
              )}
            </Col>
          </Row>
        </Container>
        <LoadingSpinner loading={this.state.loading} />
      </>
    )
  }
}
export default ServiceGroups;
