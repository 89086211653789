import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"

export default class AssignFixerInPendingDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFixerInPendingDispatch:[],
      multiSelectFixerInPendingDispatch:[],
      pendingDispatchFixers:[]
    }
  }

  componentDidMount(){
 this.getFixersInPendingDispatch()
  }

  getFixersInPendingDispatch(){
    var requestedDate = this.props.order.requestedDate
    requestedDate = requestedDate.split("-").reverse().join("-");
    this.setState({loading:true})
    Services.getFixersInPendingDispatch(this.props.order.category.id, this.props.order.city.id , requestedDate , (res)=>{
      let arr = res.data.fixers
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].arName, id: arr[i].id}
        titles.push(obj)
      }
      titles.push({label:'', id: 'stop'})
      this.setState({pendingDispatchFixers:res.data.fixers, multiSelectFixerInPendingDispatch:titles, loading:false})
    })
  }

    onSelectedChanged(selectedItems) {
    console.log(selectedItems);
    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({selectedFixer:selectedItems, selectedFixerInPendingDispatch:selectedItems, fixerIds:arr},()=>console.log(arr))
  }

  assignFixerInPendingDispatch(){
    if(this.state.fixerIds.length == 0){
      alert('Please , selected fixers')
    }else{
      let data = {
        fixerId : this.state.fixerIds[0]
      }
      this.setState({loading:true})
      Services.assignFixerInPendingDispatch(this.props.order.orderId,data,(res)=>{
        this.setState({loading:false,mainModal : this.state.mainModal, modalAssignFixerInPendingDispatch:false},()=>this.props.reload())
      })
    }

  }

  render(){
    return(
      <div>
        <div>
        {!this.state.loading?(
                  <div>
                    {this.state.multiSelectFixerInPendingDispatch.length==0?(
                      <div style={{textAlign:'center',color:'#777',padding:'14%'}}>
                        <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                        <h2>There's no result</h2>
                      </div>
                    ):(
                      // <div style={{height:500}}>
                        <MultiSelect
                          items={this.state.multiSelectFixerInPendingDispatch}
                          selectedItems={this.state.selectedFixerInPendingDispatch}
                          onChange={(item)=>this.onSelectedChanged(item)}
                          // withGrouping={true}
                          // showSelectedItems={false}
                          // showSelectAll={false}
                          // maxSelectedItems={1}
                          showSelectedItems={false}
                          showSelectAll={false}
                          maxSelectedItems={1}
                          isLocked={item => item.id == 'stop'}
                          // responsiveHeight={400}
                          // height={400}
                          // itemHeight={30}
                        />
                      // </div>
                      )}
                  </div>
                ):<div style={{minHeight:400}}/>}
          </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
