import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
import Services from './../../services/Services';
import { validateUrl } from './../../core/helper'
import { LoadingSpinner } from './../../components'
import { isNumberWithoutDecimal } from './../../core/helper'

export default class ChangePosition extends React.Component{
  constructor(props){
    super(props)
    this.state={
      position:''
    }
  }

  handleChangeInputs(e){
    if (isNumberWithoutDecimal(e.target.value) || e.target.value.trim() == '' ) {
      this.setState({position : e.target.value.trim()})
    }else {
      alert('Please, enter valid inputs')
    }
  }

  change(){
    if (this.state.position.trim()=='') {
      alert('Please, enter position')
    }else {
      this.setState({loading:true})
      Services.changePosition(this.props.data.id, this.state.position, (res)=>{
        this.setState({loading:false})
        if (res.message == 'error') {
          alert('This position is not exist please enter a new one')
        }else {
          this.props.update()
        }
      })
    }
  }


  render(){
    return(
      <div>
        <Row style={{paddingLeft:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Enter position</Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.position}
              onChange={(e)=>this.handleChangeInputs(e)}
              type="text"
              placeholder="Ex 1, 2, ..."
            />
          </Col>
        </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
