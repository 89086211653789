import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Label} from 'reactstrap'
import Services from './../../services/Services'
import { LoadingSpinner } from './../../components'

export default class FixerImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      image:'',
      imagePreview:''
    }
  }

  componentDidMount(){
    console.log(this.props.singleFixer)
  }


  changeFixerImage(){
    this.setState({loading:!this.state.loading})
    let data = new FormData()
    data.append('image',this.state.image)
    data.append('_method','PATCH')
    Services.changeFixerImage(data,this.props.singleFixer.id,(res)=>{
      this.setState({loading:false},()=>this.props.update())
    })
  }


  onSelectImageFromPC = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({image: file,imagePreview:reader.result})
    }
    reader.readAsDataURL(file)
  }


  render(){
    return(
      <div style={{marginLeft:20}}>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Images :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              onChange={(e)=>this.onSelectImageFromPC(e)}
              name="uploadfile"
              id="img"
              style={{display:'none'}}
              accept="image/x-png,image/jpeg"
            />
            <Label for="img" style={{cursor:'pointer',width:'100%',backgroundColor:'#4dbd74',borderRadius:5,textAlign:'center',padding:7}}>{this.state.image == '' ? 'Upload Image' : this.state.image.name}</Label>
          </Col>
        </Row>
        {this.state.imagePreview != '' ? (
          <Row style={{marginTop:15}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview :</Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
            </Col>
          </Row>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
