import React from 'react';
import { Spinner } from 'reactstrap';
import SnippitList from './SnippitList';
import './../../assets/style/chat/MessageInput.scss'
import Send from './Buttons/Send';
import Services from '../../services/Services';
import Scheduale from './Buttons/Schedule';
import AudioRecorder from './Buttons/AudioRecorder';
import AttachmentList from './Buttons/AttachmentList';
import { appendMediaPathForMessage } from '../../core/helper';
import { Picker } from 'emoji-mart';

class MessageInput extends React.Component{
  constructor(props){
    super(props);
    this.state={
      loading:false,
      showEmoji:false,
      message:props.messagePrefix,
      snippits:[],
      disableMessageInput:false,
    }
  }

  typeMessage(message){
    let subString = message.match(/^@([a-zA-z])+([^ ])$/g);
    console.log(subString)
    if (subString) {
      this.searchInSnippits(subString[0].slice(1));
    }
    this.setState({message:message.replace(/^([ ]+)/,'')})
  }

  searchInSnippits(searchTerm){
    Services.searchSnippit(searchTerm,(res)=>{
      this.setState({snippits:res.data.snippits})
    })
  }

  selectSnippit(snippit){
    this.setState((pS)=>({message:pS.message+snippit.body+' '}))
  }

  appendEmoji(em){
    let text = this.state.message
    text += em.native
    this.setState({message: text, showEmoji:false})
  }

  sendTextMessage(){
    let textMessage = new FormData;
    let text = this.state.message;
    textMessage.append('type','text');
    textMessage.append('body',text);
    this.sendMessage(textMessage);
  }

  sendPriceOfferMessage(data){
    let priceOfferMessage = new FormData;
    priceOfferMessage.append('type','priceOffer');
    priceOfferMessage.append('body',this.state.message);
    priceOfferMessage.append('orderId',data.order.id);
    priceOfferMessage.append('price',data.price);
    this.sendMessage(priceOfferMessage);
  }

  sendImageMessage(data){
    let imageMessage = new FormData;
    imageMessage.append('type','image');
    imageMessage.append('body',data.caption);
    imageMessage.append('image',data.file);
    this.sendMessage(imageMessage);
  }

  sendSoundMessage(data){
    let soundMessage = new FormData;
    soundMessage.append('type','sound');
    soundMessage.append('sound',data.soundFile);
    this.sendMessage(soundMessage);
  }

  sendMessage(data){
    this.setState({loading:true})
    Services.sendChatMessage(data,this.props.chat.id,(res)=>{
      this.setState({loading:false,message:''})
      if (res.message == 'success') {
        this.props.onMessageSent(
          appendMediaPathForMessage(res.data.message)
        )
      }else{
        this.setState({msgLoading:false,loading:false},()=>{
          alert('message can\'t send please try again later')
        })
      }
    })
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      if (this.state.message && !this.state.loading ) {
        this.sendTextMessage()
      }
    }
  }

  render(){
    return(
      <>
      <div className='chat-panel_message-input'>
        {!this.state.disableMessageInput?(
          <>
          <div className='chat-panel_message-input_emoji'>
            <span
              className="fa fa-smile-o"
              onClick={()=>this.setState( pS => ({showEmoji:!pS.showEmoji}))}
            />
          </div>
          {this.state.showEmoji?(
            <Picker set='emojione'
              style={{ position: 'absolute', bottom: '20px', left: '70px' }}
              showPreview={false} showSkinTones={false}
              onSelect={(em)=>this.appendEmoji(em)}
            />
          ):null}

          <AttachmentList
            customer={this.props.user}
            onSendImage = { data => this.sendImageMessage(data) }
            message = {this.state.message}
            onSendPriceOffer = { data => this.sendPriceOfferMessage(data) }
          />

          {!this.state.message?(
            <SnippitList disabled={this.state.disableMessageInput} onClick={(snippit)=>this.selectSnippit(snippit)}/>
          ):null}
        </>
      ):null}


      <textarea
        ref={this.props.messageRef}
        type="text" name="value"
        className='chat-panel_message-input_text-area'
        disabled={this.state.disableMessageInput}
        placeholder="Type a message"
        value={this.state.message}
        onKeyPress={(e)=>this.handleKeyPress(e)}
        onChange={(e) => this.typeMessage(e.target.value) }
      />
      {this.state.loading?(
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Spinner color="primary" />
        </div>
      ):(
        <>
        {this.state.message?(
          <div className='chat-panel_message-input_send-block'>
            <Send
              onSend={()=>this.sendTextMessage()}
            />
            <Scheduale
              chat={this.props.chat}
              message={this.state.body}
              onMessageScheduled={()=>this.setState({message:''})}
            />
          </div>):(
            <AudioRecorder
              onRecored={()=>this.setState({disableMessageInput:true})}
              onCancel={()=>this.setState({disableMessageInput:false})}
              onMessageRecorded={ data => this.setState({disableMessageInput:false}, () => this.sendSoundMessage(data) ) }
            />
          )}
        </>
      )}
    </div>
  </>
)
}
}

export default MessageInput;
