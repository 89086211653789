import React,{Fragment} from 'react'
import {LoadingSpinner, ChangePosition} from './../../components'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Tooltip, UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import LoadingOverlay from 'react-loading-overlay'
import {FModal,ContentMessage,AddTask} from './../../components'
import SingleCustomer from './../customers/SingleCustomer'
import SingleFixer from './../fixers/SingleFixer'
import ConfirmDeleteMessage from './../../components/ConfirmDeleteMessage'
import ChatService from './../../services/chatServices/ChatService'
import Paginator from './../../components/Pagination/V2/Paginator'
import TasksService from './../../services/tasksServices/TasksService'
import TasksCalculator from './TasksCalculator'
export default class Tasks extends React.Component{
  constructor(props) {
    super(props)
    this.state={
     noResult:false,
     tasks:[],
     previousPages:[],
     nextPages:[],
     currentPage:1,
     loading:false,
     addTaskModal:false,
     viewTaskCalculator:false,
     detailsModal:false,
     details:''
    }
  }

  componentDidMount(){
   this.getTasks()
  }

  getTasks(){
    this.setState({loading:true})
    TasksService.get(this.state.currentPage,{},(res)=>{
      this.setState({
        loading:false,
        tasks:res.data.tasks,
        noResult:!res.data.tasks.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getTasks())
  }

  handleAddTask(){
      this.setState({addTaskModal:!this.state.addTaskModal})
  }

  handleCalulateTasks(){
      this.setState({viewTaskCalculator:true})
  }

  handleDetails(item){
    this.setState({detailsModal:!this.state.detailsModal,details:item})
  }
  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            {this.renderSearch()}
            <CardHeader className='shadow'>
              <Row style={{marginRight:'2px'}}>
                  <Button className='actionsBtn'  style={{marginTop:1,marginBottom:-1, display:'block', marginLeft:'auto'}} onClick={()=>this.handleCalulateTasks()}>Tasks Calculator</Button>
              </Row>
            </CardHeader>
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderTasks()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>
          <FModal
            title='Task Calculator'
            content={
              <TasksCalculator
                ref={ref=>this.taskCalculator=ref}
              />
            }
            state={this.state.viewTaskCalculator}
            onClose={()=>this.setState({viewTaskCalculator:false})}
            noSubmitBtn={true}
          />
          <FModal
            title='Add Task'
            content={
              <AddTask
                ref={ref=>this.addTaskRef=ref}
                update={()=>this.setState({addTaskModal:false},()=>this.getTasks())}
              />
            }
            state={this.state.addTaskModal}
            onClose={()=>this.setState({addTaskModal:false})}
            onSubmit={()=>this.addTaskRef.addTask()}
          />

          <FModal
            title='Details'
            content={
              <div style={{marginLeft:20}}>
              <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Task Days:
                  <span style={{fontWeight:'bold', cursor:'pointer'}}>{"       "}{this.state.details.timeDuration} Days
                    </span>
                  </span>
                </p>
              </div>
            }
            state={this.state.detailsModal}
            onClose={()=>this.setState({detailsModal:false})}
            show={"none"}
          />
          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Tasks</span>
          </Col>
          <Col md="4">
              <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',marginLeft:'auto'}} onClick={()=>this.handleAddTask()}><i className='fa fa-plus-square' style={{marginRight:8}}/>Add New Task</Button>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderTasks(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>Category</th>
            <th style={{textAlign:'center'}}>Type</th>
            <th style={{textAlign:'center'}}>Description</th>
            <th style={{textAlign:'center'}}>Price</th>
            <th style={{textAlign:'center'}}>Details</th>
          </tr>
        </thead>
        <tbody>
          {this.state.tasks.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{item.id}</td>
              <td style={{textAlign:'center'}}>{item.category.enName}</td>
              <td style={{textAlign:'center'}}>{item.type}</td>
              <td style={{textAlign:'center'}}>{item.description}</td>
              <td style={{textAlign:'center'}}>{item.cost} LE</td>
              <td style={{textAlign:'center'}}>
                {item.timeDuration != null ? (
                 <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.handleDetails(item)}>View Details</span>
                ): 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }



}
