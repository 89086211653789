import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import { Items } from './../../../components'
export default class CloseAsInspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeInspectionReasons: [],
      selectedCloseReason: 'Choose',
      selectedCloseReasonId: '',
      selectedCloseAs: 'Choose',
      closeInspectionType: '',
    }
  }

  componentDidMount() {
    this.getReasonsForClosingInspection()
  }

  handleCloseReasons(item) {
    this.setState({ selectedCloseReason: item.arName, selectedCloseReasonId: item.id })
  }

  getReasonsForClosingInspection() {
    this.setState({ loading: true })
    Services.getReasonsForClosingInspection((res) => {
      this.setState({ loading: false, closeInspectionReasons: res.data.reasons })
    })
  }

  closeAsInspection() {
    // yasmen
    if (this.state.closeInspectionType != '') {
      this.price(this.closeAsInspectionRef.getPricingObj())
    } else {
      alert('Please, fill the data')
    }

  }

  price(data) {
    let type = ''
    if (this.props.order.quotation) {
      type = 'quotation'
      data.quotationId = this.props.order.quotation.id
    } else {
      this.state.closeInspectionType == 'fixawy' ? type = 'fixawy' : type = 'marama'
    }
    // console.log(type);
    // console.log(data);
    // return
    data.reasonId = this.state.selectedCloseReasonId
    this.setState({ loading: true })
    Services.pricingOrder(data, this.props.order.orderId, 'inspection', (res) => {
      this.setState({ loading: false, handleCloseAsInspectionModel: false, }, () => this.props.reload())
    })
  }

  render() {
    return (
      <div>
        <div style={{ padding: '0px 15px' }}>
          <Row>
            <Col md="3"><p>Close Reason: </p></Col>
            <Col md="4">
              <UncontrolledDropdown className="mb-3">
                <DropdownToggle caret style={{ width: '100%', backgroundColor: 'white', borderColor: '#777777' }}>{this.state.selectedCloseReason}</DropdownToggle>
                <DropdownMenu style={{ width: '100%' }} className='scrolledMenu'>
                  {this.state.closeInspectionReasons.map((item, index) => (
                    <DropdownItem key={index} onClick={() => this.handleCloseReasons(item)}>{item.arName}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          {this.state.selectedCloseReason != 'Choose' ? (
            <Row>
              <Col md="3"><p>Close As: </p></Col>
              <Col md="4">
                <UncontrolledDropdown className="mb-3">
                  <DropdownToggle caret style={{ width: '100%', backgroundColor: 'white', borderColor: '#777777' }}>{this.state.selectedCloseAs}</DropdownToggle>
                  <DropdownMenu style={{ width: '100%' }} className='scrolledMenu'>
                    <DropdownItem onClick={() => this.setState({ selectedCloseAs: 'Choose', closeInspectionType: '' })}>Choose</DropdownItem>
                    <DropdownItem onClick={() => this.setState({ selectedCloseAs: 'Fixawy', closeInspectionType: 'fixawy' })}>Fixawy</DropdownItem>
                    <DropdownItem onClick={() => this.setState({ selectedCloseAs: 'Quotation', closeInspectionType: 'quotation' })}>Quotation</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          ) : null}


          {this.state.closeInspectionType == 'fixawy' ? (
            <Items
              ref={(el) => { this.closeAsInspectionRef = el; }}
              order={this.props.order}
              pricing='fixawy'
            />
          ) : this.state.closeInspectionType == 'quotation' ? (
            <Items
              ref={(el) => { this.closeAsInspectionRef = el; }}
              order={this.props.order}
              pricing='normal'
            />
          ) : null}
        </div>
        <LoadingSpinner loading={this.state.loading} />
      </div>
    )
  }



}
