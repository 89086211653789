import { Global } from "../../core/Global"
import Adaptor from './../Adaptor'

export default class PaymentMethodsServices {

  static get(serviceGroupId, callBack) {
    let api = `${Global.baseURL}payment-methods`
    if (!!serviceGroupId) api += `?serviceGroupId=${serviceGroupId}`
    Adaptor.get(api).then(callBack)
  }

}
