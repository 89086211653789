import React, { Component } from 'react'
import { Button, Row, Col, Table, Modal, ModalBody, ModalHeader, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Input} from 'reactstrap'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order:this.props.order,
      cardItems:[],
      pricingItems:[],
      price:'',
      time:'',
      description:'',
      otherItem:'',
      loading:false,
      showOtherItem:false,
      timeType:'Choose'
    }
    this.handleChangeInputs = this.handleChangeInputs.bind(this)
  }

  componentDidMount(){
    if (this.props.pricing=='fixawy')
      this.setState({order:this.props.order,},()=>this.getFixawyPricingItems(),this.clear())
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pricing=='fixawy') {
      this.setState({order:nextProps.order,},()=>this.getFixawyPricingItems(),this.clear())
    }else {
      this.setState({order:nextProps.order,},()=>this.clear())
    }
  }

  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'price':
      this.setState({price:e.target.value})
      break
      case 'time':
      if (this.state.timeType == 'hour' && e.target.value > 24) {
        alert('Please, enter valid time or change time type')
      }else {
        this.setState({time:e.target.value})
      }
      break
      case 'description':
      this.setState({description:e.target.value})
      break
      case 'otherItem':
      this.setState({otherItem:e.target.value})
      break
      default:
    }
  }

  getFixawyPricingItems(page = 1){
    this.setState({loading:true})
    Services.getFixawyPricingItems('',this.state.order.category.id, page,(res)=>{
      this.setState({pricingItems:res.data.pricingItems, modalPrice:true, loading:false},
        res.data.paginationLinks.nextPages.length?()=>this.getFixawyPricingItems(page+1):null
      )
    })
  }



    addToCard(item, type){
      let arr = this.state.cardItems
      let pos = arr.map(function(e) { return e.id; }).indexOf(item.id)
      if (pos === -1) {
        item.count = 1
        item.type = type
        arr.push(item)
        this.setState({cardItems:arr},()=>console.log('yas'))
      }else {
        alert('This item is already added')
      }
      this.forceUpdate()
    }

    removeFromCard(item){
      let arr = this.state.cardItems
      let pos = arr.map(function(e) { return e; }).indexOf(item)
      arr.splice(pos, 1)
      this.setState({cardItems:arr},()=>this.forceUpdate())
    }

    increaseItemCount(item){
      let arr = this.state.cardItems
      let numberOfCount = item.count
      let pos = arr.map(function(e) { return e; }).indexOf(item)
      arr[pos].count = numberOfCount+1
      this.setState({cardItems:arr},()=>this.forceUpdate())
    }
    decreaseItemCount(item){
      let numberOfCount = item.count
      if (numberOfCount !== 1) {
        let arr = this.state.cardItems
        let pos = arr.map(function(e) { return e; }).indexOf(item)
        arr[pos].count = numberOfCount-1
        this.setState({cardItems:arr},()=>this.forceUpdate())
      }else {
        confirmAlert({
          title: 'Fixawy',
          message: `Are you sure to delete ${item.enName}`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => this.removeFromCard(item)
            },
            {
              label: 'No',
              onClick: () => console.log('cancel')
            }
          ]
        })
      }
    }

    addOtherItem(){
      if (this.state.otherItem === '') {
        alert('Please, enter other item ')
      }else {
        let obj ={
          enName: this.state.otherItem,
          id: this.state.otherItem
        }
        this.addToCard(obj, 'otherItems')
        this.setState({showOtherItem:false, otherItem:''})
      }
    }



    getPricingObj(){
      let data = {}
      let time = ''
      if (this.props.pricing === 'normal') {
        if (this.state.price === '') {
          alert('Please, enter price')
        }else if (this.state.time === '') {
          alert('Please, enter valid time')
        }else if (this.state.description === '') {
          alert('Please, enter details')
        }else {
          data.fixingPrice = this.state.price
          data.time = this.state.time
          if (this.state.description !== '')
          data.description = this.state.description

          return data
        }
      }else if (this.props.pricing === 'fixawy') {
        if (this.state.cardItems.length === 0) {
          alert('Please, select one item at least')
        }else {
          let arr = this.state.cardItems
          let defaultItems = []
          let otherItems = []

          for (var i = 0; i < arr.length; i++) {
            if (arr[i].type === 'otherItems') {
              let obj = {
                name: arr[i].enName,
                numberOfItems: arr[i].count
              }
              otherItems.push(obj)
            }else {
              let obj = {
                itemId: arr[i].id,
                numberOfItems: arr[i].count
              }
              defaultItems.push(obj)
            }
          }

          if (defaultItems.length !== 0)
            data.items = defaultItems

          if (otherItems.length)
            data.otherItems = otherItems

          return data
        }
      }else {
        alert('Please, select pricing type')
      }
    }

    clear(){
      this.setState({
        cardItems:[],
        price:'',
        time:'',
        description:'',
        otherItem:'',
      })
    }


    render(){
      return(
        <div>
          {this.state.order !== '' ? this.renderPricing():null}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </div>
      )
    }


    renderPricing(){
      return(
        <div>


          {this.props.pricing=='normal'?(
            <div>
              <Row>
                <Col md="3"><p>Price:</p></Col>
                <Col md="6">
                  <input
                    style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                    placeholder="Enter price.."
                    value={this.state.price}
                    onInput = {(e) =>{e.target.value = e.target.value.match(/^[0-9.]+$/)}}
                    onChange={(e)=>this.handleChangeInputs(e, 'price')}
                  />
                </Col>
              </Row>

              <Row style={{marginTop:10}}>
                <Col md="3"><p>Time:</p></Col>
                <Col md="3">
                  <UncontrolledDropdown >
                    <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.timeType}</DropdownToggle>
                    <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                      <DropdownItem onClick={()=>this.setState({timeType:'hour', time:''})}>hour</DropdownItem>
                      <DropdownItem onClick={()=>this.setState({timeType:'day', time:''})}>day</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>

                {this.state.timeType != 'Choose'?(
                  <Col md="5">
                    <input
                      style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                      placeholder={`Enter time by ${this.state.timeType}..`}
                      value={this.state.time}
                      onInput = {(e) =>{e.target.value = e.target.value.match(/^[0-9.]+$/)}}
                      onChange={(e)=>this.handleChangeInputs(e, 'time')}
                    />
                  </Col>
                ):null}

              </Row>

              <Row style={{marginTop:10}}>
                <Col md="3"><p>Description:</p></Col>
                <Col md="6">
                  <textarea
                    wrap="hard" cols={5} rows={3}
                    style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
                    placeholder="Enter details..."
                    value={this.state.description}
                    onChange={(e)=>this.handleChangeInputs(e, 'description')}
                    maxLength={1000}
                  />
                </Col>
              </Row>
            </div>
          ):this.props.pricing=='fixawy'?(
            <div>

              <Row>
                <Col md="3">
                  <p>Items : </p>
                </Col>
                <Col md="6">
                  <Accordion allowZeroExpanded={true}>
                    {this.state.pricingItems.map((item, index)=>(
                      item.hasChildren !== 0 ?(
                        <AccordionItem key={index}>
                          <AccordionItemHeading>
                            <AccordionItemButton>{item.enName}</AccordionItemButton>
                          </AccordionItemHeading>
                          {item.children.map((child, i)=>(
                            <AccordionItemPanel key={i} onClick={()=>this.addToCard(child, 'default')}>{child.enName}</AccordionItemPanel>
                          ))}
                        </AccordionItem>
                      ):(
                        <p className='moreItem' key={index} onClick={()=>this.addToCard(item, 'default')}>{item.enName}</p>
                      )
                    ))}
                  </Accordion>
                </Col>
              </Row>




              {this.state.showOtherItem?(
                <Row style={{display:'block', marginTop:'3%'}} className='text-center otherItem'>
                  <input
                    type="text" className='col-md-5' style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',borderWidth:1,padding:7}}
                    placeholder="Enter other item.."
                    value={this.state.otherItem}
                    onChange={(e)=>this.handleChangeInputs(e, 'otherItem')}
                  />
                  <Button className='actionsBtn' onClick={()=>this.addOtherItem()}>Add<i style={{marginLeft:10}} className="fas fa-plus-square"></i></Button>

                </Row>
              ):(
                <Button className='actionsBtn' style={{display:'block',margin:'auto',marginTop:'3%'}} onClick={()=>this.setState({showOtherItem: true})}>Add other item <i style={{marginLeft:10}} className="fas fa-plus-square"></i></Button>
              )}

            </div>
          ):null}


          {this.state.cardItems.length !== 0?(
            <div>
              <p>Selected Items</p>
              <Table hover bordered striped responsive size="md">
                <thead>
                  <tr>
                    <th style={{textAlign:'center',width:'50%'}}>Item</th>
                    <th style={{textAlign:'center'}}>Count</th>
                    <th style={{textAlign:'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.cardItems.map((item, index)=>(
                    <tr key={index}>
                      <td style={{textAlign:'center'}}>{item.enName}</td>
                      <td style={{textAlign:'center'}}>
                        <div className='cardItems'>
                          <i className='fas fa-minus-circle' onClick={()=>this.decreaseItemCount(item)}/>
                          <span>{item.count}</span>
                          <i className='fas fa-plus-circle' onClick={()=>this.increaseItemCount(item)}/>
                        </div>
                      </td>
                      <td style={{textAlign:'center'}}><Button color="danger" onClick={()=>this.removeFromCard(item)}>Delete</Button></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ):null}

        </div>
      )
    }


  }
