import React, { Component } from "react";
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table, CardHeader, Badge, PaginationItem, PaginationLink, Pagination, FormGroup, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, ModalFooter} from "reactstrap";
import { Global } from "../../core/Global";
import Services from "../../services/Services";
import LoadingOverlay from "react-loading-overlay";
import { FModal, EditPromoCode } from './../../components'
import AddPromo from "./AddPromo";
import UsersModal from "../../components/promo/UsersModal";

class PromoCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singlePromo: "",
      promos: [],
      searchTerm: "",
      paginationInfo: [],
      paginationLinks: [],
      nextPages: [],
      previousPages: [],
      loading: false,
      addPromoModal: false,
      singlePromoModal: false,
      showAddPromo: false,
      currentPage: 1,
      currentApi: "",
      noResult: true,
      searchText: "",
      showPromoCodeOrders:false,
      selectedPromoCode:null,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    this.getPromos();
    window.scrollTo(0, 0);
  }
  reload = () => {
    this.setState({ showAddPromo: false, editPromoCode:false });
    this.getPromos();
  };
  handleChangeInputs = (e, handle) => {
    this.setState({ searchTerm: e.target.value });
  };
  handleKeyPress(target) {
    if (target.charCode == 13) {
      this.setState({ currentPage: 1 }, () => this.getPromos());
    }
  }

  getPromos() {
    this.setState({ loading: true })
    let api = `${Global.baseURL}admin-panel/promo-codes?page=${this.state.currentPage}`;

    if (this.state.searchTerm != "") {
      this.setState({ currentPage: 1 })
      api += `&searchTerm=${this.state.searchTerm}`;
    }
    Services.getPromos(api, res => {
      this.setState({
        promos: res.data.promoCodes,
        paginationInfo: res.data.paginationInfo,
        paginationLinks: res.data.paginationLinks,
        previousPages: res.data.paginationLinks.previousPages.reverse(),
        nextPages: res.data.paginationLinks.nextPages,
        loading: false,
        noResult: res.data.promoCodes.length == 0 ? false : true,
        searchText: this.state.searchTerm != "" ? this.state.searchTerm : ""
      })
    })
  }

  handleAddPromo() {
    this.setState({ showAddPromo: true });
    // this.setState({addPromoModal : !this.state.addPromoModal})
  }

  handleSinglePromo(item) {
    if (this.state.singlePromoModal) {
      this.setState({
        singlePromoModal: !this.state.singlePromoModal,
        showAddPromo: false
      });
    } else {
      this.setState({
        singlePromoModal: true,
        singlePromo: item,
        showAddPromo: false
      });
    }
  }
  prevPage(item) {
    let page = this.state.currentPage;
    if (item == "") {
      this.setState({ currentPage: page - 1 }, () => this.getPromos());
    } else {
      this.setState({ currentPage: item }, () => this.getPromos());
    }
  }
  nextPage(item) {
    let page = this.state.currentPage;
    if (item == "") {
      this.setState({ currentPage: page + 1 }, () => this.getPromos());
    } else {
      this.setState({ currentPage: item }, () => this.getPromos());
    }
  }

  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            {this.renderSearch()}

            {this.state.noResult ? (
              <CardBody className="shadow">
                {this.renderPromo()}
                {this.renderPagination()}
              </CardBody>
            ) : (
              <CardBody
                className="shadow"
                style={{ textAlign: "center", color: "#777", padding: "14%" }}
              >
                <i
                  className="fas fa-heart-broken"
                  style={{ fontSize: 50, marginBottom: "2%" }}
                ></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>

          <FModal
            title='Edit Promo'
            content={<EditPromoCode ref={ref=> this.EditPromoCodeRef = ref} promo={this.state.singlePromo} reload={()=>this.reload()}/>}
            state={this.state.editPromoCode}
            onClose={()=>this.setState({editPromoCode:!this.state.editPromoCode})}
            onSubmit={()=>this.EditPromoCodeRef.editPromo()}
            // show={"none"}
          />

          {this.state.showPromoCodeOrders?(
            <FModal
             title='Users Used PromoCode'
             content={
               <UsersModal
                 promoCode={this.state.selectedPromoCode}
               />
             }
             state={this.state.showPromoCodeOrders}
             onClose={()=>this.setState({showPromoCodeOrders:false,selectedPromoCode:null})}
             show={"none"}
            />
          ):null}
          {/* singlePromo */}
          <Modal
            fade={false}
            isOpen={this.state.singlePromoModal}
            toggle={() => this.handleSinglePromo()}
            className={this.props.className}
          >
            <ModalHeader toggle={() => this.handleSinglePromo()}>
              Cancel
            </ModalHeader>
            {this.state.singlePromo != "" ? this.renderSinglePromo() : null}
            <ModalFooter>
              <Button
                className="actionsBtn"
                onClick={() => this.handleSinglePromo()}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {this.state.showAddPromo ? <AddPromo reload={this.reload} /> : null}

          {/* add promo */}
          <Modal
            fade={false}
            isOpen={this.state.addPromoModal}
            toggle={() => this.handleAddPromo()}
            className={this.props.className}
          >
            <ModalHeader toggle={() => this.handleAddPromo()}>
              Add PromoCode
            </ModalHeader>
            {this.renderAddPromo()}
            <ModalFooter>
              <Button
                className="actionsBtn"
                onClick={() => this.handleAddPromo()}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className="loading">
            <LoadingOverlay
              active={this.state.loading}
              spinner
              text="Loading..."
              style={{ backgroundColor: "#00000060" }}
            />
          </Modal>
        </Container>
      </div>
    );
  }
  renderSearch() {
    return (
      <CardHeader className="shadow">
        <Row>
          <Col md="8" style={{ display: "flex", alignItems: "center" }}>
            <i className="fa fa-align-justify"></i>
            <span style={{ marginLeft: 6 }}>All Promo Code</span>
          </Col>

          <Col md="4">
            <Button
              className="actionsBtn"
              style={{
                marginBottom: "2%",
                display: "block",
                marginLeft: "auto"
              }}
              onClick={() => this.handleAddPromo()}
            >
              <i className="fa fa-plus-square" style={{ marginRight: 8 }} />
              Add Promo Code
            </Button>
          </Col>
        </Row>
      </CardHeader>
    );
  }

  renderPromo() {
    return (
      <div>
        {this.state.searchText != "" ? (
          <div style={{ textAlign: "center", margin: "2%", color: "#808080" }}>
            <i
              className="fa fa-search"
              style={{ fontSize: 30, marginBottom: ".5%" }}
            />
            <h6 style={{ fontSize: 18 }}>
              Search Results of
              <span
                style={{ fontWeight: "bold", fontSize: 22, marginLeft: 10 }}
              >
                {this.state.searchText}
              </span>
            </h6>
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() =>
                this.setState({ searchTerm: "" }, () => this.getPromos())
              }
            >
              Clear Search
              <i
                className="fa fa-times-circle"
                style={{ color: "red", marginLeft: 10 }}
              />
            </span>
          </div>
        ) : null}

        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{ textAlign: "center", width: "5%" }}>ID</th>
              <th style={{ textAlign: "center" }}>Code</th>
              <th style={{ textAlign: "center" }}>Amount</th>
              <th style={{ textAlign: "center" }}>Type</th>
              <th style={{ textAlign: "center" }}>Categories</th>
              <th style={{ textAlign: "center" }}>Cities</th>
              <th style={{ textAlign: "center" }}>From</th>
              <th style={{ textAlign: "center" }}>To</th>
              <th style={{ textAlign: "center", width:'5%' }}>Numbers of Uses</th>
              <th style={{ textAlign: "center", width:'5%'  }}>Max Amount of Discount</th>
              <th style={{ textAlign: "center", width:'5%'  }}>Number Of Customers Used</th>
              <th style={{ textAlign: "center", width:'5%'  }}>Number Of Orders Made</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.promos.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: "center" }}>
                  <Button
                    className="click"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      padding: 10,
                      fontWeight: "bold"
                    }}
                    onClick={() => this.handleSinglePromo(item)}
                  >
                    {item.id}
                  </Button>
                </td>
                <td style={{ textAlign: "center" }}>{item.code}</td>
                <td style={{ textAlign: "center" }}>{item.amount}</td>
                <td style={{ textAlign: "center" }}>{item.type}</td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    className="click"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      padding: 10,
                      fontWeight: "bold"
                    }}
                    onClick={() => this.handleSinglePromo(item)}
                  >
                    See All
                  </Button>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    className="click"
                    style={{
                      backgroundColor: "transparent",
                      borderColor: "transparent",
                      padding: 10,
                      fontWeight: "bold"
                    }}
                    onClick={() => this.handleSinglePromo(item)}
                  >
                    See All
                  </Button>
                </td>
                <td style={{ textAlign: "center" }}>{item.validFrom}</td>
                <td style={{ textAlign: "center" }}>{item.validTo}</td>
                <td style={{ textAlign: "center" }}>
                  {item.numberOfUsesPerUser}
                </td>
                <td style={{ textAlign: "center" }}>
                  {item.maxAmountOfDiscount}
                </td>
                <td style={{ textAlign: "center" }}>
                    {item.usersCount}
                </td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    className="click"
                    style={{
                      backgroundColor:'transparent',
                      borderColor:'transparent',
                      padding:10,
                      fontWeight:'bold'
                    }}
                    onClick={()=>this.setState({showPromoCodeOrders:true, selectedPromoCode:item})}
                  >
                    {item.ordersCount}
                  </Button>
                </td>
                <td>
                  <Button
                    className="click"
                    style={{
                      backgroundColor: "#4dbd74",
                      borderColor: "transparent",
                      padding: '3px 12px',
                      // fontWeight: "bold"
                    }}
                    onClick={()=> this.setState({singlePromo:item, editPromoCode:true})}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }

  renderPagination() {
    return (
      <nav>
        <Row className="pull-right">
          <Pagination style={{ justifyContent: "flex-end" }}>
            {/* previous link*/}
            <PaginationItem>
              <PaginationLink
                previous
                tag="button"
                onClick={() =>
                  this.state.paginationInfo.prevPageUrl != null
                    ? this.prevPage("")
                    : null
                }
              />
            </PaginationItem>

            {/* previousPages */}
            {this.state.previousPages.map((item, index) => (
              <PaginationItem key={index}>
                <PaginationLink
                  tag="button"
                  onClick={() => this.prevPage(item)}
                >
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* currentPage */}
            <PaginationItem active>
              <PaginationLink
                style={{
                  backgroundColor: "#4dbd74",
                  color: "black",
                  borderColor: "#4dbd74"
                }}
                tag="button"
              >
                {this.state.paginationInfo.currentPage}
              </PaginationLink>
            </PaginationItem>

            {/* nextPages */}
            {this.state.nextPages.map((item, index) => (
              <PaginationItem key={index}>
                <PaginationLink
                  tag="button"
                  onClick={() => this.nextPage(item)}
                >
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* nextPageUrl */}
            <PaginationItem>
              <PaginationLink
                style={{ color: "#4dbd74" }}
                next
                tag="button"
                onClick={() =>
                  this.state.paginationInfo.nextPageUrl != null
                    ? this.nextPage("")
                    : null
                }
              />
            </PaginationItem>
          </Pagination>
        </Row>
      </nav>
    );
  }

  renderSinglePromo() {
    return (
      <ModalBody>
        <Row>
          <Col md="6">
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              ID:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.id}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              Code:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.code}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              Amount:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.amount}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              Type:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.type}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              Calculated At:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.calculatedOn == "fixingPrice" ? "Fixing Price" : "Total Price"}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              From:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.validFrom}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              To:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.validTo}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              Numbers of Uses:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.numberOfUsesPerUser}
              </span>
            </p>
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              Max Amount of Discount:{" "}
              <span style={{ fontWeight: "bold" }}>
                {this.state.singlePromo.maxAmountOfDiscount}
              </span>
            </p>
          </Col>
          <Col md="6">
            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              <span style={{ fontWeight: "bold" }}>Cities</span>
            </p>
            {this.state.singlePromo.cities.map((i, index) => (
              <div key={index}>
                <p style={{ marginLeft: "5%" }}>
                  - <span>{i.enName}</span>
                </p>
              </div>
            ))}

            <p>
              <i
                className="icon-arrow-right-circle"
                style={{ marginRight: 10 }}
              ></i>
              <span style={{ fontWeight: "bold" }}>Categories</span>
            </p>
            {this.state.singlePromo.categories.map((i, index) => (
              <div key={index}>
                <p style={{ marginLeft: "5%" }}>
                  - <span>{i.enName}</span>
                </p>
              </div>
            ))}
          </Col>
        </Row>
      </ModalBody>
    );
  }

  renderAddPromo() {
    return (
      <ModalBody>
        <Row>
          <Col md="3">
            <p>Promo Code : </p>
          </Col>
          <Col md="6">
            <input
              placeholder="enter promo code"
              type="text"
              style={{
                width: "100%",
                borderRadius: 5,
                borderColor: "#777777",
                borderWidth: 1,
                padding: 7
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="3">
            <p>Promo amount : </p>
          </Col>
          <Col md="6">
            <input
              placeholder="enter code amount"
              type="text"
              style={{
                width: "100%",
                borderRadius: 5,
                borderColor: "#777777",
                borderWidth: 1,
                padding: 7
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col md="3">
            <p>Promo Type : </p>
          </Col>
          <Col md="4"></Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="6">
            <Row>
              <Col md="4">
                <h4>From : </h4>
              </Col>
              <Col md="8">
                <input
                  type="date"
                  style={{
                    width: "100%",
                    padding: 7,
                    borderColor: "#777777",
                    borderWidth: 1,
                    borderRadius: 5
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col md="3">
                <h4>To : </h4>
              </Col>
              <Col md="8">
                <input
                  type="date"
                  style={{
                    width: "100%",
                    padding: 7,
                    borderColor: "#777777",
                    borderWidth: 1,
                    borderRadius: 5
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: 12 }}>
          <Col md="3">
            <p>Promo Category : </p>
          </Col>
          <Col md="4">
            <Button
              onClick={this.promoCategory}
              style={{
                width: 200,
                backgroundColor: "white",
                borderColor: "#777777"
              }}
            >
              choose{"       "}
              <i className="icon-arrow-down"></i>
            </Button>
            {this.state.modalCategoryPromo == true ? (
              <div
                className="promoCategory"
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: 200,
                  padding: 10,
                  zIndex: 999999,
                  position: "relative",
                  borderColor: "#777777"
                }}
              >
                all{" "}
                <input
                  className="inputPromoCat"
                  type="checkbox"
                  style={{ right: 30, position: "absolute" }}
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="3">
            <p>Promo City: </p>
          </Col>
          <Col md="4">
            <Button
              onClick={this.promoCity}
              style={{
                width: 200,
                backgroundColor: "white",
                borderColor: "#777777"
              }}
            >
              choose{"       "}
              <i className="icon-arrow-down"></i>
            </Button>
            {this.state.modalCityPromo == true ? (
              <div
                className="promoCategory"
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: 200,
                  padding: 10,
                  borderColor: "#777777"
                }}
              >
                all{" "}
                <input
                  className="inputPromoCat"
                  type="checkbox"
                  style={{ right: 30, position: "absolute" }}
                />
              </div>
            ) : null}
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="3">
            <p>numbers of uses : </p>
          </Col>
          <Col md="6">
            <input
              placeholder="enter code amount"
              type="text"
              style={{
                width: "100%",
                borderRadius: 5,
                borderColor: "#777777",
                borderWidth: 1,
                padding: 7
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 12 }}>
          <Col md="3">
            <p>description : </p>
          </Col>
          <Col md="8">
            <textarea
              rows="5"
              cols="20"
              type="text"
              placeholder="PromoCode description"
              style={{
                width: "100%",
                marginTop: 12,
                borderColor: "#777777",
                borderWidth: 1,
                borderRadius: 5,
                paddingLeft: 15,
                paddingTop: 10,
                resize: "none"
              }}
            />
          </Col>
        </Row>
      </ModalBody>
    );
  }
}

export default PromoCode;
