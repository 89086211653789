import React from 'react'

class ReverseReactivePaginator extends React.Component {
    constructor(props){
        super(props);
    }
    toButtom(){
        let node = document.querySelector(`#reverse-reactive-paginator${this.props.id}`);
        node.scrollBy(0, node.scrollHeight);
    }
    toLastPosition(){
        let node = document.querySelector(`#reverse-reactive-paginator${this.props.id}`);
        node.scrollTop += 20;
    }
    paginatorUpdateContent(){
        let node = document.querySelector(`#reverse-reactive-paginator${this.props.id}`);
        if (node.scrollTop == 0 && this.props.disabled == false) {
            this.props.updateContent();
        }
    }
    render(){
        return (
            <div className='chat-box_messages'
                 id={`reverse-reactive-paginator${this.props.id}`}
                 onScroll={()=>this.paginatorUpdateContent()}
            >
            {this.props.children}
            </div>
        )
    }
}
export default ReverseReactivePaginator;
