import React from 'react'
import { FModal, TableBody } from '../..'
import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TH from '../../Table/TH';
import TR from '../../Table/TR';
import TD from '../../Table/TD';

class ListCategories extends React.Component{
  render(){
    const content=(
    <Table>
      <TableHeader>
        <TH>Id</TH>
        <TH>Category Name</TH>
      </TableHeader>
      <TableBody>
        {this.props.categories?this.props.categories.map((item,index)=>(
          <TR key={index}>
            <TD>{item.id}</TD>
            <TD>{item.enName}</TD>
          </TR>
        )):null}
      </TableBody>
    </Table>
    );
    return(
      <FModal state={this.props.show}
        title='Categories'
        onClose={()=>this.props.closeHandler()}
        content={content}
      show='none' />
    )
  }
}

export default ListCategories;
