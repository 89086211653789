import React from 'react'
import {Badge} from 'reactstrap'
class StatusBadge extends React.Component{
  render(){
    return(
      <Badge className='shadow'
        color={
          this.props.colorMapper==1 ? 'warning'
          :this.props.colorMapper==2?'success'
          :this.props.colorMapper==3?'primary'
          :this.props.colorMapper==4?'dark'
          :'danger'
        }
        style={this.props.colorMapper==30?{backgroundColor:'#7D67B5'}:null}
      >
        {
            this.props.children
        }
      </Badge>
      )
    }
  }
  export default StatusBadge;
