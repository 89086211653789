import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap'
import { validatePhoneNumber } from './../../../core/helper'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitTimeOrder:'',
      selectedTime:'Choose',
      selectedRedirectedFixer:'Choose',
      redirectedFixerId:'',
      redirectedFixers:[],
      availablePeriods:[],
    }
  }
  componentDidMount(){
    console.log('order', this.props.order);
    this.getRedirectedFixers()
  }
  getRedirectedFixers(){
    Services.getRedirectedFixers(this.props.order.orderId, (res)=>{
      let arr = []
      if (res.data.redirectedFixers.length!=0) {
        arr = res.data.redirectedFixers
        let obj = { id: 'Choose', name: 'Choose', arName:'Choose' }
        arr.unshift(obj)
      }
      this.setState({redirectedFixers:arr},()=>this.handleAvaiablePeriods(this.props.order.period.id))
    })
  }
  handleRedirectionFixer(item){
    if (item.id == 'Choose') {
      this.setState({selectedRedirectedFixer:item.arName, redirectedFixerId:''},()=>console.log(this.state.redirectedFixerId))
    }else {
      this.setState({selectedRedirectedFixer:item.arName, redirectedFixerId:item.id},()=>console.log(this.state.redirectedFixerId))
    }
  }

  handleAvaiablePeriods(id){
    let arr = []
    switch (id) {
      case 1:
      arr.push('09:00 AM','09:30 AM','10:00 AM','10:30 AM')
      this.setState({availablePeriods: arr})
      break
      case 2:
      arr.push('11:00 AM','11:30 AM','12:00 PM','12:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 3:
      arr.push('01:00 PM','01:30 PM','02:00 PM','02:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 4:
      arr.push('03:00 PM','03:30 PM','04:00 PM','04:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 5:
      arr.push('05:00 PM','05:30 PM','06:00 PM','06:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 6:
      arr.push('07:00 PM','07:30 PM','08:00 PM','08:30 PM')
      this.setState({availablePeriods: arr})
      break
      default:
    }
    // this.getAgents()
  }

  handleChooseTimeMenu(item){
    console.log(item);
    let local = item.split(' ')[1]
    console.log(local);
    let visitTime = ''
    if (local == 'PM') {
      let hour = item.split(':')[0]
      let minWithLocal = item.split(':')[1]
      if (hour != '12') {
        hour = parseInt(hour) + 12
        hour+= ':'+minWithLocal.split(' ')[0]
        visitTime = hour
      }else {
        visitTime = item.split(' ')[0]
      }
    }else {
      visitTime = item.split(' ')[0]
    }
    visitTime = visitTime + ':00'
    this.setState({selectedTime:item, visitTimeOrder:visitTime,})
  }

  confirmOrder(){
    // alert('say3')
    // return
    if (this.state.visitTimeOrder == '') {
      alert('Please, choose visit time')
    }else {
      let reqData = {
        visitTime: this.state.visitTimeOrder,
        fixerId: this.state.redirectedFixerId == '' ? this.props.order.fixer.id : this.state.redirectedFixerId
      }
      console.log(reqData);
      // return true
      this.setState({loading:true})
      Services.confirmOrder(reqData, this.props.order.orderId, (res)=>{
        if (res.message == 'fixer_is_unavailable') {
          this.setState({loading:false},()=>this.props.noAvaiableError())
        }else if (res.message == 'not_allowed_to_confirm_order'){
          this.setState({loading:false},()=>this.props.errorWorkContract())
        }else if(res.message == 'order_does_not_have_service_group'){
          alert('You can not confirm order because,order does not have service group.Please,update service group')
          this.setState({loading:false, mainModal:false, modalConfirm:false},()=>this.props.update())
        }else{
          this.setState({loading:false, mainModal:false, modalConfirm:false},()=>this.props.update())
        }
      })
    }
  }

  render(){
    return(
      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <div>
          <p style={{fontWeight:'bold'}}>To confirm this order please choose :</p>
          <Row>
            <Col md="3"><p>Fixer:</p></Col>
            <Col md="4"><p style={{fontWeight:'bold'}}>{this.props.order.fixer?this.props.order.fixer.enName:'N/A'}</p></Col>
          </Row>

          {this.state.redirectedFixers.length != 0?(
            <Row>
              <Col md="3"><p>Redirection Fixer: </p></Col>
              <Col md="4">
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedRedirectedFixer}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                    {this.state.redirectedFixers.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleRedirectionFixer(item)}>{item.arName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          ):null}

          <Row style={{marginTop:12}}>
            <Col md="3"><p>Time :</p></Col>
            <Col md="4">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedTime}</DropdownToggle>
                <DropdownMenu style={{width:'100%'}}>
                  {this.state.availablePeriods.map((item, index)=>(
                    <DropdownItem key={index} onClick={()=>this.handleChooseTimeMenu(item)}>{item}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>


        <LoadingSpinner fade loading={this.state.loading}/>
      </div>
    )
  }

}
