import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'

export default class GeneralDetails extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }
  }

  componentDidMount(){

  }

  

  render(){
    return(
      <div>
                    <div style={{marginTop:10}}> 
                         <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>En Name: <span style={{fontWeight:'bold'}}>{this.props.details.enName}</span></p>
                         <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Ar Name: <span style={{fontWeight:'bold'}}>{this.props.details.arName}</span></p>
                         <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Link: <span style={{fontWeight:'bold'}}>{this.props.details.link}</span></p>
                    </div>
                    <div style={{marginTop:10}}> 
                              <div style={{width:300}}>
                              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Image:</p>
                              <img src={this.props.details.image} width="80%" height={300} style={{marginLeft:10}}/> 
                              </div>
                    </div>
      </div>
    )
  }
}
