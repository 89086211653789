import React,{Fragment} from 'react'
import {LoadingSpinner, ChangePosition} from './../../components'
import {Container,CardHeader,Card,Row,Col,Button,CardBody,Table,Input,Label} from 'reactstrap'
import Services from './../../services/Services'
import {FModal} from './../../components'
import Switch from "react-switch";
import ErrorMessage from './../../components/errors/ErrorMessage'
import Paginator from './../../components/Pagination/V2/Paginator'
import { NoResult } from '../../components'
import {Global} from './../../../src/core/Global'
import Icon from './../../assets/img/noimage.jpg'
import AddBlog from './AddBlog'
import { buildQueryStringFromArray } from '../../core/helper'
import {stateToHTML} from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
export default class Blogs extends React.Component{
  constructor(props) {
    super(props)
    this.state={
        blogs:[],
        blog:'',
        articleModal:false,
        loading:false,
        previousPages:[],
        nextPages:[],
        currentPage:1,
        noResult:false,
        checked: false,
        confirmMessageModal:false,
        blogDetails:''
    }
  }

  componentDidMount(){
    this.getAllBlogs()
  }



  handleAddArticle(){
    this.setState({articleModal:!this.state.articleModal})
  }
  handleDetaisModal(blog){
    this.setState({blogModal:true },()=>this.getBlog(blog))
  }

  getAllBlogs(){
    this.setState({loading:true})
    Services.getAllBlogs(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        blogs:res.data.articles,
        noResult:!res.data.articles.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }

  getBlog(blog){
    this.setState({loading:true})
    Services.getBlog(blog.id,(res)=>{
      this.setState({loading:false,blog:res.data.article})
    })
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getAllBlogs())
  }


  switchBlog(){
    this.setState({loading:true})
    Services.changeStatusForBlog(this.state.blogDetails.id,(res)=>{
      this.setState({loading:false,confirmMessageModal:false},()=>this.getAllBlogs())
    })
  }


  handleConfirmationMessage(blog){
    this.setState({confirmMessageModal:!this.state.confirmMessageModal,blogDetails:blog})
  }

  convertCommentFromJSONToHTML(text){
    return stateToHTML(convertFromRaw(JSON.parse(text)))
  }


  render(){
    return(
        <div className="app align-items-center">
        <Container>
        <Card>
            {this.renderHeader()}
       <CardBody className='shadow' style={{backgroundColor:'white'}}>
       {this.state.noResult == true ? <NoResult/> :
        <>
          {this.renderBlogs()}
          <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
          </>}
          <FModal
          title={<div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}><span>{this.state.blog.arTitle}</span><span>{"     "}{this.state.blog.enTitle}</span></div>}
          content={
          <div>
          <img src={this.state.blog.image} style={{width:'100%',marginBottom:20}}/>
                    <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}> English Description:
                      <span style={{fontWeight:'bold'}}>{' '}{this.state.blog.enDescription?this.state.blog.enDescription:'N/A'}
                        </span>
                      </span>
                    </p>
                    <hr style={{color:'#eee'}}/>
                    <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginRight:6}}>Arabic Description:
                      <span style={{fontWeight:'bold'}}>{' '}{this.state.blog.arDescription?this.state.blog.arDescription:'N/A'}
                        </span>
                      </span>
                    </p>
                     <hr style={{color:'#eee'}}/>
                     <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}>English Content: </span>
                    </p>
                    <div style={{direction:'ltr',textAlign:'left'}} dangerouslySetInnerHTML={{ __html:this.state.blog.enContent?this.convertCommentFromJSONToHTML(this.state.blog.enContent):'N/A'}}></div>
                    <hr style={{color:'#eee'}}/>
                    <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginRight:6}}> Arabic Content: </span>
                    </p>
                    <div style={{direction:'rtl',textAlign:'right'}} dangerouslySetInnerHTML={{ __html:this.state.blog.arContent?this.convertCommentFromJSONToHTML(this.state.blog.arContent):'N/A'}}></div>
         </div>
          }
          state={this.state.blogModal}
          onClose={()=>this.setState({blogModal:false})}
          show="none"
        />

        <FModal
          title={"Add Article"}
          content={
         <AddBlog
          ref={ref => this.addBlogRef = ref}
          update={()=>this.setState({articleModal:false},()=>this.getAllBlogs())}
         />
          }
          state={this.state.articleModal}
          onClose={()=>this.handleAddArticle()}
          onSubmit={()=>this.addBlogRef.addBlog()}
        />

        <FModal
          title={"Confirmation Message"}
          content={
        <div style={{marginLeft:20}}>
          {this.state.blogDetails.activeStatus == 1 ? <p>Are you sure to turn off the article</p> : <p>Are you sure to turn on the article</p> }
        </div>
          }
          state={this.state.confirmMessageModal}
          onClose={()=>this.handleConfirmationMessage(this.state.blog)}
          onSubmit={()=>this.switchBlog()}
        />
      <LoadingSpinner loading={this.state.loading}/>
      </CardBody>
      </Card>
      </Container>

      </div>
    )
  }

  renderHeader(){
    return (
      <CardHeader className='shadow'>
        <Row>
          <Col md="9" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Articles</span>
          </Col>
          <Col md="3" style={{display:'flex',alignItems:'right'}}>
          <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',width:'100%'}} onClick={()=>this.handleAddArticle()}><i className='fa fa-plus-square' style={{marginRight:8}}/>Add Article</Button>
        </Col>
        </Row>
      </CardHeader>
    )
  }

  renderBlogs(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>Ar Title</th>
            <th style={{textAlign:'center'}}>Description</th>
            <th style={{textAlign:'center'}}>Date And Time</th>
            <th style={{textAlign:'center'}}>Status</th>
          </tr>
        </thead>
        <tbody>
          {this.state.blogs.map((blog,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center',fontWeight:'bold'}}>{blog.id}</td>
              <td style={{textAlign:'center'}}>{blog.arTitle}</td>
              <td style={{textAlign:'center'}}>
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.handleDetaisModal(blog)}>View Details</span>
              </td>
              <td style={{textAlign:'center'}}>{blog.updatedAt}</td>
              <td style={{textAlign:'center'}}><Switch onChange={()=>this.handleConfirmationMessage(blog)} checked={blog.activeStatus} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }



}
