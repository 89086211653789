import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class FixerRevenueService {

  static get(fixerId, callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/fixers/${fixerId}/getRevenue`).then(callBack)
  }

  static confirm(fwcId, data, callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/work-contracts-reservations/${fwcId}/confirm`,data).then(callBack)
  }

  static closeReceipt(fwcId, conditions, callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/approved-reservations/${fwcId}/close`,{}).then(callBack)
  }


}
