import { buildQueryStringFromArray } from '../../core/helper'
import { Global } from './../../core/Global'
import Adaptor from './../Adaptor'


export default class QuotationService {


  static getQuotations(page, searchTerm, data, callBack) {
    let api = ''
    if (searchTerm != '') {
      api = `${Global.baseURL}admin-panel/quotations?page=${page}&searchTerm=@${searchTerm}`
    } else {
      api = `${Global.baseURL}admin-panel/quotations?page=${page}`
    }
    api += buildQueryStringFromArray('statuses', data.statuses)
    Adaptor.get(api).then(callBack)
  }

  static getStatuses(type, callBack) {
    Adaptor.get(`${Global.baseURL}admin-panel/statuses?statusType=${type}`).then(callBack)
  }

  static changeStatus(data, quotationId, callBack) {
    Adaptor.patch(`${Global.baseURL}admin-panel/quotations/${quotationId}/status`, data).then(callBack)
  }

  static getHistory(id, callBack) {
    Adaptor.get(`${Global.baseURL}admin-panel/quotations/${id}/history`).then(callBack)
  }

}
