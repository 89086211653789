import React from'react'
import { nav,Row,Pagination,PaginationLink,PaginationItem} from 'reactstrap'

class Paginator extends React.Component{
  constructor(props){
    super(props)
    this.state={
      pages:[],
      activePage:1,
    }
    this.styles={backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}
  }

  static getDerivedStateFromProps(props,state){
    let pages=[];
    props.prevPages.reverse().map( e => { pages.push(e) })
    pages.push(state.activePage)
    props.nextPages.map( e => { pages.push(e) })
    console.log(pages)
    return state.pages=pages;
  }
  prevPage(){
    this.setState((prevState)=>({activePage:prevState.activePage-1}),()=>this.props.notify(this.state.activePage))
  }
  nextPage(){
    this.setState((prevState)=>({activePage:prevState.activePage+1}),()=>this.props.notify(this.state.activePage))
  }
  setActivePage(page){
    this.setState({activePage:page},()=>this.props.notify(this.state.activePage))
  }
  getActivePage(){
    return this.state.activePage
  }

  reset(){
    this.setState({activePage:1})
  }

  render(){
    return(
      <nav>
          <Row className='pull-right'>
              <Pagination style={{justifyContent:'flex-end'}}>
                  <PaginationItem>
                      <PaginationLink previous tag="button"
                                      onClick={()=>this.prevPage()}
                                      disabled={this.state.activePage === this.state.pages[0]}
                                      />
                  </PaginationItem>
                  {this.state.pages.map((link,index)=>(
                    <PaginationItem key={index} active={link === this.state.activePage}>
                        <PaginationLink tag="button" onClick={()=>this.setActivePage(link)} style={link === this.state.activePage?this.styles:null}>{link}</PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem>
                      <PaginationLink next tag="button"
                                          style={{color:'#4dbd74'}}
                                          onClick={()=>this.nextPage()}
                                          disabled={this.state.activePage === this.state.pages.reverse()[0] }
                                          />
                  </PaginationItem>
              </Pagination>
          </Row>
      </nav>
    )
  }
}
export default Paginator;
