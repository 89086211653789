import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label,Input,InputGroup ,Table} from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import Switch from "react-switch";
import {NoResult} from './../../components'
import PermissionsService from './../../services/permissions/PermissionsService'

export default class ActionsRole extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedPermissionIds:'',
      status:'',
      permissions:[],
      permission:[]
    }

  }
  componentDidMount(){
    console.log(this.props.permission)
    this.getPermissions()
  }


  getPermission(){
    this.setState({loading:true})
    PermissionsService.getPermission(this.props.roleId,(res)=>{
      this.setState({permission:res.data,loading:false})
    })
  }

  addAssignPermissionToRole(permissionId){
    this.setState({loading:true})
    PermissionsService.addAssignPermissionToRole(this.props.roleId,permissionId,(res)=>{
      if(res.message == 'forbidden'){
        alert('You Not Authorized To Take That Action');
      }
      this.setState({loading:false},()=>this.getPermissions())
    })
  }

  removeAssignPermissionToRole(permissionId){
    this.setState({loading:true})
    PermissionsService.removeAssignPermissionToRole(this.props.roleId,permissionId,(res)=>{
      this.setState({loading:false},()=>this.getPermissions())
    })
  }

  switchAssignRolesToAgent(item,index){
    if(this.props.from  == "admins"){
      alert('Please, you can not change status of permission ')
    }else{
      if(!this.state.permission.some(function(permission2){return item.id === permission2.id})){
        this.addAssignPermissionToRole(item.id)
      }else if(this.state.permission.some(function(permission2){return item.id === permission2.id})){
        this.removeAssignPermissionToRole(item.id)
      }
    }
  }

  getPermissions(){
    this.setState({loading:true})
    PermissionsService.getPermissions((res)=>{
      this.setState({permissions:res.data,loading:false},()=>this.getPermission())
    })
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center'}}>Actions</th>
              <th style={{textAlign:'center'}}>Status</th>
            </tr>
          </thead>
          <tbody>
            {this.state.permissions.map((permission,index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>{permission.displayName}</td>
                <td style={{textAlign:'center'}}>
                  <Switch
                    onChange={()=>this.switchAssignRolesToAgent(permission , index)}
                    checked={this.state.permission.some(function(permission2){return permission.id === permission2.id})}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
