import React from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput} from 'reactstrap';
import Services from './../../services/Services'
import LoadingOverlay from 'react-loading-overlay'
import Paginator from './../../components/Pagination/V2/Paginator'
import {FModal} from './../../components'

class ChatReplay extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      snippits:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      noResult:false,
      loading:false,
      descriptionModal:false,
      descriptionData:'',
      description:'',
      title:''
    }
  }

  componentDidMount(){
    this.getChatSnippits()
  }




  changePage(page){
    this.setState({currentPage:page},()=>this.getChatSnippits())
  }

  handleCreateReplay(){
      this.setState({modalReplay: !this.state.modalReplay,title:'',description:''})
  }

  handleDescription(descriptionData){
    console.log(descriptionData)
    this.setState({descriptionModal : !this.state.descriptionModal , descriptionData : descriptionData})
  }

  handleChangeInputs(e,type){
   if(type=='description'){
     this.setState({description:e.target.value})
   }else if(type == 'title'){
    this.setState({title:e.target.value})

   }
  }


  getChatSnippits(){
    this.setState({loading:true})
    Services.getChatSnippits(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        snippits:res.data.snippits,
        noResult:!res.data.snippits.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }

  addSnippits(){
    this.setState({loading:true})
    let data = {
      body:this.state.description,
      code:this.state.title
    }
    Services.addSnippits(data,(res)=>{
      if(res.message == 'The given data was invalid.'){
        alert(res.errors.code[0])
        this.setState({loading:false})
      }else{
        this.setState({loading:false,description:'',title:'',modalReplay:false},()=>this.getChatSnippits())
      }
    })
  }

  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            {this.renderHeader()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderReplaies()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>

          <FModal
            title='Create Replay'
            content={
              <div>
                {this.renderCreateReplay()}
              </div>
            }
            state={this.state.modalReplay}
            onClose={()=>this.handleCreateReplay()}
            onSubmit={()=>this.addSnippits()}
          />

         <FModal
            title='Description'
            content={
              <div>
                {this.renderDescription()}
              </div>
            }
            state={this.state.descriptionModal}
            onClose={()=>this.handleDescription(this.state.descriptionData)}
            show={"none"}
          />

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }

  renderDescription(){
    return(
      <div style={{marginLeft:20}}>
        <p>- {this.state.descriptionData}</p>
      </div>
    )
  }


  renderHeader(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>Chat Replaies</span>
          </Col>

          <Col md="4">
            <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',marginLeft:'auto'}} onClick={()=>this.handleCreateReplay()}><i className='fa fa-plus-square' style={{marginRight:8}}/>Create Replay</Button>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderReplaies(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Replay ID</th>
            <th style={{textAlign:'center'}}>Replay Title</th>
            <th style={{textAlign:'center'}}>Replay Description</th>
            <th style={{textAlign:'center'}}>Date And Time</th>
          </tr>
        </thead>
        <tbody>
          {this.state.snippits.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{item.id}</td>
              <td style={{textAlign:'center'}}>{item.code}</td>
              <td style={{textAlign:'center'}}><Button className="someSpace" style={{cursor:'pointer',backgroundColor:'transparent',borderColor:'#262626',fontSize:12,fontWeight:'bold',borderRadius:0,borderWidth:1,color:'#262626'}} onClick={()=>this.handleDescription(item.body)}>{item.body.slice(0, 15) + "  " +"..."}</Button></td>
              <td style={{textAlign:'center'}}>{item.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  renderCreateReplay(){
      return(
          <div style={{width:'70%',marginRight:'auto',marginLeft:'auto'}}>
              <Row>
              <Col md="12">
            <Input
              type="text"
              value={this.state.title}
              onChange={(e)=>this.handleChangeInputs(e, 'title')}
              placeholder="Enter Title"
              className="addTitle"
              maxLength="150"
              // style={{resize:'none',height:120}}
            />
          </Col>
          <Col md="12">
            <Input
              type="textarea"
              value={this.state.description}
              onChange={(e)=>this.handleChangeInputs(e, 'description')}
              placeholder="Enter description"
              className="addDescription"
              style={{resize:'none',height:120,marginTop:10}}
            />
          </Col>
        </Row>
          </div>
      )
  }

}

export default ChatReplay;
