import React,{Fragment} from 'react'
import {Container,CardHeader,Card,Row,Col,Button} from 'reactstrap'
import Services from './../../services/Services'
import {FModal , AddPost ,GeneralDetails,KeywordDetails,PromoCodeDetails,LoadingSpinner, PromotedServiceGroupDetails,FeaturedServiceGroupDetails,OfferDetails} from './../../components'
import ListPosts from './ListPosts'
import SimpleReactLightbox from "simple-react-lightbox";

export default class PostDetails extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      postModal:false,
      details:[],
      loading:false,
      resources:[]
    }
  }
  componentDidMount(){
    this.getSectionDetails()
  }


  getSectionDetails(){
    this.setState({loading:!this.state.loading})
    Services.getSectionDetails(this.props.singlePost.id,(res)=>{
      this.setState({details:res.data.section.details,resources:res.data.section.resources,loading:false})
    })
  }


  render(){
    return(
      <div style={{paddingLeft:20}}>
        {this.props.singlePost.sectionTypeId == 1 ? (
          <PromotedServiceGroupDetails details={this.state.details} resources={this.state.resources}/>
        ):this.props.singlePost.sectionTypeId == 2 ? (
          <FeaturedServiceGroupDetails details={this.state.details} resources={this.state.resources}/>
        ):this.props.singlePost.sectionTypeId == 3 ? (
          <PromoCodeDetails details={this.state.details} resources={this.state.resources}/>
        ):this.props.singlePost.sectionTypeId == 4 ? (
          <OfferDetails details={this.state.details} resources={this.state.resources}/>
        ):this.props.singlePost.sectionTypeId == 5 ? (
          <GeneralDetails details={this.state.details}/>
        ):this.props.singlePost.sectionTypeId == 6 ? (
          <SimpleReactLightbox>
            <KeywordDetails details={this.state.details} resources={this.state.resources}/>
          </SimpleReactLightbox>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
