import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import Switch from "react-switch";
import firebase from 'firebase/app'
import 'firebase/database'
import {FModal , ActionsRole,AssignRoles} from './../../components'
import PermissionsService from './../../services/permissions/PermissionsService'

class Admins extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone:'',
      enName:'',
      arName:'',
      email:'',
      userTypeId:3,
      gender:'',
      genderTitle:'Choose',
      loading:false,
      modalAdmin:false,
      trelloBords:[],
      boardId:[],
      admins:[],
      checked: false,
      person:'',
      userId:'',
      modalPassword:false,
      userIdToBeBlockedOrUnBlocked:'',
      indexOfItemToBeBlockedOrUnBlocked:'',
      password:'',
      actionsRoleModal:false,
      roleId:'',
      assignRolesModal:false,
      roles:[],
      permissions:[],
      permission:[]
    }

  }

  componentDidMount(){
    // this.getArea() //check if is login
    window.scrollTo(0, 0)
    this.getAllAdmins()
  }

  getArea(){
    Services.getArea(1, (res)=>{
      this.setState({loading:false})
    })
  }
  handleChangeInputs = (e, handle) => {
    const re = /^[0-9\b]+$/;
    switch (handle) {
      case 'arName':
      this.setState({arName: e.target.value})
      break
      case 'enName':
      this.setState({enName: e.target.value})
      break
      case 'email':
      this.setState({email: e.target.value})
      break
      case 'password':
      this.setState({password: e.target.value})
      break
      case 'phone':
      if (e.target.value === '' || re.test(e.target.value))
        this.setState({phone: e.target.value})
      break
      default:
    }
  }
  handleGender(item, title){
      this.setState({gender:item, genderTitle:title})
  }

  handleAddAdmin(){
    if (this.state.modalAdmin) {
      this.setState({modalAdmin: !this.state.modalAdmin,phone:'',enName:'',arName:'',email:'',})
    }else {
      this.getTrelloBords()
    }

  }

  getTrelloBords(){
    this.setState({loading:true})
    Services.getTrelloBords((res)=>{
      this.setState({trelloBords:res.data.boards,loading:false,modalAdmin: true})
    })
  }

  submitAddAdmin(){
    let t = this.state.email
    t+='@fixawy.net'
    // this.setState({email:t})
    if (!this.state.enName.replace(/\s/g, '').length) {
      alert('Please, enter name in english')
    }else if (!this.state.arName.replace(/\s/g, '').length || !this.state.arName.match(/[\u0600-\u06FF]/)) {
      alert('Please, enter name in arName')
    }else if (!this.state.email.replace(/\s/g, '').length) {
      alert('Please, enter email')
    }else if ((t.match(/@fixawy/g) || []).length == 2) {
      alert('Please, enter email without @fixawy.net')
    }else if (!/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/.test(t.toLowerCase())) {
      alert('Please, enter valid email')
    }else if (!this.state.phone.replace(/\s/g, '').length) {
      alert('Please, enter phone')
    }else if (!this.state.phone.startsWith("01") || this.state.phone.match(/^[0-9]+$/) == null || this.state.phone.length !== 11 || this.state.phone.match(/[\u0600-\u06FF]/)) {
      alert('Please, enter valid phone')
    }else {
      let data = {
        phone: this.state.phone,
        name: this.state.enName,
        arName: this.state.arName,
        email: t,
        userTypeId: 3,
      }
      if (this.state.gender!='') {
        data.gender = this.state.gender
      }
      if (this.state.boardId.length!=0) {
        data.boards = this.state.boardId
      }
      console.log(data)
      this.setState({loading:true})
      Services.addAdmin(data,(res)=>{
        if (res.message == 'admin_exists') {
          alert('phone is exist')
        }else if (res.message == 'email_taken') {
          alert('Mail is already registered, please insert a new mail or login')
        }else if (res.message == 'trello_member_not_found') {
          alert('email doesn\'t related to trello account')
        }else if (res.message == 'success') {
          alert('new admin created successfully')
          this.setState({loading:false},()=>this.handleAddAdmin())
        }else {
          alert('email doesn\'t related to trello account')
        }
        this.setState({loading:false})
      })
    }
  }

  getSelectValues(event){
    let opts = [], opt;

    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];

      if (opt.selected) {
        opts.push(parseInt(opt.value));
      }
    }
    console.log(opts);
    this.setState({boardId: opts});
  }


  getAllAdmins(){
    this.setState({loading:true})
    Services.getAllAdmins((res)=>{
      this.setState({admins : res.data.admins , loading:false},()=>this.getArea())
    })
  }

  triggerBlockAdmin(user){
      if(user.isBlocked){
        this.unBlockedAdmin(user.id);
        this.getAllAdmins()
      }else{
        this.blockedAdmin(user.id);
        this.getAllAdmins()
      }
  }


  blockedAdmin(userId){
    console.log('call')
    console.log('call')

    Services.block(userId,21,(res)=>{
      if(res.message == 'forbidden') alert('You Are Not Authorized To Take That Action')
      else this.getAllAdmins()
    })
  }


  unBlockedAdmin(userId){
    Services.unBlock(userId,(res)=>{
      if(res.message == 'forbidden') alert('You Are Not Authorized To Take That Action')
      else this.getAllAdmins()
    })
  }

  handleDetaisModal(item){
    console.log(item)
    this.setState({actionsRoleModal:true,roleId:item.id})
  }

   handleRolesModal(item){
     console.log(item)
    this.setState({assignRolesModal:true,userId:item.id,selectedRoles:item.roles})
   }

  render() {
    return (
      <div className="app align-items-center">
        <Container>

          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderAdmins()}
                {/* {this.renderPagination()} */}
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>


          <Modal fade={false} isOpen={this.state.modalAdmin} toggle={()=>this.handleAddAdmin()} className={this.props.className}>
            <ModalHeader toggle={()=>this.handleAddAdmin()}>Add Admin</ModalHeader>
            {this.renderAddAdmin()}
            <ModalFooter>
              <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.handleAddAdmin()}>Cancel</Button>
              <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.submitAddAdmin()}>Add</Button>
            </ModalFooter>
          </Modal>

          <FModal
          title={"Actions Role"}
          content={
              <ActionsRole
              ref={ref=>this.actionsRoleRef=ref}
              roleId={this.state.roleId}
              update={()=>this.setState({actionsRoleModal:false})}
              from="admins"
              />
          }
          state={this.state.actionsRoleModal}
          onClose={()=>this.setState({actionsRoleModal:false})}
          show={"none"}
        />


        <FModal
          title={"Assign Roles"}
          content={
              <AssignRoles
              ref={ref=>this.assignRolesRef=ref}
              roles={this.state.roles}
              userId={this.state.userId}
              selectedRoles={this.state.selectedRoles}
              update={()=>this.setState({assignRolesModal:false},()=>this.getAllAdmins())}
              />
          }
          state={this.state.assignRolesModal}
          onClose={()=>this.setState({assignRolesModal:false})}
          onSubmit={()=>this.assignRolesRef.assignRolesToAgents()}
        />


          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Admins</span>
          </Col>

          <Col md="4">
            <Button className='actionsBtn' style={{marginBottom:'1%', display:'block',marginLeft:'auto'}} onClick={()=>this.handleAddAdmin()}><i className='fa fa-plus-square' style={{marginRight:8}}/>Add New Admin</Button>
            {/* <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.getFixers()} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e)}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By id, name and phone.."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
            </InputGroup> */}
          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderAdmins(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Admin ID</th>
            <th style={{textAlign:'center'}}>Admin Name</th>
            <th style={{textAlign:'center'}}>Admin Type</th>
            <th style={{textAlign:'center'}}>Role Name</th>
            <th style={{textAlign:'center'}}>Assign Roles</th>
            <th style={{textAlign:'center'}}>Phone Number</th>
            <th style={{textAlign:'center'}}>Status</th>

          </tr>
        </thead>
        <tbody>
          {this.state.admins.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{item.id}</td>
              <td style={{textAlign:'center'}}>{item.enName}</td>
              <td style={{textAlign:'center'}}>{item.type.type}</td>
              <td style={{textAlign:'center',maxWidth:175}}>
              {item.roles.length == 0 ? 'N/A' : (
                  item.roles.map((item,index)=>(
                  <Button key={index} className="someSpace" style={{cursor:'pointer',backgroundColor:'transparent',borderColor:'#262626',fontSize:12,fontWeight:'bold',borderRadius:0,borderWidth:1,color:'#262626'}} onClick={()=>this.handleDetaisModal(item)}>{item.displayName}</Button>
                  ))
                )}
              </td>
              <td style={{textAlign:'center'}}>
              <Button key={index} className="someSpace" style={{cursor:'pointer',backgroundColor:'transparent',borderColor:'#262626',fontSize:12,fontWeight:'bold',borderRadius:0,borderWidth:1,color:'#262626'}} onClick={()=>this.handleRolesModal(item)}>Assign Roles</Button>
              </td>
              <td style={{textAlign:'center'}}>{item.phone}</td>
              <td style={{textAlign:'center'}}>
                <Switch onChange={()=>this.triggerBlockAdmin(item)} checked={!item.isBlocked} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }



  renderAddAdmin(){
    return(
      <ModalBody>
        <Alert color="danger">
          Before proceeding with the steps, please make sure the new admin has a Trello account associated with the e-mail address submitted for him.
        </Alert>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>English Name: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter name in english.."
              value={this.state.enName}
              onChange={(e)=>this.handleChangeInputs(e, 'enName')}
              maxLength={150}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Arabic Name: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter name in arabic.."
              value={this.state.arName}
              onChange={(e)=>this.handleChangeInputs(e, 'arName')}
              maxLength={150}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>E-Mail: </span></Col>
          <Col md="3">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter e-mail.."
              value={this.state.email}
              onChange={(e)=>this.handleChangeInputs(e, 'email')}
            />
          </Col>
          <Col md="2" style={{margin:'auto -2.5%'}}>
            <h5 >@fixawy.net</h5>
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Phone: </span></Col>
          <Col md="5">
            <textarea
              type="number"
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter phone.."
              value={this.state.phone}
              onChange={(e)=>this.handleChangeInputs(e, 'phone')}
              maxLength={11}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Gender: </span></Col>
          <Col md="5">
            <UncontrolledDropdown  className="mb-3">
              <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.genderTitle}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}}>
                <DropdownItem onClick={()=>this.handleGender('', 'Choose')}>Choose</DropdownItem>
                <DropdownItem onClick={()=>this.handleGender('male', 'Male')}>Male</DropdownItem>
                <DropdownItem onClick={()=>this.handleGender('female', 'Female')}>Female</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Trello Boards: </span></Col>
          <Col md="5">
            <FormGroup>
              <Label for="multiple">Assign to one board or more</Label>
              <CustomInput type="select" id="multiple" multiple onChange={(event) => this.getSelectValues(event)}>
                {this.state.trelloBords.map((item,index)=>(
                  <option key={index} value={item.id}>{item.name}</option>
                ))}
              </CustomInput>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
    )
  }


  modalPassword(){
    return (
      <Modal fade={false} isOpen={this.state.modalPassword} toggle={()=>this.closePasswordModal()} className={this.props.className}>
        <ModalHeader toggle={()=>this.closePasswordModal()}>Blocked & UnBlocked</ModalHeader>
        <ModalBody>
        <Row>
      <Col md="2" style={{textAlign:'right',paddingTop:'.5%'}}>
        <Label>Password: </Label>
      </Col>
      <Col md="5">
        <Input
          value={this.state.password}
          onChange={(e)=>this.handleChangeInputs(e,'password')}
          type="password"
          placeholder="Enter password"
          autoComplete="current-password"
        />
      </Col>
      </Row>
      </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.closePasswordModal()}>Cancel</Button>
          <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.confirmAdminBlock()}>Submit</Button>
        </ModalFooter>
      </Modal>
    )
  }








}

export default Admins;
