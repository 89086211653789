import React,{Fragment} from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
// import LoadingSpinner from './../LoadingSpinner';
import Services from './../../services/Services';
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import { LoadingSpinner } from './../../components'

export default class Keywords extends React.Component{
  constructor(props){
    super(props)
    this.state={
      createdPackage:null,
      keywordId:[],
      selectedCategory:[],
      categoryId:[],
      keywords:[],
      suggestedKeywords:[],
      multiSelectKeywords:[],
      description:'',
      arDescription:'',
      titleKeyword:'',
      arTitleKeyword:'',
      pictures:[],
      loading:false
    }
  }

  componentDidMount(){
    this.getKeywords()
  }

  getKeywords(){
    this.setState({loading : !this.state.loading})
    Services.fetchKeywords((res)=>{
      let arr = res.data.keywords
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({suggestedKeywords:res.data.fixers, multiSelectKeywords:titles, loading:false})
    })
  }

  onSelectedChanged(selectedItems) {
    console.log(selectedItems);
    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({selectedKeyword:selectedItems, keywordId:arr},()=>console.log(arr))
  }

  handleChangeInputs(e,type){
    if(type == 'description'){
      this.setState({description : e.target.value})
    }else if(type == 'arDescription'){
      this.setState({arDescription : e.target.value})
    }else if(type == 'titleKeyword'){
      this.setState({titleKeyword : e.target.value})
    }else if(type == 'arTitleKeyword'){
      this.setState({arTitleKeyword : e.target.value})
    }
  }

  valideRequest(){
    let arRegex= /[\u0600-\u06FF]/;
    if (this.state.titleKeyword.trim() == '') {
      alert('Please, enter English offer title')
      return false
    }
    if (this.state.arTitleKeyword.trim() == '' || ! arRegex.test(this.state.arTitleKeyword) ) {
      alert('Please, enter Arabic offer title')
      return false
    }
    if (this.state.description.trim() == '') {
      alert('Please, enter English description')
      return false
    }
    if (this.state.arDescription.trim() == ''  || ! arRegex.test(this.state.arDescription) ) {
      alert('Please, enter Arabic description')
      return false
    }
    if (this.state.keywordId.length == 0) {
      alert('Please, choose atleast one keyword')
      return false
    }
    return true
  }

  addPost(){
    if (this.valideRequest()) {
      this.creatPackage()
    }
  }

  creatPackage(){
    this.setState({loading : !this.state.loading})
    let data = {enName:this.state.titleKeyword ,keywordIds:this.state.keywordId }
    Services.creatPackage(data,(res)=>{
      this.setState({createdPackage:res.data.keywordsPackage ,loading : !this.state.loading},()=>this.createPostFromPackage())
    })
  }

  createPostFromPackage(){
    let formData = new FormData();
    formData.append('type','keywords')
    formData.append('id',this.state.createdPackage.id)
    formData.append('additionalData[enName]',this.state.titleKeyword)
    formData.append('additionalData[arName]',this.state.arTitleKeyword)
    formData.append('additionalData[description]',this.state.description)
    formData.append('additionalData[arDescription]',this.state.arDescription)
    this.props.addPost(formData);
  }


  render(){
    return(
      <div>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Keywords :</Label>
          </Col>
          <Col md="4">
            {this.state.multiSelectKeywords.length!=0?(
              <MultiSelect
                items={this.state.multiSelectKeywords}
                selectedItems={this.state.selectedCategory}
                onChange={(item)=>this.onSelectedChanged(item)}
                withGrouping={true}
                showSelectedItems={false}
                showSelectAll={true}
              />
            ):null}
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Offer En : </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.titleKeyword}
              onChange={(e)=>this.handleChangeInputs(e, 'titleKeyword')}
              type="text"
              id="title"
              name="title"
              placeholder="English title.."
              maxLength={150}
            />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Offer Ar : </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.arTitleKeyword}
              onChange={(e)=>this.handleChangeInputs(e, 'arTitleKeyword')}
              type="text"
              id="title"
              name="title"
              placeholder="Arabic title.."
              maxLength={150}
            />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Description En : </Label>
          </Col>
          <Col md="6">
            <Input
              style={{height:150,resize:'none'}}
              value={this.state.description}
              onChange={(e)=>this.handleChangeInputs(e, 'description')}
              type="textarea"
              id="description"
              name="description"
              placeholder="English description.."
              maxLength={5000}
            />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Description Ar: </Label>
          </Col>
          <Col md="6">
            <Input
              style={{height:150,resize:'none'}}
              value={this.state.arDescription}
              onChange={(e)=>this.handleChangeInputs(e, 'arDescription')}
              type="textarea"
              id="description"
              name="description"
              placeholder="Arabic description.."
              maxLength={5000}
            />
          </Col>
        </Row>
        <Row>
        </Row>
        <LoadingSpinner loading={this.state.loading}/>

      </div>
    )
  }
}
