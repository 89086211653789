import React,{Fragment} from 'react'
import RDatePicker from "react-datepicker"
class DatePicker extends React.Component {
    render(){
        return(
            <Fragment>
                <div className='date'>
                    <RDatePicker
                    selected={this.props.date}
                    onChange={(date)=> this.props.handler(date) }
                    dateFormat="dd-MM-yyyy"
                    />
                </div>
            </Fragment>
        )
    }
}
export default DatePicker;
