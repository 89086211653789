import React,{Fragment} from 'react'
import {LoadingSpinner, ChangePosition} from './../../components'
import {Container,CardHeader,Card,Row,Col,Button,CardBody,Table,Input,Label,InputGroup,UncontrolledTooltip} from 'reactstrap'
import Services from './../../services/Services'
import {FModal} from './../../components'
import Switch from "react-switch";
import ErrorMessage from './../../components/errors/ErrorMessage'
import Paginator from './../../components/Pagination/V2/Paginator'
import { NoResult } from '../../components'
import {Global} from './../../../src/core/Global'
import Icon from './../../assets/img/noimage.jpg'
import { EditorState , convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class AddBlog extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      imagePreview:'',
      arTitle:'',
      enTitle:'',
      arDescription:'',
      enDescription:'',
      arContent:EditorState.createEmpty(),
      enContent:EditorState.createEmpty(),
      image:'',
      loading:false
    }
  }

  componentDidMount(){
  }


  fakeUpload(){
    document.getElementById('promotedKey__imageUploader').click();
  }
  uploadImage(e){
    // upload the image
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        image: file,
        imagePreview: reader.result
      });
    }
    reader.readAsDataURL(file)
  }

  clearImage(){
    this.setState({file:null,imagePreview:null})
  }

  validateBlog(){
    if(this.state.arTitle.trim() == ""){
      alert('Please,add ar title')
      return false
    }
    if(this.state.arDescription.trim() == ""){
      alert('Please,add ar description')
      return false
    }
    if(this.state.arContent == EditorState.createEmpty() ){
      alert('Please,add ar content')
      return false
    }
    if(this.state.image == ""){
      alert('Please,add image')
      return false
    }

    if (!this.validateEnglishDetails()) {
      alert('Please , Fill All Fields In English Details')
      return false
    }
    return true
  }

  validateEnglishDetails(){
    if (this.state.enTitle.trim() || this.state.enDescription.trim() || (this.state.enContent != EditorState.createEmpty())) {
      return (this.state.enTitle.trim() && this.state.enDescription.trim() && (this.state.enContent != EditorState.createEmpty()) );
    }
    return true
  }

  addBlog(){
    if(!this.validateBlog()){
      return false
    }
    this.setState({loading:true})
    let data = new FormData()
    data.append('enTitle',this.state.enTitle)
    data.append('arTitle',this.state.arTitle)
    data.append('enDescription',this.state.enDescription)
    data.append('arDescription',this.state.arDescription)
    data.append('enContent',JSON.stringify(convertToRaw(this.state.enContent.getCurrentContent())))
    data.append('arContent',JSON.stringify(convertToRaw(this.state.arContent.getCurrentContent())))
    data.append('image',this.state.image)
    console.log(data)
    Services.addBlog(data,(res)=>{
      if(res.message == 'forbidden'){
        alert('You Are Not Authorized To Take That Action')
      }
      this.setState({loading:false},()=>this.props.update())
    })
  }

  render(){
    return(
        <div>
       {this.renderAddBlog()}
       <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

  renderAddBlog(){
    return (
      <div style={{marginLeft:20}}>
             <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>Arabic Title</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input style={{padding:'20px'}} maxLength='150' placeholder='Arabic Title' value={this.state.arTitle} onChange={(e)=>this.setState({arTitle:e.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>English Title</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input style={{padding:'20px'}} maxLength='150' placeholder='English Title' value={this.state.enTitle} onChange={(e)=>this.setState({enTitle:e.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>Arabic Description</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input type="textarea" style={{padding:'10px',resize:'none',height:150}} maxLength='1000' placeholder='Arabic Description' value={this.state.arDescription} onChange={(e)=>this.setState({arDescription:e.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>English Description</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <Input type="textarea" style={{padding:'10px',resize:'none',height:150}} maxLength='1000' placeholder='English Description' value={this.state.enDescription} onChange={(e)=>this.setState({enDescription:e.target.value})}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>Arabic Content</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                  <div style={{border:'2px solid black'}}>
                    <Editor
                          editorState={this.state.arContent}
                          wrapperClassName="editor-wrapper-class"
                          editorClassName="editor-editor-class"
                          toolbarClassName="editor-toolbar-class"
                          onEditorStateChange={(editorState) => this.setState({arContent:editorState})}
                        />
                    {/* <Input type="textarea" style={{padding:'10px',resize:'none',height:150}} maxLength='10000' placeholder='Arabic Content' value={draftToHtml(convertToRaw(this.state.arContent.getCurrentContent()))} onChange={(e)=>this.setState({arContent:e.target.value})}/> */}
                  </div>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3'>
                <label style={{marginTop:10}}><strong>English Content</strong></label>
              </Col>
              <Col md='6' >
                <InputGroup style={{marginBottom:'20px'}}>
                <div style={{border:'2px solid black'}}>
                    <Editor
                          editorState={this.state.enContent}
                          wrapperClassName="editor-wrapper-class"
                          editorClassName="editor-editor-class"
                          toolbarClassName="editor-toolbar-class"
                          onEditorStateChange={(editorState) => this.setState({enContent:editorState})}
                        />
                </div>
                  {/* <Input type="textarea" style={{padding:'10px',resize:'none',height:150}} maxLength='10000' placeholder='English Content' value={this.state.enContent} onChange={(e)=>this.setState({enContent:e.target.value})}/> */}
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md='3' >
                <label style={{marginTop:'25px'}}><strong>Choose Image</strong></label>
              </Col>
              <Col md='4'>
                {this.state.imagePreview?(
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center'}} className='keywordItem'>
                    <img src={this.state.imagePreview} style={{height:150, width:'100%',marginBottom:'10px'}} />
                      <span className='image__clear' id='clearBtn' onClick={()=>this.clearImage()}>
                        <i className="fas fa-times" />
                      </span>
                      <UncontrolledTooltip placement="right" target='clearBtn'>Clear Image</UncontrolledTooltip>
                  </div>
                ):null}
              </Col>
              <Col md='2' >
                <Input id='promotedKey__imageUploader' type='file' accept="image/x-png,image/gif,image/jpeg"  onChange={(e)=>this.uploadImage(e)}/>
                <Button disabled={!!this.state.imagePreview} className='actionsBtn' onClick={()=>this.fakeUpload()}>Upload Image</Button>
              </Col>
            </Row>
      </div>
    )
  }





}
