import React from 'react'
import {Row,Col,Card,CardImg, Button} from 'reactstrap'
import {Toggler} from './../components'

class CardList extends React.Component{
  constructor(props){
    super(props)
  }
  render(){
    return(
      <Row>
        <Col md='4'>
          <div>
            <Card inverse>
              <CardImg width="100%" src={this.props.img} alt="feature image" />
            </Card>
          </div>
        </Col>
        <Col md='8'>
          <Row>
            <h3>
              {this.props.children}
            </h3>
          </Row>
          <Row>
            <Toggler handler={()=>this.props.toggleHandler()} status={this.props.toggleStatus}></Toggler>
          </Row>
          <Row>
            <div style={{padding:'1rem'}} className="clearfix">
              <Button style={{position:'absolute',bottom:0,right:0}} className='actionsBtn float-right' onClick={()=>this.props.toggleUploadImageHandler()}>Edit</Button>
            </div>
          </Row>
        </Col>
      </Row>
    )
  }
}
export default CardList;
