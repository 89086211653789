import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Input} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"

export default class RedirectOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
        suggestedFixers:[],
        multiSelectFixer:[],
        selectedFixer:[],
        fixerIds:[],
    }
  }

  componentDidMount(){
    this.getSuggestedFixers()
  }


  onSelectedChanged(selectedItems) {
    console.log(selectedItems);
    let arr = []
    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({selectedFixer:selectedItems, selectedFixerInPendingDispatch:selectedItems, fixerIds:arr},()=>console.log(arr))
  }


  getSuggestedFixers(){
    this.setState({loading : true})
    Services.getSuggestedFixers(this.props.order.category.id, this.props.order.city.id, (res)=>{
      let arr = res.data.fixers
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].arName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({suggestedFixers:res.data.fixers, multiSelectFixer:titles, loading:false})
    })

  }


  redirect(){
    if (this.state.fixerIds.length==0) {
      alert('Please, select fixer')
    }else {
      this.setState({loading:true})
      let reqData = {
        fixerIds: this.state.fixerIds
      }
      Services.submitRedirect(this.props.order.orderId, reqData, (res)=>{
        if (res.message == 'fixer_is_unavailable') {
            console.log(res)
          this.setState({loading:false},()=>this.props.closeModalsWithHandleErrorMessage())
        }else {
            console.log(res)
          this.setState({loading:false,redirectModal:false,
            fixerIds:[],
            selectedPeriod:'Choose',requestedDate:'',selectedTime:'Choose',mainModal:false,visitTime:false, visitTimeOrder:'',selectPeriodId:'',requestedDate:'',modalInprogress:false},()=>this.props.reload())
        }
      },()=>this.props.reload())
    }
  }


  render(){
    return(
      <div>
        <div>
          {this.state.multiSelectFixer.length!=0?(
            <MultiSelect
              items={this.state.multiSelectFixer}
              selectedItems={this.state.selectedFixer}
              onChange={(item)=>this.onSelectedChanged(item)}
              withGrouping={true}
              showSelectedItems={false}
              showSelectAll={false}
            />
          ):null}
        </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
