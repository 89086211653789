import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label } from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner } from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'

export default class AddBackground extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      image:'',
      imagePreview:''
    }

  }
  componentDidMount(){
  }


  onSelectImageFromPC = (e, handle) => {
          e.preventDefault();
          let reader = new FileReader();
          let file = e.target.files[0];
          reader.onloadend = () => {
            this.setState({image: file,imagePreview:reader.result})
          }
          reader.readAsDataURL(file)

}


addBackground(){
  this.setState({loading:!this.state.loading})
  let data = new FormData()
  data.append('image',this.state.image)
  Services.addBackground(data,(res)=>{
    this.setState({loading:false},()=>this.props.update())
  })
}





  render(){
    return(
      <div style={{marginLeft:20}}>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Background :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              onChange={(e)=>this.onSelectImageFromPC(e, 'arName')}
              name="uploadfile"
              id="img"
              style={{display:'none'}}
              accept="image/x-png,image/jpeg"
            />
            <Label for="img" style={{cursor:'pointer',width:'100%',backgroundColor:'#4dbd74',borderRadius:5,textAlign:'center',padding:7}}>{this.state.image == '' ? 'Choose Background' : this.state.image.name}</Label>
          </Col>
        </Row>
        {this.state.imagePreview != '' ? (
          <Row style={{marginTop:15}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview :</Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
          </Col>
        </Row>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
      )
    }
  }
