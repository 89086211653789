import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Label,Table} from 'reactstrap'
import { LoadingSpinner } from './../../../components'
import Services from './../../../services/Services'
import Paginator from './../../../components/Pagination/V2/Paginator'
import { NoResult } from '../../../components'

export default class FixerHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
       fixerHistory:[],
       currentPage:1,
       noResult:false,
       nextPages:[],
       previousPages:[],
       loading:false
    }
  }

  componentDidMount(){
      console.log(this.props.singleFixer)
      this.getFixerHistory()
  }



  getFixerHistory(){
    this.setState({loading:!this.state.loading})
    Services.getFixerHistory(this.props.singleFixer.id,this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        fixerHistory:res.data.fixerActions,
        noResult:!res.data.fixerActions.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getFixerHistory())
  }


  render(){
    return(
        <div>
        {this.state.noResult == true ? (
            <NoResult/>
        ):(
          <div>
          <Row>
          <Col>
            <Table hover bordered striped responsive size="md">
              <thead>
                <tr>
                  <th style={{textAlign:'center'}} width='15%'>Name</th>
                  <th style={{textAlign:'center'}} width='25%'>Actions</th>
                  <th style={{textAlign:'center'}} width='25%'>Date and Time</th>
                </tr>
              </thead>
              <tbody>
                {this.state.fixerHistory.map((item, index)=>(
                  <tr key={index}>
                    <td style={{textAlign:'center'}}>{item.actionMaker.name}</td>
                    <td style={{textAlign:'center'}}>
                      <span>- Action : {item.briefDescription == null ? 'N/A' : item.briefDescription}</span><br/>
                      {item.reason != null ? <span>- Reason : {item.reason.enName}</span> : null}
                    </td>
                    <td style={{textAlign:'center'}}>{item.createdAt}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
        </div>
        )}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
