import React from 'react'
import { Table , Button } from 'reactstrap';
import { SingleCustomer, SingleOrder } from '..';
import PromocodeServices from '../../services/promo/PromocodeServices';
import LoadingSpinner from '../LoadingSpinner';
import Paginator from '../Pagination/V2/Paginator';

class UsersModal extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      loading:false,
      promoCodeOrders:[],
      previousPages:[],
      nextPages:[],
      showSingleCustomer:false,
      showSingleOrder:false,
      singleCustomer:null,
      singleCustomer:null,
    }

  }

  componentDidMount(){
    this.getPromocodeOrders()
  }

  changePage(page){
    this.getPromocodeOrders(page)
  }

  getPromocodeOrders( page = 1 ){
    this.setState({loading:true})
    PromocodeServices.getPromocodeOrders(this.props.promoCode.id, page , res =>{
      console.log(page,res);
      this.setState({
        loading:false,
        promoCodeOrders:res.data.promoCodeOrders,
        nextPages:res.data.paginationLinks.nextPages,
        previousPages:res.data.paginationLinks.previousPages,
      });
    });
  }

  render(){
    return(
    <>
      <div>
          <div>
            <Table hover bordered striped responsive size="md">
              <thead>
                <tr>
                  <th style={{textAlign:'center',width:'7%'}}>Customer ID</th>
                  <th style={{textAlign:'center'}}>Customer Name</th>
                  <th style={{textAlign:'center'}}>Order Serial</th>
                </tr>
              </thead>
              <tbody>
                {this.state.promoCodeOrders.map((promoCodeOrder, index)=>(
                  <tr key={index}>
                    <td style={{textAlign:'center'}}>{promoCodeOrder.user.id}</td>
                    <td style={{textAlign:'center'}}><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({showSingleCustomer:true,singleCustomer:promoCodeOrder.user})}>{promoCodeOrder.user.name}</Button></td>
                    <td style={{textAlign:'center'}}>
                      <Button className="click" style={{backgroundColor:'#ededed',borderColor:'transparent', borderRadius:10, padding:10,fontWeight:'bold'}} onClick={()=>this.setState({showSingleOrder:true, singleOrder:promoCodeOrder.order})}>{promoCodeOrder.order.serial}</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        <LoadingSpinner loading={this.state.loading}/>
        <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
      </div>
      {this.state.showSingleCustomer?<SingleCustomer data={this.state.singleCustomer} reload={()=>this.setState({showSingleCustomer:false,singleCustomer:false})}/>:null}
      {this.state.showSingleOrder?<SingleOrder data={this.state.singleOrder} reload={()=>this.setState({showSingleOrder:false,singleOrder:null})} />:null}
    </>
    )
  }

}
export default UsersModal;
