import React from 'react'

class SoundMessage extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      isVoicePlaying:false,
      soundCounter:0,
      soundDuration:90
    };
    this.sound = null;
  }

  stopSoundCounter(){
    clearInterval(this.soundCounterHandler)
    this.sound.pause()
  }

  reset(){
    this.setState({isVoicePlaying:false,soundCounter:0},() => {
      clearInterval(this.soundCounterHandler)
      if (this.sound) {
        this.sound.pause()
        this.sound = null
      }
    });
  }

  playVoice(){
    if (!this.sound) {
      const sound = new Audio(this.props.message.sound);
      sound.addEventListener("ended",() => this.reset() );
      sound.addEventListener("durationchange" , (e) => {
        if (e.target.duration != Infinity) {
          this.setState({soundDuration:e.target.duration})
        }
      })
      this.sound = sound;
    }

    this.setState(
      () => ({isVoicePlaying:true}),
      () => {
        this.soundCounterHandler = setInterval( () => this.setState( (pState) => ({ soundCounter: pState.soundCounter+1}) ) , 1000 ) ;
        this.sound.play()
        this.props.notify(this)
      }
    );
  }

  pauseVoice(){
    this.setState(()=>({isVoicePlaying:false}) , () => this.stopSoundCounter());
  }

  render(){
    return(
      <div style={{display:'flex',width:'100%'}}>
          {this.state.isVoicePlaying?(
            <div onClick={()=>this.pauseVoice()} style={{flex:'1',display:'flex',justifyContent:'center'}}>
              <i className="fa fa-pause-circle fa-2x" aria-hidden="true"></i>
            </div>
            ):(
            <div onClick={()=>this.playVoice()} style={{flex:'1',display:'flex',justifyContent:'center'}}>
              <i className="fa fa-play-circle fa-2x" aria-hidden="true"></i>
            </div>
            )}
        <div style={{flex:'4',display:'flex',justifyContent:'center'}}>
          <input dir="ltr" type="range" onChange={()=>{}} min="0" max={this.state.soundDuration} value={this.state.soundCounter} />
        </div>
    </div>
    );
  }
}

export default SoundMessage;
