import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap'
import { validatePhoneNumber } from './../../../core/helper'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return(
      <div className='orderActions'>
        {this.props.order.status.enName == 'Waiting Confirmation'?(
          this.renderWaitingConfirmationActions()
        ):this.props.order.status.enName == 'Confirmed'?(
          this.renderConfirmedActions()
        ):this.props.order.status.enName == 'In-Progress'?(
          this.renderInprogressActions()
        ):this.props.order.status.enName == 'On Site'?(
          this.renderOnSiteActions()
        ):this.props.order.status.enName == 'Inspected'?(
          this.renderInspectionActions()
        ):this.props.order.status.enName == 'pending-dispatch'?(
          this.renderPendingDispatchActions()
        ):this.props.order.status.enName == 'Done'?(
          this.renderDoneActions()
        ):this.props.order.status.enName == 'Repeated'?(
          this.renderRepeatedActions()
        ):
        this.renderCloseExpiredFaildActions()
      }
      {/* {this.props.order.rate?(
        <p>omar</p>
      ):null} */}
    </div>
  )
}

renderWaitingConfirmationActions(){
  return(
    <Row style={{display:'block'}} className='text-center'>
      <Button className='actionsBtn' onClick={()=>this.props.confirm()}>Confirm <i className="fas fa-check-circle"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.cancel()}>Cancel<i className="fa fa-window-close"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.edit()}>Edit Data <i className="fas fa-edit"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.redirect()}>Redirect<i className="fas fa-exchange-alt"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.changeDate()}>Change Date<i className="far fa-calendar-alt"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.changeCategory()}>Change Category<i className='fas fa-sitemap'></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.changeCommunicationChannel()}>Change Communication Channel<i className="fas fa-check-circle"></i></Button>
      <Button className='actionsBtn' onClick={()=>this.props.repeat()}>Repeate Order<i className='fa fa-window-close'></i></Button>
      {this.props.order.serviceGroup&&this.props.order.serviceGroup.id==9?
        <Button className='actionsBtn' onClick={()=>this.props.addVideoLink()}>Video Meeting Link<i className='fa fa-video-camera'></i></Button>
        :null}
        <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
        {this.props.order.priceOffer?(
          <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
        ):null}

      </Row>
    )
  }

  renderConfirmedActions(){
    return(
      <Row style={{display:'block'}} className='text-center'>
        <Button className='actionsBtn' onClick={()=>this.props.cancel()}>Cancel<i className="fa fa-window-close"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.edit()}>Edit Data <i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.showPrice()}>Price <i className="fas fa-money-check-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.redirect()}>Redirect<i className="fas fa-exchange-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeDate()}>Change Date<i className="far fa-calendar-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeToInProgress()}>Change Inprogress<i className="far fa-calendar-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeCategory()}>Change Category<i className='fas fa-sitemap'></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.closeAsInspection()}>Close as Inspection<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeCommunicationChannel()}>Change Communication Channel<i className="fas fa-check-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.repeat()}>Repeate Order<i className='fa fa-window-close'></i></Button>
        {this.props.order.serviceGroup&&this.props.order.serviceGroup.id==9?
          <>
          <Button className='actionsBtn' onClick={()=>this.props.addVideoLink()}>Video Meeting Link<i className='fa fa-video-camera'></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.closeAsVideoOrder()}>Close as Video Order<i className='fa fa-times'></i></Button>
        </>
        :null}
        <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
        {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
          <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
        ):null}
        <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
        {this.props.order.priceOffer?(
          <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
        ):null}
      </Row>
    )
  }


  renderInprogressActions(){
    return(
      <Row style={{display:'block'}} className='text-center'>
        <Button className='actionsBtn' onClick={()=>this.props.edit()}>Edit Data <i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.showPrice()}>Price <i className="fas fa-money-check-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.redirect()}>Redirect<i className="fas fa-exchange-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.closeAsInspection()}>Close as Inspection<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeCommunicationChannel()}>Change Communication Channel<i className="fas fa-check-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
        {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
          <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
        ):null}
        <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
        {this.props.order.priceOffer?(
          <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
        ):null}
      </Row>
    )
  }

  renderInspectionActions(){
    return(
      <Row style={{display:'block'}} className='text-center'>
        <Button className='actionsBtn' onClick={()=>this.props.reOpen()}>Re-Open <i className="fas fa-retweet"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
        {/* <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button> */}
        {this.props.order.lastInvoice.isPaid == 1?null:(
          <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
        )}
        {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
          <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
        ):null}
        <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
        {this.props.order.priceOffer?(
          <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
        ):null}
        {this.props.order.rate?(
          <Button className='actionsBtn' onClick={()=>this.props.deleteRate()}>Delete Rate<i className="fas fa-trash"></i></Button>
        ):null}
        {this.props.order.lastInvoice.isPaid == 0 && ( this.props.order.lastInvoice.collectedAmount == 0 || this.props.order.paymentMethod.id == 1 )  ?(
          <Button className='actionsBtn' onClick={()=>this.props.confirmPayment()}>Confirm Payment<i className="fas fa-check"></i></Button>
        ):null}
      </Row>
    )
  }

  renderPendingDispatchActions(){
    return (
      <Row style={{display:'block'}} className='text-center'>
        <Button className='actionsBtn' onClick={()=>this.props.cancel()}>Cancel<i className="fa fa-window-close"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.edit()}>Edit Data <i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeDate()}>Change Date<i className="far fa-calendar-alt"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeCategory()}>Change Category<i className='fas fa-sitemap'></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.changeCommunicationChannel()}>Change Communication Channel<i className="fas fa-check-circle"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.assignFixer()}>Assign Fixer<i className="fas fa-edit"></i></Button>
        <Button className='actionsBtn' onClick={()=>this.props.repeat()}>Repeate Order<i className='fa fa-window-close'></i></Button>
        {this.props.order.serviceGroup&&this.props.order.serviceGroup.id==9?
          <Button className='actionsBtn' onClick={()=>this.props.addVideoLink()}>Video Meeting Link<i className='fa fa-video-camera'></i></Button>
          :null}
          <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
          {this.props.order.priceOffer?(
            <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
          ):null}

        </Row>
      )
    }

    renderDoneActions(){
      return(
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.props.reOpen()}>Re-Open <i className="fas fa-retweet"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
          {/* <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button> */}
          {this.props.order.lastInvoice.isPaid == 1?null:(
            <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
          )}
          {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
            <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
          ):null}
          <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
          {this.props.order.priceOffer?(
            <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
          ):null}
          {this.props.order.rate?(
            <Button className='actionsBtn' onClick={()=>this.props.deleteRate()}>Delete Rate<i className="fas fa-trash"></i></Button>
          ):null}
          {this.props.order.lastInvoice.isPaid == 0 && ( this.props.order.lastInvoice.collectedAmount == 0 || this.props.order.paymentMethod.id == 1 )  ?(
            <Button className='actionsBtn' onClick={()=>this.props.confirmPayment()}>Confirm Payment<i className="fas fa-check"></i></Button>
          ):null}
        </Row>
      )
    }

    renderCloseExpiredFaildActions(){
      return(
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.props.reOpen()}>Re-Open <i className="fas fa-retweet"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.repeat()}>Repeate Order<i className='fa fa-window-close'></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
          {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
            <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
          ):null}
          <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
          {this.props.order.priceOffer?(
            <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
          ):null}

        </Row>
      )
    }

    renderOnSiteActions(){
      return(
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.props.showPrice()}>Price <i className="fas fa-money-check-alt"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.closeAsInspection()}>Close as Inspection<i className="fas fa-edit"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.changeCommunicationChannel()}>Change Communication Channel<i className="fas fa-check-circle"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.changePaymentMethod()}>Change Payment Method<i className="fas fa-edit"></i></Button>
          {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
            <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
          ):null}
          <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
          {this.props.order.priceOffer?(
            <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
          ):null}

        </Row>
      )
    }


    renderRepeatedActions(){
      return(
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.props.reOpen()}>Re-Open <i className="fas fa-retweet"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.history()}>View History<i className="fas fa-eye"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.assignToAgent()}>Assign Order To Agents<i className="fas fa-plus-circle"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addNote()}>Add Note<i className="fas fa-edit"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.props.addEquipments()}>Add Equipments<i className='fa fa-plus'></i></Button>
          {(this.props.order.chats && this.props.order.chats.length != 0) &&  this.props.order.chats.some( c => c.lastMessage != null )?(
            <Button className='actionsBtn' onClick={()=>this.props.viewChat()}>View Chat<i className="fas fa-comment-alt"></i></Button>
          ):null}
          <Button className='actionsBtn' onClick={()=>this.props.createComplaint()}>Create Complaint<i className="fas fa-edit"></i></Button>
          {this.props.order.priceOffer?(
            <Button className='actionsBtn' onClick={()=>this.props.viewPriceOfferDetails()}>Offer Price Details<i className="fas fa-edit"></i></Button>
          ):null}

        </Row>
      )
    }
  }
