import React from 'react'
import moment from 'moment'
import MessageInfo from './../MessageInfo'
import ChatService from '../../../services/chatServices/ChatService'

class LeftHandSideMessage extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      showMessageInfo:false,
      page:1,
      info:[]
    };
  }
  getMessageInfo(){
    this.setState({loading:true})
    ChatService.getMessageInfo(this.props.message.id, this.state.page, (res)=>{

      if (res.data.length != 0) {
        this.setState((pS)=>({info:pS.info.concat(res.data), page:this.state.page+1}),()=>this.getMessageInfo())
      }else {
        this.setState((pS)=>({info:pS.info.concat(res.data), showMessageInfo:!pS.showMessageInfo, loading:false}))
      }
    })
  }

  render(){

    return (<>
      <div className="float-left" style={{display:'inline-block',backgroundColor:'white',maxWidth:'70%',borderRadius:'0px 20px 20px 20px',padding:'10px',textAlign:'left',color:'black',}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <div style={{marginRight:'10px'}}>
                <strong>
                    {(this.props.message.sender.enName != null ? this.props.message.sender.enName : this.props.message.sender.arName) +':'}
                </strong>
            </div>
            {this.state.loading?(
              <div style={{display:'flex',fontWeight:'300',fontSize:'.7em',alignItems:'center'}} >
                {moment(this.props.message.createdAt).calendar()}
                <i className="fas fa-circle-o-notch fa-spin" style={{marginLeft:10,cursor:'pointer'}}/>
              </div>
            ):(
              <div style={{display:'flex',fontWeight:'300',fontSize:'.7em',alignItems:'center'}} >
                {moment(this.props.message.createdAt).calendar()}
                <i className="fas fa-ellipsis-v" style={{marginLeft:10,cursor:'pointer'}} onClick={()=>this.getMessageInfo()}></i>
              </div>
            )}


        </div>
        {this.props.children}
      </div>
      {this.state.showMessageInfo?(<>
        <div className='clearfix'></div>
        <div className="float-left">
          <MessageInfo infos = {this.state.info}/>
        </div>
      </>):null}
      <div className='clearfix'></div>
   </>)
  }
}

export default LeftHandSideMessage;
