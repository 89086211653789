import React, { Component } from 'react'
import { Container, Row, Col, Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, InputGroupAddon,InputGroup, Input } from 'reactstrap'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import GoogleMapReact from 'google-map-react'
import firebase from 'firebase/app'
import 'firebase/database'
import SingleFixer from './../fixers/SingleFixer'


let showSingleFixer = false


const MarkerComponent = ({ fixer, status, timestamp, $hover, showProfile }) => (
  <div className='marker' >
    {$hover?(
      <div className='hoveredMarker'>
        <h6>{fixer.arName}</h6>
        <h6>{fixer.category.arName}</h6>
        <h6 style={{fontSize:10, color:'grey', marginTop:5}}>{'last update: '+ new Date(timestamp).toLocaleString('en-GB', { hour12: true })}</h6>
        <button onClick={showProfile} style={{marginTop:5}}>
          Show profile
        </button>
      </div>
    ):<div/>}
    <i className='fa fa-street-view' style={{fontSize:30, color:status=='offline'?'red':status=='invisible'?'grey':'green', position:'absolute'}} />

  </div>
)



class TrackingFixers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fixers:[],
      allFixers:[],
      categories:[],
      cities:[],
      renderSelectCity:'City',
      renderSelectStatus:'Status',
      renderSelectCategory:'Category',
      selectedCityItem:'',
      selectedCategoryItem:'',
      selectedStatusItem:'',
      loading:false,
      showSingleFixer:false,
      defaultCenter:{lat: 31.2001,lng: 29.9187},
      searchTerm:'',
      invisibleAfter:'',
      offlineAfter:'',
    }
  }

  getArea(){
    this.setState({loading:true})
    Services.getArea(this.state.selectedCityId, (res)=>{
      this.getCities()
      this.listenForRealtimeDatabaseOnFirebase()
      setInterval(()=> {
        if (!this.state.showSingleFixer) this.listenForRealtimeDatabaseOnFirebase()
      },30000)
      window.scrollTo(0, 0)
    })
  }

  componentDidMount(){
    this.getArea()

  }
  reload = () => {
    this.setState({showSingleFixer:false})
    this.listenForRealtimeDatabaseOnFirebase()
  }
  getCities(){
    this.setState({loading:true})
    Services.getCities((res)=>{
      let arr = res.data.cities
      let obj = {enName:'All',id:'all',children:[]}
      arr.unshift(obj)
      this.setState({cities:arr},()=>this.getCategories())
    })
  }
  getCategories(){
    Services.getCategories((res)=>{
      let arr = res.data.categories
      let obj = {enName:'All',id:'all',children:[]}
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.unshift(obj)
      this.setState({categories:arr, loading:false},()=>this.listenForRealtimeDatabaseOnFirebase())
    })
  }

  listenForRealtimeDatabaseOnFirebase(){
    firebase.database().ref('trackingFixer/').once('value', (snapshot) => {
      let fixers = []
      let invisibleAfter = ''
      let offlineAfter = ''
      if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
        Object.keys(snapshot.val()).forEach(function (key) {
          var val = snapshot.val()[key]
          if (key != 'after') {
            if (val.fixer.status.id == 11 && val.fixer.isBlocked == false) {
              fixers.push(val)
            }
          }else {
            invisibleAfter = val.invisible
            offlineAfter = val.offline
          }

        })
      }
      let data = fixers
      for (var i = 0; i < data.length; i++) {
        data[i].status = this.detectFixerDeviceStatusDependonTimestamp(data[i].timestamp)
      }
      console.log(data.length);
      this.setState({fixers: data, allFixers:data,invisibleAfter:invisibleAfter, offlineAfter:offlineAfter },()=>this.filterFixers())
    })
  }




  handleMenus(menu, item){
    switch (menu) {
      case 'city':
      this.setState({renderSelectCity:item.enName, selectedCityItem:item},()=>this.filterFixers())
      break
      case 'category':
      this.setState({renderSelectCategory: item.enName, selectedCategoryItem: item},()=>this.filterFixers())
      break
      case 'status':
      this.setState({renderSelectStatus:item, selectedStatusItem:item},()=>this.filterFixers())
      break
      default:
    }
  }
  filterFixers(){
    let filteredFixerArray = this.state.allFixers
    if (this.state.selectedCityItem.id != 'all' && this.state.selectedCityItem != '') {
      filteredFixerArray = this.filteredFixersByCity(filteredFixerArray)
    }
    if (this.state.selectedCategoryItem.id != 'all' && this.state.selectedCategoryItem != '') {
      filteredFixerArray = this.filteredFixersByCategory(filteredFixerArray)
    }
    if (this.state.selectedStatusItem != 'All' && this.state.selectedStatusItem !='') {
      filteredFixerArray = this.filteredFixersByStatus(filteredFixerArray)
    }
    this.setState({fixers:filteredFixerArray},()=>this.fetchSingleFixerById())
  }

  filteredFixersByCity(arr){
    this.updateMapLoactionZoneDependonFilteredCity(this.state.selectedCityItem)
    let filteredArrayByCity = []
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].fixer.city.id == this.state.selectedCityItem.id) {
        filteredArrayByCity.push(arr[i])
      }
    }
    return filteredArrayByCity
  }

  filteredFixersByCategory(arr){
    let filteredArrayByCategory = []
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].fixer.category.id == this.state.selectedCategoryItem.id) {
        filteredArrayByCategory.push(arr[i])
      }
    }
    return filteredArrayByCategory
  }

  filteredFixersByStatus(arr){
    let filteredArrayByStatus = []
    if (this.state.selectedStatusItem == 'Online') {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].status == 'online') {
          filteredArrayByStatus.push(arr[i])
        }
      }
      return filteredArrayByStatus
    }else if (this.state.selectedStatusItem == 'invisible') {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].status == 'invisible') {
          filteredArrayByStatus.push(arr[i])
        }
      }
      return filteredArrayByStatus
    }else{
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].status == 'offline') {
          filteredArrayByStatus.push(arr[i])
        }
      }
      return filteredArrayByStatus
    }
  }

  updateMapLoactionZoneDependonFilteredCity(selectedCity){
    this.setState({defaultCenter:Global.zonesLocation.find(x => x.id === selectedCity.id).location})
  }


  detectFixerDeviceStatusDependonTimestamp(lastUpdatedTimestamp){
    let currentDate = new Date()
    let timestampForLastHour = currentDate.setMinutes(currentDate.getMinutes() - this.state.invisibleAfter)
    let currentDate2 = new Date()
    let timestampForLastDay = currentDate2.setMinutes(currentDate2.getMinutes() - this.state.offlineAfter)
    if (lastUpdatedTimestamp < timestampForLastDay) {
      return 'offline'
    }else if (lastUpdatedTimestamp < timestampForLastHour ) {
      return 'invisible'
    }else {
      return 'online'
    }
  }

  showProfile(item){
    this.setState({showSingleFixer:true, singleFixer:item})
  }
  resetFilter(){
    this.setState({
      renderSelectCity:'City',
      renderSelectStatus:'Status',
      renderSelectCategory:'Category',
      selectedCityItem:'',
      selectedCategoryItem:'',
      selectedStatusItem:'',
      searchTerm:'',
      fixers:this.state.allFixers
    })
  }

  handleChangeInputs = (e) => {
    this.setState({searchTerm:e.target.value})
  }

  fetchSingleFixerById(){
    if (this.state.searchTerm!='') {
      let arr = []
      if (this.state.fixers.find(x => x.fixer.id === window.calcMD5(this.state.searchTerm)) != undefined) {
        arr.push(this.state.fixers.find(x => x.fixer.id === window.calcMD5(this.state.searchTerm)))
        this.setState({fixers:arr})
      }else {
        this.setState({fixers:[]})
      }
    }
  }
  onGoogleApiLoaded = (map, maps) => {
    var triangleCoords = [
      { lat: 25.774, lng: -80.19 },
      { lat: 18.466, lng: -66.118 },
      { lat: 32.321, lng: -64.757 },
      // { lat: 25.774, lng: -80.19 }
    ];

    var triangleCoords2 = [
      { lat: 30.774, lng: -80.19 },
      { lat: 28.466, lng: -66.118 },
      { lat: 42.321, lng: -64.757 },
      // { lat: 25.774, lng: -80.19 }
    ];

    // Construct the polygon.
    var bermudaTriangle = new maps.Polygon({
      paths: triangleCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35
    });
    var bermudaTriangle2 = new maps.Polygon({
      paths: triangleCoords2,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35
    });
    bermudaTriangle.setMap(map);
    bermudaTriangle2.setMap(map);
  };

  render() {
    return (
      <div className="app align-items-center">
        <Container>
          {this.renderFilter()}
        </Container>

        {this.state.showSingleFixer?<SingleFixer data={this.state.singleFixer} reload={this.reload}/>:null}

        <div style={{ height: '80vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyA9PJNedI5y2RLoIdjBq6ojyJGeWZ3izR0' }}
            defaultZoom={9}
            center={this.state.defaultCenter}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.onGoogleApiLoaded(map, maps)}
          >
            {this.state.fixers.map((item , index)=>(
              <MarkerComponent
                key={index}
                lat={item.latitude}
                lng={item.longitude}
                fixer={item.fixer}
                status={item.status}
                timestamp={item.timestamp}
                showProfile={()=>this.showProfile(item.fixer)}
              />
            ))}
          </GoogleMapReact>
        </div>
        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>
      </div>
      )
    }
    renderFilter(){
      return(
        <Row>
          <UncontrolledDropdown  className="mb-3">
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-map-marker-alt' style={{marginRight:10}} />{this.state.renderSelectCity}</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              {this.state.cities.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMenus('city',item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown  className="mb-3 areaMenu">
            <DropdownToggle caret  className='actionsBtn'><i className='fas fa-sitemap' style={{marginRight:10}} />{this.state.renderSelectCategory}</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              {this.state.categories.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMenus('category',item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>


          <UncontrolledDropdown  className="mb-3" style={{marginLeft:15}} >
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-mobile-alt' style={{marginRight:10}} />{this.state.renderSelectStatus}</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              <DropdownItem onClick={()=>this.handleMenus('status','All')}>All</DropdownItem>
              <DropdownItem onClick={()=>this.handleMenus('status','Online')}>Online</DropdownItem>
              <DropdownItem onClick={()=>this.handleMenus('status','invisible')}>Invisible</DropdownItem>
              <DropdownItem onClick={()=>this.handleMenus('status','Offline')}>Offline</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <Col md={2}>
            <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Reset</Button>
          </Col>
          {this.renderSearch()}
        </Row>
      )
    }
    renderSearch(){
      return(

        <Col md="4" style={{marginLeft:'auto'}}>
          <InputGroup>
            <Input
              value={this.state.searchTerm}
              onChange={(e)=>this.handleChangeInputs(e)}
              type="text"
              id="searchTerm"
              name="searchTerm"
              placeholder="By id .."
              maxLength={50}
              onKeyPress={this.handleKeyPress}
            />
            <InputGroupAddon addonType="prepend">
              <Button onClick={()=>this.fetchSingleFixerById()} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>

      )
    }
  }




  export default withRouter(TrackingFixers)
