import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'

export default class FeaturedServiceGroupDetails extends React.Component{
  constructor(props){
    super(props)
    this.state={

    }
  }

  componentDidMount(){

  }



  render(){
    return(
      <div>
        {this.props.resources.map((item,index)=>(
          <p key={index}><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Service Group: <span style={{fontWeight:'bold'}}>{item.entity.enName}</span></p>
        ))}
        <div style={{width:300}}>
          <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Image:</p>
          <img src={this.props.details.image} width="80%" height={300} style={{marginLeft:10}}/>
        </div>
      </div>
    )
  }
}
