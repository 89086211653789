import React from 'react'

const getSeenBy = (infos) => infos.filter(info => info.seen)

const MessageInfo = (props) => {

  const seenBy = getSeenBy(props.infos);
  console.log('seenBy',seenBy);
  if (seenBy.length == 0) {
    return <p style={{textAlign:'center', marginBottom:0, fontSize:12, fontStyle:'italic',color:'#666'}}>No one see this yet.</p>
  }else {
    return <p style={{textAlign:'center', marginBottom:0, fontSize:12, fontStyle:'italic',color:'#666'}}>{seenBy.reduce( ( str , info , i ) => str += info.reciever.enName + ( seenBy.length-1 > i ?',':'')  ,'seen by : ')}</p>
  }
}

export default MessageInfo;
