import React from 'react'
class ContentMessage extends React.Component{
  render(){
    return(
      <div style={{marginLeft:20}}>
        {this.props.message.type == 'text' ? (
          <p style={{marginTop:20}}>- Text : {this.props.message.body}</p>
          ):(<>
            <p style={{marginTop:20}}>- Text : {this.props.message.body}</p>
            <div style={{width:'100%'}}>
              <img src={this.props.message.image} style={{width:'30%',margin:'0 auto'}}/>
            </div>
          </>)}
      </div>
      )
    }
  }
  export default ContentMessage;
