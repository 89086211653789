import React from 'react'
import { FModal } from '..'

class DisplayImageModal extends React.Component{
  render(){
    const content=(
      <div className='keywordItem'>
        <img src={this.props.img} style={{width:'100%',height:'80%'}} />
      </div>
    );
    return(
      <FModal state={this.props.show}
              title='Image Preview'
              onClose={()=>this.props.closeHandler()}
              content={content}
              show='none' />
    )
  }
}
export default DisplayImageModal;
