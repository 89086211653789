import React from 'react'
import { Container, CardHeader, Row, Col, CardBody, Button as ReactButton, Card } from 'reactstrap';
import { Table, TableHeader, TH, TableBody, TR, TD, NoResult, Button, LoadingSpinner } from '../../components';
import SingleFixer from './SingleFixer';
import Paginator from './../../components/Pagination/V2/Paginator'
import FixersProfileService from '../../services/fixerServices/LandingPage/FixersProfileService';
import Services from './../../services/Services'
import { Global } from '../../core/Global';

class TrackFixersProfile extends React.Component{
  constructor(props){
    super(props)
    this.state={
      data:[],
      loading:false,
      showSingleFixer:false,
      fixer:null,
      previousPages:[],
      nextPages:[],
      currentPage:1,
    }
  }
  componentDidMount(){
    this.getProfiles()
  }
  showSingleFixer(item){
    this.setState({fixer:item},()=>this.setState({showSingleFixer:true}))
  }
  closeSingleFixer(){
    this.setState({showSingleFixer:false},()=>this.reload())
  }
  getProfiles(){
    this.setState({loading:true})
    FixersProfileService.get(this.state.currentPage,(res) => {
      this.setState({
        data:res.data.profiles,
        paginationInfo:res.data.paginationInfo,
        paginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        loading:false,
      })
    })
  }
  reload(){
    this.getProfiles()
  }
  updatePage(page){
    this.setState({currentPage:page},()=>this.reload())
  }

  render(){
    return(
      <>
      {this.state.showSingleFixer?(<SingleFixer data={this.state.fixer} reload={()=>this.closeSingleFixer()}/>):null}
      <div className="app align-items-center">
        <Container>
          <Card>
            <CardHeader>
              <Row>
                <Col md="8" style={{display:'flex',alignItems:'center'}}>
                    <i className="fa fa-align-justify" ></i>
                    <span style={{marginLeft:6}}>Track Fixers Profile</span>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className='shadow' style={{color:'#777'}}>
              {this.state.data.length?(
              <>
              <Table>
                <TableHeader>
                  <TH>Fixer Name</TH>
                  <TH>Fixer Profile</TH>
                  <TH>User Views</TH>
                </TableHeader>
                <TableBody>
                  {this.state.data.map((item,index)=>(
                    <TR key={index}>
                      <TD>
                        <Button className='click' handler={()=>this.showSingleFixer(item.user)}>{item.user.name?item.user.name:item.user.arName}</Button></TD>
                      <TD>{item.link?(<ReactButton href={`${Global.webSiteURL}fixers/${item.link}`} target='__blank' color="link">{`${Global.webSiteURL}fixers/${item.link}`}</ReactButton>):'N/A'}</TD>
                      <TD>{item.views?item.views:'0'}</TD>
                    </TR>
                  ))}
                </TableBody>
              </Table>
              {this.renderPagination()}
              </>
              ):(
                <NoResult/>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <LoadingSpinner loading={this.state.loading}/>
    </>);
  }
  renderPagination(){
    return(
      <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.updatePage(page)} />
    );
  }
}

export default TrackFixersProfile;
