import React from 'react'
import { Input , Dropdown , DropdownItem , DropdownMenu , DropdownToggle , Modal , ModalBody , ModalHeader } from 'reactstrap'
import PhotoIcon from './../../../assets/img/photo.png'
import MoneyIcon from './../../../assets/img/money.png'
import Send from './../Buttons/Send'
import PriceOfferMessage from './../PriceOfferMessage'

class AttachmentList extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      caption:'',
      imagePreview:'',
      file:'',
      isOpen:false,
      viewPriceOffer:false,
    }
  }

  componentDidMount(){
    this.inputRef.focus();
  }

  onSelectImageFromPC = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () =>{
      this.setState({isOpen:false,file:file,imagePreview:reader.result})
    }
    reader.readAsDataURL(file)
  }

  onSendPriceOffer(data){
    this.setState(
      {viewPriceOffer:false},
      () => this.props.onSendPriceOffer(data)
    )
  }

  onSend(){
    this.props.onSendImage({
      caption:this.state.caption,
      file:this.state.file
    })
    this.onClose();
  }

  onClose(){
    this.setState({
      caption:'',
      imagePreview:'',
      file:'',
      isOpen:false
    })
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      this.onSend()
    }
}

  render(){
    return(
    <>

      {this.state.viewPriceOffer?(
        <PriceOfferMessage
            customer={this.props.customer}
            send={(data)=>this.onSendPriceOffer(data)}
            onClose={()=>this.setState({viewPriceOffer:false})}
        />
      ):null}
      {this.state.imagePreview?(
          <Modal fade={false} isOpen={true} toggle={()=>this.onClose()}>
            <ModalHeader toggle={()=>this.onClose()} >Preview </ModalHeader>
            <ModalBody>
              <div className='message-image-modal'>
                <div className='message-input-image'>
                    <img className='message-input-image-preview' src={this.state.imagePreview} />
                </div>
                <div className='message-caption'>
                  <Input
                    className='message-image-caption'
                    placeholder='Add a caption...'
                    value={this.state.caption}
                    onChange={(e)=>this.setState({caption:e.target.value})}
                    onKeyPress={(e)=>this.handleKeyPress(e)}
                  />
                  <div>
                    <Send onSend={()=>this.onSend()} />
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
      ):null}
      <input
        type="file"
        ref={ ref => this.inputRef = ref }
        onChange={(e)=>this.onSelectImageFromPC(e)}
        name="uploadfile"
        id="img"
        style={{display:'none'}}
        accept="image/x-png,image/jpeg"
      />
      <div className='chat-panel_message-input_attachment' >
        <Dropdown direction="up" isOpen={this.state.isOpen} toggle={()=>this.setState((pS)=>({isOpen:!pS.isOpen}))}>
          <DropdownToggle disabled={this.props.disabled} className='message-snippit-btn'>
            <span
              className="fa fa-2x fa-paperclip"
            />
          </DropdownToggle>
          <DropdownMenu className='message-attachment-container'>
              <DropdownItem className='message-attachment-btn' >
                  <img style={{cursor:'pointer'}} width={50} src={PhotoIcon} onClick={()=>document.getElementById('img').click()} />
              </DropdownItem>
              {this.props.customer && this.props.message?(
              <DropdownItem className='message-attachment-btn' >
                  <img style={{cursor:'pointer'}} width={50} src={MoneyIcon} onClick={()=>this.setState({viewPriceOffer:true})} />
              </DropdownItem>
              ):null}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>)
  }
}

export default AttachmentList;
