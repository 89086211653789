import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class EmergencyService {

  static getEmergencyNumbers(callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/emergency-phones`).then(callBack)
  }

  static addNewEmergencyNumber(phone,callBack,error){
    Adaptor.post(`${Global.baseURL}admin-panel/emergency-phones`,{phone}).then(callBack)
  }

  static togglePhoneNumber(phoneId, statusId, startTime=null, endTime=null, callBack){
    let api = `${Global.baseURL}admin-panel/emergency-phones/${phoneId}`
    let data={}
    if(statusId){
      api+=`/turn-off`
    }else{
      data={validFrom:startTime,validTo:endTime}
      api+=`/turn-on`
    }

    Adaptor.post(api, data).then(callBack)
  }
}
