import React, { Component } from 'react'
import { Button, Col, Row ,Table ,CardHeader ,Badge ,Modal ,ModalBody ,ModalHeader ,ModalFooter  ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Input ,Form,FormGroup,Label} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import {LoadingSpinner} from './../../components'



export default class CreateComplaint extends Component {
  constructor(props){
    super(props);
    this.state = {
      complaint:'',
      pictures: [],
      tags:[],
      selectedTags:[],
      multiSelectTags:[]
    }
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount(){
    this.getComplainTags()
  }

  getComplainTags(){
    Services.getComplainTags((res)=>{
      let arr = res.data.tags
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({tags:res.data.fixers, multiSelectTags:titles, loading:false})
    })
  }


  onDrop(picture) {
    console.log(picture)
    this.setState({
      pictures: picture,
    });
  }

  handleChangeInputs(e,type){
    if(type == 'complaint'){
      this.setState({complaint:e.target.value})
    }
  }

  createComplaints(){
    if (this.state.complaint == '') {
      alert('Please, enter complain')
    }else{
      this.setState({loading:true})
      let formData = new FormData()
      formData.append('complaintTypeId',1)
      formData.append('description',this.state.complaint)

      if (this.state.pictures.length != 0) {
        for (let i = 0; i < this.state.pictures.length; i++) {
          formData.append(`images[${i}]`,this.state.pictures[i])
        }
      }
      if (this.state.selectedTags.length != 0) {
        for (let i = 0; i < this.state.selectedTags.length; i++) {
          formData.append(`tags[${i}]`,this.state.selectedTags[i].id)
        }
      }
      
      Services.createComplaints(formData,this.props.order.orderId,(res)=>{
        this.setState({loading:false})
        this.props.reload()
      })
    }

  }
  onChangeMultiSelect(itemSelected){
      let arr = []
      for (var i = 0; i < itemSelected.length; i++) {
        arr.push(itemSelected[i])
      }
      console.log(arr)
      this.setState({selectedTags:arr})
    }

  render(){
    return(
      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <Row>
          <Col md="2">
            <span>Complaint:</span>
          </Col>
          <Col md="8">
            <Input
              type="textarea"
              value={this.state.complaint}
              onChange={(e)=>this.handleChangeInputs(e, 'complaint')}
              placeholder="Enter Complaint"
              className="add Complaint"
              style={{resize:'none',height:150}}
              maxLength={5000}
            />
          </Col>
        </Row>
        <Row style={{marginTop:20}}>
          <Col md="2">
            <span>Tags:</span>
          </Col>
          <Col md="8">
            <MultiSelect
              items={this.state.multiSelectTags}
              selectedItems={this.state.selectedTags}
              onChange={(e)=>this.onChangeMultiSelect(e)}
              withGrouping={true}
              showSelectedItems={false}
            />
          </Col>
        </Row>
        <Row>
          <ImageUploader
            withIcon={true}
            buttonText='Choose images'
            onChange={this.onDrop}
            imgExtension={['.jpeg','.jpg', '.gif', '.png', '.gif']}
            maxFileSize={5242880}
            singleImage={false}
            buttonStyles={{backgroundColor:'#4dbd74',color:'white',fontWeight:'bold'}}
            withPreview={true}
            accept="image/*"
          />
        </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
)
}

}
