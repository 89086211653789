import React from 'react';
import {CardHeader,Row,Col,InputGroup,Input,Button,InputGroupAddon ,ModalBody,Badge ,UncontrolledTooltip,Modal,ModalHeader,UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem,ModalFooter,Label} from 'reactstrap'
import Avatar from './../../../assets/img/brand/download.png'
import Services from './../../../services/Services'
import {FModal ,Quotation ,StatusModal,AddNoteInquiry,HistoryInquiry} from './../../../components'
import { SRLWrapper } from 'simple-react-lightbox'
import SimpleReactLightbox from 'simple-react-lightbox'

import { withRouter } from 'react-router';
import SingleFixer from './../../../views/fixers/SingleFixer'
import SingleCustomer from './../../../views/customers/SingleCustomer'


class SingleInquiry extends React.Component{
  constructor(props){
    super(props);
    this.state={
      qutationsModal:false,
      imageKeyword:'',
      image:'',
      isOpenImage:false,
      statusModal:false,
      changeStatusesArr:[],
      selectedStatus:'Status',
      selectedStatusId:'',
      currentStatusSingleInquiry:'',
      currentStatusSingleInquiryId:'',
      statusId:'',
      addNoteModal:false,
      historyModal:false
    }
  }
  componentDidMount(){
    console.log(this.props.inquiry);
  }

  closeModals = () => {
    this.setState({showSingleFixer:false, showSingleCustomer:false, singleFixer:'', singleCustomer:''})
  }

  getStatuses(typeStatus){
    Services.getStatuses('inquiry',(res)=>{
      if (typeStatus == 'ForChangeStatus') {
        let arr = res.data.statuses
        this.setState({changeStatusesArr:arr})
      }else {
        let obj = {enName:'All',id:'all'}
        let choose = {enName:'Choose',id:'choose'}
        let arr = res.data.statuses
        arr.push(obj)
        arr.unshift(choose)
        this.setState({statuses:arr})
      }
    })
  }


  handleChangeStatusSingleInquiry(typeStatus,currentStatus){
    console.log(typeStatus,currentStatus)
    this.setState({loading:true})
    this.setState({statusModal:!this.state.statusModal,
      selectedStatusId:'',selectedStatus:'Status',
      loading:false},
      ()=>{
        this.getStatuses(typeStatus,currentStatus);
        this.handleStatus('statuses',this.props.inquiries,this.props.inquiry.status.enName,'ForChangeStatus')}
      )
    }


    handleStatus =(type,item,currentStatus,typeStatus)=>{
      console.log(type,item)
      if(type == 'statuses'){
        if (currentStatus) {
          this.setState({selectedStatus: currentStatus, selectedStatusId: ''})
        }else {
          console.log(this.state.selectedStatusId)
          this.setState({selectedStatus: item.enName, selectedStatusId: item.id})
        }
      }
    }


    changeStatusInquiry(status,currentStatusSingleInquiryId,currentStatusSingleInquiry){
      this.setState({loading:true})
      let data = {
        statusId : this.state.selectedStatusId
      }
      Services.changeStatusInquiry(data,this.props.inquiry.id,(res)=>{
        if (status == 'ForChangeStatus') {
          this.setState({statusModal:!this.state.statusModal,selectedStatusId:'',selectedStatus:'Status',loading:false,currentStatusSingleInquiry:currentStatusSingleInquiry,currentStatusSingleInquiryId:currentStatusSingleInquiryId},()=>this.props.reload())
        }else{
          this.setState({statusModal:!this.state.statusModal,selectedStatusId:'',selectedStatus:'Status',loading:false})
        }
      })
    }

    handleQuotationsModal(inquiryId){
      if (this.state.qutationsModal == false) {
        this.setState({qutationsModal : !this.state.qutationsModal,inquiryId:inquiryId}
          // ,
          // ()=>{
          // this.getQuotations(inquiryId)}
        )
      }else {
        this.setState({qutationsModal : !this.state.qutationsModal,inquiryId:inquiryId})
      }
    }

    handleHistoryModal(inquiryId){
      this.setState({historyModal:!this.state.historyModal,inquiryId:inquiryId})
    }


    handleAddNoteModal(inquiryId){
      this.setState({addNoteModal : !this.state.addNoteModal,inquiryId:inquiryId})
    }

        handleSendMessageToInquiryCustomer(inquiry){
          let message = `بخصوص استفسار رقم ${inquiry.serial} `; // TODO :: change when merge to be serial not id
          this.props.history.push(`/chat/customer?userId=${inquiry.customer.id}&msg_prefix=${message}`);
        }


    render(){
      return(
        <div>

          {this.state.showSingleCustomer?<SingleCustomer data={this.state.singleCustomer} reload={this.closeModals}/>:null}
          {this.state.showSingleFixer?<SingleFixer data={this.state.singleOrder.fixer} reload={this.closeModals}/>:null}

          <SimpleReactLightbox>
            {this.renderSingleIquiry()}
          </SimpleReactLightbox>
          <FModal
            title="Change Status"
            content={
              <StatusModal
                ref={ref=>this.statusModalRef=ref}
                changeStatusesArr={this.state.changeStatusesArr}
                handleStatus={this.handleStatus}
                selectedStatus={this.state.selectedStatus}
              />
            }
            state={this.state.statusModal}
            onClose={()=>this.handleChangeStatusSingleInquiry('ForChangeStatus',this.props.inquiry.status.enName)}
            onSubmit={()=>this.changeStatusInquiry('ForChangeStatus',this.state.selectedStatusId,this.state.selectedStatus)}
          />
          <FModal
            title="Quotations"
            content={
              <Quotation
                ref={ref=>this.quotationRef=ref}
                inquiryId={this.state.inquiryId}
                reload={this.reload}
              />
            }
            state={this.state.qutationsModal}
            onClose={()=>this.handleQuotationsModal(this.state.inquiryId)}
            show={"none"}
          />
          <FModal
            title="Add Note"
            content={
              <AddNoteInquiry
                ref={ref=>this.addNoteRef=ref}
                inquiryId={this.state.inquiryId}
                reload={()=>this.handleAddNoteModal(this.state.inquiryId)}
              />
            }
            state={this.state.addNoteModal}
            onClose={()=>this.handleAddNoteModal(this.state.inquiryId)}
            onSubmit={()=>this.addNoteRef.addNote()}
          />

          <FModal
            title="View History"
            content={
              <HistoryInquiry
                ref={ref=>this.historyRef=ref}
                inquiryId={this.state.inquiryId}
                reload={this.reload}
              />
            }
            state={this.state.historyModal}
            onClose={()=>this.handleHistoryModal(this.state.inquiryId)}
            show={"none"}
          />
        </div>
      )
    }

    renderSingleIquiry(){
        return (
          <ModalBody>
            <Row style={{display:'block'}} className='text-center'>
              <Button className='actionsBtn' onClick={()=>this.handleChangeStatusSingleInquiry('ForChangeStatus',this.props.inquiry.status.enName)}>Change Status <i style={{marginLeft:10}} className="fas fa-bell"></i></Button>
              <Button className='actionsBtn' onClick={()=>this.handleQuotationsModal(this.props.inquiry.id)}>Quotations <i className="fas fa-quote-right" style={{marginLeft:10}}></i></Button>
              <Button className='actionsBtn' onClick={()=>this.handleAddNoteModal(this.props.inquiry.id)}>Add Note <i className="fas fa-edit" style={{marginLeft:10}}></i></Button>
              <Button className='actionsBtn' onClick={()=>this.handleHistoryModal(this.props.inquiry.id)}>View History <i className="fas fa-eye" style={{marginLeft:10}}></i></Button>
              <Button className='actionsBtn' onClick={()=>this.handleSendMessageToInquiryCustomer(this.props.inquiry)}>Send Message <i style={{marginLeft:10}} className="fas fa-comment-dots"></i></Button>
            </Row>
            <Row style={{marginTop:'3%'}}>
              <Col md="6">
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Inquiry Serial : <span style={{fontWeight:'bold'}}>{this.props.inquiry.serial}</span>{this.props.inquiry.keyword != null ? <span style={{color:'white',fontSize:10,fontWeight:'bold',backgroundColor:'#20a8d8',borderRadius:10,width:'50%',margin:'auto',paddingLeft:7,paddingRight:7,paddingTop:2,paddingBottom:2,marginTop:7,marginLeft:5}}>S.E</span> : ''}</p>
                {this.props.inquiry.keyword != null ?<p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Keyword: <span style={{fontWeight:'bold'}}>{this.props.inquiry.keyword.enName}</span></p> : null}
                <p style={{cursor:'pointer'}} onClick={()=>this.setState({singleCustomer:this.props.inquiry.customer, showSingleCustomer:true})} ><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer Name: <span style={{fontWeight:'bold'}}>{this.props.inquiry.customer.enName}</span></p>
                {this.props.inquiry.fixer?(
                    <p><i className="icon-arrow-right-circle" onClick={()=>this.setState({singleFixer:this.props.inquiry.fixer, showSingleFixer:true})} style={{marginRight:10}}></i>Fixer Name: <span style={{fontWeight:'bold'}}>{this.props.inquiry.fixer.enName?this.props.inquiry.fixer.enName:this.props.inquiry.fixer.arName}</span></p>
                ):null}

              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>City: <span style={{fontWeight:'bold'}}>{this.props.inquiry.area.city.enName}</span></p>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Area: <span style={{fontWeight:'bold'}}>{this.props.inquiry.area.enName} EGP</span></p>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Category: <span style={{fontWeight:'bold',cursor:'pointer'}} id="UncontrolledTooltipExample">View All</span></p>
              <UncontrolledTooltip style={{marginTop:10}} placement="top" target="UncontrolledTooltipExample">
                <ul className="categoryList">
                  {this.props.inquiry.categories.map((item,index)=>(
                    <li key={index}>{item.enName + " ,"}</li>
                  ))}
                </ul>
              </UncontrolledTooltip>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Order Type: <span style={{fontWeight:'bold'}}>{this.props.inquiry.serviceGroup != null ? this.props.inquiry.serviceGroup.enName : 'N/A'}</span></p>
              {this.state.currentStatusSingleInquiry ? (
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Inquiry status: <Badge className='shadow' color={this.state.currentStatusSingleInquiryId==17?'warning' :this.state.currentStatusSingleInquiryId==18 ? 'success' : this.state.currentStatusSingleInquiryId==19 ? 'danger' : 'danger' }>{this.state.currentStatusSingleInquiry}</Badge></p>

              )
              :
              (
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Inquiry status: <Badge className='shadow' color={this.props.inquiry.status.id==17?'warning' :this.props.inquiry.status.id==18 ? 'success' : this.props.inquiry.status.id==19 ? 'danger' : 'danger' }>{this.props.inquiry.status.enName}</Badge></p>

              )
            }
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Description: <span style={{fontWeight:'bold'}}>{this.props.inquiry.description}</span></p>
          </Col>
          <Col md="6">
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Created Date: <span style={{fontWeight:'bold'}}>{this.props.inquiry.createdAt}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Payment Method: <span style={{fontWeight:'bold'}}>{this.props.inquiry.paymentMethod != null ? this.props.inquiry.paymentMethod.enName : 'N/A'}</span></p>
            <div style={{display:'flex',justifyContent:'space-between',width:'80%'}}>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Budget From: <span style={{fontWeight:'bold'}}>{this.props.inquiry.details != null ? this.props.inquiry.details.budgetFrom +"  "+ "LE" : 'N/A'}</span></p>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Budget To: <span style={{fontWeight:'bold'}}>{this.props.inquiry.details != null ? this.props.inquiry.details.budgetTo +"  "+ "LE" : 'N/A'}</span></p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Images:</p>
            {this.props.inquiry.keyword == null ?
              <div>
                <SRLWrapper>
                  {this.props.inquiry.images.map((item,index)=>(
                    <img
                      key={index} src={item.image!=null?item.image:Avatar}
                      alt="no image"
                      className='shadow'
                      style={{marginLeft:'2%',marginBottom: '5%', width:100,height:80,marginTop:'2%'}}
                    />
                  ))}
                </SRLWrapper>
              </div> :
              <div>
                <SRLWrapper>
                  <img
                    src={this.props.inquiry.inquiryKeyword.imageLink!=null?this.props.inquiry.inquiryKeyword.imageLink:Avatar}
                    alt="no image"
                    className='shadow'
                    style={{marginLeft:'2%',marginBottom: '5%', width:100,height:80,marginTop:'2%'}}
                  />
                </SRLWrapper>
              </div>
            }

          </Col>
        </Row>
      </ModalBody>
    )
  }

}
export default withRouter(SingleInquiry);
