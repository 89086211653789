import React from 'react'
import { FModal , LoadingSpinner } from './../../index'
import { UncontrolledDropdown , DropdownToggle , DropdownMenu , DropdownItem ,} from 'reactstrap'
import Services from './../../../services/Services'

class CreateOrderModel extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            loading:false,
            avaliableDays:[],
            periods:[],
            description:'',
            selectedDay:'Choose',
            selectedPeriod:'Choose',
            selectedPeriodId:null
        }
    }

    componentDidMount(){
        this.getAvaliableDays();
    }

    changeDescription(event){
        this.setState({description:event.target.value})
    }

    chooseDate(day){
        this.setState({selectedDay:day.date,periods:day.availablePeriods,selectedPeriod:'Choose',selectedPeriodId:null})
    }
    choosePeriod(period){
        this.setState({selectedPeriod:period.enName,selectedPeriodId:period.id})
    }
    createOrder( data ){
      this.setState({loading:true})
      Services.createCompensateOrder(this.props.data.id,data,(res)=>{
        if(res.message === "not_allowed"){
          alert("Your order isn't create because, the complaint isn't related to done or inspected order.")
        }else if(res.message == 'forbidden'){
          alert('You Are Not Authorized To Take That Action')
        }
        this.setState({loading:false},()=>this.props.closeHandler())
      })
    }

    makeCompensateOrder(){
        if(this.state.selectedDay === "Choose"){
            alert('Please, Choose Date')
            return
        }
        if(this.state.selectedPeriod === "Choose"){
            alert('Please, Choose Period')
            return
        }

        let data = {
            requestedDate: this.state.selectedDay,
            periodId: this.state.selectedPeriodId,
            description: this.state.description,
        }
        this.createOrder(data);
    }

    getAvaliableDays(){
      this.setState({loading:true})
      Services.getBookingDays((res)=>{
        this.setState({avaliableDays:res.data.bookingDays,loading:false})
      })
    }


    render(){
        let content = (
            <div style={{padding:'15px'}}>
                <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-calendar-alt' style={{marginRight:10}} />Available Days</h5>
                <UncontrolledDropdown  className="mb-3 areaMenu">
                  <DropdownToggle caret className='actionsBtn' style={{width:'80%',backgroundColor:'white'}}>{this.state.selectedDay}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.avaliableDays.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.chooseDate(item)}>{item.date}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-calendar-day' style={{marginRight:10}} />Periods</h5>
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret disabled={this.state.selectedDay=='Choose'?true:false} className='actionsBtn' style={{width:'80%',backgroundColor:'white'}}>{this.state.selectedPeriod}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.periods.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.choosePeriod(item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-edit' style={{marginRight:10}} />Order Details</h5>
                <textarea
                  rows="5" cols="20" type="text"
                  style={{width:'70%',borderColor:'#777777',padding:5,borderRadius:5 ,resize:'none',paddingLeft:15, margin:'0% 1% 3%'}}
                  placeholder="Enter order details.."
                  value={this.state.description}
                  onChange={(e)=>this.changeDescription(e)}
                />
                {/* loading spinner */}
                <LoadingSpinner loading={this.state.loading} />
            </div>
        );
        return(
            <FModal
                state={this.props.show}
                title='Create Order'
                onClose={()=>this.props.closeHandler()}
                content={content}
                onSubmit={()=>this.makeCompensateOrder()}
            />
        );
    }
}
export default CreateOrderModel;
