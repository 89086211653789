import React from 'react'
import MultiSelect from "@kenshooui/react-multi-select"
import {Modal,ModalBody,ModalFooter,ModalHeader,Button} from 'reactstrap'
import FixersService from '../../../services/fixerServices/FixersServices'
import Services from './../../../services/Services'
import LoadingSpinner from '../../LoadingSpinner'
class AddFixersModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            loading:false,
            workContractId:'',
            fixers:[],
            selectedFixers:[],
        }
    }
    static getDerivedStateFromProps(props, state){
        state.workContractId=props.workContractId;
        state.fixers=props.fixers
        // console.log(state.workContractId+'from get drived')
        // console.log(state.fixers)
        return state
    }
    clear(){
        this.setState({workContractId:'',fixers:[],selectedFixers:[]})
    }
    addHandler(){
      if (this.validRequest()) {
        let arr=[]
        for (let i = 0; i < this.state.selectedFixers.length; i++) {
          arr.push(this.state.selectedFixers[i].id)
        }
        this.assignFixers(arr)
        console.log(arr);
      }
    }
    validRequest(){
        if(this.state.selectedFixers.length==0){
            alert('Please, select one or more fixer');
            return false
        }
        return true
    }
    assignFixers(arr){
      this.setState({loading:true})
      FixersService.assignFixersToWorkContract(this.state.workContractId,arr,(res)=>{
        console.log(res.data)
        this.setState({loading:false});
        this.clear()
        this.props.closeHandler()
      })
    }
    onChangeMultiSelect(itemSelected){
        let arr = []
        for (var i = 0; i < itemSelected.length; i++) {
          arr.push(itemSelected[i])
        }
        console.log(arr)
        this.setState({selectedFixers:arr})
      }
    modalCloseHandler(){
        this.clear()
        this.props.closeHandler()
    }
    render(){
        return(
            <>
              <Modal fade={false}
                isOpen={this.props.show}
                toggle={()=>this.modalCloseHandler()} className={this.props.className}>
                <ModalHeader toggle={()=>this.modalCloseHandler()}>Add Fixers</ModalHeader>
                <ModalBody>
                  <div className='approvedCondition' style={{width:'50%', paddingLeft:'2%',marginLeft:'auto',marginRight:'auto', direction:'rtl'}}>
                    {this.state.fixers?
                      <MultiSelect
                        items={this.state.fixers}
                        selectedItems={this.state.selectedFixers}
                        onChange={(item)=>this.onChangeMultiSelect(item)}
                        withGrouping={true}
                        showSelectedItems={false}
                        responsiveHeight={400}
                      />:'Sorry, there is no result'}

                  </div>

                </ModalBody>
                <ModalFooter>
                  <Button className='actionsBtn' onClick={()=>this.modalCloseHandler()}>Close</Button>
                    <Button className='actionsBtn' onClick={()=>this.addHandler()}>Add</Button>
                </ModalFooter>
            </Modal>
            <LoadingSpinner loading={this.state.loading}/>
            </>
        )
    }
}
export default AddFixersModal;
