import { Global } from './../../core/Global'
import Adaptor from './../Adaptor'


export default class PromocodeServices {

  static edit(id, data, callBack) {
    Adaptor.put(`${Global.baseURL}admin-panel/promo-codes/${id}`, data).then(callBack)
  }

  static get(id, callBack) {
    Adaptor.get(`${Global.baseURL}admin-panel/promo-codes/${id}`).then(callBack)
  }

  static getPromocodeOrders(promoId, page , callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/promo-codes/${promoId}/orders?page=${page}`).then(callBack)
  }

}
