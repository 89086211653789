import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Input , Table, Badge} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import OrdersService from './../../../services/orderServices/OrdersService'

export default class ConfirmPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step:1,
      otherAmount:'',
      invoice:this.props.order.lastInvoice != null ? this.props.order.lastInvoice : ''
    }
  }

  componentDidMount(){

  }

  payOrder(amount){
    this.setState({loading:true,})
    OrdersService.payOrder(this.props.order.orderId, {amount, method:'cash'},  (res)=>{
      this.setState({loading:false})
      if (res.message=='success') {
        this.props.reload()
      }else if (res.message=='forbidden') {
        alert("You don't have permission")
      }
    })
  }

  vaildOtherAmount(){
    if (!this.state.otherAmount.trim()) {
      alert('Enter other amount')
    }else if (this.state.otherAmount < this.state.invoice.collectedAmount) {
      alert('Enter bigger amount')
    }else {
      this.payOrder(this.state.otherAmount)
    }
  }

  render(){
    return(
      <div>
        {this.state.step==1?(
          <div>
            <p style={{textAlign:'center', fontSize:15, marginBottom:30}}>Are you sure you want to confirm order by  <span style={{fontSize:20 }}>{this.state.invoice.collectedAmount}</span> LE ?</p>
            <div className='toggleCheckbox' style={{justifyContent:'space-around', padding:'0px 200px'}}>
              <div className='btn' onClick={()=>this.setState({step:2,otherAmount:''})}>Add other Amout</div>
              <div className='btn' onClick={()=>this.payOrder(this.state.invoice.collectedAmount)}>Confirm Payment</div>
            </div>
          </div>
        ):(
          <div>
            <p style={{textAlign:'center', color:'#777', fontSize:15, marginBottom:5}}>Make sure that other amount bigger than current amount</p>

            <div style={{display:'flex', justifyContent:'center', marginBottom:25}}>
              <input
                placeholder="Enter other amount.."
                type="text"
                value={this.state.otherAmount}
                onChange={e => this.setState({ otherAmount: e.target.value })}
                onInput={e => { e.target.value = e.target.value.match(/^[0-9.]+$/); }}
                style={{ width: "40%", border:'1px solid #ededed', borderRadius:20, padding: '7px 15px'}}
              />
            </div>


            <div className='toggleCheckbox' style={{justifyContent:'space-around', padding:'0px 200px'}}>
              <div className='btn' onClick={()=>this.setState({step:1,otherAmount:''})}>Back</div>
              <div className='btn' onClick={()=>this.vaildOtherAmount()}>Submit</div>
            </div>
          </div>
        )}

        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
