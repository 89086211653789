import React from 'react'
import { Input , Row , Col , Modal , ModalBody , ModalHeader , ModalFooter , Button } from 'reactstrap'
import ChatService from '../../services/chatServices/ChatService';
import Services from '../../services/Services'
import moment from 'moment'
import { LoadingSpinner } from './../../components'

class SchedualeMessage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      date:'',
      time:'',
      loading:false
    }
  }

  onDateChange(e){
    this.setState({date:e.target.value})
  }

  onTimeChange(e){
    this.setState({time:e.target.value})
  }

  sendScedualeMessage(){
    if(this.state.date < new Date().toISOString().slice(0, 10)){
      alert("date must be after today's date")
    }else {
      this.setState({loading:true})
      let data = new FormData()
      data.append('type','text')
      data.append('body',this.props.message)
      var date = moment(this.state.date + ' ' + this.state.time, "YYYY-MM-DD HH:mm:ss.")
      data.append('scheduledAt',date._i)
      ChatService.sendSchedualeMessagesToCustomerChat(data,this.props.chat.id,(res)=>{
        this.setState({loading:false})
        if(res.message == 'success'){
          alert("Your Message scheduled Successfully ");
          this.props.onMessageScheduled();
          this.props.onClose();
        }else if(res.message == 'error'){
          for (const err in res.errors) {
            if (Object.hasOwnProperty.call(res.errors, err)) {
              const errMsg = res.errors[err];
              if(err == 'scheduledHour'){
                alert('Can’t schedule message between 9:00pm and 9:00am');
              }else{
                alert(errMsg)
              }
            }
          }
        }
      })
    }
  }

  render(){
    return(
      <Modal isOpen={true} toggle={()=>this.props.onClose()}>
        <ModalHeader toggle={()=>this.props.onClose()}>Schedule Message</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6">
              <Input onChange={(e)=>this.onDateChange(e)} value={this.state.date} type="date" id="dateScheduale" name="dateScheduale"/>
            </Col>
            <Col md="6">
              <Input onChange={(e)=>this.onTimeChange(e)} value={this.state.time} type="time" id="timeScheduale" name="timeScheduale"/>
            </Col>
            <LoadingSpinner fade loading={this.state.loading}/>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn btnClose' onClick={()=>this.props.onClose()}>Close</Button>
          <Button className='actionsBtn' onClick={()=>this.sendScedualeMessage()}>Confirm</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default SchedualeMessage;
