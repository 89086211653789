import React,{Fragment} from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
import Services from './../../services/Services';
import MultiSelect from "@kenshooui/react-multi-select"
import { LoadingSpinner } from './../../components'
import ServiceGroupFilter from './../Filters/ServiceGroupFilter'

export default class PromotedServiceGroup extends React.Component{
    constructor(props){
        super(props)
        this.state={
            serviceGroupId:'',
            selectedServiceGroup:'ServiceGroup',
            loading:false,
            image:'',
            imagePreview:''

        }
    }

  componentDidMount(){
  }

      handlerServiceGroup=(item)=>{
      if (item.enName == 'Choose') {
        this.setState({selectedServiceGroup : "ServiceGroup" , serviceGroupId:''})
      }else if(item.enName == 'All'){
       alert('Please , you can not choose all')
      }else{
        this.setState({selectedServiceGroup : item.enName , serviceGroupId:item.id})
      }
  }

    onSelectImageFromPC = (e, handle) => {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({image: file,imagePreview:reader.result})
      }
      reader.readAsDataURL(file)

  }

    getData(type){
        console.log('test')
      if(this.state.serviceGroupId == ''){
        alert('Please, select service group')
      }else if(this.state.image == ''){
        alert('Please, choose image')
      }else if(type == '' || type.trim() == ''){
        alert('Please, enter type')
      }else{
        let formData = new FormData();
        formData.append('type',type);
        formData.append('id',this.state.serviceGroupId);
        formData.append('additionalData[image]',this.state.image)
        this.props.createPost(formData)
      }
  }



  render(){
    return(
      <div>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Service Groups :</Label>
          </Col>
          <Col md="4">
            <ServiceGroupFilter bc="#dddddd" bw={1} bgColor="white" handler={this.handlerServiceGroup}/>
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Images :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              onChange={(e)=>this.onSelectImageFromPC(e, 'arName')}
              name="uploadfile"
              id="img"
              style={{display:'none'}}
              accept="image/x-png,image/jpeg"
            />
            <Label for="img" style={{cursor:'pointer',width:'100%',backgroundColor:'#4dbd74',borderRadius:5,textAlign:'center',padding:7}}>{this.state.image == '' ? 'Upload Image' : this.state.image.name}</Label>
          </Col>
          <Col md='6'>
            <p><strong>Note: </strong>when insert image must the width double the length or upload this dimensions 362*138</p>
          </Col>
        </Row>
        {this.state.imagePreview != '' ? (
          <Row style={{marginTop:15}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview :</Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
          </Col>
        </Row>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
           </div>
        )
  }
}
