import { Global } from "../../core/Global"
import Adaptor from './../Adaptor'


export default class WorkContractService{

  static getSingleWorkContract(workContractId , callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/work-contracts/${workContractId}`).then(callBack)
  }

  static toggleRenewable(workContractId, status, callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/work-contracts/${workContractId}/isRenewable`,{}).then(callBack)
  }

  static getFixerHistory( fwcId , callBack ){
    Adaptor.get(`${Global.baseURL}admin-panel/fixer-work-contracts/${fwcId}/history`).then(callBack)
  }

}
