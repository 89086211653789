import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class InspectedDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
       singleOrder : this.props.order
    }
  }

  componentDidMount(){

  }

  
  render(){
    return(
      <div>
          <div>
          { this.state.singleOrder?this.state.singleOrder.status.id?
                this.renderInspectionDetails():null:null}
          </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }


  renderInspectionDetails(){
    return(
      <div className="assignOrdersToAgentContainer">
        {
          this.state.singleOrder.status.id == 31 ? (
            <div>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Inspection Reason: <span style={{fontWeight:'bold'}}>{this.state.singleOrder.finalClosingAction?this.state.singleOrder.finalClosingAction.reason.arName:null} </span></p>
              {this.state.singleOrder.finalClosingAction?this.state.singleOrder.finalClosingAction.items.length!=0?(
                <>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Items :{
                  this.state.singleOrder.finalClosingAction.items.map((item,index) => (
                    <div key={index}>
                      -name  :{item.arName}
                      -quantity:{item.quantity}
                    </div>
                  ))
                }</p>
                <div>
                  {this.state.singleOrder.finalClosingAction.inspectionEstimation?(
                    <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Range Price from {this.state.singleOrder.finalClosingAction.inspectionEstimation.least} to {
                      this.state.singleOrder.finalClosingAction.inspectionEstimation.highest
                    } L.E </p>
                  ):null}
                </div>
              </>
            ):(
              <>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>inspection details : {this.state.singleOrder.finalClosingAction.details.fixingDescription}</p>
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Price : {this.state.singleOrder.finalClosingAction.details.fixingPrice}
              L.E
            </p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Time : {this.state.singleOrder.finalClosingAction.details.time} Min</p>
            <Row>
              {this.state.singleOrder.inspectionImages?this.state.singleOrder.inspectionImages.map((img)=>(
                <div style={{padding:15}}>
                  <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Images:</span></p>
                  <Col key={img.id} md="4" style={{marginBottom:8}}>
                    <img className='shadow' src={img.image} style={{width:'100%', height:200,}} />
                  </Col>
                </div>
              )):null}
            </Row>
          </>
        ):null}
      </div>
    ) : null
  }

</div>
)
}
}
