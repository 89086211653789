import { Component } from 'react'
import { writeToCache , readFromCache } from './../core/helper';
import { Global } from './../core/Global'
import { buildQueryStringFromArray } from '../core/helper'
import Adaptor from './Adaptor'

export default class Services extends Component {

    /**
    --  get requests
    **/
    static getOrders(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getSingleOrder(order, callBack) {
        let orderId = order
        if (typeof order == "object") orderId = order.id
        Adaptor.get(`${Global.baseURL}admin-panel/orders/${orderId}`).then(callBack)
    }

    static viewOrderHistory(id, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/orders/${id}/history`).then(callBack)
    }

    static getRedirectedFixers(id, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/orders/${id}/redirectedFixers`).then(callBack)
    }

    static getSuggestedFixers(categoryId, cityId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/order-fixers?categoryId=${categoryId}&cityId=${cityId}`).then(callBack)
    }

    static getCancelReasons(reasonTypeIds, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/reasons?reasonTypeIds=${reasonTypeIds}&usedBy=back_office`).then(callBack)
    }

    static reopenOrder(orderId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/orders/${orderId}/reopen`).then(callBack)
    }

    static getCustomers(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getFixers(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getSingleCustomer(customerId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/customers/${customerId}`).then(callBack)
    }


    static getServicesGroup(categoryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/service-groups?categoryId=${categoryId}`).then(callBack)
    }


    static getCustomerAddress(customerId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/users/${customerId}/addresses`).then(callBack)
    }

    static getCities(callBack) {
        Adaptor.get(`${Global.baseURL}cities`).then(callBack)
    }

    static getArea(cities, callBack) {
        let citiesQuery = '';
        if (typeof cities === "number") {
            citiesQuery = `cities[0]=${cities}`
        } else if (Array.isArray(cities)) {
            if (cities.length != 0 && !(cities[0].id == 'all')) {
                citiesQuery = buildQueryStringFromArray('cities', cities)
            }
        }
        Adaptor.get(`${Global.baseURL}admin-panel/areas?${citiesQuery}`).then(callBack)
    }

    static getCategories(callBack) {
        Adaptor.get(`${Global.baseURL}categories`).then(callBack)
    }


    static getCategoriesThatBelongToSpecificArea(id, callBack) {
        Adaptor.get(`${Global.baseURL}areas/${id}/categories`).then(callBack)
    }

    static getStatuses(type, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/statuses?statusType=${type}`).then(callBack)
    }

    static getReasons(get, callBack) {
        let api = ''
        if (get === 'customer') {
            api = `${Global.baseURL}admin-panel/reasons?reasonTypeIds=7&usedBy=back_office`
        } else if (get === 'fixer') {
            api = `${Global.baseURL}admin-panel/reasons?reasonTypeIds=6&usedBy=back_office`
        }
        Adaptor.get(api).then(callBack)
    }

    static getAllServiceGroups(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/service-groups`).then(callBack)
    }

    static getFeaturedServiceGroups(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/featured-service-groups`).then(callBack)
    }

    static getAllPackages(page, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/packages?page=${page}`).then(callBack)
    }

    static getCategoriesDependCity(cityId, serviceGroups, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/categories?city=${cityId}&serviceGroups=${serviceGroups}`).then(callBack)
    }

    static getAllAdmins(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/admins`).then(callBack)
    }

    static getWorkContracts(page, options, callBack) {
        let api = `${Global.baseURL}admin-panel/work-contracts?page=${page}`
        if (options) {
            if (options.cityId && options.cityId != 'all') api += `&cityId=${options.cityId}`
            if (options.categoryId && options.categoryId != 'all') api += `&categoryId=${options.categoryId}`
            if (options.startDate) api += `&startDate=${options.startDate}`
            if (options.endDate) api += `&endDate=${options.endDate}`
            if (options.searchTerm) api += `&searchTerm=${options.searchTerm}`
        }
        Adaptor.get(api).then(callBack)
    }

    static getAllCriteria(userTypeId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/criteria?userTypes=${userTypeId}`).then(callBack)
    }

    static getAllCriteriaHistory(id, page, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/users/${id}/feedback?page=${page}`).then(callBack)
    }

    static getEntriesSingleFixer(order, page, accountId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/entries?order=${order}&page=${page}&accountId=@${accountId}`).then(callBack)
    }

    static getServiceGroupsDependOnCategories(categoryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/service-groups?categoryId=${categoryId}`).then(callBack)
    }

    static getAllParents(categoryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/pricing-items/parents?categoryId=${categoryId}`).then(callBack)
    }

    static unBlock(userId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/users/${userId}/unblock`).then(callBack)
    }

    static getSingleFixer(id, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/fixers/${id}`).then(callBack)
    }

    static getFixawyPricingItems(cityId, categoryId, page, callBack) {
        let api = ''
        if (cityId == '') {
            api = `${Global.baseURL}pricing-items?categoryId=${categoryId}&type=complexity&page=${page}`
        } else {
            api = `${Global.baseURL}pricing-items?cityId=${cityId}&categoryId=${categoryId}&type=complexity&page=${page}`
        }
        Adaptor.get(api).then(callBack)
    }

    static getMeterAndQuantityItems(page, categoryId, cityId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/pricing-items?page=${page}&cityId=${cityId}&categoryId=${categoryId}&type=meter_and_quantity`).then(callBack)
    }

    static getAddress(userId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/users/${userId}/addresses`).then(callBack)
    }

    static getReport(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getPromos(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getAvaliableFixer(categoryId, cityId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/order-fixers?categoryId=${categoryId}&cityId=${cityId}`).then(callBack)
    }

    static getAvaliableDays(areaId, fixerId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/fixers/${fixerId}/available-days?areaId=${areaId}`).then(callBack)
    }

    static getComplain(complainId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/complaints/${complainId}`).then(callBack)
    }

    static getComplainStatus(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/statuses?statusType=complaint`).then(callBack)
    }

    static getUnAssignedAreas(fixerId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/fixers/${fixerId}/areas?type=un-assigned`).then(callBack)
    }

    static synchronize(board, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/synchronize?board=${board}`).then(callBack)
    }

    static logOut(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/admins/logout`).then(callBack)
    }

    static getContactMsg(pageId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/contact-us?page=${pageId}`).then(callBack)
    }

    static getTrelloBords(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/trello/boards`).then(callBack)
    }

    static getAgents(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/admins`).then(callBack)
    }

    static assignOrderToAgent(orderId, adminId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/orders/${orderId}/admins/${adminId}/assign`).then(callBack)
    }

    static getConditions(typeId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/conditions-and-terms?type=${typeId}`).then(callBack)
    }

    static getReportReasons(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getFixerWithPagination(page, categoryId, cityId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/fixers?page=${page}&category=${categoryId}&city=${cityId}&status=11`).then(callBack)
    }

    static getAdminAccount(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/profile`).then(callBack)
    }

    static getReservationRequests(withProfit, statusId, options, page, callBack) {
        let api = `${Global.baseURL}admin-panel/work-contracts-reservations?statusId=${statusId}&page=${page}`
        if (withProfit != -1) api += `&withProfit=${withProfit}`
        if (options) {
            if (options.searchTerm) api += `&searchTerm=${options.searchTerm}`
            if (options.cityId && options.cityId != 'all') api += `&cityId=${options.cityId}`
            if (options.categoryId && options.categoryId != 'all') api += `&categoryId=${options.categoryId}`
            if (options.startDate) api += `&startDate=${options.startDate}`
            if (options.endDate) api += `&endDate=${options.endDate}`
        }
        Adaptor.get(api).then(callBack)
    }

    static getApprovedResevationData(isExpired, options, page, callBack) {
        let api = `${Global.baseURL}admin-panel/approved-reservations?expired=${isExpired}&page=${page}`
        if (options) {
            if (options.searchTerm) api += `&searchTerm=${options.searchTerm}`
            if (options.cityId && options.cityId != 'all') api += `&cityId=${options.cityId}`
            if (options.categoryId && options.categoryId != 'all') api += `&categoryId=${options.categoryId}`
            if (options.startDate) api += `&startDate=${options.startDate}`
            if (options.endDate) api += `&endDate=${options.endDate}`
        }
        Adaptor.get(api).then(callBack)
    }

    static getViolations(approvedReservationId, pageId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/approved-reservations/${approvedReservationId}/violations?page=${pageId}`).then(callBack)
    }

    static getConditionsForPackage(id, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/packages/${id}/conditions`).then(callBack)
    }

    static getInquiries(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getServiceGroupRelatedToSpecificCategory(categoryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/service-groups?categoryId=${categoryId}`).then(callBack)
    }

    static getQuotations(page, inquiryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/inquiries/${inquiryId}/quotations?page=${page}`).then(callBack)
    }

    static convertToOrderCountInInquiry(status, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/inquiries/count?status=${status}`).then(callBack)
    }

    static checkReservationResquestAvailabilty(reservationId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/work-contracts-reservations/${reservationId}/availability`).then(callBack)
    }

    static actionTerminationForReservationRequest(reservationId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/approved-reservations/${reservationId}/terminate`).then(callBack)
    }

    static getReasonsForClosingInspection(callBack) {
        Adaptor.get(`${Global.baseURL}reasons?reasonTypeIds=9&usedBy=fixer,back_office`).then(callBack)
    }

    static getFixersInPendingDispatch(categoryId, cityId, requestedDate, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/order-fixers?categoryId=${categoryId}&cityId=${cityId}&requestedDate=${requestedDate}`).then(callBack)
    }

    static getVideoLink(orderId, callBack) {
        Adaptor.get(`${Global.baseURL}orders/${orderId}/video-link`).then(callBack)
    }

    static getPostsTypes(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/homepage-section-types`).then(callBack)
    }

    static getCategoriesForServiceGroups(serviceGroups, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/categories?serviceGroups=${serviceGroups}`).then(callBack)
    }

    static fetchKeywords(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/keywords`).then(callBack)
    }

    static getPosts(page, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/homepage-sections?page=${page}`).then(callBack)
    }

    static getSectionDetails(sectionTypeId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/homepage-sections/${sectionTypeId}`).then(callBack)
    }
    static getInquiryHistory(inquiryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/inquiries/${inquiryId}/history`).then(callBack)
    }

    static getUserNotifications(page, callBack) {
        Adaptor.get(`${Global.baseURL}notifications?page=${page}`).then(callBack)
    }

    static getOffers(cityId, categoryId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/offers?cityId=${cityId}&categoryId=${categoryId}`).then(callBack)
    }

    static getValidItems(page, query, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/pricing-items/valid?page=${page}${query}`).then(callBack)
    }

    static getChats(page, type, searchTerm, callBack) {
        let api = `${Global.baseURL}chats/?page=${page}`;
        if (type != null) api += `&type=${type}`;
        if (searchTerm != null) api += `&searchTerm=${searchTerm}`;

        Adaptor.get(api).then(callBack)
    }

    static chatCustomer(page, callBack) {
        Adaptor.get(`${Global.baseURL}chats?page=${page}`).then(callBack)
    }

    static getChatMessages(page, chatId, callBack) {
        Adaptor.get(`${Global.baseURL}chats/${chatId}?page=${page}`).then(callBack)
    }

    static getMessages(chatId, page, callBack) {
        Adaptor.get(`${Global.baseURL}chats/${chatId}?page=${page}`).then(callBack)
    }

    static getOrderChats(orderId, callBack) {
        Adaptor.get(`${Global.baseURL}chats/order/${orderId}`).then(callBack)
    }

    static getFixerHistory(fixerId, page, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/fixers/${fixerId}/history?page=${page}`).then(callBack)
    }

    static getChatMessagesAdminPanel(page, chatId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/chats/${chatId}?page=${page}`).then(callBack)
    }

    static getOrdersApprovedReservations(id, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/approved-reservations/${id}/orders`).then(callBack)
    }

    static getChatSnippits(page, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/chats/snippit?page=${page}`).then(callBack)
    }

    static getBookingDays(callBack) {
        Adaptor.get(`${Global.baseURL}customers/booking-days`).then(callBack)
    }

    static getUserPointsDetails(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/admins/points/details`).then(callBack)
    }

    static getAdminsPoints(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/admins/all-admins-points`).then(callBack)
    }

    static getCompensationOnOrder(complainId, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/complaints/${complainId}/compensate`).then(callBack)
    }

    static getAllBlogs(page, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/articles?page=${page}`).then(callBack)
    }

    static getBlog(id, callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/articles/${id}`).then(callBack)
    }

    static getComplaints(api, callBack) {
        Adaptor.get(api).then(callBack)
    }

    static getComplainTags(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/tags?tagTypeId=2`).then(callBack)
    }

    static getUserPoints(callBack) {
        Adaptor.get(`${Global.baseURL}admin-panel/admins/points`).then(callBack)
    }

    static searchSnippit(searchTerm, callBack) {
      let api = `${Global.baseURL}admin-panel/chats/snippit?searchTerm=${searchTerm}`
      Adaptor.get(api).then((result)=> {
        callBack(result)
        writeToCache(api,result)
      },
      (error) => {
        const result = readFromCache(api);
        callBack(result)
      })
    }






    /**
    --  post requests
    **/
    static refreshToken(callBack) {
        Adaptor.post(`${Global.baseURL}token/refresh`, { refreshToken: `${JSON.parse(localStorage.getItem('userData')).refreshToken}` }).then(callBack)
    }

    static login(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/admins/login`, data).then(callBack)
    }

    static confirmOrder(data, id, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${id}/confirm`, data).then(callBack)
    }

    static submitCancelOrder(reasonId, orderId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/cancel`, { reasonId: reasonId }).then(callBack)
    }

    static updateOrderTimingDetails(id, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${id}`, data).then(callBack)
    }

    static updateOrderTimingDetailsWithImage(id, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${id}`, data).then(callBack)
    }

    static sendSMS(msg, userId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/users/${userId}/sendSms`, { sms: msg }).then(callBack)
    }

    static sendPush(data, userId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/users/${userId}/sendPushNotification`, data).then(callBack)
    }

    static block(userId, blockReasonId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/users/${userId}/block`, { reasonId: blockReasonId }).then(callBack)
    }

    static pricingOrder(data, orderId, type, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/close?type=${type}`, data).then(callBack)
    }

    static closeVideoOrder(orderId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/close`, {}).then(callBack)
    }

    static estimatingPrice(data, orderId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/estimate`, data).then(callBack)
    }

    static addPromo(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/promo-codes`, data).then(callBack)
    }

    static addAddress(data, userId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/users/${userId}/addresses`, data).then(callBack)
    }

    static makeOrder(data, customerId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/customers/${customerId}/orders`, data).then(callBack)
    }

    static submitRedirect(orderId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/redirect`, data).then(callBack)
    }

    static assignToNewArea(fixerId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/fixers/${fixerId}/areas`, data).then(callBack)
    }

    static addAdmin(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/admins`, data).then(callBack)
    }

    static addNote(orderId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/notes`, data).then(callBack)
    }

    static addConditions(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/conditions-and-terms`, data).then(callBack)
    }

    static updateSeviceGroup(serviceGroupId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/service-groups/${serviceGroupId}/update`, data).then(callBack)
    }

    static uplaodServiceGroupImage(serviceGroupId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/service-groups/${serviceGroupId}/upload-image`, data).then(callBack)
    }

    static addPackages(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/packages`, data).then(callBack)
    }

    static addWorkingContract(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/work-contracts`, data).then(callBack)
    }

    static addFeedBack(userId, criteria, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/feedback`, { userId: userId, criteria: criteria }).then(callBack)
    }

    static addPriceItem(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/pricing-items`, data).then(callBack)
    }

    static assignOrderOnApprovedReservation(orderId, id, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/approved-reservations/${id}/orders`, { orderId: orderId }).then(callBack)
    }


    static addViolationOnApprovedReservation(conditions, id, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/approved-reservations/${id}/violations`, { conditions: conditions }).then(callBack)
    }

    static activeFixer(data, id, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/fixers/${id}/activate`, data).then(callBack)
    }

    static addVideoLink(orderId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/add-video-link`, data).then(callBack)
    }

    static getPromotedServiceGroup(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/promoted-service-group`, data).then(callBack)
    }

    static createHomeSection(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/homepage-sections`, data).then(callBack)
    }

    static creatPackage(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/keywords-packages`, data).then(callBack)
    }

    static creatOfferPost(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/offers`, data).then(callBack)
    }

    static addBackground(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/background`, data).then(callBack)
    }

    static changeFixerImage(data, fixerId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/fixers/${fixerId}/image`, data).then(callBack)
    }

    static addNoteInquiry(data, inquiryId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/inquiries/${inquiryId}/notes`, data).then(callBack)
    }

    static sendNotificationToFixers(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/fixers/notify`, data).then(callBack)
    }

    static sendChatMessage(data, chatId, callBack) {
        Adaptor.post(`${Global.baseURL}chats/${chatId}/message`, data).then(callBack)
    }

    static sendMessagesToCustomerChat(data, chatId, callBack) {
        Adaptor.post(`${Global.baseURL}chats/${chatId}/message`, data).then(callBack)
    }

    static seenChatMessage(chatId, callBack) {
        Adaptor.post(`${Global.baseURL}chats/${chatId}/seen`, {}).then(callBack)
    }

    static startChat(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/chats`, data).then(callBack)
    }

    static suspendReOpenChat(id, data, action, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/chats/${id}/${action}`, data).then(callBack)
    }

    static addSnippits(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/chats/snippit`, data).then(callBack)
    }

    static createCompensateOrder(complainId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/complaints/${complainId}/order`, data).then(callBack)
    }

    static addBlog(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/articles`, data).then(callBack)
    }

    static createComplaints(data, orderId, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/complaints`, data).then(callBack)
    }

    static getPriceEstimation(data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/estimate-price`, data).then(callBack)
    }

    static compensateOrder(complainId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/complaints/${complainId}/compensate`, data).then(callBack)
    }

    static addNoteForComplaint(complaintId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/complaints/${complaintId}/notes`, data).then(callBack)
    }

    static assignOrderToAgents(orderId, data, callBack) {
        Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/admins`, data).then(callBack)
    }



    /**
    --  put requests
    **/
    static updateTagsComplaint(id, data, callBack) {
        Adaptor.put(`${Global.baseURL}complaints/${id}/update`, data).then(callBack)
    }

    static assignComplaintToAdmin(id, data, callBack) {
        Adaptor.put(`${Global.baseURL}admin-panel/complaints/${id}/update`, data).then(callBack)
    }

    static changePaymentMethod(data, id, callBack) {
        Adaptor.put(`${Global.baseURL}admin-panel/orders/${id}/setPaymentMethod`, data).then(callBack)
    }

    static seenNotification(id, callBack) {
        Adaptor.put(`${Global.baseURL}notifications/${id}/update`, {}).then(callBack)
    }

    static changeCategory(data, id, callBack) {
        Adaptor.put(`${Global.baseURL}admin-panel/orders/${id}/category`, data).then(callBack)
    }

    static changeWorkContractOpenForReservationStatus(workContractId, status, callBack) {
        status = status ? 1 : 0
        Adaptor.put(`${Global.baseURL}admin-panel/work-contracts/${workContractId}/change-availability?isOpenForReservation=${status}`, {}).then(callBack)
    }

    static sendReply(data, id, callBack) {
        Adaptor.put(`${Global.baseURL}admin-panel/complaints/${id}/update`, data).then(callBack)
    }


    /**
    --  patch requests
    **/
    static assignFixerInPendingDispatch(orderId, data, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/orders/${orderId}/fixer`, data).then(callBack)
    }

    static changeStatusToRepeated(orderId, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/orders/${orderId}/status`, { status: 'repeated' }).then(callBack)
    }

    static changePostStatus(sectionId, status, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/homepage-sections/${sectionId}/${status}`, {}).then(callBack)
    }

    static changePosition(sectionId, position, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/homepage-sections/${sectionId}/position?position=${position}`, {}).then(callBack)
    }

    static changePassword(data, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/password`, data).then(callBack)
    }

    static changeReservationRequestsStatus(contractId, statusId, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/work-contracts-reservations/${contractId}/status`, { statusId: statusId }).then(callBack)
    }

    static changeStatusInquiry(data, inquiryId, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/inquiries/${inquiryId}/status`, data).then(callBack)
    }

    static editFixerData(fixerId, data, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/fixers/${fixerId}`, data).then(callBack)
    }

    static changeStatusForBlog(articleId, callBack) {
        Adaptor.patch(`${Global.baseURL}admin-panel/articles/${articleId}/toggle`, {}).then(callBack)
    }

}
