import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class TasksService {

    static add(data,callBack){
        Adaptor.post(`${Global.baseURL}admin-panel/tasks`, data).then(callBack)
    }

    static get(page,data,callBack){
        let api =`${Global.baseURL}admin-panel/tasks?page=${page}`;
        if(data['categoryId']) api += `&categoryId=${data.categoryId}`
        Adaptor.get(api).then(callBack)
    }

    static getCost(cityId,data,callBack){
      let api = `${Global.baseURL}admin-panel/tasks/calculate?cityId=${cityId}`;
      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        api += `&taskIds[${i}][id]=${e.id}&taskIds[${i}][quantity]=${e.count}`;
      }
      Adaptor.get(api).then(callBack)
    }

}
