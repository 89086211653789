import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Table} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
        orderHistory:[],

    }
  }

  componentDidMount(){
    this.viewOrderHistory()
  }


  viewOrderHistory(){
    this.setState({loading:true})
    Services.viewOrderHistory( this.props.order.orderId, (res)=>{
      this.setState({loading:false, orderHistory:res.data.orderActions, modalHistory:true},()=>console.log(res))
    })
  }


  render(){
    return(
      <div>
        <Row>
        <Col md='1'/>
        <Col md="10">
          <Table hover bordered striped responsive size="md">
            <thead>
              <tr>
                <th style={{textAlign:'center'}} width='15%'>Name</th>
                <th style={{textAlign:'center'}} width='25%'>Actions</th>
                <th style={{textAlign:'center'}} width='35%'>Description</th>
                <th style={{textAlign:'center'}} width='25%'>Date and Time</th>
              </tr>
            </thead>
            <tbody>
              {this.state.orderHistory.map((item, index)=>(
                <tr key={index}>
                  <td style={{textAlign:'center'}}>{item.actionMaker.name}</td>
                  <td style={{textAlign:'center'}}>{item.action.action}</td>
                  <td style={{textAlign:'center'}}>{item.description}</td>
                  <td style={{textAlign:'center'}}>{item.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col md='1'/>
      </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
