import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Dropdown ,DropdownItem ,DropdownMenu ,DropdownToggle ,Modal ,ModalBody ,ModalHeader,ModalFooter, UncontrolledDropdown } from 'reactstrap';
import Src from './../../assets/img/brand/download.png'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import { FModal , PushNotification } from './../../components'

import SingleFixer from './SingleFixer'

class Fixers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixers:[],
      cities:[],
      categories:[],
      statuses:[],
      selectedCity:'City',
      selectedCityId:'',
      selectedCategory:'Category',
      selectedCategoryId:'',
      selectedStatus:'Status',
      selectedStatusId:'',
      singleFixer:'',
      searchTerm:'',
      selectedActiveStatus:'Activation Status',
      selectedActiveStatusId:'',
      paginationInfo:[],
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      loading:false,
      showSingleFixer:false,
      noResult:false,
      currentPage:1,
      currentApi:'',
      activationStatus:Global.activationStatus,
      // noResult:true,
      searchText:'',
      selectedFixers:[],
      exceptedFixers:[],
      showNotificationModal:false,
      selectAllFixers:false,
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }
  componentDidMount(){
    this.getCities()
    window.scrollTo(0, 0)
  }
  reload = () => {
    this.setState({showSingleFixer:false,selectedFixers:[],exceptedFixers:[],selectAllFixers:false,})
    this.getFixers()
  }
  handleChangeInputs = (e) => {
    this.setState({searchTerm:e.target.value})
  }
  handleKeyPress(target) {
    if(target.charCode==13){
      this.getFixers()
    }
  }
  showSingleFixer(item){
    console.log('clckk');
    this.setState({showSingleFixer:true, singleFixer:item})
  }

  selectFixerForNotification(e,item){
    if(this.state.selectAllFixers){
      if (e.target.checked) {
        this.state.exceptedFixers = this.state.exceptedFixers.filter( fixer => fixer.id != item.id )
      }else{
        this.state.exceptedFixers.push(item)
      }
    }else{
      if(e.target.checked){
        this.state.selectedFixers.push(item);
      }else{
       this.state.selectedFixers = this.state.selectedFixers.filter( fixer => fixer.id != item.id)
      }
    }
    this.forceUpdate()
    console.log('fixers',this.state.selectedFixers)
    console.log('expeFixers',this.state.exceptedFixers)
  }

  isSelectedFixer(item){
    if(this.state.selectAllFixers){
      return (!this.state.exceptedFixers.find( fixer => fixer.id == item.id )) ? true : false ;
    }else{
      return this.state.selectedFixers.find( fixer => fixer.id == item.id ) ? true : false;
    }
  }

  disablePushNotification(){
    if (this.state.selectAllFixers) {
      return false
    }
    return !this.state.selectedFixers.length
  }

  selectAllFixers(e){
    this.setState({selectAllFixers:e.target.checked,selectedFixers:[],exceptedFixers:[]})
  }

  showNotificationModal(){
    this.setState({showNotificationModal:true})
  }

  closeNotificationModal(){
    this.setState({showNotificationModal:false})
  }

  sendNotificationToSelectedFixers(){
    let data = {
      fixers:[],
      exceptedFixers:[],
    };

    data.forAll = this.state.selectAllFixers
    data.title = this.pushNotificationRef.getTitle();
    data.body = this.pushNotificationRef.getBody();

    if (this.state.selectAllFixers) {
      this.state.exceptedFixers.map(fixer => data.exceptedFixers.push(fixer.id))
      if(this.state.selectedCityId) {
        data.city = this.state.selectedCityId ;
      }
      if(this.state.selectedCategoryId) {
        data.category = this.state.selectedCategoryId ;
      }
      if(this.state.selectedStatusId) {
        data.status = this.state.selectedStatusId ;
      }
      if(this.state.selectedActiveStatusId) {
        data.blocked = this.state.selectedActiveStatusId ;
      }
    }else{
      this.state.selectedFixers.map(fixer => data.fixers.push(fixer.id))
    }
    if(this.validNotification(data)){
      this.notify(data);
    }
  }

  validNotification(data){
    if(data.title == ''){
      alert('please, add notification title')
      return false
    }
    if(data.body == ''){
      alert('please, add notification body')
      return false
    }

    return true
  }

  notify(data){
    this.setState({loading:true})
    Services.sendNotificationToFixers(data,(res)=>{
      this.setState({loading:false,selectAllFixers:false,selectedFixers:[],exceptedFixers:[]},()=>this.closeNotificationModal())
    })
  }

  getCities(){
    console.log(JSON.parse(localStorage.getItem('userData')).accessToken);
    this.setState({loading:true})
    Services.getCities((res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.cities
      arr.push(obj)
      arr.unshift(choose)
      this.setState({cities:arr},()=>this.getCategories())
    })
  }

  getCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}

      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr},()=>this.getStatuses())
    })
  }

  getStatuses(){
    Services.getStatuses('fixer',(res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.statuses
      arr.push(obj)
      arr.unshift(choose)
      this.setState({statuses:arr},()=>this.getFixers())
    })
  }

  getFixers(){
    let api = this.filter()
    this.setState({loading:true})
    Services.getFixers(api, (res)=>{
      this.setState({
        loading:false,
        fixers:res.data.fixers,
        paginationInfo:res.data.paginationInfo,
        paginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        noResult:res.data.fixers.length!=0?false:true,
        searchText:this.state.searchTerm!=''?this.state.searchTerm:''
      })
    })
  }

  resetFilter(){
    this.setState({
        selectedCity:'City',
        selectedCityId:'',
        selectedCategory:'Category',
        selectedCategoryId:'',
        selectedStatus:'Status',
        selectedStatusId:'',
        selectedActiveStatus:'Activation Status',
        selectedActiveStatusId:'',
      },()=>this.getFixers())
  }


  filter(){
    let api = `${Global.baseURL}admin-panel/fixers?page=${this.state.currentPage}`

    if (this.state.selectedCityId != '') {
      api+=`&city=${this.state.selectedCityId}`
    }
    if (this.state.selectedCategoryId != '') {
      api+=`&category=${this.state.selectedCategoryId}`
    }
    if (this.state.selectedStatusId != '') {
      api+=`&status=${this.state.selectedStatusId}`
    }
    if (this.state.selectedActiveStatusId != '') {
      api+=`&blocked=${this.state.selectedActiveStatusId}`
    }
    if (this.state.searchTerm != '') {
      this.setState({currentPage:1})
      api+=`&searchTerm=${this.state.searchTerm}`
    }
    return api
  }
  prevPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page-1},()=>this.getFixers())
    }else {
      this.setState({currentPage: item},()=>this.getFixers())
    }
  }
  nextPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page+1},()=>this.getFixers())
    }else {
      this.setState({currentPage: item},()=>this.getFixers())
    }
  }

  handleMenus(menu, item){
    switch (menu) {
      case 'city':
      if (item.id == 'choose') {
        this.setState({selectedCity: 'City', selectedCityId: ''})
      }else {
        this.setState({selectedCity: item.enName, selectedCityId: item.id})
      }
      break
      case 'category':
      if (item.id == 'choose') {
        this.setState({selectedCategory: 'Category', selectedCategoryId: ''})
      }else {
        this.setState({selectedCategory: item.enName, selectedCategoryId: item.id})
      }
      break
      case 'statuses':
      if (item.id == 'choose') {
        this.setState({selectedStatus: 'Status', selectedStatusId: ''})
      }else {
        this.setState({selectedStatus: item.enName, selectedStatusId: item.id})
      }
      break
      case 'activation':
      if (item.id == 'choose') {
        this.setState({selectedActiveStatus: 'Activation Status', selectedActiveStatusId: ''})
      }else {
        this.setState({selectedActiveStatus: item.enName, selectedActiveStatusId: item.id})
      }
      break
      default:
    }
  }


  render() {
    return (
      <div className="app align-items-center">
        <Container>
          {this.renderFilter()}
          <Card>
            {this.renderSearch()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderFixers()}
                {this.renderPagination()}
                {this.state.showSingleFixer?<SingleFixer data={this.state.singleFixer} reload={this.reload}/>:null}
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}

          </Card>

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
        {this.state.showNotificationModal?(
          <FModal state={this.state.showNotificationModal}
                  title ='Notifiy Fixers'
                  content={
                    <PushNotification ref={(ref)=> this.pushNotificationRef = ref} />
                  }
                  onClose  ={()=>this.closeNotificationModal()}
                  onSubmit ={()=>this.sendNotificationToSelectedFixers()}
                   />
        ):null}
      </div>
    )
  }


  renderFilter(){
    return(
      <Row>
        <UncontrolledDropdown  className="mb-3">
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />{this.state.selectedCity}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.cities.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('city',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown  className="mb-3" style={{marginLeft:15}} >
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />{this.state.selectedCategory}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.categories.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('category',item)}>{item.enName}</DropdownItem>
            )
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown  className="mb-3" style={{marginLeft:15}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />{this.state.selectedStatus}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.statuses.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('statuses',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown  className="mb-3" style={{marginLeft:15}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-hand-paper' style={{marginRight:10}} />{this.state.selectedActiveStatus}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.activationStatus.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('activation',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <Col md={4}>
          <Button onClick={()=>this.getFixers()} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
          <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
        </Col>


      </Row>
    )
  }
  renderSearch(){
    return(
    <>
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Fixers</span>
          </Col>

          <Col md="4">
            <InputGroup>

              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e)}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By id, name and phone.."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.getFixers()} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardHeader className='shadow'>
        <Row style={{marginLeft:'5px',display:'flex',justifyContent:'space-between',alignItems:'baseline'}}>
          <Col md='3'>
            <Input id='selectAll' type='checkbox' checked={this.state.selectAllFixers} onChange={(e)=>this.selectAllFixers(e)} />
            <label htmlFor='selectAll'> Select All Fixers </label>
          </Col>
          <Col md='4'>
            <Button className='actionsBtn' disabled={this.disablePushNotification()} onClick={()=>this.showNotificationModal()} >Send Notification To Selected Fixers</Button>
          </Col>
        </Row>
        <Row style={{marginLeft:'5px',display:'flex',alignItems:'baseline'}} >
          <strong>
            {this.state.selectAllFixers?(
              <p>Number Of Excepted Fixers: {this.state.exceptedFixers.length}</p>
            ):(
              <p>Number Of Selected Fixers: {this.state.selectedFixers.length}</p>
            )}
          </strong>
          <Button className='actionsBtn' onClick={()=>this.setState({selectedFixers:[],exceptedFixers:[]})} >Clear</Button>
        </Row>
      </CardHeader>
    </>
    )
  }

  renderFixers(){
    return(
      <div>
        {this.state.searchText!=''?(
          <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
            <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
            <h6 style={{fontSize:18}}>Search Results of
              <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

            </h6>
            <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getFixers())}>
              Clear Search
              <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
            </span>
          </div>

        ):null}

        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center'}}>Select</th>
              <th style={{textAlign:'center'}}>ID</th>
              <th style={{textAlign:'center'}}>Name</th>
              <th style={{textAlign:'center'}}>Phone</th>
              <th style={{textAlign:'center'}}>Category</th>
              <th style={{textAlign:'center'}}>City</th>
              <th style={{textAlign:'center'}}>Activation</th>
              <th style={{textAlign:'center'}}>Status</th>
              <th style={{textAlign:'center'}}>Fixer Rate</th>
              <th style={{textAlign:'center'}}>Last 4 Weeks</th>
              <th style={{textAlign:'center'}}>Done Orders</th>
              <th style={{textAlign:'center'}}>Confirmed</th>
              <th style={{textAlign:'center'}}>Waiting</th>
              <th style={{textAlign:'center'}}>In-progress</th>
              <th style={{textAlign:'center'}}>Canceled</th>
              <th style={{textAlign:'center'}}>Failed</th>
            </tr>
          </thead>
          <tbody>
            {this.state.fixers.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>
                    <Input addon type="checkbox" checked={this.isSelectedFixer(item)} onChange={(e)=>this.selectFixerForNotification(e,item)} />
                </td>
                <td style={{textAlign:'center'}}>{item.id}</td>
                <td style={{textAlign:'center'}}><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', minWidth:100}} onClick={()=>this.showSingleFixer(item)}>{item.arName}</Button></td>
                <td style={{textAlign:'center'}}>{item.phone}</td>
                <td style={{textAlign:'center'}}>{item.category!=null?item.category.arName:''}</td>
                <td style={{textAlign:'center'}}>{item.city.enName}</td>
                <td style={{textAlign:'center'}}><Badge className='shadow' color={item.isBlocked=='1'?'danger':'success'}>{item.isBlocked=='1'?'Blocked':'Un-Blocked'}</Badge></td>
                <td style={{textAlign:'center'}}>{item.status.enName}</td>
                <td style={{textAlign:'center'}}>{item.averageTotalRate}</td>
                <td style={{textAlign:'center'}}>{item.lastFourWeeksOrdersCount}</td>
                <td style={{textAlign:'center'}}>{item.doneOrdersCount}</td>
                <td style={{textAlign:'center'}}>{item.confirmedOrdersCount}</td>
                <td style={{textAlign:'center'}}>{item.waitingConfirmationOrdersCount}</td>
                <td style={{textAlign:'center'}}>{item.inProgressOrdersCount}</td>
                <td style={{textAlign:'center'}}>{item.cancelledOrdersCount}</td>
                <td style={{textAlign:'center'}}>{item.failedOrdersCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  renderPagination(){
    return(
      <nav>
        <Row className='pull-right'>
          <Pagination style={{justifyContent:'flex-end'}}>
            {/* previous link*/}
            <PaginationItem>
              <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
            </PaginationItem>

            {/* previousPages */}
            {this.state.previousPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* currentPage */}
            <PaginationItem active>
              <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                {this.state.paginationInfo.currentPage}
              </PaginationLink>
            </PaginationItem>


            {/* nextPages */}
            {this.state.nextPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* nextPageUrl */}
            <PaginationItem>
              <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
            </PaginationItem>
          </Pagination>
        </Row>
      </nav>
    )
  }







}

export default Fixers;
