import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Table} from 'reactstrap'
import CustomersServices from './../../services/customerServices/CustomersServices'
import Services from './../../services/Services'
import { LoadingSpinner } from './../../components'

export default class HasEstimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimationCarts:[],
      noData:false
    }
  }

  componentDidMount(){
    console.log(this.props.customer);
    this.getEstimation()
  }


  getEstimation(){
    this.setState({loading:true})
    CustomersServices.getEstimation(this.props.customer.id, (res)=>{
      this.setState({loading:res.data.items.length!=0,noData:res.data.items.length==0},()=>this.groupBy(res.data.items))
    })
  }

   groupBy(arr){
    const merged = arr.reduce((r, { cityId, categoryId,...rest}) => {
      const key = `${cityId}-${categoryId}`
      r[key] = r[key] || { cityId, categoryId, card: [] }
      r[key]["card"].push(rest)
      return r
    }, {})
    Object.values(merged).map((card)=>this.estimateCard(card))
  }

  estimateCard(card){
    let item = []
    let estimationCarts = this.state.estimationCarts
    let data = { cityId: card.cityId }
    card.card.map(e=>{
      item.push({itemId:e.itemId, numberOfItems:e.quantity})
    })
    data.items = item
    this.setState({loading:true})
    Services.getPriceEstimation(data,(res)=>{
      this.setState({loading:false})
      if (res.message=='success') {
        let obj = {leastPrice:res.data.leastPrice,highestPrice:res.data.highestPrice}
        card.estimate = obj
        estimationCarts.push(card)
        this.setState({estimationCarts},()=>console.log(this.state.estimationCarts))
      }
    })
  }


  render(){
    return(
      <div>
        {this.state.estimationCarts!=''?(
          <Row style={{justifyContent:'center'}}>
            {this.state.estimationCarts.map((item, key)=>(
              <Col md='3' key={key} className='estimateCard'>
                <div className='cardRow'>
                  <span>{item.card[0].city.enName}</span>
                  <span>{item.card[0].category.enName}</span>
                </div>
                <div style={{margin:'10px 0px 10px 0px'}}>
                  {item.card.map((card, k)=>(
                    <div key={k}>
                      <span>({card.quantity}) {card.item.arName}</span>
                    </div>
                  ))}
                </div>
                <span style={{fontSize:12}}>
                  Estimation <span style={{fontSize:16,fontWeight:'bold'}}>{item.estimate.leastPrice}</span> LE - <span style={{fontSize:16,fontWeight:'bold'}}>{item.estimate.highestPrice}</span> LE
                </span>
                <br/>
                <span style={{fontSize:12}}>{item.card[0].createdAt}</span>
              </Col>
            ))}
          </Row>
        ):null}

        {this.state.noData?(
          <p style={{textAlign:'center', fontSize:16, color:'#777', marginTop:40}}>Don't have Estimation yet.</p>
        ):null}

        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
