import React,{Fragment} from 'react';
import {DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'
import LoadingSpinner from './../LoadingSpinner';
import Services from './../../services/Services';
class ServiceGroupFilter extends React.Component{
  constructor(props){
    super(props)
    this.state={
      serviceGroups:[],
      serviceGroupId:null,
      selectedServiceGroup:'ServiceGroup',
      loading:false,
    }
  }
  clear(){
    this.setState({selectedServiceGroup:'ServiceGroup',serviceGroupId:null})
  }
  componentDidMount(){
    this.getServiceGroups()
  }
  setSelectedServiceGroup(serviceGroup){
    this.setState({
      serviceGroupId:serviceGroup.id,
      selectedServiceGroup:serviceGroup.enName=='Choose'?'ServiceGroup':serviceGroup.enName,
    })
  }
  getserviceGroupId(){
    return this.state.serviceGroupId?this.state.serviceGroupId:false
  }
  getServiceGroups(){
    this.setState({loading:true})
    Services.getAllServiceGroups((res)=>{
      let arr = [{id:null,enName:'Choose'}];
      for (let i = 0; i < res.data.serviceGroups.length; i++) {
        arr.push(res.data.serviceGroups[i]);
      }
      arr.push({id:null,enName:'All'})
      this.setState({serviceGroups:arr,loading:false})
    })

  }
  render(){
    return(
      <Fragment>
        <UncontrolledDropdown  className="mb-3">
          <DropdownToggle caret className='actionsBtnWithoutHover actionsBtn' style={{width:'100%',backgroundColor:this.props.bgColor,borderWidth:this.props.bw,borderColor:this.props.bc}}><i className='fas fa-list' style={{marginRight:10}} />{this.state.selectedServiceGroup}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.serviceGroups.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>{this.props.handler(item);this.setSelectedServiceGroup(item)}}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    )
  }
}
export default ServiceGroupFilter;
