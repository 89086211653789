import React from 'react'
import { Badge , UncontrolledTooltip } from 'reactstrap'
import SingleOrder from './../../../views/orders/SingleOrder'

class PriceOfferMessage extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      viewOrderDetails:false
    }
  }
  render(){
    return(
      <>
      {this.state.viewOrderDetails?(<SingleOrder data={this.props.message.priceOffer.orderId} reload={()=>this.setState({viewOrderDetails:false})} />):null}
      <div style={{cursor:'pointer',padding:'10px'}} id={'statusTooltip'+this.props.message.id} onClick={()=>this.setState({viewOrderDetails:true})}>
          <div style={{backgroundColor:'white',textAlign:'center',color:'#73818f',borderRadius:'4px'}}>
              <h5 style={{textAlign:'center',padding:'7px'}}>Price Offer on orderId {this.props.message.priceOffer.orderId} </h5>
              <h3 style={{textAlign:'center',padding:'7px'}}>{this.props.message.priceOffer.price} L.E</h3>
              <h6 className='display-linebreak' style={{textAlign:'center',padding:'7px'}} >{this.props.message.priceOffer.qoute}</h6>
          </div>
      </div>
      <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          {this.props.message.priceOffer.status.enName=='Approved'?(
              <Badge className='shadow truncate' color='success'>Approved</Badge>
              ):this.props.message.priceOffer.status.enName=='Pending Approval'?(
              <Badge className='shadow truncate' color='primary'>Pending Approval</Badge>
              ):(
              <Badge className='shadow truncate' color='danger'>Declined</Badge>
          )}
      </div>
      <UncontrolledTooltip target={'statusTooltip'+this.props.message.id}>
          <strong>
              Click Me To View Order Details
          </strong>
      </UncontrolledTooltip>
      </>
    );
  }
}

export default PriceOfferMessage;
