import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap'
import { validatePhoneNumber, validateUrl } from './../../../core/helper'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class CloseAsVideoOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  closeVideoOrder(){
    this.setState({loading:true})
    Services.closeVideoOrder(this.props.order.orderId, (res)=>{
      this.setState({loading:false,closeVideoOrderModal:false},()=>this.props.reload())
    })
  }

  render(){
    return(
      <div>
        <p style={{padding:'0px 15px'}}> Are you sure close as video order? </p>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }



}
