import React from 'react'
import Services from '../../services/Services';
import { Button, Card, CardBody, Col, Container, Table } from 'reactstrap';
import LoadingSpinner from '../../components/LoadingSpinner';
import UserPoints from '../../components/Modals/UserPoints';

class AdminsPoints extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      loading:false,
      admins:[],
      selectedAdmin:null,
      showPointsDetailsModal:false,
    }
  }

  componentDidMount(){
    this.getAdminsPoints()
  }

  getAdminsPoints(){
    this.setState({loading:true})
    Services.getAdminsPoints((res)=>{
      this.setState({admins:res.data.admins,loading:false})
    })
  }

  showAdminPointsDetails(admin){
    this.setState({showPointsDetailsModal:true,selectedAdmin:admin})
  }
  closePointsModal(){
    this.setState({showPointsDetailsModal:false,selectedAdmin:null})
  }

  calculatePoints(orders){
    let points = 0;
    orders.forEach(order => {
      points += order.points;
    });
    return Number( (points).toFixed(1) );
  }

  render(){
    return(
      <>
      {this.state.showPointsDetailsModal?(<UserPoints admin={this.state.selectedAdmin} onClose={()=>this.closePointsModal()} />):null}
      <div className="app align-items-center">
        <Container>
          <Card>
            <CardBody className='shadow'>
              <Table hover bordered striped responsive size="md">

                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Admin ID</th>
                    <th style={{textAlign:'center'}}>Admin Name</th>
                    <th style={{textAlign:'center'}}>Admin Points</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.admins.map((admin,index)=>(
                    <tr key={index}>
                      <td style={{textAlign:'center'}}>{admin.id}</td>
                      <td style={{textAlign:'center'}}>{admin.name}</td>
                      <td style={{textAlign:'center'}}>
                        <Button
                          className="click"
                          style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}}
                          onClick={() => this.showAdminPointsDetails(admin) }
                          >
                            {this.calculatePoints( admin.orders )}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
          <LoadingSpinner loading={this.state.loading} />
        </div>
      </>
    );
  }
}

export default AdminsPoints;
