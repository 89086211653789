import React from 'react'
import { FModal } from '.';

export default class ConfirmDeleteMessage extends React.Component {
  constructor(props){
    super(props)
  }
  render(){
    return(
      <FModal state={this.props.show}
      title='Confirmation Message'
      onClose={()=>this.props.close()}
      content={this.renderBody()}
      onSubmit={()=>this.props.confirm()}
      show={true} />
    )
  }
  renderBody(){
    return(
      <div style={{padding:'20px'}}>
      <strong>{this.props.message}</strong>
    </div>
    )
  }
}
