import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
export default class AssignOrderToAgents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents:[],
      agentAdmin:'Choose',
      agentIcon:'',
      agent:[],
      adminsOrder:[],
      selectedAgentIds:''
    }
  }

  componentDidMount(){
    let arr =[]
    let selectedItems = this.props.order.admins

    for (var i = 0; i < selectedItems.length; i++) {
      arr.push(selectedItems[i].id)
    }
    this.setState({agent:selectedItems,selectedAgentIds:[]})
    this.getAgents()
  }

  getAgents(){
    this.setState({loading : true})
    Services.getAgents((res)=>{
      let arr = res.data.admins
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({agents : titles,loading:false})
    })
  }


  assignOrderToAgents(){
    if(this.state.selectedAgentIds == ''){
      alert('Please , select at least one agent for order')
    }else {
      this.setState({loading : true})
      let data = {
        admins:this.state.selectedAgentIds
      }
      Services.assignOrderToAgents(this.props.order.orderId,data,(res)=>{
        if (res.message == 'success') {
          this.setState({loading:false},()=>this.props.reload())
        }
      })
    }
  }
  
  onChangeAgents(itemSelected){
    let arr = []
    for (var i = 0; i < itemSelected.length; i++) {
      arr.push(itemSelected[i].id)
    }
    this.setState({agent : itemSelected,selectedAgentIds:arr})
  }


  render(){
    return(
      <div>
        <div className="assignOrdersToAgentContainer">
          <p style={{fontWeight:'bold'}}>To assign this order please choose agent:</p>
          <Row>
            <Col md="3"><p>Agents{' '}: </p></Col>
            <Col md="6">
              <MultiSelect
                items={this.state.agents}
                selectedItems={this.state.agent}
                onChange={(item)=>this.onChangeAgents(item)}
                withGrouping={true}
                showSelectedItems={false}
              />
            </Col>
          </Row>
        </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
