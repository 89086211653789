import { func } from "prop-types"
import { Global } from "./Global"

export function validatePhoneNumber(phone){
  if (!phone.startsWith('01')) {
    alert('Phone number must be starts with 01')
    return
  }else if (phone.length !== 11) {
    alert('Phone number must be 11 digits at least')
    return
  }else if (phone.match(/^[0-9]+$/) == null) {
    alert('Enter vaild phone number')
    return
  }else {
    return true
  }
}

export function validateUrl(url){
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(url)
}


export function isNumber(text){
  const regex = /^(-?\d*)((\.(\d{0,2})?)?)$/
  return regex.test(text)
}


export function isNumberWithoutDecimal(text){
  // const regex = /^[0-9]+$/
  const regex = /^\d+$/
  return regex.test(text)
}

export function isArabic(text){
  return text.match(/[\u0600-\u06FF]/)
}

export function buildQueryStringFromArray(string,queryObject) {
  let api='';
  for (let i = 0; i < queryObject.length; i++) {
    api +=`&${string}[${i}]=${queryObject[i].id}`;
  }
  return api;
}

export function connectToSocket () {
  let payload = {
    authToken:JSON.parse(localStorage.getItem('userData')).accessToken
  };
  window.socket.emit('auth',payload);
  window.socket.$auth = true;
}

export function parseUserPoints(points) {
  if(points >= 20){
    return `💃  ${points} Current Points`
  }
  if (points >= 10) {
    return `😉 ${points} Current Points`
  }
  if (points >= 0) {
    return `😕 ${points} Current Points`
  }
  if (points >= -10){
    return `😭  ${points} Current Points`
  }

  return `😵  ${points} Current Points`
}
export function appendMediaPathForMessage(message){
  switch (message.type) {
    case 'image':
      message.image = `${Global.chatImagePath}${message.image}`;
      break;
    case 'sound':
      message.sound = `${Global.chatSoundPath}${message.sound}`;
      break;
  }

  return message;
}

export const writeToCache = (url, data) =>localStorage.setItem(url, JSON.stringify(data))

export const readFromCache = url => JSON.parse(localStorage.getItem(url)) || null
