import React from 'react'
import { Col, Container, Row , Table , Button} from 'reactstrap';
import { CategoryFilter, CityFilter, LoadingSpinner } from '../../components';
import Services from '../../services/Services';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { confirmAlert } from 'react-confirm-alert';

class PriceEstimation extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      loading:false,
      city:null,
      category:null,
      items:[],
      selectedItems:[],
      leastPrice:'',
      highestPrice:'',
    };
  }
  cityHandler(city){
    city = city.enName=='Choose'?null:city;
    this.setState({city:city},()=>this.updateItems())
  }
  categoryHandler(category){
    category = category.enName=='Choose'?null:category;
    this.setState({category:category},()=>this.updateItems())
  }
  updateItems(){
    this.setState({items:[],selectedItems:[],leastPrice:'',highestPrice:''})
    if (this.state.city !== null && this.state.category !== null) {
      this.setState({},()=>this.getItems())
    }
  }
  addToCard(item){
    let arr = this.state.selectedItems
    let pos = arr.map(function(e) { return e.id; }).indexOf(item.id);
    if (pos === -1) {
      item.count = 1;
      arr.push(item)
      this.setState({selectedItems:arr});
    }else{
      alert('This item is already added')
    }
    this.estimatingPrice()
    this.forceUpdate()
  }

  getItems(page = 1){
    this.setState({loading:true})
    Services.getFixawyPricingItems(this.state.city.id,this.state.category.id,page,(res)=>{
      if (res.message=='success') {
        this.setState((prevState) => ({items:[...prevState.items,...res.data.pricingItems],loading:false}),
        res.data.paginationLinks.nextPages.length?this.getItems(page+1):null
      )}
    })
  }

  removeFromCard(item){
    let arr = this.state.selectedItems
    let pos = arr.map(function(e) { return e; }).indexOf(item)
    arr.splice(pos, 1)
    this.setState({selectedItems:arr},()=>{
      arr != 0 ? this.estimatingPrice() : this.setState({selectedItems:[],leastPrice:'',highestPrice:''})
      this.forceUpdate()
    })
  }
  increaseItemCount(item){
    let arr = this.state.selectedItems
    let numberOfCount = item.count
    let pos = arr.map(function(e) { return e; }).indexOf(item)
    arr[pos].count = numberOfCount+1
    this.setState({selectedItems:arr},()=>this.estimatingPrice(),()=>this.forceUpdate())
  }
  decreaseItemCount(item){
    let numberOfCount = item.count
    if (numberOfCount !== 1) {
      let arr = this.state.selectedItems
      let pos = arr.map(function(e) { return e; }).indexOf(item)
      arr[pos].count = numberOfCount-1
      this.setState({selectedItems:arr},()=>this.estimatingPrice(),()=>this.forceUpdate())
    }else{
      confirmAlert({
        title: 'Fixawy',
        message: `Are you sure to delete ${item.enName}`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.removeFromCard(item)
          },
          {
            label: 'No',
            onClick: () => console.log('cancel')
          }
        ]
      })
    }
  }
  estimatingPrice(){
    this.setState({loading:true})
    let items = [];
    this.state.selectedItems.map(item => {
      items.push({itemId:item.id,numberOfItems:item.count})
    })
    let data={
      cityId:this.state.city.id,
      items : items,
    }
    Services.getPriceEstimation(data,(res)=>{
      if (res.message=='success') {
        this.setState({leastPrice:res.data.leastPrice,highestPrice:res.data.highestPrice,loading:false})
      }
    })
  }

  render(){
    return(
      <div className="app align-items-center">
        <Container>
          <Row style={{paddingLeft:10}}>
            <Col md='4'>
              <CityFilter handler={(city)=>this.cityHandler(city)} ref={(ref)=>this.cityRef=ref}/>
            </Col>
            <Col md='4'>
              <CategoryFilter handler={(category)=>this.categoryHandler(category)} ref={(ref)=>this.categoryRef=ref} estimatable/>
            </Col>
          </Row>
          <Row>
            <Col md='8'>
              {this.state.selectedItems.length !== 0?(
                <div>
                  <p className='titles'>Selected Items</p>
                  <Table hover bordered striped responsive size="md">
                    <thead>
                      <tr>
                        <th style={{textAlign:'center',width:'50%'}}>Item</th>
                        <th style={{textAlign:'center'}}>Count</th>
                        <th style={{textAlign:'center'}}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.selectedItems.map((item, index)=>(
                        <tr key={index}>
                          <td style={{textAlign:'center'}}>{item.enName}</td>
                          <td style={{textAlign:'center'}}>
                            <div className='cardItems'>
                              <i className='fas fa-minus-circle' onClick={()=>this.decreaseItemCount(item)}/>
                              <span>{item.count}</span>
                              <i className='fas fa-plus-circle' onClick={()=>this.increaseItemCount(item)}/>
                            </div>
                          </td>
                          <td style={{textAlign:'center'}}><Button color="danger" onClick={()=>this.removeFromCard(item)}>Delete</Button></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ):null}
            </Col>
            {this.state.leastPrice!=''&&this.state.highestPrice!=''?(
              <Col md="4" style={{fontSize:'24px'}}>
                <h5>Estimated Price</h5>
                <span>From <strong>{this.state.leastPrice}</strong></span>
                <span> To <strong>{this.state.highestPrice}</strong></span>
                <span> EGP</span>
              </Col>
            ):null}
          </Row>
          <Row>
            <Col md='8'>
              {this.state.items.length?(
                <Accordion allowZeroExpanded={true}>
                  {this.state.items.map((item, index)=>(
                    item.hasChildren !== 0 ?(
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{item.enName}</AccordionItemButton>
                        </AccordionItemHeading>
                        {item.children.map((child, i)=>(
                          <AccordionItemPanel key={i} onClick={()=>this.addToCard(child)}>{child.enName}</AccordionItemPanel>
                        ))}
                      </AccordionItem>
                    ):(<p className='moreItem' key={index} onClick={()=>this.addToCard(item)}>{item.enName}</p>)
                  ))}
                </Accordion>
              ):null}
            </Col>
          </Row>
        </Container>
        <LoadingSpinner loading={this.state.loading} />
      </div>
    )
  }
}

export default PriceEstimation;
