import React from 'react'
import Linkify from 'react-linkify'
const TextMessage = ({message}) => {
    return (
      <p className='display-linebreak' style={{wordWrap:'break-word', marginBottom:0}}>
        <Linkify>
          {message.body}
        </Linkify>
      </p>
    );
}

export default TextMessage;
