import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Input, Row ,Table ,CardHeader ,Modal ,ModalBody ,ModalFooter ,ModalHeader,Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, InputGroup,InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink  } from 'reactstrap';
import MultiSelect from "@kenshooui/react-multi-select"
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import "@kenshooui/react-multi-select/dist/style.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import Switch from "react-switch";
import {AddFixersModal, FixawyButton, Search, CategoryFilter, CityFilter, NoResult} from './../../components'
import FixersService from './../../services/fixerServices/FixersServices'
import SingleWorkContract from './SingleWorkContract';
import WorkContractService from './../../services/workContractServices/WorkContractService';


class Contracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noResult:true,
      cities:[],
      categories:[],
      areas:[],
      packages:[],
      selectedCity:'Choose',
      selectedCityId:'',
      selectedCategory:'Choose',
      selectedCategoryId:'',
      selectedArea:[],
      selectedAreaId:[],
      selectedPackage:'Choose',
      selectedPackageId:'',
      salary:'',
      minim:'',
      percentage:'',
      renderOrderAmountArr:[],
      allPackagesPage:1,
      order:1,
      firstOrderAmount:'',
      modalAddContract: false,
      loading:false,
      contract:'',
      workContracts: [],
      ReservationSwitcherChecked: true,
      workContractsPaginationInfo : [],
      workContractsPreviousPages: [],
      workContractsNextPages: [],
      contractEndDateToRequest:'',
      contractStartDateToRequest:'pick day',
      contractStartDate: '',
      contractEndDate: '',
      selectedPackageDuration: '',
      currentPage: 1,
      displayAllAreasModal:false,
      displayFinanceModal:false,
      singleContractAreas:'',
      companyPercantageType:'',
      target: '',
      targetType: '',
      reward: '',
      rewardType: '',
      isUsedAsDefault:false,
      singleContract:'',
      showAddFixersModal:false,
      workContractIdForFixers:'',
      fixers:[],
      displaySearchResult:false,
      searchTerm:'',
      selectedCity:'',
      selectedCategory:'',
      startDate:'',
      endDate:'',
      filters:false,
      showSingleWorkContract:false,
      selectedWorkContract:null,
      isRenewable:false
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0);
    this.getWorkContracts();
  }
  handleDisplayAllAreasModal(){
    this.setState({displayAllAreasModal:!this.state.displayAllAreasModal})
  }
  handleDisplayFinanceModal(){
    this.setState({displayFinanceModal:!this.state.displayFinanceModal})
  }

  handleChangeInReservationStatus(reservationStatus, index, workContractId) {
    this.setState({loading:true})
    Services.changeWorkContractOpenForReservationStatus(workContractId , reservationStatus ,(res)=>{
      if (res.message == 'success') {
        let workContractsArr = this.state.workContracts;
        workContractsArr[index].isOpenForReservation = reservationStatus;
        this.setState({
          workContracts:workContractsArr
        });
        this.resetState()
      }
    })
  }

  toggleRenewable(renewableStatus, index, workContractId) {
    this.setState({loading:true})
    WorkContractService.toggleRenewable(workContractId , renewableStatus ,(res)=>{
      if (res.message == 'success') this.getWorkContracts()
    })
  }

  changeOpenForReservationStatus(workContractId , reservationStatus){

  }
  getCities(){
    // console.log(JSON.parse(localStorage.getItem('userData')).accessToken);
    this.setState({loading:true})
    Services.getCities((res)=>{
      this.setState({cities:res.data.cities, loading:false})
    })
  }
  getAllPackages(page){
    Services.getAllPackages(page,(res)=>{
      if (res.data.paginationInfo.lastPage > 1 && this.state.allPackagesPage != res.data.paginationInfo.lastPage) {
        let pagePlusOne = this.state.allPackagesPage
        pagePlusOne+=1
        let packages = this.state.packages
        let arr = res.data.packages
        for (var i = 0; i < arr.length; i++) {
          packages.push(arr[i])
        }
        this.setState({allPackagesPage:pagePlusOne, packages:packages},()=>this.getAllPackages(pagePlusOne))
      }else if (this.state.allPackagesPage == res.data.paginationInfo.lastPage) {
        let packages = this.state.packages
        let arr = res.data.packages
        for (var i = 0; i < arr.length; i++) {
          packages.push(arr[i])
        }
        this.setState({packages:packages},()=>console.log(packages))
      }else {
        this.setState({packages:res.data.packages})
      }
      this.setState({loading:false})
    })
  }
  getCategoriesDependCity(cityId, serviceGroups){
    this.setState({loading:true})
    Services.getCategoriesDependCity(cityId, serviceGroups, (res)=>{
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      this.setState({categories:arr, loading:false},()=>console.log(arr))
    })
  }

  getArea(cityId){
    this.setState({loading:true})
    Services.getArea(cityId, (res)=>{
      let arr = res.data.areas
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({areas:titles, loading:false},()=>this.getAllPackages(1))
    })
  }

  calculateEndDateOfWorkContract(){
    console.log('calculateEndDateOfWorkContract');
    let contractEndDate, contractEndDateToRequest = ''
    if (this.state.selectedPackageDuration >= 24) {
      contractEndDate = moment(new Date(this.state.contractStartDate)).add(this.state.selectedPackageDuration-24 ,'hours').format('DD-MM-YYYY')
      contractEndDateToRequest = moment(new Date(this.state.contractStartDate)).add(this.state.selectedPackageDuration-24 ,'hours').format('YYYY-MM-DD')
    }else {
      contractEndDate = moment(new Date(this.state.contractStartDate)).add(this.state.selectedPackageDuration ,'hours').format('DD-MM-YYYY')
      contractEndDateToRequest = moment(new Date(this.state.contractStartDate)).add(this.state.selectedPackageDuration ,'hours').format('YYYY-MM-DD')
    }

    this.setState({
      'contractEndDate' : contractEndDate,
      contractEndDateToRequest:contractEndDateToRequest
    })
  }
  handleMenus(menu, item){
    console.log('packa',item);
    switch (menu) {
      case 'city':
      this.setState({selectedCity: item.enName, selectedCityId: item.id,selectedCategory:'Choose', selectedCategoryId:'', selectedPackage:'Choose',selectedPackageId:'', selectedArea:[],selectedAreaId:[]},()=>this.getArea(this.state.selectedCityId))
      break
      case 'category':
      this.setState({selectedCategory: item.enName, selectedCategoryId: item.id,})
      break
      case 'packages':
      let idsArr = item.serviceGroups
      let ids= ''
      for (var i = 0; i < idsArr.length; i++) {
        ids+=idsArr[i].id+','
      }
      let serviceGroups = ids.substring(0, ids.length - 1)
      console.log('serviceGroups',serviceGroups);
      console.log(item);
      this.setState(
        {
          selectedPackage: item.arName,
          selectedPackageId: item.id,
          selectedPackageDuration: item.duration,
          selectedCategory:'Choose',
          selectedCategoryId:''
        },
        ()=>{
          this.getCategoriesDependCity(this.state.selectedCityId, serviceGroups)
          this.calculateEndDateOfWorkContract()
        })
        break
        default:
      }
    }
    toggleAddContract(type, item){
      if (type == 'open' && this.state.cities.length == 0) {
        console.log(222);
        this.setState({modalAddContract:true},()=>this.getCities())
      }
      // else if(type == 'duplicate' && this.state.cities.length == 0){
      //   console.log('item', item);
      //   this.showDataInWorkContractModal(item)
      // }
      else if(this.state.modalAddContract){
        this.setState({modalAddContract:!this.state.modalAddContract},()=>this.resetState())
      }

      if(type == "duplicate"){
        this.setState({modalAddContract:!this.state.modalAddContract},()=>this.showDataInWorkContractModal(item))
      }else{
        this.setState({modalAddContract:!this.state.modalAddContract})
      }

    }

    generateOrderSlots(slots){
      console.log('generateOrderSlots');
      this.setState({firstOrderAmount:slots[0].bonus})
      console.log(slots);
      slots.shift()
      console.log(slots);
      let arr = []
      slots.map((slot, i)=>{
        arr.push({bounce:slot.bonus, id:i+1})
        this.setState({['bounce'+i+1]:slot.bonus, renderOrderAmountArr:arr})
      })
    }

    showDataInWorkContractModal(item){

      let arr = item.areas
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].enName, id: arr[i].id}
        titles.push(obj)
      }
      console.log('item', item);



      this.setState({
        modalAddContract:true,
        contractStartDateToRequest:item.startDate,
        contractStartDate:moment(item.startDate,'YYYY-MM-DD').toDate(),
        contractEndDateToRequest:item.endDate,
        selectedCityId:item.city.id,
        selectedCategoryId:item.category.id,
        selectedPackageId:item.package.id,
        selectedArea:titles,
        selectedAreaId:item.areas.map(area=>area.id),
        salary:item.basicSalary,
        minim:item.minimumSalary,
        percentage:item.companyPercentage,
        companyPercantageType:item.isCompanyPercentageCalculatedOnTotalPrice?'fixAndTools':'fixOnly',
        target:item.target?item.target:'',
        targetType:item.targetType?item.targetType=="orders_count"?'orderNumber':'fixed':'',
        reward:item.reward?item.reward:'',
        rewardType:item.rewardType?item.rewardType:'',
        selectedCity:item.city.enName,
        selectedCategory:item.category.enName,
        selectedPackage:item.package.arName,
        selectedPackage: item.package.arName,
        selectedPackageDuration: item.package.duration,
      },()=>{
        console.log('this.state.contractStartDate',this.state.contractEndDate);
        console.log(this.state.contractStartDate);
        this.getCities()
        this.getArea(this.state.selectedCityId)
        this.calculateEndDateOfWorkContract()
        this.getCategoriesDependCity(item.city.id, item.package.serviceGroups.map(i=>i.id))
      })

      if (item.orderSlots.length) this.generateOrderSlots(item.orderSlots)

      if (item.defaultBonus) {
        this.setState({firstOrderAmount:item.defaultBonus,isUsedAsDefault:true})
      }

    }

    onChange = (x, e, index) => {
      // integers only
      // const re = /^[0-9\b]+$/;

      // decimal
      const re = /^(-?\d*)((\.(\d{0,2})?)?)$/;
      if (e.target.value === '' || re.test(e.target.value)){
        switch (x) {
          case 'salary':
          this.setState({salary:e.target.value})
          break
          case 'percentage':
          this.setState({percentage:e.target.value})
          break
          case 'minim':
          this.setState({minim:e.target.value})
          break
          case 'target':
          this.setState({target:e.target.value})
          break
          case 'reward':
          this.setState({reward:e.target.value})
          break
          case 'bounce':
          console.log(index);
          let arr = this.state.renderOrderAmountArr
          // if (arr.length == 0) {
          //   let obj = {
          //     bounce:'',
          //     id: 0
          //   }
          //   arr.push(obj)
          // }
          if (index == 'firstOrder') {
            this.setState({firstOrderAmount:e.target.value})
          }else {
            arr[index-1].bounce = parseInt(e.target.value)
            this.setState({['bounce'+index]:e.target.value, renderOrderAmountArr:arr},()=>console.log(arr))
          }
          break
          default:
        }
      }else {
        alert('Please, enter numbers in english')
      }
    }
    onChangeMultiSelect(itemSelected){
      let arr = []
      for (var i = 0; i < itemSelected.length; i++) {
        arr.push(itemSelected[i].id)
      }
      this.setState({selectedArea:itemSelected,selectedAreaId:arr})
    }

    nextOrderAmount(){
      let arr = this.state.renderOrderAmountArr
      let slotNumber = this.state.order
      if (this.state.firstOrderAmount && arr.length == 0) {
        slotNumber+= 1
        let obj = {
          bounce:'',
          id: slotNumber
        }
        arr.push(obj)
        this.setState({renderOrderAmountArr:arr, order: slotNumber},()=>this.forceUpdate())
      }else if (arr.length != 0) {
        if (arr[arr.length-1].bounce) {
          slotNumber+= 1
          let obj = {
            bounce:'',
            id: slotNumber
          }
          arr.push(obj)
          this.setState({renderOrderAmountArr:arr, order: slotNumber},()=>this.forceUpdate())
        }else {
          alert('Please, enter order amount first')
        }
      }else {
        alert('Please, enter order amount first')
      }
    }

    handleOnChangeDate(date){
      let contractStartDate = new Date (date+' Z')
      this.setState({contractStartDateToRequest: contractStartDate.toISOString().split('T')[0], contractStartDate: contractStartDate},()=>{
        if (this.state.selectedPackageDuration!='') {
          this.calculateEndDateOfWorkContract()
        }
      })
    }
    valideSearch(term){
      if (term != '') {
        return true
      }
      alert('please enter a valid search term')
      return false
    }
    searchHandler(term){
      if (this.valideSearch(term)) {
        this.setState({displaySearchResult:true,searchTerm:term,currentPage:1},()=>this.getDataByFiltiration())
      }
    }
    closeSearchResult(){
      this.searchRef.clearCache()
      this.setState({displaySearchResult:false,searchTerm:'',currentPage:1},this.state.filters?()=>this.getDataByFiltiration():this.getWorkContracts())
    }
    cityHandler(city){
      this.setState({selectedCity:city})
    }
    categoryHandler(category){
      this.setState({selectedCategory:category})
    }
    dateHandler(type,date){
      switch (type) {
        case 'start':
        this.setState({startDate:date})
        break;

        case 'end':
        this.setState({endDate:date})
        break;

        default:
        break;
      }
    }
    valideFilters(){
      if( (this.state.selectedCity.enName!='Choose' && this.state.selectedCategory.enName!= 'Choose') && ( this.state.selectedCity !=''|| this.state.selectedCategory !='' || this.state.startDate !='' || this.state.endDate != '' ) ){
        if (this.state.startDate != '' && this.state.endDate != '' && this.state.startDate >this.state.endDate) {
          alert('start date can\'t be greater than end date')
          return false
        }
        return true
      }
      alert('Please , select a valid filter options')
      return false
    }
    getDataByFiltiration(){
      let options={
        cityId:this.cityRef.getCityId(),
        categoryId:this.categoryRef.getCategoryId(),
        startDate:this.state.startDate?moment(this.state.startDate).format('YYYY-MM-DD'):null,
        endDate:this.state.endDate?moment(this.state.endDate).format('YYYY-MM-DD'):null,
        searchTerm:this.state.searchTerm,
      }
      this.setState(()=>this.getWorkContracts(options))
    }
    filter(){
      if(this.valideFilters()){
        this.setState({filters:true,currentPage:1},()=>this.getDataByFiltiration())
      }
    }
    resetFilters(){
      this.cityRef.clear()
      this.categoryRef.clear()
      this.setState({filters:false,selectedCity:'',selectedCategory:'',startDate:'',endDate:'',currentPage:1},this.state.displaySearchResult?()=>this.getDataByFiltiration():()=>this.getWorkContracts())
    }

    addWorkingContract(){
      if (this.state.contractStartDateToRequest == '') {
        alert('Please, choose working contract')
      }else if (this.state.selectedCityId=='') {
        alert('Please, choose city')
      }else if (this.state.selectedPackageId=='') {
        alert('please, choose package')
      }else if (this.state.percentage != '' && this.state.companyPercantageType=='') {
        alert('Please, select percentage type')
        return
      }else if (this.state.percentage == '' && this.state.companyPercantageType!='') {
        alert('Please, enter percentage ')
        return
      }else if (this.state.selectedAreaId=='') {
        alert('Please, choose area')
      }else if (this.state.selectedCategoryId=='') {
        alert('please, choose category')
      }else if ( String(this.state.salary).length >=10) {
        alert('Please, enter digits only and basics doesn’t exceed 10 digits')
      }else if (String(this.state.percentage).length>=10) {
        alert('Please, enter digits only and basics doesn’t exceed 10 digits')
      }else if (String(this.state.minim).length>=10) {
        alert('please, enter digits only and minimum doesn’t exceed 10 digits')
      }else if (String(this.state.target).length>=10) {
        alert('please, enter digits only and target doesn’t exceed 10 digits')
      }else if (String(this.state.reward).length>=10) {
        alert('please, enter digits only and reward doesn’t exceed 10 digits')
      }else if (this.state.target != '' && this.state.targetType =='') {
        alert('Please, select target type')
        return
      }else if (this.state.target == '' && this.state.targetType !='') {
        alert('Please, enter target')
        return
      }else if (this.state.reward != '' && this.state.rewardType =='') {
        alert('Please, select reward type')
        return
      }else if (this.state.reward == '' && this.state.rewardType !='') {
        alert('Please, enter reward')
        return
      } else if (this.state.target != '' && (this.state.rewardType =='' || this.state.reward =='' )) {
        alert('Please , select reward & reward-type')
        return
      }else if (this.state.reward != '' && (this.state.target == '' || this.state.targetType == '')) {
        alert('Please, enter target first')
      } else {
        let data = {
          startDate: this.state.contractStartDateToRequest,
          endDate: this.state.contractEndDateToRequest,
          cityId: this.state.selectedCityId,
          packageId: this.state.selectedPackageId,
          areas: this.state.selectedAreaId,
          categoryId:this.state.selectedCategoryId,
          isRenewable:this.state.isRenewable?1:0
        }
        if (this.state.percentage != '') {
          data.companyPercentage = this.state.percentage
        }
        if (this.state.companyPercantageType!='') {
          data.isCompanyPercentageCalculatedOnTotalPrice = this.state.companyPercantageType == 'fixOnly' ? 0 : 1
        }
        // ==== ==== ==== ==== ==== ==== ==== ==== ==== ====
        // conditions related to target and target-type
        if (this.state.target != '') {
          data.target = this.state.target
        }
        if (this.state.targetType!='') {
          data.targetType = this.state.targetType == 'orderNumber' ? "orders_count" : "fixed_amount"
        }
        // conditions related to reward and reward-type
        if (this.state.reward != '') {
          data.reward = this.state.reward
        }
        if (this.state.rewardType!='') {
          data.rewardType = this.state.rewardType == 'percentage' ? "percentage" : "fixed"
        }
        // ==== ==== ==== ==== ==== ==== ==== ==== ==== ====




        if (this.state.salary != '') {
          data.basicSalary = this.state.salary
        }else {
          data.basicSalary=0
        }
        if (this.state.minim != '') {
          data.minimumSalary = this.state.minim
        }else {
          data.minimumSalary=0
        }

        if (!this.state.isUsedAsDefault) {
          if (this.state.firstOrderAmount != '') {
            let arr = []
            arr.push(parseInt(this.state.firstOrderAmount))
            if (this.state.renderOrderAmountArr.length != 0) {
              let bounce = this.state.renderOrderAmountArr
              for (var i = 0; i < bounce.length; i++) {
                if (!Number(parseFloat(bounce[i].bounce))) {
                  alert('Please, enter number only for order amount')
                  return
                }else {
                  arr.push(bounce[i].bounce)
                }
              }
            }
            data.bonuses = arr
          }
        }else {
          if (this.state.firstOrderAmount != '') {
            data.defaultBonus = this.state.firstOrderAmount
          }
        }
        this.setState({loading:true})
        Services.addWorkingContract(data,(res)=>{
          if (res.message == 'success') {
            this.setState({currentPage: 1},() =>this.getDataByFiltiration())
            this.resetState()
          }
        })
      }
    }



    resetState(){
      this.setState({
        categories:[],
        areas:[],
        packages:[],
        selectedCity:'Choose',
        selectedCityId:'',
        selectedCategory:'Choose',
        selectedCategoryId:'',
        selectedArea:'',
        selectedAreaId:[],
        selectedPackage:'Choose',
        selectedPackageId:'',
        salary:'',
        minim:'',
        renderOrderAmountArr:[],
        order:1,
        allPackagesPage:1,
        firstOrderAmount:'',
        modalAddContract: false,
        contractStartDateToRequest:'pick day',
        contractStartDate:'',
        loading:false,
        contractEndDate:'',
        contractEndDateToRequest:'',
        selectedPackageDuration:'',
        contractStartDate:'',
        percentage:'',
        companyPercantageType:'',
        target: '',
        targetType: '',
        reward: '',
        rewardType: '',
        isUsedAsDefault:false,
      })
    }

    getWorkContracts(options = null){
      this.setState({loading : true})
      Services.getWorkContracts(this.state.currentPage,options,(res)=>{
        this.setState({
          workContracts: res.data.worKContracts ,
          workContractsPaginationInfo:res.data.paginationInfo,
          workContractsPreviousPages:res.data.paginationLinks.previousPages.reverse(),
          workContractsNextPages:res.data.paginationLinks.nextPages,
          noResult:res.data.worKContracts.length?true:false,
          loading:false,
        })
      })
    }

    prevPage(item){
      let page = this.state.currentPage
      if (item == '') {
        this.setState({currentPage: page-1},this.state.filters||this.state.searchTerm?()=>this.getDataByFiltiration():()=>this.getWorkContracts())
      }else {
        this.setState({currentPage: item},this.state.filters||this.state.searchTerm?()=>this.getDataByFiltiration():()=>this.getWorkContracts())
      }
    }
    nextPage(item){
      let page = this.state.currentPage
      if (item == '') {
        this.setState({currentPage: page+1},this.state.filters||this.state.searchTerm?()=>this.getDataByFiltiration():()=>this.getWorkContracts())
      }else {
        this.setState({currentPage: item},this.state.filters||this.state.searchTerm?()=>this.getDataByFiltiration():()=>this.getWorkContracts())
      }
    }
    expiredContract(workContract){
      let today=(new Date()).setHours(0,0,0,0);
      let endTime=(new Date(workContract.endDate)).setHours(0,0,0,0);
      // console.table(today,endTime,workContract)
      return endTime<today
    }
    handleAddFixers(id){
      this.setState(()=>({workContractIdForFixers:id}),()=>this.fetchFixers())
      this.setState(()=>({showAddFixersModal:true}))
    }
    closeAddFixersModal(){
      this.setState({showAddFixersModal:false,workContractIdForFixers:'',fixers:[]},()=>this.getDataByFiltiration())
    }
    fetchFixers(){
      if (this.state.workContractIdForFixers) {
        this.setState({loading:true})
        FixersService.getFixersOutofWorkContracts(this.state.workContractIdForFixers,(res)=>{
          let arr = res.data.fixers
          let titles=[];
          for (let i = 0; i < arr.length; i++) {
            let obj={id:arr[i].id,label:arr[i].arName}
            titles.push(obj)
          }
          this.setState({fixers: titles,loading:false});
        })
      }
    }

    renderPagination(){

      return(
        <nav>
          <Row className='pull-right'>
            <Pagination style={{justifyContent:'flex-end'}}>
              {/* previous link*/}
              <PaginationItem>
                <PaginationLink previous tag="button" onClick={()=>this.state.workContractsPaginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
              </PaginationItem>

              {/* previousPages */}
              {this.state.workContractsPreviousPages.map((item, index)=>(
                <PaginationItem key={index}>
                  <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                    {item}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {/* currentPage */}
              <PaginationItem active>
                <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                  {this.state.workContractsPaginationInfo.currentPage}
                </PaginationLink>
              </PaginationItem>


              {/* nextPages */}
              {this.state.workContractsNextPages.map((item, index)=>(
                <PaginationItem key={index}>
                  <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                    {item}
                  </PaginationLink>
                </PaginationItem>
              ))}

              {/* nextPageUrl */}
              <PaginationItem>
                <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.workContractsPaginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
              </PaginationItem>
            </Pagination>
          </Row>
        </nav>
      )
    }


    render() {
      return (
        <div className="app align-items-center">
          <Container>
            <Row style={{paddingLeft:10}}>
              <CityFilter handler={(city)=>this.cityHandler(city)} ref={(ref)=>this.cityRef=ref}/>
              <div style={{marginLeft:20}}>
                <CategoryFilter handler={(category)=>this.categoryHandler(category)} ref={(ref)=>this.categoryRef=ref}/>
              </div>

              <div style={{marginLeft:40}} className='date'>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={(date)=> this.dateHandler('start', date) }
                  dateFormat="dd-MM-yyyy"
                  placeholderText='Start date'
                />
              </div>
              <div style={{marginLeft:20}} className='date'>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={(date)=> this.dateHandler('end', date) }
                  dateFormat="dd-MM-yyyy"
                  placeholderText='End date'
                />
              </div>
              <Col md={1}>
                <Button onClick={()=>this.filter()}
                  className='actionsBtn'
                  style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
                </Col>
                <Col md={1}>
                  <Button onClick={()=>this.resetFilters()}
                    className='actionsBtn'
                    style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'white'}}>Reset</Button>
                  </Col>
                </Row>
                <Card>
                  <Search header="Work Contracts" placeholder="By package name" maxLength="50" ref={(ref)=>this.searchRef=ref} handler={(term)=>this.searchHandler(term)}/>
                  <CardHeader className='shadow'>
                    {this.renderHeaderAddCondition()}
                  </CardHeader>

                  <CardBody>
                    {this.state.displaySearchResult?(
                      <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
                        <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
                        <h6 style={{fontSize:18}}>Search Results of
                          <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchTerm}</span>

                        </h6>
                        <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.closeSearchResult()}>
                          Clear Search
                          <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                        </span>
                      </div>
                    ):null}
                    {this.state.noResult?(<>
                      {this.renderWorkContracts()}
                      {this.renderPagination()}
                    </>):(<NoResult/>)}

                    {this.state.showSingleWorkContract?(
                      <SingleWorkContract
                        workContract={this.state.selectedWorkContract}
                        onClose={()=>this.setState({showSingleWorkContract:false,selectedWorkContract:null})}
                      />
                    ):null}

                    <Modal fade={false} isOpen={this.state.modalAddContract} toggle={()=>this.toggleAddContract('open')} className={this.props.className}>
                      <ModalHeader toggle={()=>this.toggleAddContract()}><i className='fa fa-file-text-o' style={{marginRight:10}}/>  Add Work Contract</ModalHeader>
                      {this.renderViewModalAddContract()}
                      <ModalFooter>
                        <Button className='actionsBtn' onClick={()=>this.toggleAddContract()}>Close</Button>
                        <Button className='actionsBtn' onClick={()=>this.addWorkingContract()}>Add</Button>
                      </ModalFooter>
                    </Modal>
                  </CardBody>


                  {/* all areas modal */}
                  <Modal fade={false} isOpen={this.state.displayAllAreasModal} toggle={()=>this.handleDisplayAllAreasModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.handleDisplayAllAreasModal()}>Areas</ModalHeader>
                    <ModalBody>
                      {this.state.singleContractAreas!=''?(
                        <div className='orderBadges' style={{width:350, textAlign:'center', display:'block', margin:'auto'}}>
                          {this.state.singleContractAreas.map((item, index)=>(
                            <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent', border:'1px solid #5a5a5a', color:'#5a5a5a',fontSize:12}}>{item.enName}</span>
                          ))}
                        </div>
                      ):null}
                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.handleDisplayAllAreasModal()}>close</Button>
                    </ModalFooter>
                  </Modal>

                  {/* all areas modal */}
                  <Modal fade={false} isOpen={this.state.displayFinanceModal} toggle={()=>this.handleDisplayFinanceModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.handleDisplayFinanceModal()}>Finance Report</ModalHeader>
                    <ModalBody>
                      {this.state.singleContract!=''?this.renderFinanceReport():null}
                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.handleDisplayFinanceModal()}>Close</Button>
                    </ModalFooter>
                  </Modal>

                  {/* Adding Fixers Modal  */}
                  <AddFixersModal show={this.state.showAddFixersModal}
                    fixers={this.state.fixers}
                    workContractId={this.state.workContractIdForFixers}
                    closeHandler={()=>this.closeAddFixersModal()}
                  />

                  {/* loading spinner */}
                  <Modal fade={false} isOpen={this.state.loading} className='loading'>
                    <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
                  </Modal>
                </Card>
              </Container>
            </div>
          )
        }



        renderHeaderAddCondition(){
          return (
            <Row>
              <Col md="4" style={{display:'flex',alignItems:'center'}}>
                <i className='fa fa-plus-square' style={{marginRight:8}}/>
                <span style={{marginLeft:6}}>Add Contracts</span>
              </Col>
              <Button className='actionsBtn' style={{marginTop:1,marginBottom:-1, display:'block', marginLeft:'auto'}} onClick={()=>this.toggleAddContract('open')}><i className='fa fa-plus-square' style={{marginRight:8}}/>  Add Contract</Button>
            </Row>
          )
        }



    renderWorkContracts(){
      return (
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center' ,width:'5%'}}>ID</th>
              <th style={{textAlign:'center',width:'15%'}}>StartDate</th>
              <th style={{textAlign:'center',width:'15%'}}>EndDate</th>
              <th style={{textAlign:'center',width:'10%'}}>City</th>
              <th style={{textAlign:'center',width:'15%'}}>Areas</th>
              <th style={{textAlign:'center',width:'10%'}}>Package</th>
              <th style={{textAlign:'center',width:'10%'}}>Category</th>
              <th style={{textAlign:'center',width:'20%'}}>Finance</th>
              <th style={{textAlign:'center',width:'5%'}}>Open for Reservation</th>
              <th style={{textAlign:'center',width:'20%'}}>Requests Number</th>
              <th style={{textAlign:'center',width:'20%'}}>Accepted Requests</th>
              <th style={{textAlign:'center',width:'30%'}}>Add Fixer</th>
              <th style={{textAlign:'center',width:'30%'}}>Duplicate</th>
              <th style={{textAlign:'center',width:'30%'}}>Renewable</th>
            </tr>
          </thead>
          <tbody>


            {this.state.workContracts.map((item,index)=>(
              <tr key={index}>
                <td style={{textAlign:'center',width:'5%'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({showSingleWorkContract:true,selectedWorkContract:item})}>{item.id}</Button>
                  {item.isRenewable?(
                    <span style={{fontSize:10, backgroundColor:'#0095ff', color:'#fff', padding:'3px 8px', borderRadius:10}}>Renewable</span>
                  ):null}
                </td>
                <td style={{textAlign:'center',width:'15%'}}><div style={{textAlign:'center',width:90}}>{item.startDate}</div></td>
                <td style={{textAlign:'center',width:'15%'}}><div style={{textAlign:'center',width:90}}>{item.endDate}</div></td>
                <td style={{textAlign:'center',width:'10%'}}>{item.city.enName}</td>
                <td style={{textAlign:'center'}}>
                  <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({singleContractAreas:item.areas},()=>this.handleDisplayAllAreasModal())}>Show Areas</span>
                </td>
                <td style={{textAlign:'center',width:'10%'}}>{item.package.arName}</td>
                <td style={{textAlign:'center',width:'10%'}}>{item.category.enName}</td>
                <td style={{textAlign:'center'}}>
                  <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({singleContract:item},()=>this.handleDisplayFinanceModal())}>Details</span>
                </td>
                <td style={{textAlign:'center',width:'5%'}}>
                  <label style={{width:60}}>
                    <Switch onChange={(val) => this.handleChangeInReservationStatus(val,index, item.id)} checked={item.isOpenForReservation==1?true:false} />
                  </label>
                </td>
                <td style={{textAlign:'center',width:'5%',maxWidth:'75px'}}><div style={{textAlign:'center'}}>{item.requestsNumber}</div></td>
                <td style={{textAlign:'center',width:'5%',maxWidth:'75px'}}><div style={{textAlign:'center'}}>{item.acceptedRequests}</div></td>
                <td style={{textAlign:'center',width:'10%',maxWidth:'100px'}}>
                  <FixawyButton disabled={this.expiredContract(item)} onClick={()=>this.handleAddFixers(item.id)} >Add Fixers</FixawyButton>
                </td>
                <td style={{textAlign:'center',width:'10%',maxWidth:'100px'}}>
                  <FixawyButton disabled={false} onClick={()=>this.toggleAddContract('duplicate',item)}>Duplicate <i className='fa fa-clone' /></FixawyButton>
                </td>
                <td style={{textAlign:'center',width:'5%'}}>
                  <label style={{width:60}}>
                    <Switch onChange={(val)=>this.toggleRenewable(val, index, item.id)} checked={item.isRenewable==1?true:false} />
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    }



        renderViewModalAddContract(){
          return(
            <ModalBody>
              <Row style={{padding:'15px 33px'}}>
                {/* city */}
                <Col md='6'>
                  <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-calendar' style={{marginRight:10}} />Start Date</h5>
                  <div className='date'>
                    <DatePicker
                      minDate={moment(new Date()).add(1,'days').toDate()}
                      maxDate={moment(new Date()).add(15,'days').toDate()}
                      selected={this.state.contractStartDate}
                      onChange={(date)=> this.handleOnChangeDate( date) }
                      dateFormat="dd-MM-yyyy"
                      placeholderText={this.state.contractStartDateToRequest}
                    />

                    {this.state.contractEndDate != '' && this.state.contractStartDate != '' ?
                    <p style={{'marginLeft':'3%','padding':'3px'}}>End Date :<span style={{'fontWeight':'bold'}}>{this.state.contractEndDate}</span></p> : null
                  }
                </div>
                <h5 className='titles' style={{marginLeft:10, marginTop:20}}><i className='fas fa-city' style={{marginRight:10}} />City</h5>
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret className='actionsBtn actionsBtnPicker' style={{width:'80%',backgroundColor:'white'}}>{this.state.selectedCity}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.cities.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleMenus('city',item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-box' style={{marginRight:10}} />Package</h5>
                <UncontrolledDropdown  className="mb-3 areaMenu">
                  <DropdownToggle caret disabled={this.state.selectedCity=='Choose'?true:false} className='actionsBtn actionsBtnPicker' style={{width:'80%',backgroundColor:'white'}}>{this.state.selectedPackage}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.packages.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleMenus('packages',item)}>{item.arName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-sitemap' style={{marginRight:10}} />Category</h5>
                <UncontrolledDropdown  className="mb-3 areaMenu">
                  <DropdownToggle caret disabled={this.state.selectedPackage=='Choose'?true:false} className='actionsBtn actionsBtnPicker' style={{width:'80%',backgroundColor:'white'}}>{this.state.selectedCategory}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.categories.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleMenus('category',item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <div>
                <Input type="checkbox" checked={this.state.isRenewable}  onChange={(e)=>this.setState({isRenewable:e.target.checked})}/> Renewable
              </div>
              {this.state.areas.length!=0?(
                <Col md='6' className='mulitSelectMaxHeight' style={{marginBottom:40}}>
                  <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-map' style={{marginRight:10}} />Areas</h5>
                  <MultiSelect
                    items={this.state.areas}
                    selectedItems={this.state.selectedArea}
                    onChange={(item)=>this.onChangeMultiSelect(item)}
                    withGrouping={true}
                    showSelectedItems={false}
                  />
                </Col>
              ):<Col md='6'/>}
              <Col md={10}>
                <h5 className='titles' style={{marginLeft:10}}><i className='fas fa-percent' style={{marginRight:10}} />Work Contract Percentage</h5>
                <div style={{justifyContent:'space-between',display:'flex',flexDireaction:'row'}}>
                  <div style={{width:'50%'}}>
                    <Input
                      value={this.state.percentage}
                      onChange={(e)=>this.onChange('percentage',e)}
                      type="text"
                      placeholder="Enter work contract percentage.."
                      autoComplete="username"
                      maxLength={10}
                      // onKeyPress={this.handleKeyPress}
                      style={{width:'100%',marginLeft:'5%', borderRadius:20}}
                    />
                  </div>
                  <div className='toggleCheckbox' onClick={()=>this.setState({companyPercantageType:'fixOnly'})}>
                    <i className={this.state.companyPercantageType=='fixOnly'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                    <span>Fix Only</span>
                  </div>
                  <div className='toggleCheckbox' onClick={()=>this.setState({companyPercantageType:'fixAndTools'})}>
                    <i className={this.state.companyPercantageType=='fixAndTools'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                    <span>Fix and Tools</span>
                  </div>
                  {this.state.companyPercantageType!=''?(
                    <div className='displayFlexRow ownCheckbox' style={{ color:'red'}} onClick={()=>this.setState({companyPercantageType:'', percentage:''})}>
                      <i className='fa fa-times-circle' style={{alignSelf:'center'}}/>
                      <span>Reset</span>
                    </div>
                  ):(
                    <div className='displayFlexRow ownCheckbox' style={{ color:'#fff'}}>
                      <i className='fa fa-times-circle' />
                      <span>Reset</span>
                    </div>
                  )}

                </div>

                <h5 className='titles' style={{marginLeft:10,marginTop:20}}><i className='fas fa-wallet' style={{marginRight:10}} />Basic Salary</h5>
                <Input
                  value={this.state.salary}
                  onChange={(e)=>this.onChange('salary',e)}
                  type="text"
                  placeholder="Enter basic salary.."
                  autoComplete="username"
                  maxLength={10}
                  // onKeyPress={this.handleKeyPress}
                  style={{width:'78%',marginLeft:'2%', borderRadius:20}}
                />
                <h5 className='titles' style={{marginLeft:10, marginTop:20}}><i className='fas fa-wallet' style={{marginRight:10}} />Minim salary</h5>
                <Input
                  value={this.state.minim}
                  onChange={(e)=>this.onChange('minim',e)}
                  type="text"
                  placeholder="Enter minim salary.."
                  autoComplete="username"
                  maxLength={10}
                  // onKeyPress={this.handleKeyPress}
                  style={{width:'78%',marginLeft:'2%', borderRadius:20}}
                />
                {/* === === === === === === === === === === === === === === === === === */}
                {/* Target Section*/}
                <h5 className='titles' style={{marginLeft:10,marginTop:10}}><i className='fas fa-bullseye' style={{marginRight:10,color:"red"}} />Target</h5>
                <div style={{justifyContent:'space-between',display:'flex',flexDireaction:'row'}}>
                  <div style={{width:'50%'}}>
                    <Input
                      value={this.state.target}
                      onChange={(e)=>this.onChange('target',e)}
                      type="text"
                      placeholder="Enter target amount.."
                      autoComplete="username"
                      maxLength={10}
                      style={{width:'100%',marginLeft:'5%', borderRadius:20}}
                    />
                  </div>
                  <div className='toggleCheckbox' style={{marginLeft:15}} onClick={()=>this.setState({targetType:'orderNumber'})}>
                    <i className={this.state.targetType=='orderNumber'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                    <span>Order Number</span>
                  </div>
                  <div className='toggleCheckbox' onClick={()=>this.setState({targetType:'fixed'})}>
                    <i className={this.state.targetType=='fixed'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                    <span>Fixed Amount</span>
                  </div>
                  {this.state.targetType!=''?(
                    <div className='displayFlexRow ownCheckbox' style={{ color:'red'}} onClick={()=>this.setState({targetType:'', target:''})}>
                      <i className='fa fa-times-circle' style={{alignSelf:'center'}}/>
                      <span>Reset</span>
                    </div>
                  ):(
                    <div className='displayFlexRow ownCheckbox' style={{ color:'#fff'}}>
                      <i className='fa fa-times-circle' />
                      <span>Reset</span>
                    </div>
                  )}
                </div>
                {/* End of Target Section*/}

                {/* Reward Section*/}
                <h5 className='titles' style={{marginLeft:10,marginTop:10}}><i className='fas fa-donate' style={{marginRight:10,color:"green"}} />Reward</h5>
                <div style={{justifyContent:'space-between',display:'flex',flexDireaction:'row'}}>
                  <div style={{width:'50%'}}>
                    <Input
                      value={this.state.reward}
                      onChange={(e)=>this.onChange('reward',e)}
                      type="text"
                      placeholder="Enter reward amount.."
                      autoComplete="username"
                      maxLength={10}
                      style={{width:'100%',marginLeft:'5%', borderRadius:20}}
                    />
                  </div>
                  <div className='toggleCheckbox' onClick={()=>this.setState({rewardType:'percentage'})}>
                    <i className={this.state.rewardType=='percentage'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                    <span>Percentage</span>
                  </div>
                  <div className='toggleCheckbox' onClick={()=>this.setState({rewardType:'fixed'})}>
                    <i className={this.state.rewardType=='fixed'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                    <span>Fixed Amount</span>
                  </div>
                  {this.state.rewardType!=''?(
                    <div className='displayFlexRow ownCheckbox' style={{ color:'red'}} onClick={()=>this.setState({rewardType:'', reward:''})}>
                      <i className='fa fa-times-circle' style={{alignSelf:'center'}}/>
                      <span>Reset</span>
                    </div>
                  ):(
                    <div className='displayFlexRow ownCheckbox' style={{ color:'#fff'}}>
                      <i className='fa fa-times-circle' />
                      <span>Reset</span>
                    </div>
                  )}
                </div>
                {/* End of Reward Section*/}
                {/* === === === === === === === === === === === === === === === === === */}
              </Col>


            </Row>

            <Row style={{padding:'15px 33px'}}>
              <h5 className='titles' style={{marginLeft:10, alignSelf:'center'}}><i className='fas fa-calendar-day' style={{marginRight:10}} />Order(s) Amount</h5>
              <Col md={8} className='orderAmount'>
                <Row style={{width:'100%'}}>
                  {this.state.renderOrderAmountArr.length == 0 ?(
                    <Col md={12} className='inputContainer'>
                      <Input
                        value={this.state.firstOrderAmount}
                        onChange={(e)=>this.onChange('bounce',e, 'firstOrder')}
                        type="text"
                        placeholder={!this.state.isUsedAsDefault?`Enter order ${this.state.order} amount..`:'Enter fixed order amount..'}
                        autoComplete="username"
                        maxLength={10}
                        // onKeyPress={this.handleKeyPress}
                        style={{borderRadius:20, width:'35%'}}
                      />
                      {!this.state.isUsedAsDefault?
                        <i className='fas fa-plus-circle iplus' style={{marginRight:10}} onClick={()=>this.nextOrderAmount()} />
                        :
                        <i className='fas fa-plus-circle ' style={{marginRight:10, color:'#777', fontSize:25}}/>
                      }
                      <div className='displayFlexRow ownCheckbox' style={{marginLeft:20}} onClick={()=>this.setState({isUsedAsDefault:!this.state.isUsedAsDefault})}>
                        <i className={this.state.isUsedAsDefault?'fa fa-check-square-o':'fa fa-square-o'} />
                        <span>Use as default</span>
                      </div>
                    </Col>
                  ):(
                    <div className='fullWidth'>
                      <Col md={12} className='inputContainer'>
                        <Input
                          value={this.state.firstOrderAmount}
                          onChange={(e)=>this.onChange('bounce',e, 'firstOrder')}
                          type="text"
                          placeholder={`Enter order ${this.state.order} amount..`}
                          autoComplete="username"
                          maxLength={10}
                          style={{borderRadius:20}}
                        />

                        <i className='fas fa-plus-circle iplus' style={{marginRight:10}} onClick={()=>this.nextOrderAmount()} />
                        <div className='displayFlexRow ownCheckbox' style={{ color:'red'}} onClick={()=>this.setState({renderOrderAmountArr:[],firstOrderAmount:'',order:1,})}>
                          <i className='fa fa-times-circle' />
                          <span>Reset</span>
                        </div>
                      </Col>
                      {this.state.renderOrderAmountArr.map((item, index)=>(
                        <Col md={12} className='inputContainer' key={index} style={{marginTop:15}}>
                          <Input
                            value={this.state['bounce'+index+1]}
                            onChange={(e)=>this.onChange('bounce',e, index+1)}
                            // type="text"
                            type="text" pattern="[0-9]*"
                            placeholder={`Enter order ${index+2} amount..`}
                            autoComplete="username"
                            maxLength={10}
                            // onKeyPress={this.handleKeyPress}
                            style={{borderRadius:20}}
                          />
                          <i className='fas fa-circle ' style={{marginRight:10, color:'#fff', fontSize:25}}/>
                        </Col>
                      ))}
                    </div>
                  )}

                </Row>
              </Col>

            </Row>
          </ModalBody>
        )
      }

      renderFinanceReport(){
        console.log(this.state.singleContract);
        return(
          <div>
            <Table hover bordered striped responsive size="md">
              <thead>
                <tr>
                  <th style={{textAlign:'center' }}>Key</th>
                  <th style={{textAlign:'center' }}>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{textAlign:'center',width:'40%'}}>Company Percentage</th>

                  <th style={{textAlign:'center',width:'40%'}}>
                    {this.state.singleContract.companyPercentage?(
                      <div style={{textAlign:'center'}}>{this.state.singleContract.companyPercentage}% with type {this.state.singleContract.isCompanyPercentageCalculatedOnTotalPrice?'Fix and Tools':'Fix Only'}</div>
                    ):<div style={{textAlign:'center'}}>N/A</div>}
                  </th>
                </tr>
                <tr>
                  <th style={{textAlign:'center',width:'40%'}}>Basic Salary</th>
                  <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.basicSalary} LE</div></th>
                </tr>
                <tr>
                  <th style={{textAlign:'center',width:'40%'}}>Minimum Salary</th>
                  <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.minimumSalary} LE</div></th>
                </tr>
                <tr>
                  <th style={{textAlign:'center',width:'40%'}}>Order Slots</th>
                  <th style={{textAlign:'center',width:'40%'}}>
                    <div style={{}}>
                      <ul style={{marginBottom:0,textAlign:'center', listStyle:'none', paddingLeft:0}}>
                        {
                          this.state.singleContract.orderSlots.length == 0 ? 'N/A' :
                          this.state.singleContract.orderSlots.map((slot,slotIndex)=>(
                            <li key={slotIndex}>Order#{slotIndex+1}: <span className="badge badge-dark">{slot.bonus} LE</span></li>
                          ))
                        }
                      </ul>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th style={{textAlign:'center',width:'40%'}}>Default Bonus</th>
                  <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.defaultBonus?this.state.singleContract.defaultBonus+' LE':'N/A'} </div></th>
                </tr>
                <tr>
                  <th style={{textAlign:'center',width:'40%'}}>Target</th>

                  <th style={{textAlign:'center',width:'40%'}}>
                    {this.state.singleContract.target?(
                      <div style={{textAlign:'center'}}>{this.state.singleContract.target}{this.state.singleContract.targetType == "fixed_amount" ? ' LE' : ' orders' }</div>
                    ):<div style={{textAlign:'center'}}>N/A</div>
                  }
                </th>
              </tr>
              <tr>
                <th style={{textAlign:'center',width:'40%'}}>Rewards</th>
                <th style={{textAlign:'center',width:'40%'}}>
                  {this.state.singleContract.reward?(
                    <div style={{textAlign:'center'}}>{this.state.singleContract.reward} {this.state.singleContract.rewardType == 'fixed' ? 'LE' : '%' } with type {this.state.singleContract.rewardType == 'fixed' ? 'Fixed Amount' : 'Percentage'}</div>
                  ):<div style={{textAlign:'center'}}>N/A</div>
                }
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }



}

export default withRouter(Contracts);
