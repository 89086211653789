import React from 'react'
import moment from 'moment'
import './../../assets/style/chat/ChatListItem.scss';
import Man from './../../assets/img/brand/download.png'
import { Badge } from 'reactstrap'

const renderMessage = ( message ) => {
    switch (message.type) {
      case 'text':
      case 'priceOffer':
      case 'sound':
          return message.body;
      case 'image':
          return message.body?message.body:'send new photo';
    }
}


export default function ChatListItem ({chat,onClickHandler}) {

    let user;
    const imageRef = React.createRef();
    const handleImageNotFound = () => imageRef.current.src = Man ;

    if(chat.type == 'Customer Support'){
      user = chat.customer;
    }else if(chat.type == 'Fixer Support'){
      user = chat.fixer;
    }

    return(
      <div
        className='chat_item'
        onClick ={ () => {
          chat.participants[0].unSeenMessagesCount = 0;
          onClickHandler(chat)
       }}
      >

        <div className='chat_item_avatar'>
          <img className='chat_item_avatar_icon' ref={imageRef} onError={handleImageNotFound} src={user.image} />
        </div>

        <div className='chat_item_metadata'>

          <div className='chat_item_metadata_header'>
            <div className='chat_item_metadata_header_username' >{user.enName?user.enName:user.arName}</div>
            {chat.lastMessage?(<div className='chat_item_metadata_header_time' style={{fontSize:'10px',color:'rgb(136, 149, 154)'}}>{moment(chat.lastMessage.createdAt).calendar()}</div>):null}
          </div>

          <div className='chat_item_metadata_message' >
            {chat.lastMessage?(<>
            <div className='chat_item_metadata_message_sender'>
              {chat.lastMessage.sender.enName}
            </div>
            <p className='chat_item_metadata_message_content'>{renderMessage(chat.lastMessage)}</p>
            {chat.participants[0].unSeenMessagesCount?(
            <div className='chat_item_metadata_message_seen-count'>
              <Badge color="secondary">{chat.participants[0].unSeenMessagesCount}</Badge>
            </div>
            ):null}
            </>):(
              <p className='chat_item_metadata_message_content'>There is no messages</p>
            )}
          </div>

        </div>

        <div className='clearfix'></div>

      </div>
    );
}
