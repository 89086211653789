import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert, CustomInput} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import Switch from "react-switch";
import firebase from 'firebase/app'
import 'firebase/database'
import {FModal,PriceOfferDetails} from './../../components'
import PriceOffersService from './../../services/offersServices/PriceOffersService'
import Paginator from './../../components/Pagination/V2/Paginator'
import SingleOrder from './../orders/SingleOrder'

class PriceOffers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      priceOfferDetailsModal:false,
      priceOffers:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      noResult:false,
      details:'',
      loading:false,
      order:'',
      showSingleOrder:false,

    }

  }

  componentDidMount(){
    this.getPriceOffers()
  }

  handlePriceOfferDetails(item){
      this.setState({priceOfferDetailsModal:!this.state.priceOfferDetailsModal,details:item,order:item.orderId})
  }

  getPriceOffers(){
    this.setState({loading:true})
    PriceOffersService.get(this.state.currentPage,(res)=>{
      this.setState({
        loading:false,
        priceOffers:res.data.priceOffers,
        noResult:!res.data.priceOffers.length,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        currentPage:res.data.paginationInfo.currentPage
      })
    })
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getPriceOffers())
  }

  getSingleOrder(orderId){
    this.setState({showSingleOrder:true,order:orderId})
  }

  reload =()=>{
    this.setState({showSingleOrder:false})
  }

  render() {
    return (
      <div className="app align-items-center">
        <Container>
        {this.state.showSingleOrder?<SingleOrder data={this.state.order} reload={this.reload}/>:null}

          <Card>
            {this.renderHeader()}
            {!this.state.noResult?(
              <CardBody className='shadow'>
                {this.renderPriceOffers()}
                <div style={{marginRight:15}}>
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)}/>
                </div>
              </CardBody>
            ):(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            )}
          </Card>

          <FModal
            title='Price Offer Details'
            content={
              <PriceOfferDetails
                ref={ref => this.priceOfferDetailsRef = ref}
                details={this.state.details}
                order={this.state.order}
              />
            }
            state={this.state.priceOfferDetailsModal}
            onClose={()=>this.setState({priceOfferDetailsModal:!this.state.priceOfferDetailsModal})}
            show={"none"}
          />

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderHeader(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Price Offers</span>
          </Col>

          <Col md="4">

          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderPriceOffers(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Order ID</th>
            <th style={{textAlign:'center'}}>Offer Price</th>
            <th style={{textAlign:'center'}}>Offer Status</th>
            <th style={{textAlign:'center'}}>Admin Name</th>
            <th style={{textAlign:'center'}}>Date And Time</th>
          </tr>
        </thead>
        <tbody>
          {this.state.priceOffers.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>
              <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingleOrder(item.orderId)}>{item.orderId}</Button>
              </td>
              <td style={{textAlign:'center'}}>
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.handlePriceOfferDetails(item)}>{item.price} LE</span>
              </td>
          <td style={{textAlign:'center'}}>{item.status.enName}</td>
          <td style={{textAlign:'center'}}>{item.user.name}</td>
          <td style={{textAlign:'center'}}>{item.createdAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

}

export default PriceOffers;
