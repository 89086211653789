import React, { Component } from 'react'
import { Button, Col, Row ,Table ,CardHeader ,Badge ,Modal ,ModalBody ,ModalHeader ,ModalFooter  ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Input ,Form,FormGroup,Label} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import { LoadingSpinner } from './../../components'



export default class AddNoteComplaint extends Component {
    constructor(props){
        super(props);
        this.state = {

        noteComplaint:'',
        loading:false,

        }

    }

    componentDidMount(){
    }

    handleChangeInputs = (e, handle) => {
       if(handle == 'noteComplaint'){
           this.setState({noteComplaint:e.target.value})
       }

    }

    addNoteToComplaint(){
      if(this.state.noteComplaint == "" || this.state.noteComplaint.trim() == ""){
        alert('Please , add note')
      }else{
        this.setState({loading:true})
        let data = {
          note : this.state.noteComplaint
        }
        Services.addNoteForComplaint(this.props.complaintId,data,(res)=>{
          this.setState({loading:false,noteComplaint:''},()=>this.props.close())
        })
      }
    }





   render(){
       return(
        <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <Row>
          <Col md="8">
            <Input
              type="textarea"
              value={this.state.noteComplaint}
              onChange={(e)=>this.handleChangeInputs(e, 'noteComplaint')}
              placeholder="Enter note details"
              className="addNote"
            />
          </Col>
        </Row>
        <LoadingSpinner fade loading={this.state.loading}/>
      </div>
       )
   }

}
