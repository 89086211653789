import React from 'react'
import { InputGroup , Input ,UncontrolledDropdown ,DropdownMenu , DropdownToggle , DropdownItem } from 'reactstrap'
import Services from './../../../services/Services'

class EditData extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      name:this.props.singleFixer.name,
      arName:this.props.singleFixer.arName,
      phone:this.props.singleFixer.phone,
      ssn:this.props.singleFixer.socialSecurityNumber,
      selectedCategory:this.props.singleFixer.category,
      categories:[],
    }
  }
  componentDidMount(){
    console.log(this.props.singleFixer)
    this.getCategories()
  }
  update(field,event){
    const re = /^[0-9\b]+$/,aRre = /[\u0660-\u0669]/,arregex = /[\u0600-\u06FF]/;
    switch (field) {
      case 'name':
      this.setState({name:event.target.value})
      break;
      case 'arName':
      if (arregex.test(event.target.value) || event.target.value == '') {
        this.setState({arName:event.target.value})
      }
      break;
      case 'phone':
      if(re.test(event.target.value) || event.target.value == ''){
        this.setState({phone:event.target.value})
      }else if (aRre.test(event.target.value)){
        alert('please enter phone number in english ')
      }
      break;
      case 'ssn':
      if(re.test(event.target.value) || event.target.value == ''){
        this.setState({ssn:event.target.value})
      }else if (aRre.test(event.target.value)){
        alert('please enter ssn number in english ')
      }
      break;
    }
  }
  getCategories(){
    Services.getCategories((res)=>{
      this.setState({categories:res.data.categories})
    })
  }

  submit(){
    let data = {
      name:this.state.name,
      arName:this.state.arName,
      phone:this.state.phone,
      ssn:this.state.ssn,
      categoryId:this.state.selectedCategory.id,
    }
    Services.editFixerData(this.props.singleFixer.id,data,(res)=>{
      if (res.message == "The given data was invalid.") {
        for (const err in res.errors) {
          if (res.errors.hasOwnProperty(err)) {
            const errMessages = res.errors[err];
            errMessages.forEach(alert);
          }
        }
        this.props.onError()
      }else{
        this.props.onClose()
      }
    })
  }

  render(){
    return(<>
      <div style={{padding:'15px',width:'50%'}}>
        <label style={{textAlign:'center'}}><strong>Enter Name</strong></label>
        <InputGroup style={{marginBottom:'20px'}}>
          <Input style={{padding:'20px'}} maxLength='150' placeholder='Enter Name ...' value={this.state.name} onChange={(e)=>this.update('name',e)}/>
        </InputGroup>
      </div>
      <div style={{padding:'15px',width:'50%'}}>
        <label style={{textAlign:'center'}}><strong>Enter Arabic Name</strong></label>
        <InputGroup style={{marginBottom:'20px'}}>
          <Input style={{padding:'20px'}} maxLength='150' placeholder='Enter Arabic Name ...' value={this.state.arName} onChange={(e)=>this.update('arName',e)}/>
        </InputGroup>
      </div>
      {/* <div style={{padding:'15px',width:'50%'}}>
      <label style={{textAlign:'center'}}><strong>Enter Phone</strong></label>
      <InputGroup style={{marginBottom:'20px'}}>
      <Input style={{padding:'20px'}} maxLength='11' placeholder='Enter Phone ...' value={this.state.phone} onChange={(e)=>this.update('phone',e)}/>
    </InputGroup>
  </div> */}
  <div style={{padding:'15px',width:'50%'}}>
    <label style={{textAlign:'center'}}><strong>Enter SSN</strong></label>
    <InputGroup style={{marginBottom:'20px'}}>
      <Input style={{padding:'20px'}} maxLength='14' placeholder='Enter SSN ...' value={this.state.ssn} onChange={(e)=>this.update('ssn',e)}/>
    </InputGroup>
  </div>
  <div style={{padding:'15px',width:'50%'}}>
    <label style={{textAlign:'center'}}><strong>Choose Category</strong></label>
    <UncontrolledDropdown  className="mb-3">
      <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedCategory.enName}</DropdownToggle>
      <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
        {this.state.categories.map((cat, index)=>(
          <DropdownItem key={index} onClick={()=>this.setState({selectedCategory:cat})}>{cat.enName}</DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  </div>
</>);
}
}
export default EditData ;
