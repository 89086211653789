import { writeToCache , readFromCache } from '../../core/helper';
import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class ChatService {

  static getCustomerChats(page,searchTerm,callBack){
    let api = `${Global.baseURL}admin-panel/chats/customer?page=${page}`;
    if (searchTerm != null) api+=`&searchTerm=${searchTerm}`
    Adaptor.get(api)
    .then((result)=> {
      callBack(result)
      writeToCache(api,result)
    },
    (error) => {
      const result = readFromCache(api);
      callBack(result)
    })
  }

  static getFixerChats(page,searchTerm,callBack){
    let api = `${Global.baseURL}admin-panel/chats/fixer?page=${page}`;
    if (searchTerm != null) api+=`&searchTerm=${searchTerm}`
    Adaptor.get(api)
    .then((result)=> {
      callBack(result)
      writeToCache(api,result)
    },
    (error) => {
      const result = readFromCache(api);
      callBack(result)
    })
  }

  static sendSchedualeMessagesToCustomerChat(data,chatId,callBack) {
    Adaptor.post(`${Global.baseURL}admin-panel/chats/${chatId}/schedule`,data).then(callBack)
  }

  static getScheduleMessages(page,callBack) {
    Adaptor.get(`${Global.baseURL}admin-panel/chats/schedule?page=${page}`).then(callBack)
  }

  static removeScheduleMessage(messageId,callBack) {
    Adaptor.delete(`${Global.baseURL}admin-panel/chats/schedule/${messageId}`).then(callBack)
  }

  static getQuotationChat(quotationId,callBack) {
    Adaptor.get(`${Global.baseURL}chats/quotation/${quotationId}`).then(callBack)
  }

  static getMessageInfo(id, page, callBack) {
    Adaptor.get(`${Global.baseURL}admin-panel/chat-messages/${id}/messageInfos?page=${page}`).then(callBack)
  }

}
