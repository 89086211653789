import React from 'react'
import ReactivePaginator from '../Pagination/ReactivePaginator';
import Services from './../../services/Services'
import {Dropdown , DropdownToggle , DropdownMenu , DropdownItem ,Spinner} from 'reactstrap'

class SnippitList extends React.Component
{
  constructor(props){
    super(props);
    this.state ={
      loading:false,
      snippits:[],
      currentPage:1,
      nextPages:[],
      isOpen:false
    }
  }
  componentDidMount(){
    this.getSnippits()
  }
  getNextSnippit(){
    console.log('firrred')
    if (this.state.nextPages.length) {
      this.setState(
        (prevState)=>({currentPage:prevState.currentPage+1}),
        ()=>this.getSnippits()
      )
    }
  }
  getSnippits(){
    this.setState({loading:true})
    Services.getChatSnippits(this.state.currentPage,(res)=>{
      this.setState((prevState) => ({
        loading:false,
        snippits:[...prevState.snippits,...res.data.snippits],
        nextPages:res.data.paginationLinks.nextPages,
      }))
    })
  }
  render(){
    return(
      <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Dropdown direction="up" isOpen={this.state.isOpen} toggle={()=>this.setState((pS)=>({isOpen:!pS.isOpen}))}>
          <DropdownToggle caret disabled={this.props.disabled} className='message-snippit-btn'>
            <span className="fa fa-2x fa-commenting-o" ></span>
          </DropdownToggle>
          <DropdownMenu >
            <ReactivePaginator id='30' updateContent={()=>this.getNextSnippit()} disabled={false}>
              {this.state.snippits.map((snippit) => (
                <DropdownItem key={snippit.id}>
                  <div className='truncate' onClick={()=>this.props.onClick(snippit)}>
                    {snippit.code}
                  </div>
                </DropdownItem>
              ))}
            </ReactivePaginator>
            {this.state.loading?(<div className="text-center"><Spinner size="sm" color="primary" /></div>):null}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
export default SnippitList;
