import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle ,Table} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Estimation extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount(){

  }


  render(){
    return(
      <div>
          <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Created At</th>
            {this.props.order.sourceCompanyId?(
              <th style={{textAlign:'center'}}>Actual</th>
            ):(
              <>
              <th style={{textAlign:'center'}}>Least</th>
              <th style={{textAlign:'center'}}>Highest</th>
              </>
            )}
            <th style={{textAlign:'center'}}>Cart Items</th>
          </tr>
        </thead>
        <tbody>
          {this.props.order.estimation != null ? (
            <tr>
              <td style={{textAlign:'center'}}>{this.props.order.estimation.createdAt}</td>
              {this.props.order.sourceCompanyId?(
                <td style={{textAlign:'center'}}>{this.props.order.estimation.actual} LE</td>
              ):(
                <>
                <td style={{textAlign:'center'}}>{this.props.order.estimation.least} LE</td>
                <td style={{textAlign:'center'}}>{this.props.order.estimation.highest} LE</td>
                </>
              )}

              <td style={{textAlign:'center'}}>
                <div style={{textAlign:'center'}}>
                  {this.props.order.items.length == 0 ? 'N/A' :
                  this.props.order.items.map((item,index)=>(
                    <p key={index}><span className="badge badge-dark">{item.quantity}</span>  {item.enName}</p>
                  ))}
                </div>
              </td>
            </tr>
          ):null}
        </tbody>
      </Table>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
