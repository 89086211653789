import Adaptor from './../Adaptor'


export default class workContractReportServices {

  
  static getWorkContractReport(api, callBack){
    Adaptor.get(api).then(callBack)
  }

  static getDatails(api, callBack){
    Adaptor.get(api).then(callBack)
  }

}
