import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
import Services from './../../services/Services';
import { validateUrl } from './../../core/helper'
import { LoadingSpinner } from './../../components'

export default class General extends React.Component{
  constructor(props){
    super(props)
    this.state={
      arTitle:'',
      enTitle:'',
      link:'',
      imagePreview:''
    }
  }

  componentDidMount(){

  }

  handleChangeInputs(e,type){
    if(type == 'link'){
      this.setState({link : e.target.value})
    }else if(type == 'enTitle'){
      this.setState({enTitle : e.target.value})
    }else {
      this.setState({arTitle : e.target.value})
    }
  }

  onSelectImageFromPC = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreview: reader.result
      })
    }
    reader.readAsDataURL(file)
  }

  getData(type){
    if (this.state.arTitle.trim() == '') {
      alert('Please, enter Arabic title')
    }else if (this.state.enTitle.trim() == '') {
      alert('Please, enter English title')
    }else if (this.state.link.trim() == '') {
      alert('Please, enter link')
    }else if (!this.state.link.includes('http') || !validateUrl(this.state.link)) {
      alert('Please, note link must contain http://... like https://www.Example.com ')
    }else if (!this.state.imageFile) {
      alert('Please, select image')
    }else {
      let fd = new FormData()

      fd.append('type',type)
      fd.append('additionalData[arName]',this.state.arTitle)
      fd.append('additionalData[enName]',this.state.enTitle)
      fd.append('additionalData[link]',this.state.link)
      fd.append('additionalData[image]',this.state.imageFile)

      this.props.createPost(fd)
    }
  }




  render(){
    return(
      <div>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Title Ar: </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.arTitle}
              onChange={(e)=>this.handleChangeInputs(e, 'arTitle')}
              type="text"
              placeholder="Arabic title.."
              maxLength={150}
            />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Title En: </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.enTitle}
              onChange={(e)=>this.handleChangeInputs(e, 'enTitle')}
              type="text"
              placeholder="English title.."
              maxLength={150}
            />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Link : </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.link}
              onChange={(e)=>this.handleChangeInputs(e, 'link')}
              type="text"
              placeholder="Link ..."
              maxLength={150}
            />
          </Col>
        </Row>

        <Row style={{marginTop:30}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Images :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={(e)=>this.onSelectImageFromPC(e)}
            />
          </Col>
          <Col md='6'>
            <p><strong>Note: </strong>when insert image must the width double the length or upload this dimensions 362*138</p>
          </Col>
        </Row>
        {this.state.imagePreview != '' ?(
          <Row style={{marginTop:10}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview: </Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
            </Col>
          </Row>
        ):null}

      </div>
    )
  }
}
