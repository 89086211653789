import React, { Component } from "react";
import {
  Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Table, CardHeader, Badge, PaginationItem, PaginationLink, Pagination, FormGroup, Label,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";
import { Global } from "../../core/Global";
import Services from "../../services/Services";
import LoadingOverlay from "react-loading-overlay";
import {FModal} from './../../components'
import AddItem from './AddItem'
import Paginator from './../../components/Pagination/V2/Paginator'
import { NoResult } from '../../components'
import { buildQueryStringFromArray } from './../../core/helper'

export default class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemModal:false,
      currentPage:1,
      pricingItems:[],
      previousPages:[],
      nextPages:[],
      noResult:false,
      loading:false,
      modalPricingDetails:false,
      cities:[],
      categories:[],
      selectedCategories:[],
      selectPriceType:'',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCategories()
  }


  handleAddItem(){
    this.setState({itemModal:!this.state.itemModal,currentPage:1},()=>{this.getValidItems('');this.paginatorRef.reset()})
  }

  getValidItems(query){
    this.setState({loading:true})
    Services.getValidItems(this.state.currentPage, query, (res)=>{
      if (res.message == 'success') {
        this.setState({
          pricingItems:res.data.item.pricingItems,
          // previousPages:res.data.item.paginationLinks.previousPages.reverse(),
          // nextPages:res.data.item.paginationLinks.nextPages,
          noResult:!res.data.item.pricingItems.length,
          previousPages:res.data.item.paginationLinks.previousPages.reverse(),
          nextPages:res.data.item.paginationLinks.nextPages,
          currentPage:res.data.item.paginationInfo.currentPage,
          loading:false
        },()=>this.forceUpdate())
      }
    })
  }
  getCategories(){

    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr},()=>this.getValidItems(''))
    })
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.filter(page))
  }

  filter(page){
    let query = ''
    if (this.state.selectedCategories.length!=0 && !(this.state.selectedCategories[0].id == 'all')) {
      query+=buildQueryStringFromArray('categories',this.state.selectedCategories)
    }
    if (this.state.selectPriceType != '') {
      query+=`&type=${this.state.selectPriceType}`
    }
    // this.changePage(1)
// this.getValidItems(query)
    this.setState({currentPage:page},()=>this.getValidItems(query))
  }

  handleMultiSelectMenu(type,item){
    if (item.id == 'choose') {
      this.state[type] = [];
    }else if(item.id == 'all'){
      this.state[type]=[];
      this.state[type].push(item);
    }else{
      if (this.state[type].includes(item)) {
        alert('you have been select this item before')
      }else{
        this.state[type] = this.state[type].filter( item => item.id != 'all' )
        this.state[type].push(item)
      }
    }
    this.forceUpdate()

  }
  removeMultiFilter(type,index){
    this.state[type].splice(index,1)
    this.forceUpdate()
  }

  resetFilter(){
    // this.child.clearStatuses()
    this.setState({
      selectedCategories:[],
    },()=>this.getValidItems(''))
  }


  render() {
    return (
      <div className="app align-items-center">
        <Container>
          {this.renderFilter()}

          <Card>
            {this.renderSearch()}
            {this.state.noResult == true ? <NoResult/> :
              <>
              {this.renderItems()}
              <div style={{marginRight:20}}>
                <Paginator ref={ref=>this.paginatorRef = ref} prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)}/>
              </div>
            </>
          }
        </Card>
        <FModal
          title="Add Item"
          content={
            <AddItem
              ref={ref=>this.addItemRef = ref}
              closeHandler={()=>this.handleAddItem()}
            />
          }
          state={this.state.itemModal}
          onClose={()=>this.handleAddItem()}
          onSubmit={()=>this.addItemRef.addItem()}
        />
        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className="loading">
          <LoadingOverlay
            active={this.state.loading}
            spinner
            text="Loading..."
            style={{ backgroundColor: "#00000060" }}
          />
        </Modal>
        <FModal
          title='Pricing Details'
          content={this.pricingDetails()}
          state={this.state.modalPricingDetails}
          onClose={()=>this.setState({modalPricingDetails:false, cities:[]})}
          noSubmitBtn={true}
        />
      </Container>
    </div>
  );
}
renderSearch() {
  return (
    <CardHeader className="shadow">
      <Row>
        <Col md="8" style={{ display: "flex", alignItems: "center" }}>
          <i className="fa fa-align-justify"></i>
          <span style={{ marginLeft: 6 }}>All Items</span>
        </Col>

        <Col md="4">
          <Button
            className="actionsBtn"
            style={{
              marginBottom: "2%",
              display: "block",
              marginLeft: "auto"
            }}
            onClick={() => this.setState({itemModal:!this.state.itemModal})}
            >
              <i className="fa fa-plus-square" style={{ marginRight: 8 }} />
              Add Item
            </Button>
          </Col>
        </Row>
      </CardHeader>
    );
  }

  pricingDetails(){
    return(
      <div style={{marginLeft:20}}>
        {this.state.cities.map((item,index)=>(
          <div key={index} style={{textAlign:'left'}}><span style={{fontWeight:'bold'}}>{item.enName}:</span>
          <ul style={{textAlign:'left'}}>
            <li>fix    : {item.fixingPrice} LE</li>
            <li>tools  : {item.toolsPrice} LE</li>
          </ul>
        </div>
      ))}
    </div>

  )
}

renderItems(){
  return(
    <Table hover bordered striped responsive size="md">
      <thead>
        <tr>
          <th style={{textAlign:'center'}}>ID</th>
          <th style={{textAlign:'center'}}>EnName</th>
          <th style={{textAlign:'center'}}>ArName</th>
          <th style={{textAlign:'center'}}>Category</th>
          <th style={{textAlign:'center'}}>Service Group</th>
          <th style={{textAlign:'center'}}>Expected Time</th>
          <th style={{textAlign:'center'}}>Parent</th>
          <th style={{textAlign:'center'}}>Type</th>
          <th style={{textAlign:'center'}}>Price/Complixity</th>
          <th style={{textAlign:'center'}}>Creation Date</th>
        </tr>
      </thead>
      <tbody>
        {this.state.pricingItems.map((item,index)=>(
          <tr key={index}>
            <td style={{textAlign:'center'}}>{item.id}</td>
            <td style={{textAlign:'center'}}>{item.enName}</td>
            <td style={{textAlign:'center'}}>{item.arName}</td>
            <td style={{textAlign:'center'}}>{item.category.enName}</td>
            <td style={{textAlign:'center'}}>{item.serviceGroup?item.serviceGroup.enName:'N/A'}</td>
            <td style={{textAlign:'center'}}>{item.expectedTime >= 24 ? item.expectedTime/24 +' days' : item.expectedTime.toString().split(".").shift() + " h"+"      "+item.expectedTime.toString().split(".").pop() +' m'}</td>
            <td style={{textAlign:'center'}}>{item.parent?item.parent.enName:'N/A'}</td>
            <td style={{textAlign:'center'}}>{item.itemTypeId == 1? 'Complexity' : item.itemTypeId == 2 ? "Meter and Quantity" : "N/A"}</td>
            <td style={{textAlign:'center'}}>{item.itemTypeId == 1 ? item.complexity : item.itemTypeId == 2 ? (
              <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({modalPricingDetails:true, cities:item.cities })}>View Details</span>
            ): "N/A"}</td>
            <td style={{textAlign:'center'}}>{item.createdAt}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}


renderFilter(){
  return(
    <>
    <Row style={{marginLeft:-5}}>
      <UncontrolledDropdown  className="" style={{marginRight:20}} >
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Category</DropdownToggle>
        <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          {this.state.categories.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedCategories',item)}>{item.enName}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>

      <UncontrolledDropdown  className="" style={{marginRight:20}}>
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fa fa-money' style={{marginRight:10}} />
        {this.state.selectPriceType==''?'Price Type':this.state.selectPriceType}
      </DropdownToggle>
        <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          <DropdownItem onClick={()=>this.setState({selectPriceType:''})}>Choose</DropdownItem>
          <DropdownItem onClick={()=>this.setState({selectPriceType:'complexity'})}>Complexity</DropdownItem>
          <DropdownItem onClick={()=>this.setState({selectPriceType:'meter_and_quantity'})}>Meter and Quantity</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      <Button onClick={()=>this.filter(1)} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
      <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
    </Row>
    <Row style={{padding:'0px 10px'}}>
      {this.renderFiltersInfo()}
    </Row>
  </>
)
}

renderFiltersInfo(){
  return (<>
    <Row  style={{padding:'0px 0px 20px 0px'}}>
      {this.state.selectedCategories.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Categories </span>
          <div>
            {this.state.selectedCategories.map( (category,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedCategories',i)} >
                {`${category.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
    </Row>
  </>)
}




}
