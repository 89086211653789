import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import io from 'socket.io-client';
import { Global } from './core/Global';

window.socket = io(Global.socketUrl,{
    reconnection: true,
    reconnectionAttempts:Infinity,
});

window.socket.io.on('close',()=>{
    console.log('socket closing');
})
window.socket.io.on('open',()=>{
    console.log('socket opening');
    window.socket.emit('auth',{
        authToken:JSON.parse(localStorage.getItem('userData')).accessToken
    });
})

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
