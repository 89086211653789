import React,{Fragment} from 'react';
import {DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'
import LoadingSpinner from './../LoadingSpinner';
import Services from './../../services/Services';
class AreaFilter extends React.Component{
    constructor(props){
        super(props)
        this.state={
            areas:[],
            selectedArea:'Area',
            areaId:null,
            loading:true
        }
    }
    clear(){
        this.setState({selectedArea:'Area',areaId:null})
    }
    componentDidMount(){
        console.log('from filter')
        if(this.state.selectedCityId){
            this.getAreas()
        }
    }
    setSelectedArea(item){
        this.setState({
            areaId:item.id,
            selectedArea:item.enName,
        })
    }
    getAreaId(){
     return this.state.areaId?this.state.areaId:false
    }
    getAreas = (parent,cityid=0) => {
      parent.enableLoading()
      this.clear()
      Services.getArea(cityid, (res)=>{
        let obj = {enName:'All',id:'all'}
        let arr = res.data.areas
        arr.push(obj)
        this.setState({areas:arr, loading:false})
        parent.disableLoading()
      })
    }
    render(){
        return(
            <Fragment>
                <UncontrolledDropdown className="mb-3 areaMenu">
                    <DropdownToggle caret disabled={this.props.disabled || (this.props.selectedCity=='City'||this.props.selectedCity=='Choose' || this.props.selectedCity=='All')?true:false} style={{width:'100%'}} className='actionsBtn'><i className='fas fa-map-marker-alt' style={{marginRight:10}} />{this.state.selectedArea}</DropdownToggle>
                    <DropdownMenu style={{width:'100%'}}>
                    {this.state.areas.map((item, index)=>(
                        <DropdownItem key={index} onClick={()=>{this.props.handler(item);this.setSelectedArea(item)}}>{item.enName}</DropdownItem>
                    ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Fragment>
        )
    }
}
export default AreaFilter;
