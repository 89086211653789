import React from 'react'
import {Modal,ModalBody,ModalFooter,ModalHeader,Button} from 'reactstrap'
import { LoadingSpinner } from '../../components'
import Services from './../../services/Services'

class ConfirmationPricingOrder extends React.Component{
  constructor(props){
    super(props);
    this.state={
      loading:false
    }
  }
  confirmOrder(){
    this.setState({loading:true})
    let reqData = {
      offerId:this.props.data.offer.id
    }
    let type = 'offer'
    Services.pricingOrder(reqData, this.props.data.orderId, type, (res)=>{
      this.setState({loading:false},()=>this.props.onClose(),this.props.reload())
    })
}
  render(){
    return(
      <>
      <Modal fade={false} isOpen={this.props.state} toggle={()=>this.props.onClose()} className={this.props.className}>
        <ModalHeader toggle={()=>this.props.onClose()}>Confirm Offer Pricing</ModalHeader>
        <ModalBody>
          <div style={{paddingLeft:'15px'}}>Are you sure price order with {this.props.data.offer.cities[0].price} L.E ?</div>
        </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn btnClose' onClick={()=>this.props.onClose()}>Close</Button>
          <Button className='actionsBtn' style={{display:this.props.show}} onClick={()=>this.confirmOrder()}>Confirm</Button>
          <Button className='actionsBtn' style={{display:this.props.show}} onClick={()=>this.props.editAction()}>Edit</Button>
        </ModalFooter>
      </Modal>
      <LoadingSpinner loading={this.state.loading} />
      </>
    )
  }
}
export default ConfirmationPricingOrder;
