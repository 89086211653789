import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label } from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'

export default class AddPost extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        types:[],
        selectedType:'Types',
        selectedTypeId:'',
    }

  }
  componentDidMount(){
    this.getPostsTypes()
  }


getPostsTypes(){
  Services.getPostsTypes((res)=>{
    this.setState({types : res.data.types})
  })
}


  handleTypes(item){
      this.setState({selectedType : item.type , selectedTypeId:item.id,})
  }


  addPost(){
    if(this.state.selectedTypeId == 1){
      this.promotedServiceGroupRef.getData(this.state.selectedType)
    }else if(this.state.selectedTypeId == 3){
      this.promocodeRef.getData(this.state.selectedType)
    }else if(this.state.selectedTypeId == 5){
      this.generalRef.getData(this.state.selectedType)
    }else if(this.state.selectedTypeId == 6){
      this.keywordsRef.addPost()
    }else if(this.state.selectedTypeId == 4){
      this.offerRef.addPost(this.state.selectedType)
    }else if(this.state.selectedTypeId == 2){
      this.featureServiceGroupRef.getData(this.state.selectedType)
    }
  }

  createPost(data){
    this.setState({loading:true})
    Services.createHomeSection(data, res => {
      this.setState({loading:false},()=>this.props.update())
    })
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Posts Types :</Label>
          </Col>
          <Col md="4">
            <UncontrolledDropdown  className="mb-3">
              <DropdownToggle caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}><i className='fas fa-layer-group' style={{marginRight:10}} />{this.state.selectedType}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}}>
                {this.state.types.map((item, index)=>(
                  <DropdownItem key={index} onClick={()=>this.handleTypes(item)}>{item.type}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        {this.state.selectedTypeId == 1 ?
          <PromotedServiceGroup ref={ref => this.promotedServiceGroupRef = ref} createPost={(data)=>this.createPost(data)}/>
        : this.state.selectedTypeId == 5 ?
          <General ref={ref => this.generalRef = ref} createPost={(data)=>this.createPost(data)}/>
        : this.state.selectedTypeId == 3 ?
          <PromoCode ref={ref => this.promocodeRef = ref} createPost={(data)=>this.createPost(data)}/>
        :this.state.selectedTypeId == 6 ?
          <Keywords ref={ref => this.keywordsRef = ref} addPost={(data)=>this.createPost(data)}/>
        :this.state.selectedTypeId == 4 ?
          <Offer ref={ref => this.offerRef = ref} createPost={(data)=>this.createPost(data)}/>
        :this.state.selectedTypeId == 2 ?
        <FeaturedServiceGroup ref={ref => this.featureServiceGroupRef = ref} createPost={(data)=>this.createPost(data)}/>
        :null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
      )
    }
  }
