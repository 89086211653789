import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Tooltip, UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import OrdersService from './../../services/orderServices/OrdersService'

import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import SingleCustomer from './../customers/SingleCustomer'
import SingleFixer from './../fixers/SingleFixer'
import SingleOrder from './SingleOrder'
import moment from 'moment'
import StatusBadge from './../../components/StatusBadge'
import OutOfWorkContract from './../../components/OutOfWorkContract'
import LoadingSpinner from './../../components/LoadingSpinner'
import { buildQueryStringFromArray } from './../../core/helper'

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm:'',
      orders:[],
      paginationInfo:'',
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      singleOrder:'',
      showSingleOrder:false,
      showSingleCustomer:false,
      showSingleFixer:false,
      loading:false,
      currentPage:1,
      searchText:'',
      noResult:true,
      categories:[],
      statuses:[],
      cities:[],
      serviceGroups:[],
      areas:[],
      agents:[],
      companies:[],
      selectedCities:[],
      selectedAreas:[],
      selectedCompanies:[],
      selectedCategories:[],
      selectedServiceGroups:[],
      selectedStatuses:[],
      selectedType:'All',
      selectedAgents:[],
      singleFixer:'',
      searchTerm:'',
      toDate:'',
      requestedDate:'',
      text:'',
      selectedTypeName:'',
      tooltipOpen: false,
      orderTypeMenuOptions:Global.orderTypes,
      tab:'',
      workContractStatus:'',
      workContractStatusId:null,
      selectedUnCoveredFixer:0,
      selectedUnCoveredRequestedDate:0,
      filterChat:'Choose',
      filterPayment:'',
      filterPaymentValue:'',
      filterFromComplain:'Choose',
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleOrderCreateEvent = this.handleOrderCreateEvent.bind(this)
    this.handleOrderUpdateEvent = this.handleOrderUpdateEvent.bind(this)
    // this.toggle = this.toggle.bind(this)
  }

  componentDidMount(){
    window.socket.on('orderCreate',this.handleOrderCreateEvent)
    window.socket.on('orderUpdate',this.handleOrderUpdateEvent)

    if (this.props.match.params.id==undefined) {
      this.getCities()
    }else {
      let item = {id:this.props.match.params.id}
      this.getSingle('order',item)
    }

    window.isForbidden = () => {
      console.log('omarrrrr');
      this.props.history.push('/403')
    }

    window.scrollTo(0, 0)
  }

  componentWillUnmount(){
    window.socket.removeEventListener('orderCreate',this.handleOrderCreateEvent)
    window.socket.removeEventListener('orderUpdate',this.handleOrderUpdateEvent)
  }

  handleOrderCreateEvent(order){
    console.log(order);
    if (this.state.currentPage == 1) {
      this.setState( pState => ({
        orders: [ order , ...(pState.orders.slice()) ]
      }))
    }
  }

  handleOrderUpdateEvent(order){
    console.log(order);
    const replaceOrderWithUpdatedOrder = ( o ) => {
      return o.id == order.id ? order : o ;
    }

    this.setState( pState => ({
      orders : pState.orders.map(replaceOrderWithUpdatedOrder)
    }))

  }


  toggle(item) {
    if (item.id == item.id) {
      this.setState({tooltipOpen: !this.state.tooltipOpen,text:item.name})
    }else {
      this.setState({tooltipOpen: this.state.tooltipOpen,text:''})
    }
  }



  reload = () => {
    this.setState({showSingleOrder:false,showSingleCustomer:false,showSingleFixer:false, })
    console.log(this.state.currentPage);
    this.getOrders()
  }
  handleChangeInputs = (e, handle) => {
    this.setState({searchTerm:e.target.value})
  }
  handleKeyPress(target) {
    if(target.charCode==13)
    this.setState({currentPage:1},()=>this.getOrders())
  }

  getCities(){
    this.setState({loading:true})
    Services.getCities((res)=>{
      let arr = res.data.cities
      arr.push({enName:'All',id:'all'})
      arr.unshift({enName:'Choose',id:'choose'})
      this.setState({cities:arr},()=>this.getCategories())
    })
  }
  getArea(){
    this.setState({loading:true})
    Services.getArea(this.state.selectedCities, (res)=>{
      console.log('getArea',res);
      let arr = res.data.areas
      arr.push({enName:'All',id:'all'})
      arr.unshift({enName:'Choose',id:'choose'})
      this.setState({areas:arr, loading:false})
    })
  }

  getCategories(){
    Services.getCategories((res)=>{
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push({enName:'All',id:'all',children:[]})
      arr.unshift({enName:'Choose',id:'choose',children:[]})
      this.setState({categories:arr},()=>this.getServiceGroups())
    })
  }

  getCategoriesThatBelongToSpecificArea(){
    console.log('getCategoriesThatBelongToSpecificArea');
    this.setState({loading:true})
    Services.getCategoriesThatBelongToSpecificArea(this.state.selectedAreaId, (res)=>{
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push({enName:'All',id:'all',children:[]})
      arr.unshift({enName:'Choose',id:'choose',children:[]})
      this.setState({categories:arr, loading:false})
    })
  }

  getServiceGroups(){
    Services.getAllServiceGroups((res)=>{
      let arr = [{id:'choose',enName:'Choose'}]
      for (let i = 0; i < res.data.serviceGroups.length; i++) {
        arr.push(res.data.serviceGroups[i]);
      }
      arr.push({id:'all',enName:'All'})
      arr.push({id:[],enName:'N/A'})
      this.setState({serviceGroups:arr},()=>this.getStatuses())
    })
  }

  getStatuses(){
    Services.getStatuses('order',(res)=>{
      let arr = res.data.statuses
      arr.push({enName:'All',id:'all'})
      arr.unshift({enName:'Choose',id:'choose'})
      this.setState({statuses:arr},()=>this.getAgents())
    })
  }


  getAgents(){
    Services.getAgents((res)=>{
      let arr = res.data.admins
      arr.push({enName:'All',id:'all'})
      arr.push({enName:'N/A',id:[]})
      arr.unshift({enName:'Choose',id:'choose'})
      this.setState({agents:arr},()=>this.getCompanies())
    })
  }

  getCompanies(){
    OrdersService.getCompanies((res)=>{
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.companies
      arr.map(e=> e.enName = e.name)
      // arr.push(obj)
      // arr.push(notAvailable)
      arr.unshift(choose)
      this.setState({companies:arr},()=>this.getOrders())
    })
  }





  getSingle(type, item){
    console.log(item);
    switch (type) {
      case 'customer':
      this.setState({showSingleCustomer:true, singleCustomer:item, showSingleFixer:false,showSingleOrder:false})
      break
      case 'order':
      this.setState({showSingleOrder:true, singleOrder:item, showSingleFixer:false,showSingleCustomer:false,})
      break
      case 'fixer':
      this.setState({showSingleFixer:true, singleFixer:item, showSingleCustomer:false,showSingleOrder:false})
      break
      default:
    }
  }

  getOrders(type){
    let api = ''
    if (type == 'filter') api = this.filter('filter')
    else api = this.filter()

    if (type == 'filter' && api == `${Global.baseURL}admin-panel/orders?page=${this.state.currentPage}`) {
      alert('Please, choose filter')
      return true
    }
    if (this.state.searchTerm != '') {
      this.setState({currentPage:1})
      api+=`&searchTerm=${this.state.searchTerm}`
    }
    this.setState({loading:true,showSingleOrder:false,showSingleCustomer:false,showSingleFixer:false})

    Services.getOrders(api, (res)=>{
      if(res.message == 'forbidden'){
        this.props.history.push('/403');
      }else{
        this.setState({
          loading:false,
          orders:res.orders,
          paginationInfo:res.paginationInfo,
          paginationLinks:res.paginationLinks,
          previousPages:res.paginationLinks.previousPages.reverse(),
          nextPages:res.paginationLinks.nextPages,
          noResult:res.orders.length==0?false:true,
          searchText:this.state.searchTerm!=''?this.state.searchTerm:'',
        })
        let adminAccount = localStorage.getItem('adminAccount')
        if (adminAccount == null) this.getAdminAccount()
      }
    })
  }
  getAdminAccount(){
    Services.getAdminAccount(async (res)=>{
      await localStorage.setItem('adminAccount',JSON.stringify(res.data.admin))
    })
  }

  prevPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page-1},()=>this.getOrders())
    }else {
      this.setState({currentPage: item},()=>this.getOrders())
    }
  }
  nextPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page+1},()=>this.getOrders())
    }else {
      this.setState({currentPage: item},()=>this.getOrders())
    }
  }

  removeMultiFilter(type,index){
    this.state[type].splice(index,1)
    this.forceUpdate()
  }

  handleMultiSelectMenu(type,item){
    console.log(item.enName == "N/A")
    if (item.id == 'choose') {
      this.state[type] = [];
    }else if(item.id == 'all'){
      this.state[type]=[];
      this.state[type].push(item);
    }else if(item.enName == "N/A"){
      this.state[type]=[];
      this.state[type].push(item)
    }else if(item.enName != "N/A"){
      this.state[type] = this.state[type].filter( item => item.enName != 'N/A' )
      this.state[type].push(item)
    }else{
      if (this.state[type].includes(item)) {
        alert('you have been select this item before')
      }else{
        this.state[type] = this.state[type].filter( item => item.id != 'all' )
        this.state[type].push(item)
      }
    }
    this.forceUpdate()

  }



  handleMenus(menu, item){
    switch (menu) {
      case 'type':
        if (item.id == 1) {
          this.setState({selectedType: item.enName , selectedTypeName:'all'})
        }else {
          this.setState({selectedType: item.enName,selectedTypeName:'inquiries'})
        }
      break
      case 'unCovered':
      console.log(item);
      if (item.id == 1 || item.id == 3){
        this.setState({selectedUnCoveredFixer: 1, selectedUnCoveredRequestedDate: 0})
      }else if (item.id == 2){
        this.setState({selectedUnCoveredFixer: 0, selectedUnCoveredRequestedDate: 1})
      }else {
        this.setState({selectedUnCoveredFixer: 0, selectedUnCoveredRequestedDate: 0})
      }
      break
      default:
    }
  }

  resetFilter(){
    // this.child.clearStatuses()
    this.setState({
      selectedCities:[],
      selectedAreas:[],
      selectedStatuses: [],
      selectedAgents: [],
      selectedCompanies: [],
      selectedCategories: [],
      selectedServiceGroups:[],
      selectedType: 'All',
      selectedTypeName:'',
      requestedDate:'',
      fromDate:'',
      currentPage:1,
      tab:'',
      selectedUnCoveredRequestedDate:0,
      selectedUnCoveredFixer:0,
      filterChat:'Choose',
      filterFromComplain:'Choose',
      filterPayment:'',
      filterPaymentValue:''
    },()=>this.refresh())
  }
  refresh(){
    this.getOrders()
  }

  filter(type){
    let api = ''
    if (type =='filter') {
       api = `${Global.baseURL}admin-panel/orders?page=1`
    }else {
       api = `${Global.baseURL}admin-panel/orders?page=${this.state.currentPage}`
    }


    if (this.state.selectedCities.length!=0 && !(this.state.selectedCities[0].id == 'all')) {
      api+=buildQueryStringFromArray('cities',this.state.selectedCities)
    }
    if (this.state.selectedCategories.length!=0 && !(this.state.selectedCategories[0].id == 'all')) {
      api+=buildQueryStringFromArray('categories',this.state.selectedCategories)
    }
    if (this.state.selectedStatuses.length!=0 && !(this.state.selectedStatuses[0].id == 'all')) {
      api+=buildQueryStringFromArray('statuses',this.state.selectedStatuses)
    }
    if (this.state.selectedAreas.length!=0 && !(this.state.selectedAreas[0].id == 'all')) {
      api+=buildQueryStringFromArray('areas',this.state.selectedAreas)
    }
    if (this.state.selectedAgents.length!=0 && !(this.state.selectedAgents[0].id == 'all') && !(this.state.selectedAgents[0].id == [])) {
      api+= buildQueryStringFromArray('admins',this.state.selectedAgents)
    }
    if (this.state.selectedCompanies.length!=0 && !(this.state.selectedCompanies[0].id == 'all') && !(this.state.selectedCompanies[0].id == [])) {
      api+= buildQueryStringFromArray('sourceCompanyId',this.state.selectedCompanies)
    }
    if (this.state.selectedTypeName != '' ) {
      api+=`&source=${this.state.selectedTypeName}`
    }
    if (this.state.selectedUnCoveredRequestedDate || this.state.selectedUnCoveredFixer) {
      api+=`&fwcCoverage=${0}&fixerCoverage=${this.state.selectedUnCoveredFixer}`
    }
    if(this.state.selectedServiceGroups.length!=0 && !(this.state.selectedServiceGroups[0].id == 'all') && !(this.state.selectedServiceGroups[0].id == [])){
      api+= buildQueryStringFromArray('serviceGroups',this.state.selectedServiceGroups)
    }
    if (this.state.filterChat == 'Has Chat' ) {
      api+=`&chat=1`
    }
    if (this.state.filterFromComplain == 'From Complain' ) {
      api+=`&fromComplaint=1`
    }
    if (this.state.filterPayment != '' ) {
      api+=`&paymentMethod=${this.state.filterPaymentValue}`
    }



    // if (this.state.searchTerm != '') {
    //   this.setState({currentPage:1})
    //   api+=`&searchTerm=${this.state.searchTerm}`
    // }
    if (this.state.fromDate) {
      this.state.tab!=3 ? api+=`&requestedDate=${this.state.fromDate}` : api+=`&outDated=${this.state.fromDate}`
      if (type == 'withCall') {
        this.getOrders()
      }
    }

    return api
  }



  handleOnChangeDate(type, date){
    let day = new Date (date+' Z')
    if (type == 'from') {
      this.setState({fromDate: day.toISOString().split('T')[0], requestedDate: date,})
    }else {
      this.setState({toDate: day.toISOString().split('T')[0], requestedDate: date},()=>console.log(this.state.toDate))
    }
  }

  getDate(option){
    let dateObj = new Date();
    if(option == "tommorow")
       dateObj.setDate(dateObj.getDate() + 1);

    let year = dateObj.getFullYear();
    let month = dateObj.getMonth()+1;
    let day = dateObj.getDate();

    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    return year+"-"+month+"-"+day;
  }

  handleRenderOrderStatus(item){
    // console.log(item);
    switch (item.status.id) {
      case 1:
      if (item.description) {
        return(
          <>
            <Badge className='shadow truncate' color='warning' id={'statusTooltip'+item.id} style={{direction:item.description.match(/[\u0600-\u06FF]/)?'rtl':'ltr'}}>{item.description}</Badge>
            <UncontrolledTooltip target={'statusTooltip'+item.id}>{item.description}</UncontrolledTooltip>
          </>
      )
    }else {
      return <Badge className='shadow truncate' color='warning'>{item.status.enName}</Badge>
    }
    break
    case 2:
    if (item.description) {
      return(
        <>
          <Badge className='shadow truncate' color='success' id={'statusTooltip'+item.id} style={{direction:item.description.match(/[\u0600-\u06FF]/)?'rtl':'ltr'}}>{item.description}</Badge>
          <UncontrolledTooltip target={'statusTooltip'+item.id}>{item.description}</UncontrolledTooltip>
        </>
    )
  }else {
    return <Badge className='shadow truncate' color='success'>{item.status.enName}</Badge>
  }
  break
  case 3:
  return <Badge className='shadow truncate' color='primary'>{item.status.enName}</Badge>
  break
  case 4:
  if (item.lastInvoice) {
    if(item.lastInvoice.isPaid==1){
      return (
        <Badge className='shadow truncate' color='dark'>
          <div>
            {item.lastInvoice.totalPrice} EGP <br/>
            <span style={{fontSize:10, fontWeight:'normal'}}>paid</span>
          </div>
        </Badge>
      )
    }else{
      return <Badge className='shadow truncate' color='dark'>{item.lastInvoice.totalPrice} EGP</Badge>
    }
  }else {
    return <Badge className='shadow truncate' color='dark'>{item.status.enName}</Badge>
  }
  break
  case 30:
  return <Badge className='shadow truncate' color='primary' style={{backgroundColor:'#7D67B5'}}>{item.status.enName}</Badge>
  break
  case 31:
  if (item.lastInvoice) {
    if(item.lastInvoice.isPaid==1){
      return (
        <Badge className='shadow truncate' color='dark' style={{backgroundColor:'#964A1B'}}>
          <div>
            {item.lastInvoice.collectedAmount} EGP <br/>
            <span style={{fontSize:10, fontWeight:'normal'}}>paid</span>
          </div>
        </Badge>
      )
    }else{
      return <Badge className='shadow truncate' style={{backgroundColor:'#964A1B'}} color='dark'>{item.lastInvoice.collectedAmount} EGP</Badge>
    }
  }else {
    return <Badge className='shadow truncate' style={{backgroundColor:'#964A1B'}} color='dark'>{item.status.enName}</Badge>
  }
  // return <Badge className='shadow truncate' color='primary' style={{backgroundColor:'#964A1B'}}>{item.status.enName}</Badge>
  break
  case 32:
  return <Badge className='shadow truncate' style={{backgroundColor:'#d1c5f9',color:'#fff'}}>{item.status.enName}</Badge>
  case 33:
  return <Badge className='shadow truncate' color='primary' style={{backgroundColor:'#254184'}}>{item.status.enName}</Badge>
  break
  case 34:
  return <Badge className='shadow truncate' color='primary' style={{backgroundColor:'#71758D'}}>{item.status.enName}</Badge>
  break
  default: return <Badge className='shadow truncate' color='danger'>{item.status.enName}</Badge>
}
// return <Badge className='shadow' color={item.status.id==1?'warning':item.status.id==2?'success':item.status.id==3?'primary':item.status.id==4?'dark':'danger'}>{item.status.enName}</Badge>
}


  render() {
    return (
      <div className="app align-items-center">

        <Container>
          {this.renderFilter()}
          <Card style={{marginTop:15}}>
            {this.renderSearch()}
            {this.state.searchText!=''?(
        <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
          <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
          <h6 style={{fontSize:18}}>Search Results of
            <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

          </h6>
          <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getOrders())}>
              Clear Search
              <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
            </span>
          </div>

        ):null}
            {this.state.noResult?
              <CardBody className='shadow'>
                {this.renderOrders()}
                {this.renderPagination()}
              </CardBody>
            :(
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result </h2>
              </CardBody>
            )}


            {/* SingleCustomer */}
            {this.state.showSingleCustomer?<SingleCustomer data={this.state.singleCustomer} reload={this.reload}/>:null}

            {/* SingleFixer */}
            {this.state.showSingleFixer?<SingleFixer data={this.state.singleFixer} reload={this.reload}/>:null}

            {/* SingleOrder */}
            {this.state.showSingleOrder?<SingleOrder data={this.state.singleOrder} reload={this.reload} />:null}

            <LoadingSpinner loading={this.state.loading}/>

          </Card>
        </Container>
      </div>
    )
  }

  handleReservationTab(index){
    switch (index) {
      case 1:
      this.setState({tab:index, fromDate: moment().format("YYYY-MM-DD") },()=>this.filter('withCall'))
      break;
      case 2:
      this.setState({tab:index, fromDate: moment().add(1, 'days').format("YYYY-MM-DD")},()=>this.filter('withCall'))
      break;
      case 3:
      this.setState({tab:index, fromDate: true},()=>this.filter('withCall'))
      break;
      default:
    }
  }

  handler = (item) => {
    if(item.id){
      this.setState({selectedServiceGroup: item.enName, selectedServiceGroupId: item.id})
    }else{
      this.setState({selectedServiceGroup: 'ServiceGroup', selectedServiceGroupId:null})
    }
  }





  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}} className="rowTabReservation">
            {/* <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Orders</span> */}
            {/* <Row className="rowTabReservation"> */}
            <Col md="4" className="tabButton">
              <Button onClick={()=>this.handleReservationTab(1)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==1?'#3a4248':'#4dbd74',color:'#fff',width:'100%'}}>Today</Button>
            </Col>
            <Col md="4" className="tabButton">
              <Button onClick={()=>this.handleReservationTab(2)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==2?'#3a4248':'#4dbd74',color:'#fff',width:'100%'}}>Tommorow</Button>
            </Col>
            <Col md="4" className="tabButton">
              <Button onClick={()=>this.handleReservationTab(3)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==3?'#3a4248':'#4dbd74',color:'#fff',width:'100%'}}>Out-Date</Button>
            </Col>
            {/* </Row> */}
          </Col>

          <Col  style={{margin:'auto'}}>
            <InputGroup>
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e, 'search')}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By name, serial id, phone...."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.setState({currentPage:1},()=>this.getOrders())} type="button" style={{backgroundColor:'#FFB400',borderColor:'#FFB400'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    )
  }


  renderOrders(){
    return(
      <div>
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center',width:'5%'}}>Order Serial</th>
              <th style={{textAlign:'center'}}>Customer</th>
              <th style={{textAlign:'center'}}>Fixer</th>
              <th style={{textAlign:'center'}}>Type</th>
              <th style={{textAlign:'center'}}>Creation Date</th>
              <th style={{textAlign:'center'}}>Requested Date</th>
              <th style={{textAlign:'center'}}>Status</th>
              {/* <th style={{textAlign:'center',width:'5%'}}>Redirection</th> */}
              <th style={{textAlign:'center'}}>Category</th>
              <th style={{textAlign:'center'}}>City</th>
              <th style={{textAlign:'center'}}>Area</th>
              <th style={{textAlign:'center'}}>Agents</th>
              <th style={{textAlign:'center'}}>Channel</th>
              <th style={{textAlign:'center'}}>Payment</th>
              <th style={{textAlign:'center'}}>Source Company</th>
              <th style={{textAlign:'center'}}>Serial</th>
            </tr>
          </thead>
          <tbody>
            {/* <div style={{position:'absolute',top:150,right:0}}>
              <p>{this.state.text}</p>
            </div> */}
            {this.state.orders.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('order',item)}>{item.serial}</Button>
                  {item.otherName&&item.otherPhone?(<Badge color="success" pill>F.F</Badge>):null}
                  {item.offer != null ? (<Badge color="success" pill>Offer</Badge>):null}
                  {(item.chats && item.chats.length != 0 && item.chats.some( c => c.lastMessage != null ))? (<Badge style={{backgroundColor:'#009aff', color:'#fff'}} pill>Chat</Badge>):null}
                  {item.source != null ? (item.source.source == "messenger_bot" ? <Badge color="warning" pill>Chatbot</Badge> : null):null}
                  {item.priceOffer != null ? (<Badge style={{marginLeft:5,backgroundColor:'#7c668b',color:'white'}} pill>Chat Offer</Badge>) : null}
                  {item.sourceCompany != null ? <Badge style={{backgroundColor:'#b50203',color:'#fff'}} pill>{item.sourceCompany.name}</Badge> : null}
                </td>
                <td style={{textAlign:'center'}}>
                  <Button disabled={item.customer == null} className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.getSingle('customer',item.customer)}>{item.customer != null?item.customer.name:''}</Button>
                </td>
                <td style={{textAlign:'center'}}>
                  <Button disabled={item.fixer == null} className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', minWidth:100}} onClick={()=>this.getSingle('fixer',item.fixer)}>{item.fixer!=null?item.fixer.arName:'N/A'}</Button>
                  {!item.fixerCoverage ? <span style={{fontSize:11, color:'red', whiteSpace: 'nowrap'}}>out of work contract</span>: null}
                </td>
                <td style={{textAlign:'center'}}>{item.serviceGroup!=null?item.serviceGroup.enName:'Not Available'}</td>
                <td style={{textAlign:'center'}}>{item.createdAt}</td>
                <td style={{textAlign:'center'}}>
                  <p style={{marginBottom:0}}>{item.requestedDate}</p>
                  {!item.requestedDateCoverage ? <span style={{fontSize:11, color:'red', whiteSpace: 'nowrap'}}>out of work contract</span> : null}
                </td>
                <td style={{textAlign:'center'}} >{this.handleRenderOrderStatus(item)}</td>

                {/* <td style={{textAlign:'center'}}>{item.allowRedirection=='0'?'No':'Yes'}</td> */}
                <td style={{textAlign:'center'}}>{item.category.enName}</td>
                <td style={{textAlign:'center'}}>{item.city.enName}</td>
                <td style={{textAlign:'center'}}>{item.area.enName}</td>
                <td style={{textAlign:'center'}}>
                {item.admins&&item.admins.length == 0 ? (<p style={{paddingTop:6,paddingLeft:3,cursor:'pointer'}}>N/A</p>):(
                   <div style={{display:"flex",flexDirection:'row',backgroundColor:'#feb500',alignItems:'center',justifyContent:'center',padding:5,borderRadius:20}}>
                   <>
                   {item.admins&&item.admins.map((admin,index)=>(
                   <div key={index}>
                     {admin.name?(
                       <div>
                         <span id={'Tooltip-' + admin.id} href="#">{admin.name.slice(0,2)}</span>
                         <Tooltip placement="bottom" isOpen={this.state.text == admin.name ?  this.state.tooltipOpen : false}
                           target={'Tooltip-' + admin.id} autohide={false}
                           toggle={()=>this.toggle(admin)}>{this.state.text == admin.name ? admin.name : ''}
                         </Tooltip>
                       </div>
                     ):null}
                  </div>
                ))}
                </>
                   </div>
                )
                    }
                </td>
                <td style={{textAlign:'center'}} >{item.communicationChannel?item.communicationChannel.channel:''}</td>
                <td style={{textAlign:'center'}} >{item.paymentMethod?item.paymentMethod.enName:'N/A'}</td>
                <td style={{textAlign:'center'}} >{item.sourceCompany?item.sourceCompany.name:'Fixawy'}</td>
                <td style={{textAlign:'center'}}>{item.serial!=null?item.serial:'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

)
}

renderPagination(){
  return(
    <nav>
      <Row className='pull-right'>
        <Pagination style={{justifyContent:'flex-end'}}>
          {/* previous link*/}
          <PaginationItem>
            <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
          </PaginationItem>

          {/* previousPages */}
          {this.state.previousPages.map((item, index)=>(
            <PaginationItem key={index}>
              <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                {item}
              </PaginationLink>
            </PaginationItem>
          ))}

          {/* currentPage */}
          <PaginationItem active>
            <PaginationLink style={{backgroundColor:'#FFB400',color:'black',borderColor:'#FFB400'}} tag="button">
              {this.state.paginationInfo.currentPage}
            </PaginationLink>
          </PaginationItem>


          {/* nextPages */}
          {this.state.nextPages.map((item, index)=>(
            <PaginationItem key={index}>
              <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                {item}
              </PaginationLink>
            </PaginationItem>
          ))}

          {/* nextPageUrl */}
          <PaginationItem>
            <PaginationLink style={{color:'#FFB400',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
          </PaginationItem>
        </Pagination>
      </Row>
    </nav>
  )
}


renderFilter(){
  return(
    <>
      <Row style={{padding:'0px 10px'}}>
        <UncontrolledDropdown  className="">
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />City</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.cities.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>{this.handleMultiSelectMenu('selectedCities',item);this.getArea()}}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown className=" areaMenu" style={{marginRight:20}}>
          <DropdownToggle caret disabled={!this.state.areas.length} style={{width:'100%'}} className='actionsBtn'><i className='fas fa-map-marker-alt' style={{marginRight:10}} />Area</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.areas.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedAreas',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown  className="" style={{marginRight:20}} >
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Category</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.categories.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedCategories',item)}>{item.enName}</DropdownItem>
            )
            )}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown  className="" style={{marginRight:20}} >
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Service Group</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.serviceGroups.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedServiceGroups',item)}>{item.enName}</DropdownItem>
            )
            )}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />Status</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.statuses.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedStatuses',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-user-shield' style={{marginRight:10}} />Agent</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.agents.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedAgents',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-question-circle' style={{marginRight:10}} />{this.state.selectedType}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.orderTypeMenuOptions.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('type',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>


        {/* <div style={{marginRight:35}}>
          <OutOfWorkContract ref={ref => { this.child = ref; }} handlerStatuses={(status)=>this.handleMenus('unCovered',status)}/>
        </div> */}


      </Row>
      <Row style={{padding:'0px 10px'}}>
        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fa fa-commenting-o' style={{marginRight:10}} />{this.state.filterChat!='Choose'?this.state.filterChat:'Chat'}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            <DropdownItem onClick={()=>this.setState({filterChat:'Choose'})}>Choose</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterChat:'Has Chat'})}>Orders has chat</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        {/* filterPayment */}
        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fa fa-money' style={{marginRight:10}} />{this.state.filterPayment!=''?this.state.filterPayment:'Payment'}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            <DropdownItem onClick={()=>this.setState({filterPayment:'', filterPaymentValue:''})}>Choose</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterPayment:'Cash', filterPaymentValue:1})}>Cash</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterPayment:'Online payment', filterPaymentValue:2})}>Online payment</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterPayment:'N/A', filterPaymentValue:0})}>N/A</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterPayment:'All', filterPaymentValue:''})}>All</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        {/* filterComplaint */}
        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className="fa fa-compress" aria-hidden="true" />{this.state.filterFromComplain!='Choose'?this.state.filterFromComplain:'Complain'}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            <DropdownItem onClick={()=>this.setState({filterFromComplain:'Choose'})}>Choose</DropdownItem>
            <DropdownItem onClick={()=>this.setState({filterFromComplain:'From Complain'})}>Orders From Complaint</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>


        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />Companies</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.companies.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>{this.handleMultiSelectMenu('selectedCompanies',item)}}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        <div className='date'>
          <DatePicker
            // minDate={new Date()}
            selected={this.state.requestedDate}
            onChange={(date)=> this.handleOnChangeDate('from', date) }
            dateFormat="dd-MM-yyyy"
            placeholderText='From date'
          />

        </div>

        {this.state.requestedDate!=''?(
          <div className='fa fa-close clearDatePicker' onClick={()=>this.setState({requestedDate:'',fromDate:''})}/>
        ):null}
        {/* <Col> */}
        <Button onClick={()=>this.getOrders('filter')} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'#fff',padding:'7px 20px'}}>Filter</Button>
        {/* </Col> */}

        {/* <Col> */}
        <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
        {/* </Col> */}

      </Row>
      <Row style={{padding:'0px 10px'}}>
        {this.renderFiltersInfo()}
      </Row>
    </>
)
}

renderFiltersInfo(){
  return (<>
    <Row style={{padding:'0px 0px 20px 0px'}}>
      {this.state.selectedCities.length?(
        <div style={{marginLeft:20}}>
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}}>Cities </h5>
          <div>
            {this.state.selectedCities.map( (city,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>{this.removeMultiFilter('selectedCities',i);this.getArea()}} >
                {`${city.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedAreas.length?(
        <div style={{marginLeft:20}} >
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}}>Areas </h5>
          <div>
            {this.state.selectedAreas.map( (area,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedAreas',i)} >
                {`${area.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedCategories.length?(
        <div style={{marginLeft:20}} >
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}}>Categories </h5>
          <div>
            {this.state.selectedCategories.map( (category,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedCategories',i)} >
                {`${category.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedServiceGroups.length?(
        <div style={{marginLeft:20}} >
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}}>Service Groups </h5>
          <div>
            {this.state.selectedServiceGroups.map( (serviceGroup,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedServiceGroups',i)} >
                {`${serviceGroup.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedStatuses.length?(
        <div style={{marginLeft:20}} >
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}}>Status </h5>
          <div>
            {this.state.selectedStatuses.map( (status,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedStatuses',i)} >
                {`${status.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedAgents.length?(
        <div style={{marginBottom:'10px',borderRadius:'7px',padding:'10px 20px',boxShadow:' 0px 14px 60px -17px rgba(0, 0, 0, 0.75)'}} >
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}}>Agents </h5>
          <div>
            {this.state.selectedAgents.map( (agent,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedAgents',i)} >
                {`${agent.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedCompanies.length?(
        <div style={{marginLeft:20}} >
          <h5 style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold', marginTop:10}} >Companies </h5>
          <div>
            {this.state.selectedCompanies.map( (company,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedCompanies',i)} >
                {`${company.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
  </Row>
  </>)
}

}

export default withRouter(Orders);
