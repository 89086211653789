import React from 'react';
import ChatListItem from './ChatListItem';
import {ListGroup , ListGroupItem } from 'reactstrap';
import ReactivePaginator from '../Pagination/ReactivePaginator';
import { Spinner } from 'reactstrap'

class ChatList extends React.Component
{
  render(){
    return(
        <ReactivePaginator ref={this.props.forwardedRef} id='1' updateContent={this.props.onReachBottom}>
          <ListGroup>
              {this.props.chats.map((chat,i) =>(
                <ListGroupItem className='chat-list-item' active={this.props.activeChat && this.props.activeChat.id == chat.id} key={i} >
                  <ChatListItem
                      chat={chat}
                      onClickHandler={ () => this.props.onSelectChat(chat) }
                    />
                </ListGroupItem>
              ))}
              {this.props.loading?(<div className="text-center m-5"><Spinner color="primary" /></div>):null}
          </ListGroup>
        </ReactivePaginator>
    )
  }
}

export default ChatList;
