import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class EquipmentsServices {

  static get(callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/equipments`).then(callBack)
  }

  static add(data, callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/equipments`,data).then(callBack)
  }

  static assignToOrder( orderId, data, callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/orders/${orderId}/equipments`,data).then(callBack)
  }


}
