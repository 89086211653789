import React from 'react'
import FixerRevenueService from '../../../services/fixerServices/FixerRevenueService';
import Services from './../../../services/Services'

import { Modal , ModalBody , ModalHeader , ModalFooter , Button} from 'reactstrap'
import { LoadingSpinner } from '../..';

export default class DueFeesDetails extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            loading:false,
            fixer:props.fixer,
            revenue:0,
        }
    }

    componentDidMount(){
        this.getFixerRevenue();
    }

    getFixerRevenue(){
      this.setState({loading:true});
      FixerRevenueService.get(this.state.fixer.id,(res)=>{
        this.setState({loading:false, revenue: parseFloat(res.data.revenue.replace(/,/g, ''))})
      })
    }

    render(){
        return(
        <>
        <Modal isOpen={true} toggle={()=>this.props.onClose()}>
            <ModalHeader toggle={()=>this.props.onClose()}>Due Fees Details</ModalHeader>
            <ModalBody>
                <div>
                    <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Due Fees: <span style={{fontWeight:'bold'}}>{this.state.fixer?.dueFees} L.E</span></p>
                    <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Revenue From Work Contracts: <span style={{fontWeight:'bold'}}>{this.state.revenue} L.E</span></p>
                    <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Total Due Fees: <span style={{fontWeight:'bold'}}>{this.state.fixer?(this.state.fixer.dueFees - this.state.revenue):0} L.E</span></p>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className='actionsBtn' onClick={()=>this.props.onClose()}>Close</Button>
            </ModalFooter>
        </Modal>
        <LoadingSpinner loading={this.state.loading} />
        </>
        );
    }
}
