import firebase from "firebase/app";
import "firebase/database";

firebase.initializeApp({
  // staging
  // apiKey: "AIzaSyBrzRc0DUhZWHBIR3CnF7B4zTwC_ISbmsM",
  // authDomain: "fixawyfixers.firebaseapp.com",
  // databaseURL: "https://fixawyfixers.firebaseio.com",
  // projectId: "fixawyfixers",
  // storageBucket: "fixawyfixers.appspot.com",
  // messagingSenderId: "211000783661",
  // appId: "1:211000783661:web:dc0e1b0592c415da"

  // live
  apiKey: "AIzaSyBBmtQEmMg4rWqf3NkN1MiOrMRmf5quMpY",
  authDomain: "fcmapp-4e925.firebaseapp.com",
  databaseURL: "https://fcmapp-4e925.firebaseio.com",
  projectId: "fcmapp-4e925",
  storageBucket: "fcmapp-4e925.appspot.com",
  messagingSenderId: "164317538579",
  appId: "1:164317538579:web:6d8576e8365a333b2692e6"
})

export class Global {

  // Finally
  // static baseURL = `http://localhost:8000/api/v1/`
  static baseURL = `https://fixawy-api.bdaiat.com/api/v1/`

  static chatImagePath = `http://localhost:8000/api/v1/storage/chats/images/`
  static chatSoundPath = `http://localhost:8000/api/v1/storage/chats/sounds/`


  // testing
  // static baseURL = `https://test.fixawy.net/api/v1/`
  //
  // static chatImagePath = `https://test.fixawy.net/storage/chats/images/`
  // static chatSoundPath = `https://test.fixawy.net/storage/chats/sounds/`


  // live
  static webSiteURL = `https://fixawy.net/`
  // testing
  // static webSiteURL = 'https://website-test.fixawy.net/'


  //test socket url

  // static socketUrl = 'https://socket.fixawy.net/';
  static socketUrl = 'https://development.fixawy.net/';

  static version = `1.0.0`

  static orderTypes = [
    { id: 1, enName: "All" },
    { id: 2, enName: "Inquiry" }
  ];

  static hours = [
    { id: 0, name: "Choose", value: 0 },
    { id: 1, name: "1h", value: 60 },
    { id: 2, name: "2h", value: 120 },
    { id: 3, name: "3h", value: 180 },
    { id: 4, name: "4h", value: 240 }
  ];
  static minutes = [
    { id: 0, name: "Choose", value: 0 },
    { id: 1, name: "15 min", value: 15 },
    { id: 2, name: "30 min", value: 30 },
    { id: 3, name: "45 min", value: 45 }
  ];

  static pricingTypes = [
    { id: 1, name: "Marama" },
    { id: 2, name: "Fixawy Pricing" },
    { id: 4, name: "Meter And Quantity"}
  ];

  static activationStatus = [
    { enName: "Choose", id: "choose" },
    { enName: "Blocked", id: "1" },
    { enName: "Un-Blocked", id: "0" }
  ];

  static allPeriods = [
    {
      arBrief: "من ٩ ص إلى ١١ ص",
      arName: "من ٩ ص إلى ١١ ص",
      enBrief: "From 9 AM To 11 AM",
      enName: "From 9 AM To 11 AM",
      id: 1,
      timeFrom: "09:00:00",
      timeTo: "10:59:59"
    },
    {
      arBrief: "من ١١ ص إلى ١ م",
      arName: "من ١١ ص إلى ١ م",
      enBrief: "From 11 AM To 1 PM",
      enName: "From 11 AM To 1 PM",
      id: 2,
      timeFrom: "11:00:00",
      timeTo: "12:59:59"
    },
    {
      arBrief: "من ١ م اإلى ٣ م",
      arName: "من ١ م إلى ٣ م",
      enBrief: "From 1 PM To 3 PM",
      enName: "From 1 PM To 3 PM",
      id: 3,
      timeFrom: "13:00:00",
      timeTo: "14:59:59"
    },
    {
      arBrief: "من ٣ م إلى ٥ م",
      arName: "من ٣ م إلى ٥ م",
      enBrief: "From 3 PM To 5 PM",
      enName: "From 3 PM To 5 PM",
      id: 4,
      timeFrom: "15:00:00",
      timeTo: "16:59:59"
    },
    {
      arBrief: "من ٥ م إلى ٧ م",
      arName: "من ٥ م إلى ٧ م",
      enBrief: "From 5 PM To 7 PM",
      enName: "From 5 PM To 7 PM",
      id: 5,
      timeFrom: "17:00:00",
      timeTo: "18:59:59"
    },
    {
      arBrief: "من ٧ م إلى ٩ م",
      arName: "من ٧ م إلى ٩ م",
      enBrief: "From 7 PM To 9 PM",
      enName: "From 7 PM To 9 PM",
      id: 6,
      timeFrom: "19:00:00",
      timeTo: "20:59:59"
    }
  ];

  static zonesLocation = [
    {
      id: 1,
      location: { lat: 31.2001, lng: 29.9187 }
    },
    {
      id: 2,
      location: { lat: 30.0444, lng: 31.2357 }
    },
    {
      id: 3,
      location: { lat: 30.9913, lng: 29.6315 }
    },
    {
      id: 4,
      location: { lat: 31.0409, lng: 31.3785 }
    },
    {
      id: 5,
      location: { lat: 30.7865, lng: 31.0004 }
    },
    {
      id: 6,
      location: { lat: 31.0425, lng: 30.4728 }
    }
  ];
}
