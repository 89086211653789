import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label,Input,InputGroup ,Table} from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import Switch from "react-switch";
import {NoResult} from './../../components'
import PriceOffersService from './../../services/offersServices/PriceOffersService'
export default class PriceOfferDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      priceOffers:[]
    }

  }
  componentDidMount(){
    this.getPriceOffersOfOrder()
  }


  getPriceOffersOfOrder(){
    this.setState({loading:true})
    PriceOffersService.getPerOrder(this.props.order,(res)=>{
      this.setState({priceOffers:res.data.priceOffers,loading: false, })
    })
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
        {this.props.action == "singleOrder" ? (
          <>
          {this.state.priceOffers.length == 0 ? (
            <NoResult/>
          ):(
       <Table hover bordered striped responsive size="md">
       <thead>
         <tr>
           <th style={{textAlign:'center'}}>Offer ID</th>
           <th style={{textAlign:'center'}}>Description Name</th>
           <th style={{textAlign:'center'}}>Price</th>
           <th style={{textAlign:'center'}}>Status</th>
           <th style={{textAlign:'center'}}>Date And Time</th>
         </tr>
       </thead>
       <tbody>
         {this.state.priceOffers.map((item,index)=>(
           <tr key={index}>
             <td style={{textAlign:'center'}}>{item.id}</td>
             <td style={{textAlign:'center'}}>{item.qoute}</td>
             <td style={{textAlign:'center'}}>{item.price} LE</td>
             <td style={{textAlign:'center'}}>{item.status.enName}</td>
             <td style={{textAlign:'center'}}>{item.createdAt}</td>
           </tr>
        ))}
       </tbody>
     </Table>
     )}
     </>
        ) : (
          <>
          <p>
          <i className="icon-arrow-right-circle"></i>
          <span style={{marginLeft:6}}> Offer Price:
            <span style={{fontWeight:'bold'}}>      {this.props.details.price} LE
            </span>
          </span>
        </p>
        <p>
          <i className="icon-arrow-right-circle"></i>
          <span style={{marginLeft:6}}> Offer Description:
            <span style={{fontWeight:'bold'}}>            {this.props.details.qoute}
            </span>
          </span>
        </p>
        </>
        )}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
      )
    }
  }
