import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Table} from 'reactstrap'
import QuotationService from './../../services/quotationServices/QuotationService'
import Services from './../../services/Services'
import { LoadingSpinner } from './../../components'

export default class QuotationsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history:[],
      noData:false
    }
  }

  componentDidMount(){
    console.log(this.props.quotation);
    this.getHistory()
  }


  getHistory(){
    this.setState({loading:true})
    QuotationService.getHistory( this.props.quotation.id, (res)=>{
      this.setState({loading:false, history:res.data.quotationActions, noData:res.data.quotationActions.length==0},()=>console.log(res))
    })
  }


  render(){
    return(
      <div>
        {this.state.history!=''?(
          <Row>
            <Col md='1'/>
            <Col md="10">
              <Table hover bordered striped responsive size="md">
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}} width='15%'>Name</th>
                    <th style={{textAlign:'center'}} width='25%'>Actions</th>
                    <th style={{textAlign:'center'}} width='35%'>Description</th>
                    <th style={{textAlign:'center'}} width='25%'>Date and Time</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.history.map((item, index)=>(
                    <tr key={index}>
                      <td style={{textAlign:'center'}}>{item.actionMaker.enName}</td>
                      <td style={{textAlign:'center'}}>{item.action.action}</td>
                      <td style={{textAlign:'center'}}>{item.briefDescription}</td>
                      <td style={{textAlign:'center'}}>{item.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col md='1'/>
          </Row>
        ):null}

        {this.state.noData?(
          <p style={{textAlign:'center', fontSize:16, color:'#777', marginTop:40}}>There is no actions taken</p>
        ):null}

        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
