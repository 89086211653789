import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class OrdersRateService {

  static get(page, callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/rates?page=${page}`).then(callBack)
  }

  static delete(id, callBack){
    Adaptor.delete(`${Global.baseURL}admin-panel/rates/${id}`).then(callBack)
  }


}
