import React, { Component } from 'react'
import { Button,Col, Row, Table, CardHeader, Badge, Modal, ModalBody, ModalHeader, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown ,module , Label ,Input} from 'reactstrap'
import Avatar from './../../assets/img/brand/download.png'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
// import MakeOrder from './MakeOrder'
import Rating from 'react-rating'
import firebase from 'firebase/app'
import 'firebase/database'
class FeedBack extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal:true,
      modalFeedBack:false,
      criteria:[],
      feedbackCriteria:[],
      modalPassword:false,
      userIdToBeBlockedOrUnBlocked:'',
      indexOfItemToBeBlockedOrUnBlocked:'',
      password:'',
    }
    this.onChangeCriteriaRate = this.onChangeCriteriaRate.bind(this)
    this.handleChangeInputs = this.handleChangeInputs.bind(this);
  }
  componentDidMount(){
    var userTypeId = this.props.user.hasOwnProperty('userTypeId') ?
    this.props.user.userTypeId  :2;
    this.getAllCriteria(userTypeId)
    console.log(this.props.user);
    this.getFirebaseGeneralData()
  }
  handleFeedBack(){
    this.setState({modalFeedBack : !this.state.modalFeedBack})
  }
  getFirebaseGeneralData(){
    firebase.database().ref('general/').on('value', (snapshot) => {
      if (Object.keys(snapshot.node_.children_.root_).length !== 0) {
        Object.keys(snapshot.val()).forEach( (key) => {
          let val = snapshot.val()[key]
          if(key == "feedbackPassword"){
            this.setState({feedbackPassword:val})
          }
        })
      }
    })
  }
  onChangeCriteriaRate(index,value){
    let feedbackCriteria = this.state.criteria;
    feedbackCriteria[index].points = value;
    this.setState({
      criteria:feedbackCriteria
    })
  }
  addFeedBack(){
    // if (this.state.feedbackPassword != this.state.password) {
    //   alert('Please ,password in correct')
    // }else if (this.state.password == "" || this.state.password.trim() == "") {
    //   alert("Please , enter password")
    // }else {
      let criteriaToBeInserted ;
      criteriaToBeInserted = this.validateCriteriaBeforeInsertAndReturnWithInsertedCriteria(this.state.criteria);
      if(criteriaToBeInserted != 'error'){
        if(!criteriaToBeInserted.length)
        alert('Please, give  feedback');
        else
        this.insertFeedBack(criteriaToBeInserted)
      // }
    }
  }
  insertFeedBack(criteriaToBeInserted){
    this.setState({loading:true})
    Services.addFeedBack(this.props.user.id , criteriaToBeInserted , (res)=>{
      this.setState({loading:false } , () => this.props.insertFeedBack(this.props.user))
    })
  }
  validateCriteriaBeforeInsertAndReturnWithInsertedCriteria(criteria){
    var criteriaToBeInserted = [];
    console.log(criteria)
    for (var i = 0 ; i < criteria.length ; i++) {
      if (criteria[i].points == 0){
        if(criteria[i].notes){
          alert("Please, give feedback for "+criteria[i].enName+"  or delete note");
          return 'error';
        }
      }
      else{
        if (criteria[i].notes!=''&&!criteria[i].notes.replace(/\s/g, '').length) {
          alert("Please, enter note correctly");
          return 'error';
        }else {
          criteriaToBeInserted.push(criteria[i]);
        }
      }
    }
    return criteriaToBeInserted;
  }
  getAllCriteria(userTypeId){
    this.setState({loading:true})
    Services.getAllCriteria(userTypeId, (res)=>{
      let systemCriteria = res.data.criteria;
      let feedbackCriteria = [];
      for(let i = 0 ; i < systemCriteria.length ; i++){
        feedbackCriteria.push({
          'id':systemCriteria[i].id,
          'enName':systemCriteria[i].enName,
          'points':0,
          'notes' : ''
        })
      }
      this.setState({loading:false, criteria:feedbackCriteria})
    })
  }
  handleChangeInputs(e , index){
    let feedbackCriteria = this.state.criteria;
    feedbackCriteria[index].notes = e.target.value;
    this.setState({
      criteria:feedbackCriteria
    })
  }
  togglePassword(){
    let criteriaToBeInserted ;
    criteriaToBeInserted = this.validateCriteriaBeforeInsertAndReturnWithInsertedCriteria(this.state.criteria);
    if(criteriaToBeInserted != 'error'){
      if(!criteriaToBeInserted.length){
        alert('Please, give  feedback');
      }else {
        this.setState({
          modalPassword : !this.state.modalPassword ,
        })
      }
    }
  }
  closePasswordModal(){
    let criteriaToBeInserted ;
    criteriaToBeInserted = this.validateCriteriaBeforeInsertAndReturnWithInsertedCriteria(this.state.criteria);
    if(criteriaToBeInserted != 'error'){
      if(!criteriaToBeInserted.length){
        alert('Please, give  feedback');
      }else {
        this.setState({
          modalPassword : !this.state.modalPassword ,
          password:''
        })
      }
    }
  }
  handleChangeInputsPassword(e,index){
    if (index == 'password') {
      this.setState({
        password:e.target.value
      })
    }
  }
  renderCriteria(){
    return (
      <div>
        <Row style={{textAlign:'center',marginBottom:25,fontSize:18}}>
          <Col md="2" style={{textAlign:'center'}}><span className='titles'>Criterion</span></Col>
          <Col md="3" style={{textAlign:'center'}}><span className='titles'>Scale Number</span>
          </Col>
          <Col md="5">
            <span className='titles'>Note</span>
          </Col>
        </Row>
        {this.state.criteria.map((item,index)=>(
          <Row key={index}>
            <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>{item.enName}</span></Col>
            <Col md="3" style={{textAlign:'center',marginTop:6}}>
              <Rating
                name='feedbackRating'
                emptySymbol={<i className="far fa-star" style={{fontSize:20}}></i>}
                initialRating={item.points}
                fullSymbol={<i className="fas fa-star" style={{color:'#4dbd74',fontSize:20,borderWidth:1,borderColor:'#777'}}></i>}
                onClick={(value)=>this.onChangeCriteriaRate(index,value)}
                value={5}
              />
            </Col>
            <Col md="5">
              <textarea
                wrap="hard" cols={5} rows={3}
                style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
                placeholder="Enter note"
                value={item.notes}
                onChange={(e)=>this.handleChangeInputs(e , index)}
                maxLength={500}
              />
            </Col>
          </Row>
        ))}
      </div>
    )
  }
render(){
  return (
    <Modal fade={false} isOpen={this.state.showModal} toggle={()=>this.props.closeFeedBackModal()} className={this.props.className}>
      <ModalHeader toggle={()=>this.props.closeFeedBackModal()}>{this.props.user.name}</ModalHeader>
      <ModalBody>
        {this.renderCriteria()}
        {this.modalPassword()}
      </ModalBody>
      <ModalFooter>
        <Button className='actionsBtn' onClick={()=>this.props.closeFeedBackModal()}>Cancel</Button>
        <Button className='actionsBtn' onClick={()=>this.addFeedBack()}>Submit</Button>
        {/* <Button className='actionsBtn' onClick={()=>this.closePasswordModal()}>Submit</Button> */}
      </ModalFooter>
    </Modal>
  )
}
modalPassword(){
  return (
    <Modal fade={false} isOpen={this.state.modalPassword} toggle={()=>this.closePasswordModal()} className={this.props.className}>
      <ModalHeader toggle={()=>this.closePasswordModal()}>Feed Back</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="2" style={{textAlign:'right',paddingTop:'.5%'}}>
            <Label>Password: </Label>
          </Col>
          <Col md="5">
            <Input
              value={this.state.password}
              onChange={(e)=>this.handleChangeInputsPassword(e,'password')}
              type="password"
              placeholder="Enter password"
              autoComplete="current-password"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.closePasswordModal()}>Cancel</Button>
        <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.addFeedBack()}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}
}
export default withRouter(FeedBack);
