import React, { Component } from 'react'
import { Button, Col, Row, Badge} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner, Actions, MapModal } from './../../../components'
import moment from 'moment'
export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleOrder: '',
      showMap:false
    }
  }

  componentDidMount(){
    this.setState({singleOrder:this.props.order,})
  }


  onSite(){
    return (this.state.singleOrder.status.id==30 || (this.state.singleOrder.status.id==4 && this.isMintanance() && this.state.singleOrder.quotation==null))
  }
  isMintanance(){
    if(this.state.singleOrder.serviceGroup!=null){
      return this.state.singleOrder.serviceGroup.id==1
    }
    return false
  }
  showMap(i){
    this.setState({lat:i.latitude, lng: i.longitude, showMap:true})
  }

  render(){
    return(
      <div>

        <MapModal fade show={this.state.showMap} closeHandler={()=>this.setState({showMap:false})} lat={this.state.lat} lng={this.state.lng} />

        {this.state.singleOrder==''?(
          <LoadingSpinner loading={this.state.loading}/>
        ):(
          <div style={{padding:'0px 20px'}}>
            <Row style={{marginTop:20}}>
              <Col>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Order Serial :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.serial}</span>
                  </span>
                  {this.state.singleOrder.otherName&&this.state.singleOrder.otherPhone?(<Badge color="success" pill>F.F</Badge>):null}
                  {this.state.singleOrder.offer != null ? (<Badge style={{marginLeft:5}} color="success" pill>Offer</Badge>) : null}
                  {this.state.singleOrder.source != null ? (this.state.singleOrder.source.source == "messenger_bot" ? <Badge style={{marginLeft:5}} color="warning" pill>Chatbot</Badge> : null) : null}
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Serial Number:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.serial == null ? "N/A" : this.state.singleOrder.serial}
                    </span>
                  </span>
                  {this.state.singleOrder.priceOffer != null ? (<Badge style={{marginLeft:5,backgroundColor:'#7c668b',color:'white'}} pill>Chat Offer</Badge>) : null}
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Customer Name:
                    <span style={{fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.props.showSingleCustomer()}>{this.state.singleOrder.customer.enName}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Customer Phone:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.customer.phone}
                    </span>
                  </span>
                </p>
                {this.state.singleOrder.otherName&&this.state.singleOrder.otherPhone?(
                  <div>
                    <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}> Friend Name:
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.otherName}</span>
                      </span>
                    </p>
                    <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}> Friend Phone:
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.otherPhone}
                        </span>
                      </span>
                    </p>
                  </div>
                ):null}
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Fixer Name:
                    <span style={{fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.props.showSingleFixer()}>{this.state.singleOrder.fixer?this.state.singleOrder.fixer.arName:'N/A'}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Fixer Phone:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.fixer?this.state.singleOrder.fixer.phone:'N/A'}
                    </span>
                  </span>
                </p>

                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Creation Date:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.createdAt.toString().split(' ')[0]}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Requested Date:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.requestedDate}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Period:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.period.enName}
                    </span>
                  </span>
                </p>

                {this.state.singleOrder.status.id != 1?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Visit Time:
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.visitTime}
                      </span>
                    </span>
                  </p>
                ):null}

                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Creation Time:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.createdAt.toString().split(' ')[1]}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Redirection Option:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.allowRedirection=='0'?'No':'Yes'}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Order Status:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.status.enName}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Category:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.category.enName}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> City:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.city.enName}
                    </span>
                  </span>
                </p>
              </Col>
              <Col>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Area:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.area.enName}
                    </span>
                  </span>
                </p>
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Order Type:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.serviceGroup!=null?this.state.singleOrder.serviceGroup.enName:'Not Available'}
                    </span>
                  </span>
                </p>


                {this.state.singleOrder.promoCode!=null?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Promo Code:
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.promoCode.code}
                      </span>
                    </span>
                  </p>
                ):null}

                {this.state.singleOrder.description!=null?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Order Description:
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.description}
                      </span>
                    </span>
                  </p>
                ):null}
                {this.state.singleOrder.finalClosingAction&&
                  this.state.singleOrder.finalClosingAction.details&&
                  this.state.singleOrder.finalClosingAction.details.fixingDescription?(
                    <p>
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}> Order Description:
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.finalClosingAction.details.fixingDescription}
                        </span>
                      </span>
                    </p>
                  ):null}

                {this.state.singleOrder.address != null?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Order Address:
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.address.enAddress}</span>
                    </span>
                    {this.state.singleOrder.address.longitude && this.state.singleOrder.address.latitude ?(
                      <div onClick={()=>this.showMap(this.state.singleOrder.address)}
                        style={{cursor:'pointer', marginLeft:'5%',borderRadius:20, border: '1px solid #4dbd74',display: 'flex',justifyContent: 'center',width: '25%',padding: 5, fontSize:12 }}
                        >
                          Show on Map <i style={{ fontSize:18,color:'#4dbd74', marginLeft:5, alignSelf:'center'}} className='fa fa-map-marker'/>
                        </div>
                      ):null}
                  </p>
                ):null}
                <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Payment Method:
                    <span style={{fontWeight:'bold'}}>
                      {this.state.singleOrder.paymentMethod?this.state.singleOrder.paymentMethod.enName:'N/A'}
                    </span>
                  </span>
                </p>
                {this.state.singleOrder.fromComplaint?(
                  <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Complaint ID:
                    <Badge href={`/complaints/${this.state.singleOrder.fromComplaint.id}`} color="warning">
                    <span style={{fontWeight:'bold'}}>
                        {this.state.singleOrder.fromComplaint.id}
                    </span>
                    </Badge>
                  </span>
                </p>
                ):null}
                {this.state.singleOrder.status.id == 5?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Cancel Reason:
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.cancellationReason}
                      </span>
                    </span>
                  </p>
                ):null}
                {this.state.singleOrder.status.id == 12?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Failure Reason:
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.failureReason}
                      </span>
                    </span>
                  </p>
                ):null}
                {/* {this.state.singleOrder.status.id == 4?(
                  <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Implementation Time:
                  <span style={{fontWeight:'bold'}}>{this.state.singleOrder.visitTime}
                  </span>
                  </span>
                  </p>
                ):null} */}
                {/* {this.state.singleOrder.lastInvoice!=null?(
                  <p>
                    <Button className="click"
                  style={{backgroundColor:'transparent',borderColor:'transparent',padding:0,fontWeight:'bold'}}
                  onClick={()=>this.props.showInvoice()}
                    >
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}>View Invoice </span>
                  <span style={{fontWeight:'bold'}}>{this.state.singleOrder.lastInvoice.collectedAmount}</span> EGP
                    </Button>
                  </p>
                  ):null}
                  {this.state.singleOrder.status.id == 31?(
                  <p><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:0,fontWeight:'bold'}} onClick={()=>this.props.showInspection()}><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>View Inspected Details</span></Button></p>
                  ):null}
                  <a target="_blank" href={this.state.singleOrder.trelloCardLink}>
                  <p className='trelloCardLink'><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6, fontWeight:'bold'}}> Link Card </span>
                  </p>
                </a> */}
                {/* {this.state.singleOrder.offer != null?(
                  <>
                    <div>
                  <p><i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Offer Details :</span>
                  </p>
                    </div>
                    <div style={{marginLeft:20}} className="quotation">
                  <p style={{float:'left'}}> - Items :</p>
                  <ul style={{listStyleType:'none'}}>
                  {this.state.singleOrder.offer.pricingItems.map((item,index)=>(
                  <li  key={index} style={{float:'left',fontWeight:'bold'}}>{`${item.enName}${this.state.singleOrder.offer.pricingItems.length != index+1?' , ':null}`}</li>
                  ))}
                  </ul>
                    </div>
                    <div style={{marginLeft:20,clear:'both'}} className="quotation">
                  <p> - Price :
                  <span style={{fontWeight:'bold'}}>{this.state.singleOrder.offer.price}   LE</span>
                  </p>
                    </div>
                  </>
                ):null} */}
                {/* {this.state.singleOrder.inquiry != null?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span> Inquiry Id :</span><span style={{marginLeft:6,fontWeight:'bold'}}>{this.state.singleOrder.inquiry.id}</span>
                  </p>
                ):null} */}
                {/* {this.state.singleOrder.status.id == 4?(
                  <p>
                  <i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Implementation Time:
                  <span style={{fontWeight:'bold'}}>{this.state.singleOrder.visitTime}
                  </span>
                  </span>
                  </p>
                ):null} */}
                {this.state.singleOrder.lastInvoice!=null?(
                  <p>
                    <Button className="click"
                      style={{backgroundColor:'transparent',borderColor:'transparent',padding:0,fontWeight:'bold'}}
                      onClick={()=>this.props.showInvoice()}
                    >
                      <i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}>View Invoice </span>
                      <span style={{fontWeight:'bold'}}>{this.state.singleOrder.lastInvoice.collectedAmount}</span> EGP
                    </Button>
                  </p>
                ):null}
                {this.state.singleOrder.status.id == 31?(
                  <p><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:0,fontWeight:'bold'}} onClick={()=>this.props.showInspection()}><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>View Inspected Details</span></Button></p>
                ):null}
                <a target="_blank" href={this.state.singleOrder.trelloCardLink}>
                  <p className='trelloCardLink'><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6, fontWeight:'bold'}}> Link Card </span>
                  </p>
                </a>
                {this.state.singleOrder.offer != null?(
                  <>
                    <div>
                      <p><i className="icon-arrow-right-circle"></i>
                        <span style={{marginLeft:6}}> Offer Details :</span>
                      </p>
                    </div>
                    <div style={{marginLeft:20}} className="quotation">
                      <p style={{float:'left'}}> - Items :</p>
                      <ul style={{listStyleType:'none'}}>
                        {this.state.singleOrder.offer.pricingItems.map((item,index)=>(
                          <li  key={index} style={{float:'left',fontWeight:'bold'}}>
                            {`${item.enName}${this.state.singleOrder.offer.pricingItems.length != index+1?' , ':' '}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div style={{marginLeft:20,clear:'both'}} className="quotation">
                      <p> - Price :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.offer.cities[0].price}   LE</span>
                      </p>
                    </div>
                  </>
                ):null}
                {this.state.singleOrder.rate != null?(
                  <>
                    <div>
                      <p><i className="icon-arrow-right-circle"></i>
                        <span style={{marginLeft:6}}> Rate Details :</span>
                      </p>
                    </div>
                    <div style={{marginLeft:20,clear:'both'}} className="quotation">
                      <p> - Fixer Rate :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.rate.totalRate}</span>
                      </p>
                    </div>
                    <div style={{marginLeft:20,clear:'both'}} className="quotation">
                      <p> - Order Price Rate :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.rate.priceSatisfactionLevel.enName}</span>
                      </p>
                    </div>
                    <div style={{marginLeft:20,clear:'both'}} className="quotation">
                      <p> - Comment :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.rate.comment}</span>
                      </p>
                    </div>
                    <div style={{marginLeft:20}} className="quotation">
                      <p style={{float:'left'}}> - Tags :</p>
                      <ul style={{listStyleType:'none'}}>
                        {this.state.singleOrder.rate.tags.map((item,index)=>(
                          <li  key={index} style={{float:'left',fontWeight:'bold'}}>
                            {`${item.enName}`}-
                          </li>
                        ))}
                      </ul>
                    </div>

                  </>
                ):null}
              {this.state.singleOrder.priceOffer != null?(
                  <>
                    <div>
                      <p><i className="icon-arrow-right-circle"></i>
                        <span style={{marginLeft:6}}>Chat Price Offer :</span>
                      </p>
                    </div>
                    <div style={{marginLeft:20}} className="quotation">
                      <p style={{fontWeight:'bold'}}> - Price Offer :</p>
                          <p style={{marginLeft:20}}>
                            Description :<span style={{fontWeight:'bold'}}>{this.state.singleOrder.priceOffer.qoute == null ? 'N/A' : this.state.singleOrder.priceOffer.qoute}</span>
                          </p>
                          <p style={{marginLeft:20}}>
                            Price       :<span style={{fontWeight:'bold'}}>{this.state.singleOrder.priceOffer.price == null ? 'N/A' : this.state.singleOrder.priceOffer.price} LE</span>
                          </p>
                          <p style={{marginLeft:20}}>
                            Status      :<span style={{fontWeight:'bold'}}>{this.state.singleOrder.priceOffer.status == null ? 'N/A' : this.state.singleOrder.priceOffer.status.enName}</span>
                          </p>
                          <p style={{marginLeft:20}}>
                            Date        :<span style={{fontWeight:'bold'}}>{this.state.singleOrder.priceOffer.createdAt == null ? 'N/A' : this.state.singleOrder.priceOffer.createdAt}</span>
                          </p>
                    </div>
                  </>
                ):null}

                {this.state.singleOrder.inquiry != null?(
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span> Inquiry Id :</span><span style={{marginLeft:6,fontWeight:'bold'}}>{this.state.singleOrder.inquiry.id}</span>
                  </p>
                ):null}

                {this.state.singleOrder.quotation != null?(
                  <div>
                    <p><i className="icon-arrow-right-circle"></i>
                      <span style={{marginLeft:6}}> Approved Quotation :</span>
                    </p>
                    <div style={{marginLeft:20,marginTop:-10,marginBottom:10}} className="quotation">
                      <p> - Fixer :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.fixer.arName ? this.state.singleOrder.quotation.fixer.arName : this.state.singleOrder.quotation.fixer.enName}</span>
                      </p>
                      <p> - Time  :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.time >= 24 ? this.state.singleOrder.quotation.time/24 + "    Day" : this.state.singleOrder.quotation.time + "    Hour"}</span>
                      </p>
                      <p> - Price :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.price}    LE (isn't applied 10 % )</span>
                      </p>
                      <p> - Price after apply 10% :
                        <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.price + this.state.singleOrder.quotation.price*10/100}    LE (this price approved from customer)</span>
                      </p>
                    </div>
                  </div>
                ):null}

            {this.state.singleOrder.quotation != null?(
              <div>
                <p><i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Approved Quotation :</span>
                </p>
                <div style={{marginLeft:20,marginTop:-10,marginBottom:10}} className="quotation">
                  <p> - Fixer :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.fixer.arName ? this.state.singleOrder.quotation.fixer.arName : this.state.singleOrder.quotation.fixer.enName}</span>
                  </p>
                  <p> - Time  :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.time >= 24 ? this.state.singleOrder.quotation.time/24 + "    Day" : this.state.singleOrder.quotation.time + "    Hour"}</span>
                  </p>
                  <p> - Price :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.price}    LE (isn't applied 10 % )</span>
                  </p>
                  <p> - Price after apply 10% :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.quotation.price + this.state.singleOrder.quotation.price*10/100}    LE (this price approved from customer)</span>
                  </p>
                </div>
              </div>
            ):null}

            {this.state.singleOrder.intervals.length != 0 && this.onSite()?(
              <div>
                <p><i className="icon-arrow-right-circle"></i>
                  <span style={{marginLeft:6}}> Order Interval :</span>
                </p>
                <div style={{marginLeft:20,marginTop:-10,marginBottom:10}} className="quotation">
                  <p> - Start Time :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.intervals[0]? moment(this.state.singleOrder.intervals[0].startPoint).format('MMMM Do YYYY, h:mm a') : 'N/A'}</span>
                  </p>
                  <p> - Start Location  :
                    <span style={{fontWeight:'bold'}}><Button disabled={!(this.state.singleOrder.startingLongitude&&this.state.singleOrder.startingLatitude)}  onClick={()=>this.props.showStartLocation()} color="light">Find On Map</Button></span>
                  </p>
                  <p> - battery level :
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.startingBattery?Math.round(this.state.singleOrder.startingBattery)+'%':'N/A'}</span>
                  </p>
                  <p> - End Time:
                    <span style={{fontWeight:'bold'}}>{this.state.singleOrder.intervals[this.state.singleOrder.intervals.length-1]?moment( this.state.singleOrder.intervals[this.state.singleOrder.intervals.length-1].endPoint ).format('MMMM Do YYYY, h:mm a')  : 'N/A'}</span>
                  </p>
                  <p> - End Location :
                    <span style={{fontWeight:'bold'}}><Button disabled={!(this.state.singleOrder.endingLongitude&&this.state.singleOrder.endingLatitude)} onClick={()=>this.props.showEndLocation()} color="light">Find On Map</Button></span>
                  </p>
                </div>
                <p>
                  <i className="icon-arrow-right-circle">
                    <Button color="link" onClick={()=>this.props.showIntervals()} >See In Details</Button>
                  </i>
                </p>
              </div>
            ):null}


            {this.state.singleOrder.estimation!=null?(
              <Button className='trelloCardLink' style={{padding:0,borderColor:'transparent',marginBottom:10}} onClick={()=>this.props.showEstimation()}>
                <i className="icon-arrow-right-circle"></i>
                {this.state.singleOrder.sourceCompanyId?(
                  <span style={{marginLeft:6, fontWeight:'bold'}}> Estimation   {this.state.singleOrder.estimation.actual} EGP</span>
                ):(
                  <span style={{marginLeft:6, fontWeight:'bold'}}> Estimation   {this.state.singleOrder.estimation.least+' : '+this.state.singleOrder.estimation.highest} EGP</span>
                )}
              </Button>
            ):null}

            {this.state.singleOrder.admins.map((item,index)=>(
              <div key={index} style={{display:'flex',flexDirection:'row',marginTop:10}}>
                <span className="iconAgent">{item.enName.slice(0,2).toUpperCase()}</span>
                <span style={{marginLeft:10,marginTop:6}}>{item.enName}</span>
              </div>
            ))}

            {/* {this.state.agentAction == true ? (
              <div style={{display:'flex',flexDirection:'row'}}>
              <span className="iconAgent">{this.state.agentIcon.toUpperCase()}</span>
              <span style={{marginLeft:10,marginTop:6}}>{this.state.agentAdminHandle}</span>
              </div>
              ):(
              <div>
              {
              this.state.adminsOrder.map((item,index)=>(
              <div key={index} style={{display:'flex',flexDirection:'row'}}>
              <span className="iconAgent">{item.enName.slice(0,2).toUpperCase()}</span>
              <span style={{marginLeft:10,marginTop:6}}>{item.enName}</span>
              </div>
              ))
              }
              </div>
            )} */}

          </Col>

        </Row>

          {this.state.singleOrder.inquiry?(
            <div>
              {this.state.singleOrder.inquiry.images.length != 0?(
                <div style={{padding:15}}>
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Order Image:</span>
                  </p>
                  <Row>
                    {this.state.singleOrder.inquiry.images.map((item, index)=>(
                      <Col key={item.id} md="4" style={{marginBottom:8}}>
                        <img className='shadow' src={item.image} style={{width:'100%', height:200,}} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ):null}
            </div>
          ):(
            <div>
              {this.state.singleOrder.repairRequestImages.length != 0?(
                <div style={{padding:15}}>
                  <p>
                    <i className="icon-arrow-right-circle"></i>
                    <span style={{marginLeft:6}}> Order Image:</span>
                  </p>
                  <Row>
                    {this.state.singleOrder.repairRequestImages.map((item, index)=>(
                      <Col key={item.id} md="4" style={{marginBottom:8}}>
                        <img className='shadow' src={item.image} style={{width:'100%', height:200,}} />
                      </Col>
                    ))}
                  </Row>
                </div>
              ):null}
            </div>
          )}

        </div>
      )}

      </div>
)
}

}
