import React from 'react';
import {CardHeader,Row,Col,InputGroup,Input,Button,InputGroupAddon} from 'reactstrap'

class Search extends React.Component{
    constructor(props){
        super(props);
        this.state={
            searchTerm:'',
        }
        this.myRef=React.createRef()
    }
    handleKeyPress = (target) => {
        if (target.charCode == 13) {
            this.props.handler(this.state.searchTerm)
            // this.clearCache()
        }
    }
    handleChangeInputs= (e) => {
        this.setState({
            searchTerm:e.target.value,
        })
    }
    clearCache = () => {
        this.setState({
            searchTerm:'',
        })
    }
    render(){
        return(
            <CardHeader className="shadow">
                <Row>
                    <Col md="8" style={{display:'flex',alignItems:'center'}}>
                        <i className="fa fa-align-justify" ></i>
                        <span style={{marginLeft:6}}>{this.props.header}</span>
                    </Col>
                    <Col md="4">
                        <InputGroup>
                            <Input
                            value={this.state.searchTerm}
                            onChange={(e)=>this.handleChangeInputs(e)}
                            type="text"
                            id="searchTerm"
                            name="searchTerm"
                            placeholder={this.props.placeholder}
                            maxLength={this.props.maxLength}
                            onKeyPress={this.handleKeyPress}
                            ref={this.myRef}

                            />
                            <InputGroupAddon addonType="prepend">
                            <Button onClick={()=>{
                                                    this.props.handler(this.state.searchTerm)
                                                    // this.clearCache()
                                                }
                                            }
                                     type="button"
                                     style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}>
                            <i className="fa fa-search"></i> Search</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                </CardHeader>
        )
    }
}
export default Search;
