import React, { Component , Fragment} from 'react';
import {FormFeedback,FormText,ButtonGroup, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import FixerRevenueService from './../../services/fixerServices/FixerRevenueService'
import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import SingleFixer from './../fixers/SingleFixer'
import SingleOrder from './../orders/SingleOrder'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import ErrorMessage from './../../components/errors/ErrorMessage'
import moment from 'moment'
import { Search, CityFilter, CategoryFilter, NoResult,FModal} from '../../components';
import SingleWorkContract from './SingleWorkContract';
import FixerWorkContractHistory from '../../components/fixerWorkContract/FixerWorkContractHistory';


class ApprovedReservations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      noResult:true,
      assignOrderModal:false,
      tab:1,
      showSingleFixer:false,
      showSingleOrder:false,
      displayFixerRevenue:false,
      displayEditFixerRevenue:false,
      singleContract:'',
      enteredOrderId:'',
      paginationInfo:[],
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      currentPage:1,
      violationsCurrentPage:1,
      isExpired:0,
      data:[],
      viewViolationModal:false,
      violations:[],
      violationsPaginationInfo:[],
      violationsPaginationLinks:[],
      violationsNextPages:[],
      violationsPreviousPages:[],
      addViolationModal:false,
      conditions:[],
      selectedCondition:[],
      selectedConditionId:[],
      revenueViolations:[],
      revenueSelectedViolations:[],
      service:[],
      approvedReservationId:'',
      revenueTypeSelected:null,
      amountValidation:'',
      revenueAmount:'',
      modalErrorMessage:false,
      terminatedModal:false,
      displayFixerRevenueConditions:false,
      fixerRevenueConditions:[],
      displayConfirmationMoadl:false,
      searchTerm:'',
      displaySearchResult:false,
      selectedCity:'',
      selectedCategory:'',
      startDate:'',
      endDate:'',
      filters:false,
      ordersModal:false,
      orders:[],
      showSingleWorkContract:false,
      selectedWorkContract:null,
      showFixerWorkContractHistoryModal:false,
      selectedFixerWorkContract:null,
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.getApprovedResevationData()
    console.log(this.state.data)
  }
  getApprovedResevationData(options = null){
    this.setState({loading:true})
    Services.getApprovedResevationData(this.state.isExpired, options ,this.state.currentPage,(res)=>{
      this.setState({
        data:res.data.approvedReservations,
        paginationInfo:res.data.paginationInfo,
        paginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        noResult:res.data.approvedReservations.length?true:false,
        loading:false
      })
    })
  }
  reload = (options = null) => {
    this.setState({showSingleFixer:false, showSingleOrder:false,})
    if (this.state.tab == 3 || this.state.tab == 4 || this.state.tab == 5 || this.state.tab == 6 || this.state.tab == 7) {
      this.getReservationRequests(options)
    }else {
      this.getApprovedResevationData(options)
    }
  }
  showSingleFixer(fixer){
    this.setState({showSingleFixer:true, singleFixer:fixer})
  }
  showSingleOrder(order){
    this.setState({showSingleOrder:true, singleOrder:order})
  }

  showFixerWorkContractHistory(fixerWorkContract){
    this.setState({
      showFixerWorkContractHistoryModal:true,
      selectedFixerWorkContract:fixerWorkContract,
    })
  }

  closeFixerWorkContractHistory(){
    this.setState({
      showFixerWorkContractHistoryModal:false,
      selectedFixerWorkContract:null,
    })
  }

  getDataByFiltration(){
    let options={
      searchTerm:this.state.searchTerm,
      cityId:this.cityRef.getCityId(),
      categoryId:this.categoryRef.getCategoryId(),
      startDate:this.state.startDate?moment(this.state.startDate).format('YYYY-MM-DD'):'',
      endDate:this.state.endDate?moment(this.state.endDate).format('YYYY-MM-DD'):'',
    }
    this.setState(()=>this.reload(options))
  }

  cityHandler(city){
    this.setState({selectedCity:city})
  }

  categoryHandler(category){
    this.setState({selectedCategory:category})
  }

  dateHandler(type,date){
    switch (type) {
      case 'start':
        this.setState({startDate:date})
        break;

      case 'end':
        this.setState({endDate:date})
        break;

      default:
        break;
    }
  }

  filter(){
    if (this.valideFilters()) {
      this.setState({filters:true,currentPage:1},()=>this.getDataByFiltration())
    }
  }

  valideFilters(){
    if( (this.state.selectedCity.enName!='Choose' && this.state.selectedCategory.enName!= 'Choose') && ( this.state.selectedCity !=''|| this.state.selectedCategory !='' || this.state.startDate !='' || this.state.endDate != '' ) ){
      if (this.state.startDate != '' && this.state.endDate != '' && this.state.startDate >this.state.endDate) {
        alert('start date can\'t be greater than end date')
        return false
      }
      return true
    }
    alert('Please , select a valid filter options')
    return false
  }

  resetFilters(){
    this.cityRef.clear()
    this.categoryRef.clear()
    this.setState({filters:false,selectedCategory:'',selectedCity:'',startDate:'',endDate:'',currentPage:1},this.state.searchTerm?()=>this.getDataByFiltration():()=>this.reload())
  }

  searchHandler(term){
    if (this.isValidTerm(term)) {
      this.setState({displaySearchResult:true,searchTerm:term,currentPage:1},()=>this.getDataByFiltration())
    }
  }

  isValidTerm(term){
    if (term != '') {
      return true
    }
    alert('Please insert a valid search term')
    return false
  }

  closeSearchResult(){
    this.searchRef.clearCache()
    this.setState({displaySearchResult:false,searchTerm:'',currentPage:1},this.state.filters?()=>this.filter():()=>this.reload())
  }

  handleAssignOrderModal(){
    this.setState({assignOrderModal:!this.state.assignOrderModal})
  }

  handleErrorMessage =()=>{
    this.setState({assignOrderModal:false},()=>this.closeModalsWithHandleErrorMessage())
  }


  closeModalsWithHandleErrorMessage(){
    this.setState({modalErrorMessage : !this.state.modalErrorMessage})
  }

  handleReservationTab(index){
    if(index == 3 || index == 4 || index == 5 || index == 6 || index == 7){
      this.setState({tab:index, currentPage:1},this.state.filters||this.state.searchTerm?()=>this.getDataByFiltration():()=>this.getReservationRequests())
    }else{
      this.setState({tab:index,isExpired:index==1?0:1, currentPage:1},this.state.filters||this.state.searchTerm?()=>this.getDataByFiltration():()=>this.getApprovedResevationData())
    }
  }
  handleViewViolationModal(){
    if (!this.state.viewViolationModal) {
      this.getViolations()
    }
    this.setState({viewViolationModal:!this.state.viewViolationModal} , () => this.resetViolationsPagination())
  }
  handleAddViolationModal(){
    this.setState({addViolationModal:!this.state.addViolationModal, selectedCondition:[]},()=>this.getConditionsForPackage())
  }

  handleTerminateModal(item){
    this.setState({terminatedModal:!this.state.terminatedModal,reservationId:item})
  }

  handleChangeInputs = (e, handle) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' ||re.test(e.target.value)) {
      this.setState({enteredOrderId:e.target.value})
    }
  }
  handleDisplayAllAreasModal(){
    this.setState({displayAllAreasModal:!this.state.displayAllAreasModal})
  }
  handleDisplayFixerRevenue(){
    this.setState({displayFixerRevenue : !this.state.displayFixerRevenue },()=>this.getDataByFiltration())
  }
  closeFixerRevenueModal(){
    this.setState({
      conditions:[],
      selectedCondition:[],
      revenueViolations:[],
      revenueSelectedViolations:[],
      revenueTypeSelected:null,
      displayFixerRevenue:false,
      revenueAmount:'',
      // amountValidation:null,

    })
  }
  closeEditFixerRevenueModal(){
    this.setState({displayEditFixerRevenue :false,})
  }
  handleDisplayEditFixerRevenue(){
    this.closeFixerRevenueModal()
    this.setState({displayEditFixerRevenue : !this.state.displayEditFixerRevenue})
  }
  confirmFixerRevenue(){
    this.setState({loading:true})
    FixerRevenueService.closeReceipt(this.state.singleContract.id, null, (res)=>{
      this.setState({loading:false})
      if (res.message == 'open_order_found') {
        alert(`sorry can’t close fixer work contract because orders don’t close`)
      }else if (res.message == 'success'){
        this.setState({
          conditions:[],
          selectedCondition:[],
          revenueViolations:[],
          revenueSelectedViolations:[],
          revenueTypeSelected:null,
          displayFixerRevenue:false,
          revenueAmount:'',
          singleContract:'',
          displayConfirmationMoadl:false,
        },()=>this.getDataByFiltration())
      }
    })
  }
  setRevenueTypeSelected(option){
    let id=this.state.singleContract.workContract.packageId;
    console.log(this.state.singleContract.workContract);
    this.setState({selectedCondition:[],revenueSelectedViolations:[],revenueAmount:'',})
    console.log(this.state.conditions)
    console.log(this.state.revenueViolations)
    if(this.state.conditions.length == 0 && this.state.revenueViolations.length == 0 ){
      this.getBonusAndPenaltyConditions(id)
    }
    this.setState({ revenueTypeSelected:option=='penalty'?'Penalty':'Bounce',})
  }
  getBonusAndPenaltyConditions(id){
    Services.getConditionsForPackage(id,(res)=>{
      let arr=res.data.conditions
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj = {id : arr[i].id , label:arr[i].description}
        titles.push(obj)
      }
      this.setState({conditions : titles,revenueViolations : titles,})
    })
  }

  handleRevenueAmount= (e) => {

    const re = /^[0-9\b]+$/;
    if (e.target.value === '' ||re.test(e.target.value)) {
      this.setState({revenueAmount:e.target.value,})
    }
    console.log(this.state.revenueAmount)

  }
  confirmEditFixerRevenue(){
    if(this.isValidRevenueEditRequest()){
      let payload={};
      if(this.state.revenueTypeSelected=='Bounce'){
        payload={additionalAmount:this.state.revenueAmount,additionalConditions:this.state.selectedConditionId}
      }else{
        payload={deductionAmount:this.state.revenueAmount,deductionConditions:this.state.selectedConditionId}
      }
      this.editFixerRevenue(payload)
    }
  }
  isValidRevenueEditRequest(){
    if( this.state.revenueTypeSelected != 'Bounce' && this.state.revenueTypeSelected != 'Penalty' ){
      alert('Please ,choose type bounce or penalty first')
      return false
    }
    if(this.state.revenueAmount =='' || this.state.revenueAmount < 0){
      alert('Please ,enter a valid revenue amount ')
      return false
    }
    if(this.state.selectedCondition.length == 0 && this.state.revenueSelectedViolations.length == 0){
      alert('Please ,choose conditions first')
      return false
    }
    return true
  }
  editFixerRevenue(payload){
    this.setState({loading:true})
    FixerRevenueService.confirm(this.state.singleContract.id, payload, (res)=>{
      this.setState({
        conditions:[],
        selectedCondition:[],
        revenueViolations:[],
        revenueSelectedViolations:[],
        selectedConditionId:[],
        revenueTypeSelected:null,
        displayFixerRevenue:false,
        displayEditFixerRevenue:false,
        revenueAmount:'',
        singleContract:'',
        loading:false
      },()=>this.getDataByFiltration())
    })
  }
  handleTabsCallFunctions(){
    if (this.state.tab == 3 || this.state.tab == 4 || this.state.tab == 5 || this.state.tab == 6 || this.state.tab == 7) {
      this.state.filters||this.state.searchTerm?this.getDataByFiltration():this.getReservationRequests()
    }else {
      this.state.filters||this.state.searchTerm?this.getDataByFiltration():this.getApprovedResevationData()
    }
  }
  prevPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page-1},()=>this.handleTabsCallFunctions())
    }else {
      this.setState({currentPage: item},()=>this.handleTabsCallFunctions())
    }
  }

  nextPage(item){
    let page = this.state.currentPage
    if (item == '') {
      this.setState({currentPage: page+1},()=>this.handleTabsCallFunctions())
    }else {
      this.setState({currentPage: item},()=>this.handleTabsCallFunctions())
    }
  }

  prevViolationsPage(item){
    let page = this.state.violationsCurrentPage
    if (item == '') {
      this.setState({violationsCurrentPage: page-1},()=>this.getViolations())
    }else {
      this.setState({violationsCurrentPage: item},()=>this.getViolations())
    }
  }
  nextViolationsPage(item){
    let page = this.state.violationsCurrentPage
    if (item == '') {
      this.setState({violationsCurrentPage: page+1},()=>this.getViolations())
    }else {
      this.setState({violationsCurrentPage: item},()=>this.getViolations())
    }
  }

  assignOrder(){
    if (!this.state.enteredOrderId.replace(/\s/g, '').length) {
      alert('Please, make sure you enter valid order Id')
      return
    }
    this.setState({loading:true})
    Services.assignOrderOnApprovedReservation(this.state.enteredOrderId, this.state.singleContract.id, (res)=>{
      if (res.message == 'fixer_is_unavailable') {
        console.log(res)
        this.setState({loading:false},()=>this.closeModalsWithHandleErrorMessage())
      }else if(res.message == "not_allowed") {
        this.setState({loading:false})
        alert(`Sorry, you can't add order please, check that:\n
        1.order is not quotation\n
        2-order status is not [Done or inspected or video order]\n
        3-order's fixer is the same fixer of fixer work contract\n
        4-you can't add order because, service group doesn't match fwc`)
      }else {
        this.setState({loading:false})
        if (res.message == 'success') {
          this.setState({assignOrderModal:false},()=>this.getDataByFiltration())
        }else {
          alert('Order doesn\'t exist')
        }
      }
    })
  }

  getViolations(){
    this.setState({loading:true})
    Services.getViolations(this.state.singleContract.id , this.state.violationsCurrentPage , (res)=>{
      this.setState({
        loading:false,
        violations:res.data.violations,
        violationsPaginationInfo:res.data.paginationInfo,
        violationsPaginationLinks:res.data.paginationLinks,
        violationsPreviousPages:res.data.paginationLinks.previousPages,
        violationsNextPages:res.data.paginationLinks.nextPages
      })
    })
  }

  resetViolationsPagination(){
    this.setState({ violationsCurrentPage  : 1})
  }

  onChangeMultiSelect(itemSelected){
    let arr = []
    for (var i = 0; i < itemSelected.length; i++) {
      arr.push(itemSelected[i].id)
    }
    console.log(arr)
    this.setState({selectedCondition:itemSelected,selectedConditionId:arr})
  }

  getConditionsForPackage(){
    this.setState({loading:true})
    Services.getConditionsForPackage(this.state.singleContract.workContract.packageId,(res)=>{
      let arr = res.data.conditions
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj = {id : arr[i].id , label:arr[i].description}
        titles.push(obj)
      }
      this.setState({conditions : titles ,loading:false})
    })
  }



  addViolationOnApprovedReservation(){
    this.setState({loading:true})
    Services.addViolationOnApprovedReservation(this.state.selectedConditionId,this.state.singleContract.id,(res)=>{
      if(this.state.tab == 4 || this.state.tab == 3 || this.state.tab == 5){
        this.setState({loading:false,addViolationModal:!this.state.addViolationModal}, () => this.getDataByFiltration())
      }else{
        this.setState({loading:false,addViolationModal:!this.state.addViolationModal}, () => this.getDataByFiltration())
      }
    })
  }


  getReservationRequests(options = null){
    this.setState({loading:true})
    let statusId = '', withProfit = -1

    if (this.state.tab == 4) statusId = 27
    else if (this.state.tab == 3) statusId = 24
    else if (this.state.tab == 5 || this.state.tab == 6) statusId = 25
    else if (this.state.tab == 7) statusId = 53
    else statusId = ''

    if (this.state.tab == 5) withProfit = 1
    else if (this.state.tab == 6) withProfit = 0

    Services.getReservationRequests(withProfit, statusId , options, this.state.currentPage, (res)=>{
      this.setState({
        data:res.data.workContractReservations,
        paginationInfo:res.data.paginationInfo,
        paginationLinks:res.data.paginationLinks,
        previousPages:res.data.paginationLinks.previousPages,
        nextPages:res.data.paginationLinks.nextPages,
        noResult:res.data.workContractReservations.length?true:false,
        loading:false
      })
    })
  }

  // handleTerminatedTab(index){
  //   this.setState({tab : index},()=>this.getReservationRequests())
  // }



  actionTerminationForReservationRequest(){
    this.setState({loading:true})
    Services.actionTerminationForReservationRequest(this.state.reservationId.id,(res)=>{
      this.setState({loading : false,terminatedModal:!this.state.terminatedModal},()=>this.getDataByFiltration())
    })
  }


  viewOrdersModal(item){
    this.setState({ordersModal:!this.state.ordersModal,item:item},()=>this.getOrdersApprovedReservations(item.id))
  }


  getOrdersApprovedReservations(reservationId){
    this.setState({loading:true})
    Services.getOrdersApprovedReservations(reservationId,(res)=>{
      this.setState({orders:res.data.orders,loading:false})
    })
  }

  render() {
    return (
      <div className="app align-items-center">

        <Container>
          {this.renderFilters()}
          <Card>
            {this.renderSearchBar()}
            <CardHeader>
              {this.renderTabReservation()}
            </CardHeader>
            <CardBody className='shadow'>
              {this.state.displaySearchResult?(
                <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
                  <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
                  <h6 style={{fontSize:18}}>Search Results of
                    <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchTerm}</span>

                  </h6>
                  <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.closeSearchResult()}>
                    Clear Search
                    <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                  </span>
                </div>
              ):null}
              {this.state.noResult?(<>
                {this.renderRequestReservation()}
                {this.renderPagination()}
              </>):( <NoResult/>)}
            </CardBody>

            {this.state.showSingleWorkContract?(
              <SingleWorkContract
                workContract={this.state.selectedWorkContract}
                onClose={()=>this.setState({showSingleWorkContract:false,selectedWorkContract:null})}
              />
            ):null}

            {this.state.showSingleFixer?<SingleFixer data={this.state.singleFixer} reload={()=>this.getDataByFiltration()}/>:null}

            {this.state.showSingleOrder?<SingleOrder data={this.state.singleOrder} reload={()=>this.getDataByFiltration()} />:null}
            {this.state.modalErrorMessage ? <ErrorMessage modalErrorMessage={this.state.modalErrorMessage} handleErrorMessage={this.handleErrorMessage} titlePopUp={<i color="white" style={{fontSize:80}} className="far fa-frown"></i>} errorMessage={"You can’t confirm or redirect this order because fixer has order in this period ."} close={"OK"} width={'35%'} visible={"none"}/>:null}

            <FModal
                title='Fixer Work Contract History'
                content={<FixerWorkContractHistory fixerWorkContract={this.state.selectedFixerWorkContract} />}
                state={this.state.showFixerWorkContractHistoryModal}
                noSubmitBtn={true}
                onClose={()=>this.closeFixerWorkContractHistory()}
              />


            {/* all areas modal */}
            <Modal fade={false} isOpen={this.state.displayAllAreasModal} toggle={()=>this.handleDisplayAllAreasModal()} className={this.props.className}>
              <ModalHeader toggle={()=>this.handleDisplayAllAreasModal()}>Areas</ModalHeader>
              <ModalBody>
                {this.state.singleContract!=''?(
                  <div className='orderBadges' style={{width:350, textAlign:'center', display:'block', margin:'auto'}}>
                    {this.state.singleContract.workContract.areas.map((item, index)=>(
                      <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent', border:'1px solid #5a5a5a', color:'#5a5a5a',fontSize:12}}>{item.enName}</span>
                    ))}
                  </div>
                ):null}
              </ModalBody>
              <ModalFooter>
                <Button className='actionsBtn' onClick={()=>this.handleDisplayAllAreasModal()}>close</Button>
              </ModalFooter>
            </Modal>
            {/* Fixer Revenue modal   */}
            <Modal fade={true} isOpen={this.state.displayFixerRevenue} toggle={()=>this.closeFixerRevenueModal()} className={this.props.className}>
              <ModalHeader toggle={()=>this.closeFixerRevenueModal()}>Fixer Revenue</ModalHeader>
              <ModalBody>
                {this.state.singleContract!=''?(
                  <Fragment>


                    <div className='orderBadges' style={{width:350, textAlign:'center', display:'block', margin:'auto'}}>
                      <strong>Orders Number : {this.state.singleContract.receipt != null ? this.state.singleContract.receipt.numberOfDoneOrders : "N/A"} order</strong>
                    </div>
                    <div className='orderBadges' style={{width:350, textAlign:'center', display:'block', margin:'auto'}}>
                      <strong>Total Orders :
                        {this.state.singleContract.workContract.isCompanyPercentageCalculatedOnTotalPrice ? Math.round(this.state.singleContract.receipt.totalOrdersAmount) : Math.round(this.state.singleContract.receipt.totalOrdersFixingAmount) } LE
                      </strong>
                    </div>
                    <div className='orderBadges' style={{width:600, textAlign:'center', display:'block', margin:'auto'}}>
                      <Table  hover bordered striped responsive size="md">
                        <thead>
                          <tr>
                            <th style={{textAlign:'center' }}>Key</th>
                            <th style={{textAlign:'center' }}>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Fixer Percentage</th>
                            <th style={{textAlign:'center',width:'40%'}}>{this.state.singleContract.receipt != null ? `${this.state.singleContract.receipt.fixerPercentageAmount} LE ( ${100-this.state.singleContract.workContract.companyPercentage} %)` : "N/A"} </th>
                          </tr>
                          <tr>
                            <th style={{textAlign:'center',width:'40%'}}>Basic</th>
                            <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.workContract.basicSalary} LE</div></th>
                          </tr>

                          <tr>
                            <th style={{textAlign:'center'}}>Orders Slots</th>
                            <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.receipt != null ? this.state.singleContract.receipt.totalOrdersBonuses : "N/A"} LE</div></th>
                          </tr>
                          <tr>
                            <th style={{textAlign:'center'}}>Default Bonus</th>
                            <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{!this.state.singleContract.workContract.defaultBonus?"N/A" :this.state.singleContract.workContract.defaultBonus +' LE' } </div></th>
                          </tr>
                          <tr>
                            <th style={{textAlign:'center'}}>Target</th>
                            <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.workContract.targetType == 'orders_count' ? `${this.state.singleContract.workContract.target}  Orders`: this.state.singleContract.workContract.targetType == 'fixed_amount' ? `${this.state.singleContract.workContract.target} LE` :"N/A"}</div></th>
                            </tr>
                            <tr>
                              <th style={{textAlign:'center'}}>Reward</th>
                              <th style={{textAlign:'center',width:'40%'}}>
                                <div style={{textAlign:'center'}}>
                                  {this.state.singleContract.receipt != null ?
                                     !this.state.singleContract.receipt.rewardAmount? "N\A" :
                                      `${this.state.singleContract.receipt.rewardAmount} LE`  + (this.state.singleContract.workContract.rewardType == "percentage" ?
                                        `( ${this.state.singleContract.workContract.reward} % )`  :
                                        '' ) : "N/A"}
                                      </div>
                                    </th>
                                </tr>
                                {this.state.singleContract.receipt.additionalAmount?(
                                  <tr>
                                    <th style={{textAlign:'center',width:'40%'}}>Additional Amount</th>
                                    <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.receipt.additionalAmount + " LE"}</div></th>
                                  </tr>
                                ):null}
                                {this.state.singleContract.receipt.deductionAmount?(
                                  <tr>
                                    <th style={{textAlign:'center',width:'40%'}}>Deduction Amount</th>
                                    <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.singleContract.receipt.deductionAmount + " LE"}</div></th>
                                  </tr>
                                ):null}
                                {this.state.singleContract.receipt.additionalConditions.length&&this.state.singleContract.receipt.additionalAmount?(
                                  <tr>
                                    <th style={{textAlign:'center',width:'40%'}}>Bonus Conditions</th>
                                    <th style={{textAlign:'center',width:'40%'}}><Button color="link" onClick={()=>this.displayFixerRevenueConditions(this.state.singleContract.receipt.additionalConditions)} >Show Details</Button> </th>
                                  </tr>
                                ):null}
                                {this.state.singleContract.receipt.deductionConditions.length&&this.state.singleContract.receipt.deductionAmount?(
                                  <tr>
                                    <th style={{textAlign:'center',width:'40%'}}>Penalty Violations</th>
                                    <th style={{textAlign:'center',width:'40%'}}><Button color="link" onClick={()=>this.displayFixerRevenueConditions(this.state.singleContract.receipt.deductionConditions)} >Show Details</Button> </th>
                                  </tr>
                                ):null}
                                  <tr>
                                    <th style={{textAlign:'center',width:'40%'}}>Fixer Revenue</th>
                                    <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{!this.state.singleContract.receipt.fixerIncome ? "N/A" : this.state.singleContract.receipt.fixerIncome + " LE"}</div></th>
                                  </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Fragment>
                      ):null}
                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.handleDisplayFixerRevenue()}>Close</Button>
                      {
                        (this.state.tab !==3 && this.state.tab !=1 && this.state.tab != 5 && this.state.tab != 6) ? (
                          <Fragment>
                            <Button className='actionsBtn' onClick={()=>this.handleDisplayEditFixerRevenue()}>Edit</Button>
                            <Button className='actionsBtn' onClick={()=>this.confirmFixerRevenue()}>Confirm</Button>
                          </Fragment>
                        ) : null
                      }

                    </ModalFooter>
                  </Modal>
                  {/* fixer revenue conditions modal  */}
                  <Modal fade={true} isOpen={this.state.displayFixerRevenueConditions} toggle={()=>this.closeFixerRevenueConditions()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.closeFixerRevenueConditions()}>Fixer Revenue Conditions</ModalHeader>
                    <ModalBody>
                      <Table  hover bordered striped responsive size="md">
                          <thead>
                            <tr>
                              <th style={{textAlign:'center' }}>conditions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.fixerRevenueConditions.length?(
                              this.state.fixerRevenueConditions.map((item,index)=>(
                              <tr key={index}>
                                <th style={{textAlign:'center',width:'40%'}}>{item.description}</th>
                              </tr>
                              ))
                            ):null}
                          </tbody>
                      </Table>
                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.closeFixerRevenueConditions()}>close</Button>
                    </ModalFooter>
                  </Modal>
                  {/* confirmation modal  */}
                  <Modal fade={true} isOpen={this.state.displayConfirmationMoadl} toggle={()=>this.closeConfirmationModal()} >
                    <ModalHeader toggle={()=>this.closeConfirmationModal()}>Confirmation Message</ModalHeader>
                    <ModalBody>
                          <div style={{width:'100%',padding:'2%'}}>
                            <p>Are you sure to confirm fixer revenue and create entries with these value?</p>
                          </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.closeConfirmationModal()}>Close</Button>
                        <Button className='actionsBtn' onClick={()=>this.confirmFixerRevenue()}>Confirm</Button>
                    </ModalFooter>
                  </Modal>

                  {/* Edit Fixer Revenue modal   */}
                  <Modal fade={true} isOpen={this.state.displayEditFixerRevenue} toggle={()=>this.closeEditFixerRevenueModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.closeEditFixerRevenueModal()}>Edit Fixer Revenue</ModalHeader>
                    <ModalBody>
                      {this.state.singleContract!=''?(
                        <div style={{padding:'2%'}}>
                          <div style={{width:'50%'}}>
                            <p>Type :</p>
                          </div>
                          <Row>
                            <UncontrolledDropdown style={{width:'50%', paddingLeft:'2%' ,marginBottom:'4%'}} >
                              <DropdownToggle style={{width:'100%'}} caret>
                                {(this.state.revenueTypeSelected) ? this.state.revenueTypeSelected : 'Choose'}
                              </DropdownToggle>
                              <DropdownMenu style={{width:'95%',textAlign:'center'}} >
                                <DropdownItem style={{width:'100%'}} onClick={()=>this.setRevenueTypeSelected('penalty')} >Penalty</DropdownItem>
                                <DropdownItem style={{width:'100%'}} onClick={()=>this.setRevenueTypeSelected('bonus')}>Bonus</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Row>

                          <div style={{width:'50%', paddingLeft:'2%'}} >
                            <Row>
                              <p>Amount :</p>
                            </Row>
                            <Row>
                              <Input
                                value={this.state.revenueAmount}
                                style={{width:'50%' , marginBottom:'5px'}}
                                onChange={(e)=>this.handleRevenueAmount(e)}
                                type="text"
                                placeholder="Enter Amount..."
                                maxLength={10}
                              /> <div style={{marginLeft:'5px', padding:'7px'}}>LE</div>

                              {this.state.revenueTypeSelected!=null?(this.renderRevenueTypeSelectedMenu()) : null}
                            </Row>
                          </div>
                        </div>
                      ):null}
                    </ModalBody>
                    <ModalFooter>

                      <Button className='actionsBtn' onClick={()=>this.handleDisplayEditFixerRevenue()}>Close</Button>
                      <Button className='actionsBtn' onClick={()=>this.confirmEditFixerRevenue()}>Edit</Button>
                    </ModalFooter>
                  </Modal>

                  {/* assign order modal */}
                  <Modal fade={false} isOpen={this.state.assignOrderModal} toggle={()=>this.handleAssignOrderModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.handleAssignOrderModal()}>Assign Order</ModalHeader>
                    <ModalBody>
                      <div style={{width:'50%', paddingLeft:'2%'}}>
                        <Input
                          value={this.state.enteredOrderId}
                          onChange={(e)=>this.handleChangeInputs(e, 'search')}
                          type="text"
                          placeholder="Enter order id.."
                          maxLength={50}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.handleDisplayAllAreasModal()}>close</Button>
                    </ModalFooter>
                  </Modal>

                  {/* assign order modal */}
                  <Modal fade={false} isOpen={this.state.assignOrderModal} toggle={()=>this.handleAssignOrderModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.handleAssignOrderModal()}>Assign Order</ModalHeader>
                    <ModalBody>
                      <div style={{width:'50%', paddingLeft:'2%'}}>
                        <Input
                          value={this.state.enteredOrderId}
                          onChange={(e)=>this.handleChangeInputs(e, 'search')}
                          type="text"
                          placeholder="Enter order id.."
                          maxLength={50}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>

                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.handleAssignOrderModal()}>Cancel</Button>
                      <Button className='actionsBtn' onClick={()=>this.assignOrder()}>Assign</Button>
                    </ModalFooter>
                  </Modal>



                  {/* view Violation modal */}
                  <Modal fade={false} isOpen={this.state.viewViolationModal} toggle={()=>this.handleViewViolationModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.handleViewViolationModal()}>Violation</ModalHeader>
                    <ModalBody>
                      {this.state.violations.length!=0?this.renderViolationContent():null}
                      {this.renderViolationsPagination()}

                    </ModalBody>
                    <ModalFooter>
                      <Button className='actionsBtn' onClick={()=>this.handleViewViolationModal()}>Close</Button>
                    </ModalFooter>
                  </Modal>


                  {/* add violation modal  */}
                  <Modal fade={false} isOpen={this.state.addViolationModal} toggle={()=>this.handleAddViolationModal()} className={this.props.className}>
                    <ModalHeader toggle={()=>this.handleAddViolationModal()}>Add Violation</ModalHeader>
                    <ModalBody>
                      <div className='approvedCondition' style={{width:'50%', paddingLeft:'2%',marginLeft:'auto',marginRight:'auto', direction:'rtl'}}>
                        {this.state.conditions.length != 0 ?
                          <MultiSelect
                            items={this.state.conditions}
                            selectedItems={this.state.selectedCondition}
                            onChange={(item)=>this.onChangeMultiSelect(item)}
                            withGrouping={true}
                            showSelectedItems={false}
                            responsiveHeight={400}
                          />:null}

                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button className='actionsBtn' onClick={()=>this.handleAddViolationModal()}>Cancel</Button>
                        <Button className='actionsBtn' onClick={()=>this.addViolationOnApprovedReservation()}>Add</Button>
                      </ModalFooter>
                    </Modal>


                    {/* terminated Work Contract */}
                    <Modal fade={false} isOpen={this.state.terminatedModal} toggle={()=>this.handleTerminateModal()} className={this.props.className}>
                      <ModalHeader toggle={()=>this.handleTerminateModal()}>Terminate</ModalHeader>
                      <ModalBody>
                        <div style={{paddingLeft:'3%'}}>
                          <p>Are you sure terminate fixer work contract?</p>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button className='actionsBtn' onClick={()=>this.handleTerminateModal()}>No</Button>
                        <Button className='actionsBtn' onClick={()=>this.actionTerminationForReservationRequest()}>Yes</Button>
                      </ModalFooter>
                    </Modal>

            <FModal
            title='Orders'
            content={
            <div style={{marginLeft:20}}>
              {this.state.orders.length == 0 ? (
                <NoResult/>
              ) : (
                <div>
                <div>
                {this.state.orders.slice([0], [12]).map((item,index)=>(
                      <span key={index} id={'tooltip'+item.id} onClick={()=>this.showSingleOrder({id:item.id})} className='noWrap someSpace customShadow' style={{background:this.state.item.fixer.id != item.fixerId ? '#a91010' :'#3a4248',color:'white'}}>
                       @{item.serial}
                       <UncontrolledTooltip target={'tooltip'+item.id}>{item.status.enName}</UncontrolledTooltip>
                     </span>
                  ))}
                </div>
                <div style={{marginTop:10}}>
                {this.state.orders.slice([13], [25]).map((item,index)=>(
                      <span key={index} id={'tooltip'+item.id} onClick={()=>this.showSingleOrder({id:item.id})} className='noWrap someSpace customShadow' style={{background:this.state.item.fixer.id != item.fixerId ? '#a91010' :'#3a4248',color:'white'}}>
                       @{item.serial}
                       <UncontrolledTooltip target={'tooltip'+item.id}>{item.status.enName}</UncontrolledTooltip>
                     </span>
                  ))}
                </div>
                <div style={{marginTop:10}}>
                {this.state.orders.slice([26], [40]).map((item,index)=>(
                      <span key={index} id={'tooltip'+item.id} onClick={()=>this.showSingleOrder({id:item.id})} className='noWrap someSpace customShadow' style={{background:this.state.item.fixer.id != item.fixerId ? '#a91010' :'#3a4248',color:'white'}}>
                       @{item.serial}
                       <UncontrolledTooltip target={'tooltip'+item.id}>{item.status.enName}</UncontrolledTooltip>
                     </span>
                  ))}
                </div>
                </div>
              )}

            </div>
            }
            state={this.state.ordersModal}
            onClose={()=>this.viewOrdersModal(this.state.item)}
            show={"none"}
          />




                    {/* loading spinner */}
                    <Modal fade={false} isOpen={this.state.loading} className='loading'>
                      <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
                    </Modal>

                  </Card>
                </Container>
              </div>
            )
          }

          renderRevenueTypeSelectedMenu(){
            return  (
              <Col md="10" style={{marginLeft:'-3%'}}>
                <Label>{this.state.revenueTypeSelected=='bonus'?'Conditions:':'Violations:'}</Label>
                <MultiSelect
                  items={this.state.conditions}
                  selectedItems={this.state.revenueTypeSelected=='bonus'?this.state.selectedCondition:this.state.revenueSelectedViolations}
                  onChange={(item)=>this.onChangeMultiSelect(item)}
                  withGrouping={true}
                  showSelectedItems={false}
                />
              </Col>
            )
            // : (
            //   <Col md="10" style={{marginLeft:'-3%'}}>
            //     <Label>Violations:</Label>
            //     <MultiSelect
            //       items={this.state.revenueViolations}
            //       selectedItems={this.state.revenueSelectedViolations}
            //       onChange={(item)=>this.onChangeMultiSelect(item)}
            //       withGrouping={true}
            //       showSelectedItems={false}
            //     />
            //   </Col>
            // )
          }


          renderRequestReservation(){
            console.log(this.state.data)
            return(
              <>
              <div>
                <Table hover bordered striped responsive size="md">
                  <thead>
                    <tr>
                      <th style={{textAlign:'center',width:'5%'}}>ID</th>
                      <th style={{textAlign:'center'}}>Fixer</th>
                      <th style={{textAlign:'center'}}>Contract ID</th>
                      <th style={{textAlign:'center'}}>Contract Date</th>
                      <th style={{textAlign:'center'}}>City</th>
                      <th style={{textAlign:'center'}}>Areas</th>
                      <th style={{textAlign:'center'}}>Category</th>
                      <th style={{textAlign:'center',width:'5%'}}>Package</th>
                      <th style={{textAlign:'center'}}>Expiration</th>
                      <th style={{textAlign:'center'}}>Orders</th>
                      <th style={{textAlign:'center'}}>Total Orders Value</th>
                      <th style={{textAlign:'center'}}>Fixer Revenue</th>
                      {this.state.tab==4?<th style={{textAlign:'center'}}>Termination Date</th>:null}
                      <th style={{textAlign:'center'}}>Violation</th>
                      {this.state.tab == 1 ? (
                        <th style={{textAlign:'center'}}>Terminate</th>
                      ):null}
                      {this.state.tab == 5 ? (
                        <th style={{textAlign:'center'}}>Close Time</th>
                      ): null}
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.data.map((item, index)=>(
                      <tr key={index}>
                      <td style={{textAlign:'center',width:'5%'}}>

                          <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', minWidth:100}} onClick={()=>this.showFixerWorkContractHistory(item)}>
                            {item.id}
                          </Button>
                        </td>
                          <td style={{textAlign:'center'}}><Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold', minWidth:100}} onClick={()=>this.showSingleFixer({id:item.fixer.id})}>{item.fixer.enName?item.fixer.enName:item.fixer.arName}</Button></td>
                          <td style={{textAlign:'center',width:'5%'}}>
                            <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({showSingleWorkContract:true,selectedWorkContract:item.workContract})}>{item.workContract.id}</Button>
                          </td>
                          <td style={{textAlign:'center'}}><div style={{width:80}}>{item.workContract.startDate}</div></td>
                          <td style={{textAlign:'center'}}>{item.workContract.city.enName}</td>
                          <td style={{textAlign:'center'}}>
                            <span  className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({singleContract:item},()=>this.handleDisplayAllAreasModal())}>Show Areas</span>
                          </td>
                          <td style={{textAlign:'center'}}>{item.workContract.category.enName}</td>
                          <td style={{textAlign:'center',width:'5%'}}>{item.workContract.package.arName}</td>
                          <td style={{textAlign:'center'}}>{item.expiration} day(s) {this.state.tab==1?'left':'ago'}</td>

                          <td style={{textAlign:'center'}}>
                            <div className='orderBadges' style={{width:300}}>
                              {/* {item.orders.map((i, x)=>(
                                <span key={x} id={'tooltip'+i.id} onClick={()=>this.showSingleOrder({id:i.id})} className='noWrap someSpace customShadow' style={{background:item.fixer.id != i.fixerId ? '#a91010' :'#3a4248'}}>
                                  @{i.id}
                                  <UncontrolledTooltip target={'tooltip'+i.id}>{i.status.enName}</UncontrolledTooltip>
                                </span>
                              ))} */}
                              <span className='noWrap someSpace' style={{background:'#feb600', color:'#000'}} onClick={()=>this.viewOrdersModal(item)}>View Orders</span>
                              {this.state.tab!=3 && this.state.tab!=4 && this.state.tab !=5 &&  this.state.tab !=6 ?
                                <span className='noWrap someSpace' style={{background:'#feb600', color:'#000'}} onClick={()=>this.setState({singleContract:item, enteredOrderId:''},()=>this.handleAssignOrderModal())}>Add</span>
                              :null}
                            </div>
                          </td>


                          <td style={{textAlign:'center'}}>{item.workContract.isCompanyPercentageCalculatedOnTotalPrice ? Math.round(item.receipt.totalOrdersAmount) : Math.round(item.receipt.totalOrdersFixingAmount) }</td>
                          <td style={{textAlign:'center'}}>
                            <div className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({singleContract:item},()=>this.handleDisplayFixerRevenue())}>
                              {item.receipt != null ? <div> {!item.receipt.fixerIncome ? "N/A" : item.receipt.fixerIncome }</div> : "N/A" }
                            </div>
                          </td>
                          {this.state.tab==4&&item.finalTerminationAction!=null?<td style={{textAlign:'center'}}><div style={{width:80}}>{moment(item.finalTerminationAction.createdAt).format("YYYY-MM-DD")}</div></td>:null}
                          <td style={{textAlign:'center'}}>
                            <div className='orderBadges' >
                              {item.violationsCount != 0?(
                                <span className='noWrap someSpace' style={{background:'#3a4248', color:'#fff'}} onClick={()=>this.setState({singleContract:item,},()=>this.handleViewViolationModal())}>{item.violationsCount}</span>
                              ):"N/A"}
                              {this.state.tab !==3 && this.state.tab !=5? (
                                <span className='noWrap someSpace' style={{background:'#feb600', color:'#000'}} onClick={()=>this.setState({singleContract:item},()=>this.handleAddViolationModal())}>Add</span>
                              ):null}
                            </div>
                          </td>
                          {this.state.tab == 1 ? (
                            <td style={{textAlign:'center'}}>
                              <div className='orderBadges' >
                                <span className='noWrap someSpace' style={{background:'#feb600', color:'#000'}} onClick={()=>this.handleTerminateModal(item)}>Terminate</span>
                              </div>
                            </td>
                          ) : null}
                          {this.state.tab == 5 ? (
                            <th style={{textAlign:'center'}}>
                              <div style={{fontWeight:'normal', minWidth:100}}>{item.closingTime != null ? item.closingTime : 'N/A'}</div>
                            </th>
                          ): null}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                </>
              )
            }


            renderTabReservation(){
              return (
                <div>
                  <Row className="rowTabReservation">
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(1)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==1?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Approved</Button>
                    </Col>
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(2)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==2?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Expired</Button>
                    </Col>
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(3)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==3?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Pending Review</Button>
                    </Col>
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(4)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==4?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Terminated</Button>
                    </Col>
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(5)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==5?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Closed On Profit</Button>
                    </Col>
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(6)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==6?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Closed W/O Profit</Button>
                    </Col>
                    <Col md="2" className="tabButton">
                      <Button onClick={()=>this.handleReservationTab(7)} className='actionsBtn actionBtnReservation' style={{backgroundColor:this.state.tab==7?'#3a4248':'#feb500',color:'#fff',width:'100%'}}>Closed Manual</Button>
                    </Col>
                  </Row>
                </div>
              )
            }

            renderFilters(){
              return(
                <Row style={{paddingLeft:10}}>
                  <CityFilter handler={(city)=>this.cityHandler(city)} ref={(ref)=>this.cityRef=ref}/>
                  <div style={{marginLeft:20}}>
                    <CategoryFilter handler={(category)=>this.categoryHandler(category)} ref={(ref)=>this.categoryRef=ref}/>
                  </div>

                  <div style={{marginLeft:30}} className='date'>
                    <DatePicker
                      selected={this.state.startDate}
                      onChange={(date)=> this.dateHandler('start', date) }
                      dateFormat="dd-MM-yyyy"
                      placeholderText='Start date'
                    />
                  </div>
                  <div style={{marginLeft:15}} className='date'>
                    <DatePicker
                        selected={this.state.endDate}
                        onChange={(date)=> this.dateHandler('end', date) }
                        dateFormat="dd-MM-yyyy"
                        placeholderText='End date'
                      />
                  </div>
                  <Col md={1}>
                    <Button onClick={()=>this.filter()}
                      className='actionsBtn'
                      style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
                  </Col>
                  <Col md={1}>
                    <Button onClick={()=>this.resetFilters()}
                      className='actionsBtn'
                      style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'white'}}>Reset</Button>
                  </Col>
                </Row>
              )
            }

            renderSearchBar(){
              return(
                <Search header='Approved Reservation'
                handler={(term)=>this.searchHandler(term)}
                maxLength='150'
                ref={(ref)=>this.searchRef=ref}
                placeholder='Search by id,name ....' />
              )
            }

            renderPagination(){
              return(
                <nav>
                  <Row className='pull-right'>
                    <Pagination style={{justifyContent:'flex-end'}}>
                      {/* previous link*/}
                      <PaginationItem>
                        <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
                      </PaginationItem>
                      {/* previousPages */}
                      {this.state.previousPages.map((item, index)=>(
                        <PaginationItem key={index}>
                          <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                            {item}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      {/* currentPage */}
                      <PaginationItem active>
                        <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                          {this.state.paginationInfo.currentPage}
                        </PaginationLink>
                      </PaginationItem>


                      {/* nextPages */}
                      {this.state.nextPages.map((item, index)=>(
                        <PaginationItem key={index}>
                          <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                            {item}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      {/* nextPageUrl */}
                      <PaginationItem>
                        <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
                      </PaginationItem>
                    </Pagination>
                  </Row>
                </nav>
              )
            }



            renderViolationsPagination(){
              return(
                <nav>
                  <Row className='pull-right'>
                    <Pagination style={{justifyContent:'flex-end'}}>
                      {/* previous link*/}
                      <PaginationItem>
                        <PaginationLink previous tag="button" onClick={()=>this.state.violationsPaginationInfo.prevPageUrl!=null?this.prevViolationsPage(''):null}/>
                      </PaginationItem>

                      {/* previousPages */}
                      {this.state.violationsPreviousPages.map((item, index)=>(
                        <PaginationItem key={index}>
                          <PaginationLink tag="button" onClick={()=>this.prevViolationsPage(item)}>
                            {item}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      {/* currentPage */}
                      <PaginationItem active>
                        <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                          {this.state.violationsPaginationInfo.currentPage}
                        </PaginationLink>
                      </PaginationItem>


                      {/* nextPages */}
                      {this.state.violationsNextPages.map((item, index)=>(
                        <PaginationItem key={index}>
                          <PaginationLink tag="button" onClick={()=>this.nextViolationsPage(item)}>
                            {item}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      {/* nextPageUrl */}
                      <PaginationItem>
                        <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.violationsPaginationInfo.nextPageUrl!=null?this.nextViolationsPage(''):null} />
                      </PaginationItem>
                    </Pagination>
                  </Row>
                </nav>
              )
            }



            renderViolationContent(){
              return(
                <Table hover bordered striped responsive size="md">
                  <thead>
                    <tr>
                      <th style={{textAlign:'center',width:'5%'}}>Admin</th>
                      <th style={{textAlign:'center'}}>Action ID</th>
                      <th style={{textAlign:'center'}}>Conditions</th>
                      <th style={{textAlign:'center'}}>Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>

                    {this.state.violations.map((item, index)=>(
                      <tr key={index}>
                        <td style={{textAlign:'center',width:'5%'}}>{item.actionMaker.enName?item.actionMaker.enName:item.actionMaker.arName}</td>
                        <td style={{textAlign:'center'}}>{item.id}</td>
                        <td style={{textAlign:'center'}}>
                          <div style={{paddingLeft:20, paddingRight:20}}>
                            {item.conditions.map((i, ind)=>(
                              <div key={ind} style={{textAlign:'right', padding:5}}>{i.description}  * </div>
                            ))}
                          </div>
                        </td>
                        <td style={{textAlign:'center'}}>{item.createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )

            }


          }

          export default withRouter(ApprovedReservations);
