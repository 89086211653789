import { Global } from './../../core/Global'
import Adaptor from './../Adaptor'


export default class CustomersServices{

  static create(data,callBack) {
    Adaptor.post(`${Global.baseURL}admin-panel/customers`,data).then(callBack)
  }

  static listEqupmentsRequest(page,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/equipment-requests?page=${page}`).then(callBack)
  }

  static getTemporaryWallet(id,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/users/${id}/temporary-wallet`).then(callBack)
  }

  static getOfferablePriceOrders(customerId , callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/orders/price-offerable?customerId=${customerId}`).then(callBack)
  }

  static getHistory(id , callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/customers/${id}/history`).then(callBack)
  }

  static getEstimation(id , callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/customers/${id}/carts`).then(callBack)
  }

  static emailIsExist(data, callBack) {
    Adaptor.post(`${Global.baseURL}isEmailExist`,data).then(callBack)
  }


}
