import React, { Component } from "react";
import {Col,Input,Row,Label,DropdownItem,DropdownMenu,DropdownToggle,UncontrolledDropdown, Button} from "reactstrap";
import { Global } from "../../core/Global";
import Services from "../../services/Services";
import LoadingOverlay from "react-loading-overlay";
import CategoryFilter from './../../components/Filters/CategoryFilter'
import { LoadingSpinner } from "../../components";
export default class AddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      arName:'',
      enName:'',
      selectedCategory:'Category',
      selectedCategoryId:'',
      selectedServiceGroup:'ServiceGroup',
      selectedServiceGroupId:'',
      selectedType:'ExpectedTime',
      selectedTypeId:'',
      selectedTime:'minutes',
      selectedTimeId:'',
      selectedPlanType:'Choose',
      selectedPlanTypeId:'',
      selectedParent:'Choose',
      selectedParentId:'',
      serviceGroups:[],
      parents:[],
      cities:[],
      addParent:false,
      addParentFlag:true,
      chooseParentFlag:true,
      parentEnName:'',
      parentArName:'',
      selectedCityArr:[],
      qunatityArr:[""],
      toolsPriceArr:[""],
      complexity:'',
      hour:'',
      day:'',
      expectedTime:[
        {id :1,type:'Day'},
        {id :2,type:'Hour'}
      ],
      times:[
        {id:1 , time:15},
        {id:2 , time:30},
        {id:3 , time:45},
      ],
      planTypes:[
        {id:1, enName:'complexity'},
        {id:2, enName:'meter and quantity'},
      ],
      meterAndQuantityNumber:1,
      applyForAll:false,
    };
  }

  componentDidMount() {
    this.getCities();
  }

  getServideGroups(){
    Services.getServiceGroupsDependOnCategories(this.state.selectedCategoryId,(res)=>{
      this.setState({serviceGroups:res.data.serviceGroups,selectedServiceGroup:'ServiceGroup',selectedServiceGroupId:''})
    })
  }

  getCities(){
    Services.getCities((res)=>{
      this.setState({cities:res.data.cities})
    })
  }

  getAllParents(){
    Services.getAllParents(this.state.selectedCategoryId,(res)=>{
      let parentsArr = []
      parentsArr.push({id:'', enName:'Choose'})
      for (let i = 0; i < res.data.prcingItems.length; i++) {
        parentsArr.push(res.data.prcingItems[i])
      }
      this.setState({parents:parentsArr,selectedParent:'Choose',selectedParentId:''})
    })
  }


  handleChangeInputs(e,type){
    const re = /^[0-9\b]+$/;
    if (type == 'arName') {
      this.setState({arName : e.target.value})
    }else if(type == 'enName'){
      this.setState({enName : e.target.value})
    }else if(type == 'hour'){
      if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({hour: e.target.value})
       }
    }else if(type == 'day'){
      if (e.target.value === '' || re.test(e.target.value)) {
        this.setState({day: e.target.value})
     }
    }else if (type == 'parentEnName') {
      this.setState({parentEnName:e.target.value})
    }else if (type == 'parentArName') {
      this.setState({parentArName:e.target.value})
    }else if (type == 'complexity') {
      if (e.target.value === '' || !isNaN(e.target.value) ) {
        this.setState({complexity:e.target.value})
      }
    }
  }

  handleQuantityArr(index,value){
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value) ){
      this.state.qunatityArr[index] = value ;
      let newArr = this.state.qunatityArr;
      this.setState({qunatityArr:newArr});
    }
  }
  handleToolsPriceyArr(index,value){
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value) ){
      this.state.toolsPriceArr[index] = value ;
      let newArr = this.state.toolsPriceArr;
      this.setState({toolsPriceArr:newArr});
    }
  }

  handlerCategory =(item)=>{
    if (item.enName !== 'Choose') {
      this.setState({selectedCategory:item.enName,selectedCategoryId:item.id},()=>{this.getServideGroups();this.getAllParents()})
    }else{
      this.setState({selectedServiceGroup:'ServiceGroup',selectedServiceGroupId:'',serviceGroups:[],parents:[],selectedParent:'Choose',selectedParentId:''})
    }
  }

  handleServiceGroups(item){
    this.setState({selectedServiceGroup:item.enName,selectedServiceGroupId:item.id})
  }

  handleType(item){
    this.setState({selectedType:item.type,selectedTypeId:item.id,hour:'',day:'',selectedTime:'minutes'})
  }
  handleTimes(item){
    this.setState({selectedTime:item.time,selectedTimeId:item.id})
  }
  handelSelectParent(item){
    let parentFlag = false;
    parentFlag= (item.enName=='Choose' && item.id =='');
    this.setState({selectedParent:item.enName,selectedParentId:item.id,addParentFlag:parentFlag})
  }
  handlePlanType(item){
    this.setState({selectedPlanType:item.enName,selectedPlanTypeId:item.id,meterAndQuantityNumber:1,qunatityArr:[""],selectedCityArr:[],complexity:''})
  }
  handleCity(index,item){
    let memento = this.state.selectedCityArr[index];
    let cities = this.state.cities.filter((city)=> city != item)
    if (memento) {
      cities.push(memento)
    }
    this.state.selectedCityArr[index] = item;
    let newArr = this.state.selectedCityArr
    this.setState({selectedCityArr:newArr,cities:cities})
  }
  addNewRow(){
    if (this.validateMeterAndQuantity()) {
      let fixingArr = this.state.qunatityArr;
      let toolsArr = this.state.toolsPriceArr
      fixingArr.push("")
      toolsArr.push("")
      this.setState({qunatityArr:fixingArr,toolsPriceArr:toolsArr},()=>this.setState({meterAndQuantityNumber:this.state.meterAndQuantityNumber+1}))
    }
  }
  validateData(){
    if (this.state.arName == '' || this.state.enName == '') {
      alert('please, names fields can\'t be blank')
      return false
    }
    if (this.state.selectedCategoryId == '') {
      alert('please, choose Category first')
      return false
    }
    if (this.state.selectedType == 'ExpectedTime' ) {
      alert('please, enter a valid expected Time')
      return false
    }
    if (this.state.selectedType == 'Day' && (this.state.day == '')) {
      alert('please, enter a valid day number')
      return false
    }
    if (this.state.selectedType == 'Hour' && (this.state.hour == '' && this.state.selectedTime == 'minutes')) {
      alert('please, enter a valid Hour and minutes ')
      return false
    }
    if (this.state.addParent == true  ) {
      if (this.state.parentArName == '' || this.state.parentEnName == '') {
        alert('please,fill parent names fields first')
        return false
      }
    }
    if (this.state.selectedPlanTypeId == '') {
      alert('please, choose pricing type first')
      return false
    }
    if (this.state.selectedPlanTypeId == 1 && this.state.complexity == '') {
      alert('please, add comlexity value')
      return false
    }
    if (this.state.selectedPlanTypeId == 2 ) {
      if (this.state.applyForAll) {
        return this.validateForAllCities()
      }else{
        return this.validateMeterAndQuantity()
      }
    }
    return true
  }
  validateForAllCities(){
    if (! this.state.qunatityArr[0]) {
      alert(`please , add fixing price for all cities `)
      return false
    }
    if (! this.state.toolsPriceArr[0]) {
      alert(`please , add tools price for all cities `)
      return false
    }
    return true
  }
  validateMeterAndQuantity(){
    for (let i = 0; i < this.state.meterAndQuantityNumber; i++) {
      if (this.state.selectedCityArr[i] == null) {
        alert(`please , choose city in the field of ${i+1}'s position `)
        return false
      }
      if (! this.state.qunatityArr[i]) {
        alert(`please , add fixing price in the field of ${i+1}'s position `)
        return false
      }
      if (! this.state.toolsPriceArr[i]) {
        alert(`please , add tools price in the field of ${i+1}'s position `)
        return false
      }
    }
    return true
  }
  setMeterAndQuantityForAllCities(){
    let meterAndQuantity=[];
    if (this.state.selectedCityArr[0]) {
      meterAndQuantity.push({
        cityId:this.state.selectedCityArr[0].id,
        fixingPrice:this.state.qunatityArr[0],
        toolsPrice:this.state.toolsPriceArr[0],
      })
    }
    for (let i = 0; i < this.state.cities.length; i++) {
      let obj = {
        cityId : this.state.cities[i].id,
        fixingPrice : this.state.qunatityArr[0],
        toolsPrice : this.state.toolsPriceArr[0],
      }
      meterAndQuantity.push(obj);
    }
    return meterAndQuantity;
  }
  setMeterAndQuantity(){
    let meterAndQuantity=[];
    for (let i = 0; i < this.state.meterAndQuantityNumber; i++) {
      let obj = {
        cityId : this.state.selectedCityArr[i].id,
        fixingPrice : this.state.qunatityArr[i],
        toolsPrice : this.state.toolsPriceArr[i],
      }
      meterAndQuantity.push(obj);
    }
    return meterAndQuantity;
  }
  submit(){
    let timeInMin = 0;
    if (this.state.selectedType == 'Day') {
      timeInMin = (this.state.day * 24 * 60 );
    }else if (this.state.selectedType == 'Hour') {
      timeInMin = (this.state.hour * 60);
      if (this.state.selectedTime != 'minutes') {
        timeInMin += this.state.selectedTime;
      }
    }
    let meterAndQuantity = [];
    if (this.state.selectedPlanTypeId == 2) {
      meterAndQuantity = (this.state.applyForAll) ? this.setMeterAndQuantityForAllCities() : this.setMeterAndQuantity() ;
    }
    let data = {
      enName:this.state.enName,
      arName:this.state.arName,
      parentId:this.state.selectedParentId,
      parentArName:this.state.parentArName,
      parentEnName:this.state.parentEnName,
      pricingModelId:this.state.selectedPlanTypeId==1?1:4,
      itemType:this.state.selectedPlanTypeId==1?'complexity':'meter_and_quantity',
      categoryId:this.state.selectedCategoryId,
      serviceGroupId:this.state.selectedServiceGroupId,
      meterAndQuantity : meterAndQuantity,
    };
    if (timeInMin > 0) {
      data.expectedTime = timeInMin;
    }
    if (this.state.selectedPlanTypeId == 1) {
      data.complexity = this.state.complexity;
    }
    console.log(data);
    // return
    this.setState({loading:true})
    Services.addPriceItem(data,(res)=>{
      this.setState({loading:false})
      this.props.closeHandler()
    })


  }
  addItem(){
    if (this.validateData()) {
      this.submit()
    }
  }


  render() {
    return (
      <>
      <div style={{paddingLeft:20}}>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Ar Name:</Label>
          </Col>
          <Col md="4">
              <Input
                value={this.state.arName}
                onChange={(e)=>this.handleChangeInputs(e,'arName')}
                type="text"
                id="arName"
                name="arName"
                placeholder="arName.."
                maxLength={150}
                onKeyPress={this.handleKeyPress}
              />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>En Name:</Label>
          </Col>
          <Col md="4">
              <Input
                value={this.state.enName}
                onChange={(e)=>this.handleChangeInputs(e,'enName')}
                type="text"
                id="enName"
                name="enName"
                placeholder="enName.."
                maxLength={150}
                onKeyPress={this.handleKeyPress}
              />
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Category:</Label>
          </Col>
          <Col md="4">
              <CategoryFilter className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}} marginLeft={0} handler={this.handlerCategory}/>
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Service Groups:</Label>
          </Col>
          <Col md="4">
          <UncontrolledDropdown  className="mb-3">
                    <DropdownToggle caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}><i className='fas fa-list' style={{marginRight:10}} />{this.state.selectedServiceGroup}</DropdownToggle>
                    <DropdownMenu style={{width:'100%'}}>
                    {this.state.serviceGroups.map((item, index)=>(
                        <DropdownItem key={index} onClick={()=>this.handleServiceGroups(item)}>{item.enName}</DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
          </Col>
        </Row>
        <Row style={{marginTop:15}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Expected Time:</Label>
          </Col>
          <Col md="4">
          <UncontrolledDropdown  className="mb-3">
                    <DropdownToggle caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}><i className='fas fa-clock' style={{marginRight:10}} />{this.state.selectedType}</DropdownToggle>
                    <DropdownMenu style={{width:'100%'}}>
                    {this.state.expectedTime.map((item, index)=>(
                        <DropdownItem key={index} onClick={()=>this.handleType(item)}>{item.type}</DropdownItem>
                        ))}
                    </DropdownMenu>
          </UncontrolledDropdown>
          </Col>
          {this.state.selectedTypeId == 1 ? (
            <Col md="6">
            <Input
            style={{width:'100%'}}
            value={this.state.day}
            onChange={(e)=>this.handleChangeInputs(e,'day')}
            type="text"
            id="day"
            name="day"
            placeholder="day.."
            maxLength={150}
            onKeyPress={this.handleKeyPress}
          />
          </Col>
          ) :this.state.selectedTypeId == 2 ? (
                <Col md="6">
            <Input
            style={{width:'100%'}}
            value={this.state.hour}
            onChange={(e)=>this.handleChangeInputs(e,'hour')}
            type="text"
            id="hour"
            name="hour"
            placeholder="hour.."
            maxLength={150}
            onKeyPress={this.handleKeyPress}
          />
                </Col>
          ):null}
        </Row>
        {this.state.selectedTypeId == 2 ? (
            <Row style={{marginTop:15}}>
            <Col md="2">
            <Label style={{fontWeight:'bold'}}>Minutes:</Label>
            </Col>
            <Col md="4">
              <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}><i className='fas fa-clock' style={{marginRight:10}} />{this.state.selectedTime} mins</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                  {this.state.times.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleTimes(item)}>{item.time} mins</DropdownItem>
                      ))}
                  </DropdownMenu>
              </UncontrolledDropdown>
              </Col>
            </Row>
          ):null}
          <Row>
            <Col md="2" >
              <Label style={{fontWeight:'bold'}} >Choose Parent :</Label>
            </Col>
            <Col md="4">
              <UncontrolledDropdown className="mb-3">
                  <DropdownToggle disabled={!this.state.chooseParentFlag} caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}>{this.state.selectedParent} </DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                  {this.state.parents.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handelSelectParent(item)}>{item.enName}</DropdownItem>
                      ))}
                  </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col md="4">
              <Button className="actionsBtn" disabled={!this.state.addParentFlag} onClick={()=>this.setState({addParent:true,chooseParentFlag:false})} >Add Parent</Button>
            </Col>
          </Row>
          {this.state.addParent?(
            <>
            <Row style={{marginTop:15}}>
              <Col md="2">
                <Label style={{fontWeight:'bold'}}>Parent Ar Name:</Label>
              </Col>
              <Col md="4">
                  <Input
                    value={this.state.parentArName}
                    onChange={(e)=>this.handleChangeInputs(e,'parentArName')}
                    type="text"
                    id="arName"
                    name="parentArName"
                    placeholder="Parent arName.."
                    maxLength={150}
                    onKeyPress={this.handleKeyPress}
                  />
              </Col>
            </Row>
            <Row style={{marginTop:15}}>
              <Col md="2">
                <Label style={{fontWeight:'bold'}}>Parent En Name:</Label>
              </Col>
              <Col md="4">
                  <Input
                    value={this.state.parentEnName}
                    onChange={(e)=>this.handleChangeInputs(e,'parentEnName')}
                    type="text"
                    id="enName"
                    name="parentArName"
                    placeholder="Parent enName.."
                    maxLength={150}
                    onKeyPress={this.handleKeyPress}
                  />
              </Col>
            </Row>
          </>
          ):null}
          <Row style={{marginTop:15}}>
            <Col md="2" >
              <Label style={{fontWeight:'bold'}} >Choose Type :</Label>
            </Col>
            <Col md="4">
              <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}>{this.state.selectedPlanType} </DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                  {this.state.planTypes.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handlePlanType(item)}>{item.enName}</DropdownItem>
                      ))}
                  </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          {this.state.selectedPlanType == 'meter and quantity'?(
            <>{
              [...Array(this.state.meterAndQuantityNumber)].map( (val,i) => (
                  <Row style={{marginTop:15}} key={i}>
                    <Col md="10">
                      <Row>
                        <Col md="2" >
                          <Label style={{fontWeight:'bold',marginTop:'5px'}} >Choose City :</Label>
                        </Col>
                        <Col md="2">
                          <UncontrolledDropdown  className="mb-3">
                              <DropdownToggle disabled={this.state.applyForAll} caret className='actionsBtnWithoutHover' style={{width:'100%',backgroundColor:'white',borderWidth:1,borderColor:'#dddddd'}}>{ (!this.state.applyForAll) && this.state.selectedCityArr[i]?this.state.selectedCityArr[i].enName:'Choose'} </DropdownToggle>
                              <DropdownMenu style={{width:'100%'}}>
                              {this.state.cities.map((item, index)=>(
                                  <DropdownItem key={index} onClick={()=>this.handleCity(i,item)}>{item.enName}</DropdownItem>
                                  ))}
                              </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                        <Col md="1" >
                          <Label style={{fontWeight:'bold',marginTop:'5px'}} >Fix</Label>
                        </Col>
                        <Col md="2" style={{right:'20px'}}>
                          <Input value={this.state.qunatityArr[i]}
                                onChange={(e)=>this.handleQuantityArr(i,e.target.value)}
                                type="text"
                                placeholder="Fixing Price .."
                                maxLength={10}
                              />
                        </Col>
                        <Col md="1" style={{marginTop:'5px',right:'20px'}} >
                              <strong>L.E</strong>
                        </Col>
                        <Col md="1" >
                          <Label style={{fontWeight:'bold',marginTop:'5px'}} >Tools</Label>
                        </Col>
                        <Col md="2" style={{right:'20px'}}>
                          <Input value={this.state.toolsPriceArr[i]}
                                onChange={(e)=>this.handleToolsPriceyArr(i,e.target.value)}
                                type="text"
                                placeholder="Tools Price .."
                                maxLength={10}
                              />
                        </Col>
                        <Col md="1" style={{marginTop:'5px',right:'20px'}} >
                              <strong>L.E</strong>
                        </Col>
                      </Row>
                    </Col>

                    {this.state.meterAndQuantityNumber==1?(
                    <Col md="2" style={{marginTop:'5px'}} >
                      <Input type="checkbox" id="check-all" onClick={()=>this.setState({applyForAll:!this.state.applyForAll})} /> <label htmlFor="check-all">Apply For All</label>
                    </Col>
                    ):null}
                  </Row>
                  ))
            }
            <Button disabled={this.state.applyForAll || !this.state.cities.length } className='actionsBtn' onClick={()=>this.addNewRow()}>Add Quantity</Button>
            </>
          ):null}
          {this.state.selectedPlanType == 'complexity'?
          <Row style={{marginTop:15}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Complexity :</Label>
            </Col>
            <Col md="4">
              <Input
                value={this.state.complexity}
                onChange={(e)=>this.handleChangeInputs(e,'complexity')}
                type="text"
                placeholder="Complexity .."
                maxLength={150}
              />
            </Col>
          </Row>
          :null}
      </div>
      <LoadingSpinner loading={this.state.loading} />
      </>
    );
  }

}
