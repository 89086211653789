import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label,Input,InputGroup ,Table} from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import Switch from "react-switch";

export default class RoleDetails extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      
    }

  }
  componentDidMount(){
    
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
        <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Description</th>
          </tr>
        </thead>
        <tbody>
            <tr>
            <td style={{textAlign:'center'}}>{this.props.details}</td>
            </tr>
        </tbody>
      </Table>   
        <LoadingSpinner loading={this.state.loading}/>
      </div>
      )
    }
  }
