import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class OrdersService {

  static payOrder(id, data, callBack) {
    Adaptor.post(`${Global.baseURL}admin-panel/orders/${id}/pay`, data).then(callBack)
  }

  static getCompanies(callBack) {
    Adaptor.get(`${Global.baseURL}admin-panel/companies`).then(callBack)
  }

}
