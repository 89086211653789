import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import {Input,InputGroup} from 'reactstrap'
import CustomersServices from '../../../services/customerServices/CustomersServices'
import Services from './../../../services/Services'
import LoadingSpinner from '../../LoadingSpinner'
class AddCustomerModal extends React.Component{
  constructor(props){
    super(props)
    this.state={
      name:'',
      arName:'',
      phone:'',
      email:'',
      gender:'',
      loading:false,
    }
  }
  update(input,e){
    switch (input) {
      case 'name':
        this.setState({name:e.target.value})
        break;

      case 'arName':
        const arregex = /[\u0600-\u06FF]/;
        if (arregex.test(e.target.value) || e.target.value == '') {
          this.setState({arName:e.target.value})
        }
        break;

      case 'phone':
        const re = /^[0-9\b]+$/,aRre = /[\u0660-\u0669]/;
        if(re.test(e.target.value) || e.target.value == ''){
          this.setState({phone:e.target.value})
        }else if (aRre.test(e.target.value)){
          alert('please enter phone number in english ')
        }
        break;

      case 'email':
        this.setState({email:e.target.value})
        break;

      case 'gender':
        this.setState({gender:e})
        break;

      default:
        break;
    }
  }
  clear(){
    this.setState({name:'',arName:'',phone:'',email:'',gender:''})
  }
  closeHandler(){
    this.clear()
    this.props.closeHandler()
  }
  validateName(){
    if (this.state.name != '') return true
    alert('Name can\'t be blank ')
    return false
  }
  validatePhone(){
    if (this.state.phone != '' &&  this.state.phone.length == 11 && this.state.phone.startsWith("01")) return true
    alert('Phone must be valid phone number')
    return false
  }
  validateEmail() {
    let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(this.state.email == '' || re.test(this.state.email)) return true
    alert('Please enter valid e-mail address ex.: user@example.com')
    return false
  }
  validateGender(){
    if (this.state.gender == 'male' || this.state.gender == 'female') return true
    alert('Please choose gender first')
    return false
  }
  valideInputs(){
    return (this.validateName()&&this.validatePhone()&&this.validateEmail()&&this.validateGender());
  }

  emailIsExist(){
    console.log('emailIsExist');

  }
  addCustomer(){
    if(this.valideInputs()){
      let customer={
        name:this.state.name,
        arName:this.state.arName,
        email:this.state.email,
        phone:this.state.phone,
        gender:this.state.gender
      }
      this.createNewCustomer(customer)
    }
  }
  createNewCustomer(customer){
    this.props.loadingHandler(true)

    CustomersServices.emailIsExist({email:this.state.email,userTypeId:1},(res)=>{
      if (res.message == 'email_taken') {
        this.props.loadingHandler(false)
        alert('This email already registered')
      }else{
        CustomersServices.create(customer,(res)=>{
          if (res.message == 'user_registered_before') {
            this.props.loadingHandler(false)
            this.setState({loading:false,phone:''},()=>alert('This phone already registered'))
          }else{
            this.props.loadingHandler(false)
            this.setState({loading:false},()=>this.closeHandler())
          }
        })
      }
    })
  }
  render(){
    return (
      <Modal fade={false}
              isOpen={this.props.show}
              toggle={()=>this.closeHandler()}
              >
        <ModalHeader toggle={()=>this.closeHandler()}>
          Add Customer
        </ModalHeader>
        <ModalBody>
        <div style={{padding:'15px',width:'50%'}}>
          <label style={{textAlign:'center'}}><strong>Enter Name</strong></label>
          <InputGroup style={{marginBottom:'20px'}}>
            <Input style={{padding:'20px'}} maxLength='150' placeholder='Enter Name ...' value={this.state.name} onChange={(e)=>this.update('name',e)}/>
          </InputGroup>
        </div>
        <div style={{padding:'15px',width:'50%'}}>
          <label style={{textAlign:'center'}}><strong>Enter Arabic Name</strong></label>
          <InputGroup style={{marginBottom:'20px'}}>
            <Input style={{padding:'20px'}} maxLength='150' placeholder='Enter Arabic Name ...' value={this.state.arName} onChange={(e)=>this.update('arName',e)}/>
          </InputGroup>
        </div>
        <div style={{padding:'15px',width:'50%'}}>
          <label style={{textAlign:'center'}}><strong>Enter Phone</strong></label>
          <InputGroup style={{marginBottom:'20px'}}>
            <Input style={{padding:'20px'}} maxLength='11' placeholder='Enter Phone ...' value={this.state.phone} onChange={(e)=>this.update('phone',e)}/>
          </InputGroup>
        </div>
        <div style={{padding:'15px',width:'50%'}}>
          <label style={{textAlign:'center'}}><strong>Enter E-Mail</strong></label>
          <InputGroup style={{marginBottom:'20px'}}>
            <Input style={{padding:'20px'}} maxLength='120' placeholder='Enter E-Mail ...' value={this.state.email} onChange={(e)=>this.update('email',e)}/>
          </InputGroup>
        </div>
        <div style={{padding:'15px'}}>
            <Row>
              <Col md='6'>
                <label style={{textAlign:'center'}}><strong>Choose Gender</strong></label>
              </Col>
            </Row>
            <Row>
            <Col md="3">
              <div className='toggleCheckbox' onClick={()=>this.update('gender','male')}>
                  <i className={this.state.gender=='male'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                  <span>Male</span>
              </div>
            </Col>
            <Col md="3">
              <div className='toggleCheckbox' onClick={()=>this.update('gender','female')}>
                  <i className={this.state.gender=='female'?'fa fa-check-circle':'fa fa-circle-thin'}/>
                  <span>Fe-Male</span>
              </div>
            </Col>
          </Row>
        </div>
        </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn' onClick={()=>this.closeHandler()}>Close</Button>
          <Button className='actionsBtn' onClick={()=>this.addCustomer()}>Add</Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default AddCustomerModal;
