import React from 'react'
import { DropdownToggle, UncontrolledDropdown, DropdownItem, DropdownMenu, Row, Col, Label,Input,InputGroup ,Table} from 'reactstrap'
import { Keywords, PromotedServiceGroup, General, PromoCode, Offer, LoadingSpinner ,FeaturedServiceGroup} from './../../components'
import Services from './../../services/Services'
import {Global} from './../../core/Global'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import Switch from "react-switch";
import PermissionsService from './../../services/permissions/PermissionsService'

export default class AssignRoles extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      loading:false,
      selectedRolesId:'',
      role:[],
      roles:[]
    }

  }
  componentDidMount(){
    console.log(this.props.roles)
    let arr =[]
    let selectedItems = this.props.selectedRoles

    for (var i = 0; i < selectedItems.length; i++){
      arr.push(selectedItems[i].id)
    }
    this.setState({role:selectedItems,selectedRolesId:[]})
    this.getRoles()
  }

  assignRolesToAgents(){
    this.setState({loading:true})
    let data = {
      roles:this.state.selectedRolesId
    }
    console.log(data)
    PermissionsService.assignRolesToAgents(this.props.userId,data,(res)=>{
      this.setState({loading:false},()=>this.props.update())
    })
  }

  getRoles(){
    this.setState({loading:true})
    PermissionsService.getRoles((res)=>{
      let arr = []
      for (let i = 0; i < res.data.length; i++) {
        let obj = {label:res.data[i].displayName,id:res.data[i].id}
        arr.push(obj)
      }
      console.log(arr)
      this.setState({roles:arr,loading:false})
    })
  }



  onChangeRoles(itemSelected){
    let arr = []
    for (var i = 0; i < itemSelected.length; i++) {
      arr.push(itemSelected[i].id)
    }
    console.log(arr)
    this.setState({role : itemSelected,selectedRolesId:arr})
  }

  render(){
    return(
      <div style={{marginLeft:20}}>
        <Row>
          <Col md="3"><p>Roles{' '}: </p></Col>
          <Col md="6">
            <MultiSelect
              items={this.state.roles}
              selectedItems={this.state.role}
              onChange={(item)=>this.onChangeRoles(item)}
              withGrouping={true}
              showSelectedItems={false}
            />
          </Col>
        </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
