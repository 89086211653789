import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader,ModalFooter ,Dropdown,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown,Alert} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'


class Trello extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentModal:'',
      loading:false,
      modalAdmin:false
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.getArea() //check if is login
  }
  getArea(){
    // this.setState({loading:true})
    Services.getArea(1, (res)=>{
      this.setState({loading:false})
    })
  }


  handleConfirmationModal(item){
    if (item != '') {
      this.setState({modalAdmin: !this.state.modalAdmin, currentModal:item})
    }else {
      this.setState({modalAdmin: !this.state.modalAdmin,currentModal:''})
    }
  }

  sync(){
    this.setState({loading:true})
    Services.synchronize(this.state.currentModal, (res)=>{
      if (res.message == 'admin_exists') {
        alert('phone is exist')
      }else if (res.message == 'trello_member_not_found') {
        alert('email doesn\'t related to trello account')
      }else if (res.message == 'success') {
        this.setState({loading:false},()=>this.handleConfirmationModal(''))
      }else {
        alert('email doesn\'t related to trello account')
      }
      this.setState({loading:false})
    })

  }



  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <div>
            <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.handleConfirmationModal('Orders')}><i className='fa fa-sync' style={{marginRight:8}}/>Synchronize Orders</Button>
            <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.handleConfirmationModal('Complaints')}><i className='fa fa-sync' style={{marginRight:8}}/>Synchronize Complaints</Button>
            <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.handleConfirmationModal('Inquiries')}><i className='fa fa-sync' style={{marginRight:8}}/>Synchronize Inquiries</Button>
          </div>

          <Modal fade={false} isOpen={this.state.modalAdmin} toggle={()=>this.handleConfirmationModal('')} className={this.props.className}>
            <ModalHeader toggle={()=>this.handleConfirmationModal('')}>Add Admin</ModalHeader>
            {this.renderModal()}
            <ModalFooter>
              <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.handleConfirmationModal('')}>Cancel</Button>
              <Button className='actionsBtn' style={{marginBottom:'1%'}} onClick={()=>this.sync()}>Synchronize</Button>
            </ModalFooter>
          </Modal>

          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>
        </Container>
      </div>
    )
  }


  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Admins</span>
          </Col>

          <Col md="4">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.getFixers()} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e)}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By id, name and phone.."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    )
  }




  renderModal(){
    return(
      <ModalBody>
        <h5>Are you sure you want to synchronize Trello board now ?</h5>
      </ModalBody>
    )
  }




}

export default Trello;
