import React from 'react'
import {nav,Row,Pagination,PaginationItem,PaginationLink} from 'reactstrap'
class Paginator extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
        <nav>
            <Row className={this.props.left ?'pull-left':'pull-right'}>
                <Pagination style={{justifyContent:'flex-end'}}>
                    <PaginationItem>
                        <PaginationLink previous tag="button"
                                        onClick={this.props.prevAction}
                                        disabled={this.props.disablePrev}
                                        />
                    </PaginationItem>
                        {this.props.children}
                    <PaginationItem>
                        <PaginationLink next tag="button"
                                            style={{color:'#4dbd74',}}
                                            onClick={this.props.nextAction}
                                            disabled={this.props.disableNext}
                                            />
                    </PaginationItem>
                </Pagination>
            </Row>
        </nav>
        )
    }
}
export default Paginator;