import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './views/login/Login'
import NetworkDetector from './core/NetworkDetector'
import CustomerChat from './views/chats/CustomerChat/CustomerChat'
import FixerChat from './views/chats/FixerChat/FixerChat'
import swiftly from './assets/swiftly.mp3';
import DefaultLayout from './views/containers/DefaultLayout'
import { connectToSocket } from './core/helper'
import SimpleReactLightbox from "simple-react-lightbox"
import './core/Interceptor'
import './App.scss'

class App extends React.PureComponent {
  constructor(props){
    super(props);
    window.$unSeenCustomerMsgs = 0;
    this.playSoundOnNewMessage = this.playSoundOnNewMessage.bind(this);
    this.messageNotification = new Audio(swiftly);
  }

  componentDidMount(){
    let isLogged = localStorage.getItem('logged');
    if (isLogged === 'home') {
      connectToSocket();
      window.socket.on('chatMessage',this.playSoundOnNewMessage)
    }
    window.isForbidden = () => { console.log(20) }
  }

  componentWillUnmount(){
    window.socket.removeEventListener('chatMessage',this.playSoundOnNewMessage)
  }



  playSoundOnNewMessage(message){
    if (message.sender.userTypeId != 3 ) {
      this.messageNotification.play()
    }
  }


  render() {
    return (
      <SimpleReactLightbox>
        <BrowserRouter>
          <React.Suspense>
            <Switch>
              <Route path="/chat/customer" name="Chat" render={props => <CustomerChat {...props}/>} />
              <Route path="/chat/fixer" name="Chat" render={props => <FixerChat {...props}/>} />
              <Route path="/login" name="login" render={props => <Login {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </SimpleReactLightbox>
    )
  }
}

export default App;
