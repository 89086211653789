import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import CommunicationChannelService from '../../../services/orderServices/CommunicationChannelService'

export default class DisplayCommunicationChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
    channel:this.props.order.communicationChannel.channel,
    }
  }

  componentDidMount(){

  }

  validChannel(){
    return (this.state.channel=='push-notification' || this.state.channel == 'sms')
  }


  communicationChannel(){
    if(this.validChannel()){
      this.setState({loading:true})
      CommunicationChannelService.updateChannel(this.props.order.orderId,this.state.channel,(res)=>{
        if (res.message == 'success') {
          this.setState({loading:false,displayCommunicationChannelModal:false,channel:this.props.order.communicationChannel.channel},()=>this.props.reload())
        }
      })
    }else{
      alert('please, choose valid channel')
    }
  }

  changeCommunicationChannel(key){
    this.setState({channel:key})
  }




  render(){
    return(
      <div>
        <div style={{paddingLeft:'20px'}}>
          <Row>
            <Col md="3"><p>Choose Channel : </p></Col>
            <Col md="4">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.channel=='push-notification'?'Push Notification':'SMS'}</DropdownToggle>
                <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                  <DropdownItem key="1" onClick={()=>this.changeCommunicationChannel('push-notification')}>Push Notification</DropdownItem>
                  <DropdownItem key="2" onClick={()=>this.changeCommunicationChannel('sms')}>SMS</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
