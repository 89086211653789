import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import AreaFilter from '../../components/Filters/AreaFilter';
import CategoryFilter from '../../components/Filters/CategoryFilter';
import CityFilter from '../../components/Filters/CityFilter'
import ServiceGroupFilter from '../../components/Filters/ServiceGroupFilter';
import LoadingSpinner from '../../components/LoadingSpinner';
import DatePicker from 'react-datepicker'
import { Global } from '../../core/Global';
import Services from '../../services/Services';
import { StatusFilter } from '../../components';
var Highcharts = require('highcharts')
require('highcharts/modules/exporting')(Highcharts)

class ReceivedOrdersPerMonth extends React.Component
{
    constructor(props){
        super(props);
        this.state={
            loading:false,
            city:null,
            area:null,
            category:null,
            status:null,
            serviceGroup:null,
            startDate:null,
            startMonth:null,
            startYear:null,
            endDate:null,
            endMonth:null,
            endYear:null,
            data:{
                chart: {type: 'column'},
                title: {text: 'Received Orders Per Month'},
                xAxis: {
                  type: 'category',
                  labels: {
                    rotation: -45,
                    style: {fontSize: '13px',fontFamily: 'Verdana, sans-serif'}
                  }
                },
                yAxis: {
                  min: 0,
                  title: {text: 'Numbers of Orders'},
                  tickInterval:1,
                },
                legend: {enabled: false},
                tooltip: {pointFormat: 'Received Orders Per Month: <b>{point.y:.1f}</b>'},
                series: [{
                  name: 'orders',
                  data: [],
                  turboThreshold:1000000,
                }]

            }
        }
    }
    componentDidMount(){
        this.filter()
    }
    enableLoading(){
        this.setState({loading:true})
    }

    disableLoading(){
        this.setState({loading:false})
    }
    handleChangeCity(city){
        this.areaRef.clear()
        if (city.id != 'choose') {
            this.setState({city:city},()=>this.areaRef.getAreas(this,city.id))
        }
    }
    setDate(key,date){
        let month =  new Date(date).getMonth() + 1;
        let year =  new Date(date).getFullYear();
        switch (key) {
            case 'endDate':
                this.setState({endDate:date,endMonth:month,endYear:year})
                break;
            case 'startDate':
                this.setState({startDate:date,startMonth:month,startYear:year})
                break;

            default:
                break;
        }
    }
    filter(){
        let api = `${Global.baseURL}admin-panel/reports/orders/received-per-month?`;
        if (this.state.startDate != null) {
            api += `fromMonth=${this.state.startMonth}&fromYear=${this.state.startYear}&`;
        }
        if (this.state.endDate != null) {
            api += `toMonth=${this.state.endMonth}&toYear=${this.state.endYear}&`;
        }

        if (this.state.city != null && this.state.city.id != 'choose' && this.state.city.id != 'all' ) {
            api += `cities[0]=${this.state.city.id}&`;
        }

        if (this.state.area != null && this.state.area.id != 'choose' && this.state.area.id != 'all' ) {
            api += `areas[0]=${this.state.area.id}&`;
        }

        if (this.state.category != null && this.state.category.id != 'choose' && this.state.category.id != 'all' ) {
            api += `categories[0]=${this.state.category.id}&`;
        }

        if (this.state.serviceGroup != null && this.state.serviceGroup.id != 'choose' && this.state.serviceGroup.id != 'all' ) {
            api += `serviceGroups[0]=${this.state.serviceGroup.id}&`;
        }

        if (this.state.status != null && this.state.status.id != 'choose' && this.state.status.id != 'all' ) {
            api += `statuses[0]=${this.state.status.id}&`;
        }
        this.getReport(api)

    }

    getReport(api){
      this.setState({loading:true})
      Services.getReport(api, (res)=>{
        let arr = []
        for (var i = 0; i < res.data.length; i++) {
          let singleArr = []
          let date = res.data[i].month+'/'+res.data[i].year
          singleArr.push(date)
          singleArr.push(parseInt(res.data[i].count))
          arr.push(singleArr)
        }
        let newData = this.state.data
        newData.series[0].data = arr
        // newData.tooltip.pointFormat = `${this.state.pageTitle}: <b>{point.y:.1f}</b>`
        this.setState({loading:false, data:newData, noResult:res.data.length!=0?false:true},()=> {
          if (!this.state.noResult) {
            Highcharts.chart('container', this.state.data)
          }
        })
      })
    }

    resetFilter(){
        this.cityRef.clear()
        this.areaRef.clear()
        this.categoryRef.clear()
        this.serviceGroupRef.clear()
        this.statusRef.clear()
        this.setState({
            city:null,
            area:null,
            category:null,
            serviceGroup:null,
            status:null,
            startDate:null,
            startMonth:null,
            startYear:null,
            endDate:null,
            endMonth:null,
            endYear:null,
        },()=>this.filter())
    }

    render(){
        return(
        <div className="app align-items-center">
            <Container>
                <Card>
                    <CardHeader className='shadow' style={{backgroundColor:'#4dbd74',borderWidth:0}}>
                    <i className="fa fa-align-justify" style={{fontSize:20}}></i><strong style={{fontSize:20}}>Received Orders Per Month Report</strong>
                    </CardHeader>
                </Card>
                <Row>
                    <Col md='3'>
                        <CityFilter ref={(ref)=>this.cityRef = ref} handler={(city)=>this.handleChangeCity(city)} />
                    </Col>
                    <Col md='3'>
                        <AreaFilter disabled={!this.state.city} ref={(ref)=>this.areaRef = ref} handler={(area)=>this.setState({area:area})} />
                    </Col>
                    <Col md='3'>
                        <CategoryFilter ref={(ref)=>this.categoryRef = ref} handler={(category)=>this.setState({category:category})} />
                    </Col>
                    <Col md='3'>
                        <ServiceGroupFilter ref={(ref) => this.serviceGroupRef = ref} handler={(serviceGroup) => this.setState({serviceGroup:serviceGroup})} />
                    </Col>
                </Row>
                <Row>
                    <Col md='3'>
                        <StatusFilter ref={(ref)=>this.statusRef = ref } type='order' onStatusChange={(status)=>this.setState({status:status})} />
                    </Col>
                    <Col md='3'>
                        <div className='date'>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={date => this.setDate('startDate',date)}
                                selectsStart
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                dateFormat="MM/yyyy"
                                placeholderText={'Start Date'}
                                showMonthYearPicker
                            />
                        </div>
                    </Col>
                    <Col md='3'>
                        <div className='date' >
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={date => this.setDate('endDate',date)}
                                selectsEnd
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                dateFormat="MM/yyyy"
                                placeholderText={'End Date'}
                                showMonthYearPicker
                            />
                        </div>

                    </Col>
                    <Col md='1'>
                        <Button onClick={()=>this.filter()} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
                    </Col>
                    <Col md='1'>
                        <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff'}}>Reset</Button>
                    </Col>
                </Row>
                <Card>
                    <CardBody className='shadow'>
                        <div id="container"></div>
                    </CardBody>
                </Card>
            </Container>
            <LoadingSpinner loading={this.state.loading} />
        </div>);
    }
}
export default ReceivedOrdersPerMonth;
