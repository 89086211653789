import React from 'react'
import {CardBody} from 'reactstrap'
function NoResult(props) {
    return (
        <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
            <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
            <h2>There's no result</h2>
        </CardBody>
    )
}
export default NoResult;
