import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Reopen extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount(){

  }


  reOpen(){
    this.setState({loading:true})
    Services.reopenOrder(this.props.order.orderId,  (res)=>{
      this.setState({loading:false})
      if(res.message == 'can_not_reopen_order') {
        alert('Sorry, can\'t re open this order ')
      }else if(res.message == 'forbidden'){
        alert('You Are Not Authorized To Take That Action')
      }else {
        this.setState({mainModal:false, reOpenModal:false},()=>this.props.reload())
      }
    })
  }


  render(){
    return(
      <div>
        <p style={{marginLeft:20}}>Are you sure re-open this order ? </p>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
