import React from 'react';
import {Col,Row,Label,Input} from 'reactstrap'
import Services from './../../services/Services';
import MultiSelect from "@kenshooui/react-multi-select"
import { LoadingSpinner } from './../../components'
import {Global} from './../../core/Global'

let pagination = 1

export default class PromoCode extends React.Component{
  constructor(props){
    super(props)
    this.state={
      promos:[],
      selectedPromo:[],
      arName:'',
      enName:'',
      loading:false,
      promoId:[],
      imagePreview:''
    }
  }

  componentDidMount(){
     pagination = 1
    this.getPromos(pagination)
  }

  getPromos(page) {
    this.setState({ loading: !this.state.loading });
    let api = `${Global.baseURL}admin-panel/promo-codes?expired=0&page=${page}`;
    Services.getPromos(api, res => {
      if (res.data.paginationInfo.lastPage > 1 && pagination != res.data.paginationInfo.lastPage) {
       pagination += 1
       let promos = this.state.promos
       let arr = res.data.promoCodes
       for (var i = 0; i < arr.length; i++) {
         let obj ={label:arr[i].code, id: arr[i].id}
         promos.push(obj)
       }
       promos.push({label:'', id: ''})
       this.setState({promos:promos},()=>this.getPromos(pagination))
     }else {
       let promos = this.state.promos
       let arr = res.data.promoCodes
       for (var i = 0; i < arr.length; i++) {
         let obj ={label:arr[i].code, id: arr[i].id}
         promos.push(obj)
       }
       promos.push({label:'', id: ''})
       this.setState({promos:promos},()=>console.log(' 1 promo ', promos))
     }
     this.setState({loading:false})
    })
  }


  handleChangeInputs(e,type){
    if(type == 'arName'){
      this.setState({arName : e.target.value})
    }else{
      this.setState({enName : e.target.value})
    }
  }

  onSelectedChanged(selectedItems) {
    this.setState({selectedPromo:selectedItems,})
  }

  onSelectImageFromPC = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreview: reader.result
      })
    }
    reader.readAsDataURL(file)
  }

  getData(type){
    if (this.state.arName.trim() == '') {
      alert('Please, enter Arabic title')
    }else if (this.state.enName.trim() == '') {
      alert('Please, enter English title')
    }else if (this.state.selectedPromo.length == 0) {
      alert('Please, select promoCode')
    }else if (!this.state.imageFile) {
      alert('Please, select image')
    }else {
      let fd = new FormData()

      fd.append('type',type)
      fd.append('id',this.state.selectedPromo[0].id)
      fd.append('additionalData[enName]',this.state.enName)
      fd.append('additionalData[arName]',this.state.arName)
      fd.append('additionalData[image]',this.state.imageFile)

      this.props.createPost(fd)
    }
  }



  render(){
    return(
      <div>
        <Row>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Offer Ar : </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.arName}
              onChange={(e)=>this.handleChangeInputs(e, 'arName')}
              type="text"
              id="arName"
              name="arName"
              placeholder="Arabic title.."
              maxLength={150}
              // onKeyPress={this.handleKeyPress}
            />
          </Col>
        </Row>
        <Row style={{marginTop:20}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Offer En : </Label>
          </Col>
          <Col md="4">
            <Input
              value={this.state.enName}
              onChange={(e)=>this.handleChangeInputs(e, 'enName')}
              type="text"
              id="enName"
              name="enName"
              placeholder="English title.."
              maxLength={150}
              // onKeyPress={this.handleKeyPress}
            />
          </Col>
        </Row>
        <Row style={{marginTop:20}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Promo Codes :</Label>
          </Col>
          <Col md="4" style={{height:300}}>
            {this.state.promos.length!=0?(
              <MultiSelect
                items={this.state.promos}
                selectedItems={this.state.selectedPromo}
                onChange={(item)=>this.onSelectedChanged(item)}
                showSelectedItems={false}
                showSelectAll={false}
                maxSelectedItems={1}
                responsiveHeight={300}
              />
            ):null}
          </Col>
        </Row>
        <Row style={{marginTop:30}}>
          <Col md="2">
            <Label style={{fontWeight:'bold'}}>Images :</Label>
          </Col>
          <Col md="4">
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={(e)=>this.onSelectImageFromPC(e)}
            />
          </Col>
          <Col md='6'>
            <p><strong>Note: </strong>when insert image must the width double the length or upload this dimensions 362*138</p>
          </Col>
        </Row>
        {this.state.imagePreview != '' ?(
          <Row style={{marginTop:10}}>
            <Col md="2">
              <Label style={{fontWeight:'bold'}}>Preview: </Label>
            </Col>
            <Col md="4">
              <img src={this.state.imagePreview} style={{height:300, width:'100%'}}/>
            </Col>
          </Row>
        ):null}

        <LoadingSpinner loading={this.state.loading}/>

      </div>
    )
  }
}
