import React from 'react'
import { Button, Col, Row ,Table ,Modal ,ModalBody ,ModalFooter ,ModalHeader } from 'reactstrap';
import Badge from 'reactstrap/lib/Badge';
import { LoadingSpinner } from '../../components';
import Services from '../../services/Services';
import WorkContractService from '../../services/workContractServices/WorkContractService';
import { FModal, WorkContractHistory } from './../../components'

class SingleWorkContract extends React.Component{

  constructor(props){
    super(props);
    this.state={
      loading:false,
      showAreas:false,
      showFinanceDetails:false,
      workContract:null,
    }
  }

  componentDidMount(){
    this.getSingleWorkContract();
  }

  getSingleWorkContract(){
    this.setState({loading:true})
    WorkContractService.getSingleWorkContract(this.props.workContract.id,(res) => {
      if (res.message == 'success') {
        this.setState({
          loading:false,
          workContract:res.data.workContract
        })
      }
    })
  }


  renderFinanceReport(){
    return(
      <div>
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center' }}>Key</th>
              <th style={{textAlign:'center' }}>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Company Percentage</th>

              <th style={{textAlign:'center',width:'40%'}}>
                {this.state.workContract.companyPercentage?(
                  <div style={{textAlign:'center'}}>{this.state.workContract.companyPercentage}% with type {this.state.workContract.isCompanyPercentageCalculatedOnTotalPrice?'Fix and Tools':'Fix Only'}</div>
                ):<div style={{textAlign:'center'}}>N/A</div>}
              </th>
            </tr>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Basic Salary</th>
              <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.workContract.basicSalary} LE</div></th>
            </tr>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Minimum Salary</th>
              <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.workContract.minimumSalary} LE</div></th>
            </tr>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Order Slots</th>
              <th style={{textAlign:'center',width:'40%'}}>
                <div style={{}}>
                  <ul style={{marginBottom:0,textAlign:'center', listStyle:'none', paddingLeft:0}}>
                    {
                      this.state.workContract.orderSlots.length == 0 ? 'N/A' :
                      this.state.workContract.orderSlots.map((slot,slotIndex)=>(
                        <li key={slotIndex}>Order#{slotIndex+1}: <span className="badge badge-dark">{slot.bonus} LE</span></li>
                      ))
                    }
                  </ul>
                </div>
              </th>
            </tr>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Default Bonus</th>
              <th style={{textAlign:'center',width:'40%'}}><div style={{textAlign:'center'}}>{this.state.workContract.defaultBonus?this.state.workContract.defaultBonus+' LE':'N/A'} </div></th>
            </tr>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Target</th>

              <th style={{textAlign:'center',width:'40%'}}>
                {this.state.workContract.target?(
                  <div style={{textAlign:'center'}}>{this.state.workContract.target}{this.state.workContract.targetType == "fixed_amount" ? ' LE' : ' orders' }</div>
                ):<div style={{textAlign:'center'}}>N/A</div>
                }
              </th>
            </tr>
            <tr>
              <th style={{textAlign:'center',width:'40%'}}>Rewards</th>
              <th style={{textAlign:'center',width:'40%'}}>
                {this.state.workContract.reward?(
                  <div style={{textAlign:'center'}}>{this.state.workContract.reward} {this.state.workContract.rewardType == 'fixed' ? 'LE' : '%' } with type {this.state.workContract.rewardType == 'fixed' ? 'Fixed Amount' : 'Percentage'}</div>
                ):<div style={{textAlign:'center'}}>N/A</div>
                }
              </th>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }


  render(){
    return (<>
    <Modal isOpen={this.state.showFinanceDetails} toggle={()=>this.setState({showFinanceDetails:false})} >
      <ModalHeader toggle={()=>this.setState({showFinanceDetails:false})}>Finance Report</ModalHeader>
      <ModalBody>
        {this.state.workContract?this.renderFinanceReport():null}
      </ModalBody>
      <ModalFooter>
        <Button className='actionsBtn' onClick={()=>this.setState({showFinanceDetails:false})}>Close</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={this.state.showAreas} toggle={()=>this.setState({showAreas:false})} >
      <ModalHeader toggle={()=>this.setState({showAreas:false})}>Areas</ModalHeader>
      <ModalBody>
        {this.state.workContract?(
          <div className='orderBadges' style={{width:350, textAlign:'center', display:'block', margin:'auto'}}>
            {this.state.workContract.areas.map((area, i)=>(
              <span key={i} className='noWrap someSpace' style={{backgroundColor:'transparent', border:'1px solid #5a5a5a', color:'#5a5a5a',fontSize:12}}>{area.enName}</span>
            ))}
          </div>
        ):null}
      </ModalBody>
      <ModalFooter>
        <Button className='actionsBtn' onClick={()=>this.setState({showAreas:false})}>close</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={true} toggle={()=>this.props.onClose()} >
      <ModalHeader toggle={()=>this.props.onClose()}>Work Contract</ModalHeader>
      <ModalBody>
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.setState({historyModal:true})}>History <i style={{marginLeft:10}} className="fas fa-file-text"></i></Button>
        </Row>
        {this.state.workContract?(
          <div style={{padding:'0px 20px'}}>
            <Row style={{marginTop:20}}>
              <Col>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Contract ID: <span style={{fontWeight:'bold'}}>{this.state.workContract.id}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>StartDate: <span style={{fontWeight:'bold'}}>{this.state.workContract.startDate}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>EndDate: <span style={{fontWeight:'bold'}}>{this.state.workContract.endDate}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>City: <span style={{fontWeight:'bold'}}>{this.state.workContract.city.enName} </span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Areas: <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({showAreas:true})}>Show Areas</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Package :<span style={{fontWeight:'bold'}}>{this.state.workContract.package.arName}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Category :<span style={{fontWeight:'bold'}}>{this.state.workContract.category.enName}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Finance :<span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({showFinanceDetails:true})}>Details</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Reservation Status  :<span style={{fontWeight:'bold'}}>{this.state.workContract.isOpenForReservation?(<Badge color='success' >Opened </Badge>):(<Badge color='danger'>Closed</Badge>)}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Requests Number :<span style={{fontWeight:'bold'}}>{this.state.workContract.requestsNumber}</span></p>
                <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Accepted Requests:<span style={{fontWeight:'bold'}}>{this.state.workContract.acceptedRequests}</span></p>
                {/* <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>History:<span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer'}} onClick={()=>this.setState({historyModal:true})}>Details</span></p> */}
              </Col>
            </Row>
          </div>
        ):null}
      </ModalBody>
      <ModalFooter>
        <Button className='actionsBtn' onClick={()=>this.props.onClose()}>Cancel</Button>
      </ModalFooter>
    </Modal>
    <LoadingSpinner loading={this.state.loading} />

    <FModal
     title='View History'
     content={
       <WorkContractHistory
         // ref={ref => this.qouteChatRef = ref}
         workContract={this.state.workContract}
         reload={()=>this.setState({historyModal:false,selectedQuotation:null})}
       />
     }
     state={this.state.historyModal}
     onClose={()=>this.setState({historyModal:false})}
     show={"none"}
   />
  </>);
  }

}

export default SingleWorkContract;
