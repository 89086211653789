import React,{Component , Fragment} from 'react'
import { Container,Card,Col,Row,Button as ReactButton } from 'reactstrap'
import Search from '../../components/Search'
import NoResult from '../../components/NoResult'
import Table from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import TH from '../../components/Table/TH'
import TD from '../../components/Table/TD'
import TableBody from '../../components/Table/TableBody'
import TR from '../../components/Table/TR'
import Button from '../../components/Button'
import StatusBadge from '../../components/StatusBadge'
import Paginator from '../../components/Pagination/Paginator'
import PageLink from '../../components/Pagination/PageLink'
import Services from '../../services/Services'
import {Global} from '../../core/Global'
import LoadingSpinner from '../../components/LoadingSpinner'
import SingleFixer from './SingleFixer'
import SingleOrder from './../orders/SingleOrder';
import SearchResult from './../../components/SearchResult';
import moment from 'moment'
import CityFilter from '../../components/Filters/CityFilter'
import AreaFilter from '../../components/Filters/AreaFilter'
import CategoryFilter from '../../components/Filters/CategoryFilter'
import DatePicker from '../../components/DatePicker'

class FixersSchedule extends Component{
  constructor(props){
    super(props)
    this.state={
      loading:true,
      date:moment().locale('en').format('YYYY-MM-DD'),
      requestedDate:moment().locale('en').toDate(),
      periods:[1,2,3,4,5,6],
      data:[],
      fixers:[],
      singleFixer:null,
      showFixer:false,
      singleOrder:{},
      showOrder:false,
      paginationInfo:[],
      nextPages:[],
      previousPages:[],
      currentPage:1,
      searchFlag:false,
      searchTerm:'',
      filtersFlag:false,
      selectedCity:'City',
      selectedCityId:0,
      selectedArea:'Area',
      selectedAreaId:0,
      selectedCategory:'Category',
      selectedCategoryId:0,
    }

    this.searchRef=React.createRef();
    this.cityRef=React.createRef();
    this.areaRef=React.createRef();
    this.categoryRef=React.createRef();

  }
  componentDidMount(){
    //fire the request to get data
    this.getFixers();
    console.log(this.state.date);
  }

  resetFilters(){
    this.setState({
      filtersFlag:false,
      loading:true,
      requestedDate:'',
      selectedCity:'City',
      selectedCityId:0,
      selectedArea:'Area',
      selectedAreaId:0,
      selectedCategory:'Category',
      selectedCategoryId:0,
    })
    this.cityRef.current.clear();
    this.areaRef.current.clear();
    this.categoryRef.current.clear();
    if(this.state.searchFlag){
      this.searchFixers(this.state.searchTerm)
    }else{
      this.getFixers()
    }
  }

  buildApiExtenstion(api){
    if(this.state.selectedCityId !=0 ){
      api+=`&city=${this.state.selectedCityId}`
    }
    if(this.state.selectedAreaId!=0){
      api+=`&area=${this.state.selectedAreaId}`
    }
    if(this.state.selectedCategoryId != 0 ){
      api+=`&category=${this.state.selectedCategoryId}`
    }
    if(this.state.requestedDate != '' ){
      let dateInFormat=moment(this.state.requestedDate).format("YYYY-MM-DD")
      api+=`&requestedDate=${dateInFormat}`
    }else{
      api+=`&requestedDate=${this.state.date}`
    }
    return api
  }

  getDataByFiltiration(page=1){
    if(this.state.selectedCategoryId || this.state.selectedAreaId || this.state.selectedCityId || (this.state.requestedDate != '')){
      this.setState({filtersFlag:true,loading:true})
      this.getFixers(page)
    }else{
      alert('Please Choose Filter')
    }
    //todo make a req to fetch data
  }

  cityHandler=(city)=>{
    this.setState({selectedCity:city.enName,selectedCityId:city.id})
    this.areaRef.current.getAreas(this,city.id)
    if (city.enName=='Choose' || city.enName == "All" ) {
      this.categoryRef.current.getCategories(this,false)
    }
  }

  areaHandler = (area)=>{
    this.setState({selectedArea:area.enName,selectedAreaId:area.id})
    this.categoryRef.current.getCategories(this,true,area.id)
  }

  categoryHandler = (category) => {
    this.setState({selectedCategory:category.enName,selectedCategoryId:category.id})
  }

  datePickerHandler=(date)=>{
    console.log(date)
    this.setState({requestedDate:date})
  }

  gotoPage=(page)=>{
    if (this.state.searchFlag) {
      this.setState({loading:true,},()=>this.searchFixers(this.state.searchTerm,page))
    }else{
      this.setState({loading:true,},()=>this.getFixers(page))
    }
  }

  prevPage= () => {
    let page=this.state.currentPage
    this.gotoPage(page-1)
  }

  nextPage= () => {
    let page=this.state.currentPage
    this.gotoPage(page+1)
  }

  SearchHandler = (term)=>{
    if(term != ''){
      this.setState({searchTerm:term})
      this.searchFixers(term)
    }else{
      alert('Please Add Search Keyword')
    }
  }

  searchFixers = (searchTerm,page=1) =>{
    let api=`${Global.baseURL}admin-panel/fixers?page=${page}&returnWithOpenOrders=1&searchTerm=${searchTerm}`
    this.setState(()=>({
      loading:true,
      searchFlag:true,
      currentPage:page,
    }))
    let newApi=this.buildApiExtenstion(api)
    console.log(newApi)
    this.fetchFixers(newApi)
  }

  closeSearch = ()=>{
    this.setState( () =>({
      searchFlag:false,
      searchTerm:'',
      loading:true
    }),()=>{this.getFixers()})
    this.searchRef.current.clearCache()
  }

  showFixer = (fixer) => {
    this.setState((state,props) => ({singleFixer:fixer}))
    this.setState((state,props) => ({showFixer:true,}))
  }

  showOrder = (order) => {
    this.setState((state,props) => ({singleOrder : order}))
    this.setState((state,props) => ({showOrder:true,}))
  }

  getFixers=(page=1)=>{
    let api=`${Global.baseURL}admin-panel/fixers?page=${page}&returnWithOpenOrders=1`
    api=this.buildApiExtenstion(api)
    console.log(api)
    this.fetchFixers(api)
  }

  fetchFixers=(api)=>{
    Services.getFixers(api,(res)=>{
      this.setState({
        loading:false,
        data:res.data,
        fixers:res.data.fixers,
        paginationInfo:res.data.paginationInfo,
        nextPages:res.data.paginationLinks.nextPages,
        previousPages:res.data.paginationLinks.previousPages,
        currentPage:res.data.paginationInfo.currentPage,
      })
    })
  }

  reload = () => {
    this.setState({
      showFixer:false,
      showOrder:false,
      singleFixer:{},
      singleOrder:{},
    })
    if(this.state.searchFlag){
      this.searchFixers(this.state.searchTerm,this.state.currentPage)
    }else{
      this.getFixers(this.state.currentPage)
    }
  }

  enableLoading = () =>{
    this.setState({loading:true})
  }

  disableLoading = () =>{
    this.setState({loading:false})
  }

  render(){
    // console.log(this.state.singleFixer)
    return (
      <div  className="app align-items-center">
        <LoadingSpinner fade loading={this.state.loading} />
        <Container>
          <Row>
            <CityFilter handler={(city)=>this.cityHandler(city)} ref={this.cityRef}/>
            <AreaFilter selectedCity={this.state.selectedCity}
              ref={this.areaRef}
              handler={(area)=>this.areaHandler(area)}/>
            <CategoryFilter ref={this.categoryRef}
              handler={(category)=>this.categoryHandler(category)}/>
            <DatePicker date={this.state.requestedDate} currentDate={this.state.date} handler={(date)=>{this.datePickerHandler(date)}}/>
            {this.state.requestedDate!=''?(
              <div className='fa fa-close clearDatePicker' onClick={()=>this.setState({requestedDate:''})}/>
            ):null}
            <Col md={1}>
              <ReactButton onClick={()=>this.getDataByFiltiration()}
                className='actionsBtn'
                style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</ReactButton>
            </Col>

            <Col md={1}>
              <ReactButton onClick={()=>this.resetFilters()}
                className='actionsBtn'
                style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'white'}}>Reset</ReactButton>
            </Col>
          </Row>

          <Card>
            <Search header="Fixers Schedule"
              placeholder="By name, id, phone...."
              maxLength="50"
              handler={this.SearchHandler}
              ref={this.searchRef}
            ></Search>
            { this.state.searchFlag ? <SearchResult searchTerm={this.state.searchTerm} handler={this.closeSearch} /> : null }

            {this.state.fixers.length != 0 ? (
              <Fragment>
                <Table size="md">
                  <TableHeader>
                    <TH >Fixer</TH>
                    <TH >9-11 AM</TH>
                    <TH >11-1 PM</TH>
                    <TH >1-3 PM</TH>
                    <TH >3-5 PM</TH>
                    <TH >5-7 PM</TH>
                    <TH >7-9 PM</TH>
                  </TableHeader>
                  <TableBody>
                    {
                      this.state.fixers.map((fixer, index)=>{
                        return(
                          <TR key={index}>
                            <TD>
                              <Button handler={()=>this.showFixer(fixer)}>
                                {fixer.arName==''?fixer.name : fixer.arName}
                              </Button>
                            </TD>
                            {this.state.periods.map((period, index)=>{
                              return(
                                <TD key={index}>
                                  {<Fragment>
                                    {fixer.orders.map((order, index) => (
                                      <Fragment key={index}>
                                        {period == order.period.id ?
                                          (<Button badge
                                            handler={()=>this.showOrder(order)}
                                            minWidth='0'
                                           padding='0'>
                                            <StatusBadge colorMapper={order.status.id}>

                                              {order.id}
                                            </StatusBadge>
                                          </Button>
                                          ):null}
                                      </Fragment>
                                    ))
                                    }
                                  </Fragment>
                                  }
                                </TD> )})
                            }
                          </TR>)})}
                  </TableBody>
                </Table>
                <Paginator  disablePrev={(this.state.previousPages.length == 0 )}
                  disableNext={(this.state.nextPages.length == 0)}
                  prevAction={()=>this.prevPage()}
                  nextAction={()=>this.nextPage()}
                >
                  {this.state.previousPages.reverse().map((link, index) => {
                    return (
                      <PageLink key={index} action={()=>this.gotoPage(link)}>
                        {link}
                      </PageLink>
                    )
                  }
                  )}
                  <PageLink active >
                    {this.state.currentPage}
                  </PageLink>
                  {this.state.nextPages.map((link, index) => {
                    return (
                      <PageLink key={index} action={()=>this.gotoPage(link)}>
                        {link}
                      </PageLink>
                    )})}
                </Paginator>
              </Fragment>
            ):(<NoResult/>)}
            { this.state.showFixer ? <SingleFixer data={this.state.singleFixer} reload={this.reload}/> : null }
            { this.state.showOrder ? <SingleOrder data={this.state.singleOrder} reload={this.reload}/> : null }
          </Card>
        </Container>
      </div>
                    )
                  }
                }
                export default FixersSchedule;
