import React, { Component } from 'react'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import ErrorMessage from './../../components/errors/ErrorMessage'
import PricingOrder from './PricingOrder'
import EditOrder from './EditOrder'
import SingleFixer from './../fixers/SingleFixer'
import SingleCustomer from './../customers/SingleCustomer'

import CommunicationChannelService from '../../services/orderServices/CommunicationChannelService'
import {
  IntervalDetailsModal, MapModal, AddNote, Actions, FModal, Confirm,
  CloseAsVideoOrder, Cancel,  Reopen, Inprogress, ChangeCategory, CloseAsInspection,
  Estimation, History, AssignOrderToAgents, AddVideoLink, Repeate, RedirectOrder,
  Invoice, InspectedDetails, AssignFixerInPendingDispatch, DisplayCommunicationChannel,
  ChangeOrderTiming, LoadingSpinner, OrderDetails, Equipments, ConfirmationInquiryOrder,
  ChangePaymentMethod,Chat,CreateComplaint, PriceOfferDetails, ConfirmPayment
} from '../../components'
import ConfirmationPricingOrder from './ConfirmationPricingOrder'
import OrdersRateService from './../../services/orderServices/OrdersRateService'


  class SingleOrder extends Component {
    constructor(props) {
      super(props);
      this.state = {
        singleOrder:'',
        loading:true,
        mainModal: false,
        modalConfirm : false,
        redirectModal : false,
        modalInprogress : false,
        modalCancel : false,
        reOpenModal : false,
        visitTime :false,
        modalInvoice:false,
        modalHistory : false,
        requestedDate:'',
        showSingleCustomer: false,
        renderPricingOrder:false,
        renderEditOrder:false,
        modalAgent:false,
        modalAddNote:false,
        modalChangeCategory:false,
        estimationModal:false,
        modalErrorMessage:false,
        modalOutOfWorkContract:false,
        showSingleCustomer:false,
        showSingleFixer:false,
        showIntervalsModal:false,
        intervalsDetails:[],
        lng:'',
        lat:'',
        showMap:false,
        modalInspectedDetails:false,
        handleCloseAsInspectionModel:false,
        displayCommunicationChannelModal:false,
        modalAssignFixerInPendingDispatch:false,
        modalRepeatedOrder:false,
        addVideoLinkModal:false,
        closeVideoOrderModal:false,
        addEquipmentModal:false,
        confirmPricingOrder:false,
        confirmPricingInquiryOrder:false,
        changePaymentMethodModal:false,
        viewChatModal:false,
        createComplaintModal:false,
      }

      this.closeModalOutOfWCErrorMessage=this.closeModalOutOfWCErrorMessage.bind(this)
    }

    componentDidMount(){
        this.getOrderDetails(this.props.data)
      // this.setState({renderPricingOrder:false,renderEditOrder:false,},()=>)
    }
    componentWillReceiveProps(nextProps) {
      this.getOrderDetails(nextProps.data)
      // this.setState({renderPricingOrder:false,renderEditOrder:false},()=>)
    }

    reload = () => {
      this.setState({showSingleCustomer:false,showSingleFixer:false,renderPricingOrder:false,renderEditOrder:false, confirmPaymentModal:false},()=>this.getOrderDetails(this.props.data))
    }

    closeSingleCustomerOrFixer = () => {
      this.setState({showSingleCustomer:false,showSingleFixer:false,})
    }

    handleErrorMessage =()=>{
      this.setState({redirectModal:false,modalConfirm:false},()=>this.closeModalsWithHandleErrorMessage())
    }

    closeModalsWithHandleErrorMessage(){
      this.setState({modalErrorMessage : !this.state.modalErrorMessage})
    }

    closeModalWithOutOfWorkContractError(){
      this.setState({modalOutOfWorkContract:true})
    }
    closeModalOutOfWCErrorMessage(){
      this.setState({modalOutOfWorkContract:false})
    }

    handleRepeatedOrderModal =()=>{
      this.setState({modalRepeatedOrder:!this.state.modalRepeatedOrder})
    }

    getSingle(type, item){
      console.log(item);
      switch (type) {
        case 'customer':
        this.setState({showSingleCustomer:true, singleCustomer:item, showSingleFixer:false,showSingleOrder:false})
        break
        case 'order':
        this.setState({showSingleOrder:true, singleOrder:item, showSingleFixer:false,showSingleCustomer:false,})
        break
        case 'fixer':
        this.setState({showSingleFixer:true, singleFixer:item, showSingleCustomer:false,showSingleOrder:false})
        break
        default:
      }
    }

    getOrderDetails(item){
      console.log(item);
      this.setState({loading:true})
      Services.getSingleOrder(item, (res)=>{
        this.setState({singleOrder:res.data.order,loading:false,mainModal:true,})
      })
    }

    // orderHistoryModal(){
    //   if (!this.state.modalHistory) {
    //     this.setState({modalHistory : !this.state.modalHistory, mainModal:true,renderPricingOrder:false,renderEditOrder:false})
    //   }else {
    //     this.setState({modalHistory : !this.state.modalHistory, mainModal:true,renderPricingOrder:false,renderEditOrder:false})
    //   }
    // }

    handleOrderDetailsModal(item){
      if (this.state.mainModal !== false) {
        this.setState({mainModal:!this.state.mainModal, singleOrder:'', renderPricingOrder:false, renderEditOrder:false},()=>this.props.reload())
      }else {
        this.getOrderDetails(item)
      }
    }
    handleConfirmOrderModal(){
      this.setState({renderPricingOrder:false,renderEditOrder:false,modalConfirm:!this.state.modalConfirm, selectedRedirectedFixer:'Choose',selectedTime:'Choose',visitTimeOrder:''})
      // this.setState({renderPricingOrder:false,renderEditOrder:false,modalConfirm:!this.state.modalConfirm, selectedRedirectedFixer:'Choose',selectedTime:'Choose',visitTimeOrder:''},()=>this.getRedirectedFixers())
    }
    toggleRedirect(){
      // yasss
      if (this.state.redirectModal) {
        this.setState({renderPricingOrder:false,renderEditOrder:false,redirectModal: !this.state.redirectModal, suggestedFixers:[],fixerIds:[],selectedFixer:[]})
      }else {
        this.setState({redirectModal:true, loading:false,renderPricingOrder:false,renderEditOrder:false,},
          // ()=>this.getSuggestedFixers()
        )

      }
    }
    handleInprogressModal(){
      this.setState({renderPricingOrder:false,renderEditOrder:false,modalInprogress : !this.state.modalInprogress})
    }
    handleReOpenModal(){
      this.setState({renderPricingOrder:false,renderEditOrder:false,reOpenModal : !this.state.reOpenModal})
    }
    handleVisitTimeModal(){
      // zzzzzz
      this.setState({renderPricingOrder:false,renderEditOrder:false,visitTime:!this.state.visitTime,displayDay:'',requestedDate:'',selectedPeriod:'Choose',selectedTime:'Choose',selectPeriodId:'',visitTimeOrder:''})
    }
    handleCancelModal(){
      if (this.state.modalCancel) {
        this.setState({renderPricingOrder:false,renderEditOrder:false,modalCancel:!this.state.modalCancel, selectedReasonType:'Choose',selectedCancelReason:'Choose',cancelReasons:[],reasonId:''})
      }else {
        this.setState({renderPricingOrder:false,renderEditOrder:false,modalCancel:!this.state.modalCancel, selectedReasonType:'Choose',selectedCancelReason:'Choose'})
      }
    }

    invoice(){
      this.setState({renderPricingOrder:false,renderEditOrder:false,modalInvoice : !this.state.modalInvoice})
    }
    inspectedDetails(){
      this.setState({modalInspectedDetails: !this.state.modalInspectedDetails})
    }

    handleAssignOrdersToAgent(){
      this.setState({modalAgent : !this.state.modalAgent})
    }

    handleChangeCategoryModal(type){
      if (type =='close') {
        this.setState({
          modalChangeCategory : !this.state.modalChangeCategory,
          // loading:false, modalChangeCategory:false,
          selectedCategory: 'Category',
          selectedCategoryId: '',
          selectedServiceGroup: 'Service Group',
          selectedServiceGroupId: '',
          selectedSuggestedFixer: 'Fixer',
          selectedSuggestedFixerId: ''})
        }else { //zzzzzzz
          this.setState({modalChangeCategory : !this.state.modalChangeCategory})
          // this.setState({modalChangeCategory : !this.state.modalChangeCategory}, ()=>this.getOrderDetails(this.props.data))
        }

      }

      handleCloseAsInspection(){
        if (!this.state.handleCloseAsInspectionModel) {
          this.setState({
            closeInspectionReasons:[],
            selectedCloseReason:'Choose',
            selectedCloseReasonId:'',
            selectedCloseAs:'Choose',
            closeInspectionType:''
          },
          // ()=>this.getReasonsForClosingInspection()
        )

      }
      this.setState({handleCloseAsInspectionModel:!this.state.handleCloseAsInspectionModel})
    }

    handleCommunicationChannelActoin(){
      this.setState({displayCommunicationChannelModal:true},() => console.log('in time') )
    }

    closeCommunicationChannelModal(){
      this.setState({displayCommunicationChannelModal:false,channel:this.state.singleOrder.communicationChannel.channel})
    }

    handleEstimationModal(){
      this.setState({estimationModal:!this.state.estimationModal})
    }
    showPrice(){
      global.showPriceModle=true
      this.setState({renderEditOrder:false,renderPricingOrder:true},()=>this.forceUpdate())
    }

    ShowIntervalsModal(intervals){
      console.log('hitted')
      this.setState(()=>({intervalsDetails:intervals}))
      this.setState(()=>({showIntervalsModal:true}))
    }

    closeIntervalDetailsModal(){
      this.setState({intervalsDetails:[],showIntervalsModal:false})
    }

    showMapModal(lat,lng){
      this.setState(()=>({lat:lat,lng:lng}))
      this.setState(()=>({showMap:true}))
    }

    closeMapModal(){
      this.setState({lng:'',lat:'',showMap:false})
    }

    // isMintanance(){
    //   if(this.state.singleOrder.serviceGroup!=null){
    //     return this.state.singleOrder.serviceGroup.id==1
    //   }
    //   return false
    // }

    handleAssignFixerInPendingDispatchModal(){
      this.setState({modalAssignFixerInPendingDispatch : !this.state.modalAssignFixerInPendingDispatch},
        // ()=>this.getFixersInPendingDispatch()
      )
    }

    handleRepeatedOrderModal(){
      this.setState({modalRepeatedOrder:!this.state.modalRepeatedOrder})
    }

    handlePricing(){
      if (this.state.singleOrder.offer) {
        return this.showConfirmationPricingOrder()
      }else if(this.state.singleOrder.quotation){
        return this.showConfirmationInquiryPricing()
      }else{
        this.showPrice()
      }
    }

    editPricing(){
      this.setState({confirmPricingOrder:false},()=>this.showPrice())
    }

    showConfirmationPricingOrder(){
      this.setState({confirmPricingOrder:true})
    }

    cancelConfirmationPricingOrder(){
      this.setState({confirmPricingOrder:false})
    }

    editInquiryOrder(){
      this.setState({confirmPricingInquiryOrder:false},()=>this.showPrice())
    }

    showConfirmationInquiryPricing(){
      this.setState({confirmPricingInquiryOrder:true})
    }

    cancelConfirmInquiryPricing(){
      this.setState({confirmPricingInquiryOrder:false})
    }
    deleteRate(){
      this.setState({loading: true})
      OrdersRateService.delete( this.state.singleOrder.rate.id, res=>{
        this.setState({loading: false, deleteRateModal:false, mainModal:false},()=>this.reload())
      })
    }

    render(){
      return(
        <div>
          {this.state.confirmPricingInquiryOrder? <ConfirmationInquiryOrder data={this.state.singleOrder} reload={this.props.reload} state={this.state.confirmPricingInquiryOrder} editAction={()=>this.editInquiryOrder()} onClose={()=>this.cancelConfirmInquiryPricing()} />:null}
          {this.state.confirmPricingOrder? <ConfirmationPricingOrder data={this.state.singleOrder} reload={this.props.reload} state={this.state.confirmPricingOrder} editAction={()=>this.editPricing()} onClose={()=>this.cancelConfirmationPricingOrder()}/> :null}
          {this.state.renderPricingOrder? <PricingOrder data={this.state.singleOrder} reload={this.props.reload}/>:null}
          {this.state.renderEditOrder? <EditOrder data={this.state.singleOrder} reload={this.props.reload}/>:null}
          {this.state.modalErrorMessage ? <ErrorMessage modalErrorMessage={this.state.modalErrorMessage} handleErrorMessage={this.handleErrorMessage} titlePopUp={<i color="white" style={{fontSize:80}} className="far fa-frown"></i>} errorMessage={"You can’t confirm or redirect this order because fixer has order in this period ."} close={"OK"} width={'35%'} visible={"none"}/>:null}
          {this.state.modalOutOfWorkContract ? <ErrorMessage modalErrorMessage={this.state.modalOutOfWorkContract} handleErrorMessage={this.closeModalOutOfWCErrorMessage} titlePopUp={<i color="white" style={{fontSize:80}} className="far fa-frown"></i>} errorMessage={"Sorry, You can’t confirm order because, fixer hasn’t valid work contract ."} close={"OK"} width={'35%'} visible={"none"}/>:null}

          {this.state.showSingleCustomer?<SingleCustomer data={this.state.singleOrder.customer} reload={this.closeSingleCustomerOrFixer}/>:null}
          {this.state.showSingleFixer?<SingleFixer data={this.state.singleOrder.fixer} reload={this.closeSingleCustomerOrFixer}/>:null}


          <IntervalDetailsModal fade show={this.state.showIntervalsModal} closeHandler={()=>this.closeIntervalDetailsModal()} Intervals={this.state.intervalsDetails} />
          <MapModal fade show={this.state.showMap} closeHandler={()=>this.closeMapModal()} lat={this.state.lat} lng={this.state.lng} />

          <FModal
            title='Order Details'

            content={this.renderOrderDetails()}
            // content={this.state.singleOrder!=''?this.renderSingleOrder():null}
            state={this.state.mainModal}
            onClose={()=>this.setState({mainModal:false},()=>this.props.reload())}
            // onSubmit={()=>this.confirmOrderRef.confirmOrder()}
            show='none'
          />

          <FModal
            title='Confirm'
            content={
              <Confirm
                ref={ref=>this.confirmOrderRef=ref}
                order={this.state.singleOrder}
                update={()=>this.setState({confirmModal:false},()=>this.props.reload())}
                noAvaiableError={()=>this.closeModalsWithHandleErrorMessage()}
                errorWorkContract={()=>this.closeModalWithOutOfWorkContractError()}
              />
            }
            state={this.state.modalConfirm}
            onClose={()=>this.handleConfirmOrderModal()}
            onSubmit={()=>this.confirmOrderRef.confirmOrder()}
          />

          <FModal
            title='Add Note'
            content={
              <AddNote
                ref={ref=> this.addNoteRef = ref}
                orderId={this.state.singleOrder.orderId}
                close={()=>this.setState({modalAddNote:false})}
              />
            }
            state={this.state.modalAddNote}
            onClose={()=>this.setState({modalAddNote:false})}
            onSubmit={()=>this.addNoteRef.addNoteModal()}
          />

          <FModal
            title='Change Category'
            content={
              <ChangeCategory
                ref={ref => this.changeCategory = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalChangeCategory}
            onClose={()=>this.handleChangeCategoryModal('close')}
            onSubmit={()=>this.changeCategory.changeCategory()}
          />

          <FModal
            title='Close as Inspection'
            content={
              <CloseAsInspection
                ref={ref => this.closeAsInspection = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.handleCloseAsInspectionModel}
            onClose={()=>this.handleCloseAsInspection()}
            onSubmit={()=>this.closeAsInspection.closeAsInspection()}
          />

          <FModal
            title='Estimation'
            content={
              <Estimation
                ref={ref => this.estimationOrder = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.estimationModal}
            onClose={()=>this.handleEstimationModal()}
            show={"none"}
          />

          <FModal
            title='Cancel'
            content={
              <Cancel
                ref={ref => this.cancelOrder = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalCancel}
            onClose={()=>this.handleCancelModal()}
            onSubmit={()=>this.cancelOrder.cancel()}
          />

          <FModal
            title='View History'
            content={
              <History
                ref={ref => this.orderHistory = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalHistory}
            // onClose={()=>this.orderHistoryModal()}
            onClose={()=>this.setState({modalHistory:!this.state.modalHistory})}
            show={"none"}
          />

          <FModal
            title='Re-Open'
            content={
              <Reopen
                ref={ref => this.reopenOrder = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.reOpenModal}
            onClose={()=>this.handleReOpenModal()}
            onSubmit={()=>this.reopenOrder.reOpen()}
          />

          <FModal
            title='Change Visit Time'
            content={
              <ChangeOrderTiming
                ref={ref => this.visitTimeRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.visitTime}
            onClose={()=>this.handleVisitTimeModal()}
            onSubmit={()=>this.visitTimeRef.orderTiming()}
          />

          <FModal
            title='Assign Orders To Agent'
            content={
              <AssignOrderToAgents
                ref={ref => this.assignOrderToAgentRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalAgent}
            onClose={()=>this.handleAssignOrdersToAgent()}
            onSubmit={()=>this.assignOrderToAgentRef.assignOrderToAgents()}
          />

          <FModal
            title='Change order status to in-progress'
            content={
              <Inprogress
                ref={ref => this.inprogressOrder = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalInprogress}
            onClose={()=>this.handleInprogressModal()}
            onSubmit={()=>this.inprogressOrder.inprogress()}
          />

          <FModal
            title='Redirect'
            content={
              <RedirectOrder
                ref={ref => this.redirectOrderRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
                closeModalsWithHandleErrorMessage={()=>this.closeModalsWithHandleErrorMessage()}
              />
            }
            state={this.state.redirectModal}
            onClose={()=>this.toggleRedirect()}
            onSubmit={()=>this.redirectOrderRef.redirect()}
          />

          <FModal
            title='Invoice'
            content={
              <Invoice
                ref={ref => this.invoiceRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalInvoice}
            onClose={()=>this.invoice()}
            show={"none"}
          />

          <FModal
            title='Invoice'
            content={
              <InspectedDetails
                ref={ref => this.inspectedDetailsRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalInspectedDetails}
            onClose={()=>this.inspectedDetails()}
            show={"none"}
          />

          <FModal
            title='Add Video Meeting Link'
            content={
              <AddVideoLink
                ref={ref => this.addVideoLinkRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.addVideoLinkModal}
            onClose={()=>this.setState({addVideoLinkModal:false})}
            onSubmit={()=>this.addVideoLinkRef.addVideoLink()}
          />

          <FModal
            title='Close as Video Order'
            content={
              <CloseAsVideoOrder
                ref={ref => this.closeAsVideoOrderRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.closeVideoOrderModal}
            onClose={()=>this.setState({closeVideoOrderModal:false})}
            onSubmit={()=>this.closeAsVideoOrderRef.closeVideoOrder()}
          />

          <FModal
            title='Change Communication Channel '
            content={
              <DisplayCommunicationChannel
                ref={ref => this.communicationChannelRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.displayCommunicationChannelModal}
            onClose={()=>this.closeCommunicationChannelModal()}
            onSubmit={()=>this.communicationChannelRef.communicationChannel()}
          />

          <FModal
            title='Assign Fixer'
            content={
              <AssignFixerInPendingDispatch
                ref={ref => this.assignFixerInPendingDispatchRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalAssignFixerInPendingDispatch}
            onClose={()=>this.handleAssignFixerInPendingDispatchModal()}
            onSubmit={()=>this.assignFixerInPendingDispatchRef.assignFixerInPendingDispatch()}
          />

          <FModal
            title='Change order status to repeated'
            content={
              <Repeate
                ref={ref => this.repeatedOrder = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.modalRepeatedOrder}
            onClose={()=>this.handleRepeatedOrderModal()}
            onSubmit={()=>this.repeatedOrder.repeate()}
          />

          <FModal
            title='Add Equipments'
            content={
              <Equipments
                ref={ref=>this.addEquipmentsRef=ref}
                order={this.state.singleOrder}
                onClose={()=>this.setState({addEquipmentModal:false},()=>this.props.reload())}
              />
            }
            state={this.state.addEquipmentModal}
            onClose={()=>this.setState({addEquipmentModal:false})}
            onSubmit={()=>this.addEquipmentsRef.assignToOrder()}
          />

          <FModal
            title='Change Payment Method'
            content={
              <ChangePaymentMethod
                ref={ref=>this.changePaymentMethodRef=ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.changePaymentMethodModal}
            onClose={()=>this.setState({changePaymentMethodModal:false})}
            onSubmit={()=>this.changePaymentMethodRef.changePaymentMethod()}
          />

          <FModal
            title='View Chat'
            content={
              <Chat
                ref={ref => this.orderChatRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.viewChatModal}
            // onClose={()=>this.orderHistoryModal()}
            onClose={()=>this.setState({viewChatModal:!this.state.viewChatModal})}
            show={"none"}
          />


          <FModal
            title='Create Complaint'
            content={
              <CreateComplaint
                ref={ref => this.createComplaintRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.setState({createComplaintModal:false},()=>this.props.reload())}
              />
            }
            state={this.state.createComplaintModal}
            onClose={()=>this.setState({createComplaintModal:false})}
            onSubmit={()=>this.createComplaintRef.createComplaints()}
          />

           <FModal
            title='Offer Prices'
            content={
              <PriceOfferDetails
                ref={ref => this.offerPricesDetailsRef = ref}
                order={this.state.singleOrder.orderId}
                action={"singleOrder"}
              />
            }
            state={this.state.offerPriceDetailsModal}
            onClose={()=>this.setState({offerPriceDetailsModal:false})}
            show={"none"}
          />


          <FModal
           title='Delete Rate'
           content={
             <p style={{textAlign:'center', fontSize:18}}>Are you sure you want to delete this rate ?</p>
           }
           state={this.state.deleteRateModal}
           onClose={()=>this.setState({deleteRateModal:false})}
           onSubmit={()=>this.deleteRate()}
          />

          <FModal
            title='Confirm Payment'
            content={
              <ConfirmPayment
                ref={ref => this.ConfirmPaymentRef = ref}
                order={this.state.singleOrder}
                reload={()=>this.props.reload()}
              />
            }
            state={this.state.confirmPaymentModal}
            onClose={()=>this.setState({confirmPaymentModal:false})}
            show={"none"}
          />



          <LoadingSpinner loading={this.state.loading} />
        </div>
      )
    }
    renderOrderDetails(){
      return(
        <div>
          <Actions
            ref={ref=> this.singleOrderAction = ref}
            order={this.state.singleOrder}
            confirm={()=>this.handleConfirmOrderModal()}
            cancel={()=>this.handleCancelModal()}
            edit={()=>this.setState({renderPricingOrder:false,renderEditOrder:true})}
            redirect={()=>this.toggleRedirect()}
            // history={()=>this.orderHistoryModal()}
            history={()=>this.setState({modalHistory:!this.state.modalHistory})}
            changeDate={()=>this.handleVisitTimeModal()}
            assignToAgent={()=>this.handleAssignOrdersToAgent()}
            addNote={()=>this.setState({modalAddNote:true})}
            changeCategory={()=>this.handleChangeCategoryModal()}
            changeCommunicationChannel={()=>this.handleCommunicationChannelActoin()}
            repeat={()=>this.handleRepeatedOrderModal()}
            addVideoLink={()=>this.setState({addVideoLinkModal:true})}
            showPrice={()=>this.handlePricing()}
            changeToInProgress={()=>this.handleInprogressModal()}
            closeAsInspection={()=>this.handleCloseAsInspection()}
            closeAsVideoOrder={()=>this.setState({closeVideoOrderModal:true})}
            reOpen={()=>this.handleReOpenModal()}
            assignFixer={()=>this.handleAssignFixerInPendingDispatchModal()}
            addEquipments={()=>this.setState({addEquipmentModal:true})}
            changePaymentMethod={()=>this.setState({changePaymentMethodModal:true})}
            viewChat={()=>this.setState({viewChatModal:true})}
            createComplaint={()=>this.setState({createComplaintModal:true})}
            viewPriceOfferDetails={()=>this.setState({offerPriceDetailsModal:true})}
            deleteRate={()=>this.setState({deleteRateModal:true})}
            confirmPayment={()=>this.setState({confirmPaymentModal:true})}
          />
          <OrderDetails
            ref={ref=> this.singleOrderRef = ref}
            order={this.state.singleOrder}
            showSingleFixer={()=>this.setState({showSingleFixer:true})}
            showSingleCustomer={()=>this.setState({showSingleCustomer:true})}
            showInvoice={()=>this.invoice()}
            showInspection={()=>this.inspectedDetails()}
            showEstimation={()=>this.handleEstimationModal()}
            showIntervals={()=>this.ShowIntervalsModal(this.state.singleOrder.intervals)}
            showStartLocation={()=>this.showMapModal(this.state.singleOrder.startingLatitude,this.state.singleOrder.startingLongitude)}
            showEndLocation={()=>this.showMapModal(this.state.singleOrder.endingLatitude,this.state.singleOrder.endingLongitude)}
          />
        </div>
      )
    }

  }
  export default withRouter(SingleOrder)
