import React from 'react'
import {Modal,ModalBody,ModalHeader,ModalFooter,Input,InputGroup,Button} from 'reactstrap'
class PasswordModal extends React.Component{
  constructor(props){
    super(props)
    this.state={
      secret:'',
    }
  }
  clear(){
    this.setState({secret:''})
  }
  submit(){
    if (this.confirmPass()) {
      this.props.submitAction()
      this.clear()
    }
  }
  confirmPass(){
    if(this.props.firebasePass!=this.state.secret){
        alert('Please, enter valid credentials')
        this.setState({secret:''})
        return false
    }
    return true
  }
  render(){
    return(
      <Modal fade={true} isOpen={this.props.isOpen} toggle={()=>{this.clear();this.props.closeHandler()}}>
      <ModalHeader toggle={()=>{this.clear();this.props.closeHandler()}}>Authorized Step</ModalHeader>
      <ModalBody>
        <div style={{padding:'15px'}}>
            <label style={{textAlign:'center'}}><strong>Password</strong></label>
            <InputGroup style={{marginBottom:'20px'}}>
                <Input style={{padding:'20px'}} type="password" placeholder='Enter Password ...' value={this.state.secret} onChange={(e)=>this.setState({secret:e.target.value})}/>
            </InputGroup>
        </div>
      </ModalBody>
      <ModalFooter>
            <Button className='actionsBtn' onClick={()=>{this.clear();this.props.closeHandler()}}>Close</Button>
            <Button className='actionsBtn' onClick={()=>this.submit()}>Confirm</Button>
      </ModalFooter>
    </Modal>
    )
  }
}

export default PasswordModal;
