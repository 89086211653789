import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Dropdown ,DropdownItem ,DropdownToggle ,DropdownMenu, Modal, UncontrolledDropdown } from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import moment from 'moment'
import { buildQueryStringFromArray } from './../../core/helper'

var Highcharts = require('highcharts')
require('highcharts/modules/exporting')(Highcharts)



class Report extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pageTitle:'',
      fromDate:moment().startOf('month').format('YYYY-MM-DD'),
      toDate:'',
      renderToDate:'',
      renderFromDate:moment().startOf('month').toDate(),
      cities:[],
      areas:[],
      selectedCity:'City',
      selectedArea:'Area',
      selectedCityId:'',
      selectedAreaId:'',
      noResult:false,
      categories:[],
      selectedCategories:[],
      data:{
        chart: {type: 'column'},
        title: {text: ''},
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {fontSize: '13px',fontFamily: 'Verdana, sans-serif'}
          }
        },
        yAxis: {
          min: 0,
          title: {text: 'Numbers of cancel orders'},
          tickInterval:1,
        },
        legend: {enabled: false},
        tooltip: {pointFormat: ''},
        series: [{
          name: 'customers',
          data: [],
          turboThreshold:1000000,
        }]

      }
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.handleCurrentPage(this.props.currentPage)
    // this.setState({currentPage:this.props.currentPage})
    this.getCities()
  }

  getCities(){
    this.setState({loading:true})
    Services.getCities((res)=>{
      let obj = {enName:'All',id:'all'}
      let arr = res.data.cities
      arr.push(obj)
      this.setState({cities:arr},()=>this.getCategories())
    })
  }

  getCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr},()=>this.filter())
    })
  }

  getArea(){
    this.setState({loading:true})
    Services.getArea(this.state.selectedCityId, (res)=>{
      let obj = {enName:'All',id:'all'}
      let arr = res.data.areas
      arr.push(obj)
      this.setState({areas:arr, loading:false})
    })
  }



  handleCurrentPage(page){
    console.log('handleCurrentPage');
    console.log(page);
    this.setState({pageTitle:page})
    // switch (page) {
    //   case 'recivedOrderPerDay':
    //   console.log(1);
    //   this.setState({pageTitle:'Recived Orders'})
    //   break
    //   case 'cancelOrderPerDay':
    //   console.log(2);
    //   this.setState({pageTitle:'Cancel Orders'})
    //   break
    //   case 'DoneOrderPerDay':
    //   console.log(3);
    //   this.setState({pageTitle:'Done Orders'})
    //   break
    //   case 'registedCustomerPerDay':
    //   console.log(4);
    //   this.setState({pageTitle:'Registered Customer Per Day'})
    //   break
    //   default:
    // }
  }
  handleMenus(menu, item){
    switch (menu) {
      case 'city':
      this.setState({selectedCity: item.enName, selectedCityId: item.id, selectedArea:'Area',selectedAreaId:''},()=>this.getArea())
      break
      case 'area':
      this.setState({selectedArea: item.enName, selectedAreaId: item.id})
      break
      default:
    }
  }
  handleOnChangeDate(type, date){
    let day = new Date (date+' Z')
    if (type == 'from') {
      this.setState({fromDate: day.toISOString().split('T')[0], renderFromDate: date, toDate:'', renderToDate:''},()=>console.log(this.state.fromDate))
    }else {
      this.setState({toDate: day.toISOString().split('T')[0], renderToDate: date},()=>console.log(this.state.toDate))
    }
  }



  filter(){
    let api = ''

    if (this.props.currentPage == 'Recived Order Per Day') {
      api = `${Global.baseURL}admin-panel/reports/orders`
    }else {
      api = `${Global.baseURL}admin-panel/reports/customers`
    }

    if (this.props.currentPage == 'Done Order Per Day') {
      api = `${Global.baseURL}admin-panel/reports/orders?statusId=4`
    }

    if (this.props.currentPage == 'Cancel Order Per Day') {
      api = `${Global.baseURL}admin-panel/reports/orders?statusId=5`
    }

    if (this.state.selectedCityId != '' && this.state.selectedCityId != 'all') {
      if (api == `${Global.baseURL}admin-panel/reports/orders` || api == `${Global.baseURL}admin-panel/reports/customers`) {
        api+=`?cityId=${this.state.selectedCityId}`
      }else {
        api+=`&cityId=${this.state.selectedCityId}`
      }
    }
    if (this.state.selectedAreaId != '' && this.state.selectedAreaId != 'all') {
      if (api == `${Global.baseURL}admin-panel/reports/orders` || api == `${Global.baseURL}admin-panel/reports/customers`) {
        api+=`?areaId=${this.state.selectedAreaId}`
      }else {
        api+=`&areaId=${this.state.selectedAreaId}`
      }
    }
    if (this.state.fromDate != '') {
      if (api == `${Global.baseURL}admin-panel/reports/orders` || api == `${Global.baseURL}admin-panel/reports/customers`) {
        api+=`?from=${this.state.fromDate}`
      }else {
        api+=`&from=${this.state.fromDate}`
      }
    }
    if (this.state.toDate != '') {
      if (api == `${Global.baseURL}admin-panel/reports/orders` || api == `${Global.baseURL}admin-panel/reports/customers`) {
        api+=`?to=${this.state.toDate}`
      }else {
        api+=`&to=${this.state.toDate}`
      }
    }

    if (this.state.selectedCategories.length!=0 && !(this.state.selectedCategories[0].id == 'all')) {
      api+=buildQueryStringFromArray('categories',this.state.selectedCategories)
    }

    console.log(api);
    this.setState({loading:false})
    this.getReport(api)
  }


  getReport(api){
    this.setState({loading:true})
    Services.getReport(api, (res)=>{
      let arr = []
      for (var i = 0; i < res.data.length; i++) {
        let singleArr = []
        let date = this.convertLinuxDate(res.data[i].createdAt)
        singleArr.push(date)
        singleArr.push(parseInt(this.props.currentPage == 'Registed Customer Per Day'?res.data[i].count:res.data[i].ordersCount))
        arr.push(singleArr)
      }
      let newData = this.state.data
      newData.title.text = this.state.pageTitle
      newData.yAxis.title.text = "Number of " + this.state.pageTitle
      newData.series[0].data = arr
      newData.tooltip.pointFormat = `${this.state.pageTitle}: <b>{point.y:.1f}</b>`
      // console.log(arr);
      this.setState({loading:false, data:newData, noResult:res.data.length!=0?false:true},()=> {
        if (!this.state.noResult) {
          Highcharts.chart('container', this.state.data)
        }

        console.log(this.state.data)
      })
    })
  }


  convertLinuxDate(linux_date) {
    var arrDate = linux_date.split("-");
    return arrDate[2] + "/" +arrDate[1] + "/" + arrDate[0];
  }
  handleMultiSelectMenu(type,item){
    if (item.id == 'choose') {
      this.state[type] = [];
    }else if(item.id == 'all'){
      this.state[type]=[];
      this.state[type].push(item);
    }else{
      if (this.state[type].includes(item)) {
        alert('you have been select this item before')
      }else{
        this.state[type] = this.state[type].filter( item => item.id != 'all' )
        this.state[type].push(item)
      }
    }
    this.forceUpdate()

  }
  removeMultiFilter(type,index){
    this.state[type].splice(index,1)
    this.forceUpdate()
  }

  resetFilter(){
    // this.child.clearStatuses()
    this.setState({
      pageTitle:'',
      fromDate:moment().startOf('month').format('YYYY-MM-DD'),
      toDate:'',
      renderToDate:'',
      renderFromDate:moment().startOf('month').toDate(),
      selectedCity:'City',
      selectedArea:'Area',
      selectedCityId:'',
      selectedAreaId:'',
      noResult:false,
      // categories:[],
      selectedCategories:[],
    },()=>this.filter())
  }


  render() {
    return (
      <div className="app align-items-center">
        <Container>

          {this.renderFilter()}
          {!this.state.noResult?(
            <Card>
              <CardBody className='shadow'>
                <div id="container"></div>
              </CardBody>
            </Card>
          ):(
            <Card>
              <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                <h2>There's no result</h2>
              </CardBody>
            </Card>
          )}
        </Container>


        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>
      </div>
    );
  }
  renderFilter(){
    return(
      <>
      <Row style={{marginLeft:-5}}>
        <UncontrolledDropdown  className="mb-3">
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />{this.state.selectedCity}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.cities.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMenus('city',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>

        {this.state.pageTitle!='Registed Customer Per Day'?(
          <UncontrolledDropdown className="mb-3 areaMenu" style={{marginRight:15}}>
            <DropdownToggle caret disabled={this.state.selectedCity=='City' || this.state.selectedCity=='All'?true:false} style={{width:'100%'}} className='actionsBtn'><i className='fas fa-map-marker-alt' style={{marginRight:10}} />{this.state.selectedArea}</DropdownToggle>
            <DropdownMenu style={{width:'100%'}}>
              {this.state.areas.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMenus('area',item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        ):null}
        {this.state.pageTitle!='Registed Customer Per Day'?(
          <UncontrolledDropdown  className="" style={{marginRight:20}} >
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Category</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              {this.state.categories.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedCategories',item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        ):null}

        <div className='date'>
          <DatePicker
            // minDate={new Date()}
            selected={this.state.renderFromDate}
            onChange={(date)=> this.handleOnChangeDate('from', date) }
            dateFormat="dd-MM-yyyy"
            placeholderText='From date'
          />
        </div>

        <div className='date'>
          <DatePicker
            minDate={this.state.renderFromDate==''?new Date():this.state.renderFromDate}
            selected={this.state.renderToDate}
            onChange={(date)=> this.handleOnChangeDate('to', date) }
            dateFormat="dd-MM-yyyy"
            placeholderText='To date'
          />
        </div>

      </Row>
      <Row style={{marginLeft:-5, marginTop:-5}}>
        <Button onClick={()=>this.filter()} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
        <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
      </Row>
      <Row style={{padding:'0px 10px'}}>
        {this.renderFiltersInfo()}
      </Row>
    </>
  )
}

renderFiltersInfo(){
  return (<>
    <Row  style={{padding:'0px 0px 20px 0px'}}>
      {this.state.selectedCategories.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Categories </span>
          <div>
            {this.state.selectedCategories.map( (category,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedCategories',i)} >
                {`${category.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
    </Row>
  </>)
}

}





export default Report;
