import React, { Component } from 'react';
// import { Link ,NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination   ,Modal, Dropdown ,DropdownItem ,DropdownMenu ,DropdownToggle ,UncontrolledDropdown } from 'reactstrap';
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom';
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import SingleCustomer from './SingleCustomer'
import { AddCustomerModal, FModal, ReferralCode } from './../../components'

class Customers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customers:[],
      singleCustomer:'',
      searchTerm:'',
      paginationInfo:[],
      paginationLinks:[],
      nextPages:[],
      previousPages:[],
      loading:false,
      showSingleCustomer: false,
      currentPage:1,
      currentApi:'',
      noResult:true,
      searchText:'',
      showAddCustomerModal:false,

    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount(){
    this.getCustomers()
    window.scrollTo(0, 0)
  }

  handleChangeInputs = (e, handle) => {
    this.setState({searchTerm: e.target.value})
  }
  handleKeyPress(target) {
    if(target.charCode===13){
      this.setState({currentPage:1},()=>this.getCustomers())
    }
  }
  closeCustomerModal(){
    this.setState({showAddCustomerModal:false},()=>this.reload())
  }
  addCustomerHandler(){
    this.setState({showAddCustomerModal:true})
  }
  addCustomerLoadingHandler(event){
    event ? this.setState({loading:true}) : this.setState({loading:false})
  }
  reload = () => {
    this.setState({showSingleCustomer:false})
    this.getCustomers()
  }

  getCustomers(){
    let api = `${Global.baseURL}admin-panel/customers?page=${this.state.currentPage}`
    if (this.state.searchTerm !== '') {
      this.setState({currentPage:1})
      api+=`&searchTerm=${this.state.searchTerm}`
    }
    if (this.state.hasEstimation) {
      api+=`&hasCart=1`
    }

    this.setState({loading:true})
    Services.getCustomers(api, (res)=>{
      this.setState({
        loading:false,
        customers:res.customers,
        paginationInfo:res.paginationInfo,
        paginationLinks:res.paginationLinks,
        previousPages:res.paginationLinks.previousPages.reverse(),
        nextPages:res.paginationLinks.nextPages,
        noResult:res.customers.length===0?false:true,
        searchText:this.state.searchTerm!==''?this.state.searchTerm:''
      })
    })
  }
  prevPage(item){
    let page = this.state.currentPage
    if (item === '') {
      this.setState({currentPage: page-1},()=>this.getCustomers())
    }else {
      this.setState({currentPage: item},()=>this.getCustomers())
    }
  }
  nextPage(item){
    let page = this.state.currentPage
    if (item === '') {
      this.setState({currentPage: page+1},()=>this.getCustomers())
    }else {
      this.setState({currentPage: item},()=>this.getCustomers())
    }
  }

  showSingleCustomer(item){
    this.setState({showSingleCustomer:true, singleCustomer:item})
  }

  renderFilter(){
    return(
      <Row>
        <UncontrolledDropdown  className="mb-3">
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />{this.state.hasEstimation?'Has Estimation':'Estimation'}</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            <DropdownItem onClick={()=>this.setState({hasEstimation:true})}>Has Estimation</DropdownItem>
            <DropdownItem onClick={()=>this.setState({hasEstimation:false})}>Cancel</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <Col md={4}>
          <Button onClick={()=>this.getCustomers()} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'white'}}>Filter</Button>
          <Button onClick={()=>this.setState({hasEstimation:false},()=>this.getCustomers())} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
        </Col>


      </Row>
    )
  }

  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="8" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Customers</span>
          </Col>

          <Col md="4">
            <InputGroup className="float-right">
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e)}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By id, name, phone, mail...."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.getCustomers()} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>

        <hr/>

        <Row>
          <Col md="10" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-plus-square" style={{ marginRight: 8 }} />
            <span style={{marginLeft:6}}>Add Customers</span>
          </Col>

          <Col md="2" style={{padding:'0'}}>
            <Button className="actionsBtn float-right"
                  style={{marginBottom: "0%",display: "inline-block",marginLeft: "auto"}}
                  onClick={()=>this.addCustomerHandler()} >
                <i className="fa fa-plus-square" style={{ marginRight: 8 }} />
                Add Customer
            </Button>
          </Col>
        </Row>
      </CardHeader>
    )
  }

  renderCustomers(){
    return(
      <div>
        {this.state.searchText!==''?(
          <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
            <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
            <h6 style={{fontSize:18}}>Search Results of
              <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

            </h6>
            <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getCustomers())}>
              Clear Search
              <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
            </span>
          </div>

        ):null}
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center',width:'7%'}}>ID</th>
              <th style={{textAlign:'center'}}>Name</th>
              <th style={{textAlign:'center'}}>Phone</th>
              <th style={{textAlign:'center'}}>Mail</th>
              <th style={{textAlign:'center'}}>Wallet Money</th>
              <th style={{textAlign:'center'}}>Temperary Wallet</th>
              <th style={{textAlign:'center'}}>Registration Date</th>
              <th style={{textAlign:'center',width:'12%'}}>Status</th>
              <th style={{textAlign:'center'}}>Referral code</th>
            </tr>
          </thead>
          <tbody>
            {this.state.customers.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>{item.id}</td>
                <td style={{textAlign:'center'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.showSingleCustomer(item)}>{item.name}</Button>
                  <br/>
                  {item.sourceCompany?
                    <Badge className='shadow' style={{backgroundColor:'#0381ff', color:'#fff', marginTop:-14}}>{item.sourceCompany.name}</Badge>
                  :null}
                </td>
                <td style={{textAlign:'center'}}>{item.phone}</td>
                <td style={{textAlign:'center'}}>
                  {item.isEmailVerified?(<div><i className='fa fa-check-circle' style={{color:'#4dbd74', fontSize:18}}/> <br/></div>):''}
                  {item.email?item.email:'N/A'}
                </td>
                <td style={{textAlign:'center'}}>{item.walletAmount }EGP</td>
                {item.user?(
                  <td style={{textAlign:'center'}}>{item.user.temporaryWallet.credit} LE</td>
                ):(
                  <td style={{textAlign:'center'}}>N/A</td>
                )}
                {/* <td style={{textAlign:'center'}}>N/A</td> */}
                <td style={{textAlign:'center'}}>{item.createdAt?item.createdAt.split(' ')[0]:''}</td>
                <td style={{textAlign:'center'}}><Badge className='shadow' color={item.isBlocked==='1'?'danger':'success'}>{item.isBlocked==='1'?'Blocked':'Active'}</Badge></td>
                {item.referralCode?(
                  <td style={{textAlign:'center'}}>
                    <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({referralPopup:true, referralCode:item.referralCode})}>{item.referralCode.code}</Button>
                  </td>
                ):(
                  <td style={{textAlign:'center'}}>N/A</td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  renderPagination(){
    return(
      <nav>
        <Row className='pull-right'>
          <Pagination style={{justifyContent:'flex-end'}}>
            {/* previous link*/}
            <PaginationItem>
              <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
            </PaginationItem>

            {/* previousPages */}
            {this.state.previousPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* currentPage */}
            <PaginationItem active>
              <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
                {this.state.paginationInfo.currentPage}
              </PaginationLink>
            </PaginationItem>


            {/* nextPages */}
            {this.state.nextPages.map((item, index)=>(
              <PaginationItem key={index}>
                <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                  {item}
                </PaginationLink>
              </PaginationItem>
            ))}

            {/* nextPageUrl */}
            <PaginationItem>
              <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
            </PaginationItem>
          </Pagination>
        </Row>
      </nav>
    )
  }


render() {
  return (
    <>
    <AddCustomerModal show={this.state.showAddCustomerModal}
                      closeHandler={()=>this.closeCustomerModal()}
                      loadingHandler={(event)=>this.addCustomerLoadingHandler(event)} />
    <div className="app align-items-center">
      <Container>
        {this.renderFilter()}
        <Card>
          {this.renderSearch()}

          {this.state.noResult?
            <CardBody className='shadow'>
              {this.renderCustomers()}
              {this.renderPagination()}
            </CardBody>
          :(
            <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
              <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
              <h2>There's no result</h2>
            </CardBody>
          )}

          {this.state.showSingleCustomer?<SingleCustomer data={this.state.singleCustomer} reload={this.reload}/>:null}


          {/* loading spinner */}
          <Modal fade={false} isOpen={this.state.loading} className='loading'>
            <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
          </Modal>

        </Card>
      </Container>

      <FModal
        title='Referral Code'
        content={
          <ReferralCode
            ref={ref=>this.ReferralCodeRef=ref}
            update={()=>this.setState({referralPopup:false})}
            code={this.state.referralCode}
          />
        }
        state={this.state.referralPopup}
        onClose={()=>this.setState({referralPopup:false})}
        show={"none"}
      />
    </div>
    </>
  )
}
}

export default withRouter(Customers);
