import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


import PricingOrder from './PricingOrder'

class EditOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleOrder:this.props.data,
      orderImages:[],
      servicesGroups:[],
      customerAddress:[],
      newImage:[],
      editDesc:'',
      selectedService:'Choose',
      serviceId:'',
      visitTimeOrder:'',
      redirectedFixerId:'',
      selectPeriodId:'',
      loading:false,
      modalEditData : true,

    }

    this.handleChangeInputs = this.handleChangeInputs.bind(this)
  }

  componentDidMount(){
    this.getServicesGroup()
  }
  componentWillReceiveProps(nextProps) {
    console.log('singleCustomer componentWillReceiveProps');
    this.getServicesGroup()
  }


  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'desc':
      this.setState({editDesc:e.target.value})
      break
      default:
    }
  }



  submitEditData(){
    let reqData = {}
    if (this.state.serviceId != '') {
      reqData.serviceGroupId  = this.state.serviceId
    }

    if (this.state.singleOrder.address!=null && this.state.customerAddress.length != 0) {
      if (this.state.selectAddressId != this.state.singleOrder.address.id) {
        if (this.state.selectAddressId != "Not Available") {
          reqData.addressId  = this.state.selectAddressId
        }

      }
    }else if (this.state.singleOrder.address==null && this.state.customerAddress.length != 0) {
      if (this.state.selectAddressId != "Not Available") {
        reqData.addressId  = this.state.selectAddressId
      }
    }

    if (this.state.editDesc != this.state.singleOrder.description) {
      reqData.description  = this.state.editDesc
    }
    if (this.state.newImage.length != 0) {
      let body = new FormData()
      for (var i = 0; i < this.state.newImage.length; i++) {
        // console.log(this.state.newImage[i]);
        body.append(`images[${i}]`,this.state.newImage[i])
        // body.append(`images[${i}]`,{ type: 'image/jpg', name: this.state.newImage[i].name})
        console.log(body);
      }
      this.updateOrderTimingActionWithImage(body)
      return true
      // reqData.images  =
    }

    console.log(reqData);
    if (Object.keys(reqData).length === 0) {
      return true
    }else {
      this.updateOrderTimingAction(reqData)
    }
  }

  updateOrderTimingActionWithImage(reqData){
    this.setState({loading:true})
    Services.updateOrderTimingDetailsWithImage(this.state.singleOrder.orderId, reqData, (res)=>{
      this.setState({loading:false,visitTime:false, visitTimeOrder:'',selectPeriodId:'',modalInprogress:false},()=>this.props.reload())
    })
  }

  updateOrderTimingAction(reqData){
    console.log(reqData);
    // return true
    this.setState({loading:true})
    Services.updateOrderTimingDetails(this.state.singleOrder.orderId, reqData, (res)=>{
      this.setState({loading:false,visitTime:false, visitTimeOrder:'',selectPeriodId:'',modalInprogress:false},()=>this.props.reload())
    })
  }

  getServicesGroup(){
    this.setState({loading:true})
    Services.getServicesGroup(this.state.singleOrder.category.id,(res)=>{
      this.setState({servicesGroups:res.data.serviceGroups},()=>this.getCustomerAddress())
    })
  }

  getCustomerAddress(){
    Services.getCustomerAddress(this.state.singleOrder.customer.id, (res)=>{
      let arr = res.data.addresses
      this.setState({loading:false, customerAddress:arr,
        modalEditData:true, mainModal:true,
        editDesc:this.state.singleOrder.description!=null?this.state.singleOrder.description:'',
        selectAddressId:this.state.singleOrder.address!=null?this.state.singleOrder.address.id:'Not Available',
        selectedService:this.state.singleOrder.serviceGroup!=null?this.state.singleOrder.serviceGroup.enName:'Choose'
      })
    })
  }

  handleEditDataModal(){
    if (this.state.modalEditData) {
      this.setState({modalEditData:!this.state.modalEditData,orderImages:[],
      servicesGroups:[],
      customerAddress:[],
      newImage:[],
      editDesc:'',
      selectedService:'Choose',
      serviceId:'',
      visitTimeOrder:'',
      redirectedFixerId:'',
      selectPeriodId:'',
      loading:false,})
    }else {
      this.getServicesGroup()
    }
  }

  handleServicesGroups(item){
    this.setState({selectedService:item.enName, serviceId:item.id})
  }
  removeUploadedImage(item){
    let arr = this.state.orderImages
    let newImage = this.state.newImage
    let pos = arr.map(function(e) { return e.id; }).indexOf(item.id)
    arr.splice(pos,1)
    let x = newImage.map(function(e) { return e.id; }).indexOf(item.id)
    newImage.splice(x,1)
    this.setState({orderImages:arr, newImage:newImage},()=>console.log(this.state.newImage))
  }
  handleAddressList(item){
    this.setState({selectAddress:item,selectAddressId:item.id},()=>console.log(this.state.selectAddressId))
  }
  _handleImageChange(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    console.log(file);
    reader.onloadend = () => {
      let obj = {
        id:reader.result,
        image: reader.result,
        canDetele:'yes'
      }
      let newImage = this.state.newImage
      newImage.push(file)
      let arr = this.state.orderImages
      arr.push(obj)
      this.setState({orderImages:arr, newImage:newImage},()=>console.log(this.state.newImage))
    }
    reader.readAsDataURL(file)
  }




  render(){
    return(
      <div>

        {/* editDataModal */}
        <Modal fade={false} isOpen={this.state.modalEditData} toggle={()=>this.handleEditDataModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleEditDataModal()}>Edit Data</ModalHeader>
          {this.state.singleOrder != '' ? this.renderEditData():null}
          <ModalFooter>
            <Button className='actionsBtn'  onClick={()=>this.handleEditDataModal()}>Close</Button>
            <Button className='actionsBtn' onClick={()=>this.submitEditData()}>Submit</Button>
          </ModalFooter>
        </Modal>


        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>
      </div>
    )
  }

  renderEditData(){
    return(
      <ModalBody>
        <Row>
          <Col md="3">
            <p className='titles'>Order Types :</p>
          </Col>
          <Col md="5">
            <UncontrolledDropdown  className="mb-3">
              <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedService}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}}>
                {this.state.servicesGroups.map((item, index)=>(
                  <DropdownItem key={index} onClick={()=>this.handleServicesGroups(item)}>{item.enName}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row>
          <Col md="3"><p>order Description :</p></Col>
          <Col md="6">
            <textarea
              placeholder="Enter order description.."
              style={{resize:'none',padding:'6px 13px',borderRadius:5}}
              onChange={(e)=>this.handleChangeInputs(e, 'desc')}
              value={this.state.editDesc}
              cols={49} rows={5}
            />
          </Col>
        </Row>

        {/* {this.state.orderImages.length != 0?(
          <div style={{padding:15}}>
            <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}> Order Image:</span></p>
            <Row>
          {this.state.orderImages.map((item, index)=>(
          <Col key={item.id} md="4" style={{marginBottom:8}}>
          <img className='shadow' src={item.image} style={{width:'100%', height:200,}} />
          {item.canDetele == 'yes'?<i className='fa fa-trash shadow deleteImage' onClick={()=>this.removeUploadedImage(item)} />:null}
          </Col>
          ))}
            </Row>
          </div>
        ):null} */}

        {/* <input type="file" name="photo" id="upload-photo" accept="image/*" onChange={(e)=>this._handleImageChange(e)}/> */}

        {this.state.customerAddress.length != 0?(
          <div style={{padding:15}}>
            <p><i className="icon-arrow-right-circle"></i><span style={{marginLeft:6}}>Address: </span></p>
            <Row>
              {this.state.customerAddress.map((item, index)=>(
                <div key={index} className={this.state.selectAddressId == item.id?'activeCustomerAddress':'customerAddress'} onClick={()=>this.handleAddressList(item)}>
                  <span>{item.enAddress}</span>
                </div>
              ))}
            </Row>
          </div>
        ):null}


      </ModalBody>
    )
  }




}
export default withRouter(EditOrder)
