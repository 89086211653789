import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {Global} from './../../../core/Global'

export default class ChangeOrderTiming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime:'Choose',
      selectedPeriod:'Choose',
      visitTimeOrder:'',
      selectPeriodId:'',
      availablePeriods:[],
      requestedDate:'',
      allPeriods:Global.allPeriods,
      displayDay:''


    }
  }

  componentDidMount(){
    this.handleAvaiablePeriods(this.props.order.period.id)
  }

  orderTiming(){
    if (this.state.requestedDate == '' && this.state.selectPeriodId == '' && this.state.visitTimeOrder == '') {
      alert('Please, enter vaild data')
    }else {
      let reqData = {}
      if (this.state.visitTimeOrder != '') {
        reqData.visitTime  = this.state.visitTimeOrder
      }
      if (this.state.selectPeriodId != '') {
        reqData.periodId  = this.state.selectPeriodId
      }
      if (this.state.requestedDate != '') {
        reqData.requestedDate  = this.state.requestedDate
      }

      console.log(reqData);
      // return true
      this.updateOrderTimingAction(reqData)
    }
  }

  handleAvaiablePeriods(id){
    let arr = []
    switch (id) {
      case 1:
      arr.push('09:00 AM','09:30 AM','10:00 AM','10:30 AM')
      this.setState({availablePeriods: arr})
      break
      case 2:
      arr.push('11:00 AM','11:30 AM','12:00 PM','12:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 3:
      arr.push('01:00 PM','01:30 PM','02:00 PM','02:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 4:
      arr.push('03:00 PM','03:30 PM','04:00 PM','04:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 5:
      arr.push('05:00 PM','05:30 PM','06:00 PM','06:30 PM')
      this.setState({availablePeriods: arr})
      break
      case 6:
      arr.push('07:00 PM','07:30 PM','08:00 PM','08:30 PM')
      this.setState({availablePeriods: arr})
      break
      default:
    }
    // this.getAgents()
  }

  handleChooseTimeMenu(item){
    console.log(item);
    let local = item.split(' ')[1]
    console.log(local);
    let visitTime = ''
    if (local == 'PM') {
      let hour = item.split(':')[0]
      let minWithLocal = item.split(':')[1]
      if (hour != '12') {
        hour = parseInt(hour) + 12
        hour+= ':'+minWithLocal.split(' ')[0]
        visitTime = hour
      }else {
        visitTime = item.split(' ')[0]
      }
    }else {
      visitTime = item.split(' ')[0]
    }
    visitTime = visitTime + ':00'
    this.setState({selectedTime:item, visitTimeOrder:visitTime,})
  }

  handleOnChangeDate(date){
    let day = new Date (date+' Z')
    this.setState({requestedDate: day.toISOString().split('T')[0], displayDay: date, },()=>console.log(day.toISOString().split('T')[0]))
  }


  handleSelectedPeriod(item){
    this.setState({selectedPeriod:item.enBrief, selectPeriodId:item.id, selectedTime:'Choose'},()=>this.handleAvaiablePeriods(item.id))
  }

  updateOrderTimingAction(reqData){
    this.setState({loading:true})
    Services.updateOrderTimingDetails(this.props.order.orderId, reqData, (res)=>{
      this.setState({loading:false,selectedPeriod:'Choose',requestedDate:'',selectedTime:'Choose',mainModal:false,visitTime:false, visitTimeOrder:'',selectPeriodId:'',requestedDate:'',modalInprogress:false},()=>this.props.reload())
    })
  }
  
  render(){
    return(
      <div>
<Row>
          <Col md="3"><p>Date: <span style={{fontWeight:'bold'}}>{this.props.order.requestedDate}</span></p></Col>
          <Col md="4">
            <DatePicker
              minDate={new Date()}
              selected={this.state.displayDay}
              onChange={(input) => this.handleOnChangeDate(input)}
              dateFormat="dd-MM-yyyy"
              placeholderText='Click to select a date'
            />
          </Col>
        </Row>
        <Row style={{marginTop:12}}>
          <Col md="3"><p>Period: <span style={{fontWeight:'bold'}}>{this.props.order.period.enName}</span></p></Col>
          <Col md="4">
            <UncontrolledDropdown  className="mb-3">
              <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedPeriod}</DropdownToggle>
              <DropdownMenu style={{width:'100%'}}>
                {this.state.allPeriods.map((item, index)=>(
                  <DropdownItem key={index} onClick={()=>this.handleSelectedPeriod(item)}>{item.enBrief}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        {this.props.order.status.id == 2?(
          <Row>
            <Col md="3"><p>Time: <span style={{fontWeight:'bold'}}>{this.props.order.visitTime}</span></p></Col>
            <Col md="4">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedTime}</DropdownToggle>
                <DropdownMenu style={{width:'100%'}}>
                  {this.state.availablePeriods.map((item, index)=>(
                    <DropdownItem key={index} onClick={()=>this.handleChooseTimeMenu(item)}>{item}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        ):null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
