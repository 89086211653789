import React, { Component } from 'react'
import { Button, Col, Row ,Table ,CardHeader ,Badge ,Modal ,ModalBody ,ModalHeader ,ModalFooter  ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Input ,Form,FormGroup,Label} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'


class ErrorMessage extends Component {

      render(){
        return (
          <Modal fade={false} style={{width:this.props.width}} isOpen={this.props.modalErrorMessage} toggle={this.props.handleErrorMessage} className={this.props.className}>
            <ModalHeader toggle={this.props.handleErrorMessage}>{this.props.titlePopUp}</ModalHeader>
            <ModalBody>
              <p style={{marginLeft:'5%',color:"black"}}>{this.props.errorMessage}</p>
            </ModalBody>
            <ModalFooter>
              <Button className='actionsBtn'  onClick={this.props.handleErrorMessage}>{this.props.close}</Button>
              <Button className='actionsBtn' style={{display:this.props.visible}}  onClick={this.props.actionFunc}>{this.props.action}</Button>
            </ModalFooter>
          </Modal>
        )
    }
}

export default withRouter(ErrorMessage)
