import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Input , Table, Badge} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"

export default class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
        lastInvoice:this.props.order.lastInvoice != null ? this.props.order.lastInvoice : ''
    }
    console.log(this.state.lastInvoice)
  }

  componentDidMount(){

  }


  render(){
    return(
      <div>
        {this.state.lastInvoice.isPaid == 1 ?
          <Badge className='shadow truncate' color='dark'>paid</Badge>
        :null}
        {this.props.order.status?
          this.props.order.status.id == 31?
            this.renderInspectionInvoive():
            this.renderNormalInvoice()
        :null}
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }


  renderInspectionInvoive(){
    return(
      <div>
        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Price Type: <span style={{fontWeight:'bold'}}>INSPECTION </span></p>
        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Inspection Price: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.fixingPrice} EGP</span></p>
        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Amount of Promo Code: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.promoAmount}</span> EGP</p>
        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer Wallet: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.walletAmount}</span> EGP</p>
        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Total Amount After Discount: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.collectedAmount}</span> EGP</p>
      </div>
    )
  }


  renderNormalInvoice(){
    return(
      <div>
        {this.state.lastInvoice.invoiceItems.length == 0 ? (
          <div>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Price Type: <span style={{fontWeight:'bold'}}>MARAMA </span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Fixing Price: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.fixingPrice} EGP</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Tools Price: <span style={{fontWeight:'bold'}}> {this.state.lastInvoice.toolsPrice} EGP</span></p>
          </div>
        ):(
          <div>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Price Type:<span style={{fontWeight:'bold'}}> FIXAWY</span> </p>
            <Row style={{padding:'0px 50px'}}>
              <Table hover bordered striped responsive size="md" >
                <thead>
                  <tr>
                    <th style={{textAlign:'center'}}>Item</th>
                    <th style={{textAlign:'center'}}>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.lastInvoice.invoiceItems.map((item, index)=>(
                    <tr key={index}>
                      <td style={{textAlign:'center'}}>{item.arName}</td>
                      <td style={{textAlign:'center'}}>{item.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </div>
        )}


        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Total Amount: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.totalPrice}</span> EGP</p>

        {this.state.lastInvoice.promoAmount!=='0.00'?(
          <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Amount of Promo Code: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.promoAmount}</span> EGP</p>
        ):null}

        {this.state.lastInvoice.walletAmount!=='0.00'?(
          <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer Wallet: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.walletAmount}</span> EGP</p>
        ):null}
        {this.state.lastInvoice.fixawyPay!=='0.00'?(
          <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Temprory Wallet: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.fixawyPay}</span> EGP</p>
        ):null}

        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Total Amount After Discount: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.collectedAmount}</span> EGP</p>

        <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Paid Amount From Customer: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.paidAmount}</span> EGP</p>

        {this.state.lastInvoice.fixingTime!=='0.00'?(
          <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Hours Implementation: <span style={{fontWeight:'bold'}}>{this.state.lastInvoice.fixingTime}</span> min</p>
        ):null}
      </div>
    )
  }

}
