import React, { Component } from 'react'
import { Button,Col, Row, Table, CardHeader, Badge, Modal, ModalBody, ModalHeader, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown ,module ,Pagination,PaginationItem,PaginationLink,paginationInfo} from 'reactstrap'
import Avatar from './../../assets/img/brand/download.png'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
// import MakeOrder from './MakeOrder'
import Rating from 'react-rating'

class FeedBackHistory extends Component {

 constructor(props) {
   super(props)
   this.state = {
     showModal:true,
     modalFeedBack:false,
     criteria:[],
     note:'',
     rate:'',
     name:'',
     value:'',
     item:'',
     feedbackCriteria:[],
     history:[],
     loading:false,
     paginationInfo:[],
     paginationLinks:[],
     nextPages:[],
     previousPages:[],
     currentPage:1,

   }
   // this.onChangeCriteriaRate = this.onChangeCriteriaRate.bind(this)

 }

componentDidMount(){
  console.log(this.props.user.id);
  this.getAllCriteriaHistory()
}


getAllCriteriaHistory(){
  this.setState({loading:true})
  Services.getAllCriteriaHistory(this.props.user.id,this.state.currentPage,(res)=>{
    let arr = []
    let response = res.data.feedback
    for (var i = 0; i < response.length; i++) {
      for (var j = 0; j < response[i].criteria.length; j++) {
        response[i].criteria[j].actionMaker = response[i].actionMaker.enName
        arr.push({
          id : response[i].id,
          actionMakerName: response[i].actionMaker.enName,
          criterionName : response[i].criteria[j].enName,
          criterionPoints : response[i].criteria[j].points,
          criterionNotes : response[i].criteria[j].notes,
          createdAt : response[i].createdAt,
        })
      }
    }
    this.setState({
      loading:false,
      history: arr ,
      paginationInfo:res.paginationInfo,
      paginationLinks:res.paginationLinks,
      previousPages:res.paginationLinks.previousPages.reverse(),
      nextPages:res.paginationLinks.nextPages,
    })
  })
}


prevPage(item){
  let page = this.state.currentPage
  if (item == '') {
    this.setState({currentPage: page-1},()=>this.getAllCriteriaHistory())
  }else {
    this.setState({currentPage: item},()=>this.getAllCriteriaHistory())
  }
}
nextPage(item){
  let page = this.state.currentPage
  if (item == '') {
    this.setState({currentPage: page+1},()=>this.getAllCriteriaHistory())
  }else {
    this.setState({currentPage: item},()=>this.getAllCriteriaHistory())
  }
}




renderPagination(){
  return(
    <nav>
      <Row className='pull-right'>
        <Pagination style={{justifyContent:'flex-end'}}>
          {/* previous link*/}
          <PaginationItem>
            <PaginationLink previous tag="button" onClick={()=>this.state.paginationInfo.prevPageUrl!=null?this.prevPage(''):null}/>
          </PaginationItem>

          {/* previousPages */}
          {this.state.previousPages.map((item, index)=>(
            <PaginationItem key={index}>
              <PaginationLink tag="button" onClick={()=>this.prevPage(item)}>
                {item}
              </PaginationLink>
            </PaginationItem>
          ))}

          {/* currentPage */}
          <PaginationItem active>
            <PaginationLink style={{backgroundColor:'#4dbd74',color:'black',borderColor:'#4dbd74'}} tag="button">
              {this.state.paginationInfo.currentPage}
            </PaginationLink>
          </PaginationItem>


          {/* nextPages */}
          {this.state.nextPages.map((item, index)=>(
            <PaginationItem key={index}>
              <PaginationLink tag="button" onClick={()=>this.nextPage(item)}>
                {item}
              </PaginationLink>
            </PaginationItem>
          ))}

          {/* nextPageUrl */}
          <PaginationItem>
            <PaginationLink style={{color:'#4dbd74',}} next tag="button" onClick={()=>this.state.paginationInfo.nextPageUrl!=null?this.nextPage(''):null} />
          </PaginationItem>
        </Pagination>
      </Row>
    </nav>
  )
}







 renderHistoryOfCriteria(){
   return (
      <div>
               <Table hover bordered striped responsive size="md">
                 <thead>

                   <tr>
                     <th style={{textAlign:'center',width:'5%'}}>Feedback ID</th>
                     <th style={{textAlign:'center',width:'20%'}}>Name</th>
                     <th style={{textAlign:'center',width:'20%'}}>Criteria Name</th>
                     <th style={{textAlign:'center',width:'5%'}}>Scale Number</th>
                     <th style={{textAlign:'center',width:'30%'}}>Note</th>
                     <th style={{textAlign:'center',width:'20%'}}>Date</th>
                   </tr>

                 </thead>
                 <tbody>


                   {this.state.history.length!=0 && this.state.history.map((item,index)=>{
                       console.log(item);
                       return(
                       <tr key={index}>
                         <td style={{textAlign:'center',width:'5%'}}>{item.id}</td>
                         <td style={{textAlign:'center',width:'20%'}}>{item.actionMakerName}</td>
                         <td style={{textAlign:'center',width:'20%'}}>{item.criterionName}</td>
                         <td style={{textAlign:'center',width:'5%'}}>{item.criterionPoints}</td>
                         <td style={{textAlign:'center',width:'30%'}}>{item.criterionNotes}</td>
                         <td style={{textAlign:'center',width:'20%'}}>{item.createdAt}</td>
                       </tr>
                     )
                   })
                 }


                 </tbody>
               </Table>
               {this.renderPagination()}
             </div>
   )
 }



 render(){
   return (

       <Modal fade={false} isOpen={this.state.showModal} toggle={()=>this.props.closeFeedBackHistoryModal()} className={this.props.className}>
         <ModalHeader toggle={()=>this.props.closeFeedBackHistoryModal()}>{this.props.user.name}</ModalHeader>
         <ModalBody>
        {this.renderHistoryOfCriteria()}
       </ModalBody>
      </Modal>
   )
 }
}

export default withRouter(FeedBackHistory);
