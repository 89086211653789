import React from 'react'
import {Modal} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay';
function LoadingSpinner(props) {
  return(
    <Modal fade={props.fade} isOpen={props.loading} className='loading'>
      <LoadingOverlay active={props.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
    </Modal>
  )
}
export default LoadingSpinner;
