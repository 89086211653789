import React,{Fragment} from 'react'
import {LoadingSpinner, ChangePosition} from './../../components'
import {Container,CardHeader,Card,Row,Col,Button,CardBody,Table,Input,Label} from 'reactstrap'
import Services from './../../services/Services'
import {FModal,PostHistory} from './../../components'
import Switch from "react-switch";
import ErrorMessage from './../../components/errors/ErrorMessage'
import Paginator from './../../components/Pagination/V2/Paginator'
import { NoResult } from '../../components'
import PostDetails from './PostDetails'
import {Global} from './../../../src/core/Global'
import { withRouter } from 'react-router-dom';


class ListPosts extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      postDetailModal:false,
      checked:false,
      password:'',
      changeStatusModal:false,
      modalErrorMessage:false,
      posts:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      noResult:false,
      loading:false,
      sectionTypeId:'',
      status:'',
      post:'',
      postIndex:'',
      postId:'',
      changePositionModal:false,
      singlePost:'',
      currentPage:1,
      postHistoryModal:false
    }
  }

  componentDidMount(){
    this.getPosts()
  }

  update(){
    this.getPosts()
  }

  update(){
    this.getPosts()
  }

  getPosts(){
    this.setState({loading:!this.state.loading})
    Services.getPosts(this.state.currentPage,(res)=>{
      if(res.message == 'forbidden'){
        this.props.history.push('/403')
      }else{
        this.setState({
          loading:false,
          posts:res.data.sections,
          noResult:!res.data.sections.length,
          previousPages:res.data.paginationLinks.previousPages,
          nextPages:res.data.paginationLinks.nextPages,
          currentPage:res.data.paginationInfo.currentPage
        })
      }
    })
  }

  handlePostDetails(item){
    console.log(item)
    this.setState({postDetailModal:!this.state.postDetailModal,singlePost:item})
  }

  handlePostHistory(item){
    this.setState({postHistoryModal:!this.state.postHistoryModal,singlePost:item})
  }
  changePage(page){
    this.setState({currentPage:page},()=>this.getPosts())
  }

  postStatusSwitcher(item , indexId) {
    if (item.statusId == 35) {
      this.setState({ status : 'Are you sure to turn off the post ?',post:item})
    }else{
      this.setState({ status : 'Are you sure to turn on the post ?',post:item})
    }
    this.togglePostStatus(item,indexId)
  }

  togglePostStatus(item,indexId){
    this.setState({
      modalErrorMessage:!this.state.modalErrorMessage,
      postId:item.id,
      postIndex:indexId
    })
  }


  changePostStatus(postId,postStatus){
    let status =''
    if(postStatus == 36){
      status = 'on'
    }else{
      status = 'off'
    }
    Services.changePostStatus(postId,status,(res)=>{
      let postsArr = this.state.posts
      if (postStatus == 36) {
        postsArr[this.state.postIndex].statusId = 35;
      }else{
        postsArr[this.state.postIndex].statusId = 36;
      }
      this.setState({
        posts: postsArr,
        modalErrorMessage:false,
        postIndex:'',
        postId:'',
      },()=>this.getPosts())
    })
  }



  handleErrorMessage =()=>{
    this.setState({modalErrorMessage:!this.state.modalErrorMessage})
  }

  changePostStatusAction =()=>{
    let postId = this.state.postId;
    let postsArr = this.state.posts;
    this.changePostStatus(postId,postsArr[this.state.postIndex].statusId)
  }

  titlePost(){
    return (
      <div>
        <p>{this.generatePostTitle()}</p>
      </div>
    )
  }

  generatePostTitle(){
    switch (this.state.singlePost.sectionTypeId) {
      case 1:
      return 'Promoted Service Group Details:'
      break;
      case 2:
      return 'Featured Service Group Details:'
      break;
      case 3:
      return 'Promo Code Details:'
      break;
      case 4:
      return 'Offer Details:'
      break;
      case 5:
      return 'General Details:'
      break;
      case 6:
      return 'Keyword Details:'
      break;
      default:
      break;
    }
  }

  changePosition(post){
    this.setState({changePositionModal:true, singlePost:post})
  }

  render(){
    return(
      <CardBody className='shadow'>
        {this.state.noResult == true ? <NoResult/> :
          <>
          {this.renderPosts()}
          <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
        </>}
        <FModal
          title={this.titlePost()}
          content={
            <PostDetails
              singlePost={this.state.singlePost}
            />
          }
          state={this.state.postDetailModal}
          onClose={()=>this.handlePostDetails(this.state.singlePost.sectionTypeId)}
          show={"none"}
        />

        <FModal
          title='Change Section Position'
          content={
            <ChangePosition
              ref={ref=> this.changePositionRef = ref}
              data={this.state.singlePost}
              update={()=>this.setState({changePositionModal:false},()=>this.getPosts())}
            />
          }
          state={this.state.changePositionModal}
          onClose={()=>this.setState({changePositionModal:false})}
          onSubmit={()=>this.changePositionRef.change()}
        />

        <FModal
          title={"View History"}
          content={
            <PostHistory
              ref={ref=> this.postHistoryRef = ref}
              data={this.state.singlePost}
            />
          }
          state={this.state.postHistoryModal}
          onClose={()=>this.handlePostHistory(this.state.singlePost)}
          show={"none"}
        />

        <ErrorMessage
          modalErrorMessage={this.state.modalErrorMessage}
          handleErrorMessage={this.handleErrorMessage}
          actionFunc={this.changePostStatusAction}
          close="No"
          action="Yes"
          errorMessage={this.state.status}
        />

        <LoadingSpinner loading={this.state.loading}/>
      </CardBody>

    )
  }

  renderPosts(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>Position</th>
            <th style={{textAlign:'center'}}>Type</th>
            <th style={{textAlign:'center'}}>Id</th>
            <th style={{textAlign:'center'}}>Post</th>
            <th style={{textAlign:'center'}}>Date</th>
            <th style={{textAlign:'center'}}>Change Postion</th>
            <th style={{textAlign:'center'}}>Status</th>
            <th style={{textAlign:'center'}}>History</th>

          </tr>
        </thead>
        <tbody>
          {this.state.posts.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>{ (this.state.currentPage-1 )*25 + index+1}</td>
              <td style={{textAlign:'center'}}>{item.type.type}</td>
              <td style={{textAlign:'center'}}>{item.id}</td>
              <td style={{textAlign:'center'}}>
                <span className="noWrap someSpace detailsPosts" onClick={()=>this.handlePostDetails(item)}>Details</span>
              </td>
              <td style={{textAlign:'center'}}>{item.createdAt}</td>
              <td style={{textAlign:'center'}}>
                <span className="noWrap someSpace detailsPosts" onClick={()=>this.changePosition(item)}>Change</span>
              </td>
              <td style={{textAlign:'center'}}>
                <Switch onChange={()=>this.postStatusSwitcher(item ,index)} checked={item.statusId == 35 ? true : false} />
              </td>
              <td style={{textAlign:'center'}}>
                <span className="noWrap someSpace detailsPosts" onClick={()=>this.handlePostHistory(item)}>View History</span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

}

export default withRouter(ListPosts)
