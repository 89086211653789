import React from 'react';
import Linkify from 'react-linkify';

class ImageMessage extends React.Component
{
  render(){
    return(<>
      <img alt={this.props.message.body?this.props.message.body:'  '} style={{width:'20%', display:'block', margin:'auto', cursor:'pointer'}} src={this.props.message.image} />
      {this.props.message.body?(<p className='display-linebreak' style={{wordWrap:'break-word'}}><Linkify>{this.props.message.body}</Linkify></p>):null}
    </>);
  }
}

export default ImageMessage;
