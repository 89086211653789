import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap'
import { isNumber, validateUrl } from './../../../core/helper'
import EquipmentsServices from './../../../services/equipments/EquipmentsServices'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import MultiSelect from "@kenshooui/react-multi-select"

export default class AddEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arName:'',
      enName:'',
      arModel:'',
      enModel:'',
      price:'',
      link:'',
      imageLink:'',
      imageFile:'',
      imagePreviewUrl:'',
      uploadImgOption:0,
      loading:false
    }
  }

  componentDidMount(){

  }

  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'arName':
      this.setState({arName: e.target.value})
      break
      case 'enName':
      this.setState({enName: e.target.value})
      break
      case 'arModel':
      this.setState({arModel: e.target.value})
      break
      case 'enModel':
      this.setState({enModel: e.target.value})
      break
      case 'price':
      if (isNumber(e.target.value)) {
        this.setState({price: e.target.value})
      }else {
        alert('Please, enter numbers only')
      }
      break
      case 'link':
      this.setState({link: e.target.value})
      break
      case 'imageLink':
      this.setState({imageLink: e.target.value, imagePreviewUrl:e.target.value})
      break
      default:
    }
  }

  onSelectImageFromPC = (e, handle) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        imageFile: file,
        imagePreviewUrl: reader.result
      })
    }
    reader.readAsDataURL(file)
  }


  add(){
    if (!this.state.arName.replace(/\s/g, '').length) {
      alert('NOTE, Arabic name is required')
      return
    }else if (this.state.price == '' && this.state.link == '') {
      alert('NOTE, Equipment must has price OR preview link')
      return
    }else if (this.state.imageLink == '' && this.state.imageFile == '') {
      alert('NOTE, Equipment must has image kindly upload one using below options')
      return
    }else {
      let data = {
        arName: this.state.arName,
      }
      if (this.state.price != '') data.price = this.state.price
      if (this.state.enName != '') data.enName = this.state.enName
      if (this.state.imageFile != '') data.image = this.state.imageFile

      if (this.state.arModel != '' && this.state.enModel == '') {
        alert('Please, enter English model')
        return
      }

      if (this.state.arModel == '' && this.state.enModel != '') {
        alert('Please, enter Arabic model')
        return
      }

      if (this.state.arModel != '' && this.state.enModel != '') {
        data.arModel = this.state.arModel
        data.enModel = this.state.enModel
      }

      if (this.state.arModel != '') data.arModel = this.state.arModel
      if (this.state.enModel != '') data.enModel = this.state.enModel

      if (this.state.link != ''){
        if (!this.state.link.includes('http') || !validateUrl(this.state.link)) {
          alert('Please, note link must contain http://... like https://www.Example.com')
          return
        }else if (validateUrl(this.state.link)) {
          data.previewLink = this.state.link
        }
      }
      if (this.state.imageLink != ''){
        if (validateUrl(this.state.imageLink)) {
          data.imageLink = this.state.imageLink
        }else {
          alert('Please, enter valid image link')
          return
        }
      }
      var body = new FormData()
      Object.keys(data).forEach((key) => {
        body.append(key, data[key])
      })
      this.setState({loading:true})
      EquipmentsServices.add(body, (res)=>{
        this.setState({loading:false},()=>this.props.update())
      })
    }
  }

  render(){
    return(
      <div className='orderActions'>
        <Row style={{padding:'0px 15px', marginBottom:20}}>
          <Col md="1" style={{textAlign:'left',marginTop:6}}><span className='titles'>Name: </span></Col>
          <Col md="4">
            <Input
              value={this.state.arName}
              onChange={(e)=>this.handleChangeInputs(e, 'arName')}
              placeholder="arabic name.."
            />
          </Col>
          <Col md="4">
            <Input
              value={this.state.enName}
              onChange={(e)=>this.handleChangeInputs(e, 'enName')}
              placeholder="english name.."
            />
          </Col>
        </Row>
        <Row style={{padding:'0px 15px', marginBottom:20}}>
          <Col md="1" style={{textAlign:'left',marginTop:6}}><span className='titles'>Model: </span></Col>
          <Col md="4">
            <Input
              value={this.state.arModel}
              onChange={(e)=>this.handleChangeInputs(e, 'arModel')}
              placeholder="arabic model.."
            />
          </Col>
          <Col md="4">
            <Input
              value={this.state.enModel}
              onChange={(e)=>this.handleChangeInputs(e, 'enModel')}
              placeholder="english model.."
            />
          </Col>
        </Row>

        <Row style={{padding:'0px 15px', marginBottom:20}}>
          <Col md="1" style={{textAlign:'left',marginTop:6}}><span className='titles'>Price: </span></Col>
          <Col md="4">
            <Input
              value={this.state.price}
              onChange={(e)=>this.handleChangeInputs(e, 'price')}
              placeholder="price.."
            />
          </Col>
        </Row>

        <Row style={{padding:'0px 15px', marginBottom:20}}>
          <Col md="1" style={{textAlign:'left',marginTop:6}}><span className='titles'>Link: </span></Col>
          <Col md="4">
            <Input
              value={this.state.link}
              onChange={(e)=>this.handleChangeInputs(e, 'link')}
              placeholder="preview link.."
            />
          </Col>
        </Row>

        <Row style={{padding:'0px 15px', marginBottom:20}} className='uploadImg'>
          <Col md="1" style={{textAlign:'left'}}><span className='titles'>Image: </span></Col>
          <Col md="4">
            <h6>You can upload image by those options</h6>
            <p onClick={()=>this.setState({uploadImgOption:1, imagePreviewUrl:'', imageFile:''})}>
              <i className={this.state.uploadImgOption==1?"fa fa-check-square-o":"fa fa-square-o"}></i> External link
            </p>
            <p onClick={()=>this.setState({uploadImgOption:2, imagePreviewUrl:'', imageLink:''})}>
              <i className={this.state.uploadImgOption==2?"fa fa-check-square-o":"fa fa-square-o"}></i> Upload from my PC
            </p>

            {this.state.uploadImgOption == 1 ?(
              this.renderExternalLink()
            ):this.state.uploadImgOption == 2 ?(
              this.renderFromMyPC()
            ):null}

          </Col>
          {this.state.imagePreviewUrl != '' ?(
            <Col md="4">
              <h6>Preview Image</h6>
              <img src={this.state.imagePreviewUrl} style={{height:300, width:'100%'}}/>
            </Col>
          ):null}

        </Row>


        <LoadingSpinner loading={this.state.loading} />
      </div>
    )
  }

  renderExternalLink(){
    return(
      <Input
        value={this.state.imageLink}
        onChange={(e)=>this.handleChangeInputs(e, 'imageLink')}
        placeholder="preview link.."
      />
    )
  }

  renderFromMyPC(){
    return(
      <input
        type="file"
        onChange={(e)=>this.onSelectImageFromPC(e, 'arName')}
      />
    )
  }

}
