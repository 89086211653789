import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, Button} from 'reactstrap'
import { validatePhoneNumber } from './../../../core/helper'
import EquipmentsServices from './../../../services/equipments/EquipmentsServices'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import MultiSelect from "@kenshooui/react-multi-select"
import { AddEquipment, NoResult, FModal } from './../../../components'

export default class Equipments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipments:[],
      selectedEquipments:[],
      selectedEquipmentsIds:[],
      loading:false,
      addEquipmentModal:false
    }
  }

  componentDidMount(){
    this.getEquipments()
  }

  onSelectedChanged(selectedItems) {
    this.setState({
      selectedEquipments:selectedItems,
      selectedEquipmentsIds:selectedItems.map(e=>e.id)
    })
  }

  getEquipments(){
    this.setState({loading:true})
    EquipmentsServices.get((res)=>{
      let arr = res.data.equipments
      let titles = []
      for (var i = 0; i < arr.length; i++) {
        let obj ={label:arr[i].arName, id: arr[i].id}
        titles.push(obj)
      }
      this.setState({loading:false, equipments:titles})
    })
  }

  assignToOrder(){
    console.log(this.props.order.orderId);
    if (this.state.selectedEquipmentsIds.length == 0) {
      alert('Please, select at least one equipment')
    }else {
      this.setState({loading:true})
      let data = {
        equipments:this.state.selectedEquipmentsIds
      }
      EquipmentsServices.assignToOrder(this.props.order.orderId, data, (res)=>{
        this.setState({loading:false,},()=>this.props.onClose())
      })
    }
  }




  render(){
    return(
      <div className='orderActions' style={{minHeight:500}}>
        {this.state.loading?(
          <LoadingSpinner loading={this.state.loading} />
        ):(
          this.state.equipments.length?(
            <div>
              <h5 style={{padding:'0px 15px'}}>Select or add new equipment</h5>
              <Row style={{padding:'0px 15px'}}>
                <Col md={8}>
                  <MultiSelect
                    items={this.state.equipments}
                    selectedItems={this.state.selectedEquipments}
                    onChange={(item)=>this.onSelectedChanged(item)}
                    withGrouping={true}
                    showSelectedItems={false}
                    showSelectAll={true}
                  />
                </Col>
                <Col>
                  <Button className='actionsBtn' onClick={()=>this.setState({addEquipmentModal:true})}>
                    <i className="fas fa-plus" style={{marginLeft:0,marginRight:5}}></i> Add New
                  </Button>
                </Col>
              </Row>
            </div>
          ):(
            <div>
              <Button className='actionsBtn' style={{marginBottom:15}} onClick={()=>this.setState({addEquipmentModal:true})}>
                <i className="fas fa-plus" style={{marginLeft:0,marginRight:5}}></i> Add New
              </Button>
              <NoResult />
            </div>

          )
        )}

        <FModal
          title='Add New Equipment'
          content={
            <AddEquipment
              ref={ref=> this.addEquipmentRef = ref}
              update={()=>this.setState({addEquipmentModal:false},()=>this.getEquipments())}
            />
          }
          state={this.state.addEquipmentModal}
          onClose={()=>this.setState({addEquipmentModal:false})}
          onSubmit={()=>this.addEquipmentRef.add()}
        />
      </div>
    )
  }
}
