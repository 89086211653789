import React from 'react'
import {Dropdown,DropdownMenu,DropdownItem,DropdownToggle,UncontrolledDropdown } from 'reactstrap'
class OutOfWorkContract extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      statuses : [
        {id : 0 , name : 'Choose'},
        {id : 1 , name : 'Fixers out of work contract'},
        {id : 2 , name : 'RequestedDates out of work contract'},
        {id : 3 , name : 'All'}
      ],
      statusesToggle:false,
      selectedStatus:'Out of work contract'
    }
  }

  componentDidMount(){
    this.statusesFilter()
  }

  toggleStatusesFilter(){
    this.setState({statusesToggle : !this.state.statusesToggle})
  }

  statusesFilter(){
    this.setState({statuses : this.state.statuses})
  }

  handleSelection(status){
    if (status.id == 0) {
      this.clearStatuses()
    }else {
      this.setState({selectedStatus:status.name})
    }
  }

  clearStatuses(){
    this.setState({selectedStatus:'Out of work contract'})
  }

  returnStatus(status){
    this.handleSelection(status)
    this.props.handlerStatuses(status)
  }


  render(){
    return (
      <UncontrolledDropdown  className="mb-3"  isOpen={this.state.statusesToggle} toggle={()=>this.toggleStatusesFilter()} >
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}>
          <i className='fas fa-thermometer-quarter' style={{marginRight:10}} />{this.state.selectedStatus}
        </DropdownToggle>
        <DropdownMenu >
          {this.state.statuses.map((status,index)=>(
            <DropdownItem key={index} onClick={()=>this.returnStatus(status)}>{status.name}</DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
}

export default OutOfWorkContract
