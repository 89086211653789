import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'


export default class PermissionsService {

  
  static getRoles(callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/roles`).then(callBack)
  }

  static getPermission(roleId,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/permissions?roleId=${roleId}`).then(callBack)
  }

  static getPermissions(callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/permissions`).then(callBack)
  }

  static addRoles(data,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/roles`, data).then(callBack)
  }

  static assignRolesToAgents(userId,data,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/users/${userId}/roles`, data).then(callBack)
  }

  static assignPermissionsToRole(roleId,data,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/roles/${roleId}/permissions`, data).then(callBack)
  }

  static addAssignPermissionToRole(roleId,permissionId,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/roles/${roleId}/permissions/${permissionId}/add`, {}).then(callBack)
  }

  static removeAssignPermissionToRole(roleId,permissionId,callBack){
    Adaptor.post(`${Global.baseURL}admin-panel/roles/${roleId}/permissions/${permissionId}/remove`, {}).then(callBack)
  }

}
