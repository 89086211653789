import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle,Input} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'
import {validateUrl} from './../../../core/helper'

export default class AddVideoLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
        link:'',
        lastLink:'',
        addVideLinkModal:false,
    }
  }

  componentDidMount(){

  }

  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'link':
      this.setState({link: e.target.value})
      break
      case 'noteLink':
      this.setState({noteLink: e.target.value})
      break
      case 'noteLinkTitle':

      this.setState({noteLinkTitle: e.target.value},()=>{
        if (this.state.noteLinkTitle.length == 0) {
          this.setState({noteLink: ''})
        }
      })
      break
      default:
    }
  }

  addVideoLink(){
    if (!this.state.link) {
      alert('Please, enter link')
    }else if (!validateUrl(this.state.link) || !this.state.link.includes('http')) {
      alert('Please, note link must contain http://... like https://www.Example.com')
    }else {
      this.setState({loading:true})
      let data = {
        link : this.state.link
      }
      Services.addVideoLink(this.props.order.orderId, data, (res)=>{
        this.setState({loading: false, addVideoLinkModal:false, },()=>this.props.reload())
      })
    }
  }


  openAddVideoLinkModal(){
    this.setState({addVideLinkModal:true, loading:true})
    Services.getVideoLink(this.props.order.orderId, (res)=>{
      this.setState({loading: false, link: res.data.link?res.data.link:'' , lastLink: res.data.link?res.data.link:'' })
    })
  }


  render(){
    return(
      <div>
        <Row>
        <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Zoom Link: </span></Col>
        <Col md="5">
          <Input
            value={this.state.link}
            onChange={(e)=>this.handleChangeInputs(e, 'link')}
            placeholder="Enter link"
            autoComplete="current-password"
          />
        </Col>
      </Row>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
