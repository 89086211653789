import React from 'react'
import { Col, Container, Row , Table , Button} from 'reactstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { CategoryFilter , CityFilter, LoadingSpinner } from '../../components'
import Services from '../../services/Services';
import TasksService from './../../services/tasksServices/TasksService'
import { confirmAlert } from 'react-confirm-alert';
class TasksCalculator extends React.Component
{
   constructor(props){
      super(props);
      this.state = {
          loading:false,
          selectedCityId:'',
          selectedCategoryId:'',
          tasks:[],
          selectedTasks:[],
          cost:'',
      };
   }

   cityHandler(city){
     if(isNaN(city.id)){
       this.setState({selectedCityId:''});
     }else{
       this.setState({selectedCityId:city.id},()=>this.getTasksCost());
     }
   }

   categoryHandler(category){
     if(isNaN(category.id)){
       this.setState({selectedCategoryId:'',tasks:[]});
     }else{
       this.setState({selectedCategoryId:category.id,tasks:[]},()=>this.getTasks());
     }
   }

   selectTask(task){
      if(!this.state.selectedTasks.isAlreadyIncluded(task)){
        let addedTask = {
          id:task.id,
          description:task.description,
          count:1,
        }
        this.setState((prevState) => ({selectedTasks:[addedTask,...prevState.selectedTasks]}),()=>this.getTasksCost())
      }else{
        alert('This Task Is Already Selected')
      }
   }

   removeFromCard(task){
      this.setState((prevState) => ({
        selectedTasks:prevState.selectedTasks.filter( t => t.id != task.id )
      }),()=>this.getTasksCost())
   }

   increaseTaskCount(task){
     task.count++;
     this.forceUpdate();
     this.getTasksCost()
   }

   decreaseTaskCount(task){
     if(task.count != 1){
       task.count--;
       this.forceUpdate();
     }else{
      confirmAlert({
        title: 'Fixawy',
        message: `Are you sure to delete ${task.description}`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.removeFromCard(task)
          },
          {
            label: 'No',
            onClick: () => console.log('cancel')
          }
        ]
      })
     }
     this.getTasksCost()
   }

  getTasks( page = 1 ){
    this.setState({loading:true})
    TasksService.get(page,{categoryId:this.state.selectedCategoryId},(res)=>{
      this.setState((prevState)=>({
        tasks:[...prevState.tasks,...res.data.tasks],
        loading:!!res.data.paginationLinks.nextPages.length
      }),res.data.paginationLinks.nextPages.length?()=>this.getTasks(page+1):null)
    })
  }

  getTasksCost(){
    if((!this.state.selectedTasks.length)||this.state.selectedCityId==''){
       this.setState({cost:''})
       return;
    }
    this.setState({loading:true})
    TasksService.getCost(this.state.selectedCityId,this.state.selectedTasks,(res)=>{
      this.setState({cost:res.cost,loading:false})
    })
  }

   render(){
     return (<>
      <div className="app align-items-center">
        <Container>
          <Row style={{paddingLeft:10}}>
              <Col md='4'>
                  <CityFilter handler={(city)=>this.cityHandler(city)} ref={(ref)=>this.cityRef=ref}/>
              </Col>
              <Col md='4'>
                  <CategoryFilter handler={(category)=>this.categoryHandler(category)} ref={(ref)=>this.categoryRef=ref} />
              </Col>
          </Row>
          <Row>
              <Col md='8'>
                {this.state.selectedTasks.length !== 0?(
                  <div>
                    <p className='titles'>Selected Items</p>
                    <Table hover bordered striped responsive size="md">
                      <thead>
                        <tr>
                          <th style={{textAlign:'center',width:'50%'}}>Item</th>
                          <th style={{textAlign:'center'}}>Count</th>
                          <th style={{textAlign:'center'}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selectedTasks.map((task, index)=>(
                          <tr key={index}>
                            <td style={{textAlign:'center'}}>{task.description}</td>
                            <td style={{textAlign:'center'}}>
                              <div className='cardItems'>
                                <i className='fas fa-minus-circle' onClick={()=>this.decreaseTaskCount(task)}/>
                                <span>{task.count}</span>
                                <i className='fas fa-plus-circle' onClick={()=>this.increaseTaskCount(task)}/>
                              </div>
                            </td>
                            <td style={{textAlign:'center'}}><Button color="danger" onClick={()=>this.removeFromCard(task)}>Delete</Button></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ):null}
              </Col>
              {this.state.cost?(
              <Col md="4" style={{fontSize:'24px'}}>
                <h5>Estimated Price</h5>
                <span>From <strong>{ Math.round(this.state.cost*10) / 10 }</strong></span>
                <span> EGP</span>
              </Col>
              ):null}
            </Row>
            <Row>
                <Col md='8'>
                  {this.state.tasks.length?(
                    <Accordion allowZeroExpanded={true}>
                      {this.state.tasks.map((task, index)=>(<p className='moreItem' key={index} onClick={()=>this.selectTask(task)}>{task.description}</p>))}
                    </Accordion>
                  ):(<div style={{textAlign:'center',fontWeight:'bold'}}>
                    Sorry, There is No Tasks Change The Selected Category To get The Tasks
                  </div>)}
                </Col>
            </Row>
        </Container>
      </div>
      <LoadingSpinner loading={this.state.loading} />
    </>);
   }
}
export default TasksCalculator;
