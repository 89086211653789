import {Global} from './../../../core/Global'
import Adaptor from './../../Adaptor'

export default class FixersProfileService {

  static get(page,callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/shared-profiles/fixers?page=${page}`).then(callBack)
  }

}
