import { Button , Table } from 'reactstrap';
import React from 'react'
import FixersService from '../../../services/fixerServices/FixersServices';
import Services from '../../../services/Services';
import LoadingSpinner from '../../LoadingSpinner';
import NoResult from '../../NoResult';
import SingleWorkContract from '../../../views/workContracts/SingleWorkContract';

class ViewValidFixerWorkContracts extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      noResult:false,
      loading:false,
      fixerWorkContracts:[],
      showSingleWorkContract:false,
      selectedWorkContract:null
    }
  }

  componentDidMount(){
    this.getValidFixerWorkContract();
  }

  getValidFixerWorkContract(){
    this.setState({loading:true})
    FixersService.getValidFixerWorkContracts(this.props.singleFixer.id,(res) => {
      this.setState({
        fixerWorkContracts:res.data.reservationsPerFixer,
        loading:false,
        noResult:!res.data.reservationsPerFixer.length
      })
    })
  }


  render(){
    return(<>
      {this.state.showSingleWorkContract?(
        <SingleWorkContract
        workContract={this.state.selectedWorkContract}
        onClose={()=>this.setState({showSingleWorkContract:false,selectedWorkContract:null})}
        />
      ):null}
      <div>
        {this.state.noResult?(<NoResult />):(
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center',width:'5%'}}>ID</th>
              <th style={{textAlign:'center',width:'5%'}}>Contract ID</th>
              <th style={{textAlign:'center',width:'15%'}}>StartDate</th>
              <th style={{textAlign:'center',width:'15%'}}>EndDate</th>
              <th style={{textAlign:'center',width:'10%'}}>Package</th>
            </tr>
          </thead>
          {this.state.fixerWorkContracts.map( (fwc , i) => (
            <tr key={i}>
                <td style={{textAlign:'center',width:'5%'}}>{fwc.id}</td>
                <td style={{textAlign:'center',width:'5%'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.setState({showSingleWorkContract:true,selectedWorkContract:fwc.workContract})}>{fwc.workContract.id}</Button>
                </td>
                <td style={{textAlign:'center',width:'15%'}}><div style={{textAlign:'center',width:90}}>{fwc.workContract.startDate}</div></td>
                <td style={{textAlign:'center',width:'15%'}}><div style={{textAlign:'center',width:90}}>{fwc.workContract.endDate}</div></td>
                <td style={{textAlign:'center',width:'10%'}}>{fwc.workContract.package.arName}</td>
            </tr>
          ))}
        </Table>
        )}
      </div>
      <LoadingSpinner loading={this.state.loading} />
    </>)
  }
}

export default ViewValidFixerWorkContracts;
