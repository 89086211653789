import React, { Component } from 'react'
import { Col, Row, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import Services from './../../../services/Services'
import { LoadingSpinner } from './../../../components'

export default class Inprogress extends Component {
  constructor(props) {
    super(props);
    this.state = {
       
    }
  }

  componentDidMount(){

  }

  


  inprogress(){
    console.log(JSON.parse(localStorage.getItem('userData')).accessToken);
    let reqData = {}
    reqData.statusId = 3
    // reqData.visitTime = this.state.singleOrder.visitTime.split(' ')[0] +':00'
    // console.log(reqData);
    // return true
    this.updateOrderTimingAction(reqData)
  }


  updateOrderTimingAction(reqData){
    this.setState({loading:true})
    Services.updateOrderTimingDetails(this.props.order.orderId, reqData, (res)=>{
      this.setState({loading:false,selectedPeriod:'Choose',requestedDate:'',selectedTime:'Choose',mainModal:false,visitTime:false, visitTimeOrder:'',selectPeriodId:'',requestedDate:'',modalInprogress:false},()=>this.props.reload())
    })
  }


  render(){
    return(
      <div>
        <p style={{marginLeft:20}}> Are you sure change status this order to in-progress? </p>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }
}
