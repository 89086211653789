import React from 'react';
import {Row,ModalBody,Table,Pagination,PaginationItem,PaginationLink,Modal,Col,Input,Label} from 'reactstrap'
import Avatar from './../../../assets/img/brand/download.png'
import Services from './../../../services/Services'
import {LoadingSpinner} from './../../../components';
export default class AddNoteInquiry extends React.Component{
    constructor(props){
        super(props);
        this.state={
            loading:false,
            note:''
        }
    }
    componentDidMount(){
      console.log(this.props.inquiryId)
    }
    handleChangeInputs(e){
    this.setState({note:e.target.value})
    }
   addNote(){
     if(this.state.note.trim() == "" ){
       alert("Please,enter note details")
     }else{
      this.setState({loading:!this.state.loading})
      let data = {
        note:this.state.note
      }
      Services.addNoteInquiry(data,this.props.inquiryId,(res)=>{
        this.setState({loading:false},()=>this.props.reload(this.props.inquiryId))
      })
     }

   }
    render(){
        return(
        <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <Row>
          <Col md="10">
            <Input
              type="textarea"
              value={this.state.note}
              onChange={(e)=>this.handleChangeInputs(e)}
              placeholder="Enter note details"
              className="addNote"
              maxLength={5000}
            />
          </Col>
        </Row>
        <LoadingSpinner loading={this.state.loading} fade={false} isOpen={this.state.loading}/>
        </div>

        )
    }
}
