import React, { Component } from 'react'
import { Button, Card, CardBody, CardGroup, Col, Container, Input, Row ,Table ,CardHeader ,Modal ,ModalBody ,ModalFooter ,ModalHeader  } from 'reactstrap';

import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'


class Conditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAddCondition: false,
      description:'',
      typeId:1,
      conditions:[],
      loading:true
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    this.getConditions()
  }



  toggleAddCondition(){
    if (this.state.modalAddCondition) {
      this.setState({modalAddCondition : !this.state.modalAddCondition , description:''})
    }else {
      this.setState({modalAddCondition : !this.state.modalAddCondition})
    }
  }



  onChangeAddConditions(e){
    this.setState({description : e.target.value})
  }


  getConditions(){
    this.setState({loading:true})
    Services.getConditions(this.state.typeId,(res)=>{
      this.setState({conditions : res.data.conditionsAndTerms,loading:false})
    })
  }


  addConditions(){
    if (this.state.description == "" || this.state.description.trim() == "") {
      alert('please add condition')
    }else {
      let data = {
        typeId : this.state.typeId,
        description : this.state.description
      }
      console.log(data);
      this.setState({loading:true})
      Services.addConditions(data,(res)=>{
        if (res.message == 'success') {
          console.log(res)
          this.setState({description:''},()=>this.toggleAddCondition())
          this.getConditions()
        }else {
          alert('please add condition')
        }
        this.setState({loading:false})
      })
    }
  }



  render() {
    return (
      <div className="app align-items-center">
        <Container>
          <Card>
            <CardHeader className='shadow'>
              {this.renderHeaderAddCondition()}
            </CardHeader>

            <CardBody>
              {this.renderViewConditions()}
              {this.renderViewModalAddCondition()}
            </CardBody>

            {/* loading spinner */}
            <Modal fade={false} isOpen={this.state.loading} className='loading'>
              <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
            </Modal>
          </Card>
        </Container>
      </div>
    )
  }



  renderHeaderAddCondition(){
    return (
      <Row>
        <Col md="4" style={{display:'flex',alignItems:'center'}}>
          <i className="fa fa-align-justify" ></i>
          <span style={{marginLeft:6}}>Conditions</span>
        </Col>
        <Button className='actionsBtn' style={{marginTop:1,marginBottom:-1, marginLeft:'auto'}} onClick={()=>this.toggleAddCondition()}><i className='fa fa-plus-square' style={{marginRight:8}}/>  Add Condition</Button>
      </Row>
    )
  }



  renderViewConditions(){
    return (
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          {this.state.conditions.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center',width:'7%'}}>{item.id}</td>
              <td style={{textAlign:'center',padding:'17px 12px'}}>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }


  renderViewModalAddCondition(){
    return(
      <Modal fade={false} isOpen={this.state.modalAddCondition} toggle={()=>this.toggleAddCondition()} className={this.props.className}>
        <ModalHeader toggle={()=>this.toggleAddCondition()}>Add Condition</ModalHeader>
        <ModalBody>
          <div style={{width:'70%',margin:'auto'}}>
            <Input
              style={{width:'100%', color:'#000'}}
              value={this.state.description}
              onChange={(e)=>this.onChangeAddConditions(e)}
              type="textarea"
              placeholder="Enter condition content.."
              maxLength={1000}
              className="textareaconditions"
              rows={5}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className='actionsBtn' onClick={()=>this.toggleAddCondition()}>Close</Button>
          <Button className='actionsBtn' onClick={()=>this.addConditions()}>Add</Button>
        </ModalFooter>
      </Modal>
    )
  }



}

export default withRouter(Conditions);
