import React from 'react'
import {Button} from 'reactstrap'
class FixawyButton extends React.Component{
    render(){
        return(
            <Button
              className='noWrap someSpace'
              style={{background:'#feb600', color:'#000', borderColor:'#feb600'}}
                disabled={this.props.disabled}
                onClick={this.props.onClick}
                >{this.props.children}</Button>
        )
    }
}
export default FixawyButton;
