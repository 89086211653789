import Adaptor from './../Adaptor'


export default class OrderStatusReportServices {

  static getReport(api, callBack){
    Adaptor.get(api).then(callBack)
  }

}
