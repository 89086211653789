import React from 'react';
import {Row,ModalBody,Table,Pagination,PaginationItem,PaginationLink,Modal} from 'reactstrap'
import Avatar from './../../../assets/img/brand/download.png'
import Services from './../../../services/Services'
import LoadingOverlay from 'react-loading-overlay';
import {LoadingSpinner} from './../../../components'
export default class HistoryInquiry extends React.Component{
  constructor(props){
    super(props);
    this.state={
      noResultInquiries:true,
      loading:false,
      history:[]
    }
  }
  componentDidMount(){
    this.getInquiryHistory()
  }



  getInquiryHistory(){
    this.setState({loading:!this.state.loading})
    Services.getInquiryHistory(this.props.inquiryId,(res)=>{
      this.setState({history:res.data.inquiryActions,loading:false,})
    })
  }



  render(){
    return(
      <div>
        {this.state.noResultInquiries ?
          <div>
            {this.renderHistoryModel()}
          </div>
          :
          <div>
            <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%', textAlign:'center', width:'100%'}}></i>
            <h2 style={{ textAlign:'center'}}>There's no result</h2>
          </div>
        }
        <LoadingSpinner loading={this.state.loading} fade={false} isOpen={this.state.loading}/>
      </div>
    )
  }

  renderHistoryModel(){
    return (
      <ModalBody>
        <Table hover bordered striped responsive size="md">
          <thead>
            <tr>
              <th style={{textAlign:'center',}}>Name</th>
              <th style={{textAlign:'center'}}>Actions</th>
              <th style={{textAlign:'center'}}>Description</th>
              <th style={{textAlign:'center'}}>Date and Time</th>
            </tr>
          </thead>

          <tbody>
            {this.state.history.map((item, index)=>(
              <tr key={index}>
                <td style={{textAlign:'center'}}>{item.actionMaker.name}</td>
                <td style={{textAlign:'center'}}>{item.action.action}</td>
                <td style={{textAlign:'center'}}>{item.description}</td>
                <td style={{textAlign:'center'}}>{item.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
    )
  }



}
