import {Global} from './../../core/Global'
import Adaptor from './../Adaptor'

export default class CategoriesService {

  static getCategories(callBack){
    Adaptor.get(`${Global.baseURL}admin-panel/categories/all`).then(callBack)
  }

  static editDailyPrice(data,categoryId,callBack){
    Adaptor.put(`${Global.baseURL}admin-panel/categories/${categoryId}`,data).then(callBack)
  }

}
