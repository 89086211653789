import React from 'react'
import { Modal,ModalBody,ModalFooter,ModalHeader,Col,Row,Input,InputGroup, Button,DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'
import { Toggler, LoadingSpinner } from './../../components'
import Services from './../../services/Services'
class ServiceGroupModal extends React.Component{
  constructor(props){
    super(props)
    this.state={
      loading:false,
      image:null,
      file:null,
      serviceGroup:this.props.serviceGroup,
    }
  }
  componentDidMount(){
    if (this.props.serviceGroup.featuredImage != null) {
      this.submit()
    }
  }

  addFeatureSG(data){
    this.setState({loading:true})
    Services.updateSeviceGroup(this.state.serviceGroup.id,data,res => {
      this.setState({loading:false},()=>{this.clear();this.props.closeHandler()})
    })
  }
  handleImageChange(e){
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        image: reader.result
      });
    }
    reader.readAsDataURL(file)
  }
  clearImg(){
    this.setState({
      image:null,
      file:null,
    })
  }
  fakeUpload(){
    document.getElementById('image-uploader').click();
  }
  clear(){
    this.setState({image:null,file:null,secret:'',serviceGroup:null})
  }
  validateRequest(){
    if (this.state.serviceGroup.featuredImage == null && this.state.file == null) {
      alert('please upload image first')
      return false
    }
    return true
  }
  submit(){
    if(this.validateRequest()){
      let data = new FormData();
      if (this.state.file != null) {
        data.append('image',this.state.file)
      }
      data.append('status',this.state.serviceGroup.featured?0:1)
      this.addFeatureSG(data)
    }
  }
  render(){
    return(
      <>
      <Modal fade={true} isOpen={this.props.isOpen} toggle={()=>{this.clear();this.props.toggleHandler()}}>
        <ModalHeader toggle={()=>{this.clear();this.props.toggleHandler()}}>Toggle Featured Service Group</ModalHeader>
        <ModalBody>
          {this.state.serviceGroup.featuredImage==null?(
          <div style={{padding:'20px'}}>
            <label style={{textAlign:'center'}}><strong>Add Image</strong></label>
            <InputGroup>
              <Button className='actionsBtn' onClick={()=>this.fakeUpload()}>Upload Image</Button>
              <Input id='image-uploader' type='file' accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>this.handleImageChange(e)}/>
              {this.state.image?(
              <>
              <Row>
                <Col md="4" style={{marginBottom:8,padding:'15px'}}>
                    <div id='image-eraser' className='shadow' style={{width:'100%'}}>
                      <Button onClick={()=>this.clearImg()} color="danger">
                            <img src={this.state.image} width='100%' height='100%'/>
                      </Button>
                      <span onClick={()=>this.clearImg()} className='icon-close' id='image-eraser-icon'></span>
                    </div>
                </Col>
              </Row>
              </>
              ):null}
            </InputGroup>
          </div>
          ):null}
        </ModalBody>
        <ModalFooter>
              <Button className='actionsBtn' onClick={()=>{this.clear();this.props.closeHandler()}}>Close</Button>
              <Button className='actionsBtn' onClick={()=>this.submit()}>Add</Button>
        </ModalFooter>
      </Modal>
      <LoadingSpinner loading={this.state.loading} />
      </>
    )
  }
}
export default ServiceGroupModal;
