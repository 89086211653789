import React, { Component } from 'react'
import { Button, Col, Row ,Table ,CardHeader ,Badge ,Modal ,ModalBody ,ModalHeader ,ModalFooter  ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Input ,Form,FormGroup,Label} from 'reactstrap'
import Src from './../../assets/img/brand/download.png'
import ImageUploader from 'react-images-upload';
import MultiSelect from "@kenshooui/react-multi-select"
import "@kenshooui/react-multi-select/dist/style.css"
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import Services from './../../services/Services'
import { LoadingSpinner } from './../../components'



export default class AssignAdminComplaint extends Component {
  constructor(props){
    super(props);
    this.state = {
      admins:[],
      selectedAdminId:'',
      selectedAdmin:'Choose',
      loading:false

    }

  }

  componentDidMount(){
    this.getAdmins()
  }


  getAdmins(){
    this.setState({loading : true})
    Services.getAgents((res)=>{
      this.setState({admins : res.data.admins,loading:false})
    })
  }

  assignComplaintToAdmin(){
    if(this.state.selectedAdminId == ''){
      alert('Please,select admin')
    }else{
      this.setState({loading:true})
      let data = {
        adminId:this.state.selectedAdminId
      }
      Services.assignComplaintToAdmin(this.props.complaintId,data,(res)=>{
        this.setState({loading:false,selectedAdminId:'',selectedAdmin:''},()=>this.props.close())
      })
    }
  }



  handleSelectedAdmin(item){
    console.log(item.id)
    this.setState({
      selectedAdmin:item.enName,
      selectedAdminId:item.id
    })
  }



  render(){
    return(
      <div style={{width:'90%',marginLeft:'auto',marginRight:'auto'}}>
        <div>
          <p style={{fontWeight:'bold'}}>To assign this complaint please choose admin:</p>
          <Row>
            <Col md="3"><p>Admins{' '}: </p></Col>
            <Col md="4">
              <UncontrolledDropdown  className="mb-3">
                <DropdownToggle caret style={{width:'100%',backgroundColor:'white',borderColor:'#777777'}}>{this.state.selectedAdmin}</DropdownToggle>
                <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
                  {this.state.admins.map((item, index)=>(
                    <DropdownItem key={index} onClick={()=>this.handleSelectedAdmin(item)}>{item.enName}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <p style={{color:'red'}}>{this.state.error}</p>
            </Col>
          </Row>
        </div>
        <LoadingSpinner loading={this.state.loading}/>
      </div>
    )
  }

}
