import React from 'react'
import './../../../assets/style/chat/AudioRecorder.scss'
class AudioRecorder extends React.Component
{
  constructor(props){
    super(props);
    this.state={
      isRecoreding:false,
      count:1,
    }

    this.audioChunks = [];
    this.mediaRecorder = null;
    this.audioMessageIntervalHandler = null;
  }


  cancelAudioRecoreding(){
    this.mediaRecorder.addEventListener("stop",()=>{
      this.audioChunks = [];
      clearInterval(this.audioMessageIntervalHandler);
      this.setState({isRecoreding:false,count:1})
    })
    this.mediaRecorder.stop();
    this.mediaRecorder = null;
    this.props.onCancel();
  }

  async startRecored(){
    const mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
    this.mediaRecorder = new MediaRecorder(mediaStream);
    this.mediaRecorder.addEventListener("dataavailable", e => {
      this.audioChunks.push(e.data)
    });
    this.mediaRecorder.start();
    this.audioMessageIntervalHandler = setInterval( () => {
        if (this.state.count == 180 ) {
           this.mediaRecorder.pause();
           clearInterval(this.audioMessageIntervalHandler);
        }else{
          this.setState( (prevState) => ({count:prevState.count+1}))
        }
      }, 1000 );
  }

  onRecored(){
    this.setState({isRecoreding:true},() => this.startRecored())
    this.props.onRecored();
  }

  audioRecorderded(){
    this.mediaRecorder.addEventListener("stop" , () => {
      const soundBlob = new Blob(this.audioChunks , {type: "audio/wav"});
      const soundPreview = URL.createObjectURL(soundBlob);
      this.audioChunks = [] ;
      clearInterval(this.audioMessageIntervalHandler);
      this.setState(
        {isRecoreding:false,count:1},
        () => this.props.onMessageRecorded({soundFile:soundBlob,soundPreview:soundPreview})
      )
    })
    this.mediaRecorder.stop()
    this.mediaRecorder = null;
  }

  buitfyCounterTime(){
    let min = Math.floor(this.state.count / 60 );
    let sec = this.state.count % 60 ;

    return `${min} : ${sec}`;

  }

  render(){
    return (<>
      {this.state.isRecoreding?(<>
        <div className='chat_audio-recorder_cancel-btn'>
          <i style={{color:'red',cursor:'pointer'}} onClick={()=>this.cancelAudioRecoreding()} className="fa fa-times fa-2x" aria-hidden="true"></i>
        </div>
        <div className='chat_audio-recorder_counter' >
          <div className="spinner-grow text-danger">
            <span className="visually-hidden" />
          </div>
          {this.buitfyCounterTime()}
        </div>
        <div  className='chat_audio-recorder_send-btn'>
          <i style={{color:'green',cursor:'pointer'}} onClick={()=>this.audioRecorderded()} className="fa fa-check fa-2x" aria-hidden="true"></i>
        </div>
      </>):(
      <div className='chat_audio-recorder_mic' >
          <i onClick={()=>this.onRecored()} style={{cursor:'pointer'}} className="fa fa-microphone fa-2x" aria-hidden="true"></i>
      </div>
      )}
    </>);
  }
}

export default AudioRecorder;
