import React, { Component } from 'react'
import { Button,Col, Row, Table, CardHeader, Badge, Modal, ModalBody, ModalHeader, ModalFooter, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown ,module} from 'reactstrap'
import Avatar from './../../assets/img/brand/download.png'
import Services from './../../services/Services'
import { withRouter } from 'react-router-dom'
import {Global} from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay'
import MakeOrder from './MakeOrder'
import Rating from 'react-rating'
import {MapModal } from './../../components'
import ComplaintsServices from './../../services/customerServices/ComplaintsServices'

import FeedBack from './../feedback/FeedBack'
import FeedBackHistory from './../feedback/FeedBackHistory'
import { ChargeFixawyPay , FModal, CustomerHistory, HasEstimation } from './../../components'


class SingleCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      singleCustomer:'',
      smsTitle:'',
      pushTitle:'',
      pushBody:'',
      pushURL:'',
      reasons:[],
      blockReason:'Choose',
      blockReasonId:'',
      loading:false,
      mainModal: false,
      modalPush : false,
      modalSMS : false,
      modalunblock : false,
      modalBlock : false,
      renderMakeOrder:false,
      feedBackModal: false,
      singleCustomerFeedBack:'',
      feedBackHistoryModal:false,
      badgeColor:'',
      level:'',
      showMap:false,
      chargeFixawyPayModal:false,
      viewComplaints:false,
      complaints:[]

    }
    // this.onChangeRate = this.onChangeRate.bind(this)
    //this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount(){
    console.log('singleCustomer componentDidMount');
    this.getSingleCustomer(this.props.data)

  }

  componentWillReceiveProps(nextProps) {
    console.log('singleCustomer componentWillReceiveProps');
    // this.setState({renderMakeOrder:false})
    this.getSingleCustomer(nextProps.data)

  }


  handleChangeInputs = (e, handle) => {
    switch (handle) {
      case 'sms':
      this.setState({smsTitle: e.target.value})
      break
      case 'pushTitle':
      this.setState({pushTitle: e.target.value})
      break
      case 'pushBody':
      this.setState({pushBody: e.target.value})
      break
      case 'pushURL':
      this.setState({pushURL: e.target.value})
      break
      case 'note':
      this.setState({note: e.target.value})
      break
      default:
    }
  }

  getSingleCustomer(item){
    this.setState({loading:true})
    Services.getSingleCustomer(this.props.from == "request" ? item.customerId : item.id, (res)=>{
      this.setState({loading:false, singleCustomer:res.data.customer, mainModal:true,} , () => this.compareFeedBackCustomer())
    })
  }




  getReasons(){
    this.setState({loading:true})
    Services.getReasons('customer',(res)=>{
      this.setState({loading:false, reasons:res.data.reasons, mainModal:true, modalBlock:true})
    })
  }

  sendSMS(){
    if (this.state.smsTitle == '' || !this.state.smsTitle.replace(/\s/g, '').length) {
      alert('Please, enter SMS message content')
    }else {
      this.setState({loading:true})
      Services.sendSMS(this.state.smsTitle, this.state.singleCustomer.id, (res)=>{
        this.setState({loading:false, mainModal:true, modalSMS:false})
      })
    }
  }
  sendPush(){
    if (this.state.pushTitle == '' || !this.state.pushTitle.replace(/\s/g, '').length) {
      alert('Please, enter push title')
    }else if (this.state.pushBody == '' || !this.state.pushBody.replace(/\s/g, '').length) {
      alert('Please, enter push content')
    }else {
      let reqData = {
        title: this.state.pushTitle,
        body: this.state.pushBody
      }
      if (this.state.pushURL != '') {
        reqData.url = this.state.pushURL
      }
      this.setState({loading:true})
      Services.sendPush(reqData, this.state.singleCustomer.id, (res)=>{
        this.setState({loading:false, mainModal:true, modalPush:false})
      })
    }
  }


  blockCustomer(){
    if (this.state.blockReasonId == '') {
      alert('Please, select block reason')
    }else {
      this.setState({loading:true})
      Services.block( this.state.singleCustomer.id,this.state.blockReasonId, (res)=>{
        this.setState({loading:false, mainModal:false, modalBlock:false, blockReason:'Choose'},()=>this.props.reload())
      })
    }
  }

  unBlockCustomer(){
    this.setState({loading:true})
    Services.unBlock(this.state.singleCustomer.id, (res)=>{
      this.setState({loading:false, mainModal:false, modalunblock:false},()=>this.props.reload())
    })
  }


  handleSingleCustomer(item) {
    if (this.state.mainModal !== false) {
      this.setState({mainModal:!this.state.mainModal, singleOrder:'', orderImages:[], renderMakeOrder:false},()=>this.props.reload())
    }else {
      this.getSingleCustomer(item)
    }
  }





  handlePushModal(){
    this.setState({modalPush : !this.state.modalPush, renderMakeOrder:false,pushTitle:'',pushBody:'',pushURL:''})
  }


  // handleFeedBack(){
  //     this.setState({modalFeedBack : !this.state.modalFeedBack, renderMakeOrder:false,customerField:!this.state.customerField})
  // }
  handelSMSModal(){
    this.setState({modalSMS : !this.state.modalSMS, renderMakeOrder:false,smsTitle:''})
  }
  handleUnBlockModal(){
    this.setState({modalunblock : !this.state.modalunblock, renderMakeOrder:false})
  }
  handleBlockModal(){
    if (this.state.modalBlock !== false) {
      this.setState({modalBlock : !this.state.modalBlock, renderMakeOrder:false,blockReasonId:'',blockReason:'Choose'})
    }else {
      this.getReasons()
    }
  }

  handleBlockReason(item){
    this.setState({blockReason: item.enName, blockReasonId:item.id, renderMakeOrder:false})
  }

  startChat(){
    this.setState({loading:true})
    let data = {
      userId:this.state.singleCustomer.id,
    }
    Services.startChat(data,(res)=>{
      console.log(res);
      // return
      this.setState({loading:false},()=>this.props.history.push(`/chat/customer?userId=${data.userId}`))
    })
  }


// feedbackCustomer(){
//   if (this.state.showModal) {
//      return <FeedBackCustomer/>
//    } else {
//      return null;
//    }
// }


// handleClick(event) {
//    this.setState({showModal: !this.state.showModal});
//  }


 // addCustomerFeedBack(){
 //   console.log('customer')
 // }



 closeFeedBackModal = () => {
   this.setState({feedBackModal:false});
 }


 closeFeedBackHistoryModal = () => {
   this.setState({feedBackHistoryModal : false})
 }


 insertFeedBack = (customer) => {
   this.getSingleCustomer(customer);
   this.closeFeedBackModal();
 }


 insertFeedBackHistory = (customer) => {
   this.getSingleCustomer(customer);
   this.closeFeedBackHistoryModal();
 }



 compareFeedBackCustomer(){
   if (this.state.singleCustomer.finalFeedback >= 1 &&this.state.singleCustomer.finalFeedback <=2.9) {
      this.setState({badgeColor:'danger' ,level:'Low'})
   }else if (this.state.singleCustomer.finalFeedback >=3 && this.state.singleCustomer.finalFeedback <=3.9 ) {
     this.setState({badgeColor:'warning',level:'Medium'})
   }else {
     this.setState({badgeColor:'success',level:'High'})
   }
 }


showMap(i){
  this.setState({lat:i.latitude, lng: i.longitude, showMap:true})
}



listComplaintsCustomer(customerId){
  this.setState({loading:true})
  ComplaintsServices.listComplaintsCustomer(customerId,(res)=>{
    this.setState({complaints:res.data.complaints,loading:false})
  })
}


singleComplaint(item){
  const url = `/complaints/${item}`;
  window.open(url, '_blank');
}

  renderSingleCustomer(){
    return(
      <ModalBody>
        <Row style={{display:'block'}} className='text-center'>
          <Button className='actionsBtn' onClick={()=>this.handlePushModal()}>Push <i style={{marginLeft:10}} className="fas fa-bell"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.handelSMSModal()}>SMS<i style={{marginLeft:10}} className="fas fa-sms"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({renderMakeOrder:true})}>Create Order <i style={{marginLeft:10}} className="fas fa-plus-circle"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({feedBackModal:true})}>FeedBack<i style={{marginLeft:10}} className="fas fa-comment-alt"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({feedBackHistoryModal:true})}>FeedBack History<i style={{marginLeft:10}} className="fas fa-user-tie"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.startChat()}>Start Chat<i style={{marginLeft:10}} className="far fa-comment-dots"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({chargeFixawyPayModal:true})}>Charge Fixawy Pay<i style={{marginLeft:10}} className="fas fa-money-bill-wave"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({historyModal:true})}>History<i style={{marginLeft:10}} className="fas fa-user-tie"></i></Button>
          <Button className='actionsBtn' onClick={()=>this.setState({estimationModal:true})}>Has Estimation<i style={{marginLeft:10}} className="fas fa-bar-chart"></i></Button>



          {/* {this.state.singleCustomer.isBlocked=='1'?(
            <Button className='actionsBtn' onClick={()=>this.handleUnBlockModal()}>un-Block<i style={{marginLeft:10}} className="fas fa-check"></i></Button>
            ):(
            <span>
              <Button className='actionsBtn' onClick={()=>this.setState({renderMakeOrder:true})}>Create Order <i style={{marginLeft:10}} className="fas fa-plus-circle"></i></Button>
              <Button className='actionsBtn' onClick={()=>this.handleBlockModal()}>Block<i style={{marginLeft:10}} className="fas fa-stop-circle"></i></Button>
            </span>
          )} */}
        </Row>
        <Row>
          <Col md="6" style={{marginTop:'5%'}}>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer ID: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.id}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer Name: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.name}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Gender: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.gender}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Wallet Amount: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.walletAmount} EGP</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Phone Number: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.phone}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer status: <Badge className='shadow' color={this.state.singleCustomer.isBlocked=='1'?'danger':'success'}>{this.state.singleCustomer.isBlocked=='1'?'Blocked':'Active'}</Badge></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Last City: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.lastCity?this.state.singleCustomer.lastCity.enName:'N\A'}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Last Area: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.lastArea?this.state.singleCustomer.lastArea.enName:'N\A'}</span></p>

            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Temperary Wallet:</p>
            <div style={{width:'70%',marginLeft:25}}>
            <Row>
              <Col style={{fontWeight:'bold'}}>Wallet</Col>
              <Col style={{fontWeight:'bold'}}>Expiry Date</Col>
            </Row>
            {this.state.singleCustomer.temporaryWallet.entries.length == 0 ? '0 LE' : (
            <>
            {this.state.singleCustomer.temporaryWallet.entries.map((item,index)=>(
            <Row key={index} style={{marginTop:5}}>
              <Col>{item.amount} LE</Col>
              <Col>{item.expiryDate}</Col>
            </Row>
            ))}
            </>
            )}
            </div>
             <div style={{marginLeft:25,marginTop:10}}>
             <p><span style={{fontWeight:'bold'}}>Total Wallet</span> : {this.state.singleCustomer.temporaryWallet.credit} LE</p>
             </div>

            {this.state.singleCustomer.isBlocked=='1'?(
              <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Block Reason: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.blockReason.enName}</span></p>
            ):null}

            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>
            E-Mail: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.email!=null?this.state.singleCustomer.email:'Not Available'}   {this.state.singleCustomer.isEmailVerified?<i className='fa fa-check-circle' style={{color:'#4dbd74', fontSize:15}}/>:''}</span>
          </p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Facebook Account: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.faceBookAccountLink != null?<a href={this.state.singleCustomer.faceBookAccountLink} target='_blank'>Link</a>:'Not Available'}</span></p>

            {this.state.singleCustomer.addresses.length == 0?(
              <p style={{marginBottom:0}}><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Addresses: <span style={{fontWeight:'bold'}}>Not Available</span></p>
            ):(
              <div>
                <p style={{marginBottom:0}}><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Addresses:</p>
                {this.state.singleCustomer.addresses.map((i, index)=>(
                  <div key={index}>
                    <p  style={{fontWeight:'bold', paddingLeft:'5%',marginBottom:0}}>- {i.enAddress}</p>
                    {i.longitude && i.latitude ?(
                      <div onClick={()=>this.showMap(i)}
                        style={{cursor:'pointer', marginLeft:'5%',borderRadius:20, border: '1px solid #4dbd74',display: 'flex',justifyContent: 'center',width: '25%',padding: 5, fontSize:12 }}
                        >
                          Show on Map <i style={{ fontSize:18,color:'#4dbd74', marginLeft:5, alignSelf:'center'}} className='fa fa-map-marker'/>
                        </div>
                      ):null}
                    </div>
                  ))}
                </div>
              )}

            </Col>
          <Col md="6">
            <img src={this.state.singleCustomer.image!=null?this.state.singleCustomer.image:Avatar} className='shadow' style={{borderRadius:'50%',marginBottom: '5%', width:200,height:180,marginTop:'2%'}}/>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Customer FeedBack: <Badge className='shadow' style={{padding:7,fontSize:14}} color={this.state.badgeColor}>{this.state.level}</Badge></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Join Date: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.createdAt}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Done Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.doneOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Inprogress Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.inProgressOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Waiting Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.waitingConfirmationOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Confirmed Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.confirmedOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Canceled Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.cancelledOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Failed Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.failedOrdersCount}</span></p>
            <p><i className="icon-arrow-right-circle" style={{marginRight:10}}></i>Numbers of Complains Orders: <span style={{fontWeight:'bold'}}>{this.state.singleCustomer.complaintsCount}</span>
            {this.state.singleCustomer.complaintsCount == 0 ? null : (
              <span className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer',marginLeft:10}} onClick={()=>this.setState({viewComplaints:true},()=>this.listComplaintsCustomer(this.state.singleCustomer.id))}>View Complaints</span>
            )}
            </p>

          </Col>

        </Row>
        {this.state.singleCustomer.devices.length!=0?<h1 style={{textAlign:'center',fontSize:20}}>Devices</h1>:''}
        <Row style={{justifyContent:'center'}}>

          {this.state.singleCustomer.devices.map((i, index)=>(
            <Col md='3' key={index} className='estimateCard'>
              {/* {this.state.singleCustomer.devices.length>1?<h6><i className="icon-arrow-right-circle" style={{marginRight:10}}/> Device {index+1}</h6>:null} */}
              <p> Model: <span style={{fontWeight:'bold'}}>{i.model}</span></p>
              <p> Platform: <span style={{fontWeight:'bold'}}>{i.platformType.type}</span></p>
              <p> Version: <span style={{fontWeight:'bold'}}>{i.osVersion}</span></p>
            </Col>
          ))}
        </Row>
      </ModalBody>
    )
  }





  renderPushInputs(){
    return(
      <ModalBody>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Title: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter notification title"
              value={this.state.pushTitle}
              onChange={(e)=>this.handleChangeInputs(e, 'pushTitle')}
              maxLength={500}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>Body: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={2}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="Enter notification content"
              value={this.state.pushBody}
              onChange={(e)=>this.handleChangeInputs(e, 'pushBody')}
              maxLength={1000}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2" style={{textAlign:'center',marginTop:6}}><span className='titles'>URL: </span></Col>
          <Col md="5">
            <textarea
              wrap="hard" cols={5} rows={1}
              style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
              placeholder="http://......"
              value={this.state.pushURL}
              onChange={(e)=>this.handleChangeInputs(e, 'pushURL')}
            />
          </Col>
        </Row>
      </ModalBody>
    )
  }



  render() {
    return (
      <div>

        {this.state.renderMakeOrder? <MakeOrder data={this.state.singleCustomer} reload={this.props.reload}/> : null}

        {/* main modal */}
        <Modal fade={false} isOpen={this.state.mainModal} toggle={()=>this.handleSingleCustomer()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleSingleCustomer()}>Customer Details</ModalHeader>
          {this.state.singleCustomer != ''? this.renderSingleCustomer() : null}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleSingleCustomer()}>Close</Button>
          </ModalFooter>
        </Modal>


        {/* Push notification */}
        <Modal fade={false} isOpen={this.state.modalPush} toggle={()=>this.handlePushModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handlePushModal()}>Push Notification</ModalHeader>
          {this.renderPushInputs()}
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handlePushModal()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.sendPush()}>Send</Button>
          </ModalFooter>
        </Modal>


        <FModal
            title='View Complaints'
            content={
             <div style={{marginLeft:20}}>
               {this.state.complaints.map((item,index)=>(
              <span key={index} className='noWrap someSpace' style={{backgroundColor:'transparent',border:'1px solid #1a1a1a', color:'#1a1a1a',fontSize:12, fontWeight:'bold', cursor:'pointer',marginLeft:10}} onClick={()=>this.singleComplaint(item.id)}>{item.id}</span>
               ))}
             </div>
            }
            state={this.state.viewComplaints}
            // onClose={()=>this.orderHistoryModal()}
            onClose={()=>this.setState({viewComplaints:!this.state.viewComplaints})}
            show={"none"}
          />

       {/* FeedBack Createria */}
       {this.state.feedBackModal? <FeedBack  user={this.props.data} insertFeedBack={this.insertFeedBack} closeFeedBackModal={this.closeFeedBackModal}/> : null }
        {/* FeedBack History */}
       {this.state.feedBackHistoryModal? <FeedBackHistory  user={this.props.data} insertFeedBackHistory={this.insertFeedBackHistory} closeFeedBackHistoryModal={this.closeFeedBackHistoryModal}/> : null }


        {/* SMS Message */}
        <Modal fade={false} isOpen={this.state.modalSMS} toggle={()=>this.handelSMSModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handelSMSModal()}>SMS Message</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="3">
                <p className='titles'>SMS message : </p>
              </Col>
              <Col md="6">
                <textarea
                  wrap="hard" cols={5} rows={2}
                  style={{width:'100%',padding:5,borderRadius:5,borderColor:'#777777',padding:7,borderWidth:1,resize:'none'}}
                  placeholder="Enter content of SMS message.."
                  value={this.state.smsTitle}
                  onChange={(e)=>this.handleChangeInputs(e, 'sms')}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handelSMSModal()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.sendSMS()}>Send</Button>
          </ModalFooter>
        </Modal>


        {/* unBlock */}
        <Modal fade={false} isOpen={this.state.modalunblock} toggle={()=>this.handleUnBlockModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleUnBlockModal()}>un-Block</ModalHeader>
          <ModalBody>
            <p style={{fontWeight:'bold',fontSize:16}}>Are you sure un-Block this user ? </p>
          </ModalBody>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleUnBlockModal()}>Cancel</Button>{' '}
            <Button className='actionsBtn' onClick={()=>this.unBlockCustomer()}>Submit</Button>
          </ModalFooter>
        </Modal>


        {/* Block */}
        <Modal fade={false} isOpen={this.state.modalBlock} toggle={()=>this.handleBlockModal()} className={this.props.className}>
          <ModalHeader toggle={()=>this.handleBlockModal()}>Block</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="3">
                <p className='titles'>Reason: </p>
              </Col>
              <Col md="6">
                <UncontrolledDropdown  className="mb-3">
                  <DropdownToggle caret  style={{width:'100%', backgroundColor:'#fff'}}>{this.state.blockReason}</DropdownToggle>
                  <DropdownMenu style={{width:'100%'}}>
                    {this.state.reasons.map((item, index)=>(
                      <DropdownItem key={index} onClick={()=>this.handleBlockReason(item)}>{item.enName}</DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className='actionsBtn' onClick={()=>this.handleBlockModal()}>Cancel</Button>
            <Button className='actionsBtn' onClick={()=>this.blockCustomer()}>Submit</Button>
          </ModalFooter>
        </Modal>

        <MapModal fade show={this.state.showMap} closeHandler={()=>this.setState({showMap:false})} lat={this.state.lat} lng={this.state.lng} />

        <FModal
            title='Charge Fixawy Pay'
            content={
              <ChargeFixawyPay
                ref={ref=>this.chargeFixawyPayRef=ref}
                customer={this.state.singleCustomer}
                update={()=>this.setState({chargeFixawyPayModal:false})}
              />
            }
            state={this.state.chargeFixawyPayModal}
            onClose={()=>this.setState({chargeFixawyPayModal:false})}
            onSubmit={()=>this.chargeFixawyPayRef.chargeFixawyPay()}
          />
          <FModal
            title='Customer History'
            content={
              <CustomerHistory
                customer={this.state.singleCustomer}
                update={()=>this.setState({chargeFixawyPayModal:false})}
              />
            }
            state={this.state.historyModal}
            onClose={()=>this.setState({historyModal:false})}
            // onSubmit={()=>this.setState({historyModal:false})}
            show={"none"}
          />


          <FModal
            title='Has Estimation'
            content={
              <HasEstimation
                customer={this.state.singleCustomer}
                update={()=>this.setState({chargeFixawyPayModal:false})}
              />
            }
            state={this.state.estimationModal}
            onClose={()=>this.setState({estimationModal:false})}
            // onSubmit={()=>this.setState({historyModal:false})}
            show={"none"}
          />




        <MapModal fade show={this.state.showMap} closeHandler={()=>this.setState({showMap:false})} lat={this.state.lat} lng={this.state.lng} />

        {/* loading spinner */}
        <Modal fade={false} isOpen={this.state.loading} className='loading'>
          <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
        </Modal>

      </div>
)
}
}

export default withRouter(SingleCustomer);
