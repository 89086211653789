import React,{Fragment} from 'react'
import {LoadingSpinner, ChangePosition} from './../../components'
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Tooltip, UncontrolledTooltip} from 'reactstrap';
import Services from './../../services/Services'
import { Link, HashRouter } from 'react-router-dom'
import {FModal} from './../../components'
import DatePicker from "react-datepicker"
import { buildQueryStringFromArray } from './../../core/helper'

import Paginator from './../../components/Pagination/V2/Paginator'
import { NoResult } from '../../components'
// import PostDetails from './PostDetails'
import {Global} from './../../../src/core/Global'
import SingleCustomer from './../customers/SingleCustomer'
import SingleOrder from './../orders/SingleOrder'
import SingleFixer from './../fixers/SingleFixer'

export default class ListComplaints extends React.Component{
  constructor(props) {
    super(props)
    this.state={
      noResult:false,
      complaints:[],
      previousPages:[],
      nextPages:[],
      currentPage:1,
      loading:false,
      showSingleCustomer:false,
      customer:'',
      orderId:'',
      showSingleOrder:false,
      fixer:'',
      showSingleFixer:false,
      searchTerm:'',
      searchText:'',
      categories:[],
      statuses:[],
      cities:[],
      serviceGroups:[],
      areas:[],
      agents:[],
      selectedCities:[],
      selectedAreas:[],
      selectedCategories:[],
      selectedServiceGroups:[],
      selectedStatuses:[],
      selectedType:'All',
      selectedAgents:[],
      singleFixer:'',
      searchTerm:'',
      toDate:'',
      requestedDate:'',
      tags:[],
      selectedTags:[],
      requestedDate:'',
      fromDateToRequest:''
    }
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount(){
    this.getComplaints('')
  }

  handleKeyPress(target) {
    if(target.charCode==13){
      this.getComplaints()
    }
  }


  getComplaints(type){
    let api = ''
    if (type == 'filter') {
      api = this.filter('filter')
    }else {
      api = this.filter()
    }

    if (type == 'filter' && api == `${Global.baseURL}admin-panel/complaints?page=${this.state.currentPage}`) {
      alert('Please, choose filter')
      return true
    }


    if (this.state.searchTerm != '') {
      this.setState({currentPage:1})
      api+=`&searchTerm=${this.state.searchTerm}`
    }


    console.log(api);

    this.setState({loading:true,showSingleOrder:false,showSingleCustomer:false,showSingleFixer:false})

    this.setState({loading:true})
    Services.getComplaints(api,(res)=>{
      if(res.message == 'forbidden'){
        this.props.history.push('/403');
      }else{
        console.log(res)
        this.setState({
          loading:false,
          complaints:res.data.complaints,
          noResult:!res.data.complaints.length,
          previousPages:res.data.paginationLinks.previousPages,
          nextPages:res.data.paginationLinks.nextPages,
          currentPage:res.data.paginationInfo.currentPage,
          searchText:this.state.searchTerm!=''?this.state.searchTerm:''
        })
      }
    })
    this.getCities()
  }

  getCities(){
    this.setState({loading:true})
    Services.getCities((res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.cities
      arr.push(obj)
      arr.unshift(choose)
      this.setState({cities:arr},()=>this.getCategories())
    })
  }

  getCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr},()=>this.getServiceGroups())
    })
  }
  getServiceGroups(){
    Services.getAllServiceGroups((res)=>{
      let arr = [{id:'choose',enName:'Choose'}];
      for (let i = 0; i < res.data.serviceGroups.length; i++) {
        arr.push(res.data.serviceGroups[i]);
      }
      arr.push({id:'all',enName:'All'})
      this.setState({serviceGroups:arr},()=>this.getStatuses())
    })
  }

  getStatuses(){
    Services.getStatuses('complaint',(res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.statuses
      arr.push(obj)
      arr.unshift(choose)
      this.setState({statuses:arr},()=>this.getComplainTags())
    })
  }

  getComplainTags(){
    Services.getComplainTags((res)=>{
      let arr = res.data.tags
      arr.push({enName:'All',id:'all'})
      arr.unshift({enName:'Choose',id:'choose'})
      this.setState({tags:arr})
    })
  }


  showSingleCustomer(customer){
    console.log(customer)
    this.setState({showSingleCustomer:true,showSingleOrder:false,showSingleFixer:false,customer:customer})
  }

  reload =()=>{
    this.setState({showSingleFixer:false,showSingleCustomer:false,showSingleOrder:false})
    this.getComplaints('')
  }

  showSingleOrder(orderId){
    console.log(orderId)
    this.setState({showSingleCustomer:false,showSingleOrder:true,showSingleFixer:false,orderId:orderId})
  }

  showSingleFixer(fixer){
    console.log(fixer)
    this.setState({showSingleCustomer:false,showSingleOrder:false,showSingleFixer:true,fixer:fixer})
  }

  changePage(page){
    this.setState({currentPage:page},()=>this.getComplaints(''))
  }

  singleComplaint(item){
    const url = `/complaints/${item}`;
    window.open(url, '_blank');
  }


  handleChangeInputs = (e, handle) => {
    this.setState({searchTerm:e.target.value})
  }

  handleMultiSelectMenu(type,item){
    if (item.id == 'choose') {
      this.state[type] = [];
    }else if(item.id == 'all'){
      this.state[type]=[];
      this.state[type].push(item);
    }else{
      if (this.state[type].includes(item)) {
        alert('you have been select this item before')
      }else{
        this.state[type] = this.state[type].filter( item => item.id != 'all' )
        this.state[type].push(item)
      }
    }
    this.forceUpdate()

  }
  removeMultiFilter(type,index){
    this.state[type].splice(index,1)
    this.forceUpdate()
  }
  filter(type){
    let api = ''
    if (type =='filter') {
      api = `${Global.baseURL}admin-panel/complaints?page=1`
    }else {
      api = `${Global.baseURL}admin-panel/complaints?page=${this.state.currentPage}`
    }


    if (this.state.selectedCities.length!=0 && !(this.state.selectedCities[0].id == 'all')) {
      api+=buildQueryStringFromArray('cities',this.state.selectedCities)
    }
    if (this.state.selectedCategories.length!=0 && !(this.state.selectedCategories[0].id == 'all')) {
      api+=buildQueryStringFromArray('categories',this.state.selectedCategories)
    }
    if (this.state.selectedStatuses.length!=0 && !(this.state.selectedStatuses[0].id == 'all')) {
      api+=buildQueryStringFromArray('statuses',this.state.selectedStatuses)
    }
    if(this.state.selectedServiceGroups.length!=0 && !(this.state.selectedServiceGroups[0].id == 'all')){
      api+= buildQueryStringFromArray('serviceGroups',this.state.selectedServiceGroups)
    }
    if(this.state.selectedTags.length!=0 && !(this.state.selectedTags[0].id == 'all')){
      api+= buildQueryStringFromArray('tags',this.state.selectedTags)
    }



    console.log(api);

    if (this.state.requestedDate!='') {
      api+=`&startDate=${this.state.fromDateToRequest}&endDate=${this.state.fromDateToRequest}`
    }

    return api
  }

  resetFilter(){
    // this.child.clearStatuses()
    this.setState({
      selectedCities:[],
      selectedAreas:[],
      selectedStatuses: [],
      selectedAgents: [],
      selectedCategories: [],
      selectedServiceGroups:[],
      selectedType: 'All',
      selectedTypeName:'',
      requestedDate:'',
      fromDate:'',
      currentPage:1,
      tab:'',
      selectedUnCoveredRequestedDate:0,
      selectedUnCoveredFixer:0,
      filterChat:'Choose',
      filterPayment:'',
      filterPaymentValue:'',
      selectedTags:[]
    },()=>this.getComplaints(''))
  }

  handleOnChangeDate(date){
    var day = new Date (date+' Z')
    var neededDate  = day.toISOString().split('T')[0]
    console.log(neededDate);
    this.setState({requestedDate:date, fromDateToRequest:neededDate })
  }



  render(){
    return(
      <div className="app align-items-center">
        <Container>
          {this.state.showSingleCustomer?<SingleCustomer data={this.state.customer} reload={this.reload}/>:null}
          {this.state.showSingleOrder?<SingleOrder data={this.state.orderId} reload={this.reload}/>:null}
          {this.state.showSingleFixer?<SingleFixer data={this.state.fixer} reload={this.reload}/>:null}

          {this.renderFilter()}

          <Card>
            {/* <CardHeader className='shadow'> */}
            {this.renderSearch()}
            {/* </CardHeader> */}
            <CardBody className='shadow'>
              {this.state.noResult == true ? <NoResult/> :
                <>
                {this.state.searchText!=''?(
                  <div style={{textAlign:'center',margin:'2%',color:'#808080'}}>
                    <i className='fa fa-search' style={{fontSize:30, marginBottom:'.5%'}}/>
                    <h6 style={{fontSize:18}}>Search Results of
                      <span style={{fontWeight:'bold',fontSize:22,marginLeft:10}}>{this.state.searchText}</span>

                    </h6>
                    <span style={{color:'red', cursor:'pointer'}} onClick={()=>this.setState({searchTerm:''},()=>this.getComplaints(''))}>
                      Clear Search
                      <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                    </span>
                  </div>
                ):null}
                {this.renderComplaints()}
                <Paginator prevPages={this.state.previousPages} nextPages={this.state.nextPages} notify={(page)=>this.changePage(page)} />
              </>}

              <LoadingSpinner loading={this.state.loading}/>
            </CardBody>
          </Card>
        </Container>
      </div>

    )
  }






  renderSearch(){
    return(
      <CardHeader className='shadow'>
        <Row>
          <Col md="6" style={{display:'flex',alignItems:'center'}}>
            <i className="fa fa-align-justify" ></i>
            <span style={{marginLeft:6}}>All Complaints</span>
          </Col>
          <Col  style={{margin:'auto'}}>
            <InputGroup>
              <Input
                value={this.state.searchTerm}
                onChange={(e)=>this.handleChangeInputs(e, 'search')}
                type="text"
                id="searchTerm"
                name="searchTerm"
                placeholder="By @complaint Id, order Id, phone ,name...."
                maxLength={50}
                onKeyPress={this.handleKeyPress}
              />
              <InputGroupAddon addonType="prepend">
                <Button onClick={()=>this.setState({currentPage:1},()=>this.getComplaints(''))} type="button" style={{backgroundColor:'#4dbd74',borderColor:'#4dbd74'}}><i className="fa fa-search"></i> Search</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    )
  }


  renderComplaints(){
    return(
      <Table hover bordered striped responsive size="md">
        <thead>
          <tr>
            <th style={{textAlign:'center'}}>ID</th>
            <th style={{textAlign:'center'}}>Order Serial</th>
            <th style={{textAlign:'center'}}>Customer Name</th>
            <th style={{textAlign:'center'}}>Fixer Name</th>
            <th style={{textAlign:'center'}}>Status</th>
            <th style={{textAlign:'center'}}>City</th>
            <th style={{textAlign:'center'}}>Category</th>
            <th style={{textAlign:'center'}}>Service Group</th>
            <th style={{textAlign:'center'}}>Tags</th>
            <th style={{textAlign:'center'}}>Admin</th>
            <th style={{textAlign:'center'}}>Satisfied</th>
            <th style={{textAlign:'center'}}>Date</th>
          </tr>
        </thead>
        <tbody>
          {this.state.complaints.map((item,index)=>(
            <tr key={index}>
              <td style={{textAlign:'center'}}>
                <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.singleComplaint(item.id)}>{item.id}</Button>
              </td>
              <td style={{textAlign:'center'}}>
                <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.showSingleOrder(item.orderId)}>{item.order.serial}</Button>
              </td>
              {item.customer != null ? (
                <td style={{textAlign:'center'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.showSingleCustomer(item.customer)}>{item.customer.enName}</Button>
                </td>
              ):<td style={{textAlign:'center'}}>N/A</td>}
              {item.fixer != null?
                <td style={{textAlign:'center'}}>
                  <Button className="click" style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}} onClick={()=>this.showSingleFixer(item.fixer)}>{item.fixer.arName}</Button>
                </td> :
                <td style={{textAlign:'center'}}>N/A</td>
              }
              <td style={{textAlign:'center'}}>{item.status.enName}</td>

              <td style={{textAlign:'center'}}>{item.order.city.enName}</td>
              <td style={{textAlign:'center'}}>{item.order.category.enName}</td>
              <td style={{textAlign:'center'}}>{item.order.serviceGroup?item.order.serviceGroup.enName:'N/A'}</td>

              <td style={{textAlign:'center', maxWidth:175}}>
                {item.tags.length == 0 ? 'N/A' : (
                  item.tags.map((e,k)=>(
                    <span key={k} style={{marginRight:10, whiteSpace:'nowrap',color:'#0089ff', textDecoration:'underline'}}>#{e.enName}</span>
                  ))
                )}
              </td>
              <td style={{textAlign:'center'}}>{item.admin == null ? 'N/A' : item.admin.enName}</td>
              <td style={{textAlign:'center'}}>{item.satisfied == 0 ? <i className="fas fa-angry" style={{fontSize:30,color:'rgb(255, 180, 0)'}}></i> : <i className="fas fa-laugh-beam" style={{fontSize:30,color:'rgb(255, 180, 0)'}}></i>}</td>
              <td style={{textAlign:'center'}}>{item.createAt}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }


  renderFilter(){
    return(
      <>
      <Row style={{padding:'0px 10px'}}>
        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />City</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.cities.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>{this.handleMultiSelectMenu('selectedCities',item)}}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>


        <UncontrolledDropdown  className="" style={{marginRight:20}} >
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Category</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.categories.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedCategories',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>


        <UncontrolledDropdown  className="" style={{marginRight:20}}>
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />Status</DropdownToggle>
          <DropdownMenu style={{width:'100%'}}>
            {this.state.statuses.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedStatuses',item)}>{item.enName}</DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>


        <UncontrolledDropdown  className="" style={{marginRight:20}} >
          <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Service Group</DropdownToggle>
          <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
            {this.state.serviceGroups.map((item, index)=>(
              <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedServiceGroups',item)}>{item.enName}</DropdownItem>
            )
          )}
        </DropdownMenu>
      </UncontrolledDropdown>

      <UncontrolledDropdown  className="" style={{marginRight:20}} >
        <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Tags</DropdownToggle>
        <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
          {this.state.tags.map((item, index)=>(
            <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedTags',item)}>{item.enName}</DropdownItem>
          )
        )}
      </DropdownMenu>
    </UncontrolledDropdown>


    <div className='date'>
      <DatePicker
        // minDate={new Date()}
        selected={this.state.requestedDate}
        onChange={(date)=> this.handleOnChangeDate(date) }
        dateFormat="dd-MM-yyyy"
        placeholderText='Date'
      />
    </div>
    {this.state.requestedDate!=''?(
      <div className='fa fa-close clearDatePicker' onClick={()=>this.setState({requestedDate:'',fromDate:''})}/>
    ):null}


  </Row>

  <Row style={{padding:'5px 10px'}}>
    <Button onClick={()=>this.getComplaints('filter')} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'#fff',padding:'7px 20px'}}>Filter</Button>

    <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
  </Row>
  <Row style={{padding:'0px 10px'}}>
    {this.renderFiltersInfo()}
  </Row>
</>
)
}


renderFiltersInfo(){
  return (<>
    <Row  style={{padding:'0px 0px 20px 0px'}}>
      {this.state.selectedCities.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Cities </span>
          <div>
            {this.state.selectedCities.map( (city,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>{this.removeMultiFilter('selectedCities',i)}} >
                {`${city.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedCategories.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Categories </span>
          <div>
            {this.state.selectedCategories.map( (category,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedCategories',i)} >
                {`${category.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedServiceGroups.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Service Groups </span>
          <div>
            {this.state.selectedServiceGroups.map( (serviceGroup,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedServiceGroups',i)} >
                {`${serviceGroup.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}
      {this.state.selectedStatuses.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Status </span>
          <div>
            {this.state.selectedStatuses.map( (status,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedStatuses',i)} >
                {`${status.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}

      {this.state.selectedTags.length?(
        <div style={{marginLeft:20}}>
          <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Tags </span>
          <div>
            {this.state.selectedTags.map( (status,i) => (
              <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedTags',i)} >
                {`${status.enName} `}
                <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
              </Button>
            ))}
          </div>
        </div>
      ):null}

    </Row>
  </>)
}

}
