import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row ,Table ,CardHeader ,Badge ,PaginationItem ,PaginationLink ,Pagination ,FormGroup ,Label ,Modal ,ModalBody ,ModalHeader ,ModalFooter ,Dropdown ,DropdownItem,DropdownMenu,DropdownToggle, UncontrolledDropdown ,Tooltip} from 'reactstrap';
import Services from './../../services/Services'
import workContractReportServices from './../../services/reports/workContractReportServices'
import { withRouter } from 'react-router-dom';
import { Global } from './../../core/Global'
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { buildQueryStringFromArray } from './../../core/helper'
import { FModal, WorkContractDetails } from './../../components'
import moment from 'moment'

class WorkContractReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workContracts:[],
      cities:[],
      areas:[],
      categories:[],
      packages:[],
      selectedCities:[],
      selectedAreas:[],
      selectedCategories:[],
      selectedPackages:[],
      from:moment().startOf('month').toDate(),
      to:'',
      renderFromDate : '',
      renderToDate: '',
      currentPage:1
    }
  }

  async componentDidMount(){
    await this.getWorkContractReport()
    await this.getCities()
    window.scrollTo(0, 0)
  }

  getCities(){
    this.setState({loading:true})
    Services.getCities((res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.cities
      arr.push(obj)
      arr.unshift(choose)
      this.setState({cities:arr},()=>this.getArea())
    })
  }
  getArea(){
    this.setState({loading:true})
    Services.getArea(this.state.selectedCities, (res)=>{
      let obj = {enName:'All',id:'all'}
      let choose = {enName:'Choose',id:'choose'}
      let arr = res.data.areas
      arr.push(obj)
      arr.unshift(choose)
      this.setState({areas:arr},()=>this.getCategories())
    })
  }

  getCategories(){
    Services.getCategories((res)=>{
      let obj = {enName:'All',id:'all',children:[]}
      let choose = {enName:'Choose',id:'choose',children:[]}
      let arr = res.data.categories
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children.length != 0) {
          for (var j = 0; j < arr[i].children.length; j++) {
            arr.push(arr[i].children[j])
          }
          arr.splice(i,1)
        }
      }
      arr.push(obj)
      arr.unshift(choose)
      this.setState({categories:arr },()=>this.getAllPackages(1))
    })
  }
  getAllPackages(page){
    this.setState({loading:true})
    Services.getAllPackages(page,(res)=>{
      let arr = this.state.packages
      if (res.data.paginationLinks.nextPages.length!=0) {
        this.setState({packages:arr.concat(res.data.packages)})
        this.getAllPackages(res.data.paginationLinks.nextPages[0])
      }else {
        let arr = this.state.packages.concat(res.data.packages)
        let obj = {arName:'All',id:'all'}
        let choose = {arName:'Choose',id:'choose'}
        arr.push(obj)
        arr.unshift(choose)
        this.setState({packages:arr ,loading:false})
      }
    })
  }




  getWorkContractReport(type){
    let api = ''
    if (type == 'filter') {
      api = this.filter('filter')
    }else {
      api = this.filter()
    }

    if (type == 'filter' && api == `${Global.baseURL}admin-panel/reports/work-contracts/released?`) {
      alert('Please, choose filter')
      return true
    }
    console.log(this.state.currentPage);

    console.log(api);

    this.setState({loading:true})

    workContractReportServices.getWorkContractReport(api, (res)=>{
      this.setState({
        loading:false,
        workContracts:res.data,
        noResult:res.data.length==0?false:true,
      })
    })
  }




  handleMultiSelectMenu(type,item){
    if (item.id == 'choose') {
      this.state[type] = [];
    }else if(item.id == 'all'){
      this.state[type]=[];
      this.state[type].push(item);
    }else{
      if (this.state[type].includes(item)) {
        alert('you have been select this item before')
      }else{
        this.state[type] = this.state[type].filter( item => item.id != 'all' )
        this.state[type].push(item)
      }
    }
    this.forceUpdate()

  }
  removeMultiFilter(type,index){
    this.state[type].splice(index,1)
    this.forceUpdate()
  }
  filter(type){
    let api = '', wcDetailsApi = ''
    if (type =='filter') {
      api = `${Global.baseURL}admin-panel/reports/work-contracts/released?page=1&withDetails=0`
    }else {
      api = `${Global.baseURL}admin-panel/reports/work-contracts/released?page=${this.state.currentPage}&withDetails=0`
    }

    wcDetailsApi = `${Global.baseURL}admin-panel/reports/work-contracts/released-with-details?withDetails=1`

    if (this.state.selectedCities.length!=0 && !(this.state.selectedCities[0].id == 'all')) {
      api+=buildQueryStringFromArray('cities',this.state.selectedCities)
      wcDetailsApi+= buildQueryStringFromArray('cities',this.state.selectedCities)
    }
    if (this.state.selectedCategories.length!=0 && !(this.state.selectedCategories[0].id == 'all')) {
      api+=buildQueryStringFromArray('categories',this.state.selectedCategories)
      wcDetailsApi+= buildQueryStringFromArray('categories',this.state.selectedCategories)
    }
    if (this.state.selectedAreas.length!=0 && !(this.state.selectedAreas[0].id == 'all')) {
      api+=buildQueryStringFromArray('areas',this.state.selectedAreas)
      wcDetailsApi+= buildQueryStringFromArray('areas',this.state.selectedAreas)
    }
    if (this.state.selectedPackages.length!=0 && !(this.state.selectedPackages[0].id == 'all')) {
      api+=buildQueryStringFromArray('packages',this.state.selectedPackages)
      wcDetailsApi+= buildQueryStringFromArray('packages',this.state.selectedPackages)
    }

    if (this.state.renderFromDate) {
      api+=`&startDate=${this.state.renderFromDate}`
      wcDetailsApi+= `&startDate=${this.state.renderFromDate}`
    }
    if (this.state.renderToDate) {
      api+=`&endDate=${this.state.renderToDate}`
      wcDetailsApi+= `&endDate=${this.state.renderToDate}`
    }

    console.log(api);
    this.setState({wcDetailsApi})
    return api
  }

  resetFilter(){
    this.setState({
      selectedCities:[],
      selectedAreas:[],
      selectedPackages: [],
      selectedCategories: [],
      from:'',
      currentPage:1,
      to:'',
      renderFromDate:'',
      renderToDate:'',
    },()=>this.getWorkContractReport(''))
  }


  handleOnChangeDate(type, date){
    var day = new Date (date+' Z')

    if(!date){
      this.setState({from:'', renderFromDate: '',to:'', renderFromDate: ''})
    }else {
      var neededDate  = day.toISOString().split('T')[0]
      if (type === 'from') {
        this.setState({from:date, renderFromDate: neededDate},()=>console.log(this.state.renderFromDate))
      }else {
        this.setState({to:date, renderToDate: neededDate},()=>console.log(this.state.renderToDate))
      }
    }
  }

  getNextReports(){
    let page = this.state.currentPage
    this.setState({currentPage:page+1},()=>this.getWorkContractReport(''))
  }
  getPreviousReports(){
    let page = this.state.currentPage
    this.setState({currentPage:page-1},()=>this.getWorkContractReport(''))
  }




  render() {
    return (
      <div className="app align-items-center">

        <Container>
          {this.renderFilter()}
          <Card>
            <CardHeader className='shadow'>
              <Row>
                <Col md="8" style={{display:'flex',alignItems:'center'}}>
                  <i className="fa fa-align-justify" ></i>
                  <span style={{marginLeft:6}}>Released Work Contract</span>
                </Col>
              </Row>

            </CardHeader>

            {this.state.noResult?
              <CardBody className='shadow'>
                {this.renderWorkContractReport()}
                {this.renderPagination()}
              </CardBody>
              :(
                <CardBody className='shadow' style={{textAlign:'center',color:'#777',padding:'14%'}}>
                  <i className="fas fa-heart-broken" style={{fontSize:50,marginBottom:'2%'}}></i>
                  <h2>There's no result</h2>
                  {this.state.currentPage > 1 ? this.renderPagination():null}
                </CardBody>
              )}


              {/* loading spinner */}
              <Modal fade={false} isOpen={this.state.loading} className='loading'>
                <LoadingOverlay active={this.state.loading} spinner text='Loading...' style={{backgroundColor:'#00000060'}}/>
              </Modal>

            </Card>
          </Container>

          <FModal
            title='Details'
            content={
              <WorkContractDetails
                api={this.state.wcDetailsApi}
                workContract={this.state.selectedWC}
              />
            }
            state={this.state.displayDetails}
            onClose={()=>this.setState({displayDetails:false},()=>this.getWorkContractReport(''))}
            noSubmitBtn
          />
        </div>
      )
    }




    renderWorkContractReport(){
      return(
        <div>

          <Table hover bordered striped responsive size="md">
            <thead>
              <tr>
                <th style={{textAlign:'center'}}>Year</th>
                <th style={{textAlign:'center'}}>Month</th>
                <th style={{textAlign:'center'}}>Released WC</th>
                <th style={{textAlign:'center'}}>fx-reservation</th>
                <th style={{textAlign:'center'}}>fx-accepted</th>
                <th style={{textAlign:'center'}}>fx-new</th>
                <th style={{textAlign:'center'}}>fx-declined</th>
                <th style={{textAlign:'center'}}>fx-approved</th>
                <th style={{textAlign:'center'}}>fx-expired</th>
                <th style={{textAlign:'center'}}>fx-closed</th>
                <th style={{textAlign:'center'}}>fx-terminated</th>
              </tr>
            </thead>
            <tbody>
              {this.state.workContracts.map((item, index)=>(
                <tr key={index}>
                  <td style={{textAlign:'center'}}>{item.year}</td>
                  <td style={{textAlign:'center'}}>{item.month}</td>
                  <td style={{textAlign:'center'}}>
                    <Button className="click"
                      style={{backgroundColor:'transparent',borderColor:'transparent',padding:10,fontWeight:'bold'}}
                      onClick={()=>this.setState({displayDetails:true, selectedWC:item})}
                      >{item.totalWcs}
                    </Button>
                  </td>
                  <td style={{textAlign:'center'}}>{item.totalFwcs}</td>
                  <td style={{textAlign:'center'}}>{item.totalAcceptedRequests}</td>
                  <td style={{textAlign:'center'}}>{item.totalNew}</td>
                  <td style={{textAlign:'center'}}>{item.totalDeclined}</td>
                  <td style={{textAlign:'center'}}>{item.totalApproved}</td>
                  <td style={{textAlign:'center'}}>{item.totalExpired}</td>
                  <td style={{textAlign:'center'}}>{item.totalClosed}</td>
                  <td style={{textAlign:'center'}}>{item.totalTerminated}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

      )
    }



    renderFilter(){
      return(
        <>
        <Row style={{padding:'0px 10px'}}>
          <UncontrolledDropdown  className="" style={{}}>
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-city' style={{marginRight:10}} />City</DropdownToggle>
            <DropdownMenu style={{width:'100%'}}>
              {this.state.cities.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>{this.handleMultiSelectMenu('selectedCities',item)}}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown className=" areaMenu"  style={{marginRight:20}}>
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-thermometer-quarter' style={{marginRight:10}} />Area</DropdownToggle>
            <DropdownMenu style={{width:'100%'}}>
              {this.state.areas.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedAreas',item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>


          <UncontrolledDropdown  className="" style={{marginRight:20}} >
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-sitemap' style={{marginRight:10}} />Category</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              {this.state.categories.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedCategories',item)}>{item.enName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown  className="" style={{marginRight:20}} >
            <DropdownToggle caret className='actionsBtn' style={{width:'100%'}}><i className='fas fa-bars' style={{marginRight:10}} />Packages</DropdownToggle>
            <DropdownMenu style={{width:'100%'}} className='scrolledMenu'>
              {this.state.packages.map((item, index)=>(
                <DropdownItem key={index} onClick={()=>this.handleMultiSelectMenu('selectedPackages',item)}>{item.arName}</DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>


          <div className="date">
            <DatePicker
              maxDate={this.state.to === '' ? new Date() :  this.state.to}
              selected={this.state.from}
              onChange={(date)=> this.handleOnChangeDate('from', date)}
              dateFormat="yyyy-MM-dd"
              placeholderText='Start date'
            />
          </div>
          {this.state.from!=''?(
            <div className='fa fa-close clearDatePicker' onClick={()=>this.setState({from:'',fromDate:''})}/>
          ):null}

          <div className="date">
            <DatePicker
              minDate={this.state.from}
              disabled={this.state.from==''}
              selected={this.state.to}
              onChange={(date)=> this.handleOnChangeDate('to', date)}
              dateFormat="yyyy-MM-dd"
              placeholderText='End date'
            />
          </div>
          {this.state.to!=''?(
            <div className='fa fa-close clearDatePicker' onClick={()=>this.setState({to:'',fromDate:''})}/>
          ):null}

        </Row>

        <Row style={{padding:'5px 10px'}}>
          <Button onClick={()=>this.getWorkContractReport('filter')} className='actionsBtn' style={{backgroundColor:'#3a4248',borderColor:'#3a4248',color:'#fff',padding:'7px 20px'}}>Filter</Button>
          <Button onClick={()=>this.resetFilter()} className='actionsBtn' style={{backgroundColor:'#a91010',borderColor:'#a91010',color:'#fff',padding:'7px 20px'}}>Reset</Button>
        </Row>

        <Row style={{padding:'0px 10px'}}>
          {this.renderFiltersInfo()}
        </Row>
      </>
    )
  }


  renderFiltersInfo(){
    return (<>
      <Row  style={{padding:'0px 0px 20px 0px'}}>
        {this.state.selectedCities.length?(
          <div style={{marginLeft:20}}>
            <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Cities </span>
            <div>
              {this.state.selectedCities.map( (city,i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={()=>{this.removeMultiFilter('selectedCities',i)}} >
                  {`${city.enName} `}
                  <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                </Button>
              ))}
            </div>
          </div>
        ):null}
        {this.state.selectedAreas.length?(
          <div style={{marginLeft:20}}>
            <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Areas </span>
            <div>
              {this.state.selectedAreas.map((area,i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedAreas',i)} >
                  {`${area.enName} `}
                  <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                </Button>
              ))}
            </div>
          </div>
        ):null}

        {this.state.selectedCategories.length?(
          <div style={{marginLeft:20}}>
            <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Categories </span>
            <div>
              {this.state.selectedCategories.map( (category,i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedCategories',i)} >
                  {`${category.enName} `}
                  <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                </Button>
              ))}
            </div>
          </div>
        ):null}

        {this.state.selectedPackages.length?(
          <div style={{marginLeft:20}}>
            <span style={{paddingLeft:'10px',fontSize:14,fontWeight:'bold'}} >Packages </span>
            <div>
              {this.state.selectedPackages.map((item,i) => (
                <Button className='multiFilter' color="secondary" key={i} onClick={()=>this.removeMultiFilter('selectedPackages',i)} >
                  {`${item.arName} `}
                  <i className='fa fa-times-circle' style={{color:'red',marginLeft:10}} />
                </Button>
              ))}
            </div>
          </div>
        ):null}

      </Row>
    </>)
  }

  renderPagination(){
    return(
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', padding:'5px 15px'}}>

        <Button
          onClick={()=>this.getPreviousReports()}
          className='actionsBtn'
          disabled={this.state.currentPage <= 1}
          style={{color:'#fff',padding:'7px 20px'}}
          >
            <span>
              <i className='fa fa-angle-left' style={{marginRight:5}}/>  Perviose
            </span>
          </Button>
          <Button
            disabled={this.state.workContracts.length == 0 }
            onClick={()=>this.getNextReports()}
            className='actionsBtn'
            style={{color:'#fff',padding:'7px 20px'}}
            >
              <span>Next    <i className='fa fa-angle-right' style={{marginLeft:5}}/></span>
            </Button>
      </div>
    )
  }

}

export default withRouter(WorkContractReport);
